const onetoone: Record<string, string> = {
  'onetoone.label.make.with': 'Faites un 1to1 avec',
  'onetoone.detail.roadblock.title': 'Blocage',
  'onetoone.detail.roadblock.add.new': 'Ajouter un nouveau blocage',
  'onetoone.dialog.edit.need.title': 'Modifier mes besoins',
  'onetoone.need.dialog.choose.level.weather': 'Choisissez votre niveau de météo:',
  'onetoone.need.dialog.edit.need.success': 'Mise à jour de mes besoin avec succès',
  'onetoone.need.dialog.edit.need.error': 'Échec de la mise à jour de mes besoins',
  'onetoone.detail.roadblock.detail': 'Détail du blocage',
  'onetoone.detail.roadblock.noitem': 'Pas encore de blocage ',
  'onetoone.detail.roadblock.create.success': 'Blocage créé avec succès',
  'onetoone.detail.mission.action': 'Missions et actions',
  'onetoone.detail.mission.add.new': 'Ajouter une nouvelle mission',
  'onetoone.detail.mission.update': 'Mettre à jour la mission',
  'onetoone.detail.mission.update.success': 'Mise à jour de la mission avec succès',
  'onetoone.detail.mission.create.success': 'Création de la mission avec succès ',
  'onetoone.detail.mission.noitem': 'Pas encore de mission',
  'onetoone.detail.mission.estimate': 'Estimation',
  'onetoone.detail.mission.deadline': 'Date limite',
  'onetoone.detail.mission.delete.confirm': 'Voulez-vous supprimer cette mission ?',
  'onetoone.detail.mission.delete': 'Supprimer la mission',
  'onetoone.detail.mission.delete.success': 'Suppression de la mission avec succès',
  'onetoone.detail.mission.give.title': 'Donnez un titre à votre mission',
  'onetoone.detail.mission.level': 'niveau',
  'onetoone.detail.mission.estimate.date': "Date d'estimation",
  'onetoone.detail.mission.status': 'Status',
  'onetoone.detail.missions': 'Missions',
  'onetoone.detail.actions': 'Actions',
  'onetoone.superior.manager': 'Supérieur',

  'onetoone.roadblock.delete': 'Roadblock supprimé avec succès',
  'onetoone.detail.feedback.add.new': 'Nouveau feedback',
  'onetoone.detail.feedback.sent.noitem': 'Aucun commentaire ne vous a été envoyé',
  'onetoone.detail.feedback.sent': 'Envoyé',
  'onetoone.detail.feedback.received.noitem': 'Aucun commentaire reçu pour le moment',
  'onetoone.detail.feedback.received': 'Reçu',
  'onetoone.detail.feedback': 'Retour',
  'onetoone.detail.feedback.sent.have': 'Vous avez envoyé un commentaire à :',
  'onetoone.detail.feedback.received.have': 'Vous avez reçu des commentaires de :',

  'onetoone.button.tooltip.title.archive':
    'Ici vous pouvez trouver tous les tableaux de bord de 1to1 que vous enregistrez',

  'onetoone.detail.feedback.response.success': 'Votre réponse a été envoyée avec succès',
  'onetoone.detail.feedback.response.error': 'Erreur lors de la réponse au feedback',
  'onetoone.detail.feedback.response': 'Réponse du feedback',
  'onetoone.detail.feedback.sent.feedback': 'Feedback envoyé',
  'onetoone.detail.feedback.received.feedback': 'Feedback reçu',
};
export default onetoone;
