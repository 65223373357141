const businessunit: Record<string, string> = {
  'business-unit.search.no.result': 'No business unit found for your search',
  'business-unit.search.for': 'Search for business unit',
  'business-unit.no.item': 'No business unit to show...',
  'business-unit.label.add.new': 'Create a new business unit',
  'business-unit.delete.label': 'Delete business unit',
  'business-unit.remove-member.label': 'Remove business unit member',
  'business-unit.label.update': 'Edit business unit',
  'business-unit.label.add.member': 'Add business unit member',
  'business-unit.label.indicators': 'Business unit indicators',
  'business-unit.label.members': 'Business unit members',
  'business-unit.no.specified': 'No Business Unit specified',
  'business-unit.label.delete-image': 'Delete business unit image',
  'business-unit.label.image': 'Business unit image',

  'business-unit.label.excedeed.member': 'This business unit can have at most 15 members',
  'business-unit.no.indicator':
    'You can see your business unit KPI here, but you need to {create} new one first',
  'business-unit.no.images.start-label':
    'There is no image in your repository, you can drop it here or ',
  'business-unit.no.images.end-label': 'from your computer',
};
export default businessunit;
