const dialog: Record<string, string> = {
  'coin.history': 'Historique des coins',
  'coin.type.credited': 'crédité',
  'coin.type.debited': 'débité',
  'coin.history.title': 'Vous avez été crédité de {coin} coin(s) de la part de',
  'coin.from': 'coin(s) de la part de',
  'coin.for': 'coin(s) pour',
};

export default dialog;
