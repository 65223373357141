const user: Record<string, string> = {
  'user.update.success': "Mise à jour de l'utilisateur avec succès",
  'error.update.user': "Erreur lors de la mise à jour de l'utilisateur",
  'user.update.motto.success': 'Devise mise à jour avec succès',
  'user.update.motto.error': 'Échec de la mise à jour de la devise',
  'error.create.motto': 'Échec de la création de la devise',
  'user.picture.delete.successfully': 'Image supprimée avec succès',
  'user.delete.presentation.video': 'Pvidéo de présentation supprimée avec succès',
  'user.change.supervisor.success': 'Superviseur mis à jour avec succès',
  'user.label.see.company.structure': "Voir la structure de l'entreprise",
  'user.hierarchy.download.label': 'Télécharger au format JPG',
  'user.hierarchy.company.structure.label': "Structure de l'entreprise :",
  'user.double.click.to.see.more': 'Double-cliquez pour en savoir plus sur {name}',
  'user.hiring.date': "Date d'embauche",
  'user.label.user.access': 'Accès utilisateur ',
  'user.label.user.role': " Rôle de l'utilisateur",
  'user.already.signed.up': 'Vous êtes déjà inscrit. Connectez-vous à votre compte pour continuer',
  'user.personal.information': 'Informations personnelles',
  'user.detail.information': 'Détails des informations',
  'user.select.collaborator': 'Sélectionnez un collaborateur',

  'user.button.tootlip.title.company.structure': "Vous trouverez ici l'organigramme de votre BU",

  //invitation
  'user.label.email.invitation.sent': 'Invitation envoyée avec succès !',
  'user.label.invitation.not.yet.accepted':
    "Invitation pas encore acceptée, renvoyer l'invitation ?",
  'error.label.invite.user': "Une erreur s'est produite lors de l'envoie d'invitation",

  'user.label.people.found': '{total} personnes correspond à la recherche',
  'user.label.company.structure': "Structure de l'entreprise",

  'user.event': 'Événement utilisateur',
};

export default user;
