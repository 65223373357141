import { gql } from '@apollo/client';

export const USER_THANK_FRAGMENT = gql`
  fragment UserThankInfo on UserThank {
    id
    content
    response
    alreadySeen
    score
    createdAt
    companyMemberSenderId
  }
`;
