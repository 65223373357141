const process: Record<string, string> = {
  'process.team.in.charge': 'Équipe en charge',
  'process.team.concerned': 'Équipe impactée',
  'process.team.in.expert': 'Équipe experte',
  'process.delete.description': 'Supprimer le Process',
  'process.delete.success': 'Processus supprimé avec succès',
  'process.label.add': 'Ajouter un processus',
  'process.label.create': 'Créer un processus',
  'process.search.no.result': 'Aucun processus trouvé pour votre recherche',
  'process.search.for': 'Rechercher un processus',
  'process.no.item': 'Aucun processus à afficher...',
};
export default process;
