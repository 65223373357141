const userPreference: Record<string, string> = {
  'user.preference.update.active.hours.start.success':
    'Active hours start time updated with success',
  'user.preference.update.active.hours.end.success': 'Active hours end time updated with success',
  'user.preference.update.active.hours.start.error':
    'An error occured while updating active hours start time',
  'user.preference.update.active.hours.end.error':
    'An error occured while updating active hours end time',
  'user.preference.toogle.entry.tunnel.success': "Entry tunnel displayer's status is changed",
  'user.preference.toogle.active.hours.success': 'Active hours activation updated with success',
  'user.preference.toogle.active.hours.error':
    'An error occured while updating active hours activation',
  'user.preference.toogle.entry.tunnel.error':
    'An error occured while changing entry tunnel displayer status',
  'user.preference.toogle.exit.tunnel.success': "Exit tunnel displayer's status is changed",
  'user.preference.toogle.exit.tunnel.error': 'Exit on change entry tunnel displayer status',
  'user.preference.toogle.entry.tunnel.save.time.success': 'Entry tunnel time setted with success',
  'user.preference.toogle.entry.tunnel.save.time.error':
    'An error occured while setting entry tunnel time',
  'user.preference.toogle.exit.tunnel.save.time.success': 'Exit tunnel time setted with success',
  'user.preference.toogle.exit.tunnel.save.time.error':
    'An error occured while setting entry tunnel time',
  'user.preference.select.language': 'Select a language',
  'user.preference.langage.updated.success': 'Language updated with success',
  'user.preference.langage.updated.error': 'An error occured while updating language',
};
export default userPreference;
