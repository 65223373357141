const project: Record<string, string> = {
  // finalized
  'project.finalized.successfully': 'Projet finalisé avec succès',
  'project.finalized.error': "Une erreur s'est produite lors de la finalisation du projet",

  // status
  'project.update.status.successfully': 'Statut du projet mis à jour avec succès',
  'project.update.status.error':
    "Une erreur s'est produite lors de la mise à jour de l'état du projet",

  // deletion
  'project.delete': 'Supprimer ce projet',
  'project.delete.success': 'Projet supprimé avec succès',
  'project.delete.error': "Une erreur s'est produite lors de la suppression du projet",
};

export default project;
