export const PROJECT_NAME = 'wimtim';
export const TEAM_ISSUE_DETAIL = 'team-issue-detail';
export const USER_ALREADY_EXISTS_IN_COMPANY = 'User already exists in this company';
export const USER_ALREADY_INVITED_THIS_IN_COMPANY = 'User already invited for this company';

export const EMAIL_NOT_SEND = 'Email not send';
export const NO_CATEGORY = 'No category';
export const CHAT_MEMBER_LISTS_LIMIT = 100;
export const SENT = 'sent';
export const IDEA = 'IDEA';
export const INITIAL_IDEA_SLIDER_VALUE: [number, number] = [0, 5];
export const OPEN_USER_INFO = 'open_user_info';
export const OPEN_USER_DETAIL_DIALOG = 'user_detail_dialog';
export const OPEN_PERSONAL_USER_INFO_DIALOG = 'info_user_dialog';

export const COACH_APP_ID = 'wimtim';
export const LOGOUT_EVENT_ID = 'EV_00045';
export const LOGIN_NOT_DIRECTOR_EVENT_ID = 'EV_00001';
export const LOGIN_DIRECTOR_EVENT_ID = 'EV_00008';
export const USER_CREATE_INDICATOR_EVENT_ID = 'EV_00048';
export const USER_CLICK_ON_INDICATOR_EVENT_ID = 'EV_00051';
export const USER_ENTER_TEAMS_EVENT_ID = 'EV_00062';
export const USER_ENTER_1TO1_MENU_EVENT_ID = 'EV_00095';
export const USER_ENTER_1TO1_EVENT_ID = 'EV_00013';
export const USER_ENTER_HIVE_EVENT_ID = 'EV_00010';
export const USER_SENT_TENTH_FEEDBACK_EVENT_ID = 'EV_00057';
export const USER_SENT_FIFTEENTH_THANK_EVENT_ID = 'EV_00058';
export const SPONTANEOUS_EVENT_ID = 'EV_00080';
export const USER_HAS_BAD_WEATHER_EVENT_ID = 'EV_00047';
export const USER_ENTER_THE_MAP = 'EV_00074';
export const USER_SENT_FIFTH_IDEA = 'EV_00061';

export const OPEN_ACTION_DIALOG = 'open_action_dialog';
export const OPEN_ACTION_EDIT_DIALOG = 'open_action_edit_dialog';
export const OPEN_DELETE_ACTION_DIALOG = 'open_delete_action_dialog';
export const OPEN_DETAIL_ACTION_DIALOG = 'open_detail_action_dialog';
export const MY_ACTION = 'my-action';

export const CLICK_STEP_ZOOM_LEVEL = 40;
export const WHEEL_STEP_ZOOM_LEVEL = 200 / 15;
export const MIN_ZOOM_LEVEL = 100;
export const MAX_ZOOM_LEVEL = 300;

export const HOUR_MINUTE = 'hh:mm';
export const IS_VALID_RANGE_KEY = 'is-valid-range';
export const DATE_RANGE_REQUIRED = 'date-range-required';

export const OTHERID = 'other-id';
export const OTHER_REASON_LABEL = 'Other reason';

export const DEFAULT_START_TIME = '08:00';
export const DEFAULT_END_TIME = '18:00';
export const DEFAULT_ZERO_TIME = '00:00';

export const DEFAULT_MIN_HOUR = 8;
export const DEFAULT_MAX_HOUR = 18;
export const DEFAULT_ZERO_MINUTE = 0;

export const DEFAULT_MINUTE_DURATION = 60;

export const TEMP_ID = 'temp-id';

export const COMPANY_MEMBER_RESTORED_SUCCESSFULLY = 'Member has been restored';

export const FEEDBACK = 'FEEDBACK';
export const THANKS = 'THANKS';
export const REPLYTHANKS = 'REPLYTHANKS';
