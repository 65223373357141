const calendar: Record<string, string> = {
  'calendar.create.success': "Événement d'équipe créé avec succès",
  'calendar.update.success': "Événement d'équipe mis à jour avec succès",
  'calendar.delete.success': "Événement d'équipe supprimé avec succès",
  'calendar.meeting.delete.success': 'Réunion supprimée avec succès',
  'calendar.create.error': "Echec de la création de l'événement d'équipe",
  'calendar.update.error': "Echec de la mise à jour de l'événement d'équipe",
  'calendar.meeting.delete.error': 'Échec de la suppression de la réunion',
  'calendar.delete.error': "Echec de la suppression de l'événement d'équipe",
  'calendar.delete.confirm.message': "Supprimer l'événement d'équipe ?",

  'calendar.team-event-type.required': "Le type d'événement d'équipe est requis",

  'calendar.placeholder.title': "Donner un titre à l'événement",
  'calendar.drag.to.the.past.warning':
    "Pardon! Impossible de faire glisser l'événement vers le passé. ",
  'calendar.invalid.date': "Assurez-vous que l'événement se produise au plus tard aujourd'hui ",
  'calendar.drag.to.week.end.warning':
    "Pardon! Impossible d'organiser un événement ou une réunion le week-end ",
  'calendar.event.time.warning': "Assurez-vous que l'heure est entre 8h00 et 18h00 ",
  'calendar.create.on.week.end.warning':
    "Pardon! Impossible d'organiser un événement ou une réunion le week-end ",

  'calendar.leave.event.title': 'Congés',

  // location
  'calendar.location.label.delete': 'Supprimer la localisation',
  'calendar.location.create.label': 'Définissez votre localisation',
  'calendar.location.edit.label': 'Editez votre localisation',
  'calendar.label.set.location': 'Définir la localisation',
  'calendar.label.chose.your.location': 'Choisissez votre localisation',
  'calendar.label.add.location': 'Ajouter une localisation',
  'calendar.set.location.success': 'Localisation définit avec succès',
  'calendar.edit.location.success': 'Localisation modifiée avec succès',
  'calendar.location.details.label': 'Détails de la localisation',
  'calendar.location.filter.label': 'Filtrer par localisation',
  'calendar.delete.location.success': 'Localisation supprimée avec succès',

  // label
  'calendar.label.location': 'Localisation',
  'calendar.label.full.day': 'Journée entière',
  'calendar.label.half.day': 'Démi-journée',
  'calendar.label.half.day.leaves': 'Congés en démi-journées ?',
  'calendar.label.event.privacy': "Confidentialité de l'événement",
  'calendar.label.start.date': 'Date de début',
  'calendar.label.end.date': 'Date de fin',
  'calendar.label.start.date.time': 'Heure et date de début',
  'calendar.label.end.date.time': 'Heure et date de fin',
  'calendar.label.half.pm': 'Après-midi',
  'calendar.label.half.am': 'Matin',

  // leave
  'calendar.label.type.of.leave': 'Type de congé',
  'calendar.label.type.of.leave.description':
    'Vous pouvez choisir un type de congé entre "journée entière", "matin" ou "après-midi"',

  'calendar.label.event.title': "Titre de l'événement",
  'calendar.label.give.a.title.to.the.event': 'Donnez un titre à cet événement',
  'calendar.label.choose.event': "Choisisez l'événement que vous voulez",

  'event.privacy.public.description.text': "L'événement est visible par tous sur le calendrier",
  'event.privacy.private.description.text':
    "L'événement n'est visible que par les membres des équipes concernées et les collaborateurs sélectionnés",

  // event
  'calendar.label.event': 'Evénement',
  'calendar.label.event.details': "Details de l'événement",
  'calendar.label.more.items': "plus d'articles",
  'calendar.label.leave.period': 'Période de congé',
  'calendar.label.validation.date': 'Date de validation',
  'calendar.label.leave.validation': 'Période de congé ou Date de validation',

  'calendar.from.creator.timezone': 'Dépuis le fuseau horaire du créateur',
  'calendar.from.your.timezone': 'Dépuis votre fuseau horaire',
  'calendar.label.new.calendar.item': 'Nouvel élément du calendrier',
  'calendar.label.choose.calendar.item':
    'Choisissez un élément du calendrier que vous souhaitez ajouter à cette date',

  'calendar.find.dialog.title': 'Recherche dans le calendrier',
  'calendar.collaborators.who.are.on.leave': 'Collaborateurs en congé',

  'calendar.create.filter.success': 'Filtre créé avec succès',
  'calendar.update.filter.success': 'Filtre mis à jour avec succès',
  'calendar.delete.filter.success': 'Filtre supprimé avec succès',

  'calendar.no.leave': 'Aucun congé',
  'calendar.no.event': 'Aucun événement',
  'calendar.no.meeting': 'Aucune réunion',
  'calendar.no.location': 'Aucune location',

  'calendar.all.found.meetings': 'Tous les réunions trouvées',
  'calendar.all.found.events': 'Tous les événements trouvés',
  'calendar.all.found.locations': 'Tous les localisations trouvées',

  'calendar.month': 'Mois',
  'calendar.week': 'Semaine',
  'calendar.day': 'Jour',

  'calendar.localisation.homeOffice': 'A la maison',
  'calendar.localisation.outside': 'En déplacement',
  'calendar.localisation.travelling': 'En voyage',
  'calendar.localisation.office': 'Au bureau',
  'calendar.event.outside': "A l'extérieur",
  'calendar.event.client': 'Réunion client',
  'calendar.event.gouvernance': 'Gouvernance',
  'calendar.event.milestone': 'Jalon',
  'calendar.event.mission': 'Mission',
  'calendar.event.meeting': 'Réunion',
  'calendar.event.party': 'Fête / Anniversaire',

  'calendar.status.pending': 'En Attente',
  'calendar.status.rejected': 'Rejetée',
  'calendar.status.valide': 'Validée',
};

export default calendar;
