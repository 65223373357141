import { SetStateAction } from 'react';
import { IndicatorAction, IndicatorLevel, IndicatorTrend } from 'graphql/generated/graphql';

export * from './themeTypes';
export * from './refetchQueryType';

declare global {
  interface Window {
    Olvy: any;
  }
}

export type StateSetter<T> = (next: SetStateAction<T>) => void;
export type NonEmptyArray<T> = [T, ...T[]];

export type TPriorityLevel = 'low' | 'medium' | 'high';

export type TPostState = 'toDo' | 'inProgress' | 'done' | 'blocked';

export type TProjectState = 'toDo' | 'inProgress' | 'done';

// TODO to delete
export type TProcessState = 'draft' | 'validated';

export type TMissionState = 'toDo' | 'inProgress' | 'finished';

export type TDemandSolve = 'assign' | 'pending' | 'solvingInProgress' | 'solved';

export type TDemandImprove =
  | 'assign'
  | 'pending'
  | 'inProgress'
  | 'testSolution'
  | 'parked'
  | 'solved';

export type TState =
  | TPostState
  | TProjectState
  | TProcessState
  | TMissionState
  | TDemandSolve
  | TDemandImprove;

export type TIndicatorLevel = {
  type: 'Level';
  state: IndicatorLevel;
};

export type TIndicatorAction = {
  type: 'Action';
  state: IndicatorAction;
};

export type TIndicatorActionCount = {
  type: 'ActionCount';
  state: number;
};

export type TIndicatorTrend = {
  type: 'Trend';
  state: IndicatorTrend;
};

export type TIndicatorValue = {
  type: 'Value';
  state: string;
};

export type TIndicator =
  | TIndicatorLevel
  | TIndicatorAction
  | TIndicatorActionCount
  | TIndicatorTrend
  | TIndicatorValue;

export enum NavTab {
  HIVE = 'hive',
  ONE_TO_ONE = 'one-to-one',
  MAP = 'map',
  PEOPLE = 'people',
  TEAMS = 'teams',
  CALENDAR = 'calendar',
  RULES = 'rules',
  ISSUES = 'issues',
  ADMIN = 'admin',
  DATABASE = 'database',
}

export enum AdminTabs {
  COMPANY = 'company',
  TEAMS = 'teams',
  USERS = 'users',
  EMPLOYEE_OF_DAY = 'employee-of-day',
  HIVE = 'hive',
  TUNNELS = 'tunnels',
  COACH = 'coach',
  POINTS = 'points',
  SHOP = 'shop',
  BUSINESS_UNITS = 'business-units',
  SUBSCRIPTION = 'subscription',
  APPLICATION = 'application',
  CALENDAR = 'calendar',
  BADGES = 'badges',
}

export * from './post';
export * from './loadMore';

export type ArrayType<T> = T extends (infer Item)[] ? Item : never;

export enum SortOrder {
  DESC = 'desc',
  ASC = 'asc',
}

export enum TMutationCase {
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
}

export enum DatabaseUrlTabs {
  Action = 'action',
  Issue = 'issue',
}

// Enum for Moment.js base units
export enum TMomentBaseUnits {
  Year = 'year',
  Month = 'month',
  Day = 'day',
  Hour = 'hour',
  Minute = 'minute',
  Second = 'second',
  Millisecond = 'millisecond',
}
