const issue: Record<string, string> = {
  'issue.team.in.charge': 'Team in charge',
  'issue.team.concerned': 'Impacted Team',
  'issue.team.in.expert': 'Team in expert',
  'issue.demand.both': 'Improve/Solve',
  'issue.demand.improve': 'Improve',
  'issue.demand.improve.show.a3': 'Improve: show A3',
  'issue.demand.solve': 'Solve',
  'issue.demand.solve.show.issue': 'Solve: show issue',
  'issue.delete.description': 'Delete Issue',
  'issue.delete.success': 'Issue deleted succefully',
  'issue.label.add': 'Add an issue',
  'issue.label.create': 'Create an issue',
  'issue.search.no.result': 'Search issue no result',
  'issue.search.for': 'Search for issues',
  'issue.handle.dialog.title': 'Hanlde solve/imporve',
  'issue.handle.dialog.radio.button.label': 'Choose between: ',
  'issue.no.item': 'No issue to show...',
  'issue.label.click.on.demand': '*Please, click on one of the demand type below',
  'issue.label.issue.privacy': 'Issue Privacy',
  'issue.label.issue.privacy.public': 'External Issue',
  'issue.label.issue.privacy.private': 'Team Issue',
  'issue.comment.public.description.text':
    'Everyone in the company can see these comments if the issue is public',
  'issue.public.description.text':
    'Issue is visible to member for the impacted Team and experts of the issue',
  'issue.private.description.text':
    'Issue will only visible in the team dashboard and only by the team in charge members',
  'issue.comment.private.description.text':
    'Only members of the team in charge can see these comments',
};
export default issue;
