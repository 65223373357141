import { LocalCreationActionType } from 'graphql/generated/graphql';

export const getActionTypeName = (localCreationType: LocalCreationActionType) => {
  let name = 'common.label.action.team';
  switch (localCreationType) {
    case LocalCreationActionType.TeamAction:
      name = 'common.label.action.team';
      break;
    case LocalCreationActionType.ProjectAction:
      name = 'common.label.project.team';
      break;
    case LocalCreationActionType.IdeaAction:
      name = 'common.label.action.idea';
      break;
    case LocalCreationActionType.IssueAction:
      name = 'common.label.issue.team';
      break;
    case LocalCreationActionType.OneToOneAction:
      name = 'common.label.one.to.one.team';
      break;

    case LocalCreationActionType.PureAction:
      name = 'common.label.pure.action.team';
      break;
    case LocalCreationActionType.RoadBlockAction:
      name = 'common.label.road.load.team';
      break;
    case LocalCreationActionType.IndicatorAction:
      name = 'common.label.indication.team';
      break;
    default:
      break;
  }

  return name;
};
