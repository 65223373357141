const chat: Record<string, string> = {
  'chat.label.no.channel': "Vous n'avez pas encore de message de groupe",
  'chat.label.no.direct.message': "Vous n'avez pas encore de message direct",
  'chat.label.leave.channel': 'Quitter le canal',
  'chat.label.cannot.discuss': 'Vous ne pouvez pas discuter avec un collègue supprimé',
  'chat.label.warning.delete_user': 'Attention : Tous vos collègues sont supprimés',

  // Labels
  'chat.label.group': 'Discussion de groupe',
  'chat.label.group.create': 'Créer un discussion de groupe',
  'chat.label.group.update': 'Modifier un discussion de groupe',
  'chat.label.direct.message': 'Messages directs',
  'chat.label.direct.message.create': 'Créer un message direct',
  'chat.label.create.group': 'Créer une discussion de groupe',
  'chat.label.update.group': 'Mettre à jour la discussion de groupe',
  'chat.label.delete.channel': 'Supprimer une discussion',
  'chat.label.leave.channel.description':
    'Êtes-vous sûr de vouloir quitter ce groupe de discussion ?',
  'chat.channel.name.change': 'Nom de la discussion changé en {channelName}',
  'chat.channel.user.joined': '{user} a rejoint la discussion',
  'chat.channel.user.removed': '{user} a été retiré de la discussion',
  'chat.label.you_and_others': 'Vous et {count} autres',
  'chat.label.from_team': 'Depuis une équipe',
  'chat.label.custom': 'Personnalisé',
  'chat.label.deleted_user': '(utilisateur supprimé)',

  // Placeholder
  'chat.placeholder.group.chat.name': 'Nom de la conversation de groupe',
  'chat.placeholder.send.a.message': 'Envoyer un message...',
  'chat.placeholder.rename.channel': 'Le nom de votre discussion...',

  'chat.a.user.is.typing': '{user} est en train de taper...',
  'chat.two.users.are.typing': '{user1} et {user2} sont en train de taper...',
  'chat.more.users.are.typing': "{user} et {remainUsersLength} d'autres tapent...",

  // Errors
  'chat.session.exired':
    "Quelque chose s'est mal passé à propos de votre compte, veuillez contacter votre administrateur",
  'chat.create.channel.error': 'Echec de la création du canal',
  'chat.create.dm.error': 'Echec de la création du message direct',
  'chat.delete.channel.error': 'Echec de la suppression du canal',
  'chat.leave.channel.error': 'Impossible de quitter le canal',
  'chat.loading.channel.error':
    'Erreur lors du chargement des conversations, veuillez réessayer momentanément',
};
export default chat;
