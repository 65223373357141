import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import useStyles from './styles';

const GeneralLoading = () => {
  const classes = useStyles();

  return (
    <Grid
      id="general-loading"
      container
      justifyContent="center"
      alignContent="center"
      className={classes.root}
      flexDirection="column"
    >
      <CircularProgress size={50} />
      <Typography variant="body1" className={classes.text}>
        <FormattedMessage id="common.label.loading" />
      </Typography>
    </Grid>
  );
};

export default GeneralLoading;
