import { Buffer } from 'buffer';

const SIGN_IN_OPERATION_NAME = 'SignIn';

export const shouldEncode = (url: RequestInfo, options: RequestInit) => {
  if (process.env.NODE_ENV === 'development') return false;
  if (options.method && options.method !== 'POST') return false;
  if (typeof url === 'string' && !url.endsWith('/graphql')) return false;
  if (options?.body) {
    let jsonBody = JSON.parse(options.body.toString());
    if (jsonBody.operationName !== SIGN_IN_OPERATION_NAME) return false;
  }
  return true;
};

export const encodeTextBody = (text: string) => {
  let buffer = new Uint8Array(new TextEncoder().encode(text));
  const encodedText = Buffer.from(buffer).toString('base64');
  return encodedText;
};

const PASS_CODE_MIN = 100000;
const PASS_CODE_MAX = 900000;
export const generatePasscode = () =>
  Math.floor(PASS_CODE_MIN + Math.random() * PASS_CODE_MAX).toString();
