import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: {
    height: '100vh',
    background: theme.palette.background.default,
  },

  text: {
    marginTop: theme.spacing(1.6),
    color: theme.palette.common.white,
  },
}));
