const ruche: Record<string, string> = {
  'ruche.label.header.message': 'Provide the right product at the right time to the lab',
  'ruche.label.my.coins': 'My coins',

  'ruche.idea.report.message.success': 'Your report was sent successfully',

  'ruche.idea.label.report': 'Report',
  'ruche.idea.label.select.reason': 'Please select a reason',

  'ruche.idea.reason.inappropriate.content': 'Inappropriate content',
  'ruche.idea.reason.harassment.or.intimidation': 'Harassment or intimidation',
  'ruche.idea.reason.undesirable.or.harmful': 'Undesirable or harmful',
  'ruche.idea.reason.other': 'Other',

  'ruche.idea.rating.star.details': 'Star rating details',
  'ruche.idea.label.my.rating': 'My Rating',
  'ruche.idea.label.rating': 'rating',
  'ruche.idea.label.rating.value': '{ratingValue} rating',
  'ruche.problem.label.add.a.problem': 'Add a issue',
  'ruche.problem.label.edit.problem': 'Edit issue',

  'ruche.problem.placeholder.title': 'Give a title to your issue',
  'ruche.problem.placeholder.content': 'Describe your issue',

  'ruche.feedback.content': 'Feedback content',
  'ruche.feedback.description':
    'What is your feedback? Remember the two key of a good feedback: a positive intent with advice for the future.',
  'ruche.feedback.content.reply': 'Type something to reply',
  'ruche.send.feedback': 'Send feedback',
  'ruche.send.feedback.success': 'Send feedback success',
  'ruche.send.feedback.error': 'Send feedback failed',
  'ruche.reply.feedback.success': 'Reply feedback success',
  'ruche.send.feedback.to': 'Send feedback to {name}',
  'ruche.received.feedback': 'You received a feedback',

  'ruche.thanks.content': 'Thanks content',
  'ruche.thanks.score.number.received': 'Number of score received',
  'ruche.send.thanks': 'Send thanks',
  'ruche.received.thanks': 'You received a thanks',
  'ruche.received.thank.you': 'Thank you',
  'ruche.send.thanks.success': 'Send thanks success',
  'ruche.send.thanks.error': 'Error on sending thanks!',
  'ruche.send.thanks.to': 'Send a thanks to {name}',
  'ruche.chat.with': 'Chat with {name}',
  'ruche.thanks.points': 'Points',
  'ruche.thanks.not.have.point': 'You have already earned points today',
  'ruche.notification.no.item.yet': 'No notification yet',
  'ruche.search.no.result': 'No result',
  'ruche.thanks.response.field': 'Response field',
  'ruche.response.thank.success': 'Thanks response  success',
  'ruche.response.thank.you': 'Thank you response!',
  'ruche.response.thank.description': '{name} sent a response to your thank you',
  'ruche.response.feedback.success': 'Response feedback success',
  'ruche.response.feedback.title': 'Feedback response!',
  'ruche.response.feedback.description': '{name} sent you a response to your feedback',

  'ruche.rating.period.end.indicator': 'The rating period of this idea ends on: {date}',

  //tooltip title
  'ruche.tooltip.title.coworker.list':
    'Thanks to this list, you can chat with a coworker, send him a thank or a feedback',
  'ruche.tooltip.title.chat': 'Chat with collegues',
  'ruche.tooltip.title.notification': 'Notification',

  'ruche.label.switch-beetween-indicator': 'Switch between your Teams, Company, and BusinessUnit',
  'ruche.label.switch-beetween-company': 'Switch between your companies',

  //Ruche tabs tooltip title
  'ruche.tabs.tooltip.title.idea': 'Here, you can share ideas anonymously to your colleagues',
  'ruche.tabs.tooltip.title.issue':
    'Here, you can share issues you have faced to your colleagues. It is important to detail in depth the issue in order to better analyse it',
  'ruche.tabs.tooltip.title.what.is.up':
    'Here you can share some professional and personal news to your colleagues',
  'ruche.tabs.tooltip.title.advice': 'Here, you can share your precious advices to your colleagues',
  'ruche.tabs.tooltip.title.have.fun': 'Here you can share some funny things to your colleagues',
  'ruche.tabs.tooltip.title.process':
    'Here you can share a process with your colleagues. They can then use it as a guideline to make things done',
  'ruche.tabs.tooltip.title.question':
    'Here you can ask or search a question to your colleagues. You can also write an answer by clicking on a questions',
  'ruche.call.with': 'Call with {name}',
  'ruche.call.created.successfuly': 'Call created successfuly',
};
export default ruche;
