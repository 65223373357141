import { CompanyMember, Maybe, User, UserCommunity } from 'graphql/generated/graphql';

export const getFullName = (...args: [Maybe<string>, Maybe<string>]) => {
  return args.join(' ').trim();
};

export const getFullNameByDataModel = <T extends User | CompanyMember | UserCommunity | undefined>(
  args: T,
) => {
  if (args) {
    if (args.__typename === 'User') return getFullName(args.firstName, args.lastName);

    if (args.__typename === 'CompanyMember' || args.__typename === 'UserCommunity')
      return getFullName(args.user?.firstName, args.user?.lastName);
  }
};
