import React, { Suspense, lazy, useContext } from 'react';
import { ReactFlowProvider } from 'react-flow-renderer';
import { IntlProvider } from 'react-intl';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import AdminSkeleton from './components/scenes/Admin/AdminSkeleton';
import CustomSnackBar from './components/common/CustomSnackBar';
import CompanyLayout from './components/scenes/layouts/CompanyLayout';
import GeneralLoading from './components/scenes/GeneralLoading';
import PrivateRoute from './components/scenes/PrivateRoute';
import ProtectedRoute from './components/scenes/ProtectedRoute';

import { ActionProvider } from './contexts/ActionContext';
import { CommunityIdContextProvider } from './contexts/CommunityIdContext';
import { PostVariablesContextProvider } from './contexts/PostVariablesContext';
import { ProcessProvider } from './contexts/ProcessContext';
import { HierarchyProvider } from './contexts/HierarchyContext';
import { MeetingDialogProvider } from './contexts/MeetingDialogContext';
import { IdsProvider } from './contexts/IdsContext';
import { CalendarProvider } from './contexts/CalendarContext/CalendarContext';
import { LocaleContextProvider } from './contexts/LocaleContext';
import { CalendarNavigationProvider } from './contexts/CalendarNavigationContext';
import UserPreferenceProvider from './contexts/UserPreferenceContext';
import { PeoplesProvider } from './contexts/PeoplesContext';

import { AppContext } from './contexts/AppContext';

import { SITE_MAP } from './constant';
import messages from './locales/messages';
import theme from '../src/theme';

const Login = lazy(() => import('./components/scenes/Login'));
const Ruche = lazy(() => import('./components/scenes/Ruche'));
const WelcomeScreen = lazy(() => import('./components/scenes/WelcomeScreen'));
const TeamAdmin = lazy(() => import('./components/scenes/Admin/TeamAdmin'));
const BusinessUnit = lazy(() => import('./components/scenes/Admin/AdminView/BusinessUnit'));
const BusinessUnitDetail = lazy(
  () => import('./components/scenes/Admin/AdminView/BusinessUnitDetail'),
);
const Team = lazy(() => import('./components/scenes/Team'));
const People = lazy(() => import('./components/scenes/People'));
const Profile = lazy(() => import('./components/scenes/Profile'));
const Members = lazy(() => import('./components/scenes/Admin/Members'));
const Company = lazy(() => import('./components/scenes/Admin/Company/CompanyTab'));
const ExitTunnel = lazy(() => import('./components/scenes/ExitTunnel'));
const SignUp = lazy(() => import('./components/scenes/SignUp'));
const ForgotPassword = lazy(() => import('./components/scenes/ForgotPassword'));
const ResetPassword = lazy(() => import('./components/scenes/ResetPassword'));
const ClientLayout = lazy(() => import('./components/scenes/layouts/ClientLayout'));
const OneToOne = lazy(() => import('./components/scenes/OneToOne'));
const Shop = lazy(() => import('./components/scenes/Shop'));
const Calendar = lazy(() => import('./components/scenes/Calendar'));
const SwitchCompany = lazy(() => import('./components/scenes/SwitchCompany'));
const OneToOneDetail = lazy(() => import('./components/scenes/OneToOneDetail'));
const Admin = lazy(() => import('./components/scenes/Admin'));
const A3 = lazy(() => import('./components/scenes/A3'));
const CompanyHierarchy = lazy(() => import('./components/scenes/CompanyHierarchy'));
const Process = lazy(() => import('./components/scenes/Process'));
const EmployeeOfDay = lazy(() => import('./components/scenes/Admin/EmployeeOfDay'));
const Tunnels = lazy(() => import('./components/scenes/Admin/Tunnels'));
const HiveAdmin = lazy(() => import('./components/scenes/Admin/HiveAdmin'));
const UserPreference = lazy(() => import('./components/scenes/UserPreference'));
const Issues = lazy(() => import('./components/scenes/Issues'));
const Database = lazy(() => import('./components/scenes/Database'));
const ActionDatabase = lazy(() => import('./components/scenes/Database/ActionsDatabase'));
const ApplicationAdmin = lazy(() => import('./components/scenes/Admin/ApplicationAdmin'));
const CalendarAdmin = lazy(() => import('./components/scenes/Admin/CalendarAdmin'));
const BadgesAdmin = lazy(() => import('./components/scenes/Admin/BadgesAdmin'));

const EmptyCompany = lazy(() => import('./components/scenes/EmptyCompany'));

export interface IconeViewProps {
  logoUrl?: string;
  text?: string;
}

//TODO: Remove this comment if you see it now
const App = () => {
  const { browserLocale } = useContext(AppContext);

  return (
    <>
      {/* Inlt provider for the page */}
      <IntlProvider locale={browserLocale} messages={messages[browserLocale]}>
        <ThemeProvider theme={theme}>
          <CustomSnackBar />
          <Router>
            <Suspense fallback={<GeneralLoading />}>
              <Routes>
                <Route path={SITE_MAP.Login.path} element={<Login />} />
                <Route path={SITE_MAP.SignUp.path} element={<SignUp />} />
                <Route path={SITE_MAP.ForgotPassword.path} element={<ForgotPassword />} />
                <Route path={SITE_MAP.ResetPassword.path} element={<ResetPassword />} />
                <Route
                  path={SITE_MAP.Client.path}
                  element={
                    <LocaleContextProvider>
                      <CalendarNavigationProvider>
                        <CalendarProvider>
                          <PrivateRoute>
                            <ClientLayout />
                          </PrivateRoute>
                        </CalendarProvider>
                      </CalendarNavigationProvider>
                    </LocaleContextProvider>
                  }
                >
                  <Route
                    path={SITE_MAP.Client.sub.SwitchCompany.path}
                    element={<SwitchCompany />}
                  />
                  <Route path={SITE_MAP.Client.sub.NoCompany.path} element={<EmptyCompany />} />
                  <Route
                    path={SITE_MAP.Client.sub.CompanyLayout.path}
                    element={
                      <PeoplesProvider>
                        <UserPreferenceProvider>
                          <CompanyLayout />
                        </UserPreferenceProvider>
                      </PeoplesProvider>
                    }
                  >
                    <Route
                      path={SITE_MAP.Client.sub.CompanyLayout.sub.WelcomScreen.path}
                      element={<WelcomeScreen />}
                    />
                    <Route
                      path={SITE_MAP.Client.sub.CompanyLayout.sub.Ruche.path}
                      element={
                        <ActionProvider>
                          <Ruche />
                        </ActionProvider>
                      }
                    />
                    <Route path={SITE_MAP.Client.sub.CompanyLayout.sub.OneToOne.path}>
                      <Route
                        index
                        element={
                          <MeetingDialogProvider>
                            <OneToOne />
                          </MeetingDialogProvider>
                        }
                      />
                      <Route
                        path={
                          SITE_MAP.Client.sub.CompanyLayout.sub.OneToOne.sub.OneToOneDetail.path
                        }
                        element={
                          <ActionProvider>
                            <MeetingDialogProvider>
                              <OneToOneDetail />
                            </MeetingDialogProvider>
                          </ActionProvider>
                        }
                      />
                    </Route>
                    <Route path={SITE_MAP.Client.sub.CompanyLayout.sub.People.path}>
                      <Route index element={<People />} />
                      <Route
                        path={
                          SITE_MAP.Client.sub.CompanyLayout.sub.People.sub.CompanyHierarchy.path
                        }
                        element={
                          <ReactFlowProvider>
                            <HierarchyProvider>
                              <CompanyHierarchy />
                            </HierarchyProvider>
                          </ReactFlowProvider>
                        }
                      />
                    </Route>
                    <Route path={SITE_MAP.Client.sub.CompanyLayout.sub.Teams.path}>
                      <Route
                        index
                        element={
                          <CommunityIdContextProvider>
                            <IdsProvider>
                              <PostVariablesContextProvider>
                                <MeetingDialogProvider>
                                  <Team />
                                </MeetingDialogProvider>
                              </PostVariablesContextProvider>
                            </IdsProvider>
                          </CommunityIdContextProvider>
                        }
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Teams.sub.A3.path}
                        element={<A3 />}
                      />
                    </Route>
                    <Route
                      path={SITE_MAP.Client.sub.CompanyLayout.sub.Calendar.path}
                      element={<Calendar />}
                    />
                    <Route
                      path={SITE_MAP.Client.sub.CompanyLayout.sub.Rules.path}
                      element={
                        <ProcessProvider>
                          <Process />
                        </ProcessProvider>
                      }
                    />
                    <Route
                      path={SITE_MAP.Client.sub.CompanyLayout.sub.Database.path}
                      element={<Database />}
                    >
                      <Route index element={<ActionDatabase />} />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Database.sub.Issue.path}
                        element={<Issues />}
                      />
                    </Route>
                    <Route
                      path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.path}
                      element={
                        <ProtectedRoute>
                          <Suspense fallback={<AdminSkeleton />}>
                            <Admin />
                          </Suspense>
                        </ProtectedRoute>
                      }
                    >
                      <Route
                        index
                        element={
                          <Navigate
                            to={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Company.path}
                            replace
                          />
                        }
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Company.path}
                        element={<Company />}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.TeamAdmin.path}
                        element={<TeamAdmin />}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Members.path}
                        element={<Members />}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.EmployeeOfDay.path}
                        element={<EmployeeOfDay />}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Shop.path}
                        element={<div>Shop</div>}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Hive.path}
                        element={<HiveAdmin />}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Tunnels.path}
                        element={<Tunnels />}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Coach.path}
                        element={<div>Coach</div>}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Points.path}
                        element={<div>Points</div>}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.BusinessUnits.path}
                        element={<BusinessUnit />}
                      >
                        <Route
                          path={
                            SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.BusinessUnits.sub
                              .BusinessUnitDetail.path
                          }
                          element={<BusinessUnitDetail />}
                        />
                      </Route>

                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Subscription.path}
                        element={<div>Subscription</div>}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Application.path}
                        element={<ApplicationAdmin />}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Calendar.path}
                        element={<CalendarAdmin />}
                      />
                      <Route
                        path={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Badges.path}
                        element={<BadgesAdmin />}
                      />
                      <Route
                        path="*"
                        element={
                          <Navigate
                            to={SITE_MAP.Client.sub.CompanyLayout.sub.Admin.sub.Company.path}
                            replace
                          />
                        }
                      />
                    </Route>
                    <Route
                      path={SITE_MAP.Client.sub.CompanyLayout.sub.ExitTunnel.path}
                      element={<ExitTunnel />}
                    />
                    <Route
                      path={SITE_MAP.Client.sub.CompanyLayout.sub.Shop.path}
                      element={<Shop />}
                    />
                    <Route
                      path={SITE_MAP.Client.sub.CompanyLayout.sub.Profile.path}
                      element={<Profile />}
                    />
                    <Route
                      path={SITE_MAP.Client.sub.CompanyLayout.sub.UserPreference.path}
                      element={<UserPreference />}
                    />
                    <Route
                      path="*"
                      element={
                        <Navigate to={SITE_MAP.Client.sub.CompanyLayout.sub.Ruche.path} replace />
                      }
                    />
                  </Route>
                </Route>
              </Routes>
            </Suspense>
          </Router>
        </ThemeProvider>
      </IntlProvider>
    </>
  );
};

export default App;
