import {
  Demand_Improve_Status,
  Demand_Solve_Status,
  EmployeeOfDayStatus,
  LeaveStatus,
  MissionProgressStatus,
  Post_Status,
  Process_Status,
  ProjectStatus,
} from 'graphql/generated/graphql';
import { TStatus } from './types';

export const statuses = {
  ...Post_Status,
  ...Demand_Solve_Status,
  ...Demand_Improve_Status,
  ...Process_Status,
  ...ProjectStatus,
  ...MissionProgressStatus,
  ...EmployeeOfDayStatus,
  ...LeaveStatus,
};

export const statusStateLabel: Record<TStatus, string> = {
  [statuses.ToDo]: 'common.label.to.do',
  [statuses.Todo]: 'common.label.to.do',
  [statuses.InProgress]: 'common.label.in.progress',
  [statuses.Blocked]: 'common.label.blocked',
  [statuses.Done]: 'common.label.done',
  [statuses.Assign]: 'common.label.assign',
  [statuses.Pending]: 'common.label.pending',
  [statuses.SolvingInProgress]: 'common.label.solving.in.progress',
  [statuses.TestSolution]: 'common.label.test.solution',
  [statuses.Parked]: 'common.label.parked',
  [statuses.Solved]: 'common.label.solved',
  [statuses.Finished]: 'common.label.finished',
  [statuses.Draft]: 'common.label.draft',
  [statuses.Validated]: 'common.label.validated',
  [statuses.Waiting]: 'common.label.waiting',
  [statuses.Accepted]: 'common.label.accepted',
  [statuses.Decline]: 'common.label.decline',
  [statuses.Rejected]: 'common.label.rejected',
};
