const post: Record<string, string> = {
  'idea.create.success': 'Idea created successfully',
  'idea.button.publish': 'Publish ',
  'idea.add.title': 'Add an Idea',
  'idea.create.title': 'Create an Idea',
  'idea.label.anonymity': 'Anonymity',
  'idea.label.post.anonymously': 'Post anonymously',
  'advice.create.success': 'Advice created successfully',
  'advice.add.title': 'Add an Advice',
  'process.create.success': 'Process created successfully',
  'whatsup.create.success': "What's up created successfully",
  'whatsup.update.success': "What's up updated successfully",
  'process.update.success': 'Process updated successfully',
  'issue.creator.label': 'Issue by {creator}',
  'issue.issue-by.label': 'Issue by',

  'post.convert.into.project': 'Convert into a project',
  'post.problem.dialog.title': 'Issue detail',
  'post.process.dialog.title': 'Process detail',
  'post.files.upload.success': 'Files uploaded successfully',
  'post.problem.create.success': 'Issue created successfully',
  'post.problem.create.error': 'An error occurred while creating issue',
  'post.problem.update.success': 'Issue updated successfully',
  'post.problem.update.error': 'An error occurred while updating issue',
  'post.files.upload.error': 'An error occurred while uploading file',
  'post.process.create.error': 'An error occurred while creating process',
  'post.process.update.error': 'An error occurred while updating process',
  'post.whatsup.create.error': "An error occurred while creating what's up",
  'post.whatsup.update.error': "An error occurred while updating what's up",
  'post.share.success': 'Post shared successfully',
  'post.share.no.community': 'You have share this post to all communities',
  'post.delete.sucess': ' Post deleted with success!',
  'post.question.create.success': 'Questiwhile creatingd successfully',
  'post.question.create.error': 'An error occurred while creating question',
  'post.question.update.success': 'Question updated successfully',
  'post.question.update.error': 'An error occurred while updating question',
  'post.already.seen': 'Already seen',
  'post.not.yet.seen': 'Not yet seen',
  'post.is.reported': 'Reported',
  'post.idea.delete.it': 'Delete idea named "{name}"',
  'post.is.hidden': 'Hidden post',
  'post.is.displayed': 'Displayed post',
  'post.issue.finalized.successfully': 'Issue finalized successfully',
  'post.issue.finalized.error': 'An error occurred while finalizing issue',

  'problem.update.status.successfully': 'Issue status updated successfully',
  'problem.update.status.error': 'An error occurred while updating issue status',
  'problem.5w.label': 'Describe your issue with the 5W method',

  'post.issue.description': 'Issue Description',
  'post.issue.attachments': 'Issue Attachments',
  'post.issue.category.deadline': 'Issue category & deadline',
  'post.issue.owners': 'Issue Owners',

  'post.issue.create.notification.title': 'An issue was created!',
  'post.issue.create.notification.body': 'Your team {arg1} is concerned with an issue named {arg2}',
  'post.issue.expert.notification.body':
    'You have been selected as an expert for an issue. named {arg1}',
  'post.issue.concerned.notification.body':
    'Your team {arg1} is concerned with an issue named {arg2}',
};
export default post;
