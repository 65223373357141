export const setCookiewithExpirationDate = (name: string, value: string, hourExpire?: Date) => {
  let date = new Date();

  if(!hourExpire)
    date.setHours(23, 59, 59, 999); // Set the time to the end of the current day
  else
    date = hourExpire;
  const expires = '; expires=' + date.toUTCString(); // Convert the date to UTC format
  document.cookie = name + '=' + value + expires + '; path=/';
};
