import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: {
    widht: '100%',
    height: '100%',
    padding: theme.spacing(2),
    boxSizing: 'border-box',

    display: 'grid',
    gridTemplateColumns: 'min-content minmax(0, 1fr)',
    gridTemplateRows: 'min-content minmax(0, 1fr)',
    gridGap: theme.spacing(2),
    gridTemplateAreas: `
      "header ."
      "tabs view"
    `,
  },

  header: {
    color: theme.palette.common.white,
  },

  rightSection: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));
