export const TOKEN_EXPIRED_CODE = 'TOKEN_EXPIRED';
export const LEAVE_ALREADY_ASKED_CODE = 'LEAVE_ALREADY_ASKED';
export const LOCATION_ALREADY_SETTED_CODE = 'LOCATION_ALREADY_SETTED';
export const EVENT_ALREADY_SETTED_CODE = 'EVENT_ALREADY_SETTED';
export const PASSWORD_CONTAINS_NAME_CODE = 'PASSWORD_CONTAINS_NAME';
export const TOKEN_REVOKED_CODE = 'TOKEN_REVOKED';
export const PASSWORD_CONTAINS_DATE_CODE = 'PASSWORD_CONTAINS_DATE';

export const CODE_ERROR_MAPPINGS: Record<string, string> = {
  [TOKEN_EXPIRED_CODE]: 'error.token.expired',
  [TOKEN_REVOKED_CODE]: 'error.token.expired',
  [PASSWORD_CONTAINS_DATE_CODE]: 'error.password.contain.date',
  [PASSWORD_CONTAINS_NAME_CODE]: 'error.password.contain.name',
};
