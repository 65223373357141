const map: Record<string, string> = {
  'map.header.title':
    'Issues exchange between the {communityName} community with other communities',
  'map.bomb.icon.tooltip.title':
    'This represents the number of issues you share with this community',
  'map.emojievent.icon.tooltip.title':
    'This represents the number of issues shared with this community you solved',
  'map.tooltip.title.ranking':
    'This corresponds to the ranking of the different teams according to the point you earned',
};
export default map;
