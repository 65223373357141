import { gql } from '@apollo/client';

export const SIMPLE_COMMUNITY_FRAGMENT = gql`
  fragment SimpleCommunityInfo on Community {
    id
    name
    logo
    slug
    logoPublicUrl
  }
`;

export const COMMUNITY_FRAGMENT = gql`
  fragment Community on Community {
    id
    name
    slug
    logo
    logoPublicUrl
    mission
    rommance
  }
`;

export const COMMUNITY_EVENT_TYPE_FRAGMENT = gql`
  fragment CommunityEventTypeInfo on CommunityEventType {
    id
    title
    icon
    iconPublicUrl
  }
`;

export const MAP_COMMUNITY_FRAGMENT = gql`
  fragment MapCommunityInfo on Community {
    id
    name
    mission
    memberCount
    coins
  }
`;

export const SUB_ACTION_FRAGMENT = gql`
  fragment SubActionInfo on Action {
    id
    description
    isCompleted
    createdAt
  }
`;

export const COMMUNITY_MEMBERS_USER_FRAGMENT = gql`
  fragment CommunityMembersUserInfo on User {
    id
    fullName
    firstName
    lastName
    photoUrl
    photoPublicUrl
    email
  }
`;

export const ACTION_FRAGMENT = gql`
  fragment ActionInfo on Action {
    id
    title
    description
    targetDate
    actionType
    isCompleted
    completionDate
    isOverdue
    updatedAt
    createdAt
    createdBycompanyMemberId
    localCreationType
  }
`;
