const exit: Record<string, string> = {
  // Shared horizon
  'exit.label.our.shared.horizon': 'Our Shared Horizon',
  'exit.shared.horizon.description':
    'Inspire your colleagues by writing a word of the day for tomorrow',
  'exit.shared.horizon.placeholder': 'Our shared horizon...',
  'exit.shared.horizon.create.success': 'Our shared horizon created successfully',
  'exit.shared.horizon.update.success': 'Our shared horizon updated successfully',

  // Actions
  'exit.action.description': 'Check your unfinished actions',

  // Accumulated point
  'exit.label.accumulated.points': 'Accumulated points',
  'exit.label.today.s.achievements': "Today's achievements",

  // Feedback
  'exit.feedback.label': 'Send feedback to',

  // Issue
  'exit.issue.label': 'Do you have any unresolved issues?',

  // Idea
  'exit.idea.label': 'Have ideas that you forgot to write down?',

  // Accumulated point
  'exit.accumulated.point.no.content': 'No Point received today',

  // Badges
  'exit.badge.no.new.badges.collected': 'No new badges collected',

  //Thanks
  'exit.thanks.label': 'Send thanks to',
};

export default exit;
