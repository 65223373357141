import { stringOrDate, View } from 'react-big-calendar';

export type TCalendarNavigation = {
  date: stringOrDate;
  view: View;
};

// this enum correspond to type View given by react-big-calendar, use it to avoid give string literal value
export enum TCalendarView {
  Month = 'month',
  Week = 'week',
  Day = 'day',
}

export enum TCalendarAction {
  Click = 'click',
  Select = 'select',
}
