import React from 'react';
import ReactDOM from 'react-dom';
import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging/sw';
import { StyledEngineProvider } from '@mui/material/styles';

import { ApolloClientContextProvider } from './contexts/ApolloClientContext/ApolloClientContext.tsx';
import { AppContextProvider } from './contexts/AppContext';
import App from './App';

import reportWebVitals from './reportWebVitals';
import { firebaseConfig } from './config.ts';
import './index.css';

// set firebase
const firebaseApp = initializeApp(firebaseConfig);
getMessaging(firebaseApp);

ReactDOM.render(
  <React.StrictMode>
    <ApolloClientContextProvider>
      <StyledEngineProvider injectFirst>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </StyledEngineProvider>
    </ApolloClientContextProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
