import { makeStyles, createStyles } from '@mui/styles';

export default makeStyles((theme) =>
  createStyles({
    root: {
      width: 'unset',
      minHeight: 49,
      margin: '8px 20px 0px',
    },

    leftSection: { flex: 1 },

    rightSection: {
      [theme.breakpoints.down('xl')]: {
        columnGap: theme.spacing(1),
      },
    },
  }),
);
