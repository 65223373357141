import { gql } from '@apollo/client';

export const NEW_EARNED_EXP_EVENT = gql`
  subscription NewEarnedExpEvent {
    newEarnedExpEvent {
      id
      earnedBy {
        id
        exp
      }
      earnedByTeam {
        id
        exp
        coins
        expLevel {
          id
          name
          iconPublicUrl
        }
      }
      # if earnedBy, increment all my teams with communityExp each time new earnedExpEvent for the current member
      expEvent {
        id
        communityExp
      }
    }
  }
`;
