import * as XLSX from 'xlsx';
import { Community } from 'graphql/generated/graphql';

export enum TeamSheets {
  Members = 'Members',
  Issues = 'Issues',
  Goals = 'Goals',
  Actions = 'Actions',
}

export const handleDownloadReports = (team: Community | undefined) => {
  const MembersSheet = XLSX.utils.json_to_sheet(
    team?.members?.map((member) => ({
      firstName: member?.user?.firstName,
      lastName: member?.user?.lastName,
      email: member?.user?.email,
      role: member?.role,
    })) ?? [],
  );
  const GoalsSheet = XLSX.utils.json_to_sheet(
    team?.indicators?.map((indicator) => ({
      name: indicator?.name,
      value: indicator?.value,
      level: indicator?.level,
      description: indicator?.description,
      date: indicator?.date,
      trend: indicator?.trend,
      createdAt: indicator?.createdAt,
    })) ?? [],
  );

  const IssuesSheet = XLSX.utils.json_to_sheet(
    team?.problems?.map((problem) => ({
      title: problem?.title,
      who: problem?.who,
      what: problem?.what,
      how: problem?.how,
      where: problem?.where,
      when: problem?.when,
      impact: problem?.impact,
      category: problem?.problemCategory?.title,
      level: problem?.problemLevel,
      demandStatus: problem?.demand,
      teamConcerned: problem?.communities?.length,
      experts: problem?.experts?.length ?? problem?.companyMemberExperts?.length,
      createdAt: problem?.createdAt,
    })) ?? [],
  );

  const ActionsSheet = XLSX.utils.json_to_sheet(
    team?.actions?.map((action) => ({
      description: action?.description,
      targetDate: action?.targetDate,
      responsibles: action?.responsibles?.length,
      type: action?.actionType,
      createdAt: action?.createdAt,
    })) ?? [],
  );

  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, MembersSheet, TeamSheets.Members);
  XLSX.utils.book_append_sheet(workBook, GoalsSheet, TeamSheets.Goals);
  XLSX.utils.book_append_sheet(workBook, IssuesSheet, TeamSheets.Issues);
  XLSX.utils.book_append_sheet(workBook, ActionsSheet, TeamSheets.Actions);

  XLSX.writeFile(workBook, `${team?.name}-reports-${new Date()}.xls`);
};
