import { API_URL, SUBSCRIPTION_URL } from '../config';

import { ApolloClient, InMemoryCache, createHttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient as createClientWs } from 'graphql-ws';
import { encodeTextBody, shouldEncode } from './encode';
import { Query } from 'apollo/cacheResolvers';

function getHttpLink(token: string, company: string) {
  const customFetch = (uri: RequestInfo, options: RequestInit) => {
    let headers: HeadersInit & {
      authorization?: string;
      company?: string;
      'content-type'?: string;
      'Content-Transfer-Encoding'?: string;
    } = {
      ...options.headers,
    };

    if (token) headers['authorization'] = `Bearer ${token}`;
    // Include current company slug to the header
    if (company) headers['company'] = company;

    const isEncoding = shouldEncode(uri, options);

    if (isEncoding) {
      headers['content-type'] = 'text/plain; charset=UTF-8';
      headers['Content-Transfer-Encoding'] = 'base64';
      if (options.body)
        return fetch(uri, { ...options, headers, body: encodeTextBody(options.body.toString()) });
    }
    return fetch(uri, { ...options, headers });
  };

  return createHttpLink({
    uri: API_URL,
    fetch: customFetch,
  });
}

export const getCompanySlug = (): string => {
  if (typeof window !== 'undefined') {
    const currentUrl = window.location.href;
    const companySlug =
      currentUrl.split('/client/')?.length > 1 ? currentUrl.split('/client/')[1].split('/')[0] : '';
    return companySlug.split('?')[0];
  }
  return '';
};

export const getPath = (): string | undefined => {
  let path: string | undefined;
  if (typeof window !== 'undefined') {
    const splitedCurrentUrl = window.location.href.split('/client/')[1];
    if (splitedCurrentUrl?.length > 1) {
      /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const [_, ...rest] = splitedCurrentUrl.split('/');
      path = rest.join('/');
    }
  }

  return path;
};

const createClient = (token: string, slug?: string) => {
  const companySlug = slug ?? getCompanySlug();

  // Websocket client
  let activeSocket: WebSocket;
  let timedOut: NodeJS.Timeout;

  const createWsLink = (token: string, slug?: string) => {
    return new GraphQLWsLink(
      createClientWs({
        url: SUBSCRIPTION_URL,
        connectionParams: {
          authorization: token,
          company: slug,
        },
        retryAttempts: Infinity,
        shouldRetry: () => true,
        keepAlive: 10_000,
        on: {
          // on connect, store the socket
          connected: (socket) =>
            socket instanceof WebSocket ? (activeSocket = socket) : undefined,
          // handle custom ping and pong events to maintain the connection on failure
          ping: (received) => {
            if (!received)
              // sent ping but did not receive pong, close the connection
              timedOut = setTimeout(() => {
                if (activeSocket.readyState === WebSocket.OPEN)
                  activeSocket.close(4408, 'Request Timeout');
              }, 5_000); // wait 5 seconds for the pong and then close the connection
          },
          pong: (received) => {
            if (received) clearTimeout(timedOut); // pong is received, clear connection close timeout
          },
        },
      }),
    );
  };

  // Create a WebSocket link:
  let wsLink = createWsLink(token, companySlug);

  let httpLink = getHttpLink(token, companySlug);
  let splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    httpLink,
  );

  // Create ApolloClient instance
  const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({ typePolicies: { Query } }),
  });

  const disconnectWebSocket = () => {
    if (activeSocket && activeSocket.readyState === WebSocket.OPEN) {
      activeSocket.close(1000, 'Disconnect WebSocket'); // Normal closure
    }
  };

  const reconnectWebSocket = (newToken: string, newSlug?: string) => {
    // Close existing WebSocket connection
    disconnectWebSocket();

    wsLink = createWsLink(newToken, newSlug);

    // Update the splitLink with the new wsLink
    httpLink = getHttpLink(token, companySlug);
    splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpLink,
    );

    // Update Apollo Client's link
    client.setLink(splitLink);
  };

  // Return the ApolloClient instance along with the disconnect and reconnect methods
  return { client, disconnectWebSocket, reconnectWebSocket };
};

export default createClient;
