const eod: Record<string, string> = {
  // label
  'eod.label.employee.of.the.day.offer': 'Employee of the day offer',
  'eod.label.already.accepted': 'Already accepted',
  'eod.label.force': 'Force',

  // content
  'eod.content.inform.chosen.tomorrow.eod':
    'You have been chosen to be tomorrow’s “Employee of the day” - {date}, make sure to update your Profile because everyone will see it in the entry tunnel. If you decline you will be chosen again only after some time.',

  // success
  'eod.snackbar.success.accept': 'Employee of the day accepted successfully',
  'eod.snackbar.success.decline': 'Employee of the day declined successfully',

  // errors
  'eod.snackbar.error.accept': 'Error while accepting employee of the day',
  'eod.snackbar.error.decline': 'Error while declining employee of the day',
};
export default eod;
