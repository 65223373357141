const team: Record<string, string> = {
  'team.current.team': 'Current Team',
  'team.community.not_found': 'Team not found',
  'team.member.already.exist': 'Member already esixt',
  'team.member.added': 'New member added',
  'team.label.total.member': 'Total Member',
  'team.label.request.added': 'Request to be added',
  'team.label.add.a.new.indicator': 'Add a new indicator',
  'team.label.edit.indicator': 'Edit indicator',
  'team.label.delete.indicator': 'Delete indicator',

  'team.indicator.placeholder.name': "Indicator's name",
  'team.indicator.placeholder.value': 'Value',
  'team.indicator.placeholder.trend': 'Trend',
  'team.indicator.placeholder.niveau': 'Niveau',
  'team.indicator.placeholder.state': 'State',
  'team.indicator.placeholder.date': 'Date',
  'team.indicator.dialogue.title': 'Indicator Details',
  'team.community.delete.success': 'Team deleted successfully',
  'team.community.user.delete.success': 'Team member deleted successfully',
  'team.member.role.update.success': 'Team member role updated successfully',
  'team.community.update.success': 'Team update with success',
  'team.community.create.success': 'Team created successfully',
  'team.indicator.created.successfully': 'Indicator created successfully',
  'team.indicator.updated.successfully': 'Indicator updated successfully',
  'team.indicator.deleted.successfully': 'Indicator deleted successfully',
  'team.action.add.new': 'Add new action',
  'team.project.add.new': 'Add new project',
  'team.action.date.immediate': 'Immediate',
  'team.admin.download.reports': 'Download reports',

  'team.problems.subtitle': 'Access the database',
  'team.action.no.item': 'No action item',
  'team.action.delete.confirm': 'Delete action',
  'team.action.update.success': 'Action has been updated successfully',
  'team.action.create.success': 'Action has been created successfully',
  'team.action.delete.success': 'Action has been deleted successfully',
  'team.action.label.immediate': 'Immediate',
  'team.action.edit': 'Edit action',
  'team.action.delete.project': 'Action has been deleted to the project',
  'team.action.change.project': 'Projet update successfully',

  'team.project.delete.success': 'Project deleted with success!',
  'team.project.detail': 'Project detail',

  'team.error.update.romance': 'Error on update romance',
  'team.error.update.mission': 'Error on update mission',

  'team.add.new.issue': 'Add new issue',
  'team.label.add.new': 'Create a new team',
  'team.label.update': 'Edit team info',
  'team.goal.add.new': 'Add new indicator',
  'team.add.new': 'Add new',
  'team.dialog.goal.details': 'Indicator details',

  'team.delete.label': 'Delete team',
  'team.member.delete.label': 'Remove member from team',
  'team.delete.success': 'Process deleted succefully',
  'team.search.no.result': 'No team found for your search',
  'team.search.for': 'Search for team',
  'team.no.item': 'No team to show...',

  'team.tootlip.title.mission':
    'The mission is how the team contribute to create the value to the company and the client',
  'team.tootlip.title.tomorrow.romance':
    "The Tomorrow's romance is the next step where the manager dreams to take this team in two years",
  'team.tootlip.title.button.members':
    'Click on the green button to show and hide the members of your team',

  'team.confirm.dialog.delete.member': 'Do you really want to delete this member?',
  'team.dialog.team.badges': 'Team badges',

  'team.label.concerned': 'Impacted Team ',
  'team.load.more.issues': 'Load more issues',
  'team.load.more.data': 'Load more data',

  'team.event': 'Team event',

  
};
export default team;
