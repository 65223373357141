const common: Record<string, string> = {
  'common.home': 'home',
  'common.help': 'Help',
  'common.sign.in': 'Sign In',
  'common.sign.up': 'Sign Up',
  'common.forgot.password': 'Forgot password?',
  'common.label.password.is.required': 'Password is required',
  'common.label.password.rules':
    'Must Contain at least 10 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
  'common.label.type.your.motto': '"Type your motto"',
  'common.forgot.password.label':
    'Please enter the email address you have registered. We will send you your new password.',
  'common.have.not.account': "Don't have an account? Sign up",
  'common.label.login': 'Login',
  'common.label.request.password': 'Request a new password',
  'common.label.password.match': 'Passwords must match',
  'common.label.incorrect.password': 'Incorrect Password',
  'common.label.uploading': 'Uploading...',
  'common.label.submit': 'Submit',
  'common.label.apply': 'Apply',
  'common.label.clear.filter': 'Clear Filter',
  'common.label.delete': 'Delete',
  'common.label.delete.toproject': 'Delete from project',
  'common.label.add.toproject': 'Add to project',
  'common.label.remove': 'Remove',
  'common.label.cancel': 'Cancel',
  'common.label.confirm': 'Confirm',
  'common.label.order': 'Order',
  'common.label.close': 'Close',
  'common.label.back': 'Back',
  'common.label.okay': 'Okay',
  'common.label.continue': 'Continue',
  'common.label.congrats': 'Congrats',
  'common.label.required': ' Required',
  'common.label.ok': 'OK',
  'common.label.ranking': 'Ranking',
  'common.label.recent': 'Recent',
  'common.label.see.details': 'See details',
  'common.label.see.history': 'See history',
  'common.label.responsible': 'Responsible',
  'common.label.pts': 'Pts',
  'common.label.my.shop': 'My Shop',
  'common.label.got.it': 'Got it',
  'common.label.logo': 'Logo',
  'common.label.error': 'Error',
  'common.label.view.details': 'View details',
  'common.label.since': 'Since',
  'common.connection': 'Log In',
  'common.label.logout': 'Log Out',
  'common.label.loading': 'Loading...',
  'common.send.mail': 'Send e-mail',
  'common.resend.mail': 'Resend e-mail',
  'common.resend.code': 'Resend code',

  'common.label.input.required': 'This field is required',
  'common.label.my.weather': 'My weather',
  'common.label.coworker': 'Coworker',
  'common.label.no.coworker': 'No coworker',
  'common.label.message': 'Message',
  'common.label.notification': 'Notifications',
  'common.label.inbox': 'Inbox',
  'common.label.sort.by': 'Sort by',
  'common.label.filter.by': 'Filter by',
  'common.label.professional.weather': 'Professional weather',
  'common.label.your.professional.weather': 'Your Professional weather ?',
  'common.label.your.professional.status': 'Your Professional Status ?',
  'common.label.community': 'Team',
  'common.label.select.communities': 'Select teams (All teams if no teams selected)',
  'common.label.community.concerned': 'Impacted Team',
  'common.label.community.inCharge': 'Team in charge',
  'common.label.community.concerned.or.recipient': 'Impacted Team or recipient',
  'common.label.event': 'Event',
  'common.label.events': 'Events',
  'common.label.motto': 'What is the motto of your day?',
  'common.label.description': 'Description',
  'common.label.communities': 'Teams',
  'common.label.skills': 'Skills',
  'common.label.add.skill': 'Add skill',
  'common.label.success': 'Success',
  'common.label.add.success': 'Add success',
  'common.label.passions': 'Passions',
  'common.label.deadline': 'Deadline',
  'common.label.timezone': 'Timezone',
  'common.label.timezones': 'Timezones',
  'common.label.add.passion': 'Add passion',
  'common.label.switch.company': 'Switch Company',
  'common.label.company.user.already.exists': 'User already exists in this company',
  'common.label.company.already.invited': 'User already invited for this company',
  'common.label.to.company': 'to {name}!',
  'common.label.switch.your.company': 'You should switch your company.',
  'common.label.previous.companies': 'Previous companies',
  'common.label.add.previous.company': 'Add previous company',
  'common.label.previous.pictures': 'Pictures',
  'common.label.employee.of.the.day': 'Employee of the day',
  'common.label.welcome': 'Welcome',
  'common.label.welcome.new.arrival': 'Welcome to new arrival',
  'common.label.access.to.hive': 'Access to the hive',
  'common.label.word.from.director': 'A word from the director',
  'common.label.member': 'Member',
  'common.label.members': 'Members',
  'common.label.no.member': 'No member',
  'common.label.add.new.member': 'Add new member',
  'common.label.community.role': 'Team Role',
  'common.label.community.role.admin': 'Admin',
  'common.label.community.role.member': 'Member',
  'common.label.community.role.manager': 'Manager',
  'common.label.community.role.secretary': 'Secretary',
  'common.label.add': 'Add',
  'common.label.download': 'Download',
  'common.label.archives': 'Archives',
  'common.label.list.view': 'List View',
  'common.label.add.issue': 'Add issue',
  'common.label.add.advice': 'Add advice',
  'common.label.add.idea': 'Add idea',

  'common.label.search.a': 'Search a {item}',
  'common.label.search.coworker': 'Search a coworker',
  'common.label.search.project': 'Search a project',
  'common.label.select.coworkers': 'Select coworkers (All coworkers if no coworkers selected)',
  'common.label.search.status': 'Search a status',
  'common.label.search.coworker.supervisor': 'Search a coworker as my supervisor',
  'common.label.search.business.unit': 'Search a business unit',
  'common.label.search.collaborator': 'Find a collaborator',
  'common.label.welcome.back': 'Welcome back',
  'common.label.some.suggestion': 'Some suggestion :',
  'common.label.joined': 'Joined',
  'common.label.join': 'Join',
  'common.label.all.companies': 'Lists of your companies',
  'common.label.wanna.thank.someone': 'Do you want to thank someone?',
  'common.label.congrats.wish.sent': 'Congratulations, your wish have been well sent',
  'common.label.congrats.thank.sent': 'Congratulations, your thank have been well sent',
  'common.label.congrats.feedback.sent': 'Congratulations, your feedback have been well sent',
  'common.label.congrats.motto.sent': 'Congratulations, your motto have been well sent',
  'common.label.congrats.pro.weather.sent': 'Professional weather setted successfully',
  'common.label.update.professional.status.succes': 'Professional Status updated successfully',
  'common.label.joined.today': 'Joined today',
  'common.label.today': 'Today',
  'common.label.categorie': 'Category',
  'common.label.categories': 'Categories',
  'common.label.all': 'All',
  'common.label.all.my.teams': 'All my teams',
  'common.label.laptop': 'Laptop',
  'common.label.newest': 'Newest',
  'common.label.oldest': 'Oldest',
  'common.label.popular': 'Popular',
  'common.label.new': 'New',
  'common.label.like': 'Like',
  'common.label.comment': 'Comment',
  'common.label.add.comment': 'Add new comment',
  'common.label.confirmation': 'Confirmation',
  'common.label.comments': 'Comments',
  'common.label.shares': 'Shares',
  'common.label.send.request': 'Send request',
  'common.label.share': 'Share',
  'common.label.rating': 'Rating',
  'common.label.convert.to.action': 'Convert to action',
  'common.label.convert.to.project': 'Convert to project',
  'common.label.converting': 'Converting',
  'common.label.pictures': 'Pictures',
  'common.label.filter': 'Filter',
  'common.label.post.to.show': 'Post to show',
  'common.label.my.ideas': 'My Ideas',
  'common.label.my.advices': 'My Advices',
  'common.label.my.problems': 'My Problems',
  'common.label.my.processes': 'My Processes',
  'common.label.my.stories': "My What's up",
  'common.label.my.questions': 'My Questions',
  'common.label.my.have.funs': 'My Have funs',
  'common.label.to.do': 'To do',
  'common.label.in.progress': 'In progress',
  'common.label.done': 'Done',
  'common.label.below': 'Below',
  'common.label.at.level': 'At level',
  'common.label.above': 'Above',
  'common.label.blocked': 'Blocked',
  'common.label.niveau': 'Level ',
  'common.label.action': 'Action',
  'common.label.actions': 'Actions',
  'common.label.new.update': 'New update',
  'common.label.trend': 'Trend',
  'common.label.growth': 'Growth',
  'common.label.no.indicator': 'No indicator',
  'common.label.indicators': 'Indicators',
  'common.label.projects': 'Projects',
  'common.label.no.content.yet': 'No content yet',
  'common.label.no.indicator.yet': 'No indicator yet',
  'common.label.no.community.founded': 'No Team founded',
  'common.label.no.archive.yet': 'No Archive yet',
  'common.label.indicator': 'Indicator',
  'common.label.history.details': 'Indicator history details',
  'common.label.history.detail': 'Indicator history detail',
  'common.label.progress': 'Progress',
  'common.label.degress': 'Degress',
  'common.label.balanced': 'Balanced',
  'common.label.no.trend': 'No trend',
  'common.label.save': 'Save',
  'common.label.edit': 'Edit',
  'common.label.problems': 'Issues',
  'common.label.problem': 'Issue',
  'common.label.idea': 'Idea',
  'common.label.story': "What's up",
  'common.label.advice': 'Advice',
  'common.label.process': 'Process',
  'common.label.question': 'Question',
  'common.label.have.fun': 'Have fun',
  'common.label.finalize': 'Finalize',
  'common.label.low': 'Low',
  'common.label.medium': 'Medium',
  'common.label.high': 'High',
  'common.label.category': 'Category',
  'common.label.technical.problem': 'Technical problem',
  'common.label.circuit': 'Circuit',
  'common.label.project': 'Project',
  'common.label.accept': 'Accept',
  'common.label.accepted': 'Accepted',
  'common.label.decline': 'Decline',
  'common.label.copied': 'Copied',
  'common.label.no.project': 'No project',
  'common.label.no.data.available': 'No data available',
  'common.label.no.changes': 'No changes',
  'common.label.deleted': 'Deleted',
  'common.label.resolved': 'Resolved',
  'common.label.level': 'Level',
  'common.label.next': 'Next',
  'common.label.previous': 'Previous',
  'common.label.from': 'From',
  'common.label.to': 'To',
  'common.label.not.found': 'Not found',
  'common.label.upload.text': 'Drop file to upload or',
  'common.label.browse': 'Browse',
  'common.label.step': 'step',
  'common.label.first': 'First',
  'common.label.second': 'Second',
  'common.label.third': 'Third',
  'common.label.forth': 'Forth',
  'common.label.last': 'Last',
  'common.label.expert': 'Expert',
  'common.label.experts': 'Experts',
  'common.label.validator': 'Validator',
  'common.label.validators': 'Validators',
  'common.label.validated': 'Validated',
  'common.label.issue': 'Issue',
  'common.label.issues': 'Issues',
  'common.label.my.issues': 'My Issues',
  'common.label.points': 'Points',
  'common.label.point': 'Point',
  'common.label.upload': 'Drop file to upload or',
  'common.label.multiple.upload': 'Drop files to upload or',
  'common.label.what.is.up': "What's up",
  'common.label.what.is.up.edited': "Edit what's up",
  'common.label.shared.to': 'Shared to',
  'common.label.shared.to.teams': 'Shared to Teams',
  'common.label.shared.to.users': 'Shared to Users',
  'common.label.people': 'People',
  'common.label.search': 'Search',
  'common.label.year': 'Year',
  'common.label.meeting': 'Meeting',
  'common.label.team.meeting': 'Team meeting',
  'common.label.not.specified': 'Not specified',
  'common.label.job.title': 'Job Title',
  'common.label.personality': 'Personality',
  'common.label.assign': 'Assign',
  'common.label.pending': 'Pending',
  'common.label.solving.in.progress': 'Solving in progress',
  'common.label.solved': 'Solved',
  'common.label.test.solution': 'Test solution',
  'common.label.parked': 'Parked',
  'common.label.read.more': 'Read more',
  'common.label.file': 'File',
  'common.label.files': 'Files',
  'common.label.skip': 'Skip',
  'common.label.your.action': 'Your action',
  'common.label.add.an.action': 'Add an action',
  'common.label.describe.it': 'Describe it...',
  'common.label.click.to.enter': 'Click to enter',
  'common.label.view.all': 'View all',
  'common.label.item': 'Item',
  'common.created-by.label': 'Created by',
  'common.creator.label': 'Created by {creator}',

  'common.confirm.dialog.delete.description': 'Do you really want to delete this {item}?',
  'common.label.multiple.company':
    'Welcome to WimTim. You are a member of multiple companies, choose the one you want to enter for this session.',

  // Placeholder
  'common.label.serch.for.users': 'Search for users',
  'common.label.serch.for.something': 'Search for Something...',
  'common.placeholder.birthday.wish': 'Wish him/her something',
  'common.placeholder.childbirth.wish': 'Congratulate a newborn',
  'common.placeholder.wedding.wish': 'Congratulate a wedding',
  'common.placeholder.think.about': 'What is the motto of your day?',
  'common.placeholder.thank.someone':
    'Thanks should refer to a specific action, the quality shown, and its positive and effective consequences.',
  'common.placeholder.no.employee.of.the.day': 'No employee of the day for today',
  'common.placeholder.no.event.today': 'No event today',
  'common.placeholder.no.new.arrival.employee.today': 'No new arrival employee today',
  'common.placeholder.no.word.from.director.today':
    'No word from director today! Btw have a nice day',
  'common.placeholder.wish.welcome': 'Wish a warn welcome',
  'common.placeholder.type.here': 'Type here ...',
  'common.placeholder.write.comment': 'Write a comment',
  'common.placeholder.select.category': 'Select a category',
  'common.help.file.size.large': 'File max size 100Mo',
  'common.label.name': 'Name',
  'common.label.lastname': 'Lastname',
  'common.label.firstname': 'Firstname',
  'common.label.firstame.is.required': 'Firstname is required',
  'common.label.email': 'Email',
  'common.label.create': 'Create',
  'common.label.email.required': 'Email Required',
  'common.label.role': 'Role',
  'common.label.invite': 'Invite user',
  'common.label.mission': 'Mission',
  'common.label.romance': 'Romance',
  'common.label.tomorrow.romance': "Tomorrow's romance",
  'common.label.dropzone.image': 'Drag and drop image here, or click to select image',
  'common.label.dropzone.image.not-supported': 'Multiple images or file type not supported',
  'common.label.upload.image': 'Upload image(s)',
  'common.label.creation': 'Creation',
  'common.label.target': 'Target',
  'common.label.overdue': 'Overdue',
  'common.labe.close.to.target': 'Close to target date',
  'common.label.not.answered': 'Not answered',
  'common.post.dialog.add.placeholder.title.idea': 'Give a title to your idea here',
  'common.post.dialog.add.placeholder.content.idea': 'Suggest a great idea',
  'common.post.dialog.add.placeholder.title.advice': 'Give a title to your advice here',
  'common.post.dialog.add.placeholder.content.advice': 'Suggest a great advice',

  'common.post.dialog.add.placeholder.community': 'Search a concerned team',
  'common.post.dialog.add.placeholder.responsible': 'Search a responsible',
  'common.one.to.one.list.view.placeholder.search': 'Search a coworker',
  'common.one.to.one.need.dialog.placeholder.describe': 'Describe it...',
  'common.one.to.one.need.dialog.placeholder.title': 'Title of your need...',
  'common.calendar.owners': 'Search owners',
  'common.calendar.community.event': 'Team Event detail',
  'common.community.event.community.concerned': 'Impacted Team',
  'common.community.event.owners': 'Team event owners',

  'common.meeting.placeholder.send.invitation':
    'Send an invitation via email (external participants will receive the meeting call link directly)',

  // Profile
  'common.label.profile': 'Profile',
  'common.label.edit.profile': 'Edit Profile',
  'common.no.item': 'No item',
  'common.label.user.profile': 'User profile',

  // One to One
  'common.label.my.needs': 'My Needs',
  'common.label.my.feedback': 'My Feedback',
  'common.label.feedback': 'Feedback',
  'common.label.responsibles': 'Responsibles',
  'common.label.finished': 'Finished',
  'common.label.send.to': 'Send to',
  'common.label.sent.to': 'Sent to',
  'common.label.sent.by': 'Sent by',
  'common.label.send': 'Send',
  'common.label.collect': 'Collect',
  'common.label.clarity': 'Clarity',
  'common.label.trust': 'Trust',
  'common.label.support': 'Support',
  'common.label.reward': 'Reward',
  'common.label.passion': 'Passion',

  'common.label.recipient.team': 'Recipient team',
  'common.label.concerned.team': 'Impacted Team',
  'common.label.pending.team': 'Pending Team',
  'common.label.expert.team': 'Expert team',

  'common.label.draft': 'Draft',
  'common.label.view': 'View',

  'common.label.share.an.improvement': 'Share an improvement',
  'common.label.add.a.new.process': 'Add a new process',
  'common.label.add.a.new.post': 'Add a new {postType}',
  'common.label.add.a.new.post.idea': 'Add a new idea',
  'common.label.add.a.new.post.problem': 'Add a new problem',
  'common.label.add.a.new.post.story': "Add a new what's up",
  'common.label.add.a.new.post.fun': 'Add a new have fun',
  'common.label.add.a.new.post.question': 'Add a new question',
  'common.label.add.a.new.post.advice': 'Add a new advice',
  'common.label.add.a.new.post.process': 'Add a new process',
  'common.label.delete.post': 'Delete {postType}',

  'common.label.reopened': 'Reopened',
  'common.label.edit.roadblock': 'Edit roadblock',
  'common.label.edit.success': 'Edit roadblock with success',
  'common.error.update.roadblock': 'Error on update roadblock',
  'common.label.finish': 'Finish',

  'common.label.teams': 'Teams',
  'common.label.supervisor': 'Supervisor',
  'common.label.my.supervisor': 'My Supervisor',
  'common.label.hierachical.superior': 'Hierarchical superiors',
  'common.label.no.supervisor': 'No Supervisor',
  'common.label.presentation.video': 'Presentation video',
  'common.label.family.situation': 'Family situation',
  'common.label.biography': 'Biography',
  'common.label.my.shared.horizon': 'My shared horizon',
  'common.label.business.unit': 'Business unit',
  'common.label.select.business.unit': 'Select Business Units (All if no BU selected)',
  'common.label.business.unit-role': 'Business unit role',
  'common.label.date': 'Date',
  'common.label.select.date': 'Select date',
  'common.label.solve': 'Solve',
  'common.label.improve': 'Improve',
  'common.label.improveSolve': 'Improve/Solve',
  'common.label.demand': 'Demand',
  'common.label.root.cause.s': 'Root cause(s)',
  'common.label.5why': '5Why',
  'common.label.5w': '5W',
  'common.label.desc': 'desc',
  'common.label.compare': 'Compare',
  'common.label.target.date': 'Target date',
  'common.label.signup.success': 'Sign up done with success!',

  // Calendar
  'common.label.create.event': 'Create event',
  'common.label.update.event': 'Update event',
  'common.label.add.event': 'Add event',
  'common.label.add.leave': 'Add leave',
  'common.label.add.meeting': 'Add meeting',

  // A3
  'common.label.a3': 'A3',
  'common.label.objective': 'Objective',
  'common.tooltip.objective.title': 'Objective?',
  'common.tooltip.plan.title': 'Plan ?',
  'common.tooltip.objective.description':
    'The specific outcome required for the business. What is the objective? How will you measure success?',
  'common.label.attach.file': 'Attach file',

  'common.label.analysis': 'Analysis',
  'common.tooltip.analysis.title': 'Analysis?',
  'common.tooltip.analysis.description': 'Choose one of the 3 methodology',
  'common.label.plan': 'Plan',

  'common.label.follow-up': 'Follow-up',
  'common.tooltip.follow-up.title': 'follow-up?',
  'common.tooltip.analysis.description1': 'How will we know if the actions have the impact needed?',
  'common.tooltip.analysis.description2':
    'What are the critical few, visual, most natural measures?',
  'common.tooltip.analysis.description3':
    'Remaining issues that can be anticipated. Any failure modes to watch out for?',
  'common.tooltip.analysis.description4': 'Any unintended consequences?',
  'common.tooltip.analysis.description5': 'Ensure ongoing P-D-C-A. Yakoten as needed.',

  'common.label.your.root.cause.s': 'Your root cause(s)',
  // Indicator
  'common.label.no.history': 'No history',
  'common.label.title': 'Title',
  'common.label.value': 'Value',
  'common.label.update': 'Update',
  'common.label.modify': 'Modify',
  'common.label.add.pictures': 'Add pictures',
  'common.label.add.video': 'Add video',
  'common.label.statistic': 'Statistic',
  'common.label.statistics': 'Statistics',
  'common.label.access.to.the.shop': 'Access to the shop',

  // Chart
  'common.chart.no.earned.exp': 'You have no earned exp',
  'common.chart.tooltip.title': 'Rule for obtaining points',
  'common.chart.tooltip.description':
    'Interwoven with key cultural concepts around language, learning and life. Through fully interactive lessons you will practise speaking Māori in a supportive group and develop confidence in using Te Reo in everyday kiwi situations.',

  'common.information.saved.successfully': 'Information saved successfully',

  // Conversion
  'common.label.convert': 'Convert',
  'common.label.conversion': 'Conversion',
  'common.label.convert.to': 'Conversion to',

  'common.label.history': 'History',
  'common.label.proposed.counter.measure': 'Proposed CounterMeasure(s)',
  'common.label.proposed.counter.measure.title': 'Proposed CounterMeasure(s)?',
  'common.label.your.comment': 'Your comment',
  'common.label.comment.it': 'Comment it...',

  //Issue
  'common.label.updatedAt': 'Updated At',
  'common.label.author': 'Author',
  'common.label.status': 'Status',
  'common.label.handle': 'Handle',
  'common.label.validate': 'Validate',
  'common.label.id': 'ID',

  //Process
  'common.label.creator': 'Creator',
  'common.label.n.files': 'N° files',
  'common.label.n.comment': 'N° comment',
  'common.label.last.edition': 'Last edition',

  // 2FA
  'common.text.received.email':
    'You have received an email containing a two-factor login code. Insert the code here log in.',
  'common.text.resend.code': 'You can always resend the code if you did not receive it!',
  'common.two.factor.authentication': 'Two-factor authentication',

  'common.email.sent.successfully': 'Email sent successfully',
  'common.company.switched.successfully': 'Company switched successfully',

  'common.label.position': 'Position',
  'common.order.by.firstname': 'Order by firstname',

  // Meeting
  'common.label.information': 'Information',
  'common.label.attendees': 'Attendees',
  'common.label.external.email': 'External Emails',
  'common.label.details': 'Details',
  'common.label.duration': 'Duration',
  'common.label.period': 'Date range',
  'common.label.pass.code': 'Passcode',
  // Question
  'common.label.answer': 'Answer',
  'common.label.ask.a.question': 'Ask a question',
  'common.label.search.a.question.or.ask': 'Search a question or ask',
  'common.placeholder.write.an.answer': 'Write an answer',
  'common.ask.your.question.here': 'Ask your question here...',

  'common.label.birthday': 'Birthday',
  'common.label.idea.rating.period': 'Rating period',
  'common.label.team': 'Team',
  'common.label.owner': 'Owner',
  'common.label.owners': 'Owners',
  'common.label.our.shared.horizon': 'Our shared horizon',

  // Not found
  'common.item.not.found': 'Item not found',
  'common.item.has.been.removed': 'This Item has been removed',

  // Badge
  'common.label.badges': 'Badges',
  'common.label.achievements': 'Achievements',
  'common.label.special': 'Special',
  'common.label.points.collection': 'Points collection',
  'common.label.see.all.badges': 'See all badges',

  // Admin
  'common.label.company': 'Company',
  'common.label.users': 'Users',
  'common.label.employee.day': 'Employee of the day',
  'common.label.tunnels': 'Tunnels',
  'common.label.coach': 'Coach',
  'common.label.business.units': 'Business Units',
  'common.label.shop': 'Shop',
  'common.label.subscription': 'Subscription',
  'common.label.admin.panel': 'Admin Panel',
  'common.label.access': 'Access',
  'common.label.hierarchical.superior': 'Hierachical superior',
  'common.label.company.role': 'Company Role',
  'common.label.suspend': 'Suspend',
  'common.label.hiring.date': 'Hiring Date',
  'common.label.user.blocker.update.state.success': 'User suspension updated with success!',
  'common.label.user.blocker.update.state.error': 'Cannot update user suspension',
  'common.label.comming.soon.tag': 'Coming soon',
  'common.label.application': 'Application',

  // User Access
  'common.label.simple.user': 'Simple User',
  'common.label.admin.user': 'Admin User',
  'common.label.company.structure': 'Company structure',

  // Company Member Role
  'common.label.director': 'Director',
  'common.label.employee': 'Employee',
  'common.label.financial.controller': 'Financial Controller',
  'common.label.user.deleted.success': 'User deleted with success!',
  'common.label.user.deleted.error': 'Error on delete user!',
  'common.label.company.member.updated.success': 'Member role updated with success!',
  'common.label.company.member.updated.error': 'Error on update member role!',
  'common.label.update.user.access.success': 'User access updated with success!',
  'common.label.update.user.hiring.date.success': 'User hiring date updated with success!',
  'common.label.update.user.access.error': 'Error on update user access!',
  'common.label.update.user.hiring.date.error': 'Error on update user hiring date!',
  'common.label.update.invitation.access.success': 'Invitation access updated with success!',
  'common.label.update.invitation.access.error': 'Error on update invitation access!',
  'common.label.update.invitation.role.success': 'Invitation role updated with success!',
  'common.label.update.invitation.role.error': 'Error on update invitation role!',

  'common.label.start': 'Start',
  'common.label.ending': 'Ending',
  'common.label.yes': 'Yes',
  'common.label.no': 'No',
  'common.label.rejected': 'Rejected',

  //  Top right header icons (coming soon) just before coworker icon
  'common.label.guides': 'Guides (Coming soon)',
  'common.label.help.marks': 'Help Marks',
  'common.label.settings': 'Settings',

  'common.label.snapshot': 'Snapshot',
  'common.label.completed': 'Completed',

  'common.label.release.notes': 'Release notes',

  //helpmark
  'common.goal.and.kpi': 'Indicators and KPIs',
  'common.team.coins': 'Team Coins',
  'common.issue.exchange': 'Issue exchange',
  'common.general': 'General',
  'common.support': 'Support',
  'common.reward': 'Reward',
  'common.trust': 'Trust',
  'common.clarity': 'Clarity',
  'common.road.block': 'Road blocs',
  'common.a3.process': 'A3 Process',
  'common.goal': 'Indicator',
  'common.mission.and.action': 'Missions & Actions',

  'common.label.finalized': 'Finalized',
  'common.label.not.finalized': 'Not finalized',

  'common.label.response': 'Response',
  'common.label.content': 'Content',
  'common.label.received.from': 'Received from',
  'common.label.or': 'or',
  'common.label.show': 'Show',
  'common.label.in.charge': 'In Charge',
  'common.label.attached.files': 'Attached Files',
  'common.label.images': 'Images',
  'common.daterange.not.selected': 'No date range selected !',
  'common.daterange.range': '{dateStart} to {dateEnd}',

  'common.label.idea.title': 'Idea title',
  'common.label.advice.title': 'Advice title',
  'common.label.meeting.title': 'Meeting title',
  'common.label.event.name': 'Event name',
  'common.label.issue.title': 'Issue title',
  'common.label.project.title': 'Project title',
  'common.label.proccess.title': 'Process title',
  'common.label.what.is.up.title': "What's up title",
  'common.label.mission.title': 'Mission title',
  'common.label.question.title': 'Question title',

  'common.languages': 'Languages',

  'common.Notifications': 'Notifications',

  'common.label.my.teams': 'My teams',
  'common.label.all.teams': 'All teams',
  'common.label.other.teams': 'Other teams',
  'common.label.all.other.teams': 'All other teams',
  'common.label.visible.to.everyone': 'Visible to everyone',

  'common.paste.image.link': 'Paste an image link here',
  'common.label.image.link': 'Image link',
  'common.label.upload.an.image': 'Upload an image ',
  'common.label.upload.insert.image': 'Upload/Insert an image',

  'common.label.export.to.xlsx': 'Download leaves to .xlsx',

  'common.label.comments.privacy': 'COMMENTS PRIVACY',
  'common.label.public': 'Public',
  'common.label.private': 'Private',
  'common.label.on': 'On',
  'common.label.off': 'Off',

  'common.label.external': 'External',

  'common.label.type': 'Type',
  'common.label.created.at': 'Created at',
  'common.label.updated.at': 'Updated at',
  'common.label.created.by': 'Created by',
  'common.label.database': 'To do',
  'common.label.my.items': 'My Items',
  'common.label.my.actions': 'My actions',
  'common.label.indication': 'Indicators',
  'common.label.my.indication': 'My indicators',
  'common.label.checked': 'Checked',
  'common.label.not.checked': 'Not checked',
  'common.label.items': 'Items',
  'common.label.action.team': 'Team action',
  'common.label.project.team': 'Project action',
  'common.label.one.to.one.team': 'One to one action',
  'common.label.road.load.team': 'Road block action',
  'common.label.pure.action.team': 'Pure action',
  'common.label.indication.team': 'Indicator action',
  'common.label.issue.team': 'Issue action',
  'common.label.action.type': 'Action type',
  'common.label.action.idea': 'Idea action',
  'common.label.coworkers': 'Coworkers',
  'common.label.undo': 'Undo',
  'common.label.show-more': 'Show more',
  'common.label.show-less': 'Show less',
  'common.label.counter': 'Counter',
  'common.label.date.from.your.timezone': 'Date from your timezone',
  'common.label.date.from.meeting.timezone': 'Date from meeting timezone',
  'common.label.company.email.not.send': 'Email not send',
  'common.label.empty': 'Empty',
  'common.label.where.are.you.working.for.today': 'Where are you working for today?',

  'common.unread.notification': 'Unread',
  'common.unread.notification.mark.all.as.read': 'Mark all as read',
  'common.read.notification': 'Read',
  'common.no.read.notification': 'No read notification',
  'common.no.unread.notification': 'No unread notification',
  'common.label.find': 'Find',
  'common.label.filter.preset': 'Filters preset',
  'common.label.untitled': 'Untitled',
  'common.label.select.type': 'Select type',
  'common.label.reset.filters': 'Reset filters',
  'common.label.filters': 'Filters',
  'common.label.date.range': 'Date range',
  'common.label.select.date.range': 'Select date range',
  'common.label.apply.filters': 'Apply filters',
  'common.no.item.found': 'No item found',
  'common.date.and.time': 'Date and time',
  'common.search.key.words': 'Search keywords',
  'common.owners.and.creator': 'Owners and creator',

  'common.label.all.fields': 'All fields',
  'common.label.family': 'Family',
  'common.label.adress': 'Adress',
  'common.label.activated': 'Activated',
  'common.label.deactivated': 'Deactivated',
  'common.label.configure': 'Configure',
  'common.leave.export.count.weekend': 'Leave export count weekend',
  'common.label.weekend.is.not.counted': 'Weekend is not counted',
  'common.label.only.saturday.is.counted': 'Only saturday is counted',
  'common.label.saturday.and.sunday.are.counted': 'Saturday and sunday are counted',
  'common.label.incoming.call': 'Incoming call',
  'common.label.join.room.call': 'Join room call',
  'common.label.host': 'Host',
  'common.label.participants': 'Participants',
  'common.label.guests': 'Guests',
  'common.label.call': 'Call',
  'common.label.rooms': 'Rooms',
  'common.label.search.a.room': 'Search a room',
  'common.label.open.a.room': 'Open a room',
  'common.label.edit.room': 'Edit room',
  'common.label.room.informations': 'Room informations',
  'common.label.give.the.room.a.name': 'Give the room a name',
  'common.label.give.the.room.a.topic': 'Give the room a topic',
  'common.label.room.details': 'Room details',
  'common.label.room.edition': 'Room edited succesfully',
  'common.label.room.deletion': 'Room deleted succesfully',
  'common.label.days': 'Days',
  'common.label.daytime': 'DayTime',
  'common.label.no.subordinate': 'No subordinate',
  'common.label.superior': 'Superior',
  'common.label.phone': 'Phone number',
  'common.label.exp': 'Exp',
  'common.label.experience.evolution': 'Experience evolution',
  'common.label.next.level.in': 'Next level in',
  'common.label.rules.for.obtaining.points': 'Rule for obtaining points',
  'common.label.rules.for.obtaining.points.description':
    'Interwoven with key cultural concepts around language, learning and life. Through fully interactive lessons you will practise speaking Māori in a supportive group and develop confidence in using Te Reo in everyday kiwi situations.',
  'common.label.you.reached.a.new.level': 'You reached a new level',
  'common.label.your.team.reached.a.new.level': 'Your team {team} reached a new level',
  'common.label.you.reached.a.new.level.description':
    "Continue in this path to professional wisdom! Collect {exp} exp to get to the {badge}! You've unlocked a mistery box",
  'common.label.view.mystery.box': 'View mystery box',
  'common.label.wholeCompany.name': 'Whole Company',

  'common.label.weather.rain': 'Rain',
  'common.label.weather.cloudy': 'Cloudy',
  'common.label.weather.windy': 'Windy',
  'common.label.weather.cloud': 'Cloud',
  'common.label.weather.thunderstorm': 'Thunderstorm',
  'common.label.weather.unknown': 'Unknown',
  'common.label.weather.sunny': 'Sunny',

  'common.label.ratio': 'Ratio',
};
export default common;
