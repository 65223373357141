import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { API_URL } from 'config';
import { GET_S3_SIGNED_URL } from 'graphql/file/query';
import { Exact, GetS3SignedUrlQuery } from 'graphql/generated/graphql';
import { parseFilename } from './parseFilename';

export const getS3SignedUrl = async (
  file?: File,
  repo?: string,
  onLoad?: React.Dispatch<React.SetStateAction<boolean>>,
  cancelTokenSource?: CancelTokenSource,
) => {
  const token = localStorage.getItem('access_token');

  const uploadS3Options: AxiosRequestConfig = {
    headers: {
      'Content-Type': file!.type,
    },

    onUploadProgress: () => {
      onLoad?.(true);
    },

    cancelToken: cancelTokenSource?.token,
  };

  const { name, extension } = parseFilename(file?.name);
  const size = file?.size;
  const type = file?.type;

  const variables: Exact<{
    name: string;
    extension: string;
    repo?: string;
  }> = {
    name,
    extension,
    repo,
  };

  const res = await axios.post<AxiosResponse<GetS3SignedUrlQuery>>(
    API_URL,
    {
      operationName: 'GetS3SignedURL',
      query: GET_S3_SIGNED_URL.loc!.source.body,
      variables,
    },
    {
      headers: {
        authorization: token ?? '',
      },
    },
  );

  return axios.put(res.data.data?.localFileSignedUrl.url ?? '', file, uploadS3Options).then(() => {
    return {
      url: res.data.data?.localFileSignedUrl.key ?? '',
      name,
      size,
      type,
    };
  });
};
