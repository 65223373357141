import { ACCESS_TOKEN_KEY, SEEN_VALUE, WELCOME_SCREEN_LOGIN_KEY } from 'constant';
import { Maybe } from 'graphql/generated/graphql';

export const handleLoggedIn = (token: Maybe<string>) => {
  if (token) {
    localStorage.setItem(ACCESS_TOKEN_KEY, token);
    localStorage.setItem(WELCOME_SCREEN_LOGIN_KEY, SEEN_VALUE);
    if (window) window.location.reload();
  }
};
