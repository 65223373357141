import React, { createContext, FC, useState } from 'react';

export const CommunityIdContext = createContext<{
  communityId: string;
  handleChangeCommunityId: (communityId: string) => void;
}>({ communityId: '', handleChangeCommunityId: () => { } });

export const CommunityIdContextProvider: FC = ({ children }) => {
  const [communityId, setCommunityId] = useState<string>('');

  const handleChangeCommunityId = (communityId: string) => {
    setCommunityId(communityId);
  };

  return (
    <CommunityIdContext.Provider value={{ communityId, handleChangeCommunityId }}>
      {children}
    </CommunityIdContext.Provider>
  );
};
