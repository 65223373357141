const exit: Record<string, string> = {
  // Shared horizon
  'exit.label.our.shared.horizon': 'Notre horizon commun',
  'exit.shared.horizon.description':
    'Inspirez vos collègues en écrivant un mot du jour pour demain',
  'exit.shared.horizon.placeholder': 'Notre horizon commun...',
  'exit.shared.horizon.create.success': 'Notre horizon commun créé avec succès',
  'exit.shared.horizon.update.success': 'Notre horizon commun mise à jour avec succès',

  // Actions
  'exit.action.description': 'Vérifiez vos actions inachevées',

  // Accumulated point
  'exit.label.accumulated.points': 'Points accumulés',
  'exit.label.today.s.achievements': "Les réalisations d'aujourd'hui",

  // Feedback
  'exit.feedback.label': 'envoyez vos commentaires à',

  // Issue
  'exit.issue.label': 'Avez-vous des problèmes non résolus ?',

  // Idea
  'exit.idea.label': 'Vous avez des idées que vous avez oublié de noter ?',

  // Accumulated point
  'exit.accumulated.point.no.content': "Aucun point reçu aujourd'hui",

  // Badges
  'exit.badge.no.new.badges.collected': 'Aucun nouveau badge collecté',

  //Thanks
  'exit.thanks.label': 'Envoyer un merci à',
};

export default exit;
