const tabs: Record<string, string> = {
  'tabs.label.hive': 'Ruche',
  'tabs.label.ruche': 'Ruche',
  'tabs.label.onetoone': '1 to 1',
  'tabs.label.map': 'Carte',
  'tabs.label.people': 'Personnes',
  'tabs.label.teams': 'Équipes',
  'tabs.label.community': 'Communauté',
  'tabs.label.calendar': 'Calendrier',
  'tabs.label.process': 'Processus',
  'tabs.label.problem': 'Problèmes',
  'tabs.label.admin': 'Administrateur',
  'tabs.label.profile': 'Profile',
  'tabs.label.processes': 'Processus',
  'tabs.label.database': 'À faire',
  'tabs.label.rules': 'Règles',
  'tabs.label.badges': 'Badges',

  //Menu tooltip title
  'tabs.tooltip.title.hive':
    "La Ruche est le salon numérique de l'entreprise, où se retrouvent indicateurs, publications et coworkers",
  'tabs.tooltip.title.onetoone':
    "Le 1-to-1 est un espace isolé qui permet aux collaborateurs et managers d'échanger sur des sujets professionnels",
  'tabs.tooltip.title.map':
    "La Carte est l'endroit où nous pouvons voir toutes les interactions des équipes et des communautés dans l'entreprise",
  'tabs.tooltip.title.people':
    'Le menu "Personnes" affiche tous vos collègues dans une vue en grille, là vous pouvez également voir le panthéon de votre entreprise',
  'tabs.tooltip.title.teams':
    'Le tableau de bord des équipes est un espace collaboratif pour chacune des équipes de votre entreprise',
  'tabs.tooltip.title.calendar':
    "Avec le calendrier, vous pouvez voir tous les événements, réunions et congés des collègues de l'entreprise avec une vue chronologique.",
  'tabs.tooltip.title.rules': "Une base de données pour tous les règles créés pour l'entreprise",
  'tabs.tooltip.title.problem':
    "Une base de données de toutes les problématiques créées dans l'entreprise, et les équipes",
  'tabs.tooltip.title.database':
    "Une base de données de toutes les Action créées dans l'entreprise, et les équipes",
};
export default tabs;
