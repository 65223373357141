const preference: Record<string, string> = {
  'preference.user.title': 'User preferences',
  'preference.user.personal.time.zone': 'Personal time zone',
  'preference.user.coach': 'Coach',
  'preference.user.active.hours': 'Active Hours',
  'preference.user.active.hours.description':
    'Specify a time period after which your professional status will automatically reset to "Available".',
  'preference.user.timezone.description':
    'All the dates and time inside the platform will follow this time zone.',
  'preference.user.update.success': 'Your preference is updated successfully',
  'preference.coach.update.activation.success':
    'Your coach preference activation is updated successfully',

  'preference.user.two.factor.authentication': 'Two factor authentication',
  'preference.user.enable.two.step.authentication': 'Enable two step authentication (2FA)',
  'preference.user.two.factor.description':
    'Protection used to ensure the security your account beyond just the email and password',

  'preference.user.tunnels.subheader':
    'Tunnels allows you to stay up to date with things that happen in your company.',
  'preference.ajust.active.hours': 'Ajust active hours',
  'preference.ajust.active.hours.description':
    "You'll be active during these hours after you set it manually",
  'preference.ajust.active.hours.to': 'to',
  'preference.user.tunnel.activate.deactivate.entry': 'Activate or deactivate the entry tunnel',
  'preference.user.tunnel.activate.deactivate.coach':
    "Activate or deactivate the Coach's assistance",
  'preference.user.tunnel.activate.deactivate.coach.description':
    'The coach is a personal assistant that can pop up anytime you need his help',
  'preference.user.tunnel.activate.deactivate.entry.description':
    "Set the time at which the entry tunnel pops up. It's better in the morning.",
  'preference.user.tunnel.activate.deactivate.exit': 'Activate or deactivate the exit tunnel',
  'preference.user.tunnel.activate.deactivate.exit.description':
    "Set the time at which the exit tunnel pops up. It's better in the evening.",
  'preference.user.languages.description':
    'Choose the language used in your WimTim. This language will be used in every WimTim content (Platform texts, emails, notifications,...)',
  'preference.user.personal.languages': 'Languages',
  'preference.user.Notifications.description':'Enable or disable different types of notifications',
  'preference.user.notifications.activate.deactivate.contextual': 'Activate or deactivate the contextual notifications',
  'preference.user.notifications.activate.deactivate.navigateur': 'Activate or deactivate the browser notifications',
  'preference.user.notifications.activate.deactivate.navigateur.description':
    "Browser notifications appear on your screen when you're not active in WimTim, but the site is open in your browser tab or window.",
  'preference.user.notifications.activate.deactivate.contextual.description':
    "Pop-up notifications appear on your screen when you're not active in WimTim.",
  'preference.contextual.notification.update.success': 'Your contextual notification preference is updated successfully',
  'preference.navigateur.notification.update.success': 'Your navigator notification preference is updated successfully',

};

export default preference;
