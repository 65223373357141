const leave: Record<string, string> = {
  'leave.label.export.leaves': 'Export leaves',
  'leave.label.download.leaves': 'Download leaves',
  'leave.label.select.a.date.range.for.downloading': 'Select a date range for downloading',
  'leave.label.filter': 'Leave filter',
  'leave.label.collaborators.search': 'Check collaborators who are on leave on this day',

  'leave.export.filename': 'Leaves-export',
  'leave.export.number.of.days.of.leaves': 'Number of days of leaves',
  'leave.export.unique.coworkers': 'Unique coworkers',
  'leave.export.start.date': 'Export start date',
  'leave.export.end.date': 'Export end date',

  'common.label.create.leave.for.someone': 'Create leave for someone',
};

export default leave;
