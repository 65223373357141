import React from 'react';

import Person4Icon from '@mui/icons-material/Person4';
import Avatar, { AvatarProps } from '@mui/material/Avatar';

import EditIcon from 'assets/svg/icons/EditIcon';

import useStyles from './styles';
import clsx from 'clsx';

export type TDefaultAvatarSize = 'small' | 'medium' | 'large' | 'huge';
export interface IDefaultPersonAvatarProps extends AvatarProps {
  avatarVariant?: AvatarProps['variant'];
  avatarSize?: TDefaultAvatarSize;
  tooglePictureDialog?: () => void;
  editable?: boolean;
}

const DefaultPersonAvatar = (props: IDefaultPersonAvatarProps) => {
  const {
    avatarVariant = 'square',
    avatarSize = 'huge',
    tooglePictureDialog,
    editable = false,
  } = props;
  const classes = useStyles({ avatarSize });

  return (
    <>
      <Avatar
        {...props}
        classes={{
          root: clsx(classes.container, {
            [classes.small]: avatarSize === 'small',
            [classes.medium]: avatarSize === 'medium',
            [classes.large]: avatarSize === 'large',
            [classes.huge]: avatarSize === 'huge',
          }),
        }}
        variant={avatarVariant}
      >
        <Person4Icon
          className={clsx(classes.avatar, {
            [classes.avatarSmall]: avatarSize === 'small',
            [classes.avatarMedium]: avatarSize === 'medium',
            [classes.avatarLarge]: avatarSize === 'large',
            [classes.avatarHuge]: avatarSize === 'huge',
          })}
        />
        {editable && <EditIcon className={classes.editIcon} onClick={tooglePictureDialog} />}
      </Avatar>
    </>
  );
};

export default DefaultPersonAvatar;
