const post: Record<string, string> = {
  'idea.create.success': 'Idée créée avec succès',
  'idea.button.publish': 'Publier ',
  'idea.add.title': 'Ajouter une idée',
  'idea.create.title': 'Créer une idée',
  'idea.label.anonymity': 'Anonymat',
  'idea.label.post.anonymously': 'Poster de façon anonyme',
  'advice.create.success': 'Conseil créé avec succès',
  'advice.add.title': 'Ajouter un conseil',
  'process.create.success': 'Processus créé avec succès',
  'whatsup.create.success': 'Quoi de neuf créé avec succès',
  'whatsup.update.success': 'Quoi de neuf mis à jour avec succès',
  'process.update.success': 'Processus mis à jour avec succès',
  'issue.creator.label': 'Émis par {creator}',
  'issue.issue-by.label': 'Émis par',

  'post.convert.into.project': 'Convertir en projet',
  'post.problem.dialog.title': 'Détail du problème',
  'post.process.dialog.title': 'Détail du processus',
  'post.files.upload.success': 'Fichiers téléchargés avec succès',
  'post.problem.create.success': 'Problème créé avec succès',
  'post.problem.create.error': "Une erreur s'est produite lors de la création du problème",
  'post.problem.update.success': 'Problème mis à jour avec succès',
  'post.problem.update.error': "Une erreur s'est produite lors de la mise à jour du problème",
  'post.files.upload.error': "Une erreur s'est produite lors du téléchargement du fichier",
  'post.process.create.error': "Une erreur s'est produite lors de la création du processus",
  'post.process.update.error': "Une erreur s'est produite lors de la mise à jour du processus",
  'post.whatsup.create.error': "Une erreur s'est produite lors de la création du Quoi de neuf",
  'post.whatsup.update.error': "Une erreur s'est produite lors de la mise à jour du Quoi de neuf",
  'post.share.success': 'Post partagé avec succès',
  'post.share.no.community': 'Vous avez partager ce post à toutes les communautés',
  'post.delete.sucess': ' Post supprimé avec succès !',
  'post.question.create.success': 'Quesion créée avec succès !',
  'post.question.create.error': "Une erreur s'est produite lors de la création de la question",
  'post.question.update.success': 'Quesion mise à jour avec suucès',
  'post.question.update.error': "Une erreur s'est produite lors de la mise à jour de la question",
  'post.already.seen': 'Déjà vu',
  'post.not.yet.seen': 'Pas encore vu',
  'post.is.reported': 'Signalé',
  'post.idea.delete.it': 'Supprimer l\'idée nommée "{name}"',
  'post.is.hidden': 'Message caché',
  'post.is.displayed': 'Article affiché',
  'post.issue.finalized.successfully': 'Problème finalisé avec succès',
  'post.issue.finalized.error': "Une erreur s'est produite lors de la finalisation du problème",

  'problem.update.status.successfully': 'État du problème mis à jour avec succès',
  'problem.update.status.error':
    "Une erreur s'est produite lors de la mise à jour de l'état du problème",
  'problem.5w.label': 'Décrire votre problème avec la méthode 5W',

  'post.issue.description': 'Description du problème',
  'post.issue.attachments': 'Émettre des pièces jointes',
  'post.issue.category.deadline': 'Catégorie du problème et date limite',
  'post.issue.owners': 'Propriétaires du problème',

  'post.issue.create.notification.title': 'Un problème a été créé !',
  'post.issue.create.notification.body': 'Votre équipe {arg1} est concernée par le probléme {arg2}',
  'post.issue.expert.notification.body': "Vous avez été choisi comme expert de l'issue {arg1}",
  'post.issue.concerned.notification.body':
    'Votre équipe {arg1} est concernée par le probléme {arg2}',
};
export default post;
