import { gql } from '@apollo/client';

export const COMMENT_IN_CALENDAR_FRAGMENT = gql`
  fragment CommentInCalendarFragment on Comment {
    id
    content
    createdAt
    isMine
    likeCount
    isLikedByMe
    companyMember {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
    }
    likedBy {
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
        }
      }
    }
  }
`;

export const COMMENT_IN_POST_FRAGMENT = gql`
  fragment CommentInPostFragment on Comment {
    id
    content
    createdAt
    isMine
    likeCount
    isLikedByMe
    companyMember {
      id
      user {
        id
        fullName
        lastName
        firstName
        photoUrl
        photoPublicUrl
      }
    }
    likedBy {
      user {
        id
        lastName
        firstName
        fullName
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
        }
      }
    }
  }
`;

export const COMMENT_IN_ACTION_FRAGMENT = gql`
  fragment CommentInActionFragment on Comment {
    id
    content
    createdAt
    isMine
    likeCount
    isLikedByMe
    companyMember {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
    }
    likedBy {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
        }
      }
    }
  }
`;
