const leave: Record<string, string> = {
  'leave.label.export.leaves': 'Exporter les congés',
  'leave.label.download.leaves': 'Télécharger les congés',
  'leave.label.select.a.date.range.for.downloading':
    'Sélectionnez une plage de dates pour le téléchargement',
  'leave.label.filter': 'Filtre du congé',
  'leave.label.collaborators.search': 'Vérifier les collaborateurs qui sont en congé ce jour-là',

  'leave.export.filename': 'Export-congés',
  'leave.export.number.of.days.of.leaves': 'Nombre de jour de congés',
  'leave.export.unique.coworkers': 'Uniques associés',
  'leave.export.start.date': "Date de début d'export",
  'leave.export.end.date': "Date de fin d'export",

  'common.label.create.leave.for.someone': "Créer un congé pour quelqu'un",
};

export default leave;
