const calendar: Record<string, string> = {
  'calendar.create.success': 'Team Event created successfully',
  'calendar.update.success': 'Team Event updated successfully',
  'calendar.delete.success': 'Team Event deleted successfully',
  'calendar.meeting.delete.success': 'Meeting deleted successfully',
  'calendar.create.error': 'Failed to create team Event',
  'calendar.update.error': 'Failed to update team Event',
  'calendar.meeting.delete.error': 'Failed to delete Meeting',
  'calendar.delete.error': 'Failed to delete team Event',
  'calendar.delete.confirm.message': 'Delete team event ?',

  'calendar.team-event-type.required': 'Team event type is required',

  'calendar.placeholder.title': 'Give a title to the event',
  'calendar.drag.to.the.past.warning': "Sorry ! Can't drag event to the past. At least today ",
  'calendar.invalid.date': 'Make sure that the event happen at least today ',
  'calendar.drag.to.week.end.warning': "Sorry ! Can't have event or meeting on week-end ",
  'calendar.event.time.warning': 'Make sure that time is between 8:00 and 18:00 ',
  'calendar.create.on.week.end.warning': "Sorry ! Can't create event or meeting on week-end ",

  'calendar.leave.event.title': 'Leave',

  // location
  'calendar.location.label.delete': 'Delete location',
  'calendar.location.create.label': 'Set your location',
  'calendar.location.edit.label': 'Edit your location',
  'calendar.label.set.location': 'Set location',
  'calendar.label.chose.your.location': 'Choose your location',
  'calendar.label.add.location': 'Add location',
  'calendar.set.location.success': 'Location setted successfully',
  'calendar.edit.location.success': 'Location edited successfully',
  'calendar.location.details.label': 'Location details',
  'calendar.location.filter.label': 'Filter by location',
  'calendar.delete.location.success': 'Location deleted successfully',

  // label
  'calendar.label.location': 'Location',
  'calendar.label.full.day': 'Full day',
  'calendar.label.half.day': 'Half day',
  'calendar.label.half.day.leaves': 'Half day leaves?',
  'calendar.label.event.privacy': 'Event privacy',
  'calendar.label.start.date': 'Start date',
  'calendar.label.end.date': 'End date',
  'calendar.label.start.date.time': 'Start date time',
  'calendar.label.end.date.time': 'End date time',
  'calendar.label.half.pm': 'Half p.m',
  'calendar.label.half.am': 'Half a.m',

  // leave
  'calendar.label.type.of.leave': 'Type of leave',
  'calendar.label.type.of.leave.description':
    'You can choose the type of leave between "full day", "half a.m" and "“half p.m"',

  'calendar.label.event.title': 'Event title',
  'calendar.label.give.a.title.to.the.event': 'Give a title to the event',
  'calendar.label.choose.event': 'Select event you want to create',

  'event.privacy.public.description.text': 'Event is visible to every one on the calendar',
  'event.privacy.private.description.text':
    'Event is visible only to member fo the teams concerned and selected coworkers',

  // event
  'calendar.label.event': 'Event',
  'calendar.label.event.details': 'Event details',

  'calendar.label.more.items': 'more items',

  'calendar.label.leave.period': 'Leave period',
  'calendar.label.validation.date': 'Validation date',
  'calendar.label.leave.validation': 'Leave period or Validation date',

  'calendar.from.creator.timezone': 'From creator timezone',
  'calendar.from.your.timezone': 'From your timezone',
  'calendar.label.new.calendar.item': 'New calendar item',
  'calendar.label.choose.calendar.item': 'Choose a calendar item you want to add on this date',

  'calendar.find.dialog.title': 'Find in calendar',
  'calendar.collaborators.who.are.on.leave': 'Collarborators who are on leave',

  'calendar.create.filter.success': 'Filter created successfully',
  'calendar.update.filter.success': 'Filter updated successfully',
  'calendar.delete.filter.success': 'Filter deleted successfully',

  'calendar.no.leave': 'No leave',
  'calendar.no.event': 'No event',
  'calendar.no.meeting': 'No meeting',
  'calendar.no.location': 'No location',

  'calendar.all.found.meetings': 'All found meetings',
  'calendar.all.found.events': 'All found events',
  'calendar.all.found.locations': 'All found locations',

  'calendar.month': 'Month',
  'calendar.week': 'Week',
  'calendar.day': 'Day',

  'calendar.localisation.homeOffice': 'Home Office',
  'calendar.localisation.outside': 'Outside',
  'calendar.localisation.travelling': 'Travelling',
  'calendar.localisation.office': 'From the office',
  'calendar.event.outside': 'Outside event',
  'calendar.event.client': 'Client Meeting',
  'calendar.event.gouvernance': 'Gouvernance',
  'calendar.event.milestone': 'Milestone',
  'calendar.event.mission': 'Mission',
  'calendar.event.meeting': 'Meeting',
  'calendar.event.party': 'Party / Birthday',

  'calendar.status.pending': 'Pending',
  'calendar.status.rejected': 'Rejected',
  'calendar.status.valide': 'Validated',
};

export default calendar;
