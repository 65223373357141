const social: Record<string, string> = {
  'social.motto.create.message.success': 'Devise créée avec succès',
  'social.event.wish.sent.success': 'Votre souhait est envoyé avec succès',
  'social.user.thank.sent.success': 'Votre message de remerciement a été envoyé avec succès',
  'social.user.wish.sent.success': 'Votre souhait est envoyé avec succès',
  'social.user.feedback.sent.success': 'Votre retour a été envoyé avec succès',

  //Comment
  'social.comment.create.success': 'Votre commentaire a été créé avec succès',
  'social.comment.update.success': 'YVotre commentaire a été mise à jour avec succès',
  'social.comment.delete.success': 'Votre commentaire a été supprimé avec succès',

  'social.sent.thank.you': 'Remerciement envoyé',
  'social.received.thank.you': 'Remerciement reçu',
  'social.send.thank.you.success': 'Merci envoyé avec succès',
  'social.send.thank.you.error': "Une erreur s'est produite lors de l'envoie du remerciement",
  'social.reply.thank.you.success': 'Merci répondu avec succès',
  'social.reply.thank.you.error':
    "Une erreur s'est produite lors de l'envoie de la réponse du remerciement",
  'social.thank.response': 'Réponse de remerciement',

  // Experiences
  'social.exp.from.to.experiences': '{from} - {to} expériences',
};
export default social;
