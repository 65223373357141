import React, { useState, Suspense, lazy, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { IntlProvider } from 'react-intl';

import LocaleContext from 'contexts/LocaleContext';

import CompanyLayoutSkeleton from './CompanyLayoutSkeleton';
import SplashScreen from './SplashScreen';

import { getCompanySlug, getCookie } from 'utils';
import messages from 'locales/messages';
import {
  SPLASH_SCREEN_KEY,
  SEEN_VALUE,
  SITE_MAP,
  COMPANY_SLUG_KEY,
  WELCOME_SCREEN_PRAGMA_KEY,
} from 'constant';

const CompanyLayoutView = lazy(() => import('./CompanyLayoutView'));

export interface ICompanyLayoutProps {}

/**
 * Renders the layout for the Company page.
 *
 * @return {JSX.Element} The CompanyLayout component.
 */
const CompanyLayout = (): JSX.Element => {
  // Hooks
  const navigate = useNavigate();

  // State
  const [showSplashScreen, setShowSplashScreen] = useState<boolean>(
    localStorage.getItem(SPLASH_SCREEN_KEY) ? false : true,
  );

  // Context
  const { locale } = useContext(LocaleContext);

  const companySlug = sessionStorage.getItem(COMPANY_SLUG_KEY) ?? getCompanySlug();

  // Get welcome url and hive url
  const { welcomeUrl, hiveUrl } = useMemo(() => {
    const pathWelcome = SITE_MAP.Client.sub.CompanyLayout.sub.WelcomScreen.path;
    const welcomeUrl = `${SITE_MAP.Client.path}/${companySlug}/${pathWelcome}`;
    const hivePath = SITE_MAP.Client.sub.CompanyLayout.sub.Ruche.path;
    const hiveUrl = `${SITE_MAP.Client.path}/${companySlug}/${hivePath}`;

    return { welcomeUrl, hiveUrl };
  }, [companySlug]);

  const entryTunnelCookieKey = `${WELCOME_SCREEN_PRAGMA_KEY}${companySlug}`;
  const isWelcomeScreenSeen = getCookie(entryTunnelCookieKey);
  const isWelcomeScreenPath = window.location.pathname.includes(
    SITE_MAP.Client.sub.CompanyLayout.sub.WelcomScreen.path,
  );
  /*En cas de connection manuel il faut juste vérifier si on a deja eu le tunnel aujourd'hui*/
  const shouldListen = Boolean(!isWelcomeScreenSeen && !isWelcomeScreenPath);

  const handleClickScreen = () => {
    setShowSplashScreen(false);
    localStorage.setItem(SPLASH_SCREEN_KEY, SEEN_VALUE);
    navigate(shouldListen ? welcomeUrl : hiveUrl);
  };

  return (
    <>
      {/* Intl provider for the current member */}
      <IntlProvider locale={locale} messages={messages[locale]}>
        <Suspense fallback={<CompanyLayoutSkeleton />}>
          <CompanyLayoutView />
        </Suspense>

        {showSplashScreen && <SplashScreen handleClickScreen={handleClickScreen} />}
      </IntlProvider>
    </>
  );
};

export default CompanyLayout;
