const authentification: Record<string, string> = {
  'auth.signup.title': "S'enregistrer",
  'auth.signup.label.firstname': 'Prénom',
  'auth.signup.label.lastname': 'Nom',
  'auth.signup.label.email': 'Email',
  'auth.signup.label.password': 'Mot de passe',
  'auth.signup.label.confirmPassword': 'Confirmer le mot de passe',

  'auth.signin.title': 'Connexion',
  'auth.signin.label.connection': "S'identifier",
  'auth.signin.label.wimtim.connection': 'Veuillez vous connecter à votre compte WimTim !',
  'auth.signin.label.email.address': 'Adresse mail',
  'auth.signin.label.remember.me': 'Souvenez-vous de moi',

  'auth.reset.label.create.password': "Création d'un nouveau mot de passe",
  'auth.reset.label.new.password': 'Nouveau mot de passse',
  'auth.reset.label.confirm.password': 'Confirmer le mot de passse',
  'auth.alert.label.no.company': "Vous n'êtes pas encore membre d''une entreprise",
  'auth.alert.label':
    "Le compte n'existe pas ou l'utilisateur n''est pas trouvé, veuillez vérifier votre adresse e-mail ou votre mot de passe",
  'auth.alert.label.access.error':
    "Votre accès à la plateforme a été suspendu ou supprimé, veuillez contacter l'administrateur WimTim de votre entreprise.",
  'auth.alert.label.access.max.error':
    "Votre compte a été verrouillé en raison d'un trop grand nombre de tentatives infructueuses.Veuillez réessayer de vous connecter après {countDown} minutes",
  'auth.alert.label.error.password':
    '{number} tentatives de connexion restantes, et vous devrez attendre 30 minutes',
  'auth.change.my.password': 'Modifier mon mot de passe',
  'auth.change.my.password.updated': 'Mot de passe modifié avec succès.',
  'auth.change.my.password.error': 'Erreur durant la modification du mot de passe',

  'auth.two.factor.authentication.success': 'Authentification réussie',
  'auth.two.factor.authentication.error': "Erreur durant l'authentification",
  'auth.two.factor.activation.success': 'Authentification à deux facteurs activée avec succès',
  'auth.two.factor.disabling.success':
    "L'authentification à deux facteurs a été désactivée avec succès",

  'auth.send.email.new.password':
    'Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe',

  'auth.alert.removed.user': 'Vous êtes supprimé de WimTim',
  'auth.alert.suspended.user': 'Vous êtes suspendu de WimTim',
  'auth.alert.deleted.info.user':
    "Vous n'avez plus accès à l'espace de cette entreprise. Veuillez contacter <a>l'administrateur</a>.",
  'auth.alert.suspended.info.user':
    "Votre accès à l'espace de cette entreprise a été suspendu. Veuillez contacter <a>l'administrateur</a>.",
};
export default authentification;
