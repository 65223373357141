const common: Record<string, string> = {
  'common.home': 'Accueil',
  'common.help': 'Aide',
  'common.sign.in': 'Connexion',
  'common.sign.up': "S'enregistrer",
  'common.forgot.password': 'Mot de passe oublié ?',
  'common.label.password.is.required': 'Le mot de passe est requis',
  'common.label.password.rules':
    'Doit contenir au moins 10 caractères, un caractère majuscule, un caractère minuscule, un chiffre et un caractère spécial',
  'common.label.type.your.motto': 'Tapez votre devise',
  'common.forgot.password.label':
    "Veuillez entrer l'adresse e-mail que vous avez enregistrée. Nous vous enverrons votre nouveau mot de passe.",
  'common.have.not.account': "Vous n'avez pas de compte? S'enregistrer",
  'common.label.login': 'Connexion',
  'common.label.request.password': 'Demander un nouveau mot de passe',
  'common.label.password.match': 'Les mots de passe doivent correspondre',
  'common.label.incorrect.password': 'Mot de passe incorrect',
  'common.label.uploading': 'Téléchargement...',
  'common.label.submit': 'Envoyer',
  'common.label.apply': 'Appliquer',
  'common.label.search.status': 'Rechercher un statut',
  'common.label.clear.filter': 'Effacer le filtre',
  'common.label.your.professional.status': 'Votre Statut Professionnel ?',
  'common.label.delete': 'Supprimer',
  'common.label.delete.toproject': 'Enlever du project',
  'common.label.add.toproject': 'Ajouter au project',
  'common.label.remove': 'Enlever',
  'common.label.cancel': 'Annuler',
  'common.label.confirm': 'Confirmer',
  'common.label.order': 'Commande',
  'common.label.close': 'Fermer',
  'common.label.back': 'Retour',
  'common.label.5w': '5W',
  'common.label.desc': 'desc',
  'common.label.okay': "D'accord",
  'common.label.continue': 'Continue',
  'common.label.congrats': 'Félicitations',
  'common.label.required': ' Obligatoire',
  'common.label.ok': 'OK',
  'common.label.ranking': 'Classement',
  'common.label.recent': 'Récent',
  'common.label.see.details': 'Voir détails',
  'common.label.see.history': 'Voir historique',
  'common.label.responsible': 'Responsable',
  'common.label.pts': 'Pts',
  'common.label.my.shop': 'Ma boutique',
  'common.label.got.it': "Je l'ai",
  'common.label.logo': 'Logo',
  'common.label.error': 'Erreur',
  'common.label.view.details': 'Voir détails',
  'common.label.since': 'Depuis',
  'common.connection': 'Connexion',
  'common.label.logout': 'Se déconnecter',
  'common.label.loading': 'Chargement...',
  'common.send.mail': 'Envoyer e-mail',
  'common.resend.mail': "Renvoyer l'e-mail",
  'common.resend.code': 'Renvoyer le code',

  'common.label.input.required': 'Ce champ est obligatoire',
  'common.label.my.weather': 'Ma météo',
  'common.label.coworker': 'Collègues',
  'common.label.no.coworker': 'Pas de collègue',
  'common.label.message': 'Message',
  'common.label.notification': 'Notifications',
  'common.label.inbox': 'Boîte de réception',
  'common.label.sort.by': 'Trier par',
  'common.label.filter.by': 'Filtrer par',
  'common.label.professional.weather': 'Météo professionnelle',
  'common.label.your.professional.weather': 'Votre météo professionnelle ?',
  'common.label.community': 'Équipe',
  'common.label.select.communities':
    "Selectionner les équipes (Tous si aucune équipe n'a été sélectionnée)",
  'common.label.community.concerned': 'Équipe impactée',
  'common.label.community.inCharge': 'Équipe en charge',
  'common.label.community.concerned.or.recipient': 'Équipe impactée ou destinataire',
  'common.label.event': 'Événement',
  'common.label.events': 'Événements',
  'common.label.motto': 'Quelle est la devise de votre journée ?',
  'common.label.description': 'Description',
  'common.label.communities': 'Équipes',
  'common.label.skills': 'Compétences',
  'common.label.add.skill': 'Ajouter une Compétence',
  'common.label.success': 'Succès',
  'common.label.add.success': 'Ajouter un Succès',
  'common.label.passions': 'Passions',
  'common.label.deadline': 'Date limite',
  'common.label.timezone': 'Fuseau horaire',
  'common.label.timezones': 'Fuseaux horaires',
  'common.label.add.passion': 'Ajouter un passion',
  'common.label.switch.company': 'Changer de société',
  'common.label.company.user.already.exists': "L'utilisateur existe déjà dans cette entreprise",
  'common.label.company.already.invited': 'Utilisateur déjà invité pour cette entreprise',
  'common.label.to.company': 'à {name}!',
  'common.label.switch.your.company': "Vous devriez changer d'entreprise.",
  'common.label.previous.companies': 'Sociétés précédentes',
  'common.label.add.previous.company': 'Ajouter une société',
  'common.label.previous.pictures': 'Images',
  'common.label.employee.of.the.day': 'Employé du jour',
  'common.label.welcome': 'Bienvenue',
  'common.label.welcome.new.arrival': 'Bienvenue aux nouveaux arrivants',
  'common.label.access.to.hive': 'Accès à la ruche',
  'common.label.word.from.director': 'Le mot du directeur',
  'common.label.member': 'Membre',
  'common.label.members': 'Membres',
  'common.label.no.member': 'Aucun membre',
  'common.label.add.new.member': 'Ajouter un nouveau membre',
  'common.label.community.role': "Rôle de l'équipe",
  'common.label.community.role.admin': 'Admin',
  'common.label.community.role.member': 'Membre',
  'common.label.community.role.manager': 'Manager',
  'common.label.community.role.secretary': 'Secrétaire',
  'common.label.add': 'Ajouter',
  'common.label.download': 'Télécharger',
  'common.label.archives': 'Archives',
  'common.label.list.view': 'Affichage en liste',
  'common.label.add.issue': 'Ajouter un problème',
  'common.label.add.advice': 'Ajouter un conseil',
  'common.label.add.idea': 'Ajouter une idée',

  'common.label.search.a': 'Rechercher {item}',
  'common.label.search.coworker': 'Rechercher un collègue',
  'common.label.search.project': 'Rechercher un projet',
  'common.label.select.coworkers':
    "Selectionner des collègues (Tous si aucun collègue n'est sélectionné)",
  'common.label.search.coworker.supervisor': 'Rechercher un collègue comme superviseur',
  'common.label.search.business.unit': 'Rechercher un business unit',
  'common.label.search.collaborator': 'Trouver un collaborateur',
  'common.label.welcome.back': 'Bienvenue',
  'common.label.some.suggestion': 'Quelques suggestions :',
  'common.label.joined': 'Joint',
  'common.label.join': 'Join',
  'common.label.all.companies': 'Listes de vos entreprises',
  'common.label.wanna.thank.someone': "Voulez-vous remercier quelqu'un ?",
  'common.label.congrats.wish.sent': 'Félicitations, votre souhait a bien été envoyé',
  'common.label.congrats.thank.sent': 'Félicitations, vos remerciements ont bien été envoyés',
  'common.label.congrats.feedback.sent': 'Félicitations, vos commentaires ont bien été envoyés',
  'common.label.congrats.motto.sent': 'Félicitations, votre devise a bien été envoyée',
  'common.label.congrats.pro.weather.sent': 'Météo professionnelle enregistrée avec succès',
  'common.label.update.professional.status.succes': 'Statut professionnel mis à jour avec succès',
  'common.label.joined.today': "Membre depuis aujourd'hui",
  'common.label.today': "aujourd'hui",
  'common.label.categorie': 'Catégorie',
  'common.label.categories': 'Catégories',
  'common.label.all': 'Tous',
  'common.label.all.my.teams': 'Toutes mes équipes',
  'common.label.laptop': 'Ordinateur portable',
  'common.label.newest': 'Nouveau',
  'common.label.oldest': 'Ancien',
  'common.label.popular': 'Populaire',
  'common.label.new': 'Nouveau',
  'common.label.like': "J'aime",
  'common.label.comment': 'Commentaire',
  'common.label.add.comment': 'Ajouter un commentaire',
  'common.label.confirmation': 'Confirmation',
  'common.label.comments': 'Commentaires',
  'common.label.shares': 'Partager',
  'common.label.send.request': 'Envoyer une demande',
  'common.label.share': 'Partager',
  'common.label.rating': 'notes',
  'common.label.convert.to.action': 'Convertir en action',
  'common.label.convert.to.project': 'Convertir en projet',
  'common.label.converting': 'Conversion',
  'common.label.pictures': 'Images',
  'common.label.filter': 'Filtre',
  'common.label.post.to.show': 'Publier pour afficher',
  'common.label.my.ideas': 'Mes Idées',
  'common.label.my.advices': 'Mes Actions',
  'common.label.my.problems': 'Mes Problèmes',
  'common.label.my.processes': 'Mes Processes',
  'common.label.my.stories': "Mes What's up",
  'common.label.my.questions': 'Mes Questions',
  'common.label.my.have.funs': 'Mes Have funs',
  'common.label.to.do': 'À faire',
  'common.label.in.progress': 'En cours',
  'common.label.done': 'Fait',
  'common.label.below': 'Sous',
  'common.label.at.level': 'Au niveau',
  'common.label.above': 'Au-dessus',
  'common.label.blocked': 'Bloqué',
  'common.label.niveau': 'Level ',
  'common.label.action': 'Action',
  'common.label.actions': 'Actions',
  'common.label.new.update': 'Nouvelle mise à jour',
  'common.label.trend': 'Tendance',
  'common.label.growth': 'Croissance',
  'common.label.no.indicator': 'Aucun indicateur',
  'common.label.indicators': 'Indicateurs',
  'common.label.projects': 'Projets',
  'common.label.no.content.yet': 'Pas encore de contenu',
  'common.label.no.indicator.yet': "Pas encore d'indicateur",
  'common.label.no.community.founded': "Aucune équipe n'a été créée",
  'common.label.no.archive.yet': "Pas encore d'archive",
  'common.label.indicator': 'Indicateur',
  'common.label.history.details': "Détails de l'historique de l'indicateurs",
  'common.label.history.detail': "Détail de l'historique de l'indicateurs",
  'common.label.progress': 'Progrès',
  'common.label.degress': 'degressive',
  'common.label.balanced': 'Équilibré',
  'common.label.no.trend': 'Pas de tendance',
  'common.label.save': 'Sauvegarder',
  'common.label.edit': 'Editer',
  'common.label.problems': 'Problèmes',
  'common.label.problem': 'Problèmes',
  'common.label.idea': 'Idées',
  'common.label.story': "What's up",
  'common.label.advice': 'Conseils',
  'common.label.process': 'Processus',
  'common.label.question': 'Questions',
  'common.label.have.fun': 'Détente',
  'common.label.finalize': 'Finaliser',
  'common.label.low': 'Faible',
  'common.label.medium': 'Moyen',
  'common.label.high': 'Haut',
  'common.label.category': 'Catégorie',
  'common.label.technical.problem': 'Probléme thechnique',
  'common.label.circuit': 'Circuit',
  'common.label.project': 'Projet',
  'common.label.accept': 'Accepter',
  'common.label.accepted': 'Accepté',
  'common.label.decline': 'Décliner',
  'common.label.copied': 'Copié',
  'common.label.no.project': 'Pas de projet',
  'common.label.no.data.available': 'Pas de données disponibles',
  'common.label.no.changes': 'Pas de changement',
  'common.label.deleted': 'Supprimé',
  'common.label.resolved': 'Résolu',
  'common.label.level': 'Niveau',
  'common.label.next': 'Suivant',
  'common.label.previous': 'Précédent',
  'common.label.from': 'de ',
  'common.label.to': 'à',
  'common.label.not.found': 'Non trouvé',
  'common.label.upload.text': 'Déposez le fichier à télécharger ou',
  'common.label.browse': 'Parcourir',
  'common.label.step': 'Étape',
  'common.label.first': 'Première',
  'common.label.second': 'Deuxième',
  'common.label.third': 'Troisième',
  'common.label.forth': 'Quatrième',
  'common.label.last': 'Dernière',
  'common.label.expert': 'Expert',
  'common.label.experts': 'Experts',
  'common.label.validator': 'Validateur',
  'common.label.validators': 'Validateurs',
  'common.label.validated': 'Validé',
  'common.label.issue': 'Problèmes',
  'common.label.issues': 'Problèmes',
  'common.label.my.issues': 'Mes Problèmes',
  'common.label.points': 'Points',
  'common.label.point': 'Point',
  'common.label.upload': 'Déposez le fichier pour le télécharger ou',
  'common.label.multiple.upload': 'Déposez le fichier à télécharger ou',
  'common.label.what.is.up': "What's up",
  'common.label.what.is.up.edited': "Modifier what's up",
  'common.label.shared.to': 'Partagé avec',
  'common.label.shared.to.teams': 'Partagé avec les équipes',
  'common.label.shared.to.users': 'Partagé avec les utilisateurs',
  'common.label.people': 'Personnes',
  'common.label.search': 'Chercher',
  'common.label.year': 'Année',
  'common.label.meeting': 'Réunion',
  'common.label.team.meeting': "Réunion d'équipe",
  'common.label.not.specified': 'Non spécifié',
  'common.label.job.title': "Titre d'emploi",
  'common.label.personality': 'Personnalité',
  'common.label.assign': 'Attribuée',
  'common.label.pending': 'En attente',
  'common.label.solving.in.progress': 'Résolution en cours',
  'common.label.solved': 'Résolu',
  'common.label.test.solution': "Solution d'essai",
  'common.label.parked': 'Rangé',
  'common.label.read.more': 'Lire la suite',
  'common.label.file': 'Fichier',
  'common.label.files': 'Fichiers',
  'common.label.skip': 'Passer',
  'common.label.your.action': 'Vos actions',
  'common.label.add.an.action': 'Ajouter une action',
  'common.label.describe.it': 'Décris la...',
  'common.label.click.to.enter': 'Cliquez pour entrer',
  'common.label.view.all': 'Tout voir',
  'common.label.item': 'Article',
  'common.created-by.label': 'Créé par',
  'common.creator.label': 'Créé par {creator}',

  'common.confirm.dialog.delete.description': 'Voulez-vous vraiment supprimer cet(te) {item}?',
  'common.label.multiple.company':
    'Bienvenue sur Wimtim. Vous êtes membre de plusieurs entreprises, choisissez celle que vous souhaitez utiliser pour cette session.',

  // Placeholder
  'common.label.serch.for.users': 'Rechercher des utilisateurs',
  'common.label.serch.for.something': 'Rechercher quelque chose...',
  'common.placeholder.birthday.wish': 'Lui souhaiter quelque chose',
  'common.placeholder.childbirth.wish': 'Féliciter un nouveau-né',
  'common.placeholder.wedding.wish': 'Féliciter un mariage',
  'common.placeholder.think.about': 'Quelle est la devise de votre journée ?',
  'common.placeholder.thank.someone':
    'Les remerciements doivent faire référence à une action spécifique, à la qualité démontrée et à ses conséquences positives et efficaces.',
  'common.placeholder.no.employee.of.the.day': "Pas d'employé du jour pour aujourd'hui",
  'common.placeholder.no.event.today': "Pas d'événement aujourd'hui",
  'common.placeholder.no.new.arrival.employee.today': "Pas de nouvel employé arrivé aujourd'hui",
  'common.placeholder.no.word.from.director.today':
    "Pas de nouvelles du directeur aujourd'hui ! bonne journée",
  'common.placeholder.wish.welcome': 'souhaite la bienvenue',
  'common.placeholder.type.here': 'Écrivez ici ...',
  'common.placeholder.write.comment': 'Écrire un commentaire',
  'common.placeholder.select.category': 'choisissez une catégorie',
  'common.help.file.size.large': "Taille maximum d'un fichier 100Mo",
  'common.label.name': 'Nom',
  'common.label.lastname': 'Nom',
  'common.label.firstname': 'Prénom',
  'common.label.firstame.is.required': 'Le prénom est requis',
  'common.label.email': 'Email',
  'common.label.create': 'Créer',
  'common.label.email.required': "L'email est requis",
  'common.label.role': 'Rôle',
  'common.label.invite': 'Inviter un utilisateur',
  'common.label.mission': 'Mission',
  'common.label.romance': 'Romance',
  'common.label.tomorrow.romance': 'La romance de demain',
  'common.label.dropzone.image': "Faites glisser l'image ici, ou cliquez pour la sélectionner",
  'common.label.dropzone.image.not-supported':
    'Plusieurs images ou type de fichier non pris en charge',
  'common.label.upload.image': 'Importer des image(s)',
  'common.label.creation': 'Creation',
  'common.label.target': 'Cible',
  'common.label.overdue': 'En retard',
  'common.labe.close.to.target': 'Proche de la date limite',
  'common.label.not.answered': 'Pas de réponse',
  'common.post.dialog.add.placeholder.title.idea': 'Donnez un titre à votre idée ici',
  'common.post.dialog.add.placeholder.content.idea': 'Suggérez une excellente idée',
  'common.post.dialog.add.placeholder.title.advice': 'Donnez un titre à votre conseil ici',
  'common.post.dialog.add.placeholder.content.advice': 'Suggérez un excellent conseil',
  'common.post.dialog.add.placeholder.community': 'Rechercher une équipe concernée',
  'common.post.dialog.add.placeholder.responsible': 'Rechercher un responsable',
  'common.one.to.one.list.view.placeholder.search': 'Rechercher un collaborateur',
  'common.one.to.one.need.dialog.placeholder.describe': 'Décris le...',
  'common.one.to.one.need.dialog.placeholder.title': 'Titre de votre besoin...',
  'common.calendar.owners': 'Rechercher les propriétaires',
  'common.calendar.community.event': "Détails de l'événement d'équipe",
  'common.community.event.community.concerned': 'Équipe impactée',
  'common.community.event.owners': "Propriétaires de l'événements d'équipe",

  'common.meeting.placeholder.send.invitation':
    "Envoyez une invitation par e-mail (les participants externes recevront directement le lien de l'appel à la réunion)",

  // Profile
  'common.label.profile': 'Profile',
  'common.label.edit.profile': 'Modifier le profil',
  'common.no.item': "Pas d'objet",
  'common.label.user.profile': "Profil de l'utilisateur",

  // One to One
  'common.label.my.needs': 'Mes besoins',
  'common.label.my.feedback': 'Mes Feedback',
  'common.label.feedback': 'Feedback',
  'common.label.responsibles': 'Responsables',
  'common.label.finished': 'Achevé',
  'common.label.send.to': 'Envoyer à',
  'common.label.sent.to': 'Envoyé à',
  'common.label.sent.by': 'Envoyé par',
  'common.label.send': 'Envoyer',
  'common.label.collect': 'Collecter',
  'common.label.clarity': 'Clarté',
  'common.label.trust': 'Confiance',
  'common.label.support': 'Support',
  'common.label.reward': 'Reconnaissance',
  'common.label.passion': 'Passion',

  'common.label.recipient.team': 'Équipe bénéficiaire',
  'common.label.concerned.team': 'Équipe impactée',
  'common.label.pending.team': 'Équipe en attente',
  'common.label.expert.team': 'Équipe experte',

  'common.label.draft': 'Brouillon',
  'common.label.view': 'Vue',

  'common.label.share.an.improvement': 'Partager une amélioration',
  'common.label.add.a.new.process': 'Ajouter un nouveau processus',
  'common.label.add.a.new.post': 'Ajouter un nouveau {postType}',
  'common.label.add.a.new.post.idea': 'Ajouter une nouvelle idée',
  'common.label.add.a.new.post.problem': 'Ajouter un nouveau problème',
  'common.label.add.a.new.post.story': "Ajouter un nouveau what's up",
  'common.label.add.a.new.post.question': 'Ajouter une nouvelle ',
  'common.label.add.a.new.post.fun': 'Ajouter un nouveau have fun',
  'common.label.add.a.new.post.advice': 'Ajouter un nouveau conseil',
  'common.label.add.a.new.post.process': 'Ajouter un nouveau processus',
  'common.label.delete.post': 'Supprimer {postType}',

  'common.label.reopened': 'Rouvert',
  'common.label.edit.roadblock': 'Modifier le roadblock',
  'common.label.edit.success': 'Roadblock modifié avec succès',
  'common.error.update.roadblock': 'Erreur lors de la mise à jour du roadblock',
  'common.label.finish': 'Finir',

  'common.label.teams': 'Équipes',
  'common.label.supervisor': 'Superviseur',
  'common.label.my.supervisor': 'Mon Superviseur',
  'common.label.hierachical.superior': 'Supérieurs hiérarchiques',
  'common.label.no.supervisor': 'pas de Superviseur',
  'common.label.presentation.video': 'Video de Présentation',
  'common.label.family.situation': 'Situation Familiale',
  'common.label.biography': 'Biographie',
  'common.label.my.shared.horizon': 'Mon horizon partagé',
  'common.label.business.unit': 'Business unit',
  'common.label.select.business.unit':
    "Selectionner un Business Unit (Tous si aucun n'a été selectionné)",
  'common.label.business.unit-role': 'Rôle de la Business unit',
  'common.label.date': 'Date',
  'common.label.select.date': 'Selectionner une date',
  'common.label.solve': 'Résoudre',
  'common.label.improve': 'Améliorer',
  'common.label.demand': 'Demande',
  'common.label.root.cause.s': 'Cause(s) profonde(s)',
  'common.label.5why': '5Pourquois',
  'common.label.compare': 'Comparer',
  'common.label.target.date': 'Date limite',
  'common.label.signup.success': 'Inscription effectuée avec succès !',

  // Calendar
  'common.label.create.event': 'Créer un évènement',
  'common.label.update.event': 'Mettre à jour un évènement',
  'common.label.add.event': 'Ajouter un évènement',
  'common.label.add.leave': 'Ajouter un congé',
  'common.label.add.meeting': 'Ajouter une réunion',

  // A3
  'common.label.a3': 'A3',
  'common.label.objective': 'Objectif',
  'common.tooltip.objective.title': 'Objectif ?',
  'common.tooltip.plan.title': 'Plan ?',
  'common.tooltip.objective.description':
    "Le résultat spécifique requis pour l'entreprise. Quel est l'objectif ? Comment mesurerez-vous le succès ?",
  'common.label.attach.file': 'Pièce jointe',

  'common.label.analysis': 'Une analyse',
  'common.tooltip.analysis.title': 'Une analyse ?',
  'common.tooltip.analysis.description': "Choisissez l'une des 3 méthodologies",
  'common.label.plan': 'Plan',

  'common.label.follow-up': 'Suivre',
  'common.tooltip.follow-up.title': 'Suivre ?',
  'common.tooltip.analysis.description1':
    "Comment saurons-nous si les actions ont l'impact nécessaire ?",
  'common.tooltip.analysis.description2':
    'Quelles sont les quelques mesures critiques, visuelles et les plus naturelles ?',
  'common.tooltip.analysis.description3':
    'Problèmes restants qui peuvent être anticipés. Des modes de défaillance à surveiller ?',
  'common.tooltip.analysis.description4': 'Des conséquences imprévues ?',
  'common.tooltip.analysis.description5': 'Assurer un P-D-C-A continu.',

  'common.label.your.root.cause.s': 'Vos causes profondes',
  // Indicator
  'common.label.no.history': "Pas d'historique",
  'common.label.title': 'Titre',
  'common.label.value': 'Valeur',
  'common.label.update': 'Mise à jour',
  'common.label.modify': 'Modifié',
  'common.label.add.pictures': 'Ajouter des images',
  'common.label.add.video': 'Ajouter une video',
  'common.label.statistic': 'Statistique',
  'common.label.statistics': 'Statistiques',
  'common.label.access.to.the.shop': 'Accès à la boutique',

  // Chart
  'common.chart.no.earned.exp': "Vous n'avez aucune exp gagnée",
  'common.chart.tooltip.title': 'Règle pour obtenir des points',
  'common.chart.tooltip.description': 'Texte en cours de rédaction',

  'common.information.saved.successfully': 'Informations enregistrées avec succès',

  // Conversion
  'common.label.convert': 'Convertir',
  'common.label.conversion': 'Conversion',
  'common.label.convert.to': 'Conversion vers',

  'common.label.history': 'Historique',
  'common.label.proposed.counter.measure': 'Contre-mesure(s) proposée(s)',
  'common.label.proposed.counter.measure.title': 'Contre-mesure(s) proposée(s) ?',
  'common.label.your.comment': 'Vos commentaires',
  'common.label.comment.it': 'Commentez-le...',

  //Issue
  'common.label.updatedAt': 'Mise à jour le',
  'common.label.author': 'Auteur',
  'common.label.status': 'Status',
  'common.label.handle': 'Manipuler',
  'common.label.validate': 'Valider',
  'common.label.id': 'ID',

  //Process
  'common.label.creator': 'Createur',
  'common.label.n.files': 'N° fichier',
  'common.label.n.comment': 'N° commentaire',
  'common.label.last.edition': 'Dérniere mise à jour',

  // 2FA
  'common.text.received.email':
    'Vous avez reçu un e-mail contenant un code de connexion à deux facteurs. Insérez le code ici pour vous connecter.',
  'common.text.resend.code': "Vous pouvez toujours renvoyer le code si vous ne l'avez pas reçu !",
  'common.two.factor.authentication': 'Authentification à deux facteurs',

  'common.email.sent.successfully': 'E-mail envoyé avec succès',
  'common.company.switched.successfully': "Changement d'entreprise réussi",

  'common.label.position': 'Position',
  'common.order.by.firstname': 'Trier par prénom',

  // Meeting
  'common.label.information': 'Information',
  'common.label.attendees': 'Participants',
  'common.label.external.email': 'Email externes',
  'common.label.details': 'Details',
  'common.label.duration': 'Durée',
  'common.label.period': 'Plage de dates',
  'common.label.pass.code': "Code d'accès",
  // Question
  'common.label.answer': 'Répondre',
  'common.label.ask.a.question': 'Poser une question',
  'common.label.search.a.question.or.ask': 'Rechercher une question ou demander',
  'common.placeholder.write.an.answer': 'Ecrire une réponse',
  'common.ask.your.question.here': 'Poser votre question ici...',

  'common.label.birthday': 'Anniversaire',
  'common.label.idea.rating.period': 'Période de vote',
  'common.label.team': 'Équipe',
  'common.label.owner': 'Propriétaire',
  'common.label.owners': 'Propriétaires',
  'common.label.our.shared.horizon': 'Notre horizon commun',

  // Not found
  'common.item.not.found': 'Objet non-trouvé',
  'common.item.has.been.removed': 'Cet élément a été supprimé',

  // Badge
  'common.label.badges': 'Badges',
  'common.label.achievements': 'Réalisations',
  'common.label.special': 'Special',
  'common.label.points.collection': 'Collecte de points',
  'common.label.see.all.badges': 'Voir tous les badges',

  // Admin
  'common.label.company': 'Companie',
  'common.label.users': 'Utilisateurs',
  'common.label.employee.day': 'Employé du jour',
  'common.label.tunnels': 'Tunnels',
  'common.label.coach': 'Coach',
  'common.label.business.units': 'Business Units',
  'common.label.shop': 'Boutique',
  'common.label.subscription': 'Abonnement',
  'common.label.admin.panel': "Panneau d'administration",
  'common.label.access': 'Accéder',
  'common.label.hierarchical.superior': 'Supérieur hiérarchique',
  'common.label.company.role': "Rôle dans l'entreprise",
  'common.label.suspend': 'Suspendre',
  'common.label.hiring.date': "Date  d'embauche",
  'common.label.user.blocker.update.state.success': 'Utilisateur bloqué avec succès',
  'common.label.user.blocker.update.state.error': "L'utilisateur ne peut pas être bloqué",
  'common.label.comming.soon.tag': 'Bientôt disponible',
  'common.label.application': 'Application',

  // User Access
  'common.label.simple.user': 'Simple utilisateur',
  'common.label.admin.user': 'Administrateur',
  'common.label.company.structure': "Structure de l'entreprise",

  // Company Member Role
  'common.label.director': 'Directeur',
  'common.label.employee': 'Employé',
  'common.label.financial.controller': 'Contrôleur financier',
  'common.label.user.deleted.success': 'Utilisateur supprimé avec succès !',
  'common.label.user.deleted.error': "Erreur lors de la suppression de l'utilisateur !",
  'common.label.company.member.updated.success': 'Rôle mis à jour avec succès !',
  'common.label.company.member.updated.error': 'Erreur lors de la mise à jour du rôle !',
  'common.label.update.user.access.success': 'Accès utilisateur mis à jour avec succès !',
  'common.label.update.user.access.error': "Erreur lors de la mise à jour de l'accès utilisateur !",
  'common.label.update.user.hiring.date.success': "Date d'embauche mis à jour avec succès!",
  'common.label.update.user.hiring.date.error':
    "Erreur lors de la mise à jour de la date d'embauche!",
  'common.label.update.invitation.access.success': 'Accès aux invitations mis à jour avec succès !',
  'common.label.update.invitation.access.error':
    "Erreur lors de la mise à jour de l'accès à l'invitation !",
  'common.label.update.invitation.role.success': "Rôle d'invitation mis à jour avec succès !",
  'common.label.update.invitation.role.error':
    "Erreur lors de la mise à jour du rôle d'invitation !",

  'common.label.start': 'Démarrer',
  'common.label.ending': 'Finir',
  'common.label.yes': 'Oui',
  'common.label.no': 'Non',
  'common.label.rejected': 'Rejeté',

  //  Top right header icons (coming soon) just before coworker icon
  'common.label.guides': 'Guides (à venir)',
  'common.label.help.marks': "Marques d'aide",
  'common.label.settings': 'Réglages',

  'common.label.snapshot': 'Instantané',
  'common.label.completed': 'Complété',

  'common.label.release.notes': 'Notes de version',

  //helpmark
  'common.goal.and.kpi': 'Indicateurs and KPIs',
  'common.team.coins': "Pièces d'équipe",
  'common.issue.exchange': "Changement de l'idée",
  'common.general': 'General',
  'common.support': 'Soutien',
  'common.reward': 'Récompense',
  'common.trust': 'Confiance',
  'common.clarity': 'Clarté',
  'common.road.block': 'Blocages',
  'common.a3.process': 'Processus A3',
  'common.goal': 'Indicateur',
  'common.mission.and.action': 'Missions & Actions',

  'common.label.finalized': 'Finalisé',
  'common.label.not.finalized': 'Non finalisé',

  'common.label.response': 'Réponse',
  'common.label.content': 'Contenu',
  'common.label.received.from': 'Reçu de',
  'common.label.or': 'ou',
  'common.label.show': 'Voir',
  'common.label.in.charge': 'En charge',
  'common.label.attached.files': 'Fichiers attachés',
  'common.label.images': 'Images',
  'common.daterange.not.selected': 'Aucune plage de dates sélectionnée !',
  'common.daterange.range': '{dateStart} à {dateEnd}',

  'common.label.idea.title': "Titre de l'idée",
  'common.label.advice.title': 'Titre du conseil',
  'common.label.meeting.title': 'Titre de la réunion',
  'common.label.event.name': "Nom de l'évènement",
  'common.label.issue.title': 'Titre du probléme',
  'common.label.project.title': 'Titre du projet',
  'common.label.proccess.title': 'Titre du process',
  'common.label.what.is.up.title': "Titre du What's up",
  'common.label.mission.title': 'Titre de la mission',
  'common.label.question.title': 'Titre de la question',

  'common.languages': 'Langues',

  'common.Notifications': 'Notifications',

  'common.label.my.teams': 'Mes équipes',
  'common.label.all.teams': 'Tous les équipes',
  'common.label.other.teams': 'Autre équipes',
  'common.label.all.other.teams': 'Tous les autres équipes',
  'common.label.visible.to.everyone': 'Visible pour tout le monde',

  'common.paste.image.link': "Collez le lien d'une image ici",
  'common.label.image.link': "Lien d'image ",
  'common.label.upload.an.image': 'Téléverser une image ',
  'common.label.upload.insert.image': 'Téléverser/Insérer une image',

  'common.label.export.to.xlsx': 'Télécharger au format .xlsx',

  'common.label.comments.privacy': 'COMMENTAIRES PRIVE',
  'common.label.public': 'Publique',
  'common.label.private': 'Privé',
  'common.label.on': 'Oui',
  'common.label.off': 'Non',

  'common.label.external': 'Externe',

  'common.label.type': 'Type',
  'common.label.updated.at': 'Mis à jour à',
  'common.label.created.at': 'Créé à',
  'common.label.created.by': 'Créé par',
  'common.label.database': 'À faire',
  'common.label.my.items': 'Mes items',
  'common.label.my.actions': 'Mes Actions',
  'common.label.indication': 'Indications',
  'common.label.my.indication': 'Mes indications',
  'common.label.checked': 'Chequé',
  'common.label.not.checked': 'Non chequé',
  'common.label.items': 'Items',
  'common.label.action.team': "Action d'équipe",
  'common.label.project.team': 'Action de projet',
  'common.label.one.to.one.team': 'Action individuelle',
  'common.label.road.load.team': 'Action de barrage routier',
  'common.label.pure.action.team': 'Action pure',
  'common.label.indication.team': "Action de l'indicateur",
  'common.label.issue.team': 'Action de problème',
  'common.label.action.type': "Type d'action",
  'common.label.action.idea': "Action d'idée",
  'common.label.coworkers': 'Associés',
  'common.label.undo': 'Annuler',
  'common.label.show-more': 'Voir plus',
  'common.label.show-less': 'Voir moins',
  'common.label.counter': 'Compteur',
  'common.label.date.from.your.timezone': 'Date de votre fuseau horaire',
  'common.label.date.from.meeting.timezone': 'Date du fuseau horaire de la réunion',
  'common.label.company.email.not.send': 'Email non envoyé',
  'common.label.empty': 'Vide',
  'common.label.where.are.you.working.for.today': "Où travaillez-vous aujourd'hui ?",

  'common.unread.notification': 'Non lue',
  'common.unread.notification.mark.all.as.read': 'Tout marquer comme lu',
  'common.read.notification': 'Lue',
  'common.no.read.notification': 'Aucune notification lue',
  'common.no.unread.notification': 'Aucune notification non lue',
  'common.label.find': 'Recherche',
  'common.label.filter.preset': 'Filtres prédéfinis',
  'common.label.untitled': 'Sans titre',
  'common.label.select.type': 'Sélectionner le type',
  'common.label.reset.filters': 'Réinitialiser les filtres',
  'common.label.filters': 'Filtres',
  'common.label.date.range': 'Plage de dates',
  'common.label.select.date.range': 'Sélectionner une plage de dates',
  'common.label.apply.filters': 'Appliquer les filtres',
  'common.no.item.found': 'Aucun élément trouvé',
  'common.date.and.time': 'Date et temps',
  'common.search.key.words': 'Recherche par mot clé',
  'common.owners.and.creator': 'Propriétaires et créateur',

  'common.label.all.fields': 'Tous les champs',
  'common.label.family': 'Famille',
  'common.label.adress': 'Adresse',
  'common.label.activated': 'Activé',
  'common.label.deactivated': 'Désactivé',
  'common.label.configure': 'Configurer',
  'common.leave.export.count.weekend': "Décompte du weekend sur l'exportation du congé",
  'common.label.weekend.is.not.counted': 'Le weekend ne compte pas',
  'common.label.only.saturday.is.counted': 'Seul le samedi est compté',
  'common.label.saturday.and.sunday.are.counted': 'Le samedi et le dimanche sont comptés',
  'common.label.incoming.call': 'Appel entrant',
  'common.label.join.room.call': "Rejoindre l'appel en salle",
  'common.label.host': 'Hôte',
  'common.label.participants': 'Participants',
  'common.label.guests': 'Invités',
  'common.label.call': 'Appel',
  'common.label.rooms': 'Salles',
  'common.label.search.a.room': 'Rechercher une salle',
  'common.label.open.a.room': 'Ouvrir une salle',
  'common.label.edit.room': 'Editer la salle',
  'common.label.room.informations': 'Informations de la salle',
  'common.label.give.the.room.a.name': 'Donnez un nom à la salle',
  'common.label.give.the.room.a.topic': 'Donnez un sujet à la salle',
  'common.label.room.details': 'Détails de la salle',
  'common.label.room.edition': 'Salle modifiée avec succès',
  'common.label.room.deletion': 'Salle supprimée avec succès',
  'common.label.days': 'Jours',
  'common.label.daytime': 'Temps',
  'common.label.no.subordinate': 'Pas de subordonné',
  'common.label.superior': 'Supérieur',
  'common.label.phone': 'Numéro de téléphone',
  'common.label.exp': 'Exp',
  'common.label.experience.evolution': 'Évolution de l’expérience',
  'common.label.next.level.in': 'Prochain niveau dans',
  'common.label.rules.for.obtaining.points': 'Règle pour obtenir des points',
  'common.label.rules.for.obtaining.points.description':
    "Entrelacé de concepts culturels clés autour de la langue, de l’apprentissage et de la vie. Grâce à des leçons entièrement interactives, vous vous entraînerez à parler maori dans un groupe de soutien et développerez votre confiance dans l'utilisation du Te Reo dans les situations quotidiennes du kiwi.",
  'common.label.you.reached.a.new.level': 'Vous avez atteint un niveau supérieur',
  'common.label.your.team.reached.a.new.level': 'Votre équipe {team} a atteint un niveau supérieur',
  'common.label.you.reached.a.new.level.description':
    'Continuez dans cette voie vers la sagesse professionnelle ! Collectez {exp} exp pour accéder au {badge} ! Vous avez débloqué une boîte mystère',
  'common.label.view.mystery.box': 'Voir la boîte mystère',
  'common.label.wholeCompany.name': 'Tous le monde',

  'common.label.weather.rain': 'Pluie',
  'common.label.weather.cloudy': 'Nuageux',
  'common.label.weather.windy': 'Venteux',
  'common.label.weather.cloud': 'Nuage',
  'common.label.weather.thunderstorm': 'Orage',
  'common.label.weather.unknown': 'Inconnue',
  'common.label.weather.sunny': 'Soleil',

  'common.label.ratio': 'Ratio',
};
export default common;
