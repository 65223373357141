const preference: Record<string, string> = {
  'preference.user.title': "Préférences de l'utilisateur",
  'preference.user.personal.time.zone': 'Fuseau horaire personnel',
  'preference.user.coach': 'Coach',
  'preference.user.active.hours': 'Heures actives',
  'preference.user.active.hours.description':
    'Spécifiez une période de temps après laquelle votre statut professionnel sera automatiquement réinitialisé à "Disponible".',
  'preference.user.timezone.description':
    "Toutes les dates et heures à l'intérieur de la plate-forme suivront ce fuseau horaire.",
  'preference.user.update.success': 'Votre préférence a été mise à jour avec succès',
  'preference.coach.update.activation.success':
    'Vos préférences de coach ont étées mises à jour avec succès',

  'preference.user.two.factor.authentication': 'Authentification à deux facteurs',
  'preference.user.enable.two.step.authentication':
    "Activer l'authentification en deux étapes (2FA)",
  'preference.user.two.factor.description':
    "Protection utilisée pour assurer la sécurité de votre compte en plus de l'e-mail et du mot de passe",

  'preference.user.tunnels.subheader':
    'Les Tunnels vous permet de rester au courant de ce qui se passe dans votre entreprise.',
  'preference.ajust.active.hours': 'Ajuster les heures actives',
  'preference.ajust.active.hours.description':
    "Vous serez actif pendant ces heures après l'avoir défini manuellement",
  'preference.ajust.active.hours.to': 'à',
  'preference.user.tunnel.activate.deactivate.entry': "Activer ou désactiver le tunnel d'entrée",
  'preference.user.tunnel.activate.deactivate.coach': "Activer ou désactiver l'assistance du Coach",
  'preference.user.tunnel.activate.deactivate.coach.description':
    'Le coach est un assistant personnel qui peut apparaître à chaque fois que vous avez besoin de son aide.',
  'preference.user.tunnel.activate.deactivate.entry.description':
    "Définissez l'heure à laquelle le tunnel d'entrée apparaît. C'est mieux le matin.",
  'preference.user.tunnel.activate.deactivate.exit': 'Activer ou désactiver le tunnel de sortie',
  'preference.user.tunnel.activate.deactivate.exit.description':
    "Réglez l'heure à laquelle le tunnel de sortie apparaît. C'est mieux le soir.",

  'preference.user.languages.description':
    'Choisissez la langue utilisée dans votre WimTim. Cette langue sera utilisée dans tous les contenus WimTim (textes de la plateforme, emails, notifications,...)',
  'preference.user.personal.languages': 'Langues',
  'preference.user.Notifications.description':
    'Activer ou désactiver les différents type de notification',
  'preference.user.notifications.activate.deactivate.contextual':
    'Activer ou désactiver les notifications contextuel',
  'preference.user.notifications.activate.deactivate.navigateur':
    'Activer ou désactiver les notifications du navigateur',
  'preference.user.notifications.activate.deactivate.navigateur.description':
    "Les notifications du navigateur apparaissent sur votre écran lorsque vous n'êtes pas actif dans WimTim, mais que le site est ouvert dans l'onglet ou la fenêtre de votre navigateur.",
  'preference.user.notifications.activate.deactivate.contextual.description':
    "Les notifications contextuelles apparaissent sur votre écran lorsque vous n'êtes pas actif dans HubSpot.",
  'preference.contextual.notification.update.success':
    'Vos préférences de notification contextuelles ont étées mises a jour avec succès',
  'preference.navigateur.notification.update.success':
    'Vos préférences de notification du navigateur ont étées mises a jour avec succès',
};

export default preference;
