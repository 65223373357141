const dialog: Record<string, string> = {
  'dialog.input.placeholder.title': 'Titre',
  'dialog.input.placeholder.description': 'Description',
  'dialog.input.placeholder.status': 'Status',
  'dialog.input.placeholder.priority': 'Priorité',
  'dialog.input.placeholder.who': 'Avec qui avez-vous le problème ?',
  'dialog.input.placeholder.what': "Quel est l'objet du problème ?",
  'dialog.input.placeholder.how': "Comment le problème s'est-il produit ?",
  'dialog.input.placeholder.where': 'Où le problème a eu lieu ?',
  'dialog.input.placeholder.when': 'Quand le problème est-il survenu ?',
  'dialog.input.placeholder.impact': 'Quels sont les impacts du problème ?',

  'dialog.input.search.placeholder.community': 'Rechercher une équipe impactée',
  'dialog.input.search.placeholder.pending.community': 'Rechercher une équipe en attente',
  'dialog.input.search.placeholder.responsible': 'Rechercher un responsable',
  'dialog.input.search.placeholder.coworker.to.select':
    'Collaborateur à sélectionner comme propriétaire de la question (Responsable)',
  'dialog.input.search.placeholder.experts': 'Rechercher un expert',
  'dialog.input.search.placeholder.experts.that.help.solve.the.issue':
    'Les collègues de travail qui peuvent être sollicités pour aider à résoudre le problème.',
  'dialog.input.search.placeholder.validators': 'Rechercher un validateur',
  'dialog.input.search.placeholder.community.source': 'Rechercher une équipe source',
  'dialog.input.search.placeholder.community.sentinel':
    'Des équipes qui ont besoin que le problème soit résolu (Sentinelle)',
  'dialog.input.search.team': 'Rechercher une équipe',
  'dialog.input.search.colaborator.or.team': 'Rechercher un collaborateur ou une équipe',
  'dialog.input.search.colaborator': 'Rechercher un collaborateur',

  'dialog.input.date.placeholder.target.date': 'Date limite',
  'dialog.input.select.placeholder.owner': 'Selectionner un collègue',

  'dialog.fieldset.label.community': 'Équipe',
  'dialog.fieldset.label.reponsible': 'Responsable',
  'dialog.fieldset.label.colaborator': 'Collaborateur',

  'dialog.project.create.title': 'Créer un projet',
  'dialog.project.edit.title': 'Modifier project',
  'dialog.project.convert.title': 'Convertir en projet',
  'dialog.project.create.success': 'Projet créé avec succès',
  'dialog.project.create.edit': 'Projet modifié avec succès',

  'dialog.idea.convert.success': 'Idée convertie avec succès',
  'dialog.post.convert.success': 'Post convertie avec succès',

  'dialog.error.required': 'Obligatoire',
  'dialog.error.invalid.date': 'Date invalide',
  'dialog.error.invalid.time': 'Heure invalide',
  'dialog.error.start.date.cannot.be.past.end.date':
    'La date de début ne peut pas être aprés la date de fin',
  'dialog.error.end.date.cannot.be.before.start.date':
    'La date de fin ne peut pas être avant la date de début',
  'dialog.error.birthday': "Assurez-vous que l'anniversaire est au moins hier",

  'dialog.label.well.done': 'Bien joué',
  'dialog.order.congrats.label':
    "Félicitations pour votre commande. Veuillez vous présenter au service d'assistance technique pour recevoir l'article",

  'dialog.congrats': 'Félicitations',
  'dialog.congrats.idea': 'Votre idée a été publiée',
  'dialog.congrats.problem': 'Votre problème a été publié',
  'dialog.congrats.story': "What's up a été publié avec succès",
  'dialog.congrats.advice': 'Votre conseil a été publié',
  'dialog.congrats.process': 'Votre processus a été publié',
  'dialog.congrats.question': 'Votre question a été publiée',
  'dialog.congrats.have.fun': 'Votre have fun a été publié',
  'dialog.order.error.label': "Vous n'avez pas assez de points pour commander cet objet",

  'dialog.action.convert.title': 'Convertir en action',

  'dialog.button.convert': 'Convertir',

  'dialog.input.label.community.source': 'Équipe source',
  'dialog.input.label.team.in.charge': 'Équipe en charge',
  'dialog.input.label.your.password': 'Votre mot de passe',

  'dialog.title.add.a.new.process': 'Ajouter un nouveau processus',
  'dialog.title.edit.process': 'Modifier le processus',
  'dialog.title.add.an.issue': 'Ajouter un problème',
  'dialog.title.edit.issue': 'Editer le problème',
  'dialog.title.change.profile.s.picture': "Modifier l'image du profil",
  'dialog.title.personal.information': 'Information Personelle',
  'dialog.title.detailed.information': "Détails de l'information",

  'dialog.title.presentation.file': 'Fichier de présentation',
  'dialog.title.attached.files': 'Fichiers joints',
  'dialog.browse.to.presentation.file': 'vers le fichier de présentation',
  'dialog.browse.to.attached.files': 'aux fichiers joints',
  'dialog.browse.for.your.profile.s.picture': 'pour les photos de votre profil',
  'dialog.browse.for.the.business-unit-logo': 'pour le logo de la BU',
  'dialog.browse.to.add.pictures': 'Pour ajouter une image',
  'dialog.browse.to.add.video': 'Pour ajouter une vidéo',

  'dialog.title.person.who.liked': 'Personne qui a aimé',
  'dialog.title.password': 'Mot de passe',

  'dialog.idea.input.title': 'Donnez un titre à votre idée',
  'dialog.problem.input.title': 'Donnez un titre à votre problème',
  'dialog.story.input.title': "Donnez un titre à votre what's up",
  'dialog.advice.input.title': 'Donnez un titre à votre conseil',
  'dialog.process.input.title': 'Donnez un titre à votre processus',
  'dialog.question.input.title': 'Donnez un titre à votre question',

  'dialog.input.give.a.title': 'Donnez un titre',
  'dialog.input.describe.it': 'Décrivez-le',

  'dialog.process.input.description': 'Décrivez votre processus',
  'dialog.shop.confirm.description': 'Êtes-vous sûr de vouloir commander cet article',
  'dialog.event.drop.confirm.description': 'Êtes-vous sûr de déplacer cet événement à cette date',

  'dialog.calendar.create.event': 'Créer un événement',
  'dialog.calendar.update.event': 'Mettre à jour un événement',
  'dialog.project.edit.success': 'Projet édité avec succès',

  'dialog.title.delete.comment': 'Supprimer commentaire',
  'dialog.post.delete.idea': 'Voulez-vous vraiment supprimer cette excellente idée?',
  'dialog.post.delete': 'Voulez-vous vraiment supprimer cette excellent(e) {postType}?',

  'dialog.title.add.a.new.question': 'Ajouter une nouvelle question',
  'dialog.title.edit.question': 'Modifier la question',
  'dialog.title.badges': 'Badges',
  'dialog.title.badge': 'Badge',

  'dialog.title.delete.user': 'Supprimer Utilisateur',
  'dialog.title.team.details': "Détails de l'équipe",

  // Leave
  'dialog.title.leave.creation': 'Création de congés',
  'dialog.title.leave.details': 'Détails du congé',
  'dialog.input.start.date': 'Date de début',
  'dialog.input.ending.on': 'Se terminant le',
  'dialog.input.reasons.of.the.leave': 'Raison du congé',
  'dialog.checkbox.special.day.off': 'Journée spéciale de congé',
  'dialog.label.people.to.notify': 'Personne à notifier',
  'dialog.label.other.validators': 'Autre validateurs',
  'dialog.browse.to.add.document.for.the.reason': 'Pour ajouter un document à la raison',
  'dialog.create.leave.success': 'Congé créé avec succès',
  'dialog.create.leave.error': "Erreur lors de la création d'un congé",
  'dialog.label.other.reasons': 'Autre raison',
  'dialog.title.delete.leave': 'Suppression du congé',
  'dialog.label.is.a.special.leave': "C'est un congé spécial?",
  'dialog.validate.leave.success': 'Status du congé mis à jour avec succès',
  'dialog.validate.leave.error': 'Erreur lors de la mise à jour du status du congé',
  'dialog.title.leave.edition': 'Modifier le congé',
  'dialog.edit.leave.success': 'Congé modifié avec succès',
  'dialog.edit.leave.error': 'Erreur lors de la modification du congé',
  'dialog.delete.leave.success': 'Congé supprimé avec succès',
  'dialog.delete.leave.error': 'Erreur lors de la suppression du congé',
  'dialog.title.leave.deletion': 'Supprimer le congé',
  'dialog.title.leave.download': 'Télécharger les congés',
  'dialog.download.leave.success': 'Congés téléchargés avec succès',
  'dialog.download.leave.error': 'Erreur lors du téléchargment des congés',
  'dialog.download.leave.warning': "Il n'y a pas de congés validés sur cette plage de dates",

  'dialog.label.this.item.has.been.deleted': 'Cet élément a été supprimé',
  'dialog.label.empty': 'Vide',

  // Business unit
  'dialog.create.business-unit.success': 'BU créée avec succès',
  'dialog.update.business-unit.success': 'BU mise à jour avec succès',
  'dialog.delete.business-unit.success': 'BU supprimée avec succès',
  'dialog.create.business-unit.error': 'Erreur lors de la création de la  BU',
  'dialog.update.business-unit.error': 'Erreur lors de la mise à jour de la BU',
  'dialog.delete.business-unit.error': 'Erreur lors de la suppression de la BU',
  'dialog.add-member.business-unit.success': 'Membre ajouté avec succès',
  'dialog.update-member.business-unit.success': 'Membre mis à jour avec succèsy',
  'dialog.add-member.business-unit.error': "Erreur lors de l'ajout d'un membre à une BU",
  'dialog.update-member.business-unit.error': 'Erreur lors de la mise à jour des membres de la BU',
  'dialog.remove-member.business-unit.success': 'Membre supprimé avec succès',
  'dialog.remove-member.business-unit.error': "Erreur lors de la suppression d'un membre de la BU",

  'dialog.label.view-all.image': 'Voir toutes les images de {item}',
  'dialog.checkbox.make.public': 'Rendre public',
  'dialog.update.issue.privacy.success': 'Confidentialité du problème mis à jour avec succès',
  'dialog.update.issue.privacy.error':
    'Erreur lors de la mise à jour de la confidentialité des problèmes',

  // action
  'dialog.title.delete.action': "Supprimer l'action",

  'dialog.coworker.input.placeholder': 'Rechercher et ajouter un collègue',

  // application
  'dialog.application.details': "Détails de l'application",

  // badge
  'dialog.badge.edit.title': 'Modifier badge',
  'dialog.badge.edit.success': 'Badge modifié avec succès',
  'dialog.badge.edit.error': 'Erreur lors de la modification du badge',

  // Exp event
  'dialog.member.exp.event.title': 'Modifier un événement utilisateur',
  'dialog.team.exp.event.title': "Modifier un événement d'équipe",
};
export default dialog;
