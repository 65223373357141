export type TFileRejectionCode = 'file-too-large' | 'file-invalid-type' | 'too-many-files';

export type TUploadedFile = {
  url: string;
  name: string;
  size: number | undefined;
  type: string | undefined;
};

export const uploadRejectionMessageId: Record<TFileRejectionCode, string> = {
  'file-too-large': 'error.file.is.larger.than.undred.mb',
  'file-invalid-type': 'error.file.invalid.type',
  'too-many-files': 'error.file.too.many.files',
};

export const getUploadRejectionMessageId = (code: TFileRejectionCode, maxSize?: boolean) => {
  if (maxSize && code === 'file-too-large') {
    return 'error.file.is.larget.than.max.size';
  }
  return uploadRejectionMessageId[code];
};
