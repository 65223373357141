const admin: Record<string, string> = {
  'admin.label.company.logo': 'Company logo',
  'admin.label.company.mission': 'Company mission',
  'admin.label.company.romance': 'Company romance',
  'admin.label.company.name': 'Company name',
  'admin.label.company.description': 'Company description',
  'admin.label.company.email': 'Company email',
  'admin.label.company.upload.logo': 'to add company logo',
  'admin.label.company.slug': 'Company slug',
  'admin.label.company.update.success': 'Company updated with success!',
  'admin.label.company.update.error': 'Error on update company!',
  'admin.label.company.reporting': 'Reporting',
  'admin.label.company.download.excel': 'Download the company report in Excel format',
  'admin.label.company.image.repository': 'Image Repository',
  'admin.label.delete.image': 'Delete image',
  'admin.label.large.file': 'File is larger than 10Mb',
  'admin.dialog.image.deleted.success': 'Image deleted successfully',
  'admin.dialog.image.deleted.error': 'Error while deleting image',
  'admin.dialog.user.access.required': 'User access required',
  'admin.dialog.member.role.required': 'Member role required',
  'admin.dialog.set.welcom.screen.success': 'The welcome screen has been chosen with sucess!',
  'admin.dialog.set.welcom.screen.error': 'Error on choose welcom screen!',
  'admin.dialog.confirm.delete.user':
    'Do you really want to delete {companyMemberEmail} from your company ?',
  'admin.button.label.shuffle': 'Shuffle',
  'admin.button.label.force': 'Force',
  'admin.label.employee': 'Employee',
  'admin.label.date': 'Date',
  'admin.label.email': 'Email',
  'admin.label.status': 'Status',
  'admin.update.rank.success': 'Rank updated successfully',
  'admin.update.rank.error': 'Error on updating rank',
  'admin.label.waiting': 'Waiting',
  'admin.label.pending': 'Pending',
  'admin.label.accepted': 'Accepted',
  'admin.label.decline': 'Decline',
  'admin.update.rank.status.error': "This employee can't be moved",
  'admin.employee.of.the.day.forced.success': 'Employee of the day forced successfully',
  'admin.employee.of.the.day.forced.error': 'Error on force employee of the day',
  'admin.employee.of.the.day.forced.status.error': "This employee can't be forced",
  'admin.label.company.indicators': 'Company indicators',
  'admin.label.company.add.new.indicator': 'Add a new indicator',
  'admin.label.delete.goal': 'Delete indicator',
  'admin.label.delete.goal.success': 'Indicator deleted successfully',
  'admin.label.delete.goal.error': 'Error on deleting indicator',
  'admin.label.our.shared.orizon': 'Our shared horizon',
  'admin.label.set.as.welcome.screen': 'Set as welcome screen',
  'admin.label.no.word.from.ceo': 'No word from director',
  'admin.word.from.ceo': 'Word from director',
  'admin.word.from.ceo.update.success': 'Word of director updated with success!',
  'admin.word.from.ceo.update.error': 'Error on update word of director!',
  'admin.placeholder.ceo.word': 'Type here word of director',
  'admin.tabs.button.tooltip.title':
    'The Admin is a management space for users with "Admin" access to the company\'s Orizon Instance',

  /** Hive */
  'admin.hive.post.no.item': 'No post',
  'admin.hive.post.no.result': 'No result on post list',
  'admin.hive.delete.post.title': 'Delete post',
  'admin.hive.update.post.title.visibility': 'Update post visibility',
  'admin.hive.request.post.title': 'Delete the request post',
  'admin.hive.delete.post.description': 'Would you delete post named "{name}"',
  'admin.hive.delete.post': 'Delete post named "{name}"',
  'admin.hive.update.post.description': 'Would you update visibility of post named "{name}"',
  'admin.hive.request.post.description': 'Would you delete the request of post named "{name}"',
  'admin.hive.manage.categories': 'Manage categories',
  'admin.hive.category.placeholder': 'Type a category',
  'admin.hive.idea.categories.title': 'Manage idea categories',
  'admin.hive.issue.categories.title': 'Manage issue categories',
  'admin.hive.advice.categories.title': 'Manage advice categories',
  'admin.hive.question.categories.title': 'Manage question categories',
  'admin.hive.delete.category': 'Delete "{title}"',
  'admin.hive.categories.list.title': 'Categories',
  'admin.hive.deleted.post': 'Post deleted with success',
  'admin.hive.deleted.request': 'Request deleted with success',
  'admin.hive.deleted.post.error': 'Error on delete post',
  'admin.hive.deleted.request.error': 'Error on delete request',
  'admin.hive.update.visibility.post.success': 'Post visibility updated with success',
  'admin.hive.update.visibility.post.error': 'Error on change post visibility',
  'admin.hive.communities.sharing': 'Communities for sharing',
  'admin.hive.members.sharing': 'Members for sharing',

  /** Tunnel */
  'admin.tunnel.placeholder.search.director': 'Search a director',
  'admin.tunnel.director.setted.success': 'Company director setted with success',

  /** User */
  'admin.user.no.item': 'No data',
  'admin.user.no.result': 'No result on user list',

  'admin.member.has.been.restored': 'Member has been restored',

  'admin.badge.edit.event': 'Edit event',
  'admin.badge.delete.event': 'Delete event',
  'admin.event.no.result': 'No result on event list',
};
export default admin;
