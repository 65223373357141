import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  root: {
    height: 'auto',
    overflow: 'auto',
  },

  tabsRoot: {
    width: 'clamp(200px, 15.6vw, 300px)',
  },

  tabsScroller: {
    color: '#FFF',
  },

  tab: {
    height: 48,
    background: theme.palette.background.paper,
    marginBottom: 4,
    maxWidth: '100%',
    textAlign: 'start',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
