import { Maybe } from 'graphql/generated/graphql';

export const parseFilename = (filename: Maybe<string>): { name: string; extension: string } => {
  if (filename) {
    const nameAsTab = filename.split('.');
    const extension = nameAsTab.length ? nameAsTab[nameAsTab?.length - 1] : '';
    const name = filename.split(`.${extension}`)?.[0] ?? '';
    return { name, extension };
  }
  return { name: '', extension: '' };
};
