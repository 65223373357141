export type TChipsValue = {
  value: string | undefined;
  label: string | null | undefined;
};

export enum TActionForPost {
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  REQUEST= 'REQUEST',
}

export enum TProblemFilter {
  CATEGORY = 'Category',
  CIRCUIT = 'Circuit',
  LEVEL = 'Level',
  SOLVE = 'Solve',
  IMPROVE = 'Improve',
  REMOVED = 'Removed',
  FINALIZED = 'Finalized',
}
