// To distinct same query name
export const CACHE_KEY = 'cacheKey';

export const COMMUNITY_ID_CACHE_KEY = 'communityId';
export const IS_COMPANY_MEMBER_ID_CACHE_KEY = 'isMyAction';
export const TEAM_ID_CACHE_KEY = 'teamId';
export const POST_ID_CACHE_KEY = 'postId';
export const PROJECT_ID_CACHE_KEY = 'projectId';
export const FILTER_CACHE_KEY = 'filter';
export const POST_TYPE_CACHE_KEY = 'postType';
// CoworkersMember query
export const COWORKERS_TAB_LIST_CACHE_KEY = 'coworker-tab-list-cache-key';
export const PEOPLE_CACHE_KEY = 'peole-cache-key';
export const ONETOONE_COWORKERS_CACHE_KEY = 'onetoone-coworkers-cache-key';

// currentCompanyProblems query
export const ISSUES_DATABASE_CACHE_KEY = 'issues-database-cache-key';
export const HIVE_ISSUES_CACHE_KEY = 'hive-issues-cache-key';
export const TEAM_ISSUES_CACHE_KEY = 'team-issues-cache-key';

// Action database
export const DATABASE_ACTION_IS_COMPLETED = 'isCompleted';
export const DATABASE_ACTION_CACHE_KEY_TEAMS = 'teamIds';
export const DATABASE_ACTION_CACHE_KEY_RESPS = 'responsibleIds';
export const DATABASE_ACTION_CACHE_KEY_TARGET_DATE = 'targedDate';
export const DATABASE_ACTION_CACHE_KEY_IS_MY_ACTION = 'isMyAction';
export const DATABASE_ACTION_CACHE_KEY_Q = 'q';
