import { useCallback } from 'react';
import { PeopleFilterField, PeopleInputOption } from 'graphql/generated/graphql';

export const useFilterToString = () => {
  const filterToString = useCallback((filter: string | PeopleInputOption) => {
    if (typeof filter === 'string') return filter;
    switch (filter.type) {
      case PeopleFilterField.Name:
        return filter.user?.fullName ?? '';
      case PeopleFilterField.Teams:
        return filter.team?.name ?? '';
      case PeopleFilterField.Personality:
        return filter.personality ?? '';
      case PeopleFilterField.Supervisor:
        return filter.supervisor?.fullName ?? '';
      case PeopleFilterField.Skills:
        return filter.skill ?? '';
      case PeopleFilterField.Success:
        return filter.success ?? '';
      case PeopleFilterField.Passions:
        return filter.passion ?? '';
      case PeopleFilterField.BusinessUnit:
        return filter.businessUnit?.name ?? '';
      case PeopleFilterField.PreviousCompanies:
        return filter.previousCompany ?? '';
      case PeopleFilterField.Email:
        return filter.email ?? '';
      case PeopleFilterField.JobTitle:
        return filter.jobTitle ?? '';
      default:
        return '';
    }
  }, []);

  return filterToString;
};
