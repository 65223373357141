const map: Record<string, string> = {
  "map.header.title":
    "Échange de problèmes entre la communauté {communityName} et les autres communautés",
  "map.bomb.icon.tooltip.title":
    "Cela représente le nombre de problèmes que vous partagez avec cette communauté",
  "map.emojievent.icon.tooltip.title":
    "Cela représente le nombre de problèmes partagés avec cette communauté que vous avez résolus",
  "map.tooltip.title.ranking":
    "Cela correspond au classement des différentes équipes selon le point que vous avez gagné",
};
export default map;
