import { Maybe } from 'graphql/jsutils/Maybe';

export const getPhotoPublicUrl = (photoPublicUrl: Maybe<string>) => {
  switch (photoPublicUrl) {
    case 'default':
      // undefined src
      return;
    default:
      return photoPublicUrl ?? 'fallback';
  }
};
