import bgImage1 from '../assets/img/webp/orizon-bg1.webp';
import bgImage2 from '../assets/img/webp/orizon-bg2.webp';
import bgImage3 from '../assets/img/webp/orizon-bg3.webp';
import bgImage4 from '../assets/img/webp/orizon-bg4.webp';

export const dynamicBgUrl = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let options: Intl.DateTimeFormatOptions = {
    timeZone,
    hour: 'numeric',
    hour12: false,
  };

  const formatter = new Intl.DateTimeFormat([], options);
  const hour: number = Number(formatter.format(new Date()));

  let bgUrl: string;

  switch (true) {
    case hour > 7 && hour < 11:
      bgUrl = bgImage1;
      break;
    case hour > 10 && hour < 14:
      bgUrl = bgImage2;
      break;
    case hour > 13 && hour < 17:
      bgUrl = bgImage3;
      break;
    case hour > 16:
    case hour <= 7:
      bgUrl = bgImage4;
      break;
    default:
      bgUrl = bgImage1;
  }

  return bgUrl;
};
