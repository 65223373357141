const database: Record<string, string> = {
  'action.label.add': 'Nouvelle action',
  'action.search.for': 'Action de recherche ou mot clé',
  'database.action.no.item': 'Pas de donnée',
  'database.action.no.result': 'Pas de résultat',
  'database.delete.title': 'Supprimer une action',
  'database.action.title.detail': 'Action',
  'database.action.snackbar.checked.message': 'Action vérifiée avec succès',
  'database.action.snackbar.unchecked.message': 'Action non vérifiée avec succès',
};

export default database;
