export const COMPANY_SLUG_KEY = 'company_slug';
export const STREAM_USER_KEY = 'streamUser';
export const WELCOME_SCREEN_LOGIN_KEY = 'welcome-screen-login';
export const WELCOME_SCREEN_PRAGMA_KEY = 'welcome-screen-pragma';
export const EXIT_TUNNEL_PRAGMA_KEY = 'exit-tunnel-pragma';
export const EXIT_TUNNEL_KEY = 'exit-tunnel';
export const SPLASH_SCREEN_KEY = 'splash-screen';
export const LOGGED_IN_COACH = 'logged-in-coach';
export const ACCESS_TOKEN_KEY = 'access_token';
export const MEETING_REDIRECT_KEY = 'meetingRedirect';
export const SEEN_VALUE = 'seen';
export const LOGGED_IN = 'logged-in';
export const INVITATION_TOKEN = 'token-invite';
export const FCM_TOKEN = 'fcm-token';