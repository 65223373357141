const onetoone: Record<string, string> = {
  'onetoone.label.make.with': 'Make a 1to1 with',
  'onetoone.detail.roadblock.title': 'Road Blocks',
  'onetoone.detail.roadblock.add.new': 'Add a new road block',
  'onetoone.dialog.edit.need.title': 'Edit my needs',
  'onetoone.need.dialog.choose.level.weather': 'Choose your level weather:',
  'onetoone.need.dialog.edit.need.success': 'Need updated successfully',
  'onetoone.need.dialog.edit.need.error': 'Failed to update Need',
  'onetoone.detail.roadblock.detail': 'Road block detail',
  'onetoone.detail.roadblock.noitem': 'No Road block yet ',
  'onetoone.detail.roadblock.create.success': 'Road block creation with success',
  'onetoone.detail.mission.action': 'Missions and actions',
  'onetoone.detail.mission.add.new': 'Add new Mission',
  'onetoone.detail.mission.update': 'Update Mission',
  'onetoone.detail.mission.update.success': 'Update Mission success',
  'onetoone.detail.mission.create.success': 'Create Mission success ',
  'onetoone.detail.mission.noitem': 'No Mission item yet',
  'onetoone.detail.mission.estimate': 'Estimate',
  'onetoone.detail.mission.deadline': 'Deadline',
  'onetoone.detail.mission.delete.confirm': 'Do you want to delete this mission?',
  'onetoone.detail.mission.delete': 'Delete Mission',
  'onetoone.detail.mission.delete.success': 'Delete Mission success',
  'onetoone.detail.mission.give.title': 'Give a title to your mission',
  'onetoone.detail.mission.level': 'Level',
  'onetoone.detail.mission.estimate.date': 'Estimate date',
  'onetoone.detail.mission.status': 'Status',
  'onetoone.detail.missions': 'Missions',
  'onetoone.detail.actions': 'Actions',
  'onetoone.superior.manager': 'Superior manager',

  'onetoone.roadblock.delete': 'Roadbloack delete with success',
  'onetoone.detail.feedback.add.new': 'Add new feedback',
  'onetoone.detail.feedback.sent.noitem': 'No feedback sent yet',
  'onetoone.detail.feedback.sent': 'Sent',
  'onetoone.detail.feedback.received.noitem': 'No feedback received yet',
  'onetoone.detail.feedback.received': 'Received',
  'onetoone.detail.feedback': 'Feedback',
  'onetoone.detail.feedback.sent.have': 'Sent feedback by:',
  'onetoone.detail.feedback.received.have': 'Received feedback from:',

  'onetoone.button.tooltip.title.archive': 'Here you can find all the dashboards of 1to1 you save',

  'onetoone.detail.feedback.response.success': 'Your response was sent successfully',
  'onetoone.detail.feedback.response.error': 'Error while responding feedback',
  'onetoone.detail.feedback.response': 'Feedback response',
  'onetoone.detail.feedback.sent.feedback': 'Sent feedback',
  'onetoone.detail.feedback.received.feedback': 'Received feedback',
};
export default onetoone;
