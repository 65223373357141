import { statuses } from 'components/common/State/StatusState/constants';

import { green, grey, lightGreen, lime, orange, red, yellow } from '@mui/material/colors';
import { createTheme, Theme, ThemeOptions } from '@mui/material/styles';

import { TThemeCalendar, TThemeStatus } from 'types';

const theme = createTheme({
  palette: {
    mode: 'dark',

    primary: {
      main: '#5FA423',
      light: '#7cff6b',
      contrastText: '#FFFFFF8C',
    },

    secondary: {
      light: '#2D2E32',
      main: '#1C1C1E78',
      dark: '#1C1C1E',
      contrastText: '#ffffff',
    },

    error: {
      main: '#f51c1c',
    },

    warning: {
      dark: '#ff5b16',
      main: '#e8871e',
      light: '#F29D04',
    },

    success: {
      main: '#0da81d',
      contrastText: '#D2D2D2',
    },

    grey: {
      '800': '#404247',
    },

    background: {
      paper: '#2d2e32',
      default: '#1C1C1E',
    },

    text: {
      primary: '#ffffff',
      secondary: '#B5B5B5',
      disabled: '#7E7E7E',
    },

    divider: '#7070701F',
  },

  typography: {
    fontFamily: 'Poppins',

    h1: {
      fontWeight: 600,
      fontSize: 32,
      lineHeight: 5,
    },

    h2: {
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.5,
    },

    h3: {
      fontWeight: 600,
      fontSize: 20,
      lineHeight: 1.5,
    },

    h4: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.5,
    },

    h5: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: 1,
    },

    subtitle1: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.5,
    },

    subtitle2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.5,
    },

    body1: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1.5,
    },

    body2: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: 1.5,
    },
  },

  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#D2D2D2',
        },
      },
    },
  },

  status: {
    [statuses.ToDo]: red[500],
    [statuses.Todo]: red[500],
    [statuses.InProgress]: yellow[600],
    [statuses.Blocked]: red[900],
    [statuses.Done]: green['A400'],
    [statuses.Assign]: red[600],
    [statuses.Pending]: orange[800],
    [statuses.SolvingInProgress]: yellow['A700'],
    [statuses.TestSolution]: lime['A400'],
    [statuses.Parked]: lightGreen['A700'],
    [statuses.Solved]: lightGreen[800],
    [statuses.Finished]: green['A400'],
    [statuses.Draft]: grey[500],
    [statuses.Validated]: green[600],
    [statuses.Waiting]: grey[100],
    [statuses.Accepted]: green['A400'],
    [statuses.Decline]: red[500],
    [statuses.Rejected]: red[800],
  },
  calendar: {
    events: { borderColor: '#ffffff57' },
  },
});

declare module '@mui/material/styles' {
  interface Theme {
    status: TThemeStatus;
    calendar: TThemeCalendar;
  }
  interface ThemeOptions {
    status: TThemeStatus;
    calendar: TThemeCalendar;
  }
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme, ThemeOptions {}
}

export default theme;
