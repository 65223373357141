export function toKebab(string: string) {
  return string
    .replace(/[_\s]+/g, '-')
    .split('')
    .map((letter) => {
      return letter;
    })
    .join('');
}

export function toSentence(string: string) {
  const interim = toKebab(string).replace(/-/g, ' ');
  return interim.slice(0, 1).toUpperCase() + interim.slice(1);
}
