import { blue, green, grey, orange, red, yellow } from '@mui/material/colors';

import theme from 'theme';
import { IStateValue } from 'interface';
import {
  TDemandImprove,
  TDemandSolve,
  TMissionState,
  TPriorityLevel,
  TPostState,
  TProcessState,
  TProjectState,
  TState,
} from 'types';
import {
  Demand_Improve_Status,
  Demand_Solve_Status,
  IndicatorAction,
  IndicatorLevel,
  IndicatorTrend,
  MissionProgressStatus,
  Post_Status,
  PostType,
  Process_Status,
  ProjectStatus,
} from 'graphql/generated/graphql';
export * from './code';
export * from './default';
export * from './dialog';
export * from './locale';
export * from './localStorage';
export * from './paramsKey';
export * from './regex';
export * from './routes';
export * from './cacheKey';

// TODO: to remove
export const LEVEL: Record<number, TPriorityLevel> = {
  1: 'low',
  2: 'medium',
  3: 'high',
};

export const problemState: Record<Post_Status, TPostState> = {
  TODO: 'toDo',
  IN_PROGRESS: 'inProgress',
  DONE: 'done',
  BLOCKED: 'blocked',
};

export const PROJECT_STATE: Record<ProjectStatus, TProjectState> = {
  TO_DO: 'toDo',
  IN_PROGRESS: 'inProgress',
  DONE: 'done',
};

// TODO to remove
export const MISSION_STATE: Record<MissionProgressStatus, TMissionState> = {
  TO_DO: 'toDo',
  IN_PROGRESS: 'inProgress',
  FINISHED: 'finished',
};

// TODO to delete
export const demandSolveState: Record<Demand_Solve_Status, TDemandSolve> = {
  ASSIGN: 'assign',
  PENDING: 'pending',
  SOLVING_IN_PROGRESS: 'solvingInProgress',
  SOLVED: 'solved',
};

export type TProcessStatus = 'draft' | 'validated';

// TODO to delete
export const demandImproveState: Record<Demand_Improve_Status, TDemandImprove> = {
  ASSIGN: 'assign',
  PENDING: 'pending',
  IN_PROGRESS: 'inProgress',
  TEST_SOLUTION: 'testSolution',
  PARKED: 'parked',
  SOLVED: 'solved',
};

export const processStatusState: Record<Process_Status, TProcessStatus> = {
  DRAFT: 'draft',
  VALIDATED: 'validated',
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}

export const POST_STATE_VALUE: Record<TState, IStateValue> = {
  toDo: {
    messageId: 'common.label.to.do',
    color: red[500],
  },
  assign: {
    messageId: 'common.label.assign',
    color: '#F51C1C',
  },
  pending: {
    messageId: 'common.label.pending',
    color: '#FF5B16',
  },
  solvingInProgress: {
    messageId: 'common.label.solving.in.progress',
    color: '#F3DE2C',
  },
  inProgress: {
    messageId: 'common.label.in.progress',
    color: '#F3DE2C',
  },
  testSolution: {
    messageId: 'common.label.test.solution',
    color: '#AEEF17',
  },
  parked: {
    messageId: 'common.label.parked',
    color: '#57C931',
  },
  solved: {
    messageId: 'common.label.solved',
    color: '#5FA423',
  },
  done: {
    messageId: 'common.label.done',
    color: green['A400'],
  },
  blocked: {
    messageId: 'common.label.blocked',
    color: red[900],
  },
  finished: {
    messageId: 'common.label.finished',
    color: green['A400'],
  },
  draft: {
    messageId: 'common.label.draft',
    color: grey[500],
  },
  validated: {
    messageId: 'common.label.validated',
    color: green['A400'],
  },
};

export const PRIORITY_LEVEL_VALUE: Record<TPriorityLevel, IStateValue> = {
  low: {
    messageId: 'common.label.low',
    color: green['A400'],
  },
  medium: {
    messageId: 'common.label.medium',
    color: orange[600],
  },
  high: {
    messageId: 'common.label.high',
    color: red[900],
  },
};

// TODO to delete
export const PROCESS_STATUS_MAP: Record<Process_Status, TProcessState> = {
  DRAFT: 'draft',
  VALIDATED: 'validated',
};

export const POST_INPUT_PLACEHOLDER: Record<
  PostType,
  {
    title: string;
    description: string;
  }
> = {
  IDEA: {
    title: 'dialog.idea.input.title',
    description: 'dialog.input.describe.it',
  },
  PROBLEM: {
    title: 'dialog.problem.input.title',
    description: 'ruche.problem.placeholder.content',
  },
  ADVICE: {
    title: 'dialog.advice.input.title',
    description: 'dialog.input.describe.it',
  },
  PROCESS: {
    title: 'dialog.process.input.title',
    description: 'dialog.process.input.description',
  },
  STORY: {
    title: 'dialog.input.give.a.title',
    description: 'dialog.input.describe.it',
  },
  QUESTION: {
    title: 'dialog.question.input.title',
    description: 'dialog.input.describe.it',
  },
  FUN: {
    title: 'dialog.input.give.a.title',
    description: 'dialog.input.describe.it',
  },
};

export const INDICATOR_LEVEL_VALUE: Record<IndicatorLevel, IStateValue> = {
  BELOW: {
    messageId: 'common.label.below',
    color: red[500],
  },
  AT_LEVEL: {
    messageId: 'common.label.at.level',
    color: yellow[500],
  },
  ABOVE: {
    messageId: 'common.label.above',
    color: green['A400'],
  },
};

export const INDICATOR_ACTION_VALUE: Record<IndicatorAction, IStateValue> = {
  IN_PROGRESS: {
    messageId: 'common.label.in.progress',
    color: orange[600],
  },
  DONE: {
    messageId: 'common.label.done',
    color: green['A400'],
  },
  TO_DO: {
    messageId: 'common.label.to.do',
    color: blue['A400'],
  },
};

export const INDICATOR_TREND_VALUE: Record<IndicatorTrend, IStateValue> = {
  PROGRESSIVE: {
    messageId: 'common.label.progress',
    color: theme.palette.primary.main,
  },
  DEGRESSIVE: {
    messageId: 'common.label.degress',
    color: theme.palette.error.main,
  },
  BALANCED: {
    messageId: 'common.label.balanced',
    color: theme.palette.info.main,
  },
  NO_TREND: {
    messageId: 'common.label.no.trend',
    color: theme.palette.secondary.main,
  },
};

export const INDICATOR_TYPE_LABEL_ID = {
  Level: 'common.label.niveau',
  Action: 'common.label.action',
  ActionCount: 'common.label.action',
  Trend: 'common.label.trend',
  Value: 'common.label.value',
};

export const NO_ID_FOUND = 'No id found';

export const THANKS_INPUT_CARACHTER_LIMIT = 300;

export const DOCUMENT_TITLE = 'Wimtim';

export * from './soundPath';

export const DEFAULT_LOCATION_ID = 'from-the-office';
export const DEFAULT_LOCATION_TITLE = 'calendar.localisation.office';
export const JITSI_HOST_NAME = 'meet.jit.si';

export const CALL_MAX_GUEST = 250;

export const DEFAULT_POOL_INTERVAL = 30_000;
