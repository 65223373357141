const notifications: Record<string, string> = {
  'notifications.issue.postComment.body': '{arg1} has commented on an issue named {arg2}',
  'notifications.issue.postComment.title': 'Issue : New comment from an expert!',
  'notifications.issue.five.body': 'You have 5 more issues in your database.',
  'notifications.issue.five.title': '5 more issues!',
  'notifications.issue.create.expert.body':
    'You have been selected as an expert for an issue named {arg1}',
  'notifications.issue.create.title': 'An issue was created!',
  'notifications.issue.create.teamCharge.body':
    'Your team {arg1} is in charge of a post named {arg2}',
  'notifications.issue.update_demand.title': 'Issue : Demand updated!',
  'notifications.issue.update_demand.body': '{arg1} update the demand of an issue named {arg2}.',
  'notifications.issue.update.title': 'An issue was edited!',
  'notifications.issue.update.body': '{arg1} has edited an issue named {arg2}',
  'notifications.issue.solved.title': '1 month solved issue!',
  'notifications.issue.solved.body':
    "An issue named {arg1} was solved one month ago, check it's update.",
  'notifications.issue.responsible.title': 'Named owner of an Issue !',
  'notifications.issue.responsible.body': '{arg1} named you responsible of an issue named {arg2}	',

  'notification.advice.create.title': 'New advice!',
  'notification.advice.create.body':
    '{arg1} posted a new advice named {arg2} addressed to your team {arg3}',
  'notification.advice.share.title': '{arg1} shared an advice!',
  'notification.advice.share.body': '{arg1} shared an advice named: {arg2}',
  'notifications.advice.postComment.body': '{arg1} has commented on your advice named {arg2}',
  'notifications.advice.postComment.title': '{arg1}  commented an advice!',
  'notification.advice.postLike.title': 'Advice : New like!',
  'notification.advice.postLike.body': '{arg1} like advice named : {arg2}',

  'notification.process.create.title': 'New Process created!',
  'notification.process.create.body': '{arg1} has created a new process named {arg2}',
  'notification.process.validation.title': 'Process needs validation!',
  'notification.process.validation.body':
    '{arg1} has created a new process, it needs your validation',
  'notification.process.validated.title': 'Process Validated!',
  'notification.process.validated.body': 'One of validators has validated a process named {arg1}',
  'notification.process.updated.title': 'Process updated!',
  'notification.process.updated.body': '{arg1} has updated a  process named {arg2}',
  'notifications.process.postComment.body': '{arg1} has commented in a process named {arg2}',
  'notifications.process.postComment.title': 'Process commented!',

  'notifications.idea.postComment.title': '{arg1} comments your idea!',
  'notifications.idea.postComment.body': '{arg1} has commented on your idea named {arg2} ',
  'notifications.idea.endingRating.body':
    'The rating period of your idea named {arg1} ended - {arg2}.',
  'notifications.idea.endingRating.title': 'The rating period ended !',
  'notification.idea.create.title': 'New idea from {arg1}',
  'notification.idea.create.body': '{arg1} shared an idea named {arg2} for your team: {arg3}',
  'notifications.idea.update.title': '{arg1} edited your idea !',
  'notifications.idea.update.body': '{arg1} has made some changes on your idea named {arg2}',
  'notification.idea.rated.title': 'Your idea was rated !',
  'notification.idea.rated.body': 'Someone has voted your idea named {arg1}',
  'notification.idea.fiveRated.title': '5 more rating for your idea !',
  'notification.idea.fiveRated.body': 'Your idea named {arg1} has 5 new ratings !',

  'notifications.story.postComment.title': 'Story : New Comment!',
  'notifications.story.postComment.body': '{arg1} commented on your story named {arg2} ',
  'notifications.story.postLike.title': "What's up : New like !",
  'notifications.story.postLike.body': '{arg1} liked your story named : {arg2}',

  'notifications.thanks.response.title': 'Thank you response !',
  'notifications.thanks.response.body': '{arg1} sent a response to your thank you.',
  'notifications.thanks.create.title': 'Thank you !',
  'notifications.thanks.create.body': '{arg1} sent you a thank you',

  'notifications.feedback.create.title': 'New feedback!',
  'notifications.feedback.create.body': '{arg1} sent you a feedback',
  'notifications.feedback.response.title': 'Feedback Response !',
  'notifications.feedback.response.body': '{arg1} sent a response to your feedback.',

  'notification.goal.newAction.title': 'New action added on the indicator!',
  'notification.goal.newAction.body': '{arg1} has added an action on the indicator named {arg3}',
  'notification.goal.newComment.title': 'New comment added on the indicator!',
  'notification.goal.newComment.body': '{arg1} has added a comment on the indicator named {arg3}',

  'notification.indicator.create.title': 'New Indicator created!',
  'notification.indicator.create.body': '{arg1} has created a new indicator named {arg2}.',
  'notification.indicator.update.title': 'Indicator updated!',
  'notification.indicator.update.body':
    '{arg1} has updated the indicator named {arg2}. click to see the changes.',

  'notification.close.toaster': 'see',

  'notification.action.create.title': 'Action Responsible Assignment',
  'notification.action.create.body': 'You have assigned to responsible on action \n{arg1}',

  'notifications.fun.postComment.title': '{arg1} comments your have fun!',
  'notifications.fun.postComment.body': '{arg1} has commented on your have fun named {arg2} ',
  'notifications.fun.postLike.title': 'Have fun: New like !',
  'notifications.fun.postLike.body': '{arg1} liked your have fun named : {arg2}',

  'notification.employee.updateSuperior.title': 'Employee changed his Superior',
  'notification.employee.updateSuperior.body':
    '{arg1} changed his hierarchical superior from "{arg2}" to "{arg3}"',
  'notification.employe.dayResult.title': '{arg2} employee of the day offer',
  'notification.employe.dayResultAccepted.body':
    '{arg1} accepted the employee offer of the day - {arg2}.',
  'notification.employe.dayResultRejected.body':
    '{arg1} refused the offer of employee of the day - {arg2}.',
  'notification.employe.dayDemande.title': 'Employee of the day reminder!',
  'notification.employe.dayDemande.body':
    "Hey, you have been chosen to be tomorrow's “Employee of the day” - {arg1}, make sure to update your Profile because everyone will see it in the entry tunnel.",
  'notification.employee.welcom.title': 'Welcome to WimTim!',
  'notification.employee.welcom.body':
    'Welcome to {arg1}, please fill your profile information you will be introduced to the other users shortly.',
  'notification.employee.badWeather.title': 'Bad weather !!',
  'notification.employee.badWeather.body': '{arg1} has a bad weather, see what can be done. ',

  'notification.calendar.newEvent.title': 'New event!',
  'notification.calendar.newEvent.body':
    '{arg1} added you as owner of a new event {arg2}{arg3} {arg4}',
  'notification.calendar.newTeamEvent.title': 'New team event!',
  'notification.calendar.newTeamEvent.body':
    '{arg1} created a new event {arg2}{arg3} for your team {arg4} {arg5}',
  'notification.calendar.updateEvent.title': 'Event edited!',
  'notification.calendar.updateEvent.body': '{arg1} has edited an event {arg2}{arg3} {arg4}',
  'notification.calendar.updateTeamEvent.title': 'Team event edited!',
  'notification.calendar.updateTeamEvent.body':
    '{arg1} has edited an event {arg2}{arg3} in your team {arg4} {arg5}',
  'notification.calendar.deleteTeamEvent.title': 'Team event deleted!',
  'notification.calendar.deleteTeamEvent.body':
    '{arg1} has deleted an event {arg2}{arg3} in your team {arg4} {arg5}',

  'notification.calendar.deleteEvent.title': 'Event deleted!',
  'notification.calendar.deleteEvent.body': '{arg1} has deleted an event {arg2}{arg3} {arg4}',
  'notification.calendar.startTeamEvent.title': 'Team event starts now!',
  'notification.calendar.startTeamEvent.body':
    'The event {arg1} {arg2} of your team {arg3} is starting now {arg4}',
  'notification.calendar.startEvent.title': 'Event starts now!',
  'notification.calendar.startEvent.body': 'The event {arg1} {arg2} is starting now {arg3}',
  'notification.calendar.commentMeeting.title': 'New comment on meeting!',
  'notification.calendar.commentMeeting.body':
    '{arg1} has added a new comment on meeting named "{arg2}" on {arg3} ({arg4})',
  'notification.calendar.createMeeting.title': 'Meeting request!',
  'notification.calendar.createMeeting.body':
    '{arg1} wants a meeting with you named "{arg2}" on {arg3} ({arg4})',
  'notification.calendar.updateMeeting.title': 'Meeting updated!',
  'notification.calendar.updateMeeting.body':
    '{arg1} updated the meeting named "{arg2}" on {arg3} ({arg4})',
  'notification.calendar.deleteMeeting.title': 'Meeting canceled!',
  'notification.calendar.deleteMeeting.body': 'A meeting {arg1} on {arg2} ({arg3}) was canceled',
  'notification.calendar.reminderMeeting.title': 'On going meeting reminder!',
  'notification.calendar.reminderMeeting.body': '{arg1} will be start in a minute',
  'notification.calendar.startingMeeting.title': 'A meeting starts now!',
  'notification.calendar.startingMeeting.body': '{arg1} is starting now',

  'notification.calendar.commentTeamEvent.title': 'New comment on team event',
  'notification.calendar.commentTeamEvent.body':
    '{arg1} has added a new comment on event "{arg2}" of the team  {arg3} {arg4}',
  'notification.calendar.commentEvent.title': 'New comment on event',
  'notification.calendar.commentEvent.body':
    '{arg1} has added a new comment on event "{arg2}" {arg3}',
  'notification.calendar.commentLocation.title': 'New comment on location',
  'notification.calendar.commentLocation.body':
    '{arg1} has added a new comment on your {arg2} location {arg3}',
  'notification.calendar.commentLeave.title': 'New comment on a leave!',
  'notification.calendar.commentLeave.body': '{arg1} has posted a new comment on a leave, {arg2}',

  'notification.semiInstanceCall.new.title': 'New incoming call!',
  'notification.semiInstanceCall.new.body': '{arg1} wants a call with you{arg2}, named {arg3}',
  'notification.semiInstanceCall.newGroup.body':
    '{arg1} wants a wants a group call to{arg2}, named {arg3}',
  'notification.semiInstanceCall.update.title': 'Updated call',
  'notification.semiInstanceCall.update.body':
    '{arg1} has updated a call with you{arg2}, named {arg3}',
  'notification.semiInstanceCall.updateGroup.body':
    '{arg1} has updated a group call for{arg2}, named {arg3}',
  'notification.semiInstanceCall.delete.title': 'Closed call',
  'notification.semiInstanceCall.delete.body':
    '{arg1} has closed a call with you{arg2}, named {arg3}',
  'notification.semiInstanceCall.deleteGroup.body':
    '{arg1} has closed a group call in{arg2}, named {arg3}',
  'notification.level.up.title': 'New level reached!',
  'notification.level.up.body': 'You have reached a new level: "{arg1}".',
  'notification.team.level.up.body': 'Your team {arg1} have reached a new level: "{arg2}".',

  // Coin notifications for member
  'notification.member.coin.title': 'New coin earned',
  'notification.member.coin.body': 'You have earned {arg1} coin(s) for {arg2} exp',

  // Coin notifications for team
  'notification.team.coin.title': 'New coin earned by your team',
  'notification.team.coin.body': 'Your team {arg1} has earned {arg2} coin(s) for {arg3} exp',
};
export default notifications;
