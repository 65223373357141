const meeting: Record<string, string> = {
  'meeting.label.join.meeting': 'Join meeting',
  'meeting.label.end.meeting': 'End meeting',
  'meeting.label.loading_zoom_meetings': 'Loading scheduled meetings...',
  'meeting.label.on.going.meeting': 'On going meeting...',
  'meeting.dialog.label.create': 'Create a meeting',
  'meeting.dialog.label.update': 'Update a meeting',
  'meeting.dialog.label.deleted': 'Delete a meeting',
  'meeting.dialog.label.accept': 'Meeting accepted',
  'meeting.dialog.label.expired': 'Meeting expired',
  'meeting.dialog.label.denied': 'Meeting denied',
  'meeting.dialog.label.removed': 'Meeting removed',
  'meeting.dialog.label.deny': 'Meeting denied',
  'meeting.label.add.coworker': 'Add a coworker',
  'meeting.label.add.teams': 'Add teams',
  'meeting.label.go_to_calendar': 'Go to calendar',
  'meeting.label.zoom.link': 'Meeting link',
  'meeting.label.ics': 'Meeting .ics file',
  'meeting.label.link.copied': 'Meeting link Copied',
  'meeting.create.success': 'Meeting created successfully',
  'meeting.update.success': 'Meeting updated successfully',
  'meeting.cant.drag':
    'You cannot edit a meeting by drag and drop , please click the edit button instead',
  'meeting.create.congrats':
    'Your meeting was successfully scheduled, Invitations are sent to the attendees',
  'meeting.update.congrats':
    'Your meeting was successfully updated, Invitations are sent to the attendees',
  'meeting.team.event.congrats':
    'Your registration request for a meeting was accepted and your meeting event was successfully created',
  'meeting.expired.details': 'The meeting was deleted or expired ',
  'meeting.denied.details': 'The meeting was denied or may be expired ',
  'meeting.removed.details': 'The meeting was removed ',
  'meeting.team.event.deny': 'Your meeting invitation was successfully denied',

  'meeeting.accept.success': 'Your registration request for a meeting was accepted',
  'meeting.deny.success': 'Your registration request for a meeting was denied',

  // Auth
  'meeting.label.auth.success': 'Zoom auth',
  'meeting.label.auth.success.detail': "Great, you're now authenticated to zoom",

  // Placeholder
  'meeting.placeholder.title': 'Meeting title',
  'meeting.placeholder.timezone': 'Select a timezone',
  'meeting.placeholder.pass.code': 'Meeting Passcode',
  'meeting.placeholder.description': 'Say something about the meeting',

  'meeting.failed.to.delete': 'Sorry, Meeting expired',
  'meeting.failed.to.delete.more':
    'Meeting is not found or has expired but your team event was deleted',

  // Prefilled values
  'meeting.from.hive': 'Scheduled meeting from {creator}',
  'meeting.from.team': 'Team meeting : ',
  'meeting.from.feedback': 'Feedback meeting : ',
  'meeting.from.a3': 'Issue meeting: ',
  'meeting.from.one.to.one': 'One-to-one meeting : ',

  'meeting.current.timezone': 'Your current timezone',
  'meeting.current.timezone.label': 'Meeting date based on your current timezone',
  'meeting.registrants.and.creator': 'Registrants and creator',
};
export default meeting;
