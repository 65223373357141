export const getAge = (dateString: string) => {
  const today = new Date();
  const dateInput = new Date(dateString);
  let age = today.getFullYear() - dateInput.getFullYear();
  const m = today.getMonth() - dateInput.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < dateInput.getDate())) {
    age--;
  }
  return age;
};
