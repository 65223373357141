import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import {
  useGetCurrentCompanyQuery,
  useGetCurrentWelcomeScreenQuery,
} from 'graphql/generated/graphql';

import useStyles from './styles';

export interface ISplashScreenProps {
  id?: string;
  className?: string;
  classes?: Partial<Record<keyof ReturnType<typeof useStyles>, string>>;
  handleClickScreen?: () => void;
}

const SplashScreen = (props: ISplashScreenProps) => {
  const { id = 'splash-screen', handleClickScreen } = props;

  const [path, setPath] = useState('');

  const { data: currentWelcomScreenData } = useGetCurrentWelcomeScreenQuery();
  const { data: currentCompanyData, loading: currentCompanyLoading } = useGetCurrentCompanyQuery();

  useEffect(() => {
    if (currentWelcomScreenData?.getCurrentWelcomeScreen)
      setPath(currentWelcomScreenData?.getCurrentWelcomeScreen);
  }, /*[currentCompanyData]*/[currentWelcomScreenData]);

  const classes = useStyles({ path, ...props });

  return (
    <Dialog
      id={id}
      data-cy={id}
      fullScreen
      open
      className={classes.root}
      onClick={handleClickScreen}
    >
      <Box className={classes.welcomeWrapper}>
        {currentCompanyLoading ? (
          <Stack gap={2} alignItems="center">
            <Typography className={classes.label}>
              <Skeleton animation="wave" variant="text" width={500} />
            </Typography>
            <Typography className={classes.companyName}>
              <Skeleton animation="wave" variant="text" width={300} />
            </Typography>
          </Stack>
        ) : (
          <>
            <Typography className={classes.label} data-cy="welcome-screen">
              <FormattedMessage id="common.label.welcome" />
            </Typography>

            <Typography className={classes.companyName} data-cy="to-company-name">
              <FormattedMessage
                id="common.label.to.company"
                values={{ name: currentCompanyData?.currentCompany?.name }}
              />
            </Typography>
          </>
        )}
      </Box>
      <Box className={classes.message}>
        <Typography variant="h4" data-cy="click-to-enter">
          {currentCompanyLoading ? (
            <Skeleton variant="text" width={100} />
          ) : (
            <FormattedMessage id="common.label.click.to.enter" />
          )}
        </Typography>
      </Box>
    </Dialog>
  );
};

export default SplashScreen;
