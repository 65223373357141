import { gql } from '@apollo/client';
import { BADGE_FRAGMENT, COMMUNITY_BADGE_FRAGMENT, INDIVIDUAL_BADGE_FRAGMENT } from './fragment';

export const GET_BADGES_BY_COMMUNITY = gql`
  query GetBadgesByCommunity($communityId: ID!) {
    badgesByCommunity(communityId: $communityId) {
      ...CommunityBadgeFragment
      badge {
        ...BadgeFragment
      }
    }
  }
  ${COMMUNITY_BADGE_FRAGMENT}
  ${BADGE_FRAGMENT}
`;

export const GET_DAILY_DONES_COMMUNITY_BADGES = gql`
  query GetDailyDonesCommunityBadges {
    getDailyDonesCommunityBadges {
      ...CommunityBadgeFragment
      badge {
        ...BadgeFragment
      }
    }
  }
  ${COMMUNITY_BADGE_FRAGMENT}
  ${BADGE_FRAGMENT}
`;

export const GET_BADGES_BY_COMPANY_MEMBER = gql`
  query GetBadgesByCompanyMember($companyMemberId: ID!) {
    getBadgesByCompanyMember(companyMemberId: $companyMemberId) {
      ...IndividualBadgeFragment
      badge {
        ...BadgeFragment
      }
    }
  }
  ${INDIVIDUAL_BADGE_FRAGMENT}
  ${BADGE_FRAGMENT}
`;

export const GET_BADGES_BY_CATEGORY = gql`
  query GetBadgesByCategory($communityId: ID, $companyMemberId: ID) {
    getBadgesByCategory(communityId: $communityId, companyMemberId: $companyMemberId) {
      community {
        id
        name
        logoPublicUrl
        mission
        myCommunityRole
        exp
        coins
        expLevel {
          id
          name
          iconPublicUrl
        }
      }
      achivements {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      special {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      pointsCollection {
        badgeId
        badge {
          ...BadgeFragment
          customBadge {
            minExp
            maxExp
          }
        }
        status
      }
    }
  }
  ${BADGE_FRAGMENT}
`;

export const GET_DAILY_DONES_BADGES_BY_CATEGORY = gql`
  query GetDailyDonesBadgesByCategory {
    getDailyDonesBadgesByCategory {
      achivements {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      special {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      pointsCollection {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
    }
  }
  ${BADGE_FRAGMENT}
`;

export const GET_CUSTOM_BADGES = gql`
  query GetCustomBadges {
    allCustomBadges {
      badgeId
      companyId
      minExp
      maxExp
      prevMinExp
      nextMaxExp
      badge {
        id
        name
        iconPublicUrl
        type
        minExp
        maxExp
      }
    }
  }
`;
