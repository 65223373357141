import React, { createContext, FC, useEffect, useMemo, useState } from 'react';

export interface IMeetingDialog {
  openDialog?: boolean;
  setOpenDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}

const MeetingDialogContext = createContext<IMeetingDialog>({});

export const MeetingDialogProvider: FC<IMeetingDialog> = ({ children }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [contextValue, setContextValue] = useState<IMeetingDialog>({});

  useEffect(() => {
    setContextValue({
      openDialog,
      setOpenDialog,
    });
    return () => {};
  }, [openDialog]);

  const value = useMemo(() => ({ contextValue }), [contextValue]);

  return (
    <MeetingDialogContext.Provider value={value.contextValue}>
      {children}
    </MeetingDialogContext.Provider>
  );
};

export default MeetingDialogContext;
