import { gql } from '@apollo/client';
import {
  ACTION_FRAGMENT,
  COMMUNITY_FRAGMENT,
  SIMPLE_COMMUNITY_FRAGMENT,
} from 'graphql/community/fragment';
import { INDICATOR_FRAGMENT } from 'graphql/indicator/fragment';
import { FEEDBACK_FRAGMENT, MISSION_FRAGMENT, ROADBLOCK_FRAGMENT } from 'graphql/oneToOne/fragment';
import { USER_THANK_FRAGMENT } from 'graphql/social/fragment';
import { USER_ON_POST_FRAGMENT } from 'graphql/user/fragment';

export const NEW_ARRIVAL_COWORKERS = gql`
  query NewArrivalCoworkers {
    newArrivalCoworkers {
      id
      jobTitle
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        bio
        communities {
          community {
            id
            logo
            logoPublicUrl
            name
          }
        }
        skills {
          id
          title
        }
        successes {
          id
          title
        }
        passions {
          id
          title
        }
        previousCompanies {
          id
          name
        }
        pictures {
          id
          imageUrl
          imagePublicUrl
          title
        }
      }
    }
  }
`;

export const GET_CURRENT_COMPANY = gql`
  query getCurrentCompany {
    currentCompany {
      id
      description
      email
      logo
      logoPublicUrl
      name
      romance
      slug
      mission
      leaveWeekendsCount
      removed
      communities {
        id
        name
        mission
        logo
        logoPublicUrl
      }
    }
  }
`;

export const GET_CURRENT_COMPANY_ID = gql`
  query getCurrentCompanyId {
    currentCompany {
      id
    }
    currentWholeCompany {
      id
      slug
      name
      logo
      logoPublicUrl
    }
  }
`;

export const GET_COMPANY_COMMUNITIES = gql`
  query getCompanyCommunities {
    currentCompany {
      id
      communities {
        ...Community
        members {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
          companyMemberId
        }
      }
    }
  }
  ${COMMUNITY_FRAGMENT}
`;
export const GET_COMPANY_COMMUNITIES_WITH_CM = gql`
  query getCompanyCommunitiesWithCm {
    currentCompany {
      id
      communities {
        ...Community
        members {
          id
          user {
            id
            fullName
            firstName
            lastName
            email
            photoUrl
            photoPublicUrl
          }
          companyMember {
            id
            user {
              id
              fullName
              firstName
              lastName
              email
              photoUrl
              photoPublicUrl
            }
          }
          companyMemberId
        }
      }
    }
  }
  ${COMMUNITY_FRAGMENT}
`;

export const GET_COMPANY_MEMBER_BY_ID = gql`
  query getCompanyMemberById($id: ID!) {
    getCompanyMemberById(id: $id) {
      id
      isManager
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        email
        myCommunity {
          id
        }
      }
      roadBlocks {
        ...RoadBlock
        actions {
          ...ActionInfo
          responsibles {
            id
            user {
              fullName
              firstName
              lastName
              photoUrl
              photoPublicUrl
            }
          }
        }
      }
      currentProfessionnalWeather {
        id
        icon
        title
      }
      currentMotto {
        id
        content
      }
      myCommunities {
        id
      }
      nPlusOne {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
    }
  }
  ${ROADBLOCK_FRAGMENT}
  ${ACTION_FRAGMENT}
`;

export const COWORKERS_MEMBER = gql`
  query CoworkersMember(
    $ids: [ID!]
    $withoutMe: Boolean
    $filterByWeatherId: ID
    $filterByCommunityId: ID
    $filterByEventTypeId: ID
    $queryString: String
    $jobTitle: String
    $sortField: String
    $sortOrder: String
    $page: Int
    $perPage: Int
    $cacheKey: String
  ) {
    coworkersMember(
      cacheKey: $cacheKey
      ids: $ids
      withoutMe: $withoutMe
      filterByWeatherId: $filterByWeatherId
      filterByCommunityId: $filterByCommunityId
      filterByEventTypeId: $filterByEventTypeId
      queryString: $queryString
      jobTitle: $jobTitle
      sortField: $sortField
      sortOrder: $sortOrder
      page: $page
      perPage: $perPage
    ) {
      id
      jobTitle
      isItMyDay
      removed
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        isItMyDay
        email
        isOnline
        removed
        suspended
        companyMembers {
          id
        }
      }
      currentMotto {
        id
        content
      }
      currentProfessionnalWeather {
        id
        icon
        title
      }
      currentProfessionalStatus {
        id
        title
        icon
      }
      nPlusOne {
        id
        jobTitle
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      nMinusOnes {
        id
        jobTitle
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      myCommunities {
        id
      }
      dayOff
      currentLocation {
        id
        title
        iconUrl
      }
    }
    total: _allCompanyMembersMeta(
      filter: { ids: $ids, q: $queryString, isByCurrentCompany: true }
    ) {
      count
    }
  }
`;

export const CURRENT_COMPANY_MEMBER = gql`
  query CurrentCompanyMember($seen: Boolean) {
    currentCompanyMember {
      id
      coins
      jobTitle
      incomingSemiInstantCallsCount
      nPlusOne {
        id
        jobTitle
        user {
          id
          fullName
          firstName
          lastName
          email
          photoUrl
          photoPublicUrl
        }
      }
      nMinusOnes {
        id
        jobTitle
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
          email
        }
      }
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        email
      }
      feedbacksReceived(seen: $seen) {
        ...FeedbackInfo
        companyMemberReceiver {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        companyMemberSender {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
            email
          }
        }
      }
      thanksReceived(seen: $seen) {
        ...UserThankInfo
        companyMemberSender {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl

            email
          }
        }
      }
      myCommunities {
        id
        name
        logo
      }
      missions {
        ...MissionInfo
        community {
          id
          logo
          logoPublicUrl
          name
        }
        experts {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
            email
          }
        }
        companyMember {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
            email
          }
        }
      }
      myActions {
        ...ActionInfo
        responsibles {
          id
          user {
            id
            fullName
            photoUrl
            photoPublicUrl
            firstName
            lastName
          }
        }
        roadBlock {
          id
          roadBlockId
        }
      }
    }
  }
  ${USER_THANK_FRAGMENT}
  ${FEEDBACK_FRAGMENT}
  ${MISSION_FRAGMENT}
  ${ACTION_FRAGMENT}
`;
export const GET_FEEDBACK_BY_COMPANY_MEMBER = gql`
  query GetFeedbacksByCM(
    $id: ID!
    $sentToId: ID
    $receivedFromId: ID
    $sentByMe: Boolean
    $receivedByMe: Boolean
  ) {
    getCompanyMemberById(id: $id) {
      id
      feedbacksSent: feedbacksSended(receiverId: $sentToId, receivedByMe: $receivedByMe) {
        ...FeedbackInfo
        companyMemberReceiver {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        companyMemberSender {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
      }
      feedbacksReceived(senderId: $receivedFromId, sentByMe: $sentByMe) {
        ...FeedbackInfo
        companyMemberReceiver {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        companyMemberSender {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
      }
    }
  }
  ${FEEDBACK_FRAGMENT}
`;

export const GET_COMPANIES = gql`
  query GetCompanies {
    companies: myCompanies {
      id
      name
      slug
    }
  }
`;

export const GET_COMPANIES_WITH_LOGO = gql`
  query GetCompaniesWithLogo {
    companies: myCompanies {
      id
      name
      slug
      logo
      logoPublicUrl
    }
  }
`;

export const GET_EMPLOYEE_OF_THE_DAY = gql`
  query GetEmployeeOfTheDay {
    getEmployeeOfTheDay {
      id
      day
      companyMember {
        id
        jobTitle
        hiringDate
        user {
          id
          fullName
          firstName
          lastName
          bio
          email
          photoUrl
          photoPublicUrl
          communities {
            id
            community {
              id
              name
              logo
              logoPublicUrl
            }
          }
          skills {
            id
            title
          }
          successes {
            id
            title
          }
          passions {
            id
            title
          }
          pictures {
            id
            title
            imageUrl
            imagePublicUrl
          }
          previousCompanies {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_MY_COMMUNITIES = gql`
  query GetMyCommunities {
    currentCompanyMember {
      id
      myCommunities {
        id
        name
        logo
        logoPublicUrl
        slug
        mission
        rommance
        myCommunityRole
        exp
        coins
        expLevel {
          id
          name
          iconPublicUrl
        }
      }
    }
    currentWholeCompany {
      id
      slug
    }
  }
`;

export const GET_CURRENT_COMPANY_POST_CATEGORIES = gql`
  query getCurrentCompanyPostCategories {
    currentCompany {
      id
      questionCategories {
        id
        title
      }
      ideaCategories {
        id
        title
      }
      problemCategories {
        id
        title
      }
      adviceCategories {
        id
        title
      }
    }
  }
`;

export const COWORKERS_WITH_ME = gql`
  query CoworkersMemberWithMe($queryString: String, $fromAdmin: Boolean) {
    coworkersMember(queryString: $queryString, fromAdmin: $fromAdmin) {
      id
      user {
        id
        email
        fullName
        firstName
        lastName
        suspended
      }
      role
      userAccess
      hiringDate
      nPlusOne {
        id
        user {
          id
          fullName
          firstName
          photoUrl
          photoPublicUrl
        }
      }
      myCommunities {
        id
        logo
        logoPublicUrl
        name
      }
      businessUnit {
        id
        name
        logo
        logoPublicUrl
      }
    }
  }
`;

export const GET_PENDING_INVITATIONS = gql`
  query GetPendingInvitationsByCompany {
    pendingInvitationByCompany: getPendingInvitationsByCompany {
      id
      targetEmail
      companyMemberRole
      companyMemberAccess
      token
      pendingTeams {
        id
        name
        logoPublicUrl
      }
    }
  }
`;

export const GET_PENDING_INVITATIONS_TEAM_ID = gql`
  query GetPendingInvitationsByTeamId($id: ID!) {
    pendingInvitationsByTeam: getPendingInvitationsByTeamId(id: $id) {
      id
      targetEmail
      communityMemberRole
    }
  }
`;

export const GET_CURRENT_WELCOME_SCREEN = gql`
  query GetCurrentWelcomeScreen {
    getCurrentWelcomeScreen
  }
`;

export const GET_PENDING_INVITATION = gql`
  query GetPendingInvitation($token: String!) {
    pendingInvitation: getPendingInvitation(token: $token) {
      id
      targetEmail
      hiringDate
    }
  }
`;

export const GET_COMPANY_IMAGES = gql`
  query getAllCompanyImages {
    companyImages: getCompanyImages {
      id
      name
      type
      size
      path
      publicUrl
    }
  }
`;

export const CHECK_INVITED_ALREADY_SIGNED_UP = gql`
  query isAlreadySignedUp($token: String!) {
    isAlreadySignedUp(token: $token)
  }
`;

export const GET_MY_N_PLUS_ONE = gql`
  query GetMyNPlusOne {
    me: currentCompanyMember {
      id
      user {
        id
        fullName
        photoPublicUrl
      }
      nPlusOne {
        id
      }
    }
  }
`;

export const GET_CEO_WORD = gql`
  query getCeoWord {
    speech: getCeoWord
  }
`;

export const CHECK_CLIENT = gql`
  query CheckClient($timezone: UserTimezoneInput!) {
    payload: checkClient(timezone: $timezone) {
      companyMember {
        id
        removed
        user {
          id
          fullName
          firstName
          lastName
          email
          photoUrl
          photoPublicUrl
        }
        company {
          id
          slug
        }
        userPreference {
          id
          userTimezone {
            id
            tzCode
          }
          enableShowEntryTunnel
          language {
            id
            key
          }
        }
      }
      streamToken
      success
      message
    }
  }
`;

export const CHECK_ADMIN = gql`
  query CheckAdmin {
    admin: checkAdmin {
      userAccess
      slug
    }
  }
`;

export const GET_COMMUNITIES_INPUT_OPTIONS = gql`
  query GetCommunitiesInputOptions($id: ID, $mine: Boolean) {
    communities: getCommunitiesByCompany(id: $id, mine: $mine) {
      id
      name
      logo
      logoPublicUrl
    }
  }
`;

export const GET_COWORKERS_INPUT_OPTIONS = gql`
  query GetCoworkersInputOptions(
    $ids: [ID!]
    $withoutMe: Boolean
    $queryString: String
    $teamId: ID
    $filterByCommunityId: ID
    $perPage: Int
  ) {
    coworkers: coworkersInputOptions(
      ids: $ids
      withoutMe: $withoutMe
      queryString: $queryString
      teamId: $teamId
      filterByCommunityId: $filterByCommunityId
      perPage: $perPage
    ) {
      id
      removed
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        isOnline
        email
        currentCompanyMember {
          id
        }
      }
    }
  }
`;

export const GET_TUNNELS_ACTION_VALUE = gql`
  query GetTunnelsActivationValue {
    tunnelsActivationValue: getTunnelsActivationValue {
      entry
      exit
    }
  }
`;

export const GET_COWORKERS_MEETING_INPUT_OPTIONS = gql`
  query GetCoworkersMeetingInputOptions($withoutMe: Boolean) {
    coworkers: coworkersMember(withoutMe: $withoutMe) {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        email
      }
    }
  }
`;

export const GET_COWORKERS_FOR_BUSINESS_UNIT_OPTIONS = gql`
  query GetCoworkersForBusinessUnitInputOptions($fromBusinessUnit: Boolean, $queryString: String) {
    coworkers: coworkersMember(fromBusinessUnit: $fromBusinessUnit, queryString: $queryString) {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
    }
  }
`;

export const GET_INDICATOR_SWITCH_ITEMS = gql`
  query GetIndicatorSwitchItems {
    switchItems: getIndicatorSwitchItems {
      id
      name
      logo
      logoPublicUrl
      indicators {
        ...IndicatorInfo
        actions {
          ...ActionInfo
          createdBy {
            ...UserOnPostInfo
          }
          responsibles {
            id
            user {
              ...UserOnPostInfo
            }
          }
          indicator {
            id
          }
        }
        actionsCount
        comments {
          id
          content
          createdAt
          isMine
          user {
            ...UserOnPostInfo
          }
        }
      }
      communities: switchItemCommunities {
        id
        name
        logo
        logoPublicUrl
        indicators {
          ...IndicatorInfo
          actions {
            ...ActionInfo
            createdBy {
              ...UserOnPostInfo
            }
            responsibles {
              id
              user {
                ...UserOnPostInfo
              }
            }
            indicator {
              id
            }
          }
          actionsCount
          comments {
            id
            content
            createdAt
            isMine
            user {
              ...UserOnPostInfo
            }
          }
        }
      }
      businessUnits: switchItemBusinessUnits {
        id
        name
        logo
        logoPublicUrl
        indicators {
          ...IndicatorInfo
          actions {
            ...ActionInfo
            createdBy {
              ...UserOnPostInfo
            }
            responsibles {
              id
              user {
                ...UserOnPostInfo
              }
            }
            indicator {
              id
            }
          }
          actionsCount
          comments {
            id
            content
            createdAt
            isMine
            user {
              ...UserOnPostInfo
            }
          }
        }
      }
    }
  }
  ${INDICATOR_FRAGMENT}
  ${ACTION_FRAGMENT}
  ${USER_ON_POST_FRAGMENT}
`;

export const GET_ALL_TEAM_MEMBERS_SUGGESTION = gql`
  query GetAllTeamMembersSuggestion(
    $teamId: ID
    $filterValue: String
    $fromAdmin: Boolean
    $page: Int
    $perPage: Int
  ) {
    data: getAllTeamMembersSuggestion(
      teamId: $teamId
      filterValue: $filterValue
      fromAdmin: $fromAdmin
      page: $page
      perPage: $perPage
    ) {
      ... on UserCompanyInvitation {
        id
        companyMemberAccess
        isConfirmed
        targetEmail
        hiringDate
        companyMemberRole
        token
        pendingTeamsIds
        communityMemberRole
        suspended
        user {
          ...UserOnPostInfo
        }
        pendingTeams {
          ...SimpleCommunityInfo
        }
      }
      ... on CompanyMember {
        id
        hiringDate
        userAccess
        role
        suspended
        businessUnit {
          id
          name
          slug
          logoPublicUrl
        }
        myCommunities {
          ...SimpleCommunityInfo
        }
        user {
          ...UserOnPostInfo
          role
        }
        nPlusOne {
          id
          user {
            ...UserOnPostInfo
          }
        }
      }
    }
  }
  ${SIMPLE_COMMUNITY_FRAGMENT}
  ${USER_ON_POST_FRAGMENT}
`;

export const GET_ALL_LANGUAGES = gql`
  query GetAllLanguages {
    languages: getAllLanguages {
      id
      key
      label
    }
  }
`;
