const social: Record<string, string> = {
  'social.motto.create.message.success': 'Motto created successfully',
  'social.event.wish.sent.success': 'Your wish is sent successfully',
  'social.user.thank.sent.success': 'Your thanks message is sent successfully',
  'social.user.wish.sent.success': 'Your wish  is sent successfully',
  'social.user.feedback.sent.success': 'Your feedback message is sent successfully',

  //Comment
  'social.comment.create.success': 'Your comment was created successfully',
  'social.comment.update.success': 'Your comment was updated successfully',
  'social.comment.delete.success': 'Your comment was deleted successfully',

  'social.sent.thank.you': 'Sent thank you',
  'social.received.thank.you': 'Received thank you',
  'social.send.thank.you.success': 'Thank you sent successfully',
  'social.send.thank.you.error': 'Error while sending thank you',
  'social.reply.thank.you.success': 'Thank you replyed successfully',
  'social.reply.thank.you.error': 'Error while replying thank you',
  'social.thank.response': 'Thank response',

  // Experiences
  'social.exp.from.to.experiences': '{from} - {to} experiences',
};
export default social;
