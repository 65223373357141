const team: Record<string, string> = {
  'team.current.team': 'Équipe actuelle',
  'team.community.not_found': 'Équipe introuvable',
  'team.member.already.exist': 'Le membre existe déja',
  'team.member.added': 'Nouveau membre ajouté',
  'team.label.total.member': 'Nombre total de membres',
  'team.label.request.added': 'Demander à être ajouté',
  'team.label.add.a.new.indicator': 'Ajouter un nouvel indicateur',
  'team.label.edit.indicator': "Modifier l'indicateur",
  'team.label.delete.indicator': "Supprimer l'indicateur",

  'team.indicator.placeholder.name': "Nom de l'indicateur",
  'team.indicator.placeholder.value': 'Valeur',
  'team.indicator.placeholder.trend': 'Tendance',
  'team.indicator.placeholder.niveau': 'Niveau',
  'team.indicator.placeholder.state': 'État',
  'team.indicator.placeholder.date': 'Date',
  'team.indicator.dialogue.title': "Détails de l'indicateur",
  'team.community.delete.success': 'Équipe supprimée avec succès',
  'team.community.user.delete.success': "Membre de l'équipe supprimé avec succès",
  'team.member.role.update.success': "Rôles des membres de l'équipe mis à jour avec succès",
  'team.community.update.success': "Mise à jour de l'équipe avec succès",
  'team.community.create.success': "Création de l'équipe avec succès",
  'team.indicator.created.successfully': 'Indicateur créé avec succès',
  'team.indicator.updated.successfully': 'Indicateur mis à jour avec succès',
  'team.indicator.deleted.successfully': 'Indicateur supprimé avec succès',
  'team.action.add.new': 'Ajouter une nouvelle action',
  'team.project.add.new': 'Ajouter un nouveau projet',
  'team.action.date.immediate': 'Immédiat',
  'team.admin.download.reports': 'Télécharger les rapports',

  'team.problems.subtitle': 'Accéder à la base de données',
  'team.action.no.item': 'Aucune action',
  'team.action.delete.confirm': "Supprimer l'action",
  'team.action.update.success': 'Action mise à jour avec succès',
  'team.action.create.success': 'Action créée avec succès',
  'team.action.delete.success': 'Action supprimée avec succès',
  'team.action.label.immediate': 'Immédiat',
  'team.action.edit': "Modifier l'action",
  'team.action.delete.project': 'Action supprimée du projet avec succès',
  'team.action.change.project': 'Projet changé avec succès',

  'team.project.delete.success': 'Projet supprimé avec succès !',
  'team.project.detail': 'Détail du projet',

  'team.error.update.romance': 'Erreur lors de la mise à jour de la romance',
  'team.error.update.mission': 'Erreur lors de la mise à jour de la mission',

  'team.add.new.issue': 'Ajouter un nouveau problème',
  'team.label.add.new': 'Créer une nouvelle équipe',
  'team.label.update': "Modifier les informations de l'équipe",
  'team.goal.add.new': 'Ajouter un nouvel indicateur',
  'team.add.new': 'Ajouter nouveau',
  'team.dialog.goal.details': "Détails de l'inidcateur",

  'team.delete.label': "Supprimer l'équipe",
  'team.member.delete.label': "Supprimer le membre de l'équipe",
  'team.delete.success': 'Process supprimé avec succès',
  'team.search.no.result': 'Aucune équipe trouvée pour votre recherche',
  'team.search.for': 'Rechercher une équipe',
  'team.no.item': 'Aucune équipe à afficher...',

  'team.tootlip.title.mission':
    "La mission est de savoir comment l'équipe contribue à créer de la valeur pour l'entreprise et le client",
  'team.tootlip.title.tomorrow.romance':
    "La romance de demain est la prochaine étape où le manager rêve d'emmener cette équipe dans deux ans",
  'team.tootlip.title.button.members':
    'Cliquez sur le bouton vert pour afficher et masquer les membres de votre équipe',

  'team.confirm.dialog.delete.member': 'Voulez-vous vraiment supprimer ce membre ?',
  'team.dialog.team.badges': "Insignes d'équipe",

  'team.label.concerned': 'Équipe impactée ',
  'team.load.more.issues': 'Charger plus de problémes',
  'team.load.more.data': 'Charger plus de données',

  'team.event': "Événement d'équipe",
};
export default team;
