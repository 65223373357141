import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
};

export type Action = {
  __typename?: 'Action';
  RoadBlockId?: Maybe<Scalars['ID']>;
  actionType?: Maybe<ActionType>;
  comments?: Maybe<Array<Comment>>;
  community?: Maybe<Community>;
  communityId?: Maybe<Scalars['ID']>;
  completionDate?: Maybe<Scalars['Date']>;
  convertedFromIdeaId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  createdByCompanyMember?: Maybe<CompanyMember>;
  createdBycompanyMemberId?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indicator?: Maybe<Indicator>;
  indicatorId?: Maybe<Scalars['ID']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isOverdue?: Maybe<Scalars['Boolean']>;
  localCreationType?: Maybe<LocalCreationActionType>;
  post?: Maybe<Post>;
  postId?: Maybe<Scalars['ID']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['ID']>;
  responsibles?: Maybe<Array<CompanyMember>>;
  roadBlock?: Maybe<RoadBlock>;
  subActions?: Maybe<Array<Maybe<Action>>>;
  targetDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ActionFilter = {
  actionType?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  isMyAction?: InputMaybe<Scalars['Boolean']>;
  q?: InputMaybe<Scalars['String']>;
  responsibleIds?: InputMaybe<Array<Scalars['ID']>>;
  targetDate?: InputMaybe<Scalars['Date']>;
  teamId?: InputMaybe<Scalars['ID']>;
  teamIds?: InputMaybe<Array<Scalars['ID']>>;
  type?: InputMaybe<LocalCreationActionType>;
};

export enum ActionType {
  Immediate = 'IMMEDIATE',
  NotImmediate = 'NOT_IMMEDIATE',
}

export type AddMemberToBusinessUnitInput = {
  businessUnitId: Scalars['ID'];
  businessUnitRole?: InputMaybe<BusinessUnitRole>;
  companyMemberId: Scalars['ID'];
};

export type AddMembersToBusinessUnitInput = {
  businessUnitId: Scalars['ID'];
  membersAndRoles: Array<MemberAndRoleInput>;
};

export type AddZoomGroupAdminInfoInput = {
  email: Scalars['String'];
  id: Scalars['String'];
};

export type AddZoomGroupAdminInput = {
  admins: AddZoomGroupAdminInfoInput;
  groupId: Scalars['String'];
};

export type AddZoomGroupAdminResponse = {
  __typename?: 'AddZoomGroupAdminResponse';
  added_at?: Maybe<Scalars['DateTime']>;
  ids?: Maybe<Scalars['String']>;
};

export type AddZoomMeetingRegistrantInfoInput = {
  address?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
};

export type AddZoomMeetingRegistrantInput = {
  auto_approve: Scalars['Boolean'];
  meetingId: Scalars['String'];
  registrant: AddZoomMeetingRegistrantInfoInput;
};

export type AddZoomMeetingRegistrantResponse = {
  __typename?: 'AddZoomMeetingRegistrantResponse';
  address?: Maybe<Scalars['String']>;
  create_time?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  join_url?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  registrant_id?: Maybe<Scalars['String']>;
  status?: Maybe<ZoomMeetingRegistrantStatus>;
  topic?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type AdviceCategory = {
  __typename?: 'AdviceCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AllSemiInstantCallsInput = {
  filter?: InputMaybe<SemiInstantCallFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Analysis5Why = {
  __typename?: 'Analysis5Why';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  rank?: Maybe<Scalars['Int']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** TO DO Resolver */
export type AnalysisCompare = {
  __typename?: 'AnalysisCompare';
  comments?: Maybe<Array<Maybe<CompareComment>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  subject1?: Maybe<Scalars['String']>;
  subject2?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnalysisCompareFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type AnalysisRootCause = {
  __typename?: 'AnalysisRootCause';
  comments?: Maybe<Array<Maybe<RootCauseComment>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnalysisRootCauseFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type AnalysisWhy = {
  __typename?: 'AnalysisWhy';
  analysis5Why?: Maybe<Array<Maybe<Analysis5Why>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AnalysisWhyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

/** -------------------------------------- INTERNAL TYPE -------------------------------# */
export type Application = {
  __typename?: 'Application';
  categories?: Maybe<Array<ApplicationCategory>>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  featureList?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  imageList?: Maybe<Array<File>>;
  isActivated?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<File>;
  longDescription?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  shortDescription?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type ApplicationCategory = {
  __typename?: 'ApplicationCategory';
  company?: Maybe<Company>;
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

export type ApplicationCategoryFilterInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

/** -------------------------------------- INPUTS -------------------------------# */
export type ApplicationFilterInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  companyId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isActivated?: InputMaybe<Scalars['Boolean']>;
  q?: InputMaybe<Scalars['String']>;
};

export type ApplicationInfoInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  featureList?: InputMaybe<Array<Scalars['String']>>;
  imageList?: InputMaybe<Array<FileInput>>;
  logo?: InputMaybe<FileInput>;
  longDescription?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type ArchiveInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  path: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

/**
 * -------------------------------------- INTERNAL TYPE -------------------------------#
 *  TODO - always use currentCustomBadge, if not exist then use badge
 */
export type Badge = {
  __typename?: 'Badge';
  category?: Maybe<BadgeCategory>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Refer to the custom badge used in the current company */
  customBadge?: Maybe<CustomBadge>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  iconPublicUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  maxExp?: Maybe<Scalars['Int']>;
  minExp?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  nextBadge?: Maybe<Badge>;
  points?: Maybe<Scalars['Int']>;
  type?: Maybe<BadgeType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum BadgeCategory {
  Achivements = 'ACHIVEMENTS',
  PointsCollection = 'POINTS_COLLECTION',
  Special = 'SPECIAL',
}

/** -------------------------------------- ENUMS -------------------------------# */
export enum BadgeType {
  Individual = 'INDIVIDUAL',
  Team = 'TEAM',
}

/** -------------------------------------- CUSTOM TYPE -------------------------------# */
export type Badges = {
  __typename?: 'Badges';
  badge: Badge;
  badgeId: Scalars['ID'];
  status: Scalars['Float'];
};

export type BadgesByCategory = {
  __typename?: 'BadgesByCategory';
  achivements: Array<Badges>;
  community?: Maybe<Community>;
  companyMember?: Maybe<CompanyMember>;
  pointsCollection: Array<Badges>;
  special: Array<Badges>;
};

export type BatchZoomMeetingRegistrationInput = {
  auto_approve: Scalars['Boolean'];
  meetingId: Scalars['String'];
  registrants: Array<AddZoomMeetingRegistrantInfoInput>;
};

export type BatchZoomMeetingRegistrationResponse = {
  __typename?: 'BatchZoomMeetingRegistrationResponse';
  registrants?: Maybe<Array<Maybe<BatchZoomMeetingRegistrationResponseInfo>>>;
};

export type BatchZoomMeetingRegistrationResponseInfo = {
  __typename?: 'BatchZoomMeetingRegistrationResponseInfo';
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  join_url?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  registrant_id?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  businessUnitImages?: Maybe<Array<File>>;
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indicators?: Maybe<Array<Indicator>>;
  indicatorsCount?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  logoPublicUrl?: Maybe<Scalars['String']>;
  members?: Maybe<Array<CompanyMember>>;
  mission?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  romance?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type BusinessUnitFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export enum BusinessUnitRole {
  CommunicationController = 'COMMUNICATION_CONTROLLER',
  Director = 'DIRECTOR',
  Member = 'MEMBER',
}

export type CalendarCommunityEventsPerDay = {
  __typename?: 'CalendarCommunityEventsPerDay';
  canIDelete?: Maybe<Scalars['Boolean']>;
  canIEdit?: Maybe<Scalars['Boolean']>;
  communities?: Maybe<Array<Community>>;
  communitiesFilter?: Maybe<CommunitiesFilter>;
  communityIds?: Maybe<Array<Scalars['ID']>>;
  companyMemberOwners?: Maybe<Array<CompanyMember>>;
  currentEventTitle?: Maybe<Scalars['String']>;
  day: Scalars['DateTime'];
  eventId?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CalendarEventType>;
};

export type CalendarDateRangeInput = {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  calendarEventCommunities?: Maybe<Array<CalendarEventCommunities>>;
  calendarEventOwners?: Maybe<Array<CalendarEventOwners>>;
  canIDelete?: Maybe<Scalars['Boolean']>;
  canIEdit?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<Array<Comment>>;
  community?: Maybe<Community>;
  communityEventType?: Maybe<CommunityEventType>;
  companyMemberOwner?: Maybe<CompanyMember>;
  createdBy?: Maybe<CompanyMember>;
  days?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  eventPlace?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isPublic?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Location>;
  removed?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CalendarEventType>;
};

export type CalendarEventCommunities = {
  __typename?: 'CalendarEventCommunities';
  calendarEvent?: Maybe<CalendarEvent>;
  calendarEventId: Scalars['ID'];
  community?: Maybe<Community>;
  communityId: Scalars['ID'];
};

export type CalendarEventOwners = {
  __typename?: 'CalendarEventOwners';
  calendarEvent?: Maybe<CalendarEvent>;
  calendarEventId: Scalars['ID'];
  companyMember?: Maybe<CompanyMember>;
  companyMemberId: Scalars['ID'];
};

/** TODO: handle other events here */
export type CalendarEventPerDay = {
  __typename?: 'CalendarEventPerDay';
  communityEvents?: Maybe<Array<CalendarEvent>>;
  locations?: Maybe<Array<CalendarLocationEventsPerDay>>;
};

export enum CalendarEventType {
  Event = 'EVENT',
  Leave = 'LEAVE',
  Location = 'LOCATION',
  Meeting = 'MEETING',
}

export type CalendarEventsPerDayInput = {
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  date: Scalars['DateTime'];
  dateRange?: InputMaybe<CalendarDateRangeInput>;
  view: Scalars['String'];
};

export type CalendarFilterHistory = {
  __typename?: 'CalendarFilterHistory';
  communityEventType?: Maybe<CommunityEventType>;
  communityFilter?: Maybe<CommunitiesFilter>;
  communityId?: Maybe<Scalars['String']>;
  creator?: Maybe<CompanyMember>;
  dateRangeEnd?: Maybe<Scalars['DateTime']>;
  dateRangeStart?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  location?: Maybe<Location>;
  statusFilter?: Maybe<LeaveStatus>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CalendarEventType>;
  validators?: Maybe<Array<CompanyMember>>;
};

export type CalendarFilterHistoryInput = {
  communityEventTypeId?: InputMaybe<Scalars['ID']>;
  communityFilter?: InputMaybe<CommunitiesFilter>;
  communityId?: InputMaybe<Scalars['String']>;
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  statusFilter?: InputMaybe<LeaveStatus>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CalendarEventType>;
  validatorIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CalendarLocationEventsPerDay = {
  __typename?: 'CalendarLocationEventsPerDay';
  communitiesFilter?: Maybe<CommunitiesFilter>;
  communityIds?: Maybe<Array<Scalars['ID']>>;
  companyMemberOwners?: Maybe<Array<CompanyMember>>;
  day: Scalars['DateTime'];
  iconUrl?: Maybe<Scalars['String']>;
  isMeACompanyMemberOwner?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<CalendarEventType>;
};

export type CanICreateLeaveForSomeoneMeta = {
  __typename?: 'CanICreateLeaveForSomeoneMeta';
  canICreateLeaveForSomeone: Scalars['Boolean'];
  id: Scalars['ID'];
  someOneIds?: Maybe<Array<Scalars['ID']>>;
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type CheckAdminPayload = {
  __typename?: 'CheckAdminPayload';
  slug: Scalars['String'];
  userAccess: MainRole;
};

export type CheckClientPayload = {
  __typename?: 'CheckClientPayload';
  companyMember?: Maybe<CompanyMember>;
  message?: Maybe<Scalars['String']>;
  streamToken?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Coach = {
  __typename?: 'Coach';
  emotion?: Maybe<CoachEmotion>;
  events?: Maybe<Array<Maybe<Event>>>;
  visible?: Maybe<Scalars['Boolean']>;
};

export enum CoachEmotion {
  Angry = 'angry',
  Awe = 'awe',
  Encouraging = 'encouraging',
  Excited = 'excited',
  Happy = 'happy',
  Idea = 'idea',
  Neutral = 'neutral',
  Notify = 'notify',
  Sad = 'sad',
  Stop = 'stop',
  Surprised = 'surprised',
}

export type CoachInput = {
  enable?: InputMaybe<Scalars['Boolean']>;
};

export type CoachPreference = {
  __typename?: 'CoachPreference';
  enable?: Maybe<Scalars['Boolean']>;
};

export type CoinHistory = {
  __typename?: 'CoinHistory';
  communityId?: Maybe<Scalars['ID']>;
  companyMemberId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  expSource?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isSeen?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CoinHistoryType>;
  value?: Maybe<Scalars['Int']>;
};

export type CoinHistoryFilter = {
  communityId?: InputMaybe<Scalars['ID']>;
  companyMemberId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isSeen?: InputMaybe<Scalars['Boolean']>;
  q?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CoinHistoryType>;
};

export enum CoinHistoryType {
  Credit = 'CREDIT',
  Debit = 'DEBIT',
}

/** ####################### INTERNAL TYPE ################## */
export type CoinListMetadata = {
  __typename?: 'CoinListMetadata';
  count: Scalars['Int'];
  sum: Scalars['Int'];
};

export type Comment = {
  __typename?: 'Comment';
  companyMember?: Maybe<CompanyMember>;
  companyMemberId?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isLikedByMe?: Maybe<Scalars['Boolean']>;
  isMine?: Maybe<Scalars['Boolean']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  likeCount?: Maybe<Scalars['Int']>;
  likedBy?: Maybe<Array<CompanyMember>>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type CommentFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type CommunitiesByCompanyMember = {
  __typename?: 'CommunitiesByCompanyMember';
  myCommunities: Array<Community>;
  otherCommunities: Array<Community>;
};

export type CommunitiesByCompanyMemberInput = {
  companyMemberId?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Scalars['String']>;
};

export enum CommunitiesFilter {
  All = 'ALL',
  Mine = 'MINE',
  Others = 'OTHERS',
}

export type Community = {
  __typename?: 'Community';
  actions?: Maybe<Array<Action>>;
  actionsChecked?: Maybe<Scalars['Int']>;
  actionsCount?: Maybe<Scalars['Int']>;
  archives?: Maybe<Array<File>>;
  channelId?: Maybe<Scalars['String']>;
  coins?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  connectedCommunities?: Maybe<Array<Community>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdProblems?: Maybe<Array<Post>>;
  employeesConnectedCount?: Maybe<Scalars['Int']>;
  exp?: Maybe<Scalars['Int']>;
  expLevel?: Maybe<Badge>;
  id: Scalars['ID'];
  indicators?: Maybe<Array<Indicator>>;
  isMeAMember?: Maybe<Scalars['Boolean']>;
  issuesChangedToSolve?: Maybe<Scalars['Int']>;
  issuesCount?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  logoPublicUrl?: Maybe<Scalars['String']>;
  managers?: Maybe<Array<Maybe<CompanyMember>>>;
  memberCount?: Maybe<Scalars['Int']>;
  memberRequestCount?: Maybe<Scalars['Int']>;
  members?: Maybe<Array<UserCommunity>>;
  mission?: Maybe<Scalars['String']>;
  missions?: Maybe<Array<Maybe<Mission>>>;
  myCommunityRole?: Maybe<CommunityRole>;
  name?: Maybe<Scalars['String']>;
  problems?: Maybe<Array<Post>>;
  projects?: Maybe<Array<Project>>;
  removed?: Maybe<Scalars['Boolean']>;
  resolvedProblemCount?: Maybe<Scalars['Int']>;
  rommance?: Maybe<Scalars['String']>;
  sharedCompanyMemberPosts?: Maybe<Array<CompanyMemberPostShare>>;
  slug?: Maybe<Scalars['String']>;
  unresolvedProblemCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommunityActionsArgs = {
  orderBy?: InputMaybe<Scalars['String']>;
};

export type CommunityActionsCheckedArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CommunityActionsCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CommunityEmployeesConnectedCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CommunityIssuesChangedToSolveArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CommunityIssuesCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CommunityMembersArgs = {
  requestStatus?: InputMaybe<CommunityMemberRequestStatus>;
};

export type CommunityResolvedProblemCountArgs = {
  communitySourceId?: InputMaybe<Scalars['ID']>;
};

export type CommunityUnresolvedProblemCountArgs = {
  communitySourceId?: InputMaybe<Scalars['ID']>;
};

export type CommunityBadge = {
  __typename?: 'CommunityBadge';
  badge?: Maybe<Badge>;
  badgeId: Scalars['ID'];
  community?: Maybe<Community>;
  communityId: Scalars['ID'];
  done?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Float']>;
};

export type CommunityEventFilter = {
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export type CommunityEventType = {
  __typename?: 'CommunityEventType';
  createdAt?: Maybe<Scalars['DateTime']>;
  icon?: Maybe<Scalars['String']>;
  iconPublicUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CommunityEventTypeFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type CommunityExpLevelUpDialog = {
  __typename?: 'CommunityExpLevelUpDialog';
  open: Scalars['Boolean'];
};

export type CommunityFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export enum CommunityMemberRequestStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum CommunityRole {
  Manager = 'MANAGER',
  Member = 'MEMBER',
  Secretary = 'SECRETARY',
}

export type Company = {
  __typename?: 'Company';
  actionsChecked?: Maybe<Scalars['Int']>;
  actionsCount?: Maybe<Scalars['Int']>;
  adviceCategories?: Maybe<Array<AdviceCategory>>;
  businessUnits?: Maybe<Array<BusinessUnit>>;
  ceoWord?: Maybe<Scalars['String']>;
  communities?: Maybe<Array<Community>>;
  companyImages?: Maybe<Array<File>>;
  /** Used for new arrival message */
  companyWideMessage?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  currentImagePath?: Maybe<Scalars['String']>;
  dailyEmployeesConnectedCount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employeesConnectedCount?: Maybe<Scalars['Int']>;
  expToCoin?: Maybe<Array<ExpToCoin>>;
  feedbacksCount?: Maybe<Scalars['Int']>;
  /**
   * TODO: Move it to the appropriate service
   * Backoffice resolvers
   */
  goalsCountPerTeam?: Maybe<Array<IndicatorsNumberPerTeam>>;
  id: Scalars['ID'];
  ideaCategories?: Maybe<Array<IdeaCategory>>;
  indicators?: Maybe<Array<Indicator>>;
  isLeaveEmailEnabled?: Maybe<Scalars['Boolean']>;
  issuesChangedToSolve?: Maybe<Scalars['Int']>;
  issuesCount?: Maybe<Scalars['Int']>;
  lastRankingDate?: Maybe<Scalars['DateTime']>;
  leaveWeekendsCount?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  logoPublicUrl?: Maybe<Scalars['String']>;
  members?: Maybe<Array<CompanyMember>>;
  mission?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  oneToOnesCount?: Maybe<Scalars['Int']>;
  problemCategories?: Maybe<Array<ProblemCategory>>;
  processesCount?: Maybe<Scalars['Int']>;
  questionCategories?: Maybe<Array<QuestionCategory>>;
  removed?: Maybe<Scalars['Boolean']>;
  romance?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  switchItemBusinessUnits?: Maybe<Array<BusinessUnit>>;
  switchItemCommunities?: Maybe<Array<Community>>;
  teamsCount?: Maybe<Scalars['Int']>;
  thanksCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyActionsCheckedArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyActionsCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyEmployeesConnectedCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyFeedbacksCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyGoalsCountPerTeamArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyIssuesChangedToSolveArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyIssuesCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyOneToOnesCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyProcessesCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyTeamsCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyThanksCountArgs = {
  from?: InputMaybe<Scalars['DateTime']>;
  to?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

/** -------------------------------------- EXTERNAL TYPE -------------------------------# */
export type CompanyMember = {
  __typename?: 'CompanyMember';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitRole?: Maybe<BusinessUnitRole>;
  coins?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  companyMemberProfessionnalWeathers?: Maybe<Array<CompanyMemberProfessionnalWeather>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdProjects?: Maybe<Array<Project>>;
  currentEvents?: Maybe<Array<CalendarEvent>>;
  currentLeave?: Maybe<Leave>;
  currentLocation?: Maybe<Location>;
  currentMotto?: Maybe<Motto>;
  currentProfessionalStatus?: Maybe<ProfessionalStatus>;
  currentProfessionnalWeather?: Maybe<ProfessionnalWeather>;
  dayOff?: Maybe<Scalars['Boolean']>;
  deviceInfos?: Maybe<Array<DeviceInfo>>;
  employeeOfDayRank?: Maybe<Scalars['Int']>;
  employeeOfDayStatus?: Maybe<EmployeeOfDayStatus>;
  employeesOfDay?: Maybe<Array<EmployeeOfDay>>;
  events?: Maybe<Array<Event>>;
  exp?: Maybe<Scalars['Int']>;
  expLevel?: Maybe<Badge>;
  expLevelProgress?: Maybe<Scalars['Float']>;
  expLevelUpIn?: Maybe<Scalars['Int']>;
  feedbacksReceived?: Maybe<Array<Feedback>>;
  feedbacksSended?: Maybe<Array<Feedback>>;
  guestOneToOnes?: Maybe<Array<Maybe<OneToOne>>>;
  hiringDate?: Maybe<Scalars['DateTime']>;
  hostOneToOnes?: Maybe<Array<Maybe<OneToOne>>>;
  id: Scalars['ID'];
  incomingSemiInstantCallsCount?: Maybe<Scalars['Int']>;
  isFirstEntry?: Maybe<Scalars['Boolean']>;
  isItMyDay?: Maybe<Scalars['Boolean']>;
  isManager?: Maybe<Scalars['Boolean']>;
  isMyBirthDay?: Maybe<Scalars['Boolean']>;
  isMyChildBirthDay?: Maybe<Scalars['Boolean']>;
  isMyWeddingDay?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  leaderMessage?: Maybe<Array<LeaderMessage>>;
  leaves?: Maybe<Array<Leave>>;
  managedProcesses?: Maybe<Array<Post>>;
  managedProjects?: Maybe<Array<Project>>;
  managedTeams?: Maybe<Array<Community>>;
  missions?: Maybe<Array<Maybe<Mission>>>;
  mottos?: Maybe<Array<Motto>>;
  myActions?: Maybe<Array<Action>>;
  myCommunities?: Maybe<Array<Community>>;
  myOneToOneActions?: Maybe<Array<Action>>;
  myOneToOneMissions?: Maybe<Array<Mission>>;
  myStreamToken?: Maybe<Scalars['String']>;
  myUnfinishedActions?: Maybe<Array<Action>>;
  nMinusOnes?: Maybe<Array<CompanyMember>>;
  nPlusOne?: Maybe<CompanyMember>;
  nPlusOneId?: Maybe<Scalars['String']>;
  posts?: Maybe<Array<Post>>;
  removed?: Maybe<Scalars['Boolean']>;
  roadBlocks?: Maybe<Array<Maybe<RoadBlock>>>;
  role?: Maybe<MemberRole>;
  supervisors?: Maybe<Array<CompanyMember>>;
  suspended?: Maybe<Scalars['Boolean']>;
  thanksReceived?: Maybe<Array<UserThank>>;
  thanksSent?: Maybe<Array<UserThank>>;
  unSeenCoins?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  userAccess?: Maybe<MainRole>;
  userCommunity?: Maybe<Array<UserCommunity>>;
  userId?: Maybe<Scalars['ID']>;
  userPreference?: Maybe<UserPreference>;
};

export type CompanyMemberFeedbacksReceivedArgs = {
  seen?: InputMaybe<Scalars['Boolean']>;
  senderId?: InputMaybe<Scalars['ID']>;
  sentByMe?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyMemberFeedbacksSendedArgs = {
  receivedByMe?: InputMaybe<Scalars['Boolean']>;
  receiverId?: InputMaybe<Scalars['ID']>;
};

export type CompanyMemberThanksReceivedArgs = {
  seen?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyMemberFilter = {
  companyId?: InputMaybe<Scalars['ID']>;
  fromAdmin?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isByCurrentCompany?: InputMaybe<Scalars['Boolean']>;
  q?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  withoutMe?: InputMaybe<Scalars['Boolean']>;
};

export type CompanyMemberOrder = {
  __typename?: 'CompanyMemberOrder';
  companyMember?: Maybe<CompanyMember>;
  cost?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  product?: Maybe<Product>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyMemberOrderFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type CompanyMemberPostShare = {
  __typename?: 'CompanyMemberPostShare';
  communities?: Maybe<Array<Community>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  post?: Maybe<Post>;
  sharedBy?: Maybe<CompanyMember>;
};

export type CompanyMemberProfessionnalWeather = {
  __typename?: 'CompanyMemberProfessionnalWeather';
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  companyMember?: Maybe<CompanyMember>;
  companyMemberId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  professionnalWeather?: Maybe<ProfessionnalWeather>;
  professionnalWeatherId?: Maybe<Scalars['ID']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CompanyMemberProfessionnalWeatherFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type CompanyRole = {
  __typename?: 'CompanyRole';
  companyMember: CompanyMember;
  companyMemberId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  scope?: Maybe<CompanyRoleScopeItem>;
  title: Scalars['String'];
};

export type CompanyRoleScopeItem = {
  __typename?: 'CompanyRoleScopeItem';
  companyRole?: Maybe<CompanyRole>;
  companyRoleId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

/** -------------------------------------- UNION TYPE -------------------------------# */
export type CompanyTeamMembersSuggestion = CompanyMember | UserCompanyInvitation;

export type CompareComment = {
  __typename?: 'CompareComment';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CongratsDialog = {
  __typename?: 'CongratsDialog';
  contentDescription?: Maybe<Scalars['String']>;
  contentTitle?: Maybe<Scalars['String']>;
  isOpen?: Maybe<Scalars['Boolean']>;
  redirectUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ConnectToZoomResponse = {
  __typename?: 'ConnectToZoomResponse';
  access_token?: Maybe<Scalars['String']>;
  expires_in?: Maybe<Scalars['Int']>;
  scope?: Maybe<Array<Maybe<Scalars['String']>>>;
  token_type?: Maybe<Scalars['String']>;
};

export type CounterMeasure = {
  __typename?: 'CounterMeasure';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<File>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CounterMeasureFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type CoworkersGrouped = {
  __typename?: 'CoworkersGrouped';
  coworkers?: Maybe<Array<CompanyMember>>;
  key: Scalars['String'];
};

export type CreateApplicationInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  companyId?: InputMaybe<Scalars['ID']>;
  featureList?: InputMaybe<Array<Scalars['String']>>;
  imageList?: InputMaybe<Array<FileInput>>;
  isActivated?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<FileInput>;
  longDescription?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  shortDescription?: InputMaybe<Scalars['String']>;
};

export type CreateBusinessUnitIndicatorInput = {
  businessUnitId: Scalars['ID'];
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  level: IndicatorLevel;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateBusinessUnitInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  mission: Scalars['String'];
  name: Scalars['String'];
  romance: Scalars['String'];
};

export type CreateCalendarCommunityEventInput = {
  communityEventTypeId: Scalars['ID'];
  /** to remove */
  communityId?: InputMaybe<Scalars['ID']>;
  communityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  end: Scalars['DateTime'];
  eventPlace?: InputMaybe<Scalars['String']>;
  makePublic?: InputMaybe<Scalars['Boolean']>;
  meetingId?: InputMaybe<Scalars['String']>;
  /** to remove */
  ownerId?: InputMaybe<Scalars['ID']>;
  ownerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export type CreateCalendarLocationEventInput = {
  end: Scalars['DateTime'];
  locationId: Scalars['ID'];
  start: Scalars['DateTime'];
};

export type CreateCommunityEventInput = {
  communityEventTypeId: Scalars['ID'];
  communityId: Scalars['ID'];
  day: Scalars['DateTime'];
  makePublic: Scalars['Boolean'];
  meetingId?: InputMaybe<Scalars['String']>;
  ownerIds: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type CreateCommunityEventTypeInput = {
  icon: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
};

export type CreateCommunityIndicatorInput = {
  communityId: Scalars['ID'];
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  level: IndicatorLevel;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateCompanyIndicatorInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  level: IndicatorLevel;
  name: Scalars['String'];
  value: Scalars['String'];
};

export type CreateCompanyInput = {
  /** company Role input */
  companyRoleLabel?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  isLeaveEmailEnabled?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  mission?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** User id for admin company member */
  userId: Scalars['ID'];
};

export type CreateCompanyMemberOrderInput = {
  cost: Scalars['Int'];
  productId: Scalars['ID'];
};

export type CreateEventTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  /** Event message suggestion content */
  messages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
};

export type CreateExternalEmailInput = {
  email: Scalars['String'];
};

export type CreateGenericExpEventInput = {
  communityExp?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  exp?: InputMaybe<Scalars['Int']>;
  expEventType?: InputMaybe<ExpEventType>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateHelpMarkInput = {
  en_description: Scalars['String'];
  en_title: Scalars['String'];
  fr_description?: InputMaybe<Scalars['String']>;
  fr_title?: InputMaybe<Scalars['String']>;
  imageId: Scalars['ID'];
  type: HelpMarkType;
};

export type CreateLeaveInput = {
  attachementIds: Array<Scalars['ID']>;
  creatorId?: InputMaybe<Scalars['ID']>;
  daysDuration?: InputMaybe<Array<LeaveDayDuration>>;
  ending: Scalars['DateTime'];
  isHalfDay?: InputMaybe<Scalars['Boolean']>;
  makePublic?: InputMaybe<Scalars['Boolean']>;
  otherReason?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  peopleToNotifyIds: Array<Scalars['ID']>;
  reasonId: Scalars['ID'];
  specialDayOff?: InputMaybe<Scalars['Boolean']>;
  start: Scalars['DateTime'];
  validatorIds: Array<Scalars['ID']>;
};

export type CreateMeetingInput = {
  alternative_hosts?: InputMaybe<Scalars['String']>;
  attendeeIds: Array<InputMaybe<Scalars['ID']>>;
  contact_email: Scalars['String'];
  contact_name: Scalars['String'];
  details?: InputMaybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  duration: Scalars['Int'];
  externalEmails?: InputMaybe<Array<CreateExternalEmailInput>>;
  invitees: Array<MeetingInviteeInput>;
  makePublic: Scalars['Boolean'];
  passcode: Scalars['String'];
  schedule_for?: InputMaybe<Scalars['String']>;
  timezone: Scalars['String'];
  topic: Scalars['String'];
};

export type CreateNeedsInput = {
  companyMemberId: Scalars['ID'];
  professionnalWeatherId: Scalars['ID'];
  title: Scalars['String'];
};

export type CreateOneToOneInput = {
  archive?: InputMaybe<ArchiveInput>;
  guestCompanyMemberId: Scalars['ID'];
  hostCompanyMemberId: Scalars['ID'];
};

export type CreatePostIndicatorInput = {
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  level: IndicatorLevel;
  name: Scalars['String'];
  postId: Scalars['ID'];
  value: Scalars['String'];
};

export type CreateProductInput = {
  companyId: Scalars['ID'];
  price?: InputMaybe<Scalars['Int']>;
  thumbnailId?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type CreateProfessionalStatus = {
  description?: InputMaybe<Scalars['String']>;
  icon: Scalars['String'];
  readOnly?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type CreateProfessionnalWeatherInput = {
  description?: InputMaybe<Scalars['String']>;
  icon: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  readOnly?: InputMaybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type CreateSemiInstantCallInput = {
  chatTeamName?: InputMaybe<Scalars['String']>;
  creatorId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['ID']>;
  guestIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  type: SemiInstantCallType;
};

export type CreateUserInput = {
  bio?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['ID']>;
  companyMemberAccess?: InputMaybe<MainRole>;
  companyMemberRole?: InputMaybe<MemberRole>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  lastname?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  photoUrl?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<MainRole>;
};

export type CreateUserPreferenceInput = {
  id?: InputMaybe<Scalars['ID']>;
  userTimezone: UserTimezoneInput;
};

/** -------------------- ZOMM INPUTS -------------------- */
export type CreateZoomGroupInput = {
  directory_privacy: Scalars['Int'];
  in_directory: Scalars['Boolean'];
  name: Scalars['String'];
};

export type CreateZoomGroupResponse = {
  __typename?: 'CreateZoomGroupResponse';
  directory_privacy?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  in_directory?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  total_members?: Maybe<Scalars['Int']>;
};

export type CreateZoomMeetingInput = {
  agenda?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  password: Scalars['String'];
  schedule_for?: InputMaybe<Scalars['String']>;
  settings: CreateZoomMeetingSettingsInput;
  start_time: Scalars['DateTime'];
  timezone: Scalars['String'];
  topic: Scalars['String'];
  type: Scalars['Int'];
  userId: Scalars['String'];
};

export type CreateZoomMeetingInvitiees = {
  __typename?: 'CreateZoomMeetingInvitiees';
  email?: Maybe<Scalars['String']>;
};

export type CreateZoomMeetingInvitieesInput = {
  email: Scalars['String'];
};

export type CreateZoomMeetingResponse = {
  __typename?: 'CreateZoomMeetingResponse';
  agenda?: Maybe<Scalars['String']>;
  assistant_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  host_email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  join_url?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  pmi?: Maybe<Scalars['Int']>;
  registration_url?: Maybe<Scalars['String']>;
  settings?: Maybe<CreateZoomMeetingSettings>;
  start_time?: Maybe<Scalars['Date']>;
  start_url?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

/** -------------------- ZOOM COMMON TYPES -------------------- */
export type CreateZoomMeetingSettings = {
  __typename?: 'CreateZoomMeetingSettings';
  approval_type?: Maybe<Scalars['Int']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  meeting_invitees?: Maybe<CreateZoomMeetingInvitiees>;
  registrants_confirmation_email?: Maybe<Scalars['Boolean']>;
  registrants_email_notification?: Maybe<Scalars['Boolean']>;
  start_time?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

export type CreateZoomMeetingSettingsInput = {
  alternative_hosts?: InputMaybe<Scalars['String']>;
  approval_type: Scalars['Int'];
  contact_email: Scalars['String'];
  contact_name: Scalars['String'];
  join_before_host?: InputMaybe<Scalars['Boolean']>;
  meeting_invitees: Array<CreateZoomMeetingInvitieesInput>;
  registrants_confirmation_email?: InputMaybe<Scalars['Boolean']>;
  registrants_email_notification?: InputMaybe<Scalars['Boolean']>;
};

export enum CreateZoomUserAction {
  AutoCreate = 'autoCreate',
  CustCreate = 'custCreate',
  SsoCreate = 'ssoCreate',
}

export type CreateZoomUserInfoInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  type: Scalars['Int'];
};

export type CreateZoomUserInput = {
  action: CreateZoomUserAction;
  user_info: CreateZoomUserInfoInput;
};

export type CreateZoomUserResponse = {
  __typename?: 'CreateZoomUserResponse';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
};

export type CustomBadge = {
  __typename?: 'CustomBadge';
  badge?: Maybe<Badge>;
  badgeId: Scalars['ID'];
  companyId: Scalars['ID'];
  maxExp?: Maybe<Scalars['Int']>;
  minExp?: Maybe<Scalars['Int']>;
  nextMaxExp?: Maybe<Scalars['Int']>;
  prevMinExp?: Maybe<Scalars['Int']>;
};

export enum Demand_Improve_Status {
  Assign = 'ASSIGN',
  InProgress = 'IN_PROGRESS',
  Parked = 'PARKED',
  Pending = 'PENDING',
  Solved = 'SOLVED',
  TestSolution = 'TEST_SOLUTION',
}

export enum Demand_Solve_Status {
  Assign = 'ASSIGN',
  Pending = 'PENDING',
  Solved = 'SOLVED',
  SolvingInProgress = 'SOLVING_IN_PROGRESS',
}

export enum Demand_Type {
  Both = 'BOTH',
  Improve = 'IMPROVE',
  Solve = 'SOLVE',
}

/** -------------------------------------- CUSTOM TYPE -------------------------------# */
export type DailyExp = {
  __typename?: 'DailyExp';
  date: Scalars['String'];
  value: Scalars['Int'];
};

export type DateRange = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type MeetingDateRangeInput = {
  end?: InputMaybe<Scalars['DateTime']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type DateRangeInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type DateRangeType = {
  __typename?: 'DateRangeType';
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type DeleteZoomMeetingInput = {
  meetingId: Scalars['String'];
};

export type DeleteZoomMeetingRegistrantInput = {
  meetingId: Scalars['String'];
  registrantId: Scalars['String'];
};

export type DeviceInfo = {
  __typename?: 'DeviceInfo';
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  isConnected?: Maybe<Scalars['Boolean']>;
  lasteUsed?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<CompanyMember>;
  token: Scalars['String'];
  typeDevice?: Maybe<Scalars['String']>;
};

export type DeviceInfoInput = {
  deviceId: Scalars['String'];
  lasteUsed?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  typeDevice?: InputMaybe<Scalars['String']>;
};

export type EarnExpEventInput = {
  description?: InputMaybe<Scalars['String']>;
  earnedById?: InputMaybe<Scalars['ID']>;
  earnedByTeamId?: InputMaybe<Scalars['ID']>;
  genericExpEventName: Scalars['String'];
};

export type EarnedExpEvent = {
  __typename?: 'EarnedExpEvent';
  communityExp?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  earnedAt?: Maybe<Scalars['DateTime']>;
  earnedBy?: Maybe<CompanyMember>;
  earnedByTeam?: Maybe<Community>;
  exp?: Maybe<Scalars['Int']>;
  expEvent?: Maybe<ExpEvent>;
  id: Scalars['ID'];
};

export type EmployeeOfDay = {
  __typename?: 'EmployeeOfDay';
  companyMember?: Maybe<CompanyMember>;
  day?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
};

export enum EmployeeOfDayStatus {
  Accepted = 'ACCEPTED',
  Decline = 'DECLINE',
  Pending = 'PENDING',
  Waiting = 'WAITING',
}

export type EndZoomMeetingInput = {
  action: MeetingStatusAction;
  meetingId: Scalars['String'];
};

export type Event = {
  __typename?: 'Event';
  company?: Maybe<Company>;
  companyMembers?: Maybe<Array<Maybe<CompanyMember>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  eventType?: Maybe<EventType>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type EventListMetaData = {
  __typename?: 'EventListMetaData';
  count: Scalars['Int'];
  locationOwners: Array<CompanyMember>;
};

export type EventMessageSuggestion = {
  __typename?: 'EventMessageSuggestion';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  eventType?: Maybe<EventType>;
  eventTypeId: Scalars['ID'];
  id: Scalars['ID'];
  removed: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
};

export type EventMessageSuggestionFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type EventType = {
  __typename?: 'EventType';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  eventMessageSuggestions?: Maybe<Array<Maybe<EventMessageSuggestion>>>;
  events?: Maybe<Array<Maybe<Event>>>;
  icon?: Maybe<Scalars['String']>;
  iconPublicUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type EventTypeFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type ExpEvent = {
  __typename?: 'ExpEvent';
  communityExp?: Maybe<Scalars['Int']>;
  endAt?: Maybe<Scalars['DateTime']>;
  exp?: Maybe<Scalars['Int']>;
  genericExpEvent?: Maybe<GenericExpEvent>;
  id: Scalars['ID'];
  startAt?: Maybe<Scalars['DateTime']>;
};

export type ExpEventFilter = {
  companyId?: InputMaybe<Scalars['ID']>;
  expEventType?: InputMaybe<ExpEventType>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

/** -------------------------------------- ENUMS -------------------------------# */
export enum ExpEventType {
  Community = 'COMMUNITY',
  Member = 'MEMBER',
}

export type ExpLevelUpDialog = {
  __typename?: 'ExpLevelUpDialog';
  open: Scalars['Boolean'];
};

export type ExpToCoin = {
  __typename?: 'ExpToCoin';
  coin?: Maybe<Scalars['Int']>;
  exp?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  type?: Maybe<ExpToCoinType>;
};

export enum ExpToCoinType {
  Community = 'COMMUNITY',
  Member = 'MEMBER',
}

export type ExportLeavesInput = {
  businessUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  companyMemberIds?: InputMaybe<Array<Scalars['ID']>>;
  dateRangeType: LeavesDateRangeType;
  ending: Scalars['DateTime'];
  reasonIds?: InputMaybe<Array<Scalars['ID']>>;
  start: Scalars['DateTime'];
};

export type ExternalEmail = {
  __typename?: 'ExternalEmail';
  email: Scalars['String'];
  id: Scalars['ID'];
  status?: Maybe<RegistrantStatus>;
};

export enum Filter_Type {
  DateRangePicker = 'DATE_RANGE_PICKER',
  DynamicMultiSelect = 'DYNAMIC_MULTI_SELECT',
  SelectBubble = 'SELECT_BUBBLE',
  Slider = 'SLIDER',
}

export type Feedback = {
  __typename?: 'Feedback';
  alreadySeen?: Maybe<Scalars['Boolean']>;
  companyMemberReceiver?: Maybe<CompanyMember>;
  companyMemberReceiverId?: Maybe<Scalars['ID']>;
  companyMemberSender?: Maybe<CompanyMember>;
  companyMemberSenderId?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  response?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type File = {
  __typename?: 'File';
  Need?: Maybe<Array<Maybe<Need>>>;
  OneToOneArchive?: Maybe<Array<Maybe<OneToOneArchive>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  extension?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  size?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FileInput = {
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type Filter = {
  dateRange?: InputMaybe<DateRangeInput>;
  isCustomFilter?: InputMaybe<Scalars['Boolean']>;
  isOneToMany?: InputMaybe<Scalars['Boolean']>;
  isSelectBubbleMultiselect?: InputMaybe<Scalars['Boolean']>;
  postField: Scalars['String'];
  selectedChip?: InputMaybe<Scalars['String']>;
  selectedChips?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  selectedDynamicMultiselectItems?: InputMaybe<Array<Scalars['String']>>;
  sliderValue?: InputMaybe<Array<Scalars['Int']>>;
};

export type FollowUp = {
  __typename?: 'FollowUp';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type FollowUpFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type ForgotPasswordConfirmInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

/** -------------------------------------- INTERNAL TYPE -------------------------------# */
export type GenericExpEvent = {
  __typename?: 'GenericExpEvent';
  communityExp?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  exp?: Maybe<Scalars['Int']>;
  expEventType?: Maybe<ExpEventType>;
  id: Scalars['ID'];
  isCodeImplemented?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** -------------------------------------- INPUTS -------------------------------# */
export type GenericExpEventFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export type GetApplicationCategoriesInput = {
  filter?: InputMaybe<ApplicationCategoryFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type GetApplicationsInput = {
  filter?: InputMaybe<ApplicationFilterInput>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type GetApplicationsMetaInput = {
  filter?: InputMaybe<ApplicationFilterInput>;
};

export type HelpMark = {
  __typename?: 'HelpMark';
  createdAt?: Maybe<Scalars['DateTime']>;
  en_description?: Maybe<Scalars['String']>;
  en_title?: Maybe<Scalars['String']>;
  fr_description?: Maybe<Scalars['String']>;
  fr_title?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  image?: Maybe<File>;
  removed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<HelpMarkType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type HelpMarkFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export enum HelpMarkType {
  A3Process = 'A3_PROCESS',
  Clarity = 'CLARITY',
  Feedback = 'FEEDBACK',
  General = 'GENERAL',
  Goal = 'GOAL',
  GoalsAndKpi = 'GOALS_AND_KPI',
  Idea = 'IDEA',
  Improve = 'IMPROVE',
  IndivCoins = 'INDIV_COINS',
  IssuesExchange = 'ISSUES_EXCHANGE',
  MissionsAndActions = 'MISSIONS_AND_ACTIONS',
  MyNeeds = 'MY_NEEDS',
  Passion = 'PASSION',
  Process = 'PROCESS',
  Reward = 'REWARD',
  RoadBlocs = 'ROAD_BLOCS',
  Submit = 'SUBMIT',
  Support = 'SUPPORT',
  Teams = 'TEAMS',
  TeamCoins = 'TEAM_COINS',
  Trust = 'TRUST',
}

export type IdeaCategory = {
  __typename?: 'IdeaCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type IdeaCategoryFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export type IdeasRatingFilter = {
  value?: InputMaybe<Scalars['Int']>;
};

export type Indicator = {
  __typename?: 'Indicator';
  OnProgressDays?: Maybe<Scalars['Int']>;
  action?: Maybe<IndicatorAction>;
  actions?: Maybe<Array<Action>>;
  actionsCount?: Maybe<Scalars['Int']>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  community?: Maybe<Community>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  histories?: Maybe<Array<IndicatorHistory>>;
  id: Scalars['ID'];
  level?: Maybe<IndicatorLevel>;
  name?: Maybe<Scalars['String']>;
  onProgressDate?: Maybe<Scalars['DateTime']>;
  requestStatus?: Maybe<CommunityMemberRequestStatus>;
  trend?: Maybe<IndicatorTrend>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
};

export enum IndicatorAction {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  ToDo = 'TO_DO',
}

export type IndicatorFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
};

export type IndicatorHistory = {
  __typename?: 'IndicatorHistory';
  actionFrom?: Maybe<IndicatorAction>;
  actionTo?: Maybe<IndicatorAction>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateFrom?: Maybe<Scalars['DateTime']>;
  dateTo?: Maybe<Scalars['DateTime']>;
  descriptionFrom?: Maybe<Scalars['String']>;
  descriptionTo?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  levelFrom?: Maybe<IndicatorLevel>;
  levelTo?: Maybe<IndicatorLevel>;
  nameFrom?: Maybe<Scalars['String']>;
  nameTo?: Maybe<Scalars['String']>;
  trendFrom?: Maybe<IndicatorTrend>;
  trendTo?: Maybe<IndicatorTrend>;
  valueFrom?: Maybe<Scalars['String']>;
  valueTo?: Maybe<Scalars['String']>;
};

export enum IndicatorLevel {
  Above = 'ABOVE',
  AtLevel = 'AT_LEVEL',
  Below = 'BELOW',
}

export enum IndicatorTrend {
  Balanced = 'BALANCED',
  Degressive = 'DEGRESSIVE',
  NoTrend = 'NO_TREND',
  Progressive = 'PROGRESSIVE',
}

/** TODO: Move it to the appropriate service */
export type IndicatorsNumberPerTeam = {
  __typename?: 'IndicatorsNumberPerTeam';
  count: Scalars['Int'];
  team: Community;
};

export type IndividualBadge = {
  __typename?: 'IndividualBadge';
  badge?: Maybe<Badge>;
  badgeId: Scalars['ID'];
  companyMemberId: Scalars['ID'];
  status?: Maybe<Scalars['Float']>;
};

export enum IssuePrivacy {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
}

export type Language = {
  __typename?: 'Language';
  id: Scalars['ID'];
  key: Scalars['String'];
  label?: Maybe<Scalars['String']>;
};

export type LeaderMessage = {
  __typename?: 'LeaderMessage';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  member?: Maybe<CompanyMember>;
  message?: Maybe<Scalars['String']>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Leave = {
  __typename?: 'Leave';
  allowToDelete?: Maybe<Scalars['Boolean']>;
  attachements?: Maybe<Array<File>>;
  comments?: Maybe<Array<Comment>>;
  creator?: Maybe<CompanyMember>;
  days?: Maybe<Scalars['Int']>;
  daysDuration?: Maybe<Array<LeaveDayDuration>>;
  ending?: Maybe<Scalars['DateTime']>;
  fileCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  imageFiles?: Maybe<Array<File>>;
  isHalfDay?: Maybe<Scalars['Boolean']>;
  isMine?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  otherReason?: Maybe<Scalars['String']>;
  owner?: Maybe<CompanyMember>;
  peoplesToNotify?: Maybe<Array<CompanyMember>>;
  reasons?: Maybe<Array<LeaveReason>>;
  specialDayOff?: Maybe<Scalars['Boolean']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LeaveStatus>;
  validationDate?: Maybe<Scalars['DateTime']>;
  validators?: Maybe<Array<CompanyMember>>;
};

export type LeaveByCompanyMember = {
  __typename?: 'LeaveByCompanyMember';
  companyMember: CompanyMember;
  leave: Leave;
};

export enum LeaveDayDuration {
  Am = 'AM',
  Full = 'FULL',
  Pm = 'PM',
}

export type LeaveFilterHistoryInput = {
  communityFilter?: InputMaybe<CommunitiesFilter>;
  communityId?: InputMaybe<Scalars['String']>;
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
  statusFilter?: InputMaybe<LeaveStatus>;
  validatorIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** -------------------------------------- INPUTS -------------------------------# */
export type LeaveFilterInput = {
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  communityId?: InputMaybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  dateRange?: InputMaybe<CalendarDateRangeInput>;
  statusFilter?: InputMaybe<LeaveStatusFilter>;
  view: Scalars['String'];
};

/** -------------------------------------- MODEL TYPE -------------------------------# */
export type LeaveMetadata = {
  __typename?: 'LeaveMetadata';
  count: Scalars['Int'];
  owners: Array<CompanyMember>;
};

export type LeaveReason = {
  __typename?: 'LeaveReason';
  id: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
};

/** -------------------------------------- ENUMS -------------------------------# */
export enum LeaveStatus {
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Validated = 'VALIDATED',
}

export enum LeaveStatusFilter {
  All = 'ALL',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Validated = 'VALIDATED',
}

export type LeavesByDate = {
  __typename?: 'LeavesByDate';
  date: Scalars['DateTime'];
  leaveCount: Scalars['Int'];
  leaveCountWithoutStatus: Scalars['Int'];
  leaves: Array<Leave>;
  owners: Array<CompanyMember>;
};

export type LeavesByDateInput = {
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  date: Scalars['String'];
  mine?: InputMaybe<Scalars['Boolean']>;
  statusFilter?: InputMaybe<LeaveStatusFilter>;
};

export enum LeavesDateRangeType {
  Period = 'PERIOD',
  PeriodOrValidationDate = 'PERIOD_OR_VALIDATION_DATE',
  ValidationDate = 'VALIDATION_DATE',
}

export type LeavesGroupedByDay = {
  __typename?: 'LeavesGroupedByDay';
  day: Scalars['DateTime'];
  leaves: Array<Leave>;
};

export enum LeavesStatusToDownload {
  Both = 'BOTH',
  Rejected = 'REJECTED',
  Validated = 'VALIDATED',
}

/** -------------------------------------- TYPE -------------------------------# */
export type ListMetadata = {
  __typename?: 'ListMetadata';
  count: Scalars['Int'];
};

export enum LiveStreamStatusAction {
  Start = 'start',
  Stop = 'stop',
}

export enum LocalCreationActionType {
  IdeaAction = 'IDEA_ACTION',
  IndicatorAction = 'INDICATOR_ACTION',
  IssueAction = 'ISSUE_ACTION',
  OneToOneAction = 'ONE_TO_ONE_ACTION',
  ProjectAction = 'PROJECT_ACTION',
  PureAction = 'PURE_ACTION',
  RoadBlockAction = 'ROAD_BLOCK_ACTION',
  TeamAction = 'TEAM_ACTION',
}

export type Location = {
  __typename?: 'Location';
  createdAt?: Maybe<Scalars['DateTime']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type LocationEventsDetailPerDay = {
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  companyMemberOwnerId?: InputMaybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  locationId?: InputMaybe<Scalars['ID']>;
};

/** -------------------- ENUMS -------------------- */
export enum Meeting_Status {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Refused = 'REFUSED',
}

export enum MainRole {
  Admin = 'ADMIN',
  Member = 'MEMBER',
}

/** -------------------- TYPES -------------------- */
export type Meeting = {
  __typename?: 'Meeting';
  attendees?: Maybe<Array<CompanyMember>>;
  comments?: Maybe<Array<Comment>>;
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<CompanyMember>;
  creatorRegistrantId?: Maybe<Scalars['String']>;
  currentConnectedJoinUrl?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  externalEmails?: Maybe<Array<ExternalEmail>>;
  humanDueDate?: Maybe<Scalars['String']>;
  humanDuration?: Maybe<Scalars['String']>;
  icsLink?: Maybe<Scalars['String']>;
  icsPublicLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  invitationLink?: Maybe<Scalars['String']>;
  isAcceptedByMe?: Maybe<Scalars['Boolean']>;
  isAlreadyApproved?: Maybe<Scalars['Boolean']>;
  isAlreadyDenied?: Maybe<Scalars['Boolean']>;
  isDeclinedByMe?: Maybe<Scalars['Boolean']>;
  isMine?: Maybe<Scalars['Boolean']>;
  isPast?: Maybe<Scalars['Boolean']>;
  isPublic?: Maybe<Scalars['Boolean']>;
  joinUrl?: Maybe<Scalars['String']>;
  myRegistrantId?: Maybe<Scalars['String']>;
  passcode?: Maybe<Scalars['String']>;
  registrants?: Maybe<Array<MeetingRegistrant>>;
  removed?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Meeting_Status>;
  timezone?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['String']>;
};

/** -------------------- TYPES -------------------- */
export type MeetingRegistrantsArgs = {
  status?: InputMaybe<RegistrantStatus>;
};

/** -------------------- INPUTS -------------------- */
export type MeetingFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type MeetingFilterHistoryInput = {
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
};

export type MeetingInviteeInput = {
  address?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name?: InputMaybe<Scalars['String']>;
  zip: Scalars['String'];
};

export type MeetingRegistrant = {
  __typename?: 'MeetingRegistrant';
  companyMember?: Maybe<CompanyMember>;
  companyMemberId: Scalars['ID'];
  meeting?: Maybe<Meeting>;
  status?: Maybe<RegistrantStatus>;
  uuid: Scalars['ID'];
  zoomRegistrantId?: Maybe<Scalars['String']>;
};

export type MeetingRegistrantInCompanyInput = {
  companyMemberId: Scalars['ID'];
  uuid: Scalars['ID'];
};

export enum MeetingRegistrantStatusAction {
  Approve = 'approve',
  Deny = 'deny',
}

export enum MeetingStatusAction {
  End = 'end',
  Recover = 'recover',
}

export type MemberAndRoleInput = {
  businessUnitRole?: InputMaybe<BusinessUnitRole>;
  companyMemberId: Scalars['ID'];
};

export enum MemberRole {
  Director = 'DIRECTOR',
  Employee = 'EMPLOYEE',
  FinancialController = 'FINANCIAL_CONTROLLER',
}

export type MessageSuggestion = {
  __typename?: 'MessageSuggestion';
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<MessageType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum MessageType {
  EmployeeOfDay = 'EMPLOYEE_OF_DAY',
  Thank = 'THANK',
  Welcome = 'WELCOME',
}

export type Mission = {
  __typename?: 'Mission';
  community?: Maybe<Community>;
  companyMember?: Maybe<CompanyMember>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  deadline?: Maybe<Scalars['Date']>;
  estimateDate?: Maybe<Scalars['Date']>;
  experts?: Maybe<Array<Maybe<CompanyMember>>>;
  id: Scalars['ID'];
  priorityLevel?: Maybe<Scalars['Int']>;
  progressStatus?: Maybe<MissionProgressStatus>;
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

/** ####################### ENUM ################################### */
export enum MissionProgressStatus {
  Finished = 'FINISHED',
  InProgress = 'IN_PROGRESS',
  ToDo = 'TO_DO',
}

export type Motto = {
  __typename?: 'Motto';
  company?: Maybe<Company>;
  companyId: Scalars['ID'];
  companyMember?: Maybe<CompanyMember>;
  companyMemberId: Scalars['ID'];
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed: Scalars['Boolean'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MottoFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  acceptCommunityMemberRequest: Community;
  activateApplication: Application;
  addCompanyMember: CompanyMember;
  addCompanyMemberToCommunity: Community;
  addMemberToBusinessUnit?: Maybe<BusinessUnit>;
  addMembersToBusinessUnit?: Maybe<BusinessUnit>;
  addOrRemoveLikePost?: Maybe<Post>;
  addZoomGroupAdmin?: Maybe<AddZoomGroupAdminResponse>;
  addZoomMeetingRegistrant?: Maybe<AddZoomMeetingRegistrantResponse>;
  attachFileToBusinessUnit: Array<Maybe<File>>;
  attachFileToCompany: File;
  batchZoomMeetingRegistration?: Maybe<BatchZoomMeetingRegistrationResponse>;
  calendarCommunityEventOnTimeNotification: Scalars['Boolean'];
  changePassword: Success;
  changePostStatusA3?: Maybe<Post>;
  changeSupervisor: CompanyMember;
  checkedAction: Action;
  chooseProfessionalStatus?: Maybe<ProfessionalStatus>;
  chooseProfessionnalWeather?: Maybe<CompanyMemberProfessionnalWeather>;
  closeSemiInstatntCall: SemiInstantCall;
  connectToZoom?: Maybe<ConnectToZoomResponse>;
  createAction: Action;
  createAdviceCategory: AdviceCategory;
  createAnalysis5Why?: Maybe<Analysis5Why>;
  createAnalysisCompare?: Maybe<AnalysisCompare>;
  createAnalysisRootCause?: Maybe<AnalysisRootCause>;
  createAnalysisWhy?: Maybe<AnalysisWhy>;
  createApplication: Application;
  createBusinessUnit?: Maybe<BusinessUnit>;
  createBusinessUnitIndicator?: Maybe<Indicator>;
  createCalendarCommunityEvent?: Maybe<CalendarEvent>;
  createCalendarFilterHistory: CalendarFilterHistory;
  createCalendarLocationEvent?: Maybe<CalendarEvent>;
  createComment: Comment;
  createCommunity: Community;
  createCommunityArchive: Community;
  createCommunityEventType?: Maybe<CommunityEventType>;
  createCommunityIndicator?: Maybe<Indicator>;
  createCompany?: Maybe<Company>;
  createCompanyIndicator?: Maybe<Indicator>;
  createCompanyMemberOrder?: Maybe<CompanyMemberOrder>;
  createCompanyMemberProfessionnalWeather?: Maybe<CompanyMemberProfessionnalWeather>;
  createCompareComment?: Maybe<CompareComment>;
  createCounterMeasure?: Maybe<CounterMeasure>;
  createEvent?: Maybe<Event>;
  createEventMessageSuggestion?: Maybe<EventMessageSuggestion>;
  createEventType?: Maybe<EventType>;
  createFile: File;
  createFollowUp?: Maybe<FollowUp>;
  /** Generic exp events mutations */
  createGenericExpEvent: GenericExpEvent;
  createHelpMark: HelpMark;
  createIdeaCategory: IdeaCategory;
  createLeave: Leave;
  createMeeting?: Maybe<Meeting>;
  createMission: Mission;
  createMotto?: Maybe<Motto>;
  createNeeds?: Maybe<Array<Need>>;
  createNotification?: Maybe<Array<Notification>>;
  createObjective?: Maybe<Objective>;
  createOneToOne: OneToOne;
  createPost: Post;
  createPostIndicator?: Maybe<Indicator>;
  createPostRating?: Maybe<Post>;
  createPostRequest?: Maybe<PostRequest>;
  createProblemCategory: ProblemCategory;
  createProblemCircuit?: Maybe<ProblemCircuit>;
  createProcessCategory: ProcessCategory;
  createProduct?: Maybe<Product>;
  createProfessionalStatus?: Maybe<ProfessionalStatus>;
  createProfessionnalWeather?: Maybe<ProfessionnalWeather>;
  createProject: Project;
  createQuestionCategory: QuestionCategory;
  createRoadBlock: RoadBlock;
  createRootCauseComment?: Maybe<RootCauseComment>;
  createSemiInstantCall: SemiInstantCall;
  createSkill?: Maybe<Skill>;
  createUser: User;
  createUserFromSignUp: Success;
  createUserPreference: UserPreference;
  createZoomGroup?: Maybe<CreateZoomGroupResponse>;
  createZoomMeeting?: Maybe<CreateZoomMeetingResponse>;
  createZoomUser?: Maybe<CreateZoomUserResponse>;
  deleteAction: Action;
  deleteAdviceCategory: AdviceCategory;
  deleteAnalysisCompare?: Maybe<AnalysisCompare>;
  deleteAnalysisRootCause?: Maybe<AnalysisRootCause>;
  deleteAnalysisWhy?: Maybe<AnalysisWhy>;
  deleteApplication: Application;
  deleteBusinessUnit?: Maybe<BusinessUnit>;
  deleteCalendarEvent?: Maybe<CalendarEvent>;
  deleteCalendarFilterHistory: CalendarFilterHistory;
  deleteComment?: Maybe<Comment>;
  deleteCommunity: Community;
  deleteCommunityEventType?: Maybe<CommunityEventType>;
  deleteCompany: Company;
  deleteCompanyMember: CompanyMember;
  deleteCompanyMemberOrder?: Maybe<CompanyMemberOrder>;
  deleteCompanyMemberProfessionnalWeather?: Maybe<CompanyMemberProfessionnalWeather>;
  deleteCounterMeasure?: Maybe<CounterMeasure>;
  deleteCounterMeasureFile?: Maybe<CounterMeasure>;
  deleteEvent?: Maybe<Event>;
  deleteEventMessageSuggestion?: Maybe<EventMessageSuggestion>;
  deleteEventType?: Maybe<EventType>;
  deleteFile: File;
  deleteFollowUp?: Maybe<FollowUp>;
  deleteGenericExpEvent: GenericExpEvent;
  deleteHelpMark: HelpMark;
  deleteIdeaCategory: IdeaCategory;
  deleteIndicator?: Maybe<Indicator>;
  deleteLeave: Leave;
  deleteMeeting?: Maybe<Meeting>;
  deleteMission: Mission;
  deleteMotto?: Maybe<Motto>;
  deleteObjective?: Maybe<Objective>;
  deleteOneToOne: OneToOne;
  deletePendingInvitation: UserCompanyInvitation;
  deletePost?: Maybe<Post>;
  deletePostRequest?: Maybe<PostRequest>;
  deleteProblemCategory: ProblemCategory;
  deleteProblemCircuit?: Maybe<ProblemCircuit>;
  deleteProcessCategory: ProcessCategory;
  deleteProduct?: Maybe<Product>;
  deleteProfessionalStatus?: Maybe<ProfessionalStatus>;
  deleteProfessionnalWeather?: Maybe<ProfessionnalWeather>;
  deleteProject: Project;
  deleteProjectAction: Action;
  deleteQuestionCategory: QuestionCategory;
  deleteRoadBlock: RoadBlock;
  deleteSkill?: Maybe<Skill>;
  deleteUser: User;
  deleteUserPresentationVideo: User;
  deleteZoomMeeting?: Maybe<Scalars['Boolean']>;
  deleteZoomMeetingRegistrant?: Maybe<Scalars['Boolean']>;
  deviceInfoManagement?: Maybe<DeviceInfo>;
  earnExpEvent: EarnedExpEvent;
  enableApplication: Application;
  endZoomMeeting?: Maybe<Scalars['Boolean']>;
  finalizePost: Post;
  finalizeProject: Project;
  forgotPasswordAsking: Success;
  forgotPasswordConfirm: Success;
  generateStreamToken: Scalars['String'];
  likeComment: Comment;
  logout: Success;
  makeSeenCoinHistories: Scalars['Boolean'];
  markAllAsRead?: Maybe<Scalars['Boolean']>;
  markAsAlreadyUseA3Process?: Maybe<Post>;
  removeCommunityInPendingInvitation: UserCompanyInvitation;
  removeCompanyMemberToCommunity: UserCommunity;
  removeMembersToBusinessUnit?: Maybe<BusinessUnit>;
  replyFeedback: Feedback;
  replyThanks: UserThank;
  resend2FACode: Success;
  resendInvitation: Success;
  /** Au minuit, reset tous les status à PENDING */
  resetEmployeesStatus: Array<CompanyMember>;
  /** Call */
  resetIncomingCallsCount?: Maybe<CompanyMember>;
  resetNewPostsCountByType: Scalars['Boolean'];
  resetProfessionalWeather: Array<CompanyMember>;
  saveCurrentFilters: Array<PostFilterHistory>;
  /** See notification by id */
  seeNotification?: Maybe<Notification>;
  /** Au midi envoyer un notif au prochain EOD + changer son status à WAITING */
  sendEmployeeOfDayNotificationOnNoon: Array<CompanyMember>;
  /** Send feedback by companyMemberId */
  sendFeedback: Feedback;
  sendNotificationOnMeetingDate?: Maybe<Scalars['Boolean']>;
  setActiveHoursTimeEnd?: Maybe<UserPreference>;
  setActiveHoursTimeStart?: Maybe<UserPreference>;
  setCurrentImage: Company;
  setDirector: Company;
  setEnableActiveHoursValue?: Maybe<UserPreference>;
  setEnableContextualNotificationValue: UserPreference;
  setEnableNavigateurNotificationValue: UserPreference;
  setEnableShowEntryTunnelValue: UserPreference;
  setEnableShowExitTunnelValue: UserPreference;
  setEntryTunnelTime: UserPreference;
  setExitTunnelTime: UserPreference;
  setIsFirstEntry: Scalars['Boolean'];
  setLocale: UserPreference;
  setShowEntryTunnelPragmaToday: UserPreference;
  setShowEntryTunnelToday: UserPreference;
  setShowExitTunnelPragmaToday: UserPreference;
  setShowExitTunnelToday: UserPreference;
  sharePost?: Maybe<CompanyMemberPostShare>;
  signUpInvitation: SignUpInvitationPayload;
  signin: SigninPayload;
  signup: SignupPayload;
  suspendCompanyMember: CompanyMember;
  switchCompany: SwitchCompanyPayload;
  thanksSomeone: UserThank;
  toggle2FA: User;
  tunnelPragmaTime: Array<UserPreference>;
  twoFactorAuthentication: TwoFactorAuthenticationPayload;
  unlikeComment: Comment;
  updateAction: Action;
  updateAnalysisCompare?: Maybe<AnalysisCompare>;
  updateAnalysisRootCause?: Maybe<AnalysisRootCause>;
  updateAnalysisWhy?: Maybe<AnalysisWhy>;
  updateApplication: Application;
  updateBusinessUnit?: Maybe<BusinessUnit>;
  updateCalendarCommunityEvent?: Maybe<CalendarEvent>;
  updateCalendarFilterHistory: CalendarFilterHistory;
  updateCalendarLocationEvent?: Maybe<CalendarEvent>;
  updateCeoWord?: Maybe<Company>;
  updateCoachPreference?: Maybe<CoachPreference>;
  updateComment?: Maybe<Comment>;
  updateCommunity: Community;
  updateCommunityEventType?: Maybe<CommunityEventType>;
  updateCommunityRoleInPendingInvitation: UserCompanyInvitation;
  updateCompany?: Maybe<Company>;
  updateCompanyMember: CompanyMember;
  updateCompanyMemberOrder?: Maybe<CompanyMemberOrder>;
  updateCompanyMemberProfessionnalWeather?: Maybe<CompanyMemberProfessionnalWeather>;
  updateCounterMeasure?: Maybe<CounterMeasure>;
  updateCustomBadge: CustomBadge;
  updateDemandStatus?: Maybe<Post>;
  /**
   * Employee of day mutations
   * Permet de modifier le rang (l'ordre)
   */
  updateEmployeeOfDayRank: Array<CompanyMember>;
  /** Permet la mise à jour du status d'un membre, ou de le forcer s'il a decliner */
  updateEmployeeOfDayStatus: Array<CompanyMember>;
  updateEvent?: Maybe<Event>;
  updateEventMessageSuggestion?: Maybe<EventMessageSuggestion>;
  updateEventType?: Maybe<EventType>;
  /** Instance exp events mutations */
  updateExpEvent: ExpEvent;
  updateFollowUp?: Maybe<FollowUp>;
  updateGenericExpEvent: GenericExpEvent;
  updateHelpMark: HelpMark;
  updateIdeaCategory: IdeaCategory;
  updateIndicator?: Maybe<Indicator>;
  updateInvitation: UserCompanyInvitation;
  updateLeaderMessage: LeaderMessage;
  updateLeave: Leave;
  updateMeeting?: Maybe<Meeting>;
  updateMission: Mission;
  updateMotto?: Maybe<Motto>;
  updateMyTotalCoins: Scalars['Int'];
  updateNeed: Need;
  updateObjective?: Maybe<Objective>;
  updateOneToOne: OneToOne;
  updatePost: Post;
  updatePostPrivacy: Post;
  updatePostVisibility: Post;
  updateProblemCategory: ProblemCategory;
  updateProblemCircuit?: Maybe<ProblemCircuit>;
  updateProduct?: Maybe<Product>;
  updateProfessionalStatus?: Maybe<ProfessionalStatus>;
  updateProfessionnalWeather?: Maybe<ProfessionnalWeather>;
  updateProfile: CompanyMember;
  updateProject: Project;
  updateProjectAction: Action;
  updateProjectStatus: Project;
  updateRoadBlock: RoadBlock;
  updateSemiInstantCall: SemiInstantCall;
  updateSkill?: Maybe<Skill>;
  updateTeamMemberRole: UserCommunity;
  updateUser: User;
  updateUserPicture: User;
  updateUserPreference?: Maybe<UserPreference>;
  updateZoomMeeting?: Maybe<Scalars['Boolean']>;
  updateZoomMeetingRegistrantStatus?: Maybe<Scalars['Boolean']>;
  userInvitation: Success;
  validateLeave: Leave;
  validatedLeaves: Array<LeavesGroupedByDay>;
  wishSomeone?: Maybe<Scalars['String']>;
};

export type MutationAcceptCommunityMemberRequestArgs = {
  memberId: Scalars['ID'];
};

export type MutationActivateApplicationArgs = {
  id: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type MutationAddCompanyMemberArgs = {
  companySlug: Scalars['String'];
  hiringDate: Scalars['DateTime'];
  role: MemberRole;
  userAccess: MainRole;
  userId: Scalars['ID'];
};

export type MutationAddCompanyMemberToCommunityArgs = {
  communityId: Scalars['ID'];
  companyMemberId: Scalars['ID'];
  role: CommunityRole;
};

export type MutationAddMemberToBusinessUnitArgs = {
  input: AddMemberToBusinessUnitInput;
};

export type MutationAddMembersToBusinessUnitArgs = {
  input: AddMembersToBusinessUnitInput;
};

export type MutationAddOrRemoveLikePostArgs = {
  postId: Scalars['ID'];
};

export type MutationAddZoomGroupAdminArgs = {
  input: AddZoomGroupAdminInput;
};

export type MutationAddZoomMeetingRegistrantArgs = {
  input: AddZoomMeetingRegistrantInput;
};

export type MutationAttachFileToBusinessUnitArgs = {
  businessUnitId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
};

export type MutationAttachFileToCompanyArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationBatchZoomMeetingRegistrationArgs = {
  input: BatchZoomMeetingRegistrationInput;
};

export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type MutationChangePostStatusA3Args = {
  a3Status: Post_A3_Status;
  postId: Scalars['ID'];
};

export type MutationChangeSupervisorArgs = {
  companyMemberId: Scalars['ID'];
  supervisorId?: InputMaybe<Scalars['ID']>;
};

export type MutationCheckedActionArgs = {
  actionId: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
};

export type MutationChooseProfessionalStatusArgs = {
  professionalStatusId?: InputMaybe<Scalars['ID']>;
};

export type MutationChooseProfessionnalWeatherArgs = {
  professionnalWeatherId: Scalars['ID'];
};

export type MutationCloseSemiInstatntCallArgs = {
  id: Scalars['ID'];
};

export type MutationCreateActionArgs = {
  actionType?: InputMaybe<ActionType>;
  businessUnitId?: InputMaybe<Scalars['ID']>;
  communityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  convertedFromIdeaId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  indicatorId?: InputMaybe<Scalars['ID']>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  localCreationType: LocalCreationActionType;
  oneToOneSubordonateId?: InputMaybe<Scalars['ID']>;
  parentActionId?: InputMaybe<Scalars['ID']>;
  postId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
  responsibleIds?: InputMaybe<Array<Scalars['ID']>>;
  roadBlockId?: InputMaybe<Scalars['ID']>;
  targetDate?: InputMaybe<Scalars['Date']>;
  title?: InputMaybe<Scalars['String']>;
};
export type MutationDeleteActionToProjectArgs = {
  id: Scalars['ID'];
};

export type MutationCreateAdviceCategoryArgs = {
  title: Scalars['String'];
};

export type MutationCreateAnalysis5WhyArgs = {
  analysisWhyId: Scalars['ID'];
  content: Scalars['String'];
  rank?: InputMaybe<Scalars['Int']>;
};

export type MutationCreateAnalysisCompareArgs = {
  postId: Scalars['ID'];
  subject1: Scalars['String'];
  subject2: Scalars['String'];
};

export type MutationCreateAnalysisRootCauseArgs = {
  description: Scalars['String'];
  postId: Scalars['ID'];
};

export type MutationCreateAnalysisWhyArgs = {
  description: Scalars['String'];
  postId: Scalars['ID'];
};

export type MutationCreateApplicationArgs = {
  input: CreateApplicationInput;
};

export type MutationCreateBusinessUnitArgs = {
  input: CreateBusinessUnitInput;
};

export type MutationCreateBusinessUnitIndicatorArgs = {
  input: CreateBusinessUnitIndicatorInput;
};

export type MutationCreateCalendarCommunityEventArgs = {
  input: CreateCalendarCommunityEventInput;
};

export type MutationCreateCalendarFilterHistoryArgs = {
  input: CalendarFilterHistoryInput;
};

export type MutationCreateCalendarLocationEventArgs = {
  input: CreateCalendarLocationEventInput;
};

export type MutationCreateCommentArgs = {
  actionId?: InputMaybe<Scalars['ID']>;
  calendarEventId?: InputMaybe<Scalars['ID']>;
  communityEventId?: InputMaybe<Scalars['ID']>;
  content: Scalars['String'];
  indicatorId?: InputMaybe<Scalars['ID']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  leaveId?: InputMaybe<Scalars['ID']>;
  meetingId?: InputMaybe<Scalars['ID']>;
  postId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
};

export type MutationCreateCommunityArgs = {
  companyId: Scalars['ID'];
  logo?: InputMaybe<Scalars['String']>;
  mission?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rommance?: InputMaybe<Scalars['String']>;
};

export type MutationCreateCommunityArchiveArgs = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MutationCreateCommunityEventTypeArgs = {
  input: CreateCommunityEventTypeInput;
};

export type MutationCreateCommunityIndicatorArgs = {
  input: CreateCommunityIndicatorInput;
};

export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};

export type MutationCreateCompanyIndicatorArgs = {
  input: CreateCompanyIndicatorInput;
};

export type MutationCreateCompanyMemberOrderArgs = {
  input: CreateCompanyMemberOrderInput;
};

export type MutationCreateCompanyMemberProfessionnalWeatherArgs = {
  professionnalWeatherId: Scalars['ID'];
};

export type MutationCreateCompareCommentArgs = {
  compareId: Scalars['ID'];
  content: Scalars['String'];
};

export type MutationCreateCounterMeasureArgs = {
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['Int']>;
  fileType?: InputMaybe<Scalars['String']>;
  postId: Scalars['ID'];
  title: Scalars['String'];
};

export type MutationCreateEventArgs = {
  day?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  eventTypeId: Scalars['ID'];
  name: Scalars['String'];
  userIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MutationCreateEventMessageSuggestionArgs = {
  content: Scalars['String'];
  eventTypeId: Scalars['ID'];
};

export type MutationCreateEventTypeArgs = {
  input?: InputMaybe<CreateEventTypeInput>;
};

export type MutationCreateFileArgs = {
  name: Scalars['String'];
  path?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type MutationCreateFollowUpArgs = {
  description: Scalars['String'];
  postId: Scalars['ID'];
};

export type MutationCreateGenericExpEventArgs = {
  input: CreateGenericExpEventInput;
};

export type MutationCreateHelpMarkArgs = {
  input?: InputMaybe<CreateHelpMarkInput>;
};

export type MutationCreateIdeaCategoryArgs = {
  title: Scalars['String'];
};

export type MutationCreateLeaveArgs = {
  input: CreateLeaveInput;
};

export type MutationCreateMeetingArgs = {
  input: CreateMeetingInput;
};

export type MutationCreateMissionArgs = {
  communityId: Scalars['ID'];
  companyMemberId: Scalars['ID'];
  deadline?: InputMaybe<Scalars['Date']>;
  estimateDate?: InputMaybe<Scalars['Date']>;
  expertIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  oneToOneSubordonateId?: InputMaybe<Scalars['ID']>;
  priorityLevel?: InputMaybe<Scalars['Int']>;
  progressStatus?: InputMaybe<MissionProgressStatus>;
  title: Scalars['String'];
};

export type MutationCreateMottoArgs = {
  content?: InputMaybe<Scalars['String']>;
};

export type MutationCreateNeedsArgs = {
  input?: InputMaybe<Array<CreateNeedsInput>>;
};

export type MutationCreateNotificationArgs = {
  date?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  isBot?: InputMaybe<Scalars['Boolean']>;
  linkTo?: InputMaybe<Scalars['String']>;
  parametres?: InputMaybe<Array<Scalars['String']>>;
  receiverIds: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type MutationCreateObjectiveArgs = {
  description: Scalars['String'];
  postId: Scalars['ID'];
};

export type MutationCreateOneToOneArgs = {
  input: CreateOneToOneInput;
};

export type MutationCreatePostArgs = {
  adviceCategoryId?: InputMaybe<Scalars['ID']>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  companyMemberExpertIds?: InputMaybe<Array<Scalars['ID']>>;
  companyMemberResponsibleIds?: InputMaybe<Array<Scalars['ID']>>;
  content: Scalars['String'];
  demand?: InputMaybe<Demand_Type>;
  fileIds?: InputMaybe<Array<Scalars['ID']>>;
  how?: InputMaybe<Scalars['String']>;
  ideaCategoryId?: InputMaybe<Scalars['ID']>;
  impact?: InputMaybe<Scalars['String']>;
  issuePrivacy?: InputMaybe<IssuePrivacy>;
  postType: PostType;
  postedAnonymously?: InputMaybe<Scalars['Boolean']>;
  presentationFileId?: InputMaybe<Scalars['ID']>;
  problemCategoryId?: InputMaybe<Scalars['ID']>;
  problemCircuitId?: InputMaybe<Scalars['ID']>;
  problemCommunitySourceId?: InputMaybe<Scalars['ID']>;
  problemFrom?: InputMaybe<Scalars['DateTime']>;
  problemLevel?: InputMaybe<Scalars['Int']>;
  problemTo?: InputMaybe<Scalars['DateTime']>;
  processStatus?: InputMaybe<Process_Status>;
  questionCategoryIid?: InputMaybe<Scalars['ID']>;
  ratingPeriod?: InputMaybe<Scalars['DateTime']>;
  recipientCommunityId?: InputMaybe<Scalars['ID']>;
  responsibleIds?: InputMaybe<Array<Scalars['ID']>>;
  sharedToCommunitiesIds?: InputMaybe<Array<Scalars['ID']>>;
  sharedToCompanyMembersIds?: InputMaybe<Array<Scalars['ID']>>;
  sharedToUsersIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<Post_Status>;
  title: Scalars['String'];
  validatorIds?: InputMaybe<Array<Scalars['ID']>>;
  what?: InputMaybe<Scalars['String']>;
  when?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<Scalars['String']>;
  who?: InputMaybe<Scalars['String']>;
};

export type MutationCreatePostIndicatorArgs = {
  input: CreatePostIndicatorInput;
};

export type MutationCreatePostRatingArgs = {
  postId: Scalars['ID'];
  rating: Scalars['Int'];
};

export type MutationCreatePostRequestArgs = {
  postId: Scalars['ID'];
  report: Scalars['String'];
};

export type MutationCreateProblemCategoryArgs = {
  title: Scalars['String'];
};

export type MutationCreateProblemCircuitArgs = {
  title: Scalars['String'];
};

export type MutationCreateProcessCategoryArgs = {
  title: Scalars['String'];
};

export type MutationCreateProductArgs = {
  input: CreateProductInput;
};

export type MutationCreateProfessionalStatusArgs = {
  input?: InputMaybe<CreateProfessionalStatus>;
};

export type MutationCreateProfessionnalWeatherArgs = {
  input?: InputMaybe<CreateProfessionnalWeatherInput>;
};

export type MutationCreateProjectArgs = {
  communityId: Scalars['ID'];
  convertedFromId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  fromSelectedCommunityId?: InputMaybe<Scalars['ID']>;
  postId?: InputMaybe<Scalars['ID']>;
  priorityLevel?: InputMaybe<Scalars['Int']>;
  responsibleId?: InputMaybe<Scalars['ID']>;
  targetDate?: InputMaybe<Scalars['Date']>;
  title: Scalars['String'];
};

export type MutationCreateQuestionCategoryArgs = {
  title: Scalars['String'];
};

export type MutationCreateRoadBlockArgs = {
  companyMemberId: Scalars['ID'];
  description: Scalars['String'];
};

export type MutationCreateRootCauseCommentArgs = {
  content: Scalars['String'];
  rootCauseId: Scalars['ID'];
};

export type MutationCreateSemiInstantCallArgs = {
  input: CreateSemiInstantCallInput;
};

export type MutationCreateSkillArgs = {
  title: Scalars['String'];
  userId: Scalars['ID'];
};

export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

export type MutationCreateUserFromSignUpArgs = {
  email: Scalars['String'];
  firstname: Scalars['String'];
  hiringDate?: InputMaybe<Scalars['DateTime']>;
  lastname?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};

export type MutationCreateUserPreferenceArgs = {
  input: CreateUserPreferenceInput;
};

export type MutationCreateZoomGroupArgs = {
  input: CreateZoomGroupInput;
};

export type MutationCreateZoomMeetingArgs = {
  input: CreateZoomMeetingInput;
};

export type MutationCreateZoomUserArgs = {
  input: CreateZoomUserInput;
};

export type MutationDeleteActionArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAdviceCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAnalysisCompareArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAnalysisRootCauseArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAnalysisWhyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteApplicationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteBusinessUnitArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCalendarEventArgs = {
  fromEntryTunel?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type MutationDeleteCalendarFilterHistoryArgs = {
  calendarFilterHistoryId: Scalars['ID'];
};

export type MutationDeleteCommentArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCommunityArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCommunityEventTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCompanyMemberArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCompanyMemberOrderArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCompanyMemberProfessionnalWeatherArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCounterMeasureArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteCounterMeasureFileArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEventMessageSuggestionArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteEventTypeArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteFileArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteFollowUpArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteGenericExpEventArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteHelpMarkArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteIdeaCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteIndicatorArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteLeaveArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMeetingArgs = {
  uuid: Scalars['ID'];
};

export type MutationDeleteMissionArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteMottoArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteObjectiveArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteOneToOneArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePendingInvitationArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePostArgs = {
  id: Scalars['ID'];
};

export type MutationDeletePostRequestArgs = {
  postId: Scalars['ID'];
};

export type MutationDeleteProblemCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProblemCircuitArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProcessCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProductArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProfessionalStatusArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProfessionnalWeatherArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteProjectArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteQuestionCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRoadBlockArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteSkillArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteUserPresentationVideoArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationDeleteZoomMeetingArgs = {
  input: DeleteZoomMeetingInput;
};

export type MutationDeleteZoomMeetingRegistrantArgs = {
  input: DeleteZoomMeetingRegistrantInput;
};

export type MutationDeviceInfoManagementArgs = {
  info: DeviceInfoInput;
};

export type MutationEarnExpEventArgs = {
  input: EarnExpEventInput;
};

export type MutationEnableApplicationArgs = {
  id: Scalars['ID'];
  value: Scalars['Boolean'];
};

export type MutationEndZoomMeetingArgs = {
  input: EndZoomMeetingInput;
};

export type MutationFinalizePostArgs = {
  id: Scalars['ID'];
};

export type MutationFinalizeProjectArgs = {
  id: Scalars['ID'];
};

export type MutationForgotPasswordAskingArgs = {
  email: Scalars['String'];
};

export type MutationForgotPasswordConfirmArgs = {
  input: ForgotPasswordConfirmInput;
};

export type MutationGenerateStreamTokenArgs = {
  userId: Scalars['ID'];
};

export type MutationLikeCommentArgs = {
  commentId: Scalars['String'];
};

export type MutationMarkAsAlreadyUseA3ProcessArgs = {
  id: Scalars['ID'];
};

export type MutationRemoveCommunityInPendingInvitationArgs = {
  communityId: Scalars['ID'];
  id: Scalars['ID'];
};

export type MutationRemoveCompanyMemberToCommunityArgs = {
  userCommunityId: Scalars['ID'];
};

export type MutationRemoveMembersToBusinessUnitArgs = {
  id: Scalars['ID'];
  memberIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationReplyFeedbackArgs = {
  id: Scalars['ID'];
  response: Scalars['String'];
};

export type MutationReplyThanksArgs = {
  id: Scalars['ID'];
  response: Scalars['String'];
};

export type MutationResendInvitationArgs = {
  targetEmail: Scalars['String'];
  token: Scalars['String'];
};

export type MutationResetIncomingCallsCountArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationResetNewPostsCountByTypeArgs = {
  postType: PostType;
};

export type MutationSaveCurrentFiltersArgs = {
  filters?: InputMaybe<Array<InputMaybe<Filter>>>;
  postType: PostType;
};

export type MutationSeeNotificationArgs = {
  id: Scalars['ID'];
};

export type MutationSendFeedbackArgs = {
  companyMemberReceiverId: Scalars['ID'];
  content: Scalars['String'];
};

export type MutationSetActiveHoursTimeEndArgs = {
  time?: InputMaybe<Scalars['DateTime']>;
};

export type MutationSetActiveHoursTimeStartArgs = {
  time?: InputMaybe<Scalars['DateTime']>;
};

export type MutationSetCurrentImageArgs = {
  path?: InputMaybe<Scalars['String']>;
};

export type MutationSetDirectorArgs = {
  directorId: Scalars['String'];
};

export type MutationSetEnableActiveHoursValueArgs = {
  value: Scalars['Boolean'];
};

export type MutationSetEnableContextualNotificationValueArgs = {
  value: Scalars['Boolean'];
};

export type MutationSetEnableNavigateurNotificationValueArgs = {
  value: Scalars['Boolean'];
};

export type MutationSetEnableShowEntryTunnelValueArgs = {
  value: Scalars['Boolean'];
};

export type MutationSetEnableShowExitTunnelValueArgs = {
  value: Scalars['Boolean'];
};

export type MutationSetEntryTunnelTimeArgs = {
  time?: InputMaybe<Scalars['DateTime']>;
};

export type MutationSetExitTunnelTimeArgs = {
  time?: InputMaybe<Scalars['DateTime']>;
};

export type MutationSetLocaleArgs = {
  key: Scalars['String'];
};

export type MutationSetShowEntryTunnelPragmaTodayArgs = {
  incrementDay?: InputMaybe<Scalars['Boolean']>;
  value: Scalars['Boolean'];
};

export type MutationSetShowEntryTunnelTodayArgs = {
  value: Scalars['Boolean'];
};

export type MutationSetShowExitTunnelPragmaTodayArgs = {
  incrementDay?: InputMaybe<Scalars['Boolean']>;
  value: Scalars['Boolean'];
};

export type MutationSetShowExitTunnelTodayArgs = {
  value: Scalars['Boolean'];
};

export type MutationSharePostArgs = {
  communityIds: Array<Scalars['ID']>;
  postId: Scalars['ID'];
};

export type MutationSignUpInvitationArgs = {
  invitationToken: Scalars['String'];
};

export type MutationSigninArgs = {
  input: SigninInput;
};

export type MutationSignupArgs = {
  input: SignupInput;
};

export type MutationSuspendCompanyMemberArgs = {
  id: Scalars['ID'];
  suspended: Scalars['Boolean'];
};

export type MutationSwitchCompanyArgs = {
  newSlug: Scalars['String'];
  timezone: UserTimezoneInput;
};

export type MutationThanksSomeoneArgs = {
  companyMemberReceiverIds: Array<InputMaybe<Scalars['ID']>>;
  content: Scalars['String'];
  type?: InputMaybe<UserThankType>;
};

export type MutationToggle2FaArgs = {
  active: Scalars['Boolean'];
  id: Scalars['ID'];
  password: Scalars['String'];
};

export type MutationTwoFactorAuthenticationArgs = {
  code: Scalars['String'];
};

export type MutationUnlikeCommentArgs = {
  commentId: Scalars['String'];
};

export type MutationUpdateActionArgs = {
  actionType?: InputMaybe<ActionType>;
  communityId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  responsibleIds?: InputMaybe<Array<Scalars['ID']>>;
  targetDate?: InputMaybe<Scalars['Date']>;
  title?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateAnalysisCompareArgs = {
  id: Scalars['ID'];
  subject1?: InputMaybe<Scalars['String']>;
  subject2?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateAnalysisRootCauseArgs = {
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationUpdateAnalysisWhyArgs = {
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationUpdateApplicationArgs = {
  input: UpdateApplicationInput;
};

export type MutationUpdateBusinessUnitArgs = {
  input: UpdateBusinessUnitInput;
};

export type MutationUpdateCalendarCommunityEventArgs = {
  input: UpdateCalendarCommunityEventInput;
};

export type MutationUpdateCalendarFilterHistoryArgs = {
  calendarFilterHistoryId: Scalars['ID'];
  input: CalendarFilterHistoryInput;
  isOnlyTitle?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateCalendarLocationEventArgs = {
  input: UpdateCalendarLocationEventInput;
};

export type MutationUpdateCeoWordArgs = {
  ceoWord: Scalars['String'];
};

export type MutationUpdateCoachPreferenceArgs = {
  input: CoachInput;
};

export type MutationUpdateCommentArgs = {
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MutationUpdateCommunityArgs = {
  id: Scalars['ID'];
  logo?: InputMaybe<Scalars['String']>;
  mission?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  rommance?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateCommunityEventTypeArgs = {
  input: UpdateCommunityEventTypeInput;
};

export type MutationUpdateCommunityRoleInPendingInvitationArgs = {
  id: Scalars['ID'];
  value: CommunityRole;
};

export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};

export type MutationUpdateCompanyMemberArgs = {
  businessUnitId?: InputMaybe<Scalars['String']>;
  hiringDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  jobTitle?: InputMaybe<Scalars['String']>;
  nPlusOneId?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<MemberRole>;
  suspended?: InputMaybe<Scalars['Boolean']>;
  userAccess?: InputMaybe<MainRole>;
};

export type MutationUpdateCompanyMemberOrderArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateCompanyMemberProfessionnalWeatherArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateCounterMeasureArgs = {
  description?: InputMaybe<Scalars['String']>;
  fileIdToRemove?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['Int']>;
  fileType?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateCustomBadgeArgs = {
  input: UpdateCustomBadgeInput;
};

export type MutationUpdateDemandStatusArgs = {
  improveStatus?: InputMaybe<Demand_Improve_Status>;
  postId: Scalars['ID'];
  solveStatus?: InputMaybe<Demand_Solve_Status>;
};

export type MutationUpdateEmployeeOfDayRankArgs = {
  destination?: InputMaybe<Scalars['Int']>;
  employeeId: Scalars['ID'];
  fromCron?: InputMaybe<Scalars['Boolean']>;
};

export type MutationUpdateEmployeeOfDayStatusArgs = {
  employeeId: Scalars['ID'];
  force?: InputMaybe<Scalars['Boolean']>;
  status: EmployeeOfDayStatus;
};

export type MutationUpdateEventArgs = {
  day?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateEventMessageSuggestionArgs = {
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MutationUpdateEventTypeArgs = {
  input?: InputMaybe<UpdateEventTypeInput>;
};

export type MutationUpdateExpEventArgs = {
  input: UpdateExpEventInput;
};

export type MutationUpdateFollowUpArgs = {
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type MutationUpdateGenericExpEventArgs = {
  input: UpdateGenericExpEventInput;
};

export type MutationUpdateHelpMarkArgs = {
  input?: InputMaybe<UpdateHelpMarkInput>;
};

export type MutationUpdateIdeaCategoryArgs = {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateIndicatorArgs = {
  input: UpdateIndicatorInput;
};

export type MutationUpdateInvitationArgs = {
  communityMemberRole?: InputMaybe<CommunityRole>;
  companyMemberAccess?: InputMaybe<MainRole>;
  companyMemberRole?: InputMaybe<MemberRole>;
  hiringDate?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  pendingTeamsIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type MutationUpdateLeaderMessageArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
};

export type MutationUpdateLeaveArgs = {
  input: UpdateLeaveInput;
};

export type MutationUpdateMeetingArgs = {
  input: UpdatemeetingInput;
};

export type MutationUpdateMissionArgs = {
  deadline?: InputMaybe<Scalars['Date']>;
  estimateDate?: InputMaybe<Scalars['Date']>;
  expertIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  id: Scalars['ID'];
  priorityLevel?: InputMaybe<Scalars['Int']>;
  progressStatus?: InputMaybe<MissionProgressStatus>;
  title?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateMottoArgs = {
  content?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MutationUpdateMyTotalCoinsArgs = {
  cost?: InputMaybe<Scalars['Int']>;
};

export type MutationUpdateNeedArgs = {
  input: UpdateNeedInput;
};

export type MutationUpdateObjectiveArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MutationUpdateOneToOneArgs = {
  id: Scalars['ID'];
};

export type MutationUpdatePostArgs = {
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  companyMemberExpertIds?: InputMaybe<Array<Scalars['ID']>>;
  companyMemberResponsibleIds?: InputMaybe<Array<Scalars['ID']>>;
  content?: InputMaybe<Scalars['String']>;
  demand?: InputMaybe<Demand_Type>;
  fileIds?: InputMaybe<Array<Scalars['ID']>>;
  how?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  ideaCategoryId?: InputMaybe<Scalars['ID']>;
  impact?: InputMaybe<Scalars['String']>;
  issuePrivacy?: InputMaybe<IssuePrivacy>;
  postType?: InputMaybe<PostType>;
  postedAnonymously?: InputMaybe<Scalars['Boolean']>;
  presentationFileId?: InputMaybe<Scalars['ID']>;
  problemCategoryId?: InputMaybe<Scalars['ID']>;
  problemCircuitId?: InputMaybe<Scalars['ID']>;
  problemCommunitySourceId?: InputMaybe<Scalars['ID']>;
  problemFrom?: InputMaybe<Scalars['DateTime']>;
  problemLevel?: InputMaybe<Scalars['Int']>;
  problemTo?: InputMaybe<Scalars['DateTime']>;
  processStatus?: InputMaybe<Process_Status>;
  questionCategoryIid?: InputMaybe<Scalars['ID']>;
  ratingPeriod?: InputMaybe<Scalars['DateTime']>;
  recipientCommunityId?: InputMaybe<Scalars['ID']>;
  removedFilesIds?: InputMaybe<Array<Scalars['ID']>>;
  responsibleIds?: InputMaybe<Array<Scalars['ID']>>;
  sharedToCommunitiesIds?: InputMaybe<Array<Scalars['ID']>>;
  sharedToCompanyMembersIds?: InputMaybe<Array<Scalars['ID']>>;
  sharedToUsersIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<Post_Status>;
  title?: InputMaybe<Scalars['String']>;
  validatorIds?: InputMaybe<Array<Scalars['ID']>>;
  what?: InputMaybe<Scalars['String']>;
  when?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<Scalars['String']>;
  who?: InputMaybe<Scalars['String']>;
};

export type MutationUpdatePostPrivacyArgs = {
  issuePrivacy: IssuePrivacy;
  postId: Scalars['ID'];
};

export type MutationUpdatePostVisibilityArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateProblemCategoryArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateProblemCircuitArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};

export type MutationUpdateProfessionalStatusArgs = {
  input?: InputMaybe<UpdateProfessionalStatusInput>;
};

export type MutationUpdateProfessionnalWeatherArgs = {
  input?: InputMaybe<UpdateProfessionnalWeatherInput>;
};

export type MutationUpdateProfileArgs = {
  input: UpdateProfileInput;
};

export type MutationUpdateProjectArgs = {
  communityId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  priorityLevel?: InputMaybe<Scalars['Int']>;
  responsibleId?: InputMaybe<Scalars['ID']>;
  targetDate?: InputMaybe<Scalars['Date']>;
  title: Scalars['String'];
};

export type MutationUpdateProjectStatusArgs = {
  id: Scalars['ID'];
  status: ProjectStatus;
};

export type MutationUpdateRoadBlockArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type MutationUpdateSemiInstantCallArgs = {
  input: UpdateSemiInstantCallInput;
};

export type MutationUpdateSkillArgs = {
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateTeamMemberRoleArgs = {
  role: CommunityRole;
  userCommunityId: Scalars['ID'];
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationUpdateUserPictureArgs = {
  picturesIdToRemove?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  picturesToAdd?: InputMaybe<Array<InputMaybe<PictureInput>>>;
};

export type MutationUpdateUserPreferenceArgs = {
  input: UpdateUserPreferenceInput;
};

export type MutationUpdateZoomMeetingArgs = {
  input: UpdateZoomMeetingInput;
};

export type MutationUpdateZoomMeetingRegistrantStatusArgs = {
  input: UpdateZoomMeetingRegistrantStatusInput;
};

export type MutationUserInvitationArgs = {
  hiringDate?: InputMaybe<Scalars['DateTime']>;
  pendingTeamsIds?: InputMaybe<Array<Scalars['ID']>>;
  role?: InputMaybe<MemberRole>;
  targetEmail: Scalars['String'];
  userAccess?: InputMaybe<MainRole>;
};

export type MutationValidateLeaveArgs = {
  id: Scalars['ID'];
  status: LeaveStatus;
  validatorId?: InputMaybe<Scalars['ID']>;
};

export type MutationValidatedLeavesArgs = {
  companyMemberIds?: InputMaybe<Array<Scalars['ID']>>;
  ending: Scalars['DateTime'];
  leavesStatusToDownload?: InputMaybe<LeavesStatusToDownload>;
  reasonIds?: InputMaybe<Array<Scalars['ID']>>;
  start: Scalars['DateTime'];
};

export type MutationWishSomeoneArgs = {
  userId: Scalars['ID'];
  wish: Scalars['String'];
};

export type Need = {
  __typename?: 'Need';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  professionnalWeather?: Maybe<ProfessionnalWeather>;
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type NewPostSummary = {
  __typename?: 'NewPostSummary';
  advice?: Maybe<Scalars['Int']>;
  fun?: Maybe<Scalars['Int']>;
  idea?: Maybe<Scalars['Int']>;
  problem?: Maybe<Scalars['Int']>;
  process?: Maybe<Scalars['Int']>;
  /** @deprecated `question` has removed and changed by have `fun` type */
  question?: Maybe<Scalars['Int']>;
  story?: Maybe<Scalars['Int']>;
};

export type Notification = {
  __typename?: 'Notification';
  community?: Maybe<Community>;
  companyMemberSender?: Maybe<CompanyMember>;
  createdAt?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSeen?: Maybe<Scalars['Boolean']>;
  isValid?: Maybe<Scalars['Boolean']>;
  linkTo?: Maybe<Scalars['String']>;
  parametres?: Maybe<Array<Maybe<Scalars['String']>>>;
  removed?: Maybe<Scalars['Boolean']>;
  seenDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Objective = {
  __typename?: 'Objective';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ObjectiveFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

/** ######################### TYPE ONETOONE SERVICE #################### */
export type OneToOne = {
  __typename?: 'OneToOne';
  createdAt?: Maybe<Scalars['DateTime']>;
  guestCompanyMember?: Maybe<CompanyMember>;
  hostCompanyMember?: Maybe<CompanyMember>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OneToOneArchive = {
  __typename?: 'OneToOneArchive';
  createdAt?: Maybe<Scalars['DateTime']>;
  file?: Maybe<File>;
  id: Scalars['ID'];
  oneToOne?: Maybe<OneToOne>;
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type OneToOneFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export enum Post_A3_Status {
  InProgress = 'IN_PROGRESS',
  Resolve = 'RESOLVE',
  Solve = 'SOLVE',
  Test = 'TEST',
}

export enum Post_Sort_Type {
  Newest = 'NEWEST',
  Oldest = 'OLDEST',
  Popular = 'POPULAR',
}

export enum Post_Status {
  Blocked = 'BLOCKED',
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  Todo = 'TODO',
}

export enum Post_To_Show {
  All = 'ALL',
  Me = 'ME',
  Other = 'OTHER',
}

export enum Process_Status {
  Draft = 'DRAFT',
  Validated = 'VALIDATED',
}

export type Passion = {
  __typename?: 'Passion';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  users?: Maybe<Array<Maybe<User>>>;
};

export enum PeopleFilterField {
  AllFields = 'ALL_FIELDS',
  Biography = 'BIOGRAPHY',
  Birthday = 'BIRTHDAY',
  BusinessUnit = 'BUSINESS_UNIT',
  Email = 'EMAIL',
  Family = 'FAMILY',
  JobTitle = 'JOB_TITLE',
  Name = 'NAME',
  Passions = 'PASSIONS',
  Personality = 'PERSONALITY',
  PreviousCompanies = 'PREVIOUS_COMPANIES',
  Skills = 'SKILLS',
  Success = 'SUCCESS',
  Supervisor = 'SUPERVISOR',
  Teams = 'TEAMS',
}

export type PeopleInputOption = {
  __typename?: 'PeopleInputOption';
  businessUnit?: Maybe<BusinessUnit>;
  email?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  passion?: Maybe<Scalars['String']>;
  personality?: Maybe<UserPersonality>;
  previousCompany?: Maybe<Scalars['String']>;
  skill?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['String']>;
  supervisor?: Maybe<User>;
  team?: Maybe<Community>;
  type: PeopleFilterField;
  user?: Maybe<User>;
};

export type Post = {
  __typename?: 'Post';
  a3Status?: Maybe<Post_A3_Status>;
  actions?: Maybe<Array<Maybe<Action>>>;
  adviceCategory?: Maybe<AdviceCategory>;
  analisisRootCauses?: Maybe<Array<Maybe<AnalysisRootCause>>>;
  analisisWhys?: Maybe<Array<Maybe<AnalysisWhy>>>;
  analysisCompare?: Maybe<Array<Maybe<AnalysisCompare>>>;
  cmCreatedBy?: Maybe<CompanyMember>;
  cmCreatedById?: Maybe<Scalars['ID']>;
  commentCount?: Maybe<Scalars['Int']>;
  comments?: Maybe<Array<Comment>>;
  /** communities" field refers to "Team Concerned" (for: issue, ) */
  communities?: Maybe<Array<Community>>;
  companyMemberExperts?: Maybe<Array<CompanyMember>>;
  companyMemberResponsibles?: Maybe<Array<CompanyMember>>;
  content?: Maybe<Scalars['String']>;
  contentImageUrls?: Maybe<Array<Scalars['String']>>;
  counterMeasures?: Maybe<Array<Maybe<CounterMeasure>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** @deprecated Use `cmCreatedBy` */
  createdBy?: Maybe<User>;
  /** @deprecated Use `cmCreatedById` */
  createdById?: Maybe<Scalars['ID']>;
  demand?: Maybe<Demand_Type>;
  demandImproveStatus?: Maybe<Demand_Improve_Status>;
  demandSolveStatus?: Maybe<Demand_Solve_Status>;
  /** @deprecated draft is deprecated. Use processStatus instead. */
  draft?: Maybe<Scalars['Boolean']>;
  /** @deprecated Use `comapnyMemberExperts` instead */
  experts?: Maybe<Array<Maybe<User>>>;
  fileCount?: Maybe<Scalars['Int']>;
  files?: Maybe<Array<File>>;
  finalized?: Maybe<Scalars['Boolean']>;
  followUps?: Maybe<Array<Maybe<FollowUp>>>;
  goals?: Maybe<Array<Indicator>>;
  how?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  ideaCategory?: Maybe<IdeaCategory>;
  ideaToActionConversion?: Maybe<Array<Action>>;
  imageFiles?: Maybe<Array<File>>;
  impact?: Maybe<Scalars['String']>;
  isAlreadyUseA3Process?: Maybe<Scalars['Boolean']>;
  isAuthorHidden?: Maybe<Scalars['Boolean']>;
  isCMValidator?: Maybe<Scalars['Boolean']>;
  isChargedByMe?: Maybe<Scalars['Boolean']>;
  isHidden?: Maybe<Scalars['Boolean']>;
  isIssueSolved?: Maybe<Scalars['Boolean']>;
  isLikedByMe?: Maybe<Scalars['Boolean']>;
  isMeAMemberOfProblemCommunitySource?: Maybe<Scalars['Boolean']>;
  isMine?: Maybe<Scalars['Boolean']>;
  isRatingEnded?: Maybe<Scalars['Boolean']>;
  isReported?: Maybe<Scalars['Boolean']>;
  isSeenByMe?: Maybe<Scalars['Boolean']>;
  issuePrivacy?: Maybe<IssuePrivacy>;
  likeCount?: Maybe<Scalars['Int']>;
  likedBy?: Maybe<Array<User>>;
  myRating?: Maybe<PostRating>;
  objectives?: Maybe<Array<Maybe<Objective>>>;
  postRequests?: Maybe<Array<PostRequest>>;
  postType?: Maybe<PostType>;
  postedAnonymously?: Maybe<Scalars['Boolean']>;
  presentationFile?: Maybe<File>;
  privateCommentCount?: Maybe<Scalars['Int']>;
  privateComments?: Maybe<Array<Comment>>;
  problemCategory?: Maybe<ProblemCategory>;
  problemCircuit?: Maybe<ProblemCircuit>;
  /** problemCommunitySource" field refers to "Team in charge" (for: issue, ) */
  problemCommunitySource?: Maybe<Community>;
  problemFrom?: Maybe<Scalars['DateTime']>;
  problemLevel?: Maybe<Scalars['Int']>;
  problemTo?: Maybe<Scalars['DateTime']>;
  processStatus?: Maybe<Process_Status>;
  processValidators?: Maybe<Array<CompanyMember>>;
  projectConversion?: Maybe<Array<Project>>;
  publicCommentCount?: Maybe<Scalars['Int']>;
  publicComments?: Maybe<Array<Comment>>;
  questionCategory?: Maybe<QuestionCategory>;
  ratingPeriod?: Maybe<Scalars['DateTime']>;
  ratingSummary?: Maybe<PostRatingSummary>;
  recipientCommunity?: Maybe<Community>;
  removed?: Maybe<Scalars['Boolean']>;
  reopened?: Maybe<Scalars['Boolean']>;
  resolvedProblemCount?: Maybe<Scalars['Int']>;
  /** @deprecated Use `companyMemberResponsibles` instead */
  responsibles?: Maybe<Array<Maybe<User>>>;
  shareCount?: Maybe<Scalars['Int']>;
  sharedCompanyMemberPosts?: Maybe<Array<CompanyMemberPostShare>>;
  sharedToCommunities?: Maybe<Array<PostSharedToCommunity>>;
  sharedToCompanyMembers?: Maybe<Array<PostSharedToCompanyMember>>;
  sharedToSpecificCommunities?: Maybe<Array<Community>>;
  sharedToSpecificCompanyMembers?: Maybe<Array<CompanyMember>>;
  /** @deprecated Use `sharedToSpecificCompanyMembers` */
  sharedToSpecificUsers?: Maybe<Array<User>>;
  /** @deprecated Use `sharedToCompanyMembers` */
  sharedToUsers?: Maybe<Array<PostSharedToUser>>;
  status?: Maybe<Post_Status>;
  subActions?: Maybe<Array<Action>>;
  title?: Maybe<Scalars['String']>;
  unresolvedProblemCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  what?: Maybe<Scalars['String']>;
  when?: Maybe<Scalars['String']>;
  where?: Maybe<Scalars['String']>;
  who?: Maybe<Scalars['String']>;
};

export type PostCount = {
  __typename?: 'PostCount';
  global?: Maybe<Scalars['Int']>;
  perTeam?: Maybe<Array<Maybe<PostCountByTeam>>>;
};

export type PostCountByTeam = {
  __typename?: 'PostCountByTeam';
  count?: Maybe<Scalars['Int']>;
  team?: Maybe<Community>;
};

export type PostFileInput = {
  name?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  /** file input */
  type?: InputMaybe<Scalars['String']>;
};

export type PostFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export type PostFilterByInput = {
  communityId?: InputMaybe<Scalars['ID']>;
  demand?: InputMaybe<Demand_Type>;
  demandImproveStatus?: InputMaybe<Demand_Improve_Status>;
  demandSolveStatus?: InputMaybe<Demand_Solve_Status>;
  finalized?: InputMaybe<Scalars['Boolean']>;
  isMine?: InputMaybe<Scalars['Boolean']>;
  problemCategoryId?: InputMaybe<Scalars['ID']>;
  problemCircuitId?: InputMaybe<Scalars['ID']>;
  problemLevel?: InputMaybe<Scalars['Int']>;
  removed?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<Post_Status>;
};

export type PostFilterHistory = {
  __typename?: 'PostFilterHistory';
  dateRange?: Maybe<DateRangeType>;
  id: Scalars['ID'];
  isCustomFilter?: Maybe<Scalars['Boolean']>;
  isOneToMany?: Maybe<Scalars['Boolean']>;
  postField: Scalars['String'];
  selectedChip?: Maybe<Scalars['String']>;
  selectedChips: Array<Scalars['String']>;
  selectedDynamicMultiselectItems: Array<Scalars['String']>;
  sliderValue?: Maybe<Array<Scalars['Int']>>;
};

export type PostList = {
  __typename?: 'PostList';
  posts?: Maybe<Array<Post>>;
  total?: Maybe<Scalars['Int']>;
};

export type PostRating = {
  __typename?: 'PostRating';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  post: Post;
  postId: Scalars['ID'];
  rating: Scalars['Int'];
  user: User;
  userId: Scalars['ID'];
};

export type PostRatingSummary = {
  __typename?: 'PostRatingSummary';
  five?: Maybe<Scalars['Int']>;
  four?: Maybe<Scalars['Int']>;
  globalRating?: Maybe<Scalars['Float']>;
  one?: Maybe<Scalars['Int']>;
  three?: Maybe<Scalars['Int']>;
  totalRatingCount?: Maybe<Scalars['Int']>;
  two?: Maybe<Scalars['Int']>;
};

export type PostRequest = {
  __typename?: 'PostRequest';
  companyMember?: Maybe<CompanyMember>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  report?: Maybe<Scalars['String']>;
};

export type PostSharedToCommunity = {
  __typename?: 'PostSharedToCommunity';
  community?: Maybe<Community>;
  communityId: Scalars['ID'];
  postId: Scalars['ID'];
};

export type PostSharedToCompanyMember = {
  __typename?: 'PostSharedToCompanyMember';
  companyMemberId: Scalars['ID'];
  postId: Scalars['ID'];
  sharedBy: Scalars['ID'];
};

export type PostSharedToUser = {
  __typename?: 'PostSharedToUser';
  postId: Scalars['ID'];
  userId: Scalars['ID'];
};

export enum PostType {
  Advice = 'ADVICE',
  Fun = 'FUN',
  Idea = 'IDEA',
  Problem = 'PROBLEM',
  Process = 'PROCESS',
  Question = 'QUESTION',
  Story = 'STORY',
}

export type PreviousCompany = {
  __typename?: 'PreviousCompany';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  removed?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type ProblemCategory = {
  __typename?: 'ProblemCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProblemCategoryFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export type ProblemCircuit = {
  __typename?: 'ProblemCircuit';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProblemCircuitFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export type ProblemsCountPayload = {
  __typename?: 'ProblemsCountPayload';
  resolvedProblemCount: Scalars['Int'];
  unresolvedProblemCount: Scalars['Int'];
};

export type ProcessCategory = {
  __typename?: 'ProcessCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Product = {
  __typename?: 'Product';
  company?: Maybe<Company>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  price?: Maybe<Scalars['Int']>;
  removed?: Maybe<Scalars['Boolean']>;
  thumbnail?: Maybe<File>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProductFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type ProfessionalStatus = {
  __typename?: 'ProfessionalStatus';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProfessionalStatusFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export type ProfessionnalWeather = {
  __typename?: 'ProfessionnalWeather';
  companyMemberProfessionalWeathers?: Maybe<Array<Maybe<CompanyMemberProfessionnalWeather>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProfessionnalWeatherFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type Project = {
  __typename?: 'Project';
  actions?: Maybe<Array<Maybe<Action>>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  community?: Maybe<Community>;
  companyMemberResponsible?: Maybe<CompanyMember>;
  convertedFrom?: Maybe<Post>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdByCompanyMember?: Maybe<CompanyMember>;
  description?: Maybe<Scalars['String']>;
  finalized?: Maybe<Scalars['Boolean']>;
  fromSelectedCommunity?: Maybe<Community>;
  id: Scalars['ID'];
  isCompleted?: Maybe<Scalars['Boolean']>;
  priorityLevel?: Maybe<Scalars['Int']>;
  status?: Maybe<ProjectStatus>;
  targetDate?: Maybe<Scalars['Date']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ProjectFilterByInput = {
  finalized?: InputMaybe<Scalars['Boolean']>;
  teamId?: InputMaybe<Scalars['ID']>;
};

export enum ProjectStatus {
  Done = 'DONE',
  InProgress = 'IN_PROGRESS',
  ToDo = 'TO_DO',
}

export type Query = {
  __typename?: 'Query';
  AnalysisCompare?: Maybe<AnalysisCompare>;
  AnalysisRootCause?: Maybe<AnalysisRootCause>;
  AnalysisWhy?: Maybe<AnalysisWhy>;
  Application: Application;
  ApplicationCategory: ApplicationCategory;
  Comment?: Maybe<Comment>;
  Community: Community;
  CommunityEventType?: Maybe<CommunityEventType>;
  CommunityEventTypeByName?: Maybe<CommunityEventType>;
  Company?: Maybe<Company>;
  CompanyMemberOrder?: Maybe<CompanyMemberOrder>;
  CompanyMemberProfessionnalWeather: CompanyMemberProfessionnalWeather;
  CounterMeasure?: Maybe<CounterMeasure>;
  Event: Event;
  EventMessageSuggestion: EventMessageSuggestion;
  EventType: EventType;
  ExpEvent?: Maybe<ExpEvent>;
  Feedback: Feedback;
  File?: Maybe<File>;
  FollowUp?: Maybe<FollowUp>;
  GenericExpEvent?: Maybe<GenericExpEvent>;
  HelpMark?: Maybe<HelpMark>;
  IdeaCategories: Array<IdeaCategory>;
  IdeaCategory?: Maybe<IdeaCategory>;
  Mission: Mission;
  Motto: Motto;
  Notification: Notification;
  Objective?: Maybe<Objective>;
  OneToOne: OneToOne;
  /** @deprecated This query doesn't handle company, use 'getPostById' instead */
  Post?: Maybe<Post>;
  ProblemCategory?: Maybe<ProblemCategory>;
  ProblemCircuit?: Maybe<ProblemCircuit>;
  Product?: Maybe<Product>;
  ProfessionalStatus: ProfessionalStatus;
  ProfessionnalWeather: ProfessionnalWeather;
  RoadBlock: RoadBlock;
  SemiInstantCall?: Maybe<SemiInstantCall>;
  Skill: Skill;
  User?: Maybe<User>;
  _actionById: Action;
  _actionInComment: Action;
  _actionInCompany: Action;
  _actionInIndicator?: Maybe<Action>;
  _actionInPost?: Maybe<Action>;
  _allActionsMeta: ListMetadata;
  _allAnalysisCompareMeta?: Maybe<ListMetadata>;
  _allAnalysisRootCausesMeta?: Maybe<ListMetadata>;
  _allAnalysisWhysMeta?: Maybe<ListMetadata>;
  _allApplicationsMeta: ListMetadata;
  _allBusinessUnitsMeta?: Maybe<ListMetadata>;
  _allCoinHistoriesMeta: CoinListMetadata;
  _allCommentsMeta?: Maybe<ListMetadata>;
  _allCommunitiesMeta?: Maybe<ListMetadata>;
  _allCommunityEventsTypeMeta?: Maybe<ListMetadata>;
  _allCompaniesMeta: ListMetadata;
  _allCompanyMemberOrdersMeta?: Maybe<ListMetadata>;
  _allCompanyMemberProfessionnalWeathersMeta?: Maybe<ListMetadata>;
  _allCompanyMembersMeta: ListMetadata;
  _allCounterMeasuresMeta?: Maybe<ListMetadata>;
  _allEventMessageSuggestionsMeta?: Maybe<ListMetadata>;
  _allEventTypesMeta?: Maybe<ListMetadata>;
  _allEventsMeta?: Maybe<ListMetadata>;
  _allExpEventsMeta: ListMetadata;
  _allFollowUpsMeta?: Maybe<ListMetadata>;
  _allGenericExpEventsMeta: ListMetadata;
  _allHelpMarksMeta?: Maybe<ListMetadata>;
  _allIdeaCategoriesMeta?: Maybe<ListMetadata>;
  _allIndicatorsMeta: ListMetadata;
  _allMeetingsMeta?: Maybe<ListMetadata>;
  _allMottosMeta?: Maybe<ListMetadata>;
  _allObjectivesMeta?: Maybe<ListMetadata>;
  _allOneToOnesMeta?: Maybe<ListMetadata>;
  _allPostsMeta?: Maybe<ListMetadata>;
  _allProblemCategorysMeta?: Maybe<ListMetadata>;
  _allProblemCircuitsMeta?: Maybe<ListMetadata>;
  _allProductsMeta?: Maybe<ListMetadata>;
  _allProfessionalStatusesMeta?: Maybe<ListMetadata>;
  _allProfessionnalWeathersMeta?: Maybe<ListMetadata>;
  _allProjectsMeta: ListMetadata;
  _allSemiInstantCallsMeta: ListMetadata;
  _allSkillsMeta?: Maybe<ListMetadata>;
  _allUsersMeta?: Maybe<ListMetadata>;
  _analysisCompareInComment?: Maybe<AnalysisCompare>;
  _analysisRootCauseInComment?: Maybe<AnalysisRootCause>;
  _analysisWhyInAnalysis5Why?: Maybe<AnalysisWhy>;
  _applicationCategoryInCompany?: Maybe<ApplicationCategory>;
  _applicationInCompany: Application;
  _applicationInFile?: Maybe<Application>;
  _businessUnitInCompany: BusinessUnit;
  _businessUnitInFile: BusinessUnit;
  _businessUnitInIndicator?: Maybe<BusinessUnit>;
  _calendarCommunityEventsPerDayInCommunity: CalendarCommunityEventsPerDay;
  _calendarCommunityEventsPerDayInCompany: CalendarCommunityEventsPerDay;
  _calendarEventCommunitiesInCommunity?: Maybe<CalendarEventCommunities>;
  _calendarEventInComment: CalendarEvent;
  _calendarEventInCommunity?: Maybe<CalendarEvent>;
  _calendarEventInCompany: CalendarEvent;
  _calendarEventOwnersInCompany?: Maybe<CalendarEventOwners>;
  _calendarFilterHistoriesMeta: ListMetadata;
  _calendarFilterHistoryInCompany: CalendarFilterHistory;
  _calendarLocationEventsPerDayInCompany: CalendarLocationEventsPerDay;
  _canICreateLeaveForSomeoneMeta: CanICreateLeaveForSomeoneMeta;
  _commentInCompany: Comment;
  _commentInUser?: Maybe<Comment>;
  _communityBadgeInCommunity?: Maybe<CommunityBadge>;
  _communityInBadge: Community;
  _communityInCompany: Community;
  _communityInFile: Community;
  _communityInIndicator?: Maybe<Community>;
  _communityInOneToOne: Community;
  _companyInBusinessUnit: Company;
  _companyInCoin?: Maybe<Company>;
  _companyInCommunity: Company;
  _companyInFile: Company;
  _companyInIndicator?: Maybe<Company>;
  _companyInOneToOne: Company;
  _companyInPost?: Maybe<Company>;
  _companyInSocial: Company;
  _companyMember: CompanyMember;
  _companyMemberInBadge: CompanyMember;
  _companyMemberInBusinessUnit: CompanyMember;
  _companyMemberInLeave: CompanyMember;
  _companyMemberInSocial: CompanyMember;
  _companyMemberInUser?: Maybe<CompanyMember>;
  _companyMemberOrderInCompany: CompanyMemberOrder;
  _companyMemberOrderInProduct?: Maybe<CompanyMemberOrder>;
  _counterMeasureInFile: CounterMeasure;
  _earnedExpEventInCommunity?: Maybe<EarnedExpEvent>;
  _earnedExpEventInCompanyMember?: Maybe<EarnedExpEvent>;
  _eventInCompany: Event;
  _eventsByCalendarFilterHistoryMeta: EventListMetaData;
  _feedbackInCompany: Feedback;
  _getNMinusOnesMeta: ListMetadata;
  _helpMarkInFile: HelpMark;
  _indicatorInComment?: Maybe<Indicator>;
  _indicatorInCommunity?: Maybe<Indicator>;
  _leaveInComment: Leave;
  _leaveInCompany: Leave;
  _leaveInFile: Leave;
  _leavesByCalendarFilterHistoryMeta: LeaveMetadata;
  _meetingInComment: Meeting;
  _meetingInCompany: Meeting;
  _meetingRegistrantInCompany: MeetingRegistrant;
  _meetingsByCalendarFilterHistoryMeta: ListMetadata;
  _missionInCompany: Mission;
  _notificationInCommunity: Notification;
  _notificationInCompany: Notification;
  _peoplesMeta: ListMetadata;
  /** Merge Post in A3 service */
  _postInA3?: Maybe<Post>;
  _postInComment: Post;
  _postInCommunity: Post;
  _postInCompany: Post;
  _postInIndicator?: Maybe<Post>;
  /** Merges in user service */
  _postInUser?: Maybe<Post>;
  _postRequestInCompany: PostRequest;
  _productInCompany: Product;
  _productInFile: Product;
  _projectInComment?: Maybe<Project>;
  _projectInCompany: Project;
  _seenNotificationsMeta: ListMetadata;
  _semiInstantCallInCommunity?: Maybe<SemiInstantCall>;
  _semiInstantCallInCompany?: Maybe<SemiInstantCall>;
  _unSeenNotificationsMeta: ListMetadata;
  /** Merge external service */
  _user: User;
  _userById: User;
  _userCommunityInCompany: UserCommunity;
  _userInCommunity: User;
  _userInFile: User;
  _userThankInCompany: UserThank;
  actionById: Action;
  adviceCategories: Array<AdviceCategory>;
  allActions: Array<Action>;
  allAnalysisCompare?: Maybe<Array<Maybe<AnalysisCompare>>>;
  allAnalysisCompareByPost?: Maybe<Array<Maybe<AnalysisCompare>>>;
  allAnalysisRootCauses?: Maybe<Array<Maybe<AnalysisRootCause>>>;
  allAnalysisRootCausesByPost?: Maybe<Array<Maybe<AnalysisRootCause>>>;
  allAnalysisWhys?: Maybe<Array<Maybe<AnalysisWhy>>>;
  allAnalysisWhysByPost?: Maybe<Array<Maybe<AnalysisWhy>>>;
  allApplicationCategories: Array<ApplicationCategory>;
  allApplications: Array<Application>;
  allBusinessUnits?: Maybe<Array<Maybe<BusinessUnit>>>;
  allCoinHistories: Array<CoinHistory>;
  allComments?: Maybe<Array<Maybe<Comment>>>;
  allCommunities?: Maybe<Array<Community>>;
  allCommunityEventsType: Array<CommunityEventType>;
  allCompanies: Array<Company>;
  allCompanyMemberOrders?: Maybe<Array<Maybe<CompanyMemberOrder>>>;
  allCompanyMemberProfessionnalWeathers?: Maybe<Array<CompanyMemberProfessionnalWeather>>;
  allCompanyMembers?: Maybe<Array<CompanyMember>>;
  allCounterMeasures?: Maybe<Array<Maybe<CounterMeasure>>>;
  allCustomBadges: Array<CustomBadge>;
  allEventMessageSuggestions?: Maybe<Array<EventMessageSuggestion>>;
  allEventTypes?: Maybe<Array<EventType>>;
  allEvents?: Maybe<Array<Event>>;
  /** Instance exp events queries */
  allExpEvents: Array<ExpEvent>;
  allFollowUps?: Maybe<Array<Maybe<FollowUp>>>;
  allFollowUpsByPost?: Maybe<Array<Maybe<FollowUp>>>;
  /** Generic exp events queries */
  allGenericExpEvents: Array<GenericExpEvent>;
  allHelpMarks: Array<HelpMark>;
  allIdeaCategories?: Maybe<Array<IdeaCategory>>;
  allIndicators: Array<Indicator>;
  allLeaves: Array<Leave>;
  allMeetings?: Maybe<Array<Maybe<Meeting>>>;
  allMottos?: Maybe<Array<Motto>>;
  allObjectives?: Maybe<Array<Maybe<Objective>>>;
  allOneToOnes?: Maybe<Array<OneToOne>>;
  allPosts?: Maybe<Array<Post>>;
  allProblemCategorys: Array<ProblemCategory>;
  allProblemCircuits?: Maybe<Array<ProblemCircuit>>;
  allProducts?: Maybe<Array<Maybe<Product>>>;
  allProfessionalStatuses: Array<ProfessionalStatus>;
  allProfessionnalWeathers: Array<ProfessionnalWeather>;
  allSemiInstantCalls: Array<SemiInstantCall>;
  allSkills?: Maybe<Array<Skill>>;
  allUsers?: Maybe<Array<User>>;
  badgesByCommunity?: Maybe<Array<CommunityBadge>>;
  calendarCommunityEventDetails: Array<CalendarEvent>;
  calendarCommunityEvents: Array<CalendarEvent>;
  calendarEventById?: Maybe<CalendarEvent>;
  calendarEventsPerDay: CalendarEventPerDay;
  calendarFilterHistories: Array<CalendarFilterHistory>;
  calendarFilterHistoryById?: Maybe<CalendarFilterHistory>;
  calendarLocationEventsCompanyMemberOwnersPerDay: Array<CompanyMember>;
  checkAdmin: CheckAdminPayload;
  checkClient: CheckClientPayload;
  checkFilteredCompanyMembersInputOptionToRemove: Array<Scalars['String']>;
  coach?: Maybe<Coach>;
  coachGetCompanyMemberProfessionalWeathersByCm?: Maybe<Array<CompanyMemberProfessionnalWeather>>;
  coachPostCreatedByCmId?: Maybe<Array<Post>>;
  communityExpLevelUpDialog: CommunityExpLevelUpDialog;
  companyMemberInCalendar?: Maybe<CompanyMember>;
  companyProblemCategories: Array<ProblemCategory>;
  congratsDialog?: Maybe<CongratsDialog>;
  countIssueRaisedByCompany: PostCount;
  countProccessesByCompany: PostCount;
  coworkers?: Maybe<Array<Maybe<User>>>;
  coworkersGrouped: Array<CoworkersGrouped>;
  coworkersInputOptions: Array<CompanyMember>;
  coworkersMember: Array<CompanyMember>;
  currentCommunity: Community;
  currentCompany: Company;
  currentCompanyAdminAdvices: PostList;
  currentCompanyAdminIdeas: PostList;
  currentCompanyAdminProblems: PostList;
  currentCompanyAdminProcess: PostList;
  currentCompanyAdminQuestions: PostList;
  currentCompanyAdminStories: PostList;
  currentCompanyAdvice: Array<Post>;
  currentCompanyFuns: Array<Post>;
  currentCompanyIdeas: Array<Post>;
  currentCompanyLastLeaderMessage?: Maybe<LeaderMessage>;
  currentCompanyMember: CompanyMember;
  currentCompanyPostNumberByType?: Maybe<Scalars['Int']>;
  currentCompanyProblems: Array<Post>;
  currentCompanyProcess: Array<Post>;
  currentCompanyQuestions?: Maybe<Array<Post>>;
  currentCompanyStories: Array<Post>;
  currentPostFilters: Array<Maybe<PostFilterHistory>>;
  currentWholeCompany: Community;
  /** Amount of exp earned per day */
  dailyExp: Array<DailyExp>;
  earnedExpEvents: Array<EarnedExpEvent>;
  eventsByCalendarFilterHistory: Array<CalendarEvent>;
  expLevelUpDialog: ExpLevelUpDialog;
  exportLeaves: Array<LeavesGroupedByDay>;
  filteredCompanyMembersInputOption: Array<CompanyMember>;
  getActionsByPostId?: Maybe<Array<Action>>;
  getAllFiles: Array<File>;
  getAllLanguages?: Maybe<Array<Language>>;
  getAllTeamMembersSuggestion?: Maybe<Array<CompanyTeamMembersSuggestion>>;
  getBadgesByCategory: BadgesByCategory;
  getBadgesByCompanyMember?: Maybe<Array<IndividualBadge>>;
  getBusinessUnitById?: Maybe<BusinessUnit>;
  getCeoWord?: Maybe<Scalars['String']>;
  getCommunitiesByCompany: Array<Community>;
  getCommunitiesByCompanyMember: CommunitiesByCompanyMember;
  getCommunityByCompanyId?: Maybe<Array<Community>>;
  getCommunityIndicators: Array<Indicator>;
  getCommunityMembersRoleBySelectedCommunity: CommunityRole;
  getCommunityPostById?: Maybe<Post>;
  getCommunityProblemsById?: Maybe<Community>;
  getCompanyBusinessUnits: Array<BusinessUnit>;
  getCompanyDirector?: Maybe<CompanyMember>;
  getCompanyImages: Array<File>;
  getCompanyIndicators: Array<Indicator>;
  getCompanyMemberByEmail: CompanyMember;
  getCompanyMemberById?: Maybe<CompanyMember>;
  getCompanyMemberByIds?: Maybe<Array<Maybe<CompanyMember>>>;
  getCompanyMemberDirectors?: Maybe<Array<CompanyMember>>;
  getCompanyMemberInCommunity: CompanyMember;
  getCompanyMemberInPost?: Maybe<CompanyMember>;
  getCompanyMemberPostShareInCommunity: CompanyMemberPostShare;
  getCompanyMemberPostShareInCompany: CompanyMemberPostShare;
  getCounterMeasuresByPost?: Maybe<Array<Maybe<CounterMeasure>>>;
  getCurrentCompanyProducts?: Maybe<Array<Maybe<Product>>>;
  getCurrentWelcomeScreen: Scalars['String'];
  getDailyDonesBadgesByCategory: BadgesByCategory;
  getDailyDonesCommunityBadges?: Maybe<Array<CommunityBadge>>;
  /** Employee of day queries */
  getEmployeeOfTheDay?: Maybe<EmployeeOfDay>;
  getEventsToday?: Maybe<Array<Event>>;
  getHelpMarkByType: HelpMark;
  getIndicatorById?: Maybe<Indicator>;
  getIndicatorHistoriesByIndicatorId: Array<IndicatorHistory>;
  getIndicatorHistoryById?: Maybe<IndicatorHistory>;
  getIndicatorSwitchItems?: Maybe<Company>;
  getLeadermessageByCompanyMemberId: LeaderMessage;
  getMessageSuggestions?: Maybe<Array<MessageSuggestion>>;
  getMyCommunitiesIndicators: Array<Indicator>;
  getMyCurrentNotificationPreference?: Maybe<UserPreference>;
  getMyCurrentTimezone?: Maybe<UserTimezone>;
  getMyLocale: Language;
  getMyOneToOneArchive?: Maybe<Array<OneToOneArchive>>;
  getMyPreferences?: Maybe<UserPreference>;
  getMyThanksReceivedToday: Array<UserThank>;
  getNMinusOnes: Array<CompanyMember>;
  getNeedById: Need;
  getNeedUserById: User;
  getNeedsByCompanyMemberId?: Maybe<Array<Need>>;
  getOneToOneArchiveById: OneToOneArchive;
  getOneToOneArchiveFileById: OneToOneArchive;
  getOneToOneFileById: File;
  getPassword: Scalars['String'];
  getPendingInvitation?: Maybe<UserCompanyInvitation>;
  getPendingInvitationsByCompany: Array<UserCompanyInvitation>;
  getPendingInvitationsByTeamId?: Maybe<Array<UserCompanyInvitation>>;
  getPostById: Post;
  getPostFilesById: Post;
  getProblemsCountBetweenCommunity: ProblemsCountPayload;
  getProfessionnalWeatherNeedById: Need;
  getProject: Project;
  getProjectsByCommunity: Array<Project>;
  getRankedCompanyMembers: Array<CompanyMember>;
  getRoadBlockInCommunity: RoadBlock;
  getTunnelsActivationValue: TunnelsActivationValue;
  getUserCompanyById: User;
  getUserManagementDataCount: Scalars['Int'];
  getUserPostsById?: Maybe<User>;
  isAlreadySignedUp: Scalars['Boolean'];
  isCompanyMemberALeaveValidator: Scalars['Boolean'];
  isCurrentCompanyMemberInChargeOf?: Maybe<Scalars['Boolean']>;
  isFiltersApplied?: Maybe<Scalars['Boolean']>;
  leave?: Maybe<Leave>;
  leaveReasonsByCompany: Array<LeaveReason>;
  leavesByCalendarFilterHistory: Array<Leave>;
  leavesByDate: LeavesByDate;
  localFileSignedUrl: S3UrlInfo;
  locationEventsDetailPerDay: Array<CalendarEvent>;
  locationInputOptions: Array<Location>;
  /** --------Authentification--------# */
  me?: Maybe<User>;
  meetingById?: Maybe<Meeting>;
  meetingsByCalendarFilterHistory: Array<Meeting>;
  myCompanies: Array<Company>;
  myLocationEventByDay?: Maybe<CalendarEvent>;
  myMeetings: Array<Meeting>;
  myZoomMeetings?: Maybe<Array<Maybe<ZoomMeetingByRegistrantFullResponse>>>;
  newArrivalCoworkers: Array<CompanyMember>;
  newPostSummary?: Maybe<NewPostSummary>;
  newProblemCount?: Maybe<Scalars['Int']>;
  people: Array<CompanyMember>;
  peopleInputOptions: Array<PeopleInputOption>;
  peoples: Array<CompanyMember>;
  ping?: Maybe<Scalars['String']>;
  problemCategories: Array<ProblemCategory>;
  questionCategories: Array<QuestionCategory>;
  refreshAccessToken?: Maybe<ZoomRefreshTokenResponse>;
  requestedPostIds?: Maybe<Array<Scalars['String']>>;
  seeFeedback: Feedback;
  seeThanks: UserThank;
  seenNotifications: Array<Notification>;
  snackBar?: Maybe<SnackBar>;
  /** Amount of exp earned for today */
  todaysEarnedExp: TodaysEarnedExp;
  unSeenNotifications: Array<Notification>;
  viewPost?: Maybe<Post>;
  zoomMeetingByMeeetingId?: Maybe<CreateZoomMeetingResponse>;
  zoomMeetingFullByMeetingId?: Maybe<ZoomMeetingByRegistrantFullResponse>;
  zoomMeetingRegistrantByRegistrantId?: Maybe<ZoomMeetingRegistrantByRegistrantIdResponse>;
  zoomMeetingRegistrantsByMeeetingId?: Maybe<ZoomMeetingRegistrantResponse>;
  /** ----------- Zoom API queries ---------------- */
  zoomMeetingsByZoomUser?: Maybe<ZoomMeetingsResponse>;
  zoomUserById?: Maybe<CreateZoomUserResponse>;
};

export type QueryAnalysisCompareArgs = {
  id: Scalars['ID'];
};

export type QueryAnalysisRootCauseArgs = {
  id: Scalars['ID'];
};

export type QueryAnalysisWhyArgs = {
  id: Scalars['ID'];
};

export type QueryApplicationArgs = {
  id: Scalars['ID'];
};

export type QueryApplicationCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryCommentArgs = {
  id: Scalars['ID'];
};

export type QueryCommunityArgs = {
  id: Scalars['ID'];
};

export type QueryCommunityEventTypeArgs = {
  id: Scalars['ID'];
};

export type QueryCommunityEventTypeByNameArgs = {
  name: Scalars['String'];
};

export type QueryCompanyArgs = {
  id: Scalars['ID'];
};

export type QueryCompanyMemberOrderArgs = {
  id: Scalars['ID'];
};

export type QueryCompanyMemberProfessionnalWeatherArgs = {
  id: Scalars['ID'];
};

export type QueryCounterMeasureArgs = {
  id: Scalars['ID'];
};

export type QueryEventArgs = {
  id: Scalars['ID'];
};

export type QueryEventMessageSuggestionArgs = {
  id: Scalars['ID'];
};

export type QueryEventTypeArgs = {
  id: Scalars['ID'];
};

export type QueryExpEventArgs = {
  id: Scalars['ID'];
};

export type QueryFeedbackArgs = {
  id: Scalars['ID'];
};

export type QueryFileArgs = {
  id: Scalars['ID'];
};

export type QueryFollowUpArgs = {
  id: Scalars['ID'];
};

export type QueryGenericExpEventArgs = {
  id: Scalars['ID'];
};

export type QueryHelpMarkArgs = {
  id: Scalars['ID'];
};

export type QueryIdeaCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryMissionArgs = {
  id: Scalars['ID'];
};

export type QueryMottoArgs = {
  id: Scalars['ID'];
};

export type QueryNotificationArgs = {
  id: Scalars['ID'];
};

export type QueryObjectiveArgs = {
  id: Scalars['ID'];
};

export type QueryOneToOneArgs = {
  id: Scalars['ID'];
};

export type QueryPostArgs = {
  id: Scalars['ID'];
};

export type QueryProblemCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryProblemCircuitArgs = {
  id: Scalars['ID'];
};

export type QueryProductArgs = {
  id: Scalars['ID'];
};

export type QueryProfessionalStatusArgs = {
  id: Scalars['ID'];
};

export type QueryProfessionnalWeatherArgs = {
  id: Scalars['ID'];
};

export type QueryRoadBlockArgs = {
  id: Scalars['ID'];
};

export type QuerySemiInstantCallArgs = {
  id: Scalars['ID'];
};

export type QuerySkillArgs = {
  id: Scalars['ID'];
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type Query_ActionByIdArgs = {
  id: Scalars['ID'];
};

export type Query_ActionInCommentArgs = {
  id: Scalars['ID'];
};

export type Query_ActionInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_ActionInIndicatorArgs = {
  id: Scalars['ID'];
};

export type Query_ActionInPostArgs = {
  id: Scalars['ID'];
};

export type Query_AllActionsMetaArgs = {
  filter?: InputMaybe<ActionFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  targedDate?: InputMaybe<DateRange>;
};

export type Query_AllAnalysisCompareMetaArgs = {
  filter?: InputMaybe<AnalysisCompareFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllAnalysisRootCausesMetaArgs = {
  filter?: InputMaybe<AnalysisRootCauseFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllAnalysisWhysMetaArgs = {
  filter?: InputMaybe<AnalysisWhyFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllApplicationsMetaArgs = {
  input: GetApplicationsMetaInput;
};

export type Query_AllBusinessUnitsMetaArgs = {
  filter?: InputMaybe<BusinessUnitFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllCoinHistoriesMetaArgs = {
  filter?: InputMaybe<CoinHistoryFilter>;
};

export type Query_AllCommentsMetaArgs = {
  filter?: InputMaybe<CommentFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllCommunitiesMetaArgs = {
  filter?: InputMaybe<CommunityFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllCommunityEventsTypeMetaArgs = {
  filter?: InputMaybe<CommunityEventTypeFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllCompaniesMetaArgs = {
  filter?: InputMaybe<CompanyFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllCompanyMemberOrdersMetaArgs = {
  filter?: InputMaybe<CompanyMemberOrderFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllCompanyMemberProfessionnalWeathersMetaArgs = {
  filter?: InputMaybe<CompanyMemberProfessionnalWeatherFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllCompanyMembersMetaArgs = {
  filter?: InputMaybe<CompanyMemberFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllCounterMeasuresMetaArgs = {
  filter?: InputMaybe<CounterMeasureFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllEventMessageSuggestionsMetaArgs = {
  filter?: InputMaybe<EventMessageSuggestionFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllEventTypesMetaArgs = {
  filter?: InputMaybe<EventTypeFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllEventsMetaArgs = {
  filter?: InputMaybe<EventFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllExpEventsMetaArgs = {
  filter?: InputMaybe<ExpEventFilter>;
};

export type Query_AllFollowUpsMetaArgs = {
  filter?: InputMaybe<FollowUpFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllGenericExpEventsMetaArgs = {
  filter?: InputMaybe<GenericExpEventFilter>;
};

export type Query_AllHelpMarksMetaArgs = {
  filter?: InputMaybe<HelpMarkFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllIdeaCategoriesMetaArgs = {
  filter?: InputMaybe<IdeaCategoryFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllIndicatorsMetaArgs = {
  communityId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<IndicatorFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllMeetingsMetaArgs = {
  filter?: InputMaybe<MeetingFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllMottosMetaArgs = {
  filter?: InputMaybe<MottoFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllObjectivesMetaArgs = {
  filter?: InputMaybe<ObjectiveFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllOneToOnesMetaArgs = {
  filter?: InputMaybe<OneToOneFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllPostsMetaArgs = {
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllProblemCategorysMetaArgs = {
  filter?: InputMaybe<ProblemCategoryFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllProblemCircuitsMetaArgs = {
  filter?: InputMaybe<ProblemCircuitFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllProductsMetaArgs = {
  filter?: InputMaybe<ProductFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllProfessionalStatusesMetaArgs = {
  filter?: InputMaybe<ProfessionalStatusFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllProfessionnalWeathersMetaArgs = {
  filter?: InputMaybe<ProfessionnalWeatherFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllProjectsMetaArgs = {
  filter?: InputMaybe<ProjectFilterByInput>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllSemiInstantCallsMetaArgs = {
  filter?: InputMaybe<SemiInstantCallFilterInput>;
};

export type Query_AllSkillsMetaArgs = {
  filter?: InputMaybe<SkillFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AllUsersMetaArgs = {
  filter?: InputMaybe<UserFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type Query_AnalysisCompareInCommentArgs = {
  id: Scalars['ID'];
};

export type Query_AnalysisRootCauseInCommentArgs = {
  id: Scalars['ID'];
};

export type Query_AnalysisWhyInAnalysis5WhyArgs = {
  id: Scalars['ID'];
};

export type Query_ApplicationCategoryInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_ApplicationInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_ApplicationInFileArgs = {
  id: Scalars['ID'];
};

export type Query_BusinessUnitInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_BusinessUnitInFileArgs = {
  id: Scalars['ID'];
};

export type Query_BusinessUnitInIndicatorArgs = {
  id: Scalars['ID'];
};

export type Query_CalendarCommunityEventsPerDayInCommunityArgs = {
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  day: Scalars['DateTime'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CalendarEventType>;
};

export type Query_CalendarCommunityEventsPerDayInCompanyArgs = {
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  day: Scalars['DateTime'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CalendarEventType>;
};

export type Query_CalendarEventCommunitiesInCommunityArgs = {
  calendarEventId: Scalars['ID'];
  communityId: Scalars['ID'];
};

export type Query_CalendarEventInCommentArgs = {
  id: Scalars['ID'];
};

export type Query_CalendarEventInCommunityArgs = {
  id: Scalars['ID'];
};

export type Query_CalendarEventInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_CalendarEventOwnersInCompanyArgs = {
  calendarEventId: Scalars['ID'];
  companyMemberId: Scalars['ID'];
};

export type Query_CalendarFilterHistoriesMetaArgs = {
  companyMemberId?: InputMaybe<Scalars['ID']>;
};

export type Query_CalendarFilterHistoryInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_CalendarLocationEventsPerDayInCompanyArgs = {
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  day: Scalars['DateTime'];
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CalendarEventType>;
};

export type Query_CommentInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_CommentInUserArgs = {
  id: Scalars['ID'];
};

export type Query_CommunityInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_CommunityInFileArgs = {
  id: Scalars['ID'];
};

export type Query_CommunityInIndicatorArgs = {
  id: Scalars['ID'];
};

export type Query_CommunityInOneToOneArgs = {
  id: Scalars['ID'];
};

export type Query_CommunityInSocialArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyInBusinessUnitArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyInCoinArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyInCommunityArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyInFileArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyInIndicatorArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyInOneToOneArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyInPostArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyInSocialArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyMemberArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyMemberInBusinessUnitArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyMemberInLeaveArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyMemberInSocialArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyMemberInUserArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyMemberOrderInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_CompanyMemberOrderInProductArgs = {
  id: Scalars['ID'];
};

export type Query_CounterMeasureInFileArgs = {
  id: Scalars['ID'];
};

export type Query_EarnedExpEventInCommunityArgs = {
  id: Scalars['ID'];
};

export type Query_EarnedExpEventInCompanyMemberArgs = {
  id: Scalars['ID'];
};

export type Query_EventInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_EventsByCalendarFilterHistoryMetaArgs = {
  input: QueryCalendarFilterHistoryInput;
};

export type Query_FeedbackInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_HelpMarkInFileArgs = {
  id: Scalars['ID'];
};

export type Query_IndicatorInCommentArgs = {
  id: Scalars['ID'];
};

export type Query_IndicatorInCommunityArgs = {
  id: Scalars['ID'];
};

export type Query_LeaveInCommentArgs = {
  id: Scalars['ID'];
};

export type Query_LeaveInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_LeaveInFileArgs = {
  id: Scalars['ID'];
};

export type Query_LeavesByCalendarFilterHistoryMetaArgs = {
  input: LeaveFilterHistoryInput;
};

export type Query_MeetingInCommentArgs = {
  id: Scalars['ID'];
};

export type Query_MeetingInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_MeetingRegistrantInCompanyArgs = {
  input: MeetingRegistrantInCompanyInput;
};

export type Query_MeetingsByCalendarFilterHistoryMetaArgs = {
  input: MeetingFilterHistoryInput;
};

export type Query_MissionInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_NotificationInCommunityArgs = {
  id: Scalars['ID'];
};

export type Query_NotificationInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_PeoplesMetaArgs = {
  field?: InputMaybe<PeopleFilterField>;
  filters?: InputMaybe<Array<Scalars['String']>>;
};

export type Query_PostInA3Args = {
  id: Scalars['ID'];
};

export type Query_PostInCommentArgs = {
  id: Scalars['ID'];
};

export type Query_PostInCommunityArgs = {
  id: Scalars['ID'];
};

export type Query_PostInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_PostInIndicatorArgs = {
  id: Scalars['ID'];
};

export type Query_PostInUserArgs = {
  id: Scalars['ID'];
};

export type Query_PostRequestInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_ProductInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_ProductInFileArgs = {
  id: Scalars['ID'];
};

export type Query_ProjectInCommentArgs = {
  id: Scalars['ID'];
};

export type Query_ProjectInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_SeenNotificationsMetaArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_SemiInstantCallInCommunityArgs = {
  id: Scalars['ID'];
};

export type Query_SemiInstantCallInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_UnSeenNotificationsMetaArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type Query_UserArgs = {
  id: Scalars['ID'];
};

export type Query_UserByIdArgs = {
  id: Scalars['ID'];
};

export type Query_UserCommunityInCompanyArgs = {
  id: Scalars['ID'];
};

export type Query_UserInCommunityArgs = {
  id: Scalars['ID'];
};

export type Query_UserInFileArgs = {
  id: Scalars['ID'];
};

export type Query_UserThankInCompanyArgs = {
  id: Scalars['ID'];
};

export type QueryActionByIdArgs = {
  id: Scalars['ID'];
};

export type QueryAllActionsArgs = {
  cacheKey?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ActionFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  postId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  targedDate?: InputMaybe<DateRange>;
};

export type QueryAllAnalysisCompareArgs = {
  filter?: InputMaybe<AnalysisCompareFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllAnalysisCompareByPostArgs = {
  filter?: InputMaybe<AnalysisCompareFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  postId: Scalars['ID'];
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllAnalysisRootCausesArgs = {
  filter?: InputMaybe<AnalysisRootCauseFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllAnalysisRootCausesByPostArgs = {
  filter?: InputMaybe<AnalysisRootCauseFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  postId: Scalars['ID'];
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllAnalysisWhysArgs = {
  filter?: InputMaybe<AnalysisWhyFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllAnalysisWhysByPostArgs = {
  filter?: InputMaybe<AnalysisWhyFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  postId: Scalars['ID'];
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllApplicationCategoriesArgs = {
  input?: InputMaybe<GetApplicationCategoriesInput>;
};

export type QueryAllApplicationsArgs = {
  input: GetApplicationsInput;
};

export type QueryAllBusinessUnitsArgs = {
  filter?: InputMaybe<BusinessUnitFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllCoinHistoriesArgs = {
  filter?: InputMaybe<CoinHistoryFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllCommentsArgs = {
  filter?: InputMaybe<CommentFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllCommunitiesArgs = {
  filter?: InputMaybe<CommunityFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllCommunityEventsTypeArgs = {
  filter?: InputMaybe<CommunityEventTypeFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllCompaniesArgs = {
  filter?: InputMaybe<CompanyFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllCompanyMemberOrdersArgs = {
  filter?: InputMaybe<CompanyMemberOrderFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllCompanyMemberProfessionnalWeathersArgs = {
  filter?: InputMaybe<CompanyMemberProfessionnalWeatherFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllCompanyMembersArgs = {
  filter?: InputMaybe<CompanyMemberFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllCounterMeasuresArgs = {
  filter?: InputMaybe<CounterMeasureFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllEventMessageSuggestionsArgs = {
  filter?: InputMaybe<EventMessageSuggestionFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllEventTypesArgs = {
  filter?: InputMaybe<EventTypeFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllEventsArgs = {
  filter?: InputMaybe<EventFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllExpEventsArgs = {
  filter?: InputMaybe<ExpEventFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllFollowUpsArgs = {
  filter?: InputMaybe<FollowUpFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllFollowUpsByPostArgs = {
  filter?: InputMaybe<FollowUpFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  postId: Scalars['ID'];
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllGenericExpEventsArgs = {
  filter?: InputMaybe<GenericExpEventFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllHelpMarksArgs = {
  filter?: InputMaybe<HelpMarkFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllIdeaCategoriesArgs = {
  filter?: InputMaybe<IdeaCategoryFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllIndicatorsArgs = {
  filter?: InputMaybe<IndicatorFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllLeavesArgs = {
  input: LeaveFilterInput;
};

export type QueryAllMeetingsArgs = {
  filter?: InputMaybe<MeetingFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllMottosArgs = {
  filter?: InputMaybe<MottoFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllObjectivesArgs = {
  filter?: InputMaybe<ObjectiveFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllOneToOnesArgs = {
  filter?: InputMaybe<OneToOneFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllPostsArgs = {
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllProblemCategorysArgs = {
  filter?: InputMaybe<ProblemCategoryFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllProblemCircuitsArgs = {
  filter?: InputMaybe<ProblemCircuitFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllProfessionalStatusesArgs = {
  filter?: InputMaybe<ProfessionalStatusFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllProfessionnalWeathersArgs = {
  filter?: InputMaybe<ProfessionnalWeatherFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllSemiInstantCallsArgs = {
  input: AllSemiInstantCallsInput;
};

export type QueryAllSkillsArgs = {
  filter?: InputMaybe<SkillFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryAllUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryBadgesByCommunityArgs = {
  communityId: Scalars['ID'];
};

export type QueryCalendarEventByIdArgs = {
  id: Scalars['ID'];
};

export type QueryCalendarEventsPerDayArgs = {
  input: CalendarEventsPerDayInput;
};

export type QueryCalendarFilterHistoriesArgs = {
  companyMemberId?: InputMaybe<Scalars['ID']>;
};

export type QueryCalendarFilterHistoryByIdArgs = {
  calendarFilterHistoryId: Scalars['ID'];
};

export type QueryCalendarLocationEventsCompanyMemberOwnersPerDayArgs = {
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  date: Scalars['DateTime'];
};

export type QueryCheckClientArgs = {
  timezone: UserTimezoneInput;
};

export type QueryCheckFilteredCompanyMembersInputOptionToRemoveArgs = {
  businessUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  selectedIds: Array<Scalars['ID']>;
};

export type QueryCoachGetCompanyMemberProfessionalWeathersByCmArgs = {
  companyMemberId: Scalars['ID'];
};

export type QueryCoachPostCreatedByCmIdArgs = {
  companyMemberId: Scalars['ID'];
};

export type QueryCompanyMemberInCalendarArgs = {
  id: Scalars['ID'];
};

export type QueryCountIssueRaisedByCompanyArgs = {
  companySlug: Scalars['String'];
};

export type QueryCountProccessesByCompanyArgs = {
  companySlug: Scalars['String'];
};

export type QueryCoworkersArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type QueryCoworkersGroupedArgs = {
  cacheKey?: InputMaybe<Scalars['String']>;
  filterByCommunityId?: InputMaybe<Scalars['ID']>;
  filterByEventTypeId?: InputMaybe<Scalars['ID']>;
  filterByLocationId?: InputMaybe<Scalars['ID']>;
  filterByWeatherId?: InputMaybe<Scalars['ID']>;
  fromAdmin?: InputMaybe<Scalars['Boolean']>;
  fromBusinessUnit?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  jobTitle?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  withoutMe?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCoworkersInputOptionsArgs = {
  filterByCommunityId?: InputMaybe<Scalars['ID']>;
  filterByEventTypeId?: InputMaybe<Scalars['ID']>;
  filterByWeatherId?: InputMaybe<Scalars['ID']>;
  fromAdmin?: InputMaybe<Scalars['Boolean']>;
  fromBusinessUnit?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  jobTitle?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  withoutMe?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCoworkersMemberArgs = {
  cacheKey?: InputMaybe<Scalars['String']>;
  filterByCommunityId?: InputMaybe<Scalars['ID']>;
  filterByEventTypeId?: InputMaybe<Scalars['ID']>;
  filterByLocationId?: InputMaybe<Scalars['ID']>;
  filterByWeatherId?: InputMaybe<Scalars['ID']>;
  fromAdmin?: InputMaybe<Scalars['Boolean']>;
  fromBusinessUnit?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  jobTitle?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  withoutMe?: InputMaybe<Scalars['Boolean']>;
};

export type QueryCurrentCompanyAdminAdvicesArgs = {
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyAdminIdeasArgs = {
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyAdminProblemsArgs = {
  cacheKey?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyAdminProcessArgs = {
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyAdminQuestionsArgs = {
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyAdminStoriesArgs = {
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyAdviceArgs = {
  communityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  filterString?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyFunsArgs = {
  communityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  filterString?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyIdeasArgs = {
  communityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  filterString?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyPostNumberByTypeArgs = {
  filters?: InputMaybe<Array<Filter>>;
  issuePrivacy?: InputMaybe<IssuePrivacy>;
  postType?: InputMaybe<PostType>;
};

export type QueryCurrentCompanyProblemsArgs = {
  cacheKey?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  filterBy?: InputMaybe<PostFilterByInput>;
  filterString?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  isMeResponsible?: InputMaybe<Scalars['Boolean']>;
  issuePrivacy?: InputMaybe<IssuePrivacy>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyProcessArgs = {
  cacheKey?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  filterString?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  processStatus?: InputMaybe<Process_Status>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentCompanyQuestionsArgs = {
  communityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  filterBy?: InputMaybe<PostFilterByInput>;
  filterString?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
};

export type QueryCurrentCompanyStoriesArgs = {
  communityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  filterString?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryCurrentPostFiltersArgs = {
  postType: PostType;
};

export type QueryEventsByCalendarFilterHistoryArgs = {
  input: QueryCalendarFilterHistoryInput;
  keyWord?: InputMaybe<Scalars['String']>;
  locationOwnerId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryExportLeavesArgs = {
  input: ExportLeavesInput;
};

export type QueryFilteredCompanyMembersInputOptionArgs = {
  businessUnitIds?: InputMaybe<Array<Scalars['ID']>>;
  communityIds?: InputMaybe<Array<Scalars['ID']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
};

export type QueryGetActionsByPostIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetAllFilesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryGetAllTeamMembersSuggestionArgs = {
  filterValue?: InputMaybe<Scalars['String']>;
  fromAdmin?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  teamId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetBadgesByCategoryArgs = {
  communityId?: InputMaybe<Scalars['ID']>;
  companyMemberId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetBadgesByCompanyMemberArgs = {
  companyMemberId: Scalars['ID'];
};

export type QueryGetBusinessUnitByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetCommunitiesByCompanyArgs = {
  fromTunnel?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  mine?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetCommunitiesByCompanyMemberArgs = {
  input?: InputMaybe<CommunitiesByCompanyMemberInput>;
};

export type QueryGetCommunityByCompanyIdArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<CommunityFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryGetCommunityIndicatorsArgs = {
  communityId: Scalars['ID'];
  filter?: InputMaybe<IndicatorFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryGetCommunityMembersRoleBySelectedCommunityArgs = {
  teamId: Scalars['ID'];
};

export type QueryGetCommunityPostByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetCommunityProblemsByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetCompanyBusinessUnitsArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<BusinessUnitFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryGetCompanyIndicatorsArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<IndicatorFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryGetCompanyMemberByEmailArgs = {
  email: Scalars['String'];
};

export type QueryGetCompanyMemberByIdArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetCompanyMemberByIdsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type QueryGetCompanyMemberInCommunityArgs = {
  id: Scalars['ID'];
};

export type QueryGetCompanyMemberInPostArgs = {
  id: Scalars['ID'];
};

export type QueryGetCompanyMemberPostShareInCommunityArgs = {
  id: Scalars['ID'];
};

export type QueryGetCompanyMemberPostShareInCompanyArgs = {
  id: Scalars['ID'];
};

export type QueryGetCounterMeasuresByPostArgs = {
  postId: Scalars['ID'];
};

export type QueryGetEventsTodayArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type QueryGetHelpMarkByTypeArgs = {
  type: HelpMarkType;
};

export type QueryGetIndicatorByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetIndicatorHistoriesByIndicatorIdArgs = {
  indicatorId: Scalars['ID'];
};

export type QueryGetIndicatorHistoryByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetLeadermessageByCompanyMemberIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetMessageSuggestionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<MessageType>;
};

export type QueryGetMyCommunitiesIndicatorsArgs = {
  filter?: InputMaybe<IndicatorFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryGetMyOneToOneArchiveArgs = {
  id: Scalars['ID'];
};

export type QueryGetNMinusOnesArgs = {
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
};

export type QueryGetNeedByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetNeedUserByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetNeedsByCompanyMemberIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetOneToOneArchiveByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetOneToOneArchiveFileByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetOneToOneFileByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetPendingInvitationArgs = {
  token: Scalars['String'];
};

export type QueryGetPendingInvitationsByTeamIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetPostByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetPostFilesByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetProblemsCountBetweenCommunityArgs = {
  sourceId: Scalars['ID'];
  targetId: Scalars['ID'];
};

export type QueryGetProfessionnalWeatherNeedByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetProjectArgs = {
  id: Scalars['ID'];
};

export type QueryGetProjectsByCommunityArgs = {
  filterBy?: InputMaybe<ProjectFilterByInput>;
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryGetRankedCompanyMembersArgs = {
  shuffle?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetRoadBlockInCommunityArgs = {
  id: Scalars['ID'];
};

export type QueryGetUserCompanyByIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetUserPostsByIdArgs = {
  id: Scalars['ID'];
};

export type QueryIsAlreadySignedUpArgs = {
  token: Scalars['String'];
};

export type QueryIsCompanyMemberALeaveValidatorArgs = {
  companyMemberId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
};

export type QueryIsCurrentCompanyMemberInChargeOfArgs = {
  postId: Scalars['ID'];
};

export type QueryIsFiltersAppliedArgs = {
  filters?: InputMaybe<Array<Filter>>;
  postType: PostType;
};

export type QueryLeaveArgs = {
  id: Scalars['ID'];
};

export type QueryLeaveReasonsByCompanyArgs = {
  companyId?: InputMaybe<Scalars['ID']>;
};

export type QueryLeavesByCalendarFilterHistoryArgs = {
  input: LeaveFilterHistoryInput;
  ownerId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryLeavesByDateArgs = {
  input: LeavesByDateInput;
};

export type QueryLocalFileSignedUrlArgs = {
  expires?: InputMaybe<Scalars['Int']>;
  extension: Scalars['String'];
  name: Scalars['String'];
  repo?: InputMaybe<Scalars['String']>;
  s3Operation?: InputMaybe<S3Operation>;
};

export type QueryLocationEventsDetailPerDayArgs = {
  input?: InputMaybe<LocationEventsDetailPerDay>;
};

export type QueryMeetingByIdArgs = {
  uuid: Scalars['ID'];
};

export type QueryMeetingsByCalendarFilterHistoryArgs = {
  input: MeetingFilterHistoryInput;
  keyWord?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type QueryMyLocationEventByDayArgs = {
  date: Scalars['DateTime'];
};

export type QueryMyMeetingsArgs = {
  status?: InputMaybe<RegistrantStatus>;
  dateRange?: InputMaybe<CalendarDateRangeInput>;
};

export type QueryMyZoomMeetingsArgs = {
  companyMemberId?: InputMaybe<Scalars['String']>;
};

export type QueryPeopleArgs = {
  perPage?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
  withoutMe?: InputMaybe<Scalars['Boolean']>;
};

export type QueryPeoplesArgs = {
  field?: InputMaybe<PeopleFilterField>;
  filters?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<SortEnum>;
};

export type QueryRefreshAccessTokenArgs = {
  refreshToken: Scalars['String'];
};

export type QuerySeeFeedbackArgs = {
  id: Scalars['ID'];
};

export type QuerySeeThanksArgs = {
  id: Scalars['ID'];
};

export type QuerySeenNotificationsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryUnSeenNotificationsArgs = {
  id?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
};

export type QueryViewPostArgs = {
  id: Scalars['ID'];
};

export type QueryZoomMeetingByMeeetingIdArgs = {
  meetingId: Scalars['String'];
};

export type QueryZoomMeetingFullByMeetingIdArgs = {
  meetingId?: InputMaybe<Scalars['String']>;
  withPending?: InputMaybe<Scalars['Boolean']>;
};

export type QueryZoomMeetingRegistrantByRegistrantIdArgs = {
  meetingId: Scalars['String'];
  registrantId: Scalars['String'];
};

export type QueryZoomMeetingRegistrantsByMeeetingIdArgs = {
  meetingId: Scalars['String'];
  status?: InputMaybe<ZoomMeetingRegistrantStatus>;
};

export type QueryZoomMeetingsByZoomUserArgs = {
  zoomUserId: Scalars['String'];
};

export type QueryZoomUserByIdArgs = {
  userId: Scalars['String'];
};

export type QueryCalendarFilterHistoryInput = {
  communityEventTypeId?: InputMaybe<Scalars['ID']>;
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<CalendarEventType>;
};

export type QuestionCategory = {
  __typename?: 'QuestionCategory';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum RegistrantStatus {
  Approve = 'APPROVE',
  Cancel = 'CANCEL',
  Deny = 'DENY',
}

export type RoadBlock = {
  __typename?: 'RoadBlock';
  actions?: Maybe<Array<Action>>;
  companyMember?: Maybe<CompanyMember>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  roadBlockId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type RootCauseComment = {
  __typename?: 'RootCauseComment';
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum S3Operation {
  GetObject = 'getObject',
  PutObject = 'putObject',
}

export type S3UrlInfo = {
  __typename?: 'S3UrlInfo';
  key: Scalars['String'];
  url: Scalars['String'];
};

export enum S3UrlMethod {
  Get = 'GET',
  Put = 'PUT',
}

export type SemiInstantCall = {
  __typename?: 'SemiInstantCall';
  amITheHost?: Maybe<Scalars['Boolean']>;
  chatTeamName?: Maybe<Scalars['String']>;
  community?: Maybe<Community>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<CompanyMember>;
  description?: Maybe<Scalars['String']>;
  guests?: Maybe<Array<CompanyMember>>;
  id: Scalars['ID'];
  jitsiMeetUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<SemiInstantCallType>;
};

export type SemiInstantCallFilterInput = {
  /** company member concerned by the call (creator or guest) */
  companyMemberId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

/** -------------------------------------- ENUMS -------------------------------# */
export enum SemiInstantCallType {
  ChatTeam = 'CHAT_TEAM',
  Direct = 'DIRECT',
  Team = 'TEAM',
}

export type SignUpInvitationPayload = {
  __typename?: 'SignUpInvitationPayload';
  is2FA?: Maybe<Scalars['Boolean']>;
  isInvalidToken?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  targetEmail?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

export type SigninInput = {
  email: Scalars['String'];
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
};

export type SigninPayload = {
  __typename?: 'SigninPayload';
  is2FA: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  noCompany: Scalars['Boolean'];
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  user: User;
};

export type SignupInput = {
  firstname: Scalars['String'];
  invitationToken: Scalars['String'];
  lastname?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
};

export type SignupPayload = {
  __typename?: 'SignupPayload';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
};

export type Skill = {
  __typename?: 'Skill';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type SkillFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  q?: InputMaybe<Scalars['String']>;
};

export type SnackBar = {
  __typename?: 'SnackBar';
  isOpen?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Subscription = {
  __typename?: 'Subscription';
  countCompanyMemberDaylyConnected: Scalars['Int'];
  incomingSemiInstantCall?: Maybe<SemiInstantCall>;
  isCompanyMemberOnline?: Maybe<Scalars['Boolean']>;
  newCommunityBadge?: Maybe<CommunityBadge>;
  newEarnedCoin?: Maybe<CoinHistory>;
  newEarnedExpEvent?: Maybe<EarnedExpEvent>;
  newIndividualBadge?: Maybe<IndividualBadge>;
  newTeamEarnedCoin?: Maybe<CoinHistory>;
  notificationCreated?: Maybe<Notification>;
  sentFeedback: Feedback;
};

/** ------------------------ SUBSCCRIPTION -------------------------------# */
export type SubscriptionCountCompanyMemberDaylyConnectedArgs = {
  companySlug?: InputMaybe<Scalars['String']>;
};

/** ------------------------ SUBSCCRIPTION -------------------------------# */
export type SubscriptionIncomingSemiInstantCallArgs = {
  guestId?: InputMaybe<Scalars['ID']>;
};

/** ------------------------ SUBSCCRIPTION -------------------------------# */
export type SubscriptionIsCompanyMemberOnlineArgs = {
  userId: Scalars['ID'];
};

/** ------------------------ SUBSCCRIPTION -------------------------------# */
export type SubscriptionNewExpLevelArgs = {
  companyMemberId?: InputMaybe<Scalars['ID']>;
};

/** ------------------------ SUBSCCRIPTION -------------------------------# */
export type SubscriptionNotificationCreatedArgs = {
  companyMemberReceiverId?: InputMaybe<Scalars['ID']>;
};

/** ------------------------ SUBSCCRIPTION -------------------------------# */
export type SubscriptionSentFeedbackArgs = {
  companyMemberId?: InputMaybe<Scalars['ID']>;
};

export type Success = {
  __typename?: 'Success';
  accessToken?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SwitchCompanyPayload = {
  __typename?: 'SwitchCompanyPayload';
  companyMember: CompanyMember;
  message?: Maybe<Scalars['String']>;
  streamToken: Scalars['String'];
  success?: Maybe<Scalars['Boolean']>;
};

export type TodaysEarnedExp = {
  __typename?: 'TodaysEarnedExp';
  earnedExp: Array<EarnedExpEvent>;
  exp: Scalars['Int'];
};

export type TunnelsActivationValue = {
  __typename?: 'TunnelsActivationValue';
  entry: Scalars['Boolean'];
  exit: Scalars['Boolean'];
};

export type TwoFactorAuthenticationPayload = {
  __typename?: 'TwoFactorAuthenticationPayload';
  message?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token: Scalars['String'];
};

export type UpdateApplicationInput = {
  apiKey?: InputMaybe<Scalars['String']>;
  categoryIds?: InputMaybe<Array<Scalars['ID']>>;
  featureList?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  imageList?: InputMaybe<Array<FileInput>>;
  isActivated?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<FileInput>;
  longDescription?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  shortDescription?: InputMaybe<Scalars['String']>;
};

export type UpdateBusinessUnitInput = {
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  logo?: InputMaybe<Scalars['String']>;
  mission?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  romance?: InputMaybe<Scalars['String']>;
};

export type UpdateCalendarCommunityEventInput = {
  communityEventTypeId: Scalars['ID'];
  /** to remove */
  communityId?: InputMaybe<Scalars['ID']>;
  communityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  description?: InputMaybe<Scalars['String']>;
  end: Scalars['DateTime'];
  eventPlace?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  makePublic?: InputMaybe<Scalars['Boolean']>;
  meetingId?: InputMaybe<Scalars['String']>;
  /** to remove */
  ownerId?: InputMaybe<Scalars['ID']>;
  ownerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  start: Scalars['DateTime'];
  title: Scalars['String'];
};

export type UpdateCalendarLocationEventInput = {
  end: Scalars['DateTime'];
  fromEntryTunel?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  start: Scalars['DateTime'];
};

export type UpdateCommunityEventInput = {
  communityEventTypeId: Scalars['ID'];
  communityId: Scalars['ID'];
  day: Scalars['DateTime'];
  id: Scalars['ID'];
  makePublic: Scalars['Boolean'];
  ownerIds: Array<Scalars['ID']>;
  title: Scalars['String'];
};

export type UpdateCommunityEventTypeInput = {
  icon: Scalars['String'];
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateCompanyInput = {
  application?: InputMaybe<ApplicationInfoInput>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isLeaveEmailEnabled?: InputMaybe<Scalars['Boolean']>;
  leaveWeekendsCount?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<Scalars['String']>;
  mission?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  romance?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

/** -------------------------------------- INPUTS -------------------------------# */
export type UpdateCustomBadgeInput = {
  badgeId: Scalars['ID'];
  maxExp?: InputMaybe<Scalars['Int']>;
  minExp?: InputMaybe<Scalars['Int']>;
};

export type UpdateEventTypeInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Event message suggestion content */
  messages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateExpEventInput = {
  communityExp?: InputMaybe<Scalars['Int']>;
  endAt?: InputMaybe<Scalars['DateTime']>;
  exp?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  startAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateExternalEmailInput = {
  email: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
};

export type UpdateGenericExpEventInput = {
  communityExp?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  exp?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  isCodeImplemented?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateHelpMarkInput = {
  en_description?: InputMaybe<Scalars['String']>;
  en_title?: InputMaybe<Scalars['String']>;
  fr_description?: InputMaybe<Scalars['String']>;
  fr_title?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  imageId?: InputMaybe<Scalars['ID']>;
};

export type UpdateIndicatorInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  level?: InputMaybe<IndicatorLevel>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdateLeaveInput = {
  attachementIds: Array<Scalars['ID']>;
  daysDuration?: InputMaybe<Array<LeaveDayDuration>>;
  ending: Scalars['DateTime'];
  id: Scalars['ID'];
  isHalfDay?: InputMaybe<Scalars['Boolean']>;
  makePublic: Scalars['Boolean'];
  otherReason?: InputMaybe<Scalars['String']>;
  peopleToNotifyIds: Array<Scalars['ID']>;
  reasonId: Scalars['ID'];
  specialDayOff: Scalars['Boolean'];
  start: Scalars['DateTime'];
  validatorIds: Array<Scalars['ID']>;
};

export type UpdateNeedInput = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  professionnalWeatherId: Scalars['ID'];
};

export type UpdateProductInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  price?: InputMaybe<Scalars['Int']>;
  thumbnailId?: InputMaybe<Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateProfessionalStatusInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateProfessionnalWeatherInput = {
  description?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateProfileInput = {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  personality?: InputMaybe<Scalars['String']>;
};

export type UpdateSemiInstantCallInput = {
  chatTeamName?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['ID']>;
  guestIds?: InputMaybe<Array<Scalars['ID']>>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserInput = {
  adress?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  familySituation?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastname?: InputMaybe<Scalars['String']>;
  mySharedHorizon?: InputMaybe<Scalars['String']>;
  passions?: InputMaybe<Array<Scalars['ID']>>;
  personality?: InputMaybe<UserPersonality>;
  phone?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  presentationVideoId?: InputMaybe<Scalars['ID']>;
  previousCompanies?: InputMaybe<Array<Scalars['ID']>>;
  removed?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<MainRole>;
  skills?: InputMaybe<Array<Scalars['ID']>>;
  successes?: InputMaybe<Array<Scalars['ID']>>;
  suspended?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateUserPreferenceInput = {
  coach?: InputMaybe<CoachInput>;
  userTimezone?: InputMaybe<UserTimezoneInput>;
};

export type UpdateZoomMeetingInput = {
  agenda?: InputMaybe<Scalars['String']>;
  duration: Scalars['Int'];
  meetingId: Scalars['String'];
  password: Scalars['String'];
  settings: CreateZoomMeetingSettingsInput;
  start_time: Scalars['DateTime'];
  timezone: Scalars['String'];
  topic: Scalars['String'];
  type: Scalars['Int'];
};

export type UpdateZoomMeetingRegistrantInput = {
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

export type UpdateZoomMeetingRegistrantStatusInput = {
  action: MeetingRegistrantStatusAction;
  meetingId: Scalars['String'];
  registrants: Array<UpdateZoomMeetingRegistrantInput>;
  timezone: Scalars['String'];
};

export type UpdatemeetingInput = {
  attendeeIds: Array<InputMaybe<Scalars['ID']>>;
  contact_email?: InputMaybe<Scalars['String']>;
  contact_name?: InputMaybe<Scalars['String']>;
  details?: InputMaybe<Scalars['String']>;
  dueDate: Scalars['DateTime'];
  duration: Scalars['Int'];
  externalEmails?: InputMaybe<Array<UpdateExternalEmailInput>>;
  invitees: Array<MeetingInviteeInput>;
  makePublic: Scalars['Boolean'];
  passcode: Scalars['String'];
  timezone: Scalars['String'];
  topic: Scalars['String'];
  uuid: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  adress?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  communities?: Maybe<Array<UserCommunity>>;
  companyMembers?: Maybe<Array<Maybe<CompanyMember>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdMissions?: Maybe<Array<Maybe<Mission>>>;
  createdRoadBlocks?: Maybe<Array<Maybe<RoadBlock>>>;
  currentCompanyMember?: Maybe<CompanyMember>;
  email?: Maybe<Scalars['String']>;
  familySituation?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  hashedPassword?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is2FA?: Maybe<Scalars['Boolean']>;
  isItMyDay?: Maybe<Scalars['Boolean']>;
  isOnline?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  myCommunity?: Maybe<Community>;
  mySharedHorizon?: Maybe<Scalars['String']>;
  /** if user has no company */
  noCompany?: Maybe<Scalars['Boolean']>;
  passions?: Maybe<Array<Passion>>;
  personality?: Maybe<UserPersonality>;
  phone?: Maybe<Scalars['String']>;
  photoPublicUrl?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  pictures?: Maybe<Array<UserPicture>>;
  posts?: Maybe<Array<Post>>;
  presentationVideo?: Maybe<File>;
  previousCompanies?: Maybe<Array<PreviousCompany>>;
  removed?: Maybe<Scalars['Boolean']>;
  role?: Maybe<MainRole>;
  skills?: Maybe<Array<Skill>>;
  successes?: Maybe<Array<UserSuccess>>;
  /** The suspended in user is true when his every companyMembers are suspended */
  suspended?: Maybe<Scalars['Boolean']>;
};

export type UserCommunity = {
  __typename?: 'UserCommunity';
  community?: Maybe<Community>;
  companyMember?: Maybe<CompanyMember>;
  companyMemberId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  role?: Maybe<CommunityRole>;
  user?: Maybe<User>;
};

export type UserCompanyInvitation = {
  __typename?: 'UserCompanyInvitation';
  communityMemberRole?: Maybe<CommunityRole>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  companyMemberAccess?: Maybe<MainRole>;
  companyMemberRole?: Maybe<MemberRole>;
  hiringDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isConfirmed: Scalars['Boolean'];
  pendingTeams?: Maybe<Array<Community>>;
  pendingTeamsIds?: Maybe<Array<Scalars['ID']>>;
  suspended?: Maybe<Scalars['Boolean']>;
  targetEmail: Scalars['String'];
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type UserFilter = {
  id?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  q?: InputMaybe<Scalars['String']>;
};

export enum UserPersonality {
  Conscientiouness = 'CONSCIENTIOUNESS',
  Dominance = 'DOMINANCE',
  Influence = 'INFLUENCE',
  Steadiness = 'STEADINESS',
}

export type UserPicture = {
  __typename?: 'UserPicture';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  imagePublicUrl?: Maybe<Scalars['String']>;
  imageUrl: Scalars['String'];
  removed?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  users?: Maybe<Array<Maybe<User>>>;
};

export type UserPostLike = {
  __typename?: 'UserPostLike';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  user: User;
};

export type UserPreference = {
  __typename?: 'UserPreference';
  coach?: Maybe<CoachPreference>;
  companyMember?: Maybe<CompanyMember>;
  createdAt?: Maybe<Scalars['DateTime']>;
  enableContextualNotification?: Maybe<Scalars['Boolean']>;
  enableNavigateurNotification?: Maybe<Scalars['Boolean']>;
  enableProfessionalStatusActiveHours?: Maybe<Scalars['Boolean']>;
  enableShowEntryTunnel?: Maybe<Scalars['Boolean']>;
  enableShowExitTunnel?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isEntryTunnelShownPragmaToday?: Maybe<Scalars['Boolean']>;
  isEntryTunnelShownToday?: Maybe<Scalars['Boolean']>;
  isExitTunnelShownPragmaToday?: Maybe<Scalars['Boolean']>;
  isExitTunnelShownToday?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Language>;
  professionalStatusActiveHoursTimeEnd?: Maybe<Scalars['DateTime']>;
  professionalStatusActiveHoursTimeStart?: Maybe<Scalars['DateTime']>;
  showEntryTunnelTime?: Maybe<Scalars['DateTime']>;
  showExitTunnelTime?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userTimezone?: Maybe<UserTimezone>;
};

export type UserSuccess = {
  __typename?: 'UserSuccess';
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['ID']>;
};

export type UserThank = {
  __typename?: 'UserThank';
  alreadySeen?: Maybe<Scalars['Boolean']>;
  companyMemberReceivers?: Maybe<Array<CompanyMember>>;
  companyMemberSender?: Maybe<CompanyMember>;
  companyMemberSenderId?: Maybe<Scalars['ID']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  removed?: Maybe<Scalars['Boolean']>;
  response?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  type?: Maybe<UserThankType>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum UserThankType {
  Thank = 'THANK',
  Wish = 'WISH',
}

export type UserTimezone = {
  __typename?: 'UserTimezone';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tzCode?: Maybe<Scalars['String']>;
  utc?: Maybe<Scalars['String']>;
};

export type UserTimezoneInput = {
  label: Scalars['String'];
  name: Scalars['String'];
  tzCode: Scalars['String'];
  utc: Scalars['String'];
};

export type ZoomMeetingByRegistrantFullResponse = {
  __typename?: 'ZoomMeetingByRegistrantFullResponse';
  agenda?: Maybe<Scalars['String']>;
  contact_email?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  host_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  join_url?: Maybe<Scalars['String']>;
  registrants?: Maybe<Array<Maybe<AddZoomMeetingRegistrantResponse>>>;
  registration_url?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Meeting_Status>;
  timezone?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

/** -------------------- ZOOM RESPONSES TYPE -------------------- */
export type ZoomMeetingRegistrantByRegistrantIdResponse = {
  __typename?: 'ZoomMeetingRegistrantByRegistrantIdResponse';
  address?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  join_url?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  status?: Maybe<ZoomMeetingRegistrantStatus>;
  zip?: Maybe<Scalars['String']>;
};

export type ZoomMeetingRegistrantResponse = {
  __typename?: 'ZoomMeetingRegistrantResponse';
  registrants?: Maybe<Array<Maybe<AddZoomMeetingRegistrantResponse>>>;
  total_records?: Maybe<Scalars['Int']>;
};

export enum ZoomMeetingRegistrantStatus {
  Approved = 'approved',
  Denied = 'denied',
  Pending = 'pending',
}

export type ZoomMeetingResponse = {
  __typename?: 'ZoomMeetingResponse';
  agenda?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  duration?: Maybe<Scalars['Int']>;
  host_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  join_url?: Maybe<Scalars['String']>;
  pmi?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['DateTime']>;
  timezone?: Maybe<Scalars['String']>;
  topic?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['Int']>;
  uuid?: Maybe<Scalars['String']>;
};

export type ZoomMeetingsResponse = {
  __typename?: 'ZoomMeetingsResponse';
  meetings?: Maybe<Array<Maybe<ZoomMeetingResponse>>>;
  total_records?: Maybe<Scalars['Int']>;
};

export type ZoomRefreshTokenResponse = {
  __typename?: 'ZoomRefreshTokenResponse';
  access_token?: Maybe<Scalars['String']>;
  expires_in?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
};

/** -------------------------------------- INPUTS -------------------------------# */
export type PictureInput = {
  imageUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type DeviceInfoManagementMutationVariables = Exact<{
  info: DeviceInfoInput;
}>;

export type DeviceInfoManagementMutation = {
  __typename?: 'Mutation';
  deviceInfoManagement?:
    | {
        __typename?: 'DeviceInfo';
        id: string;
        deviceId: string;
        token: string;
        owner?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type CreateFollowUpMutationVariables = Exact<{
  description: Scalars['String'];
  postId: Scalars['ID'];
}>;

export type CreateFollowUpMutation = {
  __typename?: 'Mutation';
  createFollowUp?:
    | { __typename?: 'FollowUp'; id: string; description?: string | null | undefined }
    | null
    | undefined;
};

export type UpdateFollowUpMutationVariables = Exact<{
  id: Scalars['ID'];
  description: Scalars['String'];
}>;

export type UpdateFollowUpMutation = {
  __typename?: 'Mutation';
  updateFollowUp?:
    | { __typename?: 'FollowUp'; id: string; description?: string | null | undefined }
    | null
    | undefined;
};

export type DeleteFollowUpMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFollowUpMutation = {
  __typename?: 'Mutation';
  deleteFollowUp?: { __typename?: 'FollowUp'; id: string } | null | undefined;
};

export type CreateObjectiveMutationVariables = Exact<{
  description: Scalars['String'];
  postId: Scalars['ID'];
}>;

export type CreateObjectiveMutation = {
  __typename?: 'Mutation';
  createObjective?:
    | { __typename?: 'Objective'; id: string; description?: string | null | undefined }
    | null
    | undefined;
};

export type UpdateObjectiveMutationVariables = Exact<{
  id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
}>;

export type UpdateObjectiveMutation = {
  __typename?: 'Mutation';
  updateObjective?:
    | { __typename?: 'Objective'; id: string; description?: string | null | undefined }
    | null
    | undefined;
};

export type DeleteObjectiveMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteObjectiveMutation = {
  __typename?: 'Mutation';
  deleteObjective?: { __typename?: 'Objective'; id: string } | null | undefined;
};

export type CreateAnalysisRootCauseMutationVariables = Exact<{
  description: Scalars['String'];
  postId: Scalars['ID'];
}>;

export type CreateAnalysisRootCauseMutation = {
  __typename?: 'Mutation';
  createAnalysisRootCause?:
    | { __typename?: 'AnalysisRootCause'; id: string; description?: string | null | undefined }
    | null
    | undefined;
};

export type UpdateAnalysisRootCauseMutationVariables = Exact<{
  id: Scalars['ID'];
  description: Scalars['String'];
}>;

export type UpdateAnalysisRootCauseMutation = {
  __typename?: 'Mutation';
  updateAnalysisRootCause?:
    | { __typename?: 'AnalysisRootCause'; id: string; description?: string | null | undefined }
    | null
    | undefined;
};

export type DeleteAnalysisRootCauseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteAnalysisRootCauseMutation = {
  __typename?: 'Mutation';
  deleteAnalysisRootCause?: { __typename?: 'AnalysisRootCause'; id: string } | null | undefined;
};

export type CreateAnalysisRootCauseCommentMutationVariables = Exact<{
  content: Scalars['String'];
  rootCauseId: Scalars['ID'];
}>;

export type CreateAnalysisRootCauseCommentMutation = {
  __typename?: 'Mutation';
  createRootCauseComment?: { __typename?: 'RootCauseComment'; id: string } | null | undefined;
};

export type CreateAnalysisWhyMutationVariables = Exact<{
  description: Scalars['String'];
  postId: Scalars['ID'];
}>;

export type CreateAnalysisWhyMutation = {
  __typename?: 'Mutation';
  createAnalysisWhy?:
    | { __typename?: 'AnalysisWhy'; id: string; description?: string | null | undefined }
    | null
    | undefined;
};

export type UpdateAnalysisWhyMutationVariables = Exact<{
  id: Scalars['ID'];
  description: Scalars['String'];
}>;

export type UpdateAnalysisWhyMutation = {
  __typename?: 'Mutation';
  updateAnalysisWhy?:
    | { __typename?: 'AnalysisWhy'; id: string; description?: string | null | undefined }
    | null
    | undefined;
};

export type DeleteAnalysisWhyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteAnalysisWhyMutation = {
  __typename?: 'Mutation';
  deleteAnalysisWhy?: { __typename?: 'AnalysisWhy'; id: string } | null | undefined;
};

export type CreateAnalysis5WhyMutationVariables = Exact<{
  analysisWhyId: Scalars['ID'];
  content: Scalars['String'];
  rank?: InputMaybe<Scalars['Int']>;
}>;

export type CreateAnalysis5WhyMutation = {
  __typename?: 'Mutation';
  createAnalysis5Why?: { __typename?: 'Analysis5Why'; id: string } | null | undefined;
};

export type CreateAnalysisCompareMutationVariables = Exact<{
  subject1: Scalars['String'];
  subject2: Scalars['String'];
  postId: Scalars['ID'];
}>;

export type CreateAnalysisCompareMutation = {
  __typename?: 'Mutation';
  createAnalysisCompare?: { __typename?: 'AnalysisCompare'; id: string } | null | undefined;
};

export type UpdateAnalysisCompareMutationVariables = Exact<{
  id: Scalars['ID'];
  subject1?: InputMaybe<Scalars['String']>;
  subject2?: InputMaybe<Scalars['String']>;
}>;

export type UpdateAnalysisCompareMutation = {
  __typename?: 'Mutation';
  updateAnalysisCompare?: { __typename?: 'AnalysisCompare'; id: string } | null | undefined;
};

export type DeleteAnalysisCompareMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteAnalysisCompareMutation = {
  __typename?: 'Mutation';
  deleteAnalysisCompare?: { __typename?: 'AnalysisCompare'; id: string } | null | undefined;
};

export type CreateAnalysisCompareCommentMutationVariables = Exact<{
  content: Scalars['String'];
  compareId: Scalars['ID'];
}>;

export type CreateAnalysisCompareCommentMutation = {
  __typename?: 'Mutation';
  createCompareComment?: { __typename?: 'CompareComment'; id: string } | null | undefined;
};

export type CreateCounterMeasureMutationVariables = Exact<{
  title: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['Int']>;
  fileType?: InputMaybe<Scalars['String']>;
  postId: Scalars['ID'];
}>;

export type CreateCounterMeasureMutation = {
  __typename?: 'Mutation';
  createCounterMeasure?:
    | {
        __typename?: 'CounterMeasure';
        id: string;
        createdAt?: any | null | undefined;
        title?: string | null | undefined;
        description?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateCounterMeasureMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  filePath?: InputMaybe<Scalars['String']>;
  fileSize?: InputMaybe<Scalars['Int']>;
  fileType?: InputMaybe<Scalars['String']>;
  fileIdToRemove?: InputMaybe<Scalars['String']>;
}>;

export type UpdateCounterMeasureMutation = {
  __typename?: 'Mutation';
  updateCounterMeasure?:
    | {
        __typename?: 'CounterMeasure';
        id: string;
        updatedAt?: any | null | undefined;
        title?: string | null | undefined;
        description?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DeleteCounterMeasureMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCounterMeasureMutation = {
  __typename?: 'Mutation';
  deleteCounterMeasure?: { __typename?: 'CounterMeasure'; id: string } | null | undefined;
};

export type DeleteCounterMeasureFileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCounterMeasureFileMutation = {
  __typename?: 'Mutation';
  deleteCounterMeasureFile?: { __typename?: 'CounterMeasure'; id: string } | null | undefined;
};

export type GetFollowUpsByPostQueryVariables = Exact<{
  postId: Scalars['ID'];
}>;

export type GetFollowUpsByPostQuery = {
  __typename?: 'Query';
  allFollowUpsByPost?:
    | Array<
        | { __typename?: 'FollowUp'; id: string; description?: string | null | undefined }
        | null
        | undefined
      >
    | null
    | undefined;
  currentWholeCompany: { __typename?: 'Community'; id: string; name?: string | null | undefined };
};

export type GetFollowUpsByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetFollowUpsByIdQuery = {
  __typename?: 'Query';
  FollowUp?:
    | { __typename?: 'FollowUp'; id: string; description?: string | null | undefined }
    | null
    | undefined;
};

export type GetA3InfoByPostIdQueryVariables = Exact<{
  postId: Scalars['ID'];
}>;

export type GetA3InfoByPostIdQuery = {
  __typename?: 'Query';
  Post?:
    | {
        __typename?: 'Post';
        id: string;
        title?: string | null | undefined;
        postType?: PostType | null | undefined;
        content?: string | null | undefined;
        problemLevel?: number | null | undefined;
        problemTo?: any | null | undefined;
        who?: string | null | undefined;
        what?: string | null | undefined;
        how?: string | null | undefined;
        where?: string | null | undefined;
        when?: string | null | undefined;
        impact?: string | null | undefined;
        demand?: Demand_Type | null | undefined;
        demandSolveStatus?: Demand_Solve_Status | null | undefined;
        demandImproveStatus?: Demand_Improve_Status | null | undefined;
        a3Status?: Post_A3_Status | null | undefined;
        createdAt?: any | null | undefined;
        commentCount?: number | null | undefined;
        isSeenByMe?: boolean | null | undefined;
        isMine?: boolean | null | undefined;
        isLikedByMe?: boolean | null | undefined;
        likeCount?: number | null | undefined;
        draft?: boolean | null | undefined;
        reopened?: boolean | null | undefined;
        isAuthorHidden?: boolean | null | undefined;
        shareCount?: number | null | undefined;
        problemCommunitySource?:
          | {
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
              members?:
                | Array<{
                    __typename?: 'UserCommunity';
                    id: string;
                    companyMember?:
                      | {
                          __typename?: 'CompanyMember';
                          id: string;
                          user?:
                            | {
                                __typename?: 'User';
                                id: string;
                                firstName?: string | null | undefined;
                                fullName?: string | null | undefined;
                                lastName?: string | null | undefined;
                                email?: string | null | undefined;
                                photoUrl?: string | null | undefined;
                                photoPublicUrl?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
        problemCategory?:
          | { __typename?: 'ProblemCategory'; id: string; title?: string | null | undefined }
          | null
          | undefined;
        likedBy?:
          | Array<{
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              fullName?: string | null | undefined;
              lastName?: string | null | undefined;
              email?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              currentCompanyMember?:
                | { __typename?: 'CompanyMember'; id: string }
                | null
                | undefined;
            }>
          | null
          | undefined;
        companyMemberResponsibles?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    email?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        responsibles?:
          | Array<
              | {
                  __typename?: 'User';
                  id: string;
                  lastName?: string | null | undefined;
                  firstName?: string | null | undefined;
                  email?: string | null | undefined;
                  photoUrl?: string | null | undefined;
                  photoPublicUrl?: string | null | undefined;
                  currentCompanyMember?:
                    | {
                        __typename?: 'CompanyMember';
                        id: string;
                        user?:
                          | {
                              __typename?: 'User';
                              id: string;
                              firstName?: string | null | undefined;
                              fullName?: string | null | undefined;
                              lastName?: string | null | undefined;
                              email?: string | null | undefined;
                              photoUrl?: string | null | undefined;
                              photoPublicUrl?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        createdBy?:
          | {
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              fullName?: string | null | undefined;
              lastName?: string | null | undefined;
              email?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        comments?:
          | Array<{
              __typename?: 'Comment';
              id: string;
              content?: string | null | undefined;
              createdAt?: any | null | undefined;
              isMine?: boolean | null | undefined;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    email?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        myRating?: { __typename?: 'PostRating'; id: string; rating: number } | null | undefined;
        files?:
          | Array<{
              __typename?: 'File';
              id: string;
              name?: string | null | undefined;
              path?: string | null | undefined;
              publicUrl?: string | null | undefined;
              type?: string | null | undefined;
            }>
          | null
          | undefined;
        ideaCategory?:
          | { __typename?: 'IdeaCategory'; id: string; title?: string | null | undefined }
          | null
          | undefined;
        companyMemberExperts?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    email?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        experts?:
          | Array<
              | {
                  __typename?: 'User';
                  id: string;
                  photoUrl?: string | null | undefined;
                  photoPublicUrl?: string | null | undefined;
                  firstName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  currentCompanyMember?:
                    | {
                        __typename?: 'CompanyMember';
                        id: string;
                        user?:
                          | {
                              __typename?: 'User';
                              id: string;
                              firstName?: string | null | undefined;
                              fullName?: string | null | undefined;
                              lastName?: string | null | undefined;
                              email?: string | null | undefined;
                              photoUrl?: string | null | undefined;
                              photoPublicUrl?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        communities?:
          | Array<{
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }>
          | null
          | undefined;
        recipientCommunity?:
          | {
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        presentationFile?:
          | {
              __typename?: 'File';
              name?: string | null | undefined;
              path?: string | null | undefined;
              publicUrl?: string | null | undefined;
              type?: string | null | undefined;
            }
          | null
          | undefined;
        sharedToSpecificUsers?:
          | Array<{
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              currentCompanyMember?:
                | { __typename?: 'CompanyMember'; id: string }
                | null
                | undefined;
            }>
          | null
          | undefined;
        sharedToSpecificCommunities?:
          | Array<{
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }>
          | null
          | undefined;
        goals?:
          | Array<{
              __typename?: 'Indicator';
              id: string;
              name?: string | null | undefined;
              date?: any | null | undefined;
              description?: string | null | undefined;
              value?: string | null | undefined;
              action?: IndicatorAction | null | undefined;
              trend?: IndicatorTrend | null | undefined;
              level?: IndicatorLevel | null | undefined;
              createdAt?: any | null | undefined;
            }>
          | null
          | undefined;
        projectConversion?:
          | Array<{
              __typename?: 'Project';
              id: string;
              title?: string | null | undefined;
              description?: string | null | undefined;
              isCompleted?: boolean | null | undefined;
              targetDate?: any | null | undefined;
              status?: ProjectStatus | null | undefined;
              priorityLevel?: number | null | undefined;
              finalized?: boolean | null | undefined;
              companyMemberResponsible?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          fullName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              convertedFrom?:
                | { __typename?: 'Post'; id: string; postType?: PostType | null | undefined }
                | null
                | undefined;
            }>
          | null
          | undefined;
        ideaToActionConversion?:
          | Array<{
              __typename?: 'Action';
              id: string;
              title?: string | null | undefined;
              description?: string | null | undefined;
              targetDate?: any | null | undefined;
              actionType?: ActionType | null | undefined;
              isCompleted?: boolean | null | undefined;
              completionDate?: any | null | undefined;
              isOverdue?: boolean | null | undefined;
              updatedAt?: any | null | undefined;
              createdAt?: any | null | undefined;
              createdBycompanyMemberId?: string | null | undefined;
              localCreationType?: LocalCreationActionType | null | undefined;
              createdBy?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
              responsibles?:
                | Array<{
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
              indicator?: { __typename?: 'Indicator'; id: string } | null | undefined;
              subActions?:
                | Array<
                    | {
                        __typename?: 'Action';
                        id: string;
                        description?: string | null | undefined;
                        isCompleted?: boolean | null | undefined;
                        createdAt?: any | null | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }>
          | null
          | undefined;
        counterMeasures?:
          | Array<
              | {
                  __typename?: 'CounterMeasure';
                  id: string;
                  title?: string | null | undefined;
                  description?: string | null | undefined;
                  file?:
                    | {
                        __typename?: 'File';
                        id: string;
                        path?: string | null | undefined;
                        publicUrl?: string | null | undefined;
                        name?: string | null | undefined;
                        size?: number | null | undefined;
                        type?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        objectives?:
          | Array<
              | { __typename?: 'Objective'; id: string; description?: string | null | undefined }
              | null
              | undefined
            >
          | null
          | undefined;
        analisisWhys?:
          | Array<
              | {
                  __typename?: 'AnalysisWhy';
                  id: string;
                  description?: string | null | undefined;
                  analysis5Why?:
                    | Array<
                        | {
                            __typename?: 'Analysis5Why';
                            id: string;
                            content?: string | null | undefined;
                            rank?: number | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        analisisRootCauses?:
          | Array<
              | {
                  __typename?: 'AnalysisRootCause';
                  id: string;
                  description?: string | null | undefined;
                  comments?:
                    | Array<
                        | {
                            __typename?: 'RootCauseComment';
                            id: string;
                            content?: string | null | undefined;
                            createdAt?: any | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        followUps?:
          | Array<
              | {
                  __typename?: 'FollowUp';
                  id: string;
                  description?: string | null | undefined;
                  createdAt?: any | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        ratingSummary?:
          | {
              __typename?: 'PostRatingSummary';
              two?: number | null | undefined;
              one?: number | null | undefined;
              three?: number | null | undefined;
              four?: number | null | undefined;
              five?: number | null | undefined;
              totalRatingCount?: number | null | undefined;
              globalRating?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetAnalysisRootCausesByPostQueryVariables = Exact<{
  postId: Scalars['ID'];
}>;

export type GetAnalysisRootCausesByPostQuery = {
  __typename?: 'Query';
  allAnalysisRootCausesByPost?:
    | Array<
        | {
            __typename?: 'AnalysisRootCause';
            id: string;
            description?: string | null | undefined;
            comments?:
              | Array<
                  | {
                      __typename?: 'RootCauseComment';
                      id: string;
                      content?: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetAnalysisWhysByPostQueryVariables = Exact<{
  postId: Scalars['ID'];
}>;

export type GetAnalysisWhysByPostQuery = {
  __typename?: 'Query';
  allAnalysisWhysByPost?:
    | Array<
        | {
            __typename?: 'AnalysisWhy';
            id: string;
            description?: string | null | undefined;
            analysis5Why?:
              | Array<
                  | {
                      __typename?: 'Analysis5Why';
                      id: string;
                      content?: string | null | undefined;
                      rank?: number | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetAnalysisCompareByPostQueryVariables = Exact<{
  postId: Scalars['ID'];
}>;

export type GetAnalysisCompareByPostQuery = {
  __typename?: 'Query';
  allAnalysisCompareByPost?:
    | Array<
        | {
            __typename?: 'AnalysisCompare';
            id: string;
            subject1?: string | null | undefined;
            subject2?: string | null | undefined;
            comments?:
              | Array<
                  | {
                      __typename?: 'CompareComment';
                      id: string;
                      content?: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetObjectiveQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetObjectiveQuery = {
  __typename?: 'Query';
  Objective?:
    | { __typename?: 'Objective'; id: string; description?: string | null | undefined }
    | null
    | undefined;
};

export type GetObjectivesQueryVariables = Exact<{ [key: string]: never }>;

export type GetObjectivesQuery = {
  __typename?: 'Query';
  allObjectives?:
    | Array<
        | { __typename?: 'Objective'; id: string; description?: string | null | undefined }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetCounterMeasuresByPostQueryVariables = Exact<{
  postId: Scalars['ID'];
}>;

export type GetCounterMeasuresByPostQuery = {
  __typename?: 'Query';
  getCounterMeasuresByPost?:
    | Array<
        | {
            __typename?: 'CounterMeasure';
            id: string;
            createdAt?: any | null | undefined;
            title?: string | null | undefined;
            description?: string | null | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ActivateApplicationMutationVariables = Exact<{
  id: Scalars['ID'];
  value: Scalars['Boolean'];
}>;

export type ActivateApplicationMutation = {
  __typename?: 'Mutation';
  application: { __typename?: 'Application'; id: string; isActivated?: boolean | null | undefined };
};

export type GetAllApplicationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ApplicationFilterInput>;
}>;

export type GetAllApplicationsQuery = {
  __typename?: 'Query';
  applications: Array<{
    __typename?: 'Application';
    id: string;
    isActivated?: boolean | null | undefined;
    name?: string | null | undefined;
    shortDescription?: string | null | undefined;
    logo?:
      | {
          __typename?: 'File';
          id: string;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetApplicationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetApplicationQuery = {
  __typename?: 'Query';
  application: {
    __typename?: 'Application';
    id: string;
    companyId?: string | null | undefined;
    isActivated?: boolean | null | undefined;
    name?: string | null | undefined;
    shortDescription?: string | null | undefined;
    longDescription?: string | null | undefined;
    featureList?: Array<string> | null | undefined;
    price?: number | null | undefined;
    logo?:
      | {
          __typename?: 'File';
          id: string;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    imageList?:
      | Array<{
          __typename?: 'File';
          id: string;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetAllApplicationCategoriesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ApplicationCategoryFilterInput>;
}>;

export type GetAllApplicationCategoriesQuery = {
  __typename?: 'Query';
  applicationCategories: Array<{
    __typename?: 'ApplicationCategory';
    id: string;
    title?: string | null | undefined;
  }>;
};

export type SignUpMutationVariables = Exact<{
  firstname: Scalars['String'];
  lastname?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  invitationToken: Scalars['String'];
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  payload: {
    __typename?: 'SignupPayload';
    token?: string | null | undefined;
    success?: boolean | null | undefined;
    message?: string | null | undefined;
  };
};

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type SignInMutation = {
  __typename?: 'Mutation';
  signin: {
    __typename?: 'SigninPayload';
    token?: string | null | undefined;
    is2FA: boolean;
    noCompany: boolean;
    success?: boolean | null | undefined;
    message?: string | null | undefined;
    user: {
      __typename?: 'User';
      id: string;
      companyMembers?:
        | Array<
            | {
                __typename?: 'CompanyMember';
                id: string;
                suspended?: boolean | null | undefined;
                company?:
                  | { __typename?: 'Company'; id: string; slug?: string | null | undefined }
                  | null
                  | undefined;
              }
            | null
            | undefined
          >
        | null
        | undefined;
    };
  };
};

export type ForgotPasswordAskingMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ForgotPasswordAskingMutation = {
  __typename?: 'Mutation';
  forgotPasswordAsking: {
    __typename?: 'Success';
    success: boolean;
    message?: string | null | undefined;
    accessToken?: string | null | undefined;
  };
};

export type ForgotPasswordConfirmMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;

export type ForgotPasswordConfirmMutation = {
  __typename?: 'Mutation';
  forgotPasswordConfirm: {
    __typename?: 'Success';
    success: boolean;
    message?: string | null | undefined;
    accessToken?: string | null | undefined;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: 'Mutation';
  logout: { __typename?: 'Success'; success: boolean; message?: string | null | undefined };
};

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
}>;

export type ChangePasswordMutation = {
  __typename?: 'Mutation';
  changePassword: { __typename?: 'Success'; success: boolean; message?: string | null | undefined };
};

export type TwoFactorAuthenticationMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type TwoFactorAuthenticationMutation = {
  __typename?: 'Mutation';
  twoFactorAuthentication: {
    __typename?: 'TwoFactorAuthenticationPayload';
    token: string;
    success?: boolean | null | undefined;
    message?: string | null | undefined;
  };
};

export type Resend2FaCodeMutationVariables = Exact<{ [key: string]: never }>;

export type Resend2FaCodeMutation = {
  __typename?: 'Mutation';
  resend2FACode: { __typename?: 'Success'; success: boolean; message?: string | null | undefined };
};

export type Toggle2FaMutationVariables = Exact<{
  id: Scalars['ID'];
  active: Scalars['Boolean'];
  password: Scalars['String'];
}>;

export type Toggle2FaMutation = {
  __typename?: 'Mutation';
  toggle2FA: { __typename?: 'User'; id: string; is2FA?: boolean | null | undefined };
};

export type CreateUserFromSignUpMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
  firstname: Scalars['String'];
  lastname?: InputMaybe<Scalars['String']>;
  hiringDate?: InputMaybe<Scalars['DateTime']>;
}>;

export type CreateUserFromSignUpMutation = {
  __typename?: 'Mutation';
  createUserFromSignUp: {
    __typename?: 'Success';
    success: boolean;
    message?: string | null | undefined;
    accessToken?: string | null | undefined;
  };
};

export type SignUpInvitationMutationVariables = Exact<{
  invitationToken: Scalars['String'];
}>;

export type SignUpInvitationMutation = {
  __typename?: 'Mutation';
  payload: {
    __typename?: 'SignUpInvitationPayload';
    success: boolean;
    message?: string | null | undefined;
    isInvalidToken?: boolean | null | undefined;
    targetEmail?: string | null | undefined;
    token?: string | null | undefined;
    is2FA?: boolean | null | undefined;
  };
};

export type CreateBusinessUnitMutationVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  mission: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  romance: Scalars['String'];
}>;

export type CreateBusinessUnitMutation = {
  __typename?: 'Mutation';
  createBusinessUnit?: { __typename?: 'BusinessUnit'; id: string } | null | undefined;
};

export type UpdateBusinessUnitMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  mission?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  romance?: InputMaybe<Scalars['String']>;
}>;

export type UpdateBusinessUnitMutation = {
  __typename?: 'Mutation';
  updateBusinessUnit?:
    | {
        __typename?: 'BusinessUnit';
        id: string;
        name?: string | null | undefined;
        mission?: string | null | undefined;
        description?: string | null | undefined;
        romance?: string | null | undefined;
        email?: string | null | undefined;
        logo?: string | null | undefined;
        logoPublicUrl?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DeleteBusinessUnitMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteBusinessUnitMutation = {
  __typename?: 'Mutation';
  deleteBusinessUnit?: { __typename?: 'BusinessUnit'; id: string } | null | undefined;
};

export type AddMemberToBusinessUnitMutationVariables = Exact<{
  businessUnitId: Scalars['ID'];
  companyMemberId: Scalars['ID'];
  businessUnitRole?: InputMaybe<BusinessUnitRole>;
}>;

export type AddMemberToBusinessUnitMutation = {
  __typename?: 'Mutation';
  addMemberToBusinessUnit?:
    | {
        __typename?: 'BusinessUnit';
        id: string;
        members?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              businessUnitRole?: BusinessUnitRole | null | undefined;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type AddMembersToBusinessUnitMutationVariables = Exact<{
  businessUnitId: Scalars['ID'];
  membersAndRoles: Array<MemberAndRoleInput> | MemberAndRoleInput;
}>;

export type AddMembersToBusinessUnitMutation = {
  __typename?: 'Mutation';
  addMembersToBusinessUnit?:
    | {
        __typename?: 'BusinessUnit';
        id: string;
        members?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              businessUnitRole?: BusinessUnitRole | null | undefined;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RemoveMembersToBusinessUnitMutationVariables = Exact<{
  id: Scalars['ID'];
  memberIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type RemoveMembersToBusinessUnitMutation = {
  __typename?: 'Mutation';
  removeMembersToBusinessUnit?:
    | {
        __typename?: 'BusinessUnit';
        id: string;
        members?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              businessUnitRole?: BusinessUnitRole | null | undefined;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCompanyBusinessUnitsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<BusinessUnitFilter>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
}>;

export type GetCompanyBusinessUnitsQuery = {
  __typename?: 'Query';
  businessUnits: Array<{
    __typename?: 'BusinessUnit';
    id: string;
    logo?: string | null | undefined;
    logoPublicUrl?: string | null | undefined;
    name?: string | null | undefined;
    mission?: string | null | undefined;
    romance?: string | null | undefined;
    email?: string | null | undefined;
    description?: string | null | undefined;
    indicatorsCount?: number | null | undefined;
    members?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          businessUnitRole?: BusinessUnitRole | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  }>;
};

export type GetBusinessUnitByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetBusinessUnitByIdQuery = {
  __typename?: 'Query';
  businessUnit?:
    | {
        __typename?: 'BusinessUnit';
        id: string;
        logo?: string | null | undefined;
        logoPublicUrl?: string | null | undefined;
        name?: string | null | undefined;
        mission?: string | null | undefined;
        romance?: string | null | undefined;
        email?: string | null | undefined;
        description?: string | null | undefined;
        indicatorsCount?: number | null | undefined;
        members?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              businessUnitRole?: BusinessUnitRole | null | undefined;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        indicators?:
          | Array<{
              __typename?: 'Indicator';
              id: string;
              name?: string | null | undefined;
              value?: string | null | undefined;
              trend?: IndicatorTrend | null | undefined;
              level?: IndicatorLevel | null | undefined;
              description?: string | null | undefined;
              date?: any | null | undefined;
            }>
          | null
          | undefined;
        businessUnitImages?:
          | Array<{
              __typename?: 'File';
              id: string;
              name?: string | null | undefined;
              path?: string | null | undefined;
              publicUrl?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCommunityEventTypeInputOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCommunityEventTypeInputOptionsQuery = {
  __typename?: 'Query';
  allCommunityEventsType: Array<{
    __typename?: 'CommunityEventType';
    id: string;
    title?: string | null | undefined;
    iconPublicUrl?: string | null | undefined;
    order?: number | null | undefined;
  }>;
};

export type GetCalendarCommunityEventsQueryVariables = Exact<{
  date: Scalars['DateTime'];
  view: Scalars['String'];
  dateRange?: InputMaybe<CalendarDateRangeInput>;
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
}>;

export type GetCalendarCommunityEventsQuery = {
  __typename?: 'Query';
  calendarCommunityEvents: Array<{
    __typename?: 'CalendarEvent';
    id: string;
    type?: CalendarEventType | null | undefined;
    title?: string | null | undefined;
    start?: any | null | undefined;
    end?: any | null | undefined;
    days?: number | null | undefined;
    canIEdit?: boolean | null | undefined;
    canIDelete?: boolean | null | undefined;
    communityEventType?:
      | {
          __typename?: 'CommunityEventType';
          id: string;
          title?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
          order?: number | null | undefined;
        }
      | null
      | undefined;
    companyMemberOwner?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    community?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    calendarEventOwners?:
      | Array<{
          __typename?: 'CalendarEventOwners';
          companyMemberId: string;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    calendarEventCommunities?:
      | Array<{
          __typename?: 'CalendarEventCommunities';
          communityId: string;
          community?:
            | {
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  }>;
};

export type GetCalendarCommunityEventDetailsQueryVariables = Exact<{
  date: Scalars['DateTime'];
  view: Scalars['String'];
  communityEventTypeTitle?: InputMaybe<Scalars['String']>;
  dateRange?: InputMaybe<CalendarDateRangeInput>;
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
}>;

export type GetCalendarCommunityEventDetailsQuery = {
  __typename?: 'Query';
  calendarCommunityEventDetails: Array<{
    __typename?: 'CalendarEvent';
    id: string;
    type?: CalendarEventType | null | undefined;
    isPublic?: boolean | null | undefined;
    title?: string | null | undefined;
    description?: string | null | undefined;
    start?: any | null | undefined;
    end?: any | null | undefined;
    days?: number | null | undefined;
    canIEdit?: boolean | null | undefined;
    canIDelete?: boolean | null | undefined;
    eventPlace?: string | null | undefined;
    communityEventType?:
      | {
          __typename?: 'CommunityEventType';
          id: string;
          title?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
          order?: number | null | undefined;
        }
      | null
      | undefined;
    companyMemberOwner?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    community?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    calendarEventOwners?:
      | Array<{
          __typename?: 'CalendarEventOwners';
          companyMemberId: string;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    calendarEventCommunities?:
      | Array<{
          __typename?: 'CalendarEventCommunities';
          communityId: string;
          community?:
            | {
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    comments?:
      | Array<{
          __typename?: 'Comment';
          id: string;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isMine?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          isLikedByMe?: boolean | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          likedBy?:
            | Array<{
                __typename?: 'CompanyMember';
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      currentCompanyMember?:
                        | { __typename?: 'CompanyMember'; id: string }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    createdBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          userPreference?:
            | {
                __typename?: 'UserPreference';
                id: string;
                userTimezone?:
                  | {
                      __typename?: 'UserTimezone';
                      id: string;
                      tzCode?: string | null | undefined;
                      label?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetLocationInputOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocationInputOptionsQuery = {
  __typename?: 'Query';
  locations: Array<{
    __typename?: 'Location';
    id: string;
    title?: string | null | undefined;
    iconUrl?: string | null | undefined;
  }>;
};

export type GetLocationEventsDetailPerDayQueryVariables = Exact<{
  date: Scalars['DateTime'];
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  companyMemberOwnerId?: InputMaybe<Scalars['ID']>;
  locationId?: InputMaybe<Scalars['ID']>;
}>;

export type GetLocationEventsDetailPerDayQuery = {
  __typename?: 'Query';
  locationEventsDetailPerDay: Array<{
    __typename?: 'CalendarEvent';
    id: string;
    type?: CalendarEventType | null | undefined;
    start?: any | null | undefined;
    end?: any | null | undefined;
    canIEdit?: boolean | null | undefined;
    canIDelete?: boolean | null | undefined;
    companyMemberOwner?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    location?:
      | {
          __typename?: 'Location';
          id: string;
          title?: string | null | undefined;
          iconUrl?: string | null | undefined;
        }
      | null
      | undefined;
    comments?:
      | Array<{
          __typename?: 'Comment';
          id: string;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isMine?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          isLikedByMe?: boolean | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          likedBy?:
            | Array<{
                __typename?: 'CompanyMember';
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      currentCompanyMember?:
                        | { __typename?: 'CompanyMember'; id: string }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
  }>;
  owners: Array<{
    __typename?: 'CompanyMember';
    id: string;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetMyLocationEventByDayQueryVariables = Exact<{
  date: Scalars['DateTime'];
}>;

export type GetMyLocationEventByDayQuery = {
  __typename?: 'Query';
  myLocationEventByDay?:
    | {
        __typename?: 'CalendarEvent';
        id: string;
        start?: any | null | undefined;
        end?: any | null | undefined;
        location?:
          | {
              __typename?: 'Location';
              id: string;
              title?: string | null | undefined;
              iconUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateCalendarLocationEventMutationVariables = Exact<{
  locationId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
}>;

export type CreateCalendarLocationEventMutation = {
  __typename?: 'Mutation';
  createCalendarLocationEvent?:
    | {
        __typename?: 'CalendarEvent';
        id: string;
        start?: any | null | undefined;
        end?: any | null | undefined;
        days?: number | null | undefined;
        title?: string | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateCalendarLocationEventMutationVariables = Exact<{
  id: Scalars['ID'];
  locationId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  fromEntryTunel?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateCalendarLocationEventMutation = {
  __typename?: 'Mutation';
  updateCalendarLocationEvent?:
    | {
        __typename?: 'CalendarEvent';
        id: string;
        start?: any | null | undefined;
        end?: any | null | undefined;
        days?: number | null | undefined;
        title?: string | null | undefined;
      }
    | null
    | undefined;
};

export type DeleteCalendarEventMutationVariables = Exact<{
  id: Scalars['ID'];
  fromEntryTunel?: InputMaybe<Scalars['Boolean']>;
}>;

export type DeleteCalendarEventMutation = {
  __typename?: 'Mutation';
  deleteCalendarEvent?: { __typename?: 'CalendarEvent'; id: string } | null | undefined;
};

export type CreateCalendarCommunityEventMutationVariables = Exact<{
  communityEventTypeId: Scalars['ID'];
  title: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  eventPlace?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['ID']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  ownerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  communityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  makePublic?: InputMaybe<Scalars['Boolean']>;
  meetingId?: InputMaybe<Scalars['String']>;
}>;

export type CreateCalendarCommunityEventMutation = {
  __typename?: 'Mutation';
  createCalendarCommunityEvent?: { __typename?: 'CalendarEvent'; id: string } | null | undefined;
};

export type UpdateCalendarCommunityEventMutationVariables = Exact<{
  id: Scalars['ID'];
  communityEventTypeId: Scalars['ID'];
  title: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  eventPlace?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['ID']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  ownerIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  communityIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  makePublic?: InputMaybe<Scalars['Boolean']>;
  meetingId?: InputMaybe<Scalars['String']>;
}>;

export type UpdateCalendarCommunityEventMutation = {
  __typename?: 'Mutation';
  updateCalendarCommunityEvent?:
    | {
        __typename?: 'CalendarEvent';
        id: string;
        start?: any | null | undefined;
        end?: any | null | undefined;
        days?: number | null | undefined;
        title?: string | null | undefined;
        description?: string | null | undefined;
        eventPlace?: string | null | undefined;
        isPublic?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type GetAllCommunityEventsTypeQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCommunityEventsTypeQuery = {
  __typename?: 'Query';
  allCommunityEventsType: Array<{
    __typename?: 'CommunityEventType';
    id: string;
    title?: string | null | undefined;
    icon?: string | null | undefined;
    iconPublicUrl?: string | null | undefined;
    order?: number | null | undefined;
  }>;
};

export type GetCalendarEventsPerDayQueryVariables = Exact<{
  date: Scalars['DateTime'];
  view: Scalars['String'];
  dateRange?: InputMaybe<CalendarDateRangeInput>;
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
}>;

export type GetCalendarEventsPerDayQuery = {
  __typename?: 'Query';
  calendarEventsPerDay: {
    __typename?: 'CalendarEventPerDay';
    locations?:
      | Array<{
          __typename?: 'CalendarLocationEventsPerDay';
          day: any;
          locationId?: string | null | undefined;
          type?: CalendarEventType | null | undefined;
          isMeACompanyMemberOwner?: boolean | null | undefined;
          title?: string | null | undefined;
          iconUrl?: string | null | undefined;
          companyMemberOwners?:
            | Array<{
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    communityEvents?:
      | Array<{
          __typename?: 'CalendarEvent';
          id: string;
          type?: CalendarEventType | null | undefined;
          title?: string | null | undefined;
          start?: any | null | undefined;
          end?: any | null | undefined;
          days?: number | null | undefined;
          canIEdit?: boolean | null | undefined;
          canIDelete?: boolean | null | undefined;
          communityEventType?:
            | {
                __typename?: 'CommunityEventType';
                id: string;
                title?: string | null | undefined;
                iconPublicUrl?: string | null | undefined;
                order?: number | null | undefined;
              }
            | null
            | undefined;
          companyMemberOwner?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          community?:
            | {
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
          calendarEventOwners?:
            | Array<{
                __typename?: 'CalendarEventOwners';
                companyMemberId: string;
                companyMember?:
                  | {
                      __typename?: 'CompanyMember';
                      id: string;
                      user?:
                        | {
                            __typename?: 'User';
                            id: string;
                            fullName?: string | null | undefined;
                            photoPublicUrl?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
          calendarEventCommunities?:
            | Array<{
                __typename?: 'CalendarEventCommunities';
                communityId: string;
                community?:
                  | {
                      __typename?: 'Community';
                      id: string;
                      name?: string | null | undefined;
                      logoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetCalendarEventByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCalendarEventByIdQuery = {
  __typename?: 'Query';
  calendarEvent?:
    | {
        __typename?: 'CalendarEvent';
        id: string;
        type?: CalendarEventType | null | undefined;
        isPublic?: boolean | null | undefined;
        title?: string | null | undefined;
        description?: string | null | undefined;
        start?: any | null | undefined;
        end?: any | null | undefined;
        days?: number | null | undefined;
        eventPlace?: string | null | undefined;
        canIEdit?: boolean | null | undefined;
        canIDelete?: boolean | null | undefined;
        location?:
          | {
              __typename?: 'Location';
              id: string;
              title?: string | null | undefined;
              iconUrl?: string | null | undefined;
            }
          | null
          | undefined;
        communityEventType?:
          | {
              __typename?: 'CommunityEventType';
              id: string;
              title?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
              order?: number | null | undefined;
            }
          | null
          | undefined;
        companyMemberOwner?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        community?:
          | {
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        calendarEventOwners?:
          | Array<{
              __typename?: 'CalendarEventOwners';
              companyMemberId: string;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        calendarEventCommunities?:
          | Array<{
              __typename?: 'CalendarEventCommunities';
              communityId: string;
              community?:
                | {
                    __typename?: 'Community';
                    id: string;
                    name?: string | null | undefined;
                    logoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        comments?:
          | Array<{
              __typename?: 'Comment';
              id: string;
              content?: string | null | undefined;
              createdAt?: any | null | undefined;
              isMine?: boolean | null | undefined;
              likeCount?: number | null | undefined;
              isLikedByMe?: boolean | null | undefined;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              likedBy?:
                | Array<{
                    __typename?: 'CompanyMember';
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                          currentCompanyMember?:
                            | { __typename?: 'CompanyMember'; id: string }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
        createdBy?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              userPreference?:
                | {
                    __typename?: 'UserPreference';
                    id: string;
                    userTimezone?:
                      | {
                          __typename?: 'UserTimezone';
                          id: string;
                          tzCode?: string | null | undefined;
                          label?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetEventsByCalendarFilterHistoryQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  locationOwnerId?: InputMaybe<Scalars['ID']>;
  keyWord?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<CalendarEventType>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
  locationId?: InputMaybe<Scalars['ID']>;
  communityEventTypeId?: InputMaybe<Scalars['ID']>;
}>;

export type GetEventsByCalendarFilterHistoryQuery = {
  __typename?: 'Query';
  eventsByCalendarFilterHistory: Array<{
    __typename?: 'CalendarEvent';
    id: string;
    type?: CalendarEventType | null | undefined;
    title?: string | null | undefined;
    start?: any | null | undefined;
    end?: any | null | undefined;
    canIEdit?: boolean | null | undefined;
    canIDelete?: boolean | null | undefined;
    location?:
      | {
          __typename?: 'Location';
          id: string;
          title?: string | null | undefined;
          iconUrl?: string | null | undefined;
        }
      | null
      | undefined;
    communityEventType?:
      | {
          __typename?: 'CommunityEventType';
          id: string;
          title?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
          order?: number | null | undefined;
        }
      | null
      | undefined;
    companyMemberOwner?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                photoUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    calendarEventOwners?:
      | Array<{
          __typename?: 'CalendarEventOwners';
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    calendarEventCommunities?:
      | Array<{
          __typename?: 'CalendarEventCommunities';
          community?:
            | {
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    createdBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          userPreference?:
            | {
                __typename?: 'UserPreference';
                id: string;
                userTimezone?:
                  | {
                      __typename?: 'UserTimezone';
                      id: string;
                      tzCode?: string | null | undefined;
                      label?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
  _eventsByCalendarFilterHistoryMeta: {
    __typename?: 'EventListMetaData';
    count: number;
    locationOwners: Array<{
      __typename?: 'CompanyMember';
      id: string;
      user?:
        | {
            __typename?: 'User';
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
            fullName?: string | null | undefined;
            photoPublicUrl?: string | null | undefined;
            photoUrl?: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export type CreateCalendarFilterHistoryMutationVariables = Exact<{
  type?: InputMaybe<CalendarEventType>;
  title?: InputMaybe<Scalars['String']>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
  communityId?: InputMaybe<Scalars['String']>;
  communityFilter?: InputMaybe<CommunitiesFilter>;
  statusFilter?: InputMaybe<LeaveStatus>;
  validatorIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type CreateCalendarFilterHistoryMutation = {
  __typename?: 'Mutation';
  createCalendarFilterHistory: {
    __typename?: 'CalendarFilterHistory';
    id: string;
    type?: CalendarEventType | null | undefined;
    title?: string | null | undefined;
    dateRangeStart?: any | null | undefined;
    dateRangeEnd?: any | null | undefined;
    communityId?: string | null | undefined;
    communityFilter?: CommunitiesFilter | null | undefined;
    statusFilter?: LeaveStatus | null | undefined;
    validators?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    location?:
      | {
          __typename?: 'Location';
          id: string;
          title?: string | null | undefined;
          iconUrl?: string | null | undefined;
        }
      | null
      | undefined;
    communityEventType?:
      | {
          __typename?: 'CommunityEventType';
          id: string;
          title?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
          order?: number | null | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateCalendarFilterHistoryMutationVariables = Exact<{
  calendarFilterHistoryId: Scalars['ID'];
  isOnlyTitle?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<CalendarEventType>;
  title?: InputMaybe<Scalars['String']>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
  communityId?: InputMaybe<Scalars['String']>;
  communityFilter?: InputMaybe<CommunitiesFilter>;
  statusFilter?: InputMaybe<LeaveStatus>;
  validatorIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  locationId?: InputMaybe<Scalars['ID']>;
  communityEventTypeId?: InputMaybe<Scalars['ID']>;
}>;

export type UpdateCalendarFilterHistoryMutation = {
  __typename?: 'Mutation';
  updateCalendarFilterHistory: {
    __typename?: 'CalendarFilterHistory';
    id: string;
    type?: CalendarEventType | null | undefined;
    title?: string | null | undefined;
    dateRangeStart?: any | null | undefined;
    dateRangeEnd?: any | null | undefined;
    communityId?: string | null | undefined;
    communityFilter?: CommunitiesFilter | null | undefined;
    statusFilter?: LeaveStatus | null | undefined;
    validators?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    location?:
      | {
          __typename?: 'Location';
          id: string;
          title?: string | null | undefined;
          iconUrl?: string | null | undefined;
        }
      | null
      | undefined;
    communityEventType?:
      | {
          __typename?: 'CommunityEventType';
          id: string;
          title?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
          order?: number | null | undefined;
        }
      | null
      | undefined;
  };
};

export type DeleteCalendarFilterHistoryMutationVariables = Exact<{
  calendarFilterHistoryId: Scalars['ID'];
}>;

export type DeleteCalendarFilterHistoryMutation = {
  __typename?: 'Mutation';
  deleteCalendarFilterHistory: { __typename?: 'CalendarFilterHistory'; id: string };
};

export type GetCalendarFilterHistoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCalendarFilterHistoriesQuery = {
  __typename?: 'Query';
  calendarFilterHistories: Array<{
    __typename?: 'CalendarFilterHistory';
    id: string;
    type?: CalendarEventType | null | undefined;
    title?: string | null | undefined;
    dateRangeStart?: any | null | undefined;
    dateRangeEnd?: any | null | undefined;
    communityId?: string | null | undefined;
    communityFilter?: CommunitiesFilter | null | undefined;
    statusFilter?: LeaveStatus | null | undefined;
    validators?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    location?:
      | {
          __typename?: 'Location';
          id: string;
          title?: string | null | undefined;
          iconUrl?: string | null | undefined;
        }
      | null
      | undefined;
    communityEventType?:
      | {
          __typename?: 'CommunityEventType';
          id: string;
          title?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
          order?: number | null | undefined;
        }
      | null
      | undefined;
  }>;
  meta: { __typename?: 'ListMetadata'; count: number };
};

export type GetCalendarFilterHistoryByIdQueryVariables = Exact<{
  calendarFilterHistoryId: Scalars['ID'];
}>;

export type GetCalendarFilterHistoryByIdQuery = {
  __typename?: 'Query';
  calendarFilterHistoryById?:
    | {
        __typename?: 'CalendarFilterHistory';
        id: string;
        type?: CalendarEventType | null | undefined;
        title?: string | null | undefined;
        dateRangeStart?: any | null | undefined;
        dateRangeEnd?: any | null | undefined;
        communityId?: string | null | undefined;
        communityFilter?: CommunitiesFilter | null | undefined;
        statusFilter?: LeaveStatus | null | undefined;
        validators?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateSemiInstantCallMutationVariables = Exact<{
  type: SemiInstantCallType;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['ID']>;
  creatorId?: InputMaybe<Scalars['ID']>;
  teamId?: InputMaybe<Scalars['ID']>;
  guestIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  chatTeamName?: InputMaybe<Scalars['String']>;
}>;

export type CreateSemiInstantCallMutation = {
  __typename?: 'Mutation';
  semiInstantCall: {
    __typename?: 'SemiInstantCall';
    id: string;
    name?: string | null | undefined;
    amITheHost?: boolean | null | undefined;
    jitsiMeetUrl?: string | null | undefined;
    createdAt?: any | null | undefined;
    createdBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    guests?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type UpdateSemiInstantCallMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['ID']>;
  guestIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  teamId?: InputMaybe<Scalars['ID']>;
}>;

export type UpdateSemiInstantCallMutation = {
  __typename?: 'Mutation';
  semiInstantCall: {
    __typename?: 'SemiInstantCall';
    id: string;
    name?: string | null | undefined;
    amITheHost?: boolean | null | undefined;
    jitsiMeetUrl?: string | null | undefined;
    createdAt?: any | null | undefined;
    createdBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    guests?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type CloseSemiInstantCallMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CloseSemiInstantCallMutation = {
  __typename?: 'Mutation';
  semiInstantCall: { __typename?: 'SemiInstantCall'; id: string };
};

export type GetAllSemiInstantCallsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<SemiInstantCallFilterInput>;
}>;

export type GetAllSemiInstantCallsQuery = {
  __typename?: 'Query';
  semiInstantCalls: Array<{
    __typename?: 'SemiInstantCall';
    id: string;
    name?: string | null | undefined;
    amITheHost?: boolean | null | undefined;
    jitsiMeetUrl?: string | null | undefined;
    createdAt?: any | null | undefined;
    createdBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    guests?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  }>;
  total: { __typename?: 'ListMetadata'; count: number };
};

export type GetSemiInstantCallQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetSemiInstantCallQuery = {
  __typename?: 'Query';
  semiInstantCall?:
    | {
        __typename?: 'SemiInstantCall';
        id: string;
        name?: string | null | undefined;
        amITheHost?: boolean | null | undefined;
        description?: string | null | undefined;
        jitsiMeetUrl?: string | null | undefined;
        createdAt?: any | null | undefined;
        createdBy?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        guests?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        community?:
          | {
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MakeSeenCoinHistoriesMutationVariables = Exact<{ [key: string]: never }>;

export type MakeSeenCoinHistoriesMutation = {
  __typename?: 'Mutation';
  makeSeenCoinHistories: boolean;
};

export type AllCoinHistoriesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CoinHistoryFilter>;
}>;

export type AllCoinHistoriesQuery = {
  __typename?: 'Query';
  coinHistories: Array<{
    __typename?: 'CoinHistory';
    id: string;
    type?: CoinHistoryType | null | undefined;
    description?: string | null | undefined;
    value?: number | null | undefined;
    expSource?: number | null | undefined;
    isSeen?: boolean | null | undefined;
    createdAt?: any | null | undefined;
  }>;
  total: { __typename?: 'CoinListMetadata'; count: number; sum: number };
};

export type CommentInCalendarFragmentFragment = {
  __typename?: 'Comment';
  id: string;
  content?: string | null | undefined;
  createdAt?: any | null | undefined;
  isMine?: boolean | null | undefined;
  likeCount?: number | null | undefined;
  isLikedByMe?: boolean | null | undefined;
  companyMember?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        user?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  likedBy?:
    | Array<{
        __typename?: 'CompanyMember';
        user?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              currentCompanyMember?:
                | { __typename?: 'CompanyMember'; id: string }
                | null
                | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type CommentInPostFragmentFragment = {
  __typename?: 'Comment';
  id: string;
  content?: string | null | undefined;
  createdAt?: any | null | undefined;
  isMine?: boolean | null | undefined;
  likeCount?: number | null | undefined;
  isLikedByMe?: boolean | null | undefined;
  companyMember?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        user?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              lastName?: string | null | undefined;
              firstName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  likedBy?:
    | Array<{
        __typename?: 'CompanyMember';
        user?:
          | {
              __typename?: 'User';
              id: string;
              lastName?: string | null | undefined;
              firstName?: string | null | undefined;
              fullName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              currentCompanyMember?:
                | { __typename?: 'CompanyMember'; id: string }
                | null
                | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type CommentInActionFragmentFragment = {
  __typename?: 'Comment';
  id: string;
  content?: string | null | undefined;
  createdAt?: any | null | undefined;
  isMine?: boolean | null | undefined;
  likeCount?: number | null | undefined;
  isLikedByMe?: boolean | null | undefined;
  companyMember?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        user?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  likedBy?:
    | Array<{
        __typename?: 'CompanyMember';
        id: string;
        user?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              currentCompanyMember?:
                | { __typename?: 'CompanyMember'; id: string }
                | null
                | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type CreateCommentMutationVariables = Exact<{
  content: Scalars['String'];
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  postId?: InputMaybe<Scalars['ID']>;
  indicatorId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
  leaveId?: InputMaybe<Scalars['ID']>;
  actionId?: InputMaybe<Scalars['ID']>;
  calendarEventId?: InputMaybe<Scalars['ID']>;
  meetingId?: InputMaybe<Scalars['ID']>;
  communityEventId?: InputMaybe<Scalars['ID']>;
}>;

export type CreateCommentMutation = {
  __typename?: 'Mutation';
  createComment: { __typename?: 'Comment'; id: string; content?: string | null | undefined };
};

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  content?: InputMaybe<Scalars['String']>;
}>;

export type UpdateCommentMutation = {
  __typename?: 'Mutation';
  updateComment?:
    | { __typename?: 'Comment'; id: string; content?: string | null | undefined }
    | null
    | undefined;
};

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCommentMutation = {
  __typename?: 'Mutation';
  deleteComment?:
    | { __typename?: 'Comment'; id: string; content?: string | null | undefined }
    | null
    | undefined;
};

export type LikeCommentMutationVariables = Exact<{
  commentId: Scalars['String'];
}>;

export type LikeCommentMutation = {
  __typename?: 'Mutation';
  likeComment: {
    __typename?: 'Comment';
    id: string;
    content?: string | null | undefined;
    likeCount?: number | null | undefined;
    isLikedByMe?: boolean | null | undefined;
    likedBy?:
      | Array<{
          __typename?: 'CompanyMember';
          user?:
            | {
                __typename?: 'User';
                id: string;
                photoPublicUrl?: string | null | undefined;
                lastName?: string | null | undefined;
                firstName?: string | null | undefined;
                currentCompanyMember?:
                  | { __typename?: 'CompanyMember'; id: string }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type UnlikeCommentMutationVariables = Exact<{
  commentId: Scalars['String'];
}>;

export type UnlikeCommentMutation = {
  __typename?: 'Mutation';
  unlikeComment: {
    __typename?: 'Comment';
    id: string;
    content?: string | null | undefined;
    likeCount?: number | null | undefined;
    isLikedByMe?: boolean | null | undefined;
    likedBy?:
      | Array<{
          __typename?: 'CompanyMember';
          user?:
            | {
                __typename?: 'User';
                id: string;
                photoPublicUrl?: string | null | undefined;
                lastName?: string | null | undefined;
                firstName?: string | null | undefined;
                currentCompanyMember?:
                  | { __typename?: 'CompanyMember'; id: string }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type SimpleCommunityInfoFragment = {
  __typename?: 'Community';
  id: string;
  name?: string | null | undefined;
  logo?: string | null | undefined;
  slug?: string | null | undefined;
  logoPublicUrl?: string | null | undefined;
};

export type CommunityFragment = {
  __typename?: 'Community';
  id: string;
  name?: string | null | undefined;
  slug?: string | null | undefined;
  logo?: string | null | undefined;
  logoPublicUrl?: string | null | undefined;
  mission?: string | null | undefined;
  rommance?: string | null | undefined;
};

export type CommunityEventTypeInfoFragment = {
  __typename?: 'CommunityEventType';
  id: string;
  title?: string | null | undefined;
  icon?: string | null | undefined;
  iconPublicUrl?: string | null | undefined;
};

export type MapCommunityInfoFragment = {
  __typename?: 'Community';
  id: string;
  name?: string | null | undefined;
  mission?: string | null | undefined;
  memberCount?: number | null | undefined;
  coins?: number | null | undefined;
};

export type SubActionInfoFragment = {
  __typename?: 'Action';
  id: string;
  description?: string | null | undefined;
  isCompleted?: boolean | null | undefined;
  createdAt?: any | null | undefined;
};

export type CommunityMembersUserInfoFragment = {
  __typename?: 'User';
  id: string;
  fullName?: string | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  photoUrl?: string | null | undefined;
  photoPublicUrl?: string | null | undefined;
  email?: string | null | undefined;
};

export type ActionInfoFragment = {
  __typename?: 'Action';
  id: string;
  title?: string | null | undefined;
  description?: string | null | undefined;
  targetDate?: any | null | undefined;
  actionType?: ActionType | null | undefined;
  isCompleted?: boolean | null | undefined;
  completionDate?: any | null | undefined;
  isOverdue?: boolean | null | undefined;
  updatedAt?: any | null | undefined;
  createdAt?: any | null | undefined;
  createdBycompanyMemberId?: string | null | undefined;
  localCreationType?: LocalCreationActionType | null | undefined;
};

export type AddCompanyMemberToCommunityMutationVariables = Exact<{
  communityId: Scalars['ID'];
  role: CommunityRole;
  companyMemberId: Scalars['ID'];
}>;

export type AddCompanyMemberToCommunityMutation = {
  __typename?: 'Mutation';
  addCompanyMemberToCommunity: { __typename?: 'Community'; id: string };
};

export type UpdateCommunityMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  mission?: InputMaybe<Scalars['String']>;
  rommance?: InputMaybe<Scalars['String']>;
}>;

export type UpdateCommunityMutation = {
  __typename?: 'Mutation';
  updateCommunity: {
    __typename?: 'Community';
    id: string;
    name?: string | null | undefined;
    slug?: string | null | undefined;
    logo?: string | null | undefined;
    logoPublicUrl?: string | null | undefined;
    mission?: string | null | undefined;
    rommance?: string | null | undefined;
  };
};

export type DeleteActionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteActionMutation = {
  __typename?: 'Mutation';
  deleteAction: { __typename?: 'Action'; id: string };
};

export type CreateActionMutationVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  targetDate?: InputMaybe<Scalars['Date']>;
  actionType?: InputMaybe<ActionType>;
  communityId?: InputMaybe<Scalars['ID']>;
  companyId?: InputMaybe<Scalars['ID']>;
  businessUnitId?: InputMaybe<Scalars['ID']>;
  indicatorId?: InputMaybe<Scalars['ID']>;
  roadBlockId?: InputMaybe<Scalars['ID']>;
  responsibleIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  convertedFromIdeaId?: InputMaybe<Scalars['ID']>;
  parentActionId?: InputMaybe<Scalars['ID']>;
  postId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
  localCreationType: LocalCreationActionType;
  oneToOneSubordonateId?: InputMaybe<Scalars['ID']>;
}>;

export type DeleteActionToProjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type CreateActionMutation = {
  __typename?: 'Mutation';
  createAction: { __typename?: 'Action'; id: string };
};
export type DeleteActionToProjectMutation = {
  __typename?: 'Mutation';
  deleteProjectAction: {
    __typename?: 'Action';
    title: string;
    description: string;
    postId: string;
    project?: Project | null | undefined;
  };
};

export type UpdateActionMutationVariables = Exact<{
  id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  targetDate?: InputMaybe<Scalars['Date']>;
  actionType?: InputMaybe<ActionType>;
  isCompleted?: InputMaybe<Scalars['Boolean']>;
  responsibleIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  title?: InputMaybe<Scalars['String']>;
}>;

export type UpdateProjectActionMutationVariables = Exact<{
  id: Scalars['ID'];
  projectId?: InputMaybe<Scalars['String']>;
}>;

export type UpdateActionMutation = {
  __typename?: 'Mutation';
  updateAction: {
    __typename?: 'Action';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    targetDate?: any | null | undefined;
    actionType?: ActionType | null | undefined;
    isCompleted?: boolean | null | undefined;
    completionDate?: any | null | undefined;
    isOverdue?: boolean | null | undefined;
    updatedAt?: any | null | undefined;
    createdAt?: any | null | undefined;
    createdBycompanyMemberId?: string | null | undefined;
    localCreationType?: LocalCreationActionType | null | undefined;
    responsibles?: Array<{ __typename?: 'CompanyMember'; id: string }> | null | undefined;
  };
};

export type UpdateProjectActionMutation = {
  __typename?: 'Mutation';
  updateProjectAction: {
    __typename?: 'Action';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    targetDate?: any | null | undefined;
    actionType?: ActionType | null | undefined;
    isCompleted?: boolean | null | undefined;
    completionDate?: any | null | undefined;
    isOverdue?: boolean | null | undefined;
    updatedAt?: any | null | undefined;
    createdAt?: any | null | undefined;
    createdBycompanyMemberId?: string | null | undefined;
    localCreationType?: LocalCreationActionType | null | undefined;
    responsibles?: Array<{ __typename?: 'CompanyMember'; id: string }> | null | undefined;
    project?: { __typename?: 'Project'; id: string } | null | undefined;
  };
};

export type DeleteProjectActionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteProjectActionMutation = {
  __typename?: 'Mutation';
  deleteProjectAction: {
    __typename?: 'Action';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    postId?: string | null | undefined;
  };
};

export type CheckedActionMutationVariables = Exact<{
  actionId: Scalars['ID'];
  isCompleted: Scalars['Boolean'];
}>;

export type CheckedActionMutation = {
  __typename?: 'Mutation';
  checkedAction: { __typename?: 'Action'; id: string; isCompleted?: boolean | null | undefined };
};

export type DeleteCommunityMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCommunityMutation = {
  __typename?: 'Mutation';
  deleteCommunity: { __typename?: 'Community'; id: string };
};

export type CreateCommunityMutationVariables = Exact<{
  name: Scalars['String'];
  companyId: Scalars['ID'];
  mission?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  rommance?: InputMaybe<Scalars['String']>;
}>;

export type CreateCommunityMutation = {
  __typename?: 'Mutation';
  createCommunity: {
    __typename?: 'Community';
    id: string;
    name?: string | null | undefined;
    mission?: string | null | undefined;
    logo?: string | null | undefined;
    rommance?: string | null | undefined;
  };
};

export type RemoveCompanyMemberToCommunityMutationVariables = Exact<{
  userCommunityId: Scalars['ID'];
}>;

export type RemoveCompanyMemberToCommunityMutation = {
  __typename?: 'Mutation';
  removeCompanyMemberToCommunity: { __typename?: 'UserCommunity'; id: string };
};

export type UpdateTeamMemberRoleMutationVariables = Exact<{
  userCommunityId: Scalars['ID'];
  role: CommunityRole;
}>;

export type UpdateTeamMemberRoleMutation = {
  __typename?: 'Mutation';
  updateTeamMemberRole: {
    __typename?: 'UserCommunity';
    id: string;
    role?: CommunityRole | null | undefined;
  };
};

export type CreateCommunityArchiveMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
}>;

export type CreateCommunityArchiveMutation = {
  __typename?: 'Mutation';
  createCommunityArchive: {
    __typename?: 'Community';
    id: string;
    archives?:
      | Array<{
          __typename?: 'File';
          id: string;
          path?: string | null | undefined;
          publicUrl?: string | null | undefined;
          createdAt?: any | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type RemoveCommunityInPendingInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
  communityId: Scalars['ID'];
}>;

export type RemoveCommunityInPendingInvitationMutation = {
  __typename?: 'Mutation';
  removeCommunityInPendingInvitation: {
    __typename?: 'UserCompanyInvitation';
    id: string;
    pendingTeamsIds?: Array<string> | null | undefined;
  };
};

export type UpdateCommunityRoleInPendingInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
  value: CommunityRole;
}>;

export type UpdateCommunityRoleInPendingInvitationMutation = {
  __typename?: 'Mutation';
  updateCommunityRoleInPendingInvitation: {
    __typename?: 'UserCompanyInvitation';
    id: string;
    communityMemberRole?: CommunityRole | null | undefined;
  };
};

export type GetMyCommunityQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyCommunityQuery = {
  __typename?: 'Query';
  me?:
    | {
        __typename?: 'User';
        id: string;
        myCommunity?:
          | {
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              slug?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
              mission?: string | null | undefined;
              rommance?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCommunityQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type GetCommunityQuery = {
  __typename?: 'Query';
  Community: {
    __typename?: 'Community';
    id: string;
    mission?: string | null | undefined;
    rommance?: string | null | undefined;
    memberCount?: number | null | undefined;
    memberRequestCount?: number | null | undefined;
    members?:
      | Array<{
          __typename?: 'UserCommunity';
          id: string;
          companyMemberId?: string | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                fullName?: string | null | undefined;
                lastName?: string | null | undefined;
                email?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                currentMotto?:
                  | { __typename?: 'Motto'; id: string; content: string }
                  | null
                  | undefined;
                currentProfessionnalWeather?:
                  | {
                      __typename?: 'ProfessionnalWeather';
                      id: string;
                      title?: string | null | undefined;
                      icon?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type CommunityQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type CommunityQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    name?: string | null | undefined;
    logo?: string | null | undefined;
    slug?: string | null | undefined;
    logoPublicUrl?: string | null | undefined;
  };
};

export type GetCommunityActionsQueryVariables = Exact<{
  id: Scalars['ID'];
  orderBy?: InputMaybe<Scalars['String']>;
}>;

export type GetCommunityActionsQuery = {
  __typename?: 'Query';
  Community: {
    __typename?: 'Community';
    id: string;
    actions?:
      | Array<{
          __typename?: 'Action';
          id: string;
          title?: string | null | undefined;
          description?: string | null | undefined;
          targetDate?: any | null | undefined;
          actionType?: ActionType | null | undefined;
          isCompleted?: boolean | null | undefined;
          completionDate?: any | null | undefined;
          isOverdue?: boolean | null | undefined;
          updatedAt?: any | null | undefined;
          createdAt?: any | null | undefined;
          createdBycompanyMemberId?: string | null | undefined;
          localCreationType?: LocalCreationActionType | null | undefined;
          responsibles?:
            | Array<{
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      email?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
          indicator?: { __typename?: 'Indicator'; id: string } | null | undefined;
          createdByCompanyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      email?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          post?: { __typename?: 'Post'; id: string } | null | undefined;
          project?: { __typename?: 'Project'; id: string } | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type CurrentCommunityQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentCommunityQuery = {
  __typename?: 'Query';
  currentCommunity: {
    __typename?: 'Community';
    id: string;
    name?: string | null | undefined;
    slug?: string | null | undefined;
    logo?: string | null | undefined;
    logoPublicUrl?: string | null | undefined;
  };
};

export type GetActionsByPostIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetActionsByPostIdQuery = {
  __typename?: 'Query';
  getActionsByPostId?:
    | Array<{
        __typename?: 'Action';
        id: string;
        title?: string | null | undefined;
        description?: string | null | undefined;
        targetDate?: any | null | undefined;
        isCompleted?: boolean | null | undefined;
        createdAt?: any | null | undefined;
        responsibles?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    email?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type GetAllActionsQueryVariables = Exact<{
  postId?: InputMaybe<Scalars['ID']>;
  projectId?: InputMaybe<Scalars['ID']>;
  filter?: InputMaybe<ActionFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  targedDate?: InputMaybe<DateRange>;
}>;

export type GetAllActionsQuery = {
  __typename?: 'Query';
  actions: Array<{
    __typename?: 'Action';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    targetDate?: any | null | undefined;
    actionType?: ActionType | null | undefined;
    isCompleted?: boolean | null | undefined;
    completionDate?: any | null | undefined;
    isOverdue?: boolean | null | undefined;
    updatedAt?: any | null | undefined;
    createdAt?: any | null | undefined;
    createdBycompanyMemberId?: string | null | undefined;
    localCreationType?: LocalCreationActionType | null | undefined;
    createdBy?:
      | {
          __typename?: 'User';
          id: string;
          firstName?: string | null | undefined;
          fullName?: string | null | undefined;
          lastName?: string | null | undefined;
          email?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    createdByCompanyMember?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                fullName?: string | null | undefined;
                lastName?: string | null | undefined;
                email?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    responsibles?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                fullName?: string | null | undefined;
                lastName?: string | null | undefined;
                email?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    indicator?: { __typename?: 'Indicator'; id: string } | null | undefined;
    community?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          slug?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
          mission?: string | null | undefined;
          rommance?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
  total: { __typename?: 'ListMetadata'; count: number };
};

export type GetCommunityMembersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCommunityMembersQuery = {
  __typename?: 'Query';
  Community: {
    __typename?: 'Community';
    id: string;
    name?: string | null | undefined;
    members?:
      | Array<{
          __typename?: 'UserCommunity';
          id: string;
          role?: CommunityRole | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                currentProfessionnalWeather?:
                  | {
                      __typename?: 'ProfessionnalWeather';
                      id: string;
                      title?: string | null | undefined;
                      icon?: string | null | undefined;
                    }
                  | null
                  | undefined;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      email?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetCommunityByCompanyIdQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<CommunityFilter>;
}>;

export type GetCommunityByCompanyIdQuery = {
  __typename?: 'Query';
  getCommunityByCompanyId?:
    | Array<{
        __typename?: 'Community';
        id: string;
        name?: string | null | undefined;
        slug?: string | null | undefined;
        logo?: string | null | undefined;
        logoPublicUrl?: string | null | undefined;
        mission?: string | null | undefined;
        rommance?: string | null | undefined;
        indicators?:
          | Array<{
              __typename?: 'Indicator';
              id: string;
              name?: string | null | undefined;
              value?: string | null | undefined;
              level?: IndicatorLevel | null | undefined;
              description?: string | null | undefined;
              date?: any | null | undefined;
              trend?: IndicatorTrend | null | undefined;
              createdAt?: any | null | undefined;
            }>
          | null
          | undefined;
        problems?:
          | Array<{
              __typename?: 'Post';
              id: string;
              title?: string | null | undefined;
              who?: string | null | undefined;
              what?: string | null | undefined;
              how?: string | null | undefined;
              where?: string | null | undefined;
              when?: string | null | undefined;
              impact?: string | null | undefined;
              problemLevel?: number | null | undefined;
              demand?: Demand_Type | null | undefined;
              createdAt?: any | null | undefined;
              problemCategory?:
                | { __typename?: 'ProblemCategory'; title?: string | null | undefined }
                | null
                | undefined;
              communities?: Array<{ __typename?: 'Community'; id: string }> | null | undefined;
              experts?:
                | Array<{ __typename?: 'User'; id: string } | null | undefined>
                | null
                | undefined;
              companyMemberExperts?:
                | Array<{ __typename?: 'CompanyMember'; id: string }>
                | null
                | undefined;
            }>
          | null
          | undefined;
        actions?:
          | Array<{
              __typename?: 'Action';
              id: string;
              description?: string | null | undefined;
              targetDate?: any | null | undefined;
              actionType?: ActionType | null | undefined;
              createdAt?: any | null | undefined;
              responsibles?: Array<{ __typename?: 'CompanyMember'; id: string }> | null | undefined;
            }>
          | null
          | undefined;
        projects?: Array<{ __typename?: 'Project'; id: string }> | null | undefined;
        members?:
          | Array<{
              __typename?: 'UserCommunity';
              id: string;
              companyMemberId?: string | null | undefined;
              role?: CommunityRole | null | undefined;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    currentProfessionnalWeather?:
                      | {
                          __typename?: 'ProfessionnalWeather';
                          id: string;
                          title?: string | null | undefined;
                          icon?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                          email?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }>
    | null
    | undefined;
  currentWholeCompany: { __typename?: 'Community'; id: string; slug?: string | null | undefined };
};

export type GetMembersByCommunityIdQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type GetMembersByCommunityIdQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    name?: string | null | undefined;
    members?:
      | Array<{
          __typename?: 'UserCommunity';
          id: string;
          role?: CommunityRole | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
                currentProfessionnalWeather?:
                  | {
                      __typename?: 'ProfessionnalWeather';
                      id: string;
                      title?: string | null | undefined;
                      icon?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetCommunityArchivesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCommunityArchivesQuery = {
  __typename?: 'Query';
  community: {
    __typename?: 'Community';
    id: string;
    archives?:
      | Array<{
          __typename?: 'File';
          id: string;
          name?: string | null | undefined;
          path?: string | null | undefined;
          publicUrl?: string | null | undefined;
          createdAt?: any | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetCommunityMembersRoleBySelectedCommunityQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;

export type GetCommunityMembersRoleBySelectedCommunityQuery = {
  __typename?: 'Query';
  communityRole: CommunityRole;
};

export type GetCommunitiesByCompanyMemberQueryVariables = Exact<{
  companyMemberId?: InputMaybe<Scalars['ID']>;
  orderBy?: InputMaybe<Scalars['String']>;
}>;

export type GetCommunitiesByCompanyMemberQuery = {
  __typename?: 'Query';
  communitiesByCompanyMember: {
    __typename?: 'CommunitiesByCompanyMember';
    myCommunities: Array<{
      __typename?: 'Community';
      id: string;
      name?: string | null | undefined;
      logoPublicUrl?: string | null | undefined;
    }>;
    otherCommunities: Array<{
      __typename?: 'Community';
      id: string;
      name?: string | null | undefined;
      logoPublicUrl?: string | null | undefined;
    }>;
  };
  companyMember?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        role?: MemberRole | null | undefined;
        userAccess?: MainRole | null | undefined;
        user?:
          | { __typename?: 'User'; id: string; fullName?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetActionByIdQueryVariables = Exact<{
  actionId: Scalars['ID'];
}>;

export type GetActionByIdQuery = {
  __typename?: 'Query';
  action: {
    __typename?: 'Action';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    targetDate?: any | null | undefined;
    actionType?: ActionType | null | undefined;
    isCompleted?: boolean | null | undefined;
    completionDate?: any | null | undefined;
    isOverdue?: boolean | null | undefined;
    updatedAt?: any | null | undefined;
    createdAt?: any | null | undefined;
    createdBycompanyMemberId?: string | null | undefined;
    localCreationType?: LocalCreationActionType | null | undefined;
    createdBy?:
      | {
          __typename?: 'User';
          id: string;
          firstName?: string | null | undefined;
          fullName?: string | null | undefined;
          lastName?: string | null | undefined;
          email?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    createdByCompanyMember?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                fullName?: string | null | undefined;
                lastName?: string | null | undefined;
                email?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    responsibles?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                fullName?: string | null | undefined;
                lastName?: string | null | undefined;
                email?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    indicator?: { __typename?: 'Indicator'; id: string } | null | undefined;
    community?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          slug?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
          mission?: string | null | undefined;
          rommance?: string | null | undefined;
        }
      | null
      | undefined;
    comments?:
      | Array<{
          __typename?: 'Comment';
          id: string;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isMine?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          isLikedByMe?: boolean | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          likedBy?:
            | Array<{
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      currentCompanyMember?:
                        | { __typename?: 'CompanyMember'; id: string }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    project?: { __typename?: 'Project'; id: string } | null | undefined;
  };
};

export type UpdateMyTotalCoinsMutationVariables = Exact<{
  cost?: InputMaybe<Scalars['Int']>;
}>;

export type UpdateMyTotalCoinsMutation = { __typename?: 'Mutation'; updateMyTotalCoins: number };

export type SwitchCompanyMutationVariables = Exact<{
  newSlug: Scalars['String'];
  timezone: UserTimezoneInput;
}>;

export type SwitchCompanyMutation = {
  __typename?: 'Mutation';
  payload: {
    __typename?: 'SwitchCompanyPayload';
    streamToken: string;
    success?: boolean | null | undefined;
    message?: string | null | undefined;
    companyMember: {
      __typename?: 'CompanyMember';
      id: string;
      user?:
        | {
            __typename?: 'User';
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
            email?: string | null | undefined;
            photoUrl?: string | null | undefined;
          }
        | null
        | undefined;
      company?:
        | { __typename?: 'Company'; id: string; slug?: string | null | undefined }
        | null
        | undefined;
      userPreference?:
        | {
            __typename?: 'UserPreference';
            id: string;
            enableShowEntryTunnel?: boolean | null | undefined;
            userTimezone?:
              | { __typename?: 'UserTimezone'; id: string; tzCode?: string | null | undefined }
              | null
              | undefined;
            language?: { __typename?: 'Language'; id: string; key: string } | null | undefined;
          }
        | null
        | undefined;
    };
  };
};

export type UserInvitationMutationVariables = Exact<{
  targetEmail: Scalars['String'];
  pendingTeamsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  hiringDate?: InputMaybe<Scalars['DateTime']>;
  role?: InputMaybe<MemberRole>;
  userAccess?: InputMaybe<MainRole>;
}>;

export type UserInvitationMutation = {
  __typename?: 'Mutation';
  userInvitation: {
    __typename?: 'Success';
    success: boolean;
    message?: string | null | undefined;
    accessToken?: string | null | undefined;
  };
};

export type ResendInvitationMutationVariables = Exact<{
  targetEmail: Scalars['String'];
  token: Scalars['String'];
}>;

export type ResendInvitationMutation = {
  __typename?: 'Mutation';
  resendInvitation: {
    __typename?: 'Success';
    success: boolean;
    message?: string | null | undefined;
    accessToken?: string | null | undefined;
  };
};

export type DeletePendingInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeletePendingInvitationMutation = {
  __typename?: 'Mutation';
  deletePendingInvitation: { __typename?: 'UserCompanyInvitation'; id: string };
};

export type UpdateProfileMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  personality?: InputMaybe<Scalars['String']>;
}>;

export type UpdateProfileMutation = {
  __typename?: 'Mutation';
  updateProfile: {
    __typename?: 'CompanyMember';
    id: string;
    jobTitle?: string | null | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          personality?: UserPersonality | null | undefined;
        }
      | null
      | undefined;
  };
};

export type ChangeSupervisorMutationVariables = Exact<{
  companyMemberId: Scalars['ID'];
  supervisorId?: InputMaybe<Scalars['ID']>;
}>;

export type ChangeSupervisorMutation = {
  __typename?: 'Mutation';
  changeSupervisor: {
    __typename?: 'CompanyMember';
    id: string;
    supervisors?: Array<{ __typename?: 'CompanyMember'; id: string }> | null | undefined;
  };
};

export type UpdateCompanyMemberMutationVariables = Exact<{
  id: Scalars['ID'];
  role?: InputMaybe<MemberRole>;
  userAccess?: InputMaybe<MainRole>;
  hiringDate?: InputMaybe<Scalars['DateTime']>;
  businessUnitId?: InputMaybe<Scalars['String']>;
  suspended?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateCompanyMemberMutation = {
  __typename?: 'Mutation';
  updateCompanyMember: {
    __typename?: 'CompanyMember';
    id: string;
    role?: MemberRole | null | undefined;
    userAccess?: MainRole | null | undefined;
    hiringDate?: any | null | undefined;
    suspended?: boolean | null | undefined;
  };
};

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany?:
    | {
        __typename?: 'Company';
        id: string;
        description?: string | null | undefined;
        email?: string | null | undefined;
        logo?: string | null | undefined;
        name?: string | null | undefined;
        romance?: string | null | undefined;
        leaveWeekendsCount?: number | null | undefined;
      }
    | null
    | undefined;
};

export type UpdateInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
  companyMemberRole?: InputMaybe<MemberRole>;
  companyMemberAccess?: InputMaybe<MainRole>;
  communityMemberRole?: InputMaybe<CommunityRole>;
  pendingTeamsIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  hiringDate?: InputMaybe<Scalars['DateTime']>;
}>;

export type UpdateInvitationMutation = {
  __typename?: 'Mutation';
  updateInvitation: {
    __typename?: 'UserCompanyInvitation';
    id: string;
    companyMemberRole?: MemberRole | null | undefined;
    companyMemberAccess?: MainRole | null | undefined;
    pendingTeamsIds?: Array<string> | null | undefined;
    communityMemberRole?: CommunityRole | null | undefined;
    hiringDate?: any | null | undefined;
  };
};

export type SetCurrentImageMutationVariables = Exact<{
  path?: InputMaybe<Scalars['String']>;
}>;

export type SetCurrentImageMutation = {
  __typename?: 'Mutation';
  setCurrentImage: {
    __typename?: 'Company';
    id: string;
    currentImagePath?: string | null | undefined;
  };
};

export type UpdateCeoWordMutationVariables = Exact<{
  ceoWord: Scalars['String'];
}>;

export type UpdateCeoWordMutation = {
  __typename?: 'Mutation';
  updateCeoWord?:
    | { __typename?: 'Company'; id: string; ceoWord?: string | null | undefined }
    | null
    | undefined;
};

export type SetEnableShowEntryTunnelValueMutationVariables = Exact<{
  value: Scalars['Boolean'];
}>;

export type SetEnableShowEntryTunnelValueMutation = {
  __typename?: 'Mutation';
  setEnableShowEntryTunnelValue: {
    __typename?: 'UserPreference';
    id: string;
    enableShowEntryTunnel?: boolean | null | undefined;
  };
};

export type SetEnableShowExitTunnelValueMutationVariables = Exact<{
  value: Scalars['Boolean'];
}>;

export type SetEnableShowExitTunnelValueMutation = {
  __typename?: 'Mutation';
  setEnableShowExitTunnelValue: {
    __typename?: 'UserPreference';
    id: string;
    enableShowExitTunnel?: boolean | null | undefined;
  };
};

export type SetEntryTunnelTimeMutationVariables = Exact<{
  time?: InputMaybe<Scalars['DateTime']>;
}>;

export type SetEntryTunnelTimeMutation = {
  __typename?: 'Mutation';
  setEntryTunnelTime: {
    __typename?: 'UserPreference';
    id: string;
    showEntryTunnelTime?: any | null | undefined;
  };
};

export type SetExitTunnelTimeMutationVariables = Exact<{
  time?: InputMaybe<Scalars['DateTime']>;
}>;

export type SetExitTunnelTimeMutation = {
  __typename?: 'Mutation';
  setExitTunnelTime: {
    __typename?: 'UserPreference';
    id: string;
    showExitTunnelTime?: any | null | undefined;
  };
};

export type SetCompanyDirectorMutationVariables = Exact<{
  directorId: Scalars['String'];
}>;

export type SetCompanyDirectorMutation = {
  __typename?: 'Mutation';
  setDirector: { __typename?: 'Company'; id: string };
};

export type SetEnableActiveHoursValueMutationVariables = Exact<{
  value: Scalars['Boolean'];
}>;

export type SetEnableActiveHoursValueMutation = {
  __typename?: 'Mutation';
  setEnableActiveHoursValue?:
    | {
        __typename?: 'UserPreference';
        id: string;
        enableProfessionalStatusActiveHours?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type SetActiveHoursTimeStartMutationVariables = Exact<{
  time?: InputMaybe<Scalars['DateTime']>;
}>;

export type SetActiveHoursTimeStartMutation = {
  __typename?: 'Mutation';
  setActiveHoursTimeStart?:
    | {
        __typename?: 'UserPreference';
        id: string;
        professionalStatusActiveHoursTimeStart?: any | null | undefined;
      }
    | null
    | undefined;
};

export type SetActiveHoursTimeEndMutationVariables = Exact<{
  time?: InputMaybe<Scalars['DateTime']>;
}>;

export type SetActiveHoursTimeEndMutation = {
  __typename?: 'Mutation';
  setActiveHoursTimeEnd?:
    | {
        __typename?: 'UserPreference';
        professionalStatusActiveHoursTimeEnd?: any | null | undefined;
        id: string;
      }
    | null
    | undefined;
};

export type NewArrivalCoworkersQueryVariables = Exact<{ [key: string]: never }>;

export type NewArrivalCoworkersQuery = {
  __typename?: 'Query';
  newArrivalCoworkers: Array<{
    __typename?: 'CompanyMember';
    id: string;
    jobTitle?: string | null | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
          bio?: string | null | undefined;
          communities?:
            | Array<{
                __typename?: 'UserCommunity';
                community?:
                  | {
                      __typename?: 'Community';
                      id: string;
                      logo?: string | null | undefined;
                      logoPublicUrl?: string | null | undefined;
                      name?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
          skills?: Array<{ __typename?: 'Skill'; id: string; title: string }> | null | undefined;
          successes?:
            | Array<{ __typename?: 'UserSuccess'; id: string; title: string }>
            | null
            | undefined;
          passions?:
            | Array<{ __typename?: 'Passion'; id: string; title: string }>
            | null
            | undefined;
          previousCompanies?:
            | Array<{ __typename?: 'PreviousCompany'; id: string; name: string }>
            | null
            | undefined;
          pictures?:
            | Array<{
                __typename?: 'UserPicture';
                id: string;
                imageUrl: string;
                imagePublicUrl?: string | null | undefined;
                title: string;
              }>
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetCurrentCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentCompanyQuery = {
  __typename?: 'Query';
  currentCompany: {
    __typename?: 'Company';
    id: string;
    description?: string | null | undefined;
    email?: string | null | undefined;
    logo?: string | null | undefined;
    logoPublicUrl?: string | null | undefined;
    name?: string | null | undefined;
    romance?: string | null | undefined;
    slug?: string | null | undefined;
    mission?: string | null | undefined;
    leaveWeekendsCount?: number | null | undefined;
    removed?: boolean | null | undefined;
    communities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          mission?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetCurrentCompanyIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentCompanyIdQuery = {
  __typename?: 'Query';
  currentCompany: { __typename?: 'Company'; id: string };
  currentWholeCompany: {
    __typename?: 'Community';
    id: string;
    slug?: string | null | undefined;
    name?: string | null | undefined;
    logo?: string | null | undefined;
    logoPublicUrl?: string | null | undefined;
  };
};

export type GetCompanyCommunitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyCommunitiesQuery = {
  __typename?: 'Query';
  currentCompany: {
    __typename?: 'Company';
    id: string;
    communities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          slug?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
          mission?: string | null | undefined;
          rommance?: string | null | undefined;
          members?:
            | Array<{
                __typename?: 'UserCommunity';
                id: string;
                companyMemberId?: string | null | undefined;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetCompanyCommunitiesWithCmQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyCommunitiesWithCmQuery = {
  __typename?: 'Query';
  currentCompany: {
    __typename?: 'Company';
    id: string;
    communities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          slug?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
          mission?: string | null | undefined;
          rommance?: string | null | undefined;
          members?:
            | Array<{
                __typename?: 'UserCommunity';
                id: string;
                companyMemberId?: string | null | undefined;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      email?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
                companyMember?:
                  | {
                      __typename?: 'CompanyMember';
                      id: string;
                      user?:
                        | {
                            __typename?: 'User';
                            id: string;
                            fullName?: string | null | undefined;
                            firstName?: string | null | undefined;
                            lastName?: string | null | undefined;
                            email?: string | null | undefined;
                            photoUrl?: string | null | undefined;
                            photoPublicUrl?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetCompanyMemberByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyMemberByIdQuery = {
  __typename?: 'Query';
  getCompanyMemberById?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        isManager?: boolean | null | undefined;
        user?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              email?: string | null | undefined;
              myCommunity?: { __typename?: 'Community'; id: string } | null | undefined;
            }
          | null
          | undefined;
        roadBlocks?:
          | Array<
              | {
                  __typename?: 'RoadBlock';
                  id: string;
                  roadBlockId?: string | null | undefined;
                  description?: string | null | undefined;
                  createdAt?: any | null | undefined;
                  actions?:
                    | Array<{
                        __typename?: 'Action';
                        id: string;
                        title?: string | null | undefined;
                        description?: string | null | undefined;
                        targetDate?: any | null | undefined;
                        actionType?: ActionType | null | undefined;
                        isCompleted?: boolean | null | undefined;
                        completionDate?: any | null | undefined;
                        isOverdue?: boolean | null | undefined;
                        updatedAt?: any | null | undefined;
                        createdAt?: any | null | undefined;
                        createdBycompanyMemberId?: string | null | undefined;
                        localCreationType?: LocalCreationActionType | null | undefined;
                        responsibles?:
                          | Array<{
                              __typename?: 'CompanyMember';
                              id: string;
                              user?:
                                | {
                                    __typename?: 'User';
                                    fullName?: string | null | undefined;
                                    firstName?: string | null | undefined;
                                    lastName?: string | null | undefined;
                                    photoUrl?: string | null | undefined;
                                    photoPublicUrl?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }>
                          | null
                          | undefined;
                      }>
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        currentProfessionnalWeather?:
          | {
              __typename?: 'ProfessionnalWeather';
              id: string;
              icon?: string | null | undefined;
              title?: string | null | undefined;
            }
          | null
          | undefined;
        currentMotto?: { __typename?: 'Motto'; id: string; content: string } | null | undefined;
        myCommunities?: Array<{ __typename?: 'Community'; id: string }> | null | undefined;
        nPlusOne?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CoworkersMemberQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  withoutMe?: InputMaybe<Scalars['Boolean']>;
  filterByWeatherId?: InputMaybe<Scalars['ID']>;
  filterByCommunityId?: InputMaybe<Scalars['ID']>;
  filterByEventTypeId?: InputMaybe<Scalars['ID']>;
  queryString?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  cacheKey?: InputMaybe<Scalars['String']>;
}>;

export type CoworkersMemberQuery = {
  __typename?: 'Query';
  coworkersMember: Array<{
    __typename?: 'CompanyMember';
    id: string;
    jobTitle?: string | null | undefined;
    isItMyDay?: boolean | null | undefined;
    removed?: boolean | null | undefined;
    dayOff?: boolean | null | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
          isItMyDay?: boolean | null | undefined;
          email?: string | null | undefined;
          isOnline?: boolean | null | undefined;
          removed?: boolean | null | undefined;
          suspended?: boolean | null | undefined;
          companyMembers?:
            | Array<{ __typename?: 'CompanyMember'; id: string } | null | undefined>
            | null
            | undefined;
        }
      | null
      | undefined;
    currentMotto?: { __typename?: 'Motto'; id: string; content: string } | null | undefined;
    currentProfessionnalWeather?:
      | {
          __typename?: 'ProfessionnalWeather';
          id: string;
          icon?: string | null | undefined;
          title?: string | null | undefined;
        }
      | null
      | undefined;
    currentProfessionalStatus?:
      | {
          __typename?: 'ProfessionalStatus';
          id: string;
          title?: string | null | undefined;
          icon?: string | null | undefined;
        }
      | null
      | undefined;
    nPlusOne?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          jobTitle?: string | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    nMinusOnes?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          jobTitle?: string | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    myCommunities?: Array<{ __typename?: 'Community'; id: string }> | null | undefined;
    currentLocation?:
      | {
          __typename?: 'Location';
          id: string;
          title?: string | null | undefined;
          iconUrl?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
  total: { __typename?: 'ListMetadata'; count: number };
};

export type CurrentCompanyMemberQueryVariables = Exact<{
  seen?: InputMaybe<Scalars['Boolean']>;
}>;

export type CurrentCompanyMemberQuery = {
  __typename?: 'Query';
  currentCompanyMember: {
    __typename?: 'CompanyMember';
    id: string;
    coins?: number | null | undefined;
    jobTitle?: string | null | undefined;
    incomingSemiInstantCallsCount?: number | null | undefined;
    nPlusOne?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          jobTitle?: string | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                email?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    nMinusOnes?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          jobTitle?: string | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                email?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
          email?: string | null | undefined;
        }
      | null
      | undefined;
    feedbacksReceived?:
      | Array<{
          __typename?: 'Feedback';
          id: string;
          content?: string | null | undefined;
          response?: string | null | undefined;
          alreadySeen?: boolean | null | undefined;
          createdAt?: any | null | undefined;
          companyMemberSenderId?: string | null | undefined;
          companyMemberReceiver?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          companyMemberSender?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      email?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    thanksReceived?:
      | Array<{
          __typename?: 'UserThank';
          id: string;
          content?: string | null | undefined;
          response?: string | null | undefined;
          alreadySeen?: boolean | null | undefined;
          score?: number | null | undefined;
          createdAt?: any | null | undefined;
          companyMemberSenderId?: string | null | undefined;
          companyMemberSender?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      email?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    myCommunities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
        }>
      | null
      | undefined;
    missions?:
      | Array<
          | {
              __typename?: 'Mission';
              id: string;
              title?: string | null | undefined;
              deadline?: any | null | undefined;
              estimateDate?: any | null | undefined;
              priorityLevel?: number | null | undefined;
              progressStatus?: MissionProgressStatus | null | undefined;
              createdAt?: any | null | undefined;
              community?:
                | {
                    __typename?: 'Community';
                    id: string;
                    logo?: string | null | undefined;
                    logoPublicUrl?: string | null | undefined;
                    name?: string | null | undefined;
                  }
                | null
                | undefined;
              experts?:
                | Array<
                    | {
                        __typename?: 'CompanyMember';
                        id: string;
                        user?:
                          | {
                              __typename?: 'User';
                              id: string;
                              fullName?: string | null | undefined;
                              firstName?: string | null | undefined;
                              lastName?: string | null | undefined;
                              photoUrl?: string | null | undefined;
                              photoPublicUrl?: string | null | undefined;
                              email?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                          email?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    myActions?:
      | Array<{
          __typename?: 'Action';
          id: string;
          title?: string | null | undefined;
          description?: string | null | undefined;
          targetDate?: any | null | undefined;
          actionType?: ActionType | null | undefined;
          isCompleted?: boolean | null | undefined;
          completionDate?: any | null | undefined;
          isOverdue?: boolean | null | undefined;
          updatedAt?: any | null | undefined;
          createdAt?: any | null | undefined;
          createdBycompanyMemberId?: string | null | undefined;
          localCreationType?: LocalCreationActionType | null | undefined;
          responsibles?:
            | Array<{
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
          roadBlock?:
            | { __typename?: 'RoadBlock'; id: string; roadBlockId?: string | null | undefined }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetFeedbacksByCmQueryVariables = Exact<{
  id: Scalars['ID'];
  sentToId?: InputMaybe<Scalars['ID']>;
  receivedFromId?: InputMaybe<Scalars['ID']>;
  sentByMe?: InputMaybe<Scalars['Boolean']>;
  receivedByMe?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetFeedbacksByCmQuery = {
  __typename?: 'Query';
  getCompanyMemberById?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        feedbacksSent?:
          | Array<{
              __typename?: 'Feedback';
              id: string;
              content?: string | null | undefined;
              response?: string | null | undefined;
              alreadySeen?: boolean | null | undefined;
              createdAt?: any | null | undefined;
              companyMemberSenderId?: string | null | undefined;
              companyMemberReceiver?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              companyMemberSender?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        feedbacksReceived?:
          | Array<{
              __typename?: 'Feedback';
              id: string;
              content?: string | null | undefined;
              response?: string | null | undefined;
              alreadySeen?: boolean | null | undefined;
              createdAt?: any | null | undefined;
              companyMemberSenderId?: string | null | undefined;
              companyMemberReceiver?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              companyMemberSender?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompaniesQuery = {
  __typename?: 'Query';
  companies: Array<{
    __typename?: 'Company';
    id: string;
    name?: string | null | undefined;
    slug?: string | null | undefined;
  }>;
};

export type GetCompaniesWithLogoQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompaniesWithLogoQuery = {
  __typename?: 'Query';
  companies: Array<{
    __typename?: 'Company';
    id: string;
    name?: string | null | undefined;
    slug?: string | null | undefined;
    logo?: string | null | undefined;
    logoPublicUrl?: string | null | undefined;
  }>;
};

export type GetEmployeeOfTheDayQueryVariables = Exact<{ [key: string]: never }>;

export type GetEmployeeOfTheDayQuery = {
  __typename?: 'Query';
  getEmployeeOfTheDay?:
    | {
        __typename?: 'EmployeeOfDay';
        id: string;
        day?: any | null | undefined;
        companyMember?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              jobTitle?: string | null | undefined;
              hiringDate?: any | null | undefined;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    bio?: string | null | undefined;
                    email?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                    communities?:
                      | Array<{
                          __typename?: 'UserCommunity';
                          id: string;
                          community?:
                            | {
                                __typename?: 'Community';
                                id: string;
                                name?: string | null | undefined;
                                logo?: string | null | undefined;
                                logoPublicUrl?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }>
                      | null
                      | undefined;
                    skills?:
                      | Array<{ __typename?: 'Skill'; id: string; title: string }>
                      | null
                      | undefined;
                    successes?:
                      | Array<{ __typename?: 'UserSuccess'; id: string; title: string }>
                      | null
                      | undefined;
                    passions?:
                      | Array<{ __typename?: 'Passion'; id: string; title: string }>
                      | null
                      | undefined;
                    pictures?:
                      | Array<{
                          __typename?: 'UserPicture';
                          id: string;
                          title: string;
                          imageUrl: string;
                          imagePublicUrl?: string | null | undefined;
                        }>
                      | null
                      | undefined;
                    previousCompanies?:
                      | Array<{ __typename?: 'PreviousCompany'; id: string; name: string }>
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMyCommunitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyCommunitiesQuery = {
  __typename?: 'Query';
  currentCompanyMember: {
    __typename?: 'CompanyMember';
    id: string;
    myCommunities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
          slug?: string | null | undefined;
          mission?: string | null | undefined;
          rommance?: string | null | undefined;
          myCommunityRole?: CommunityRole | null | undefined;
          exp?: number | null | undefined;
          coins?: number | null | undefined;
          expLevel?:
            | {
                __typename?: 'Badge';
                id: string;
                name?: string | null | undefined;
                iconPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
  currentWholeCompany: { __typename?: 'Community'; id: string; slug?: string | null | undefined };
};

export type GetCurrentCompanyPostCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentCompanyPostCategoriesQuery = {
  __typename?: 'Query';
  currentCompany: {
    __typename?: 'Company';
    id: string;
    questionCategories?:
      | Array<{ __typename?: 'QuestionCategory'; id: string; title?: string | null | undefined }>
      | null
      | undefined;
    ideaCategories?:
      | Array<{ __typename?: 'IdeaCategory'; id: string; title?: string | null | undefined }>
      | null
      | undefined;
    problemCategories?:
      | Array<{ __typename?: 'ProblemCategory'; id: string; title?: string | null | undefined }>
      | null
      | undefined;
    adviceCategories?:
      | Array<{ __typename?: 'AdviceCategory'; id: string; title?: string | null | undefined }>
      | null
      | undefined;
  };
};

export type CoworkersMemberWithMeQueryVariables = Exact<{
  queryString?: InputMaybe<Scalars['String']>;
  fromAdmin?: InputMaybe<Scalars['Boolean']>;
}>;

export type CoworkersMemberWithMeQuery = {
  __typename?: 'Query';
  coworkersMember: Array<{
    __typename?: 'CompanyMember';
    id: string;
    role?: MemberRole | null | undefined;
    userAccess?: MainRole | null | undefined;
    hiringDate?: any | null | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          email?: string | null | undefined;
          fullName?: string | null | undefined;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          suspended?: boolean | null | undefined;
        }
      | null
      | undefined;
    nPlusOne?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    myCommunities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
          name?: string | null | undefined;
        }>
      | null
      | undefined;
    businessUnit?:
      | {
          __typename?: 'BusinessUnit';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetPendingInvitationsByCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type GetPendingInvitationsByCompanyQuery = {
  __typename?: 'Query';
  pendingInvitationByCompany: Array<{
    __typename?: 'UserCompanyInvitation';
    id: string;
    targetEmail: string;
    companyMemberRole?: MemberRole | null | undefined;
    companyMemberAccess?: MainRole | null | undefined;
    token?: string | null | undefined;
    pendingTeams?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
  }>;
};

export type GetPendingInvitationsByTeamIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPendingInvitationsByTeamIdQuery = {
  __typename?: 'Query';
  pendingInvitationsByTeam?:
    | Array<{
        __typename?: 'UserCompanyInvitation';
        id: string;
        targetEmail: string;
        communityMemberRole?: CommunityRole | null | undefined;
      }>
    | null
    | undefined;
};

export type GetCurrentWelcomeScreenQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentWelcomeScreenQuery = {
  __typename?: 'Query';
  getCurrentWelcomeScreen: string;
};

export type GetPendingInvitationQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type GetPendingInvitationQuery = {
  __typename?: 'Query';
  pendingInvitation?:
    | {
        __typename?: 'UserCompanyInvitation';
        id: string;
        targetEmail: string;
        hiringDate?: any | null | undefined;
      }
    | null
    | undefined;
};

export type GetAllCompanyImagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCompanyImagesQuery = {
  __typename?: 'Query';
  companyImages: Array<{
    __typename?: 'File';
    id: string;
    name?: string | null | undefined;
    type?: string | null | undefined;
    size?: number | null | undefined;
    path?: string | null | undefined;
    publicUrl?: string | null | undefined;
  }>;
};

export type IsAlreadySignedUpQueryVariables = Exact<{
  token: Scalars['String'];
}>;

export type IsAlreadySignedUpQuery = { __typename?: 'Query'; isAlreadySignedUp: boolean };

export type GetMyNPlusOneQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyNPlusOneQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'CompanyMember';
    id: string;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    nPlusOne?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
  };
};

export type GetCeoWordQueryVariables = Exact<{ [key: string]: never }>;

export type GetCeoWordQuery = { __typename?: 'Query'; speech?: string | null | undefined };

export type CheckClientQueryVariables = Exact<{
  timezone: UserTimezoneInput;
}>;

export type CheckClientQuery = {
  __typename?: 'Query';
  payload: {
    __typename?: 'CheckClientPayload';
    streamToken?: string | null | undefined;
    success: boolean;
    message?: string | null | undefined;
    companyMember?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          removed?: boolean | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                email?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
          company?:
            | { __typename?: 'Company'; id: string; slug?: string | null | undefined }
            | null
            | undefined;
          userPreference?:
            | {
                __typename?: 'UserPreference';
                id: string;
                enableShowEntryTunnel?: boolean | null | undefined;
                userTimezone?:
                  | { __typename?: 'UserTimezone'; id: string; tzCode?: string | null | undefined }
                  | null
                  | undefined;
                language?: { __typename?: 'Language'; id: string; key: string } | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type CheckAdminQueryVariables = Exact<{ [key: string]: never }>;

export type CheckAdminQuery = {
  __typename?: 'Query';
  admin: { __typename?: 'CheckAdminPayload'; userAccess: MainRole; slug: string };
};

export type GetCommunitiesInputOptionsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  mine?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCommunitiesInputOptionsQuery = {
  __typename?: 'Query';
  communities: Array<{
    __typename?: 'Community';
    id: string;
    name?: string | null | undefined;
    logo?: string | null | undefined;
    logoPublicUrl?: string | null | undefined;
  }>;
};

export type GetCoworkersInputOptionsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  withoutMe?: InputMaybe<Scalars['Boolean']>;
  queryString?: InputMaybe<Scalars['String']>;
  teamId?: InputMaybe<Scalars['ID']>;
  filterByCommunityId?: InputMaybe<Scalars['ID']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetCoworkersInputOptionsQuery = {
  __typename?: 'Query';
  coworkers: Array<{
    __typename?: 'CompanyMember';
    id: string;
    removed?: boolean | null | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
          isOnline?: boolean | null | undefined;
          email?: string | null | undefined;
          currentCompanyMember?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetTunnelsActivationValueQueryVariables = Exact<{ [key: string]: never }>;

export type GetTunnelsActivationValueQuery = {
  __typename?: 'Query';
  tunnelsActivationValue: { __typename?: 'TunnelsActivationValue'; entry: boolean; exit: boolean };
};

export type GetCoworkersMeetingInputOptionsQueryVariables = Exact<{
  withoutMe?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCoworkersMeetingInputOptionsQuery = {
  __typename?: 'Query';
  coworkers: Array<{
    __typename?: 'CompanyMember';
    id: string;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          email?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetCoworkersForBusinessUnitInputOptionsQueryVariables = Exact<{
  fromBusinessUnit?: InputMaybe<Scalars['Boolean']>;
  queryString?: InputMaybe<Scalars['String']>;
}>;

export type GetCoworkersForBusinessUnitInputOptionsQuery = {
  __typename?: 'Query';
  coworkers: Array<{
    __typename?: 'CompanyMember';
    id: string;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetIndicatorSwitchItemsQueryVariables = Exact<{ [key: string]: never }>;

export type GetIndicatorSwitchItemsQuery = {
  __typename?: 'Query';
  switchItems?:
    | {
        __typename?: 'Company';
        id: string;
        name?: string | null | undefined;
        logo?: string | null | undefined;
        logoPublicUrl?: string | null | undefined;
        indicators?:
          | Array<{
              __typename?: 'Indicator';
              actionsCount?: number | null | undefined;
              id: string;
              name?: string | null | undefined;
              date?: any | null | undefined;
              description?: string | null | undefined;
              value?: string | null | undefined;
              action?: IndicatorAction | null | undefined;
              trend?: IndicatorTrend | null | undefined;
              level?: IndicatorLevel | null | undefined;
              createdAt?: any | null | undefined;
              actions?:
                | Array<{
                    __typename?: 'Action';
                    id: string;
                    title?: string | null | undefined;
                    description?: string | null | undefined;
                    targetDate?: any | null | undefined;
                    actionType?: ActionType | null | undefined;
                    isCompleted?: boolean | null | undefined;
                    completionDate?: any | null | undefined;
                    isOverdue?: boolean | null | undefined;
                    updatedAt?: any | null | undefined;
                    createdAt?: any | null | undefined;
                    createdBycompanyMemberId?: string | null | undefined;
                    localCreationType?: LocalCreationActionType | null | undefined;
                    createdBy?:
                      | {
                          __typename?: 'User';
                          id: string;
                          firstName?: string | null | undefined;
                          fullName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          email?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                    responsibles?:
                      | Array<{
                          __typename?: 'CompanyMember';
                          id: string;
                          user?:
                            | {
                                __typename?: 'User';
                                id: string;
                                firstName?: string | null | undefined;
                                fullName?: string | null | undefined;
                                lastName?: string | null | undefined;
                                email?: string | null | undefined;
                                photoUrl?: string | null | undefined;
                                photoPublicUrl?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }>
                      | null
                      | undefined;
                    indicator?: { __typename?: 'Indicator'; id: string } | null | undefined;
                  }>
                | null
                | undefined;
              comments?:
                | Array<
                    | {
                        __typename?: 'Comment';
                        id: string;
                        content?: string | null | undefined;
                        createdAt?: any | null | undefined;
                        isMine?: boolean | null | undefined;
                        user?:
                          | {
                              __typename?: 'User';
                              id: string;
                              firstName?: string | null | undefined;
                              fullName?: string | null | undefined;
                              lastName?: string | null | undefined;
                              email?: string | null | undefined;
                              photoUrl?: string | null | undefined;
                              photoPublicUrl?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }>
          | null
          | undefined;
        communities?:
          | Array<{
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
              indicators?:
                | Array<{
                    __typename?: 'Indicator';
                    actionsCount?: number | null | undefined;
                    id: string;
                    name?: string | null | undefined;
                    date?: any | null | undefined;
                    description?: string | null | undefined;
                    value?: string | null | undefined;
                    action?: IndicatorAction | null | undefined;
                    trend?: IndicatorTrend | null | undefined;
                    level?: IndicatorLevel | null | undefined;
                    createdAt?: any | null | undefined;
                    actions?:
                      | Array<{
                          __typename?: 'Action';
                          id: string;
                          title?: string | null | undefined;
                          description?: string | null | undefined;
                          targetDate?: any | null | undefined;
                          actionType?: ActionType | null | undefined;
                          isCompleted?: boolean | null | undefined;
                          completionDate?: any | null | undefined;
                          isOverdue?: boolean | null | undefined;
                          updatedAt?: any | null | undefined;
                          createdAt?: any | null | undefined;
                          createdBycompanyMemberId?: string | null | undefined;
                          localCreationType?: LocalCreationActionType | null | undefined;
                          createdBy?:
                            | {
                                __typename?: 'User';
                                id: string;
                                firstName?: string | null | undefined;
                                fullName?: string | null | undefined;
                                lastName?: string | null | undefined;
                                email?: string | null | undefined;
                                photoUrl?: string | null | undefined;
                                photoPublicUrl?: string | null | undefined;
                              }
                            | null
                            | undefined;
                          responsibles?:
                            | Array<{
                                __typename?: 'CompanyMember';
                                id: string;
                                user?:
                                  | {
                                      __typename?: 'User';
                                      id: string;
                                      firstName?: string | null | undefined;
                                      fullName?: string | null | undefined;
                                      lastName?: string | null | undefined;
                                      email?: string | null | undefined;
                                      photoUrl?: string | null | undefined;
                                      photoPublicUrl?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }>
                            | null
                            | undefined;
                          indicator?: { __typename?: 'Indicator'; id: string } | null | undefined;
                        }>
                      | null
                      | undefined;
                    comments?:
                      | Array<
                          | {
                              __typename?: 'Comment';
                              id: string;
                              content?: string | null | undefined;
                              createdAt?: any | null | undefined;
                              isMine?: boolean | null | undefined;
                              user?:
                                | {
                                    __typename?: 'User';
                                    id: string;
                                    firstName?: string | null | undefined;
                                    fullName?: string | null | undefined;
                                    lastName?: string | null | undefined;
                                    email?: string | null | undefined;
                                    photoUrl?: string | null | undefined;
                                    photoPublicUrl?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
        businessUnits?:
          | Array<{
              __typename?: 'BusinessUnit';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
              indicators?:
                | Array<{
                    __typename?: 'Indicator';
                    actionsCount?: number | null | undefined;
                    id: string;
                    name?: string | null | undefined;
                    date?: any | null | undefined;
                    description?: string | null | undefined;
                    value?: string | null | undefined;
                    action?: IndicatorAction | null | undefined;
                    trend?: IndicatorTrend | null | undefined;
                    level?: IndicatorLevel | null | undefined;
                    createdAt?: any | null | undefined;
                    actions?:
                      | Array<{
                          __typename?: 'Action';
                          id: string;
                          title?: string | null | undefined;
                          description?: string | null | undefined;
                          targetDate?: any | null | undefined;
                          actionType?: ActionType | null | undefined;
                          isCompleted?: boolean | null | undefined;
                          completionDate?: any | null | undefined;
                          isOverdue?: boolean | null | undefined;
                          updatedAt?: any | null | undefined;
                          createdAt?: any | null | undefined;
                          createdBycompanyMemberId?: string | null | undefined;
                          localCreationType?: LocalCreationActionType | null | undefined;
                          createdBy?:
                            | {
                                __typename?: 'User';
                                id: string;
                                firstName?: string | null | undefined;
                                fullName?: string | null | undefined;
                                lastName?: string | null | undefined;
                                email?: string | null | undefined;
                                photoUrl?: string | null | undefined;
                                photoPublicUrl?: string | null | undefined;
                              }
                            | null
                            | undefined;
                          responsibles?:
                            | Array<{
                                __typename?: 'CompanyMember';
                                id: string;
                                user?:
                                  | {
                                      __typename?: 'User';
                                      id: string;
                                      firstName?: string | null | undefined;
                                      fullName?: string | null | undefined;
                                      lastName?: string | null | undefined;
                                      email?: string | null | undefined;
                                      photoUrl?: string | null | undefined;
                                      photoPublicUrl?: string | null | undefined;
                                    }
                                  | null
                                  | undefined;
                              }>
                            | null
                            | undefined;
                          indicator?: { __typename?: 'Indicator'; id: string } | null | undefined;
                        }>
                      | null
                      | undefined;
                    comments?:
                      | Array<
                          | {
                              __typename?: 'Comment';
                              id: string;
                              content?: string | null | undefined;
                              createdAt?: any | null | undefined;
                              isMine?: boolean | null | undefined;
                              user?:
                                | {
                                    __typename?: 'User';
                                    id: string;
                                    firstName?: string | null | undefined;
                                    fullName?: string | null | undefined;
                                    lastName?: string | null | undefined;
                                    email?: string | null | undefined;
                                    photoUrl?: string | null | undefined;
                                    photoPublicUrl?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetAllTeamMembersSuggestionQueryVariables = Exact<{
  teamId?: InputMaybe<Scalars['ID']>;
  filterValue?: InputMaybe<Scalars['String']>;
  fromAdmin?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetAllTeamMembersSuggestionQuery = {
  __typename?: 'Query';
  data?:
    | Array<
        | {
            __typename?: 'CompanyMember';
            id: string;
            hiringDate?: any | null | undefined;
            userAccess?: MainRole | null | undefined;
            role?: MemberRole | null | undefined;
            suspended?: boolean | null | undefined;
            businessUnit?:
              | {
                  __typename?: 'BusinessUnit';
                  id: string;
                  name?: string | null | undefined;
                  slug?: string | null | undefined;
                  logoPublicUrl?: string | null | undefined;
                }
              | null
              | undefined;
            myCommunities?:
              | Array<{
                  __typename?: 'Community';
                  id: string;
                  name?: string | null | undefined;
                  logo?: string | null | undefined;
                  slug?: string | null | undefined;
                  logoPublicUrl?: string | null | undefined;
                }>
              | null
              | undefined;
            user?:
              | {
                  __typename?: 'User';
                  role?: MainRole | null | undefined;
                  id: string;
                  firstName?: string | null | undefined;
                  fullName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  email?: string | null | undefined;
                  photoUrl?: string | null | undefined;
                  photoPublicUrl?: string | null | undefined;
                }
              | null
              | undefined;
            nPlusOne?:
              | {
                  __typename?: 'CompanyMember';
                  id: string;
                  user?:
                    | {
                        __typename?: 'User';
                        id: string;
                        firstName?: string | null | undefined;
                        fullName?: string | null | undefined;
                        lastName?: string | null | undefined;
                        email?: string | null | undefined;
                        photoUrl?: string | null | undefined;
                        photoPublicUrl?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | {
            __typename?: 'UserCompanyInvitation';
            id: string;
            companyMemberAccess?: MainRole | null | undefined;
            isConfirmed: boolean;
            targetEmail: string;
            hiringDate?: any | null | undefined;
            companyMemberRole?: MemberRole | null | undefined;
            token?: string | null | undefined;
            pendingTeamsIds?: Array<string> | null | undefined;
            communityMemberRole?: CommunityRole | null | undefined;
            suspended?: boolean | null | undefined;
            user?:
              | {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null | undefined;
                  fullName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  email?: string | null | undefined;
                  photoUrl?: string | null | undefined;
                  photoPublicUrl?: string | null | undefined;
                }
              | null
              | undefined;
            pendingTeams?:
              | Array<{
                  __typename?: 'Community';
                  id: string;
                  name?: string | null | undefined;
                  logo?: string | null | undefined;
                  slug?: string | null | undefined;
                  logoPublicUrl?: string | null | undefined;
                }>
              | null
              | undefined;
          }
      >
    | null
    | undefined;
};

export type GetAllLanguagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllLanguagesQuery = {
  __typename?: 'Query';
  languages?:
    | Array<{ __typename?: 'Language'; id: string; key: string; label?: string | null | undefined }>
    | null
    | undefined;
};

export type UpdateEmployeeOfDayRankMutationVariables = Exact<{
  employeeId: Scalars['ID'];
  destination?: InputMaybe<Scalars['Int']>;
}>;

export type UpdateEmployeeOfDayRankMutation = {
  __typename?: 'Mutation';
  updateEmployeeOfDayRank: Array<{
    __typename?: 'CompanyMember';
    id: string;
    employeeOfDayStatus?: EmployeeOfDayStatus | null | undefined;
    employeeOfDayRank?: number | null | undefined;
  }>;
};

export type UpdateEmployeeOfDayStatusMutationVariables = Exact<{
  employeeId: Scalars['ID'];
  status: EmployeeOfDayStatus;
  force?: InputMaybe<Scalars['Boolean']>;
}>;

export type UpdateEmployeeOfDayStatusMutation = {
  __typename?: 'Mutation';
  updateEmployeeOfDayStatus: Array<{
    __typename?: 'CompanyMember';
    id: string;
    employeeOfDayStatus?: EmployeeOfDayStatus | null | undefined;
    employeeOfDayRank?: number | null | undefined;
  }>;
};

export type DeleteCompanyMemberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteCompanyMemberMutation = {
  __typename?: 'Mutation';
  deleteCompanyMember: { __typename?: 'CompanyMember'; id: string };
};

export type SuspendCompanyMemberMutationVariables = Exact<{
  id: Scalars['ID'];
  suspended: Scalars['Boolean'];
}>;

export type SuspendCompanyMemberMutation = {
  __typename?: 'Mutation';
  suspendCompanyMember: {
    __typename?: 'CompanyMember';
    id: string;
    suspended?: boolean | null | undefined;
  };
};

export type SetIsFirstEntryMutationVariables = Exact<{ [key: string]: never }>;

export type SetIsFirstEntryMutation = { __typename?: 'Mutation'; setIsFirstEntry: boolean };

export type SetLocaleMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type SetLocaleMutation = {
  __typename?: 'Mutation';
  setLocale: { __typename?: 'UserPreference'; id: string };
};

export type ResetIncomingCallsCountMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type ResetIncomingCallsCountMutation = {
  __typename?: 'Mutation';
  resetIncomingCallsCount?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        incomingSemiInstantCallsCount?: number | null | undefined;
      }
    | null
    | undefined;
};

export type GetRankedCompanyMembersQueryVariables = Exact<{
  shuffle?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetRankedCompanyMembersQuery = {
  __typename?: 'Query';
  getRankedCompanyMembers: Array<{
    __typename?: 'CompanyMember';
    id: string;
    employeeOfDayStatus?: EmployeeOfDayStatus | null | undefined;
    employeeOfDayRank?: number | null | undefined;
    suspended?: boolean | null | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          firstName?: string | null | undefined;
          fullName?: string | null | undefined;
          lastName?: string | null | undefined;
          email?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    employeesOfDay?:
      | Array<{ __typename?: 'EmployeeOfDay'; id: string; day?: any | null | undefined }>
      | null
      | undefined;
  }>;
};

export type GetMyAccessQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyAccessQuery = {
  __typename?: 'Query';
  currentCompanyMember: {
    __typename?: 'CompanyMember';
    id: string;
    userAccess?: MainRole | null | undefined;
    suspended?: boolean | null | undefined;
  };
};

export type GetMyRoleQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyRoleQuery = {
  __typename?: 'Query';
  currentCompanyMember: {
    __typename?: 'CompanyMember';
    id: string;
    role?: MemberRole | null | undefined;
  };
};

export type GetMyNPlusAndMinusOneQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyNPlusAndMinusOneQuery = {
  __typename?: 'Query';
  currentCompanyMember: {
    __typename?: 'CompanyMember';
    id: string;
    nMinusOnes?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                isOnline?: boolean | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                companyMembers?:
                  | Array<
                      | {
                          __typename?: 'CompanyMember';
                          id: string;
                          currentMotto?:
                            | { __typename?: 'Motto'; id: string; content: string }
                            | null
                            | undefined;
                          currentProfessionnalWeather?:
                            | {
                                __typename?: 'ProfessionnalWeather';
                                id: string;
                                icon?: string | null | undefined;
                              }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined
                    >
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetMyCurrentMottoQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyCurrentMottoQuery = {
  __typename?: 'Query';
  currentCompanyMember: {
    __typename?: 'CompanyMember';
    id: string;
    currentMotto?: { __typename?: 'Motto'; id: string; content: string } | null | undefined;
  };
};

export type GetPeoplesQueryVariables = Exact<{
  field?: InputMaybe<PeopleFilterField>;
  filters?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  sortOrder?: InputMaybe<SortEnum>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetPeoplesQuery = {
  __typename?: 'Query';
  companyMembers: Array<{
    __typename?: 'CompanyMember';
    id: string;
    jobTitle?: string | null | undefined;
    hiringDate?: any | null | undefined;
    isMyBirthDay?: boolean | null | undefined;
    isMyChildBirthDay?: boolean | null | undefined;
    isMyWeddingDay?: boolean | null | undefined;
    exp?: number | null | undefined;
    expLevel?:
      | {
          __typename?: 'Badge';
          id: string;
          name?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    currentProfessionalStatus?:
      | {
          __typename?: 'ProfessionalStatus';
          id: string;
          icon?: string | null | undefined;
          title?: string | null | undefined;
        }
      | null
      | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          email?: string | null | undefined;
          isOnline?: boolean | null | undefined;
          photoPublicUrl?: string | null | undefined;
          birthday?: any | null | undefined;
          bio?: string | null | undefined;
          mySharedHorizon?: string | null | undefined;
          familySituation?: string | null | undefined;
          personality?: UserPersonality | null | undefined;
          adress?: string | null | undefined;
          phone?: string | null | undefined;
          skills?: Array<{ __typename?: 'Skill'; id: string; title: string }> | null | undefined;
          successes?:
            | Array<{ __typename?: 'UserSuccess'; id: string; title: string }>
            | null
            | undefined;
          passions?:
            | Array<{ __typename?: 'Passion'; id: string; title: string }>
            | null
            | undefined;
          previousCompanies?:
            | Array<{ __typename?: 'PreviousCompany'; id: string; name: string }>
            | null
            | undefined;
          pictures?:
            | Array<{
                __typename?: 'UserPicture';
                id: string;
                title: string;
                imagePublicUrl?: string | null | undefined;
              }>
            | null
            | undefined;
          presentationVideo?:
            | { __typename?: 'File'; id: string; publicUrl?: string | null | undefined }
            | null
            | undefined;
        }
      | null
      | undefined;
    nPlusOne?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          jobTitle?: string | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    myCommunities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
    currentMotto?: { __typename?: 'Motto'; id: string; content: string } | null | undefined;
    businessUnit?:
      | { __typename?: 'BusinessUnit'; id: string; name?: string | null | undefined }
      | null
      | undefined;
  }>;
  meta: { __typename?: 'ListMetadata'; count: number };
};

export type GetPeopleByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetPeopleByIdQuery = {
  __typename?: 'Query';
  companyMember?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        jobTitle?: string | null | undefined;
        hiringDate?: any | null | undefined;
        isMyBirthDay?: boolean | null | undefined;
        isMyChildBirthDay?: boolean | null | undefined;
        isMyWeddingDay?: boolean | null | undefined;
        exp?: number | null | undefined;
        expLevel?:
          | {
              __typename?: 'Badge';
              id: string;
              name?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        user?:
          | {
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              fullName?: string | null | undefined;
              email?: string | null | undefined;
              isOnline?: boolean | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              birthday?: any | null | undefined;
              bio?: string | null | undefined;
              mySharedHorizon?: string | null | undefined;
              familySituation?: string | null | undefined;
              personality?: UserPersonality | null | undefined;
              adress?: string | null | undefined;
              phone?: string | null | undefined;
              skills?:
                | Array<{ __typename?: 'Skill'; id: string; title: string }>
                | null
                | undefined;
              successes?:
                | Array<{ __typename?: 'UserSuccess'; id: string; title: string }>
                | null
                | undefined;
              passions?:
                | Array<{ __typename?: 'Passion'; id: string; title: string }>
                | null
                | undefined;
              previousCompanies?:
                | Array<{ __typename?: 'PreviousCompany'; id: string; name: string }>
                | null
                | undefined;
              pictures?:
                | Array<{
                    __typename?: 'UserPicture';
                    id: string;
                    title: string;
                    imageUrl: string;
                    imagePublicUrl?: string | null | undefined;
                  }>
                | null
                | undefined;
              presentationVideo?:
                | { __typename?: 'File'; id: string; publicUrl?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
        nPlusOne?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              jobTitle?: string | null | undefined;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        myCommunities?:
          | Array<{
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }>
          | null
          | undefined;
        currentMotto?: { __typename?: 'Motto'; id: string; content: string } | null | undefined;
        businessUnit?:
          | { __typename?: 'BusinessUnit'; id: string; name?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CoworkersListQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  withoutMe?: InputMaybe<Scalars['Boolean']>;
  filterByWeatherId?: InputMaybe<Scalars['ID']>;
  filterByCommunityId?: InputMaybe<Scalars['ID']>;
  filterByEventTypeId?: InputMaybe<Scalars['ID']>;
  filterByLocationId?: InputMaybe<Scalars['ID']>;
  queryString?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  cacheKey?: InputMaybe<Scalars['String']>;
}>;

export type CoworkersListQuery = {
  __typename?: 'Query';
  members: Array<{
    __typename?: 'CompanyMember';
    id: string;
    jobTitle?: string | null | undefined;
    isItMyDay?: boolean | null | undefined;
    removed?: boolean | null | undefined;
    suspended?: boolean | null | undefined;
    dayOff?: boolean | null | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
          isItMyDay?: boolean | null | undefined;
          email?: string | null | undefined;
          isOnline?: boolean | null | undefined;
          removed?: boolean | null | undefined;
          suspended?: boolean | null | undefined;
          companyMembers?:
            | Array<{ __typename?: 'CompanyMember'; id: string } | null | undefined>
            | null
            | undefined;
        }
      | null
      | undefined;
    currentLeave?: { __typename?: 'Leave'; id: string } | null | undefined;
    currentEvents?:
      | Array<{
          __typename?: 'CalendarEvent';
          title?: string | null | undefined;
          id: string;
          communityEventType?:
            | { __typename?: 'CommunityEventType'; iconPublicUrl?: string | null | undefined }
            | null
            | undefined;
        }>
      | null
      | undefined;
    currentMotto?: { __typename?: 'Motto'; id: string; content: string } | null | undefined;
    currentProfessionnalWeather?:
      | {
          __typename?: 'ProfessionnalWeather';
          id: string;
          icon?: string | null | undefined;
          title?: string | null | undefined;
        }
      | null
      | undefined;
    currentProfessionalStatus?:
      | {
          __typename?: 'ProfessionalStatus';
          id: string;
          title?: string | null | undefined;
          icon?: string | null | undefined;
        }
      | null
      | undefined;
    nPlusOne?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          jobTitle?: string | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    nMinusOnes?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          jobTitle?: string | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    myCommunities?: Array<{ __typename?: 'Community'; id: string }> | null | undefined;
    currentLocation?:
      | {
          __typename?: 'Location';
          id: string;
          title?: string | null | undefined;
          iconUrl?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
  total: { __typename?: 'ListMetadata'; count: number };
};

export type SearchPeopleQueryVariables = Exact<{
  withoutMe?: InputMaybe<Scalars['Boolean']>;
  perPage?: InputMaybe<Scalars['Int']>;
  queryString?: InputMaybe<Scalars['String']>;
}>;

export type SearchPeopleQuery = {
  __typename?: 'Query';
  peoples: Array<{
    __typename?: 'CompanyMember';
    id: string;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetCompanyDirectorQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyDirectorQuery = {
  __typename?: 'Query';
  director?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        jobTitle?: string | null | undefined;
        user?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCompanyMemberDirectorsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyMemberDirectorsQuery = {
  __typename?: 'Query';
  directors?:
    | Array<{
        __typename?: 'CompanyMember';
        id: string;
        jobTitle?: string | null | undefined;
        user?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type GetCompanyMemberIsRemovedByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyMemberIsRemovedByIdQuery = {
  __typename?: 'Query';
  companyMember?:
    | { __typename?: 'CompanyMember'; id: string; removed?: boolean | null | undefined }
    | null
    | undefined;
};

export type GetCompanyMemberIsRemovedByIdsQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetCompanyMemberIsRemovedByIdsQuery = {
  __typename?: 'Query';
  companyMembers?:
    | Array<
        | { __typename?: 'CompanyMember'; id: string; removed?: boolean | null | undefined }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetCommonAvatarInfoByMemberIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCommonAvatarInfoByMemberIdQuery = {
  __typename?: 'Query';
  companyMember?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        dayOff?: boolean | null | undefined;
        exp?: number | null | undefined;
        removed?: boolean | null | undefined;
        expLevel?:
          | {
              __typename?: 'Badge';
              id: string;
              name?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        currentProfessionnalWeather?:
          | {
              __typename?: 'ProfessionnalWeather';
              id: string;
              icon?: string | null | undefined;
              title?: string | null | undefined;
            }
          | null
          | undefined;
        currentProfessionalStatus?:
          | {
              __typename?: 'ProfessionalStatus';
              id: string;
              icon?: string | null | undefined;
              title?: string | null | undefined;
            }
          | null
          | undefined;
        currentMotto?: { __typename?: 'Motto'; id: string; content: string } | null | undefined;
        currentLeave?: { __typename?: 'Leave'; id: string } | null | undefined;
        currentLocation?:
          | {
              __typename?: 'Location';
              id: string;
              title?: string | null | undefined;
              iconUrl?: string | null | undefined;
            }
          | null
          | undefined;
        currentEvents?:
          | Array<{
              __typename?: 'CalendarEvent';
              title?: string | null | undefined;
              id: string;
              communityEventType?:
                | { __typename?: 'CommunityEventType'; iconPublicUrl?: string | null | undefined }
                | null
                | undefined;
            }>
          | null
          | undefined;
        user?:
          | {
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              fullName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              email?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMyLocaleQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyLocaleQuery = {
  __typename?: 'Query';
  locale: { __typename?: 'Language'; id: string; key: string; label?: string | null | undefined };
};

export type GetMyCurrentIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyCurrentIdQuery = {
  __typename?: 'Query';
  companyMember: {
    __typename?: 'CompanyMember';
    id: string;
    user?:
      | { __typename?: 'User'; id: string; fullName?: string | null | undefined }
      | null
      | undefined;
  };
};

export type GetUserManagementDataCountQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserManagementDataCountQuery = { __typename?: 'Query'; count: number };

export type CanICreateLeaveForSomeoneQueryVariables = Exact<{ [key: string]: never }>;

export type CanICreateLeaveForSomeoneQuery = {
  __typename?: 'Query';
  companyMember: {
    __typename?: 'CanICreateLeaveForSomeoneMeta';
    id: string;
    canICreateLeaveForSomeone: boolean;
    someOneIds?: Array<string> | null | undefined;
  };
};

export type GetCompanyMemberNPlusOneByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyMemberNPlusOneByIdQuery = {
  __typename?: 'Query';
  companyMember?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        nPlusOne?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    isOnline?: boolean | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetPeopleInputOptionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPeopleInputOptionsQuery = {
  __typename?: 'Query';
  peopleInputOptions: Array<{
    __typename?: 'PeopleInputOption';
    type: PeopleFilterField;
    personality?: UserPersonality | null | undefined;
    skill?: string | null | undefined;
    success?: string | null | undefined;
    passion?: string | null | undefined;
    previousCompany?: string | null | undefined;
    email?: string | null | undefined;
    jobTitle?: string | null | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    team?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    supervisor?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    businessUnit?:
      | { __typename?: 'BusinessUnit'; id: string; name?: string | null | undefined }
      | null
      | undefined;
  }>;
};

export type GetFilteredCompanyMemberInputOptionsQueryVariables = Exact<{
  queryString?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  businessUnitIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetFilteredCompanyMemberInputOptionsQuery = {
  __typename?: 'Query';
  companyMembers: Array<{
    __typename?: 'CompanyMember';
    id: string;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
          isOnline?: boolean | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetFilteredCompanyMemberInputOptionsToRemoveQueryVariables = Exact<{
  selectedIds: Array<Scalars['ID']> | Scalars['ID'];
  businessUnitIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetFilteredCompanyMemberInputOptionsToRemoveQuery = {
  __typename?: 'Query';
  companyMemberToRemoveIds: Array<string>;
};

export type GetNMinusOnesQueryVariables = Exact<{
  queryString?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetNMinusOnesQuery = {
  __typename?: 'Query';
  companyMembers: Array<{
    __typename?: 'CompanyMember';
    id: string;
    suspended?: boolean | null | undefined;
    user?:
      | {
          __typename?: 'User';
          id: string;
          fullName?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    currentProfessionnalWeather?:
      | {
          __typename?: 'ProfessionnalWeather';
          id: string;
          icon?: string | null | undefined;
          title?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
  total: { __typename?: 'ListMetadata'; count: number };
};

export type GetMyExpLevelQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyExpLevelQuery = {
  __typename?: 'Query';
  companyMember: {
    __typename?: 'CompanyMember';
    id: string;
    exp?: number | null | undefined;
    expLevelUpIn?: number | null | undefined;
    expLevelProgress?: number | null | undefined;
    expLevel?:
      | {
          __typename?: 'Badge';
          id: string;
          name?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type GetMyCoinQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyCoinQuery = {
  __typename?: 'Query';
  companyMember: {
    __typename?: 'CompanyMember';
    id: string;
    coins?: number | null | undefined;
    unSeenCoins?: number | null | undefined;
  };
};

export type GetCompanyMemberExpLevelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCompanyMemberExpLevelQuery = {
  __typename?: 'Query';
  companyMember?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        exp?: number | null | undefined;
        expLevel?:
          | {
              __typename?: 'Badge';
              id: string;
              name?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type FileInfoFragment = {
  __typename?: 'File';
  id: string;
  type?: string | null | undefined;
  size?: number | null | undefined;
  path?: string | null | undefined;
  name?: string | null | undefined;
  publicUrl?: string | null | undefined;
  extension?: string | null | undefined;
};

export type ProductThumbnailInfoFragment = {
  __typename?: 'File';
  id: string;
  name?: string | null | undefined;
  path?: string | null | undefined;
  publicUrl?: string | null | undefined;
};

export type FileInLeaveFragmentFragment = {
  __typename?: 'File';
  id: string;
  type?: string | null | undefined;
  size?: number | null | undefined;
  path?: string | null | undefined;
  name?: string | null | undefined;
  publicUrl?: string | null | undefined;
  extension?: string | null | undefined;
};

export type CreateFileMutationVariables = Exact<{
  name: Scalars['String'];
  path?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
}>;

export type CreateFileMutation = {
  __typename?: 'Mutation';
  createFile: {
    __typename?: 'File';
    id: string;
    type?: string | null | undefined;
    size?: number | null | undefined;
    path?: string | null | undefined;
    name?: string | null | undefined;
    publicUrl?: string | null | undefined;
    extension?: string | null | undefined;
  };
};

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteFileMutation = {
  __typename?: 'Mutation';
  deleteFile: {
    __typename?: 'File';
    id: string;
    type?: string | null | undefined;
    size?: number | null | undefined;
    path?: string | null | undefined;
    name?: string | null | undefined;
    publicUrl?: string | null | undefined;
    extension?: string | null | undefined;
  };
};

export type AttachFileToCompanyMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type AttachFileToCompanyMutation = {
  __typename?: 'Mutation';
  attachFileToCompany: {
    __typename?: 'File';
    id: string;
    type?: string | null | undefined;
    name?: string | null | undefined;
  };
};

export type AttachFileToBusinessUnitMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  businessUnitId: Scalars['ID'];
}>;

export type AttachFileToBusinessUnitMutation = {
  __typename?: 'Mutation';
  attachFileToBusinessUnit: Array<
    | {
        __typename?: 'File';
        id: string;
        type?: string | null | undefined;
        name?: string | null | undefined;
      }
    | null
    | undefined
  >;
};

export type GetS3SignedUrlQueryVariables = Exact<{
  name: Scalars['String'];
  extension: Scalars['String'];
  repo?: InputMaybe<Scalars['String']>;
  expires?: InputMaybe<Scalars['Int']>;
  s3Operation?: InputMaybe<S3Operation>;
}>;

export type GetS3SignedUrlQuery = {
  __typename?: 'Query';
  localFileSignedUrl: { __typename?: 'S3UrlInfo'; url: string; key: string };
};

export type GetCompanyImagesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCompanyImagesQuery = {
  __typename?: 'Query';
  getCompanyImages: Array<{
    __typename?: 'File';
    id: string;
    name?: string | null | undefined;
    path?: string | null | undefined;
    publicUrl?: string | null | undefined;
  }>;
};

export type GetAllFilesQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetAllFilesQuery = {
  __typename?: 'Query';
  getAllFiles: Array<{
    __typename?: 'File';
    id: string;
    name?: string | null | undefined;
    type?: string | null | undefined;
  }>;
};

export type GetHelpMarkByTypeQueryVariables = Exact<{
  type: HelpMarkType;
}>;

export type GetHelpMarkByTypeQuery = {
  __typename?: 'Query';
  helpMark: {
    __typename?: 'HelpMark';
    id: string;
    en_title?: string | null | undefined;
    en_description?: string | null | undefined;
    fr_description?: string | null | undefined;
    fr_title?: string | null | undefined;
    image?:
      | { __typename?: 'File'; id: string; path?: string | null | undefined }
      | null
      | undefined;
  };
};

export type CreatePostRequestMutationVariables = Exact<{
  report: Scalars['String'];
  postId: Scalars['ID'];
}>;

export type CreatePostRequestMutation = {
  __typename?: 'Mutation';
  createPostRequest?:
    | { __typename?: 'PostRequest'; id: string; report?: string | null | undefined }
    | null
    | undefined;
};

export type DeletePostRequestMutationVariables = Exact<{
  postId: Scalars['ID'];
}>;

export type DeletePostRequestMutation = {
  __typename?: 'Mutation';
  deletePostRequest?: { __typename?: 'PostRequest'; id: string } | null | undefined;
};

export type CreateIdeaCategoryMutationVariables = Exact<{
  title: Scalars['String'];
}>;

export type CreateIdeaCategoryMutation = {
  __typename?: 'Mutation';
  createIdeaCategory: { __typename?: 'IdeaCategory'; id: string };
};

export type DeleteIdeaCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteIdeaCategoryMutation = {
  __typename?: 'Mutation';
  deleteIdeaCategory: { __typename?: 'IdeaCategory'; id: string };
};

export type GetCurrentCompanyIdeasQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  filterString?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  postType?: InputMaybe<PostType>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;

export type GetCurrentCompanyIdeasQuery = {
  __typename?: 'Query';
  isFiltersApplied?: boolean | null | undefined;
  count?: number | null | undefined;
  currentCompanyIdeas: Array<{
    __typename?: 'Post';
    id: string;
    postType?: PostType | null | undefined;
    title?: string | null | undefined;
    content?: string | null | undefined;
    createdAt?: any | null | undefined;
    commentCount?: number | null | undefined;
    isSeenByMe?: boolean | null | undefined;
    isMine?: boolean | null | undefined;
    isLikedByMe?: boolean | null | undefined;
    likeCount?: number | null | undefined;
    shareCount?: number | null | undefined;
    isAuthorHidden?: boolean | null | undefined;
    isReported?: boolean | null | undefined;
    postedAnonymously?: boolean | null | undefined;
    ratingPeriod?: any | null | undefined;
    isRatingEnded?: boolean | null | undefined;
    sharedCompanyMemberPosts?:
      | Array<{
          __typename?: 'CompanyMemberPostShare';
          communities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logo?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    communities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
          logo?: string | null | undefined;
        }>
      | null
      | undefined;
    responsibles?:
      | Array<
          | {
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    cmCreatedBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                photoUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          name?: string | null | undefined;
          path?: string | null | undefined;
          type?: string | null | undefined;
          publicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
    myRating?: { __typename?: 'PostRating'; id: string; rating: number } | null | undefined;
    ratingSummary?:
      | {
          __typename?: 'PostRatingSummary';
          two?: number | null | undefined;
          one?: number | null | undefined;
          three?: number | null | undefined;
          four?: number | null | undefined;
          five?: number | null | undefined;
          totalRatingCount?: number | null | undefined;
          globalRating?: number | null | undefined;
        }
      | null
      | undefined;
  }>;
  currentWholeCompany: { __typename?: 'Community'; id: string; name?: string | null | undefined };
};

export type ViewPostQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ViewPostQuery = {
  __typename?: 'Query';
  viewPost?:
    | { __typename?: 'Post'; id: string; isSeenByMe?: boolean | null | undefined }
    | null
    | undefined;
};

export type GetCurrentCompanyAdminIdeasQueryVariables = Exact<{
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
}>;

export type GetCurrentCompanyAdminIdeasQuery = {
  __typename?: 'Query';
  currentCompanyAdminIdeas: {
    __typename?: 'PostList';
    total?: number | null | undefined;
    ideas?:
      | Array<{
          __typename?: 'Post';
          id: string;
          postType?: PostType | null | undefined;
          title?: string | null | undefined;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isSeenByMe?: boolean | null | undefined;
          isMine?: boolean | null | undefined;
          isReported?: boolean | null | undefined;
          isHidden?: boolean | null | undefined;
          communities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
                logo?: string | null | undefined;
              }>
            | null
            | undefined;
          cmCreatedBy?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type IndicatorInfoFragment = {
  __typename?: 'Indicator';
  id: string;
  name?: string | null | undefined;
  date?: any | null | undefined;
  description?: string | null | undefined;
  value?: string | null | undefined;
  action?: IndicatorAction | null | undefined;
  trend?: IndicatorTrend | null | undefined;
  level?: IndicatorLevel | null | undefined;
  createdAt?: any | null | undefined;
};

export type MapIndicatorInfoFragment = {
  __typename?: 'Indicator';
  id: string;
  name?: string | null | undefined;
  action?: IndicatorAction | null | undefined;
  trend?: IndicatorTrend | null | undefined;
  level?: IndicatorLevel | null | undefined;
  value?: string | null | undefined;
};

export type IndicatorHistoryInfoFragment = {
  __typename?: 'IndicatorHistory';
  id: string;
  nameFrom?: string | null | undefined;
  nameTo?: string | null | undefined;
  dateFrom?: any | null | undefined;
  dateTo?: any | null | undefined;
  descriptionFrom?: string | null | undefined;
  descriptionTo?: string | null | undefined;
  valueFrom?: string | null | undefined;
  valueTo?: string | null | undefined;
  actionFrom?: IndicatorAction | null | undefined;
  actionTo?: IndicatorAction | null | undefined;
  trendFrom?: IndicatorTrend | null | undefined;
  trendTo?: IndicatorTrend | null | undefined;
  levelFrom?: IndicatorLevel | null | undefined;
  levelTo?: IndicatorLevel | null | undefined;
  createdAt?: any | null | undefined;
};

export type CreateBusinessUnitIndicatorMutationVariables = Exact<{
  businessUnitId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['String'];
  level: IndicatorLevel;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
}>;

export type CreateBusinessUnitIndicatorMutation = {
  __typename?: 'Mutation';
  createBusinessUnitIndicator?:
    | {
        __typename?: 'Indicator';
        id: string;
        name?: string | null | undefined;
        value?: string | null | undefined;
        level?: IndicatorLevel | null | undefined;
        date?: any | null | undefined;
        description?: string | null | undefined;
        trend?: IndicatorTrend | null | undefined;
        action?: IndicatorAction | null | undefined;
      }
    | null
    | undefined;
};

export type CreateCompanyIndicatorMutationVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  value: Scalars['String'];
  level: IndicatorLevel;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
}>;

export type CreateCompanyIndicatorMutation = {
  __typename?: 'Mutation';
  createCompanyIndicator?:
    | {
        __typename?: 'Indicator';
        id: string;
        name?: string | null | undefined;
        value?: string | null | undefined;
        level?: IndicatorLevel | null | undefined;
        date?: any | null | undefined;
        description?: string | null | undefined;
        trend?: IndicatorTrend | null | undefined;
        action?: IndicatorAction | null | undefined;
      }
    | null
    | undefined;
};

export type CreateCommunityIndicatorMutationVariables = Exact<{
  communityId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['String'];
  level: IndicatorLevel;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
}>;

export type CreateCommunityIndicatorMutation = {
  __typename?: 'Mutation';
  createCommunityIndicator?:
    | {
        __typename?: 'Indicator';
        id: string;
        name?: string | null | undefined;
        value?: string | null | undefined;
        level?: IndicatorLevel | null | undefined;
        date?: any | null | undefined;
        description?: string | null | undefined;
        trend?: IndicatorTrend | null | undefined;
        action?: IndicatorAction | null | undefined;
      }
    | null
    | undefined;
};

export type CreatePostIndicatorMutationVariables = Exact<{
  postId: Scalars['ID'];
  name: Scalars['String'];
  value: Scalars['String'];
  level: IndicatorLevel;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
}>;

export type CreatePostIndicatorMutation = {
  __typename?: 'Mutation';
  createPostIndicator?:
    | {
        __typename?: 'Indicator';
        id: string;
        name?: string | null | undefined;
        value?: string | null | undefined;
        level?: IndicatorLevel | null | undefined;
        date?: any | null | undefined;
        description?: string | null | undefined;
        trend?: IndicatorTrend | null | undefined;
        action?: IndicatorAction | null | undefined;
      }
    | null
    | undefined;
};

export type DeleteIndicatorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteIndicatorMutation = {
  __typename?: 'Mutation';
  deleteIndicator?: { __typename?: 'Indicator'; id: string } | null | undefined;
};

export type UpdateIndicatorMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<IndicatorLevel>;
}>;

export type UpdateIndicatorMutation = {
  __typename?: 'Mutation';
  updateIndicator?:
    | {
        __typename?: 'Indicator';
        id: string;
        name?: string | null | undefined;
        value?: string | null | undefined;
        level?: IndicatorLevel | null | undefined;
        date?: any | null | undefined;
        description?: string | null | undefined;
        trend?: IndicatorTrend | null | undefined;
        action?: IndicatorAction | null | undefined;
      }
    | null
    | undefined;
};

export type GetCompanyIndicatorsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<IndicatorFilter>;
}>;

export type GetCompanyIndicatorsQuery = {
  __typename?: 'Query';
  indicators: Array<{
    __typename?: 'Indicator';
    id: string;
    name?: string | null | undefined;
    value?: string | null | undefined;
    level?: IndicatorLevel | null | undefined;
    date?: any | null | undefined;
    description?: string | null | undefined;
    trend?: IndicatorTrend | null | undefined;
    action?: IndicatorAction | null | undefined;
  }>;
};

export type GetCommunityIndicatorsQueryVariables = Exact<{
  communityId: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<IndicatorFilter>;
}>;

export type GetCommunityIndicatorsQuery = {
  __typename?: 'Query';
  indicators: Array<{
    __typename?: 'Indicator';
    id: string;
    name?: string | null | undefined;
    value?: string | null | undefined;
    level?: IndicatorLevel | null | undefined;
    date?: any | null | undefined;
    description?: string | null | undefined;
    trend?: IndicatorTrend | null | undefined;
    action?: IndicatorAction | null | undefined;
    histories?:
      | Array<{
          __typename?: 'IndicatorHistory';
          id: string;
          nameFrom?: string | null | undefined;
          nameTo?: string | null | undefined;
          dateFrom?: any | null | undefined;
          dateTo?: any | null | undefined;
          descriptionFrom?: string | null | undefined;
          descriptionTo?: string | null | undefined;
          valueFrom?: string | null | undefined;
          valueTo?: string | null | undefined;
          actionFrom?: IndicatorAction | null | undefined;
          actionTo?: IndicatorAction | null | undefined;
          trendFrom?: IndicatorTrend | null | undefined;
          trendTo?: IndicatorTrend | null | undefined;
          levelFrom?: IndicatorLevel | null | undefined;
          levelTo?: IndicatorLevel | null | undefined;
          createdAt?: any | null | undefined;
        }>
      | null
      | undefined;
  }>;
  total: { __typename?: 'ListMetadata'; count: number };
};

export type GetMyCommunitiesIndicatorsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<IndicatorFilter>;
}>;

export type GetMyCommunitiesIndicatorsQuery = {
  __typename?: 'Query';
  indicators: Array<{
    __typename?: 'Indicator';
    id: string;
    name?: string | null | undefined;
    value?: string | null | undefined;
    level?: IndicatorLevel | null | undefined;
    date?: any | null | undefined;
    description?: string | null | undefined;
    trend?: IndicatorTrend | null | undefined;
    action?: IndicatorAction | null | undefined;
    community?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetIndicatorByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetIndicatorByIdQuery = {
  __typename?: 'Query';
  indicator?:
    | {
        __typename?: 'Indicator';
        actionsCount?: number | null | undefined;
        id: string;
        name?: string | null | undefined;
        date?: any | null | undefined;
        description?: string | null | undefined;
        value?: string | null | undefined;
        action?: IndicatorAction | null | undefined;
        trend?: IndicatorTrend | null | undefined;
        level?: IndicatorLevel | null | undefined;
        createdAt?: any | null | undefined;
      }
    | null
    | undefined;
};

export type GetIndicatorByIdForTeamQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetIndicatorByIdForTeamQuery = {
  __typename?: 'Query';
  indicator?:
    | {
        __typename?: 'Indicator';
        actionsCount?: number | null | undefined;
        id: string;
        name?: string | null | undefined;
        date?: any | null | undefined;
        description?: string | null | undefined;
        value?: string | null | undefined;
        action?: IndicatorAction | null | undefined;
        trend?: IndicatorTrend | null | undefined;
        level?: IndicatorLevel | null | undefined;
        createdAt?: any | null | undefined;
        actions?:
          | Array<{
              __typename?: 'Action';
              id: string;
              title?: string | null | undefined;
              description?: string | null | undefined;
              targetDate?: any | null | undefined;
              actionType?: ActionType | null | undefined;
              isCompleted?: boolean | null | undefined;
              completionDate?: any | null | undefined;
              isOverdue?: boolean | null | undefined;
              updatedAt?: any | null | undefined;
              createdAt?: any | null | undefined;
              createdBycompanyMemberId?: string | null | undefined;
              localCreationType?: LocalCreationActionType | null | undefined;
              createdBy?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    email?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
              responsibles?:
                | Array<{
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          firstName?: string | null | undefined;
                          fullName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          email?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
              indicator?: { __typename?: 'Indicator'; id: string } | null | undefined;
            }>
          | null
          | undefined;
        comments?:
          | Array<
              | {
                  __typename?: 'Comment';
                  id: string;
                  content?: string | null | undefined;
                  createdAt?: any | null | undefined;
                  isMine?: boolean | null | undefined;
                  likeCount?: number | null | undefined;
                  isLikedByMe?: boolean | null | undefined;
                  companyMember?:
                    | {
                        __typename?: 'CompanyMember';
                        id: string;
                        user?:
                          | {
                              __typename?: 'User';
                              id: string;
                              fullName?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                  user?:
                    | {
                        __typename?: 'User';
                        id: string;
                        firstName?: string | null | undefined;
                        fullName?: string | null | undefined;
                        lastName?: string | null | undefined;
                        email?: string | null | undefined;
                        photoUrl?: string | null | undefined;
                        photoPublicUrl?: string | null | undefined;
                      }
                    | null
                    | undefined;
                  likedBy?:
                    | Array<{
                        __typename?: 'CompanyMember';
                        user?:
                          | {
                              __typename?: 'User';
                              id: string;
                              firstName?: string | null | undefined;
                              fullName?: string | null | undefined;
                              lastName?: string | null | undefined;
                              email?: string | null | undefined;
                              photoUrl?: string | null | undefined;
                              photoPublicUrl?: string | null | undefined;
                              currentCompanyMember?:
                                | { __typename?: 'CompanyMember'; id: string }
                                | null
                                | undefined;
                            }
                          | null
                          | undefined;
                      }>
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        histories?:
          | Array<{
              __typename?: 'IndicatorHistory';
              id: string;
              nameFrom?: string | null | undefined;
              nameTo?: string | null | undefined;
              dateFrom?: any | null | undefined;
              dateTo?: any | null | undefined;
              descriptionFrom?: string | null | undefined;
              descriptionTo?: string | null | undefined;
              valueFrom?: string | null | undefined;
              valueTo?: string | null | undefined;
              actionFrom?: IndicatorAction | null | undefined;
              actionTo?: IndicatorAction | null | undefined;
              trendFrom?: IndicatorTrend | null | undefined;
              trendTo?: IndicatorTrend | null | undefined;
              levelFrom?: IndicatorLevel | null | undefined;
              levelTo?: IndicatorLevel | null | undefined;
              createdAt?: any | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetIndicatorHistoriesByIndicatorIdQueryVariables = Exact<{
  indicatorId: Scalars['ID'];
}>;

export type GetIndicatorHistoriesByIndicatorIdQuery = {
  __typename?: 'Query';
  indicatorHistories: Array<{
    __typename?: 'IndicatorHistory';
    id: string;
    nameFrom?: string | null | undefined;
    nameTo?: string | null | undefined;
    dateFrom?: any | null | undefined;
    dateTo?: any | null | undefined;
    descriptionFrom?: string | null | undefined;
    descriptionTo?: string | null | undefined;
    valueFrom?: string | null | undefined;
    valueTo?: string | null | undefined;
    actionFrom?: IndicatorAction | null | undefined;
    actionTo?: IndicatorAction | null | undefined;
    trendFrom?: IndicatorTrend | null | undefined;
    trendTo?: IndicatorTrend | null | undefined;
    levelFrom?: IndicatorLevel | null | undefined;
    levelTo?: IndicatorLevel | null | undefined;
    createdAt?: any | null | undefined;
  }>;
};

export type GetIndicatorHistoryByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetIndicatorHistoryByIdQuery = {
  __typename?: 'Query';
  indicatorHistory?:
    | {
        __typename?: 'IndicatorHistory';
        id: string;
        nameFrom?: string | null | undefined;
        nameTo?: string | null | undefined;
        dateFrom?: any | null | undefined;
        dateTo?: any | null | undefined;
        descriptionFrom?: string | null | undefined;
        descriptionTo?: string | null | undefined;
        valueFrom?: string | null | undefined;
        valueTo?: string | null | undefined;
        actionFrom?: IndicatorAction | null | undefined;
        actionTo?: IndicatorAction | null | undefined;
        trendFrom?: IndicatorTrend | null | undefined;
        trendTo?: IndicatorTrend | null | undefined;
        levelFrom?: IndicatorLevel | null | undefined;
        levelTo?: IndicatorLevel | null | undefined;
        createdAt?: any | null | undefined;
      }
    | null
    | undefined;
};

export type CreateLeaveMutationVariables = Exact<{
  start: Scalars['DateTime'];
  ending: Scalars['DateTime'];
  reasonId: Scalars['ID'];
  otherReason?: InputMaybe<Scalars['String']>;
  specialDayOff?: InputMaybe<Scalars['Boolean']>;
  makePublic?: InputMaybe<Scalars['Boolean']>;
  isHalfDay?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  creatorId?: InputMaybe<Scalars['ID']>;
  attachementIds: Array<Scalars['ID']> | Scalars['ID'];
  validatorIds: Array<Scalars['ID']> | Scalars['ID'];
  peopleToNotifyIds: Array<Scalars['ID']> | Scalars['ID'];
  daysDuration?: InputMaybe<Array<LeaveDayDuration> | LeaveDayDuration>;
}>;

export type CreateLeaveMutation = {
  __typename?: 'Mutation';
  createLeave: { __typename?: 'Leave'; id: string };
};

export type ValidateLeaveMutationVariables = Exact<{
  id: Scalars['ID'];
  validatorId?: InputMaybe<Scalars['ID']>;
  status: LeaveStatus;
}>;

export type ValidateLeaveMutation = {
  __typename?: 'Mutation';
  validateLeave: { __typename?: 'Leave'; id: string; status?: LeaveStatus | null | undefined };
};

export type UpdateLeaveMutationVariables = Exact<{
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  ending: Scalars['DateTime'];
  reasonId: Scalars['ID'];
  otherReason?: InputMaybe<Scalars['String']>;
  specialDayOff: Scalars['Boolean'];
  makePublic: Scalars['Boolean'];
  isHalfDay?: InputMaybe<Scalars['Boolean']>;
  attachementIds: Array<Scalars['ID']> | Scalars['ID'];
  validatorIds: Array<Scalars['ID']> | Scalars['ID'];
  peopleToNotifyIds: Array<Scalars['ID']> | Scalars['ID'];
  daysDuration?: InputMaybe<Array<LeaveDayDuration> | LeaveDayDuration>;
}>;

export type UpdateLeaveMutation = {
  __typename?: 'Mutation';
  updateLeave: {
    __typename?: 'Leave';
    id: string;
    start?: any | null | undefined;
    ending?: any | null | undefined;
    otherReason?: string | null | undefined;
    specialDayOff?: boolean | null | undefined;
    isPublic?: boolean | null | undefined;
    status?: LeaveStatus | null | undefined;
    isHalfDay?: boolean | null | undefined;
  };
};

export type DeleteLeaveMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteLeaveMutation = {
  __typename?: 'Mutation';
  deleteLeave: { __typename?: 'Leave'; id: string };
};

export type ValidatedLeavesMutationVariables = Exact<{
  start: Scalars['DateTime'];
  ending: Scalars['DateTime'];
  companyMemberIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  reasonIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  leavesStatusToDownload?: InputMaybe<LeavesStatusToDownload>;
}>;

export type ValidatedLeavesMutation = {
  __typename?: 'Mutation';
  validatedLeaves: Array<{
    __typename?: 'LeavesGroupedByDay';
    day: any;
    leaves: Array<{
      __typename?: 'Leave';
      id: string;
      start?: any | null | undefined;
      ending?: any | null | undefined;
      days?: number | null | undefined;
      otherReason?: string | null | undefined;
      specialDayOff?: boolean | null | undefined;
      isHalfDay?: boolean | null | undefined;
      isPublic?: boolean | null | undefined;
      status?: LeaveStatus | null | undefined;
      validationDate?: any | null | undefined;
      reasons?:
        | Array<{ __typename?: 'LeaveReason'; id: string; title?: string | null | undefined }>
        | null
        | undefined;
      owner?:
        | {
            __typename?: 'CompanyMember';
            id: string;
            businessUnit?:
              | { __typename?: 'BusinessUnit'; id: string; name?: string | null | undefined }
              | null
              | undefined;
            userCommunity?:
              | Array<{
                  __typename?: 'UserCommunity';
                  id: string;
                  community?:
                    | { __typename?: 'Community'; id: string; name?: string | null | undefined }
                    | null
                    | undefined;
                }>
              | null
              | undefined;
            user?:
              | {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  fullName?: string | null | undefined;
                  email?: string | null | undefined;
                }
              | null
              | undefined;
            nPlusOne?:
              | {
                  __typename?: 'CompanyMember';
                  id: string;
                  user?:
                    | {
                        __typename?: 'User';
                        id: string;
                        firstName?: string | null | undefined;
                        lastName?: string | null | undefined;
                        fullName?: string | null | undefined;
                        email?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      attachements?:
        | Array<{ __typename?: 'File'; id: string; name?: string | null | undefined }>
        | null
        | undefined;
      validators?:
        | Array<{
            __typename?: 'CompanyMember';
            id: string;
            user?:
              | { __typename?: 'User'; id: string; fullName?: string | null | undefined }
              | null
              | undefined;
          }>
        | null
        | undefined;
    }>;
  }>;
};

export type GetCurrentLeaveReasonsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentLeaveReasonsQuery = {
  __typename?: 'Query';
  leaveReasonsByCompany: Array<{
    __typename?: 'LeaveReason';
    id: string;
    title?: string | null | undefined;
  }>;
};

export type GetAllowedViewLeavesToMeQueryVariables = Exact<{
  date: Scalars['DateTime'];
  view: Scalars['String'];
  dateRange?: InputMaybe<CalendarDateRangeInput>;
  communityId?: InputMaybe<Scalars['ID']>;
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  statusFilter?: InputMaybe<LeaveStatusFilter>;
}>;

export type GetAllowedViewLeavesToMeQuery = {
  __typename?: 'Query';
  leaves: Array<{
    __typename?: 'Leave';
    id: string;
    start?: any | null | undefined;
    ending?: any | null | undefined;
    days?: number | null | undefined;
    isMine?: boolean | null | undefined;
    isHalfDay?: boolean | null | undefined;
    daysDuration?: Array<LeaveDayDuration> | null | undefined;
    allowToDelete?: boolean | null | undefined;
    status?: LeaveStatus | null | undefined;
    owner?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                photoUrl?: string | null | undefined;
              }
            | null
            | undefined;
          userPreference?:
            | {
                __typename?: 'UserPreference';
                id: string;
                userTimezone?:
                  | { __typename?: 'UserTimezone'; id: string; tzCode?: string | null | undefined }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetLeavesByDateQueryVariables = Exact<{
  date: Scalars['String'];
  mine?: InputMaybe<Scalars['Boolean']>;
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  communitiesFilter?: InputMaybe<CommunitiesFilter>;
  statusFilter?: InputMaybe<LeaveStatusFilter>;
}>;

export type GetLeavesByDateQuery = {
  __typename?: 'Query';
  leavesByDate: {
    __typename?: 'LeavesByDate';
    date: any;
    leaveCount: number;
    leaves: Array<{
      __typename?: 'Leave';
      id: string;
      start?: any | null | undefined;
      ending?: any | null | undefined;
      days?: number | null | undefined;
      otherReason?: string | null | undefined;
      specialDayOff?: boolean | null | undefined;
      isPublic?: boolean | null | undefined;
      status?: LeaveStatus | null | undefined;
      isHalfDay?: boolean | null | undefined;
      daysDuration?: Array<LeaveDayDuration> | null | undefined;
      allowToDelete?: boolean | null | undefined;
      isMine?: boolean | null | undefined;
      fileCount?: number | null | undefined;
      reasons?:
        | Array<{ __typename?: 'LeaveReason'; id: string; title?: string | null | undefined }>
        | null
        | undefined;
      owner?:
        | {
            __typename?: 'CompanyMember';
            id: string;
            user?:
              | {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  fullName?: string | null | undefined;
                  photoPublicUrl?: string | null | undefined;
                  photoUrl?: string | null | undefined;
                }
              | null
              | undefined;
            nPlusOne?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
            userPreference?:
              | {
                  __typename?: 'UserPreference';
                  id: string;
                  userTimezone?:
                    | {
                        __typename?: 'UserTimezone';
                        id: string;
                        tzCode?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      attachements?:
        | Array<{
            __typename?: 'File';
            id: string;
            type?: string | null | undefined;
            size?: number | null | undefined;
            path?: string | null | undefined;
            name?: string | null | undefined;
            publicUrl?: string | null | undefined;
            extension?: string | null | undefined;
          }>
        | null
        | undefined;
      imageFiles?:
        | Array<{
            __typename?: 'File';
            id: string;
            type?: string | null | undefined;
            size?: number | null | undefined;
            path?: string | null | undefined;
            name?: string | null | undefined;
            publicUrl?: string | null | undefined;
            extension?: string | null | undefined;
          }>
        | null
        | undefined;
      validators?: Array<{ __typename?: 'CompanyMember'; id: string }> | null | undefined;
      peoplesToNotify?: Array<{ __typename?: 'CompanyMember'; id: string }> | null | undefined;
      comments?:
        | Array<{
            __typename?: 'Comment';
            id: string;
            content?: string | null | undefined;
            createdAt?: any | null | undefined;
            isMine?: boolean | null | undefined;
            likeCount?: number | null | undefined;
            isLikedByMe?: boolean | null | undefined;
            companyMember?:
              | {
                  __typename?: 'CompanyMember';
                  id: string;
                  user?:
                    | {
                        __typename?: 'User';
                        id: string;
                        fullName?: string | null | undefined;
                        firstName?: string | null | undefined;
                        lastName?: string | null | undefined;
                        photoUrl?: string | null | undefined;
                        photoPublicUrl?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
            likedBy?:
              | Array<{
                  __typename?: 'CompanyMember';
                  user?:
                    | {
                        __typename?: 'User';
                        id: string;
                        fullName?: string | null | undefined;
                        firstName?: string | null | undefined;
                        lastName?: string | null | undefined;
                        photoUrl?: string | null | undefined;
                        photoPublicUrl?: string | null | undefined;
                        currentCompanyMember?:
                          | { __typename?: 'CompanyMember'; id: string }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined;
                }>
              | null
              | undefined;
          }>
        | null
        | undefined;
    }>;
    owners: Array<{
      __typename?: 'CompanyMember';
      id: string;
      user?:
        | {
            __typename?: 'User';
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
            fullName?: string | null | undefined;
            photoPublicUrl?: string | null | undefined;
            photoUrl?: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export type CheckIsCompanyMemberALeaveValidatorQueryVariables = Exact<{
  id: Scalars['ID'];
  companyMemberId?: InputMaybe<Scalars['ID']>;
}>;

export type CheckIsCompanyMemberALeaveValidatorQuery = {
  __typename?: 'Query';
  isMeAValidator: boolean;
};

export type GetLeaveQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetLeaveQuery = {
  __typename?: 'Query';
  leave?:
    | {
        __typename?: 'Leave';
        id: string;
        start?: any | null | undefined;
        ending?: any | null | undefined;
        days?: number | null | undefined;
        otherReason?: string | null | undefined;
        specialDayOff?: boolean | null | undefined;
        isPublic?: boolean | null | undefined;
        status?: LeaveStatus | null | undefined;
        isHalfDay?: boolean | null | undefined;
        daysDuration?: Array<LeaveDayDuration> | null | undefined;
        allowToDelete?: boolean | null | undefined;
        isMine?: boolean | null | undefined;
        reasons?:
          | Array<{ __typename?: 'LeaveReason'; id: string; title?: string | null | undefined }>
          | null
          | undefined;
        owner?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                  }
                | null
                | undefined;
              nPlusOne?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
              userPreference?:
                | {
                    __typename?: 'UserPreference';
                    id: string;
                    userTimezone?:
                      | {
                          __typename?: 'UserTimezone';
                          id: string;
                          tzCode?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        attachements?:
          | Array<{
              __typename?: 'File';
              id: string;
              type?: string | null | undefined;
              size?: number | null | undefined;
              path?: string | null | undefined;
              name?: string | null | undefined;
              publicUrl?: string | null | undefined;
              extension?: string | null | undefined;
            }>
          | null
          | undefined;
        validators?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        peoplesToNotify?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        comments?:
          | Array<{
              __typename?: 'Comment';
              id: string;
              content?: string | null | undefined;
              createdAt?: any | null | undefined;
              isMine?: boolean | null | undefined;
              likeCount?: number | null | undefined;
              isLikedByMe?: boolean | null | undefined;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              likedBy?:
                | Array<{
                    __typename?: 'CompanyMember';
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                          currentCompanyMember?:
                            | { __typename?: 'CompanyMember'; id: string }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetLeavesByCalendarFilterHistoryQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
  communityId?: InputMaybe<Scalars['String']>;
  communityFilter?: InputMaybe<CommunitiesFilter>;
  statusFilter?: InputMaybe<LeaveStatus>;
  validatorIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetLeavesByCalendarFilterHistoryQuery = {
  __typename?: 'Query';
  leavesByCalendarFilterHistory: Array<{
    __typename?: 'Leave';
    id: string;
    start?: any | null | undefined;
    ending?: any | null | undefined;
    status?: LeaveStatus | null | undefined;
    allowToDelete?: boolean | null | undefined;
    owner?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                photoUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
  _leavesByCalendarFilterHistoryMeta: {
    __typename?: 'LeaveMetadata';
    count: number;
    owners: Array<{
      __typename?: 'CompanyMember';
      id: string;
      user?:
        | {
            __typename?: 'User';
            id: string;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
            fullName?: string | null | undefined;
            photoPublicUrl?: string | null | undefined;
            photoUrl?: string | null | undefined;
          }
        | null
        | undefined;
    }>;
  };
};

export type ExportLeavesQueryVariables = Exact<{
  start: Scalars['DateTime'];
  ending: Scalars['DateTime'];
  reasonIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  businessUnitIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  companyMemberIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  dateRangeType: LeavesDateRangeType;
}>;

export type ExportLeavesQuery = {
  __typename?: 'Query';
  exportLeaves: Array<{
    __typename?: 'LeavesGroupedByDay';
    day: any;
    leaves: Array<{
      __typename?: 'Leave';
      id: string;
      start?: any | null | undefined;
      ending?: any | null | undefined;
      days?: number | null | undefined;
      otherReason?: string | null | undefined;
      specialDayOff?: boolean | null | undefined;
      isHalfDay?: boolean | null | undefined;
      daysDuration?: Array<LeaveDayDuration> | null | undefined;
      isPublic?: boolean | null | undefined;
      status?: LeaveStatus | null | undefined;
      validationDate?: any | null | undefined;
      reasons?:
        | Array<{ __typename?: 'LeaveReason'; id: string; title?: string | null | undefined }>
        | null
        | undefined;
      owner?:
        | {
            __typename?: 'CompanyMember';
            id: string;
            businessUnit?:
              | { __typename?: 'BusinessUnit'; id: string; name?: string | null | undefined }
              | null
              | undefined;
            userCommunity?:
              | Array<{
                  __typename?: 'UserCommunity';
                  id: string;
                  community?:
                    | { __typename?: 'Community'; id: string; name?: string | null | undefined }
                    | null
                    | undefined;
                }>
              | null
              | undefined;
            user?:
              | {
                  __typename?: 'User';
                  id: string;
                  firstName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  fullName?: string | null | undefined;
                  email?: string | null | undefined;
                }
              | null
              | undefined;
            nPlusOne?:
              | {
                  __typename?: 'CompanyMember';
                  id: string;
                  user?:
                    | {
                        __typename?: 'User';
                        id: string;
                        firstName?: string | null | undefined;
                        lastName?: string | null | undefined;
                        fullName?: string | null | undefined;
                        email?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined;
      attachements?:
        | Array<{ __typename?: 'File'; id: string; name?: string | null | undefined }>
        | null
        | undefined;
      validators?:
        | Array<{
            __typename?: 'CompanyMember';
            id: string;
            user?:
              | { __typename?: 'User'; id: string; fullName?: string | null | undefined }
              | null
              | undefined;
          }>
        | null
        | undefined;
    }>;
  }>;
};

export type GetCoachStateQueryVariables = Exact<{ [key: string]: never }>;

export type GetCoachStateQuery = {
  __typename?: 'Query';
  coach?:
    | {
        __typename?: 'Coach';
        visible?: boolean | null | undefined;
        emotion?: CoachEmotion | null | undefined;
        events?:
          | Array<
              | {
                  __typename?: 'Event';
                  eventId?: string | null | undefined;
                  message?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCongratsDialogStateQueryVariables = Exact<{ [key: string]: never }>;

export type GetCongratsDialogStateQuery = {
  __typename?: 'Query';
  congratsDialog?:
    | {
        __typename?: 'CongratsDialog';
        title?: string | null | undefined;
        contentTitle?: string | null | undefined;
        contentDescription?: string | null | undefined;
        isOpen?: boolean | null | undefined;
        redirectUrl?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetExpLevelUpDialogQueryVariables = Exact<{ [key: string]: never }>;

export type GetExpLevelUpDialogQuery = {
  __typename?: 'Query';
  expLevelUpDialog: { __typename?: 'ExpLevelUpDialog'; open: boolean };
};

export type GetCommunityExpLevelUpDialogQueryVariables = Exact<{ [key: string]: never }>;

export type GetCommunityExpLevelUpDialogQuery = {
  __typename?: 'Query';
  communityExpLevelUpDialog: { __typename?: 'CommunityExpLevelUpDialog'; open: boolean };
};

export type GetSnackbarStateQueryVariables = Exact<{ [key: string]: never }>;

export type GetSnackbarStateQuery = {
  __typename?: 'Query';
  snackBar?:
    | {
        __typename?: 'SnackBar';
        type?: string | null | undefined;
        message?: string | null | undefined;
        isOpen?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type GetCurrentCompanyCommunitiesQueryVariables = Exact<{
  communitySourceId?: InputMaybe<Scalars['ID']>;
}>;

export type GetCurrentCompanyCommunitiesQuery = {
  __typename?: 'Query';
  currentCompany: {
    __typename?: 'Company';
    id: string;
    communities?:
      | Array<{
          __typename?: 'Community';
          unresolvedProblemCount?: number | null | undefined;
          resolvedProblemCount?: number | null | undefined;
          id: string;
          name?: string | null | undefined;
          mission?: string | null | undefined;
          memberCount?: number | null | undefined;
          coins?: number | null | undefined;
          members?:
            | Array<{
                __typename?: 'UserCommunity';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      email?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
                companyMember?:
                  | {
                      __typename?: 'CompanyMember';
                      id: string;
                      jobTitle?: string | null | undefined;
                      user?:
                        | {
                            __typename?: 'User';
                            id: string;
                            firstName?: string | null | undefined;
                            fullName?: string | null | undefined;
                            lastName?: string | null | undefined;
                            email?: string | null | undefined;
                            photoUrl?: string | null | undefined;
                            photoPublicUrl?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
          indicators?:
            | Array<{
                __typename?: 'Indicator';
                id: string;
                name?: string | null | undefined;
                action?: IndicatorAction | null | undefined;
                trend?: IndicatorTrend | null | undefined;
                level?: IndicatorLevel | null | undefined;
                value?: string | null | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetTargetCommunityQueryVariables = Exact<{
  id: Scalars['ID'];
  communitySourceId?: InputMaybe<Scalars['ID']>;
}>;

export type GetTargetCommunityQuery = {
  __typename?: 'Query';
  Community: {
    __typename?: 'Community';
    unresolvedProblemCount?: number | null | undefined;
    resolvedProblemCount?: number | null | undefined;
    id: string;
    name?: string | null | undefined;
    mission?: string | null | undefined;
    memberCount?: number | null | undefined;
    coins?: number | null | undefined;
    members?:
      | Array<{
          __typename?: 'UserCommunity';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                fullName?: string | null | undefined;
                lastName?: string | null | undefined;
                email?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                jobTitle?: string | null | undefined;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      email?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    indicators?:
      | Array<{
          __typename?: 'Indicator';
          id: string;
          name?: string | null | undefined;
          action?: IndicatorAction | null | undefined;
          trend?: IndicatorTrend | null | undefined;
          level?: IndicatorLevel | null | undefined;
          value?: string | null | undefined;
        }>
      | null
      | undefined;
    connectedCommunities?:
      | Array<{
          __typename?: 'Community';
          unresolvedProblemCount?: number | null | undefined;
          resolvedProblemCount?: number | null | undefined;
          id: string;
          name?: string | null | undefined;
          mission?: string | null | undefined;
          memberCount?: number | null | undefined;
          coins?: number | null | undefined;
          members?:
            | Array<{
                __typename?: 'UserCommunity';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      email?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
                companyMember?:
                  | {
                      __typename?: 'CompanyMember';
                      id: string;
                      jobTitle?: string | null | undefined;
                      user?:
                        | {
                            __typename?: 'User';
                            id: string;
                            firstName?: string | null | undefined;
                            fullName?: string | null | undefined;
                            lastName?: string | null | undefined;
                            email?: string | null | undefined;
                            photoUrl?: string | null | undefined;
                            photoPublicUrl?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
          indicators?:
            | Array<{
                __typename?: 'Indicator';
                id: string;
                name?: string | null | undefined;
                action?: IndicatorAction | null | undefined;
                trend?: IndicatorTrend | null | undefined;
                level?: IndicatorLevel | null | undefined;
                value?: string | null | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type MeetingLocalInfoFragment = {
  __typename?: 'Meeting';
  id: string;
  topic?: string | null | undefined;
  dueDate?: any | null | undefined;
  details?: string | null | undefined;
  createdAt?: any | null | undefined;
  passcode?: string | null | undefined;
  invitationLink?: string | null | undefined;
  timezone?: string | null | undefined;
  status?: Meeting_Status | null | undefined;
  isMine?: boolean | null | undefined;
  duration?: number | null | undefined;
  isPublic?: boolean | null | undefined;
  isPast?: boolean | null | undefined;
};

export type MeetingInfoFragment = {
  __typename?: 'ZoomMeetingByRegistrantFullResponse';
  id?: string | null | undefined;
  agenda?: string | null | undefined;
  created_at?: any | null | undefined;
  duration?: number | null | undefined;
  host_id?: string | null | undefined;
  join_url?: string | null | undefined;
  start_time?: any | null | undefined;
  timezone?: string | null | undefined;
  topic?: string | null | undefined;
  status?: Meeting_Status | null | undefined;
  registration_url?: string | null | undefined;
  uuid?: string | null | undefined;
  contact_name?: string | null | undefined;
  contact_email?: string | null | undefined;
};

export type RegistrantInfoFragment = {
  __typename?: 'AddZoomMeetingRegistrantResponse';
  id?: string | null | undefined;
  email?: string | null | undefined;
  first_name?: string | null | undefined;
  last_name?: string | null | undefined;
  join_url?: string | null | undefined;
  registrant_id?: string | null | undefined;
  create_time?: any | null | undefined;
  topic?: string | null | undefined;
  status?: ZoomMeetingRegistrantStatus | null | undefined;
  address?: string | null | undefined;
  zip?: string | null | undefined;
};

export type CreateMeetingMutationVariables = Exact<{
  makePublic: Scalars['Boolean'];
  topic: Scalars['String'];
  dueDate: Scalars['DateTime'];
  timezone: Scalars['String'];
  passcode: Scalars['String'];
  duration: Scalars['Int'];
  details?: InputMaybe<Scalars['String']>;
  attendeeIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
  invitees: Array<MeetingInviteeInput> | MeetingInviteeInput;
  contact_name: Scalars['String'];
  contact_email: Scalars['String'];
  schedule_for?: InputMaybe<Scalars['String']>;
  alternative_hosts?: InputMaybe<Scalars['String']>;
  externalEmails?: InputMaybe<Array<CreateExternalEmailInput> | CreateExternalEmailInput>;
}>;

export type CreateMeetingMutation = {
  __typename?: 'Mutation';
  createMeeting?:
    | {
        __typename?: 'Meeting';
        id: string;
        topic?: string | null | undefined;
        dueDate?: any | null | undefined;
        details?: string | null | undefined;
        createdAt?: any | null | undefined;
        passcode?: string | null | undefined;
        invitationLink?: string | null | undefined;
        timezone?: string | null | undefined;
        status?: Meeting_Status | null | undefined;
        isMine?: boolean | null | undefined;
        duration?: number | null | undefined;
        isPublic?: boolean | null | undefined;
        isPast?: boolean | null | undefined;
        attendees?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                  }
                | null
                | undefined;
              myCommunities?:
                | Array<{ __typename?: 'Community'; id: string; name?: string | null | undefined }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateMeetingMutationVariables = Exact<{
  makePublic: Scalars['Boolean'];
  uuid: Scalars['ID'];
  topic: Scalars['String'];
  dueDate: Scalars['DateTime'];
  timezone: Scalars['String'];
  duration: Scalars['Int'];
  passcode: Scalars['String'];
  details?: InputMaybe<Scalars['String']>;
  attendeeIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
  invitees: Array<MeetingInviteeInput> | MeetingInviteeInput;
  contact_name: Scalars['String'];
  contact_email: Scalars['String'];
  externalEmails?: InputMaybe<Array<UpdateExternalEmailInput> | UpdateExternalEmailInput>;
}>;

export type UpdateMeetingMutation = {
  __typename?: 'Mutation';
  updateMeeting?:
    | {
        __typename?: 'Meeting';
        id: string;
        topic?: string | null | undefined;
        dueDate?: any | null | undefined;
        details?: string | null | undefined;
        createdAt?: any | null | undefined;
        passcode?: string | null | undefined;
        invitationLink?: string | null | undefined;
        timezone?: string | null | undefined;
        status?: Meeting_Status | null | undefined;
        isMine?: boolean | null | undefined;
        duration?: number | null | undefined;
        isPublic?: boolean | null | undefined;
        isPast?: boolean | null | undefined;
        attendees?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                  }
                | null
                | undefined;
              myCommunities?:
                | Array<{ __typename?: 'Community'; id: string; name?: string | null | undefined }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteMeetingMutationVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type DeleteMeetingMutation = {
  __typename?: 'Mutation';
  deleteMeeting?:
    | {
        __typename?: 'Meeting';
        id: string;
        topic?: string | null | undefined;
        dueDate?: any | null | undefined;
        details?: string | null | undefined;
        createdAt?: any | null | undefined;
        passcode?: string | null | undefined;
        invitationLink?: string | null | undefined;
        timezone?: string | null | undefined;
        status?: Meeting_Status | null | undefined;
        isMine?: boolean | null | undefined;
        duration?: number | null | undefined;
        isPublic?: boolean | null | undefined;
        isPast?: boolean | null | undefined;
        attendees?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                  }
                | null
                | undefined;
              myCommunities?:
                | Array<{ __typename?: 'Community'; id: string; name?: string | null | undefined }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateZoomMeetingRegistrantStatusMutationVariables = Exact<{
  meetingId: Scalars['String'];
  timezone: Scalars['String'];
  action: MeetingRegistrantStatusAction;
  registrants: Array<UpdateZoomMeetingRegistrantInput> | UpdateZoomMeetingRegistrantInput;
}>;

export type UpdateZoomMeetingRegistrantStatusMutation = {
  __typename?: 'Mutation';
  updateZoomMeetingRegistrantStatus?: boolean | null | undefined;
};

export type DeleteZoomMeetingRegistrantMutationVariables = Exact<{
  meetingId: Scalars['String'];
  registrantId: Scalars['String'];
}>;

export type DeleteZoomMeetingRegistrantMutation = {
  __typename?: 'Mutation';
  deleteZoomMeetingRegistrant?: boolean | null | undefined;
};

export type DeleteZoomMeetingMutationVariables = Exact<{
  meetingId: Scalars['String'];
}>;

export type DeleteZoomMeetingMutation = {
  __typename?: 'Mutation';
  deleteZoomMeeting?: boolean | null | undefined;
};

export type ZoomMeetingByMeeetingIdQueryVariables = Exact<{
  meetingId: Scalars['String'];
}>;

export type ZoomMeetingByMeeetingIdQuery = {
  __typename?: 'Query';
  zoomMeetingByMeeetingId?:
    | {
        __typename?: 'CreateZoomMeetingResponse';
        id?: string | null | undefined;
        assistant_id?: string | null | undefined;
        host_email?: string | null | undefined;
        registration_url?: string | null | undefined;
        agenda?: string | null | undefined;
        created_at?: any | null | undefined;
        duration?: number | null | undefined;
        join_url?: string | null | undefined;
        password?: string | null | undefined;
        start_time?: any | null | undefined;
        start_url?: string | null | undefined;
        timezone?: string | null | undefined;
        topic?: string | null | undefined;
        type?: number | null | undefined;
        settings?:
          | {
              __typename?: 'CreateZoomMeetingSettings';
              contact_name?: string | null | undefined;
              contact_email?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ZoomMeetingsByZoomUserQueryVariables = Exact<{
  zoomUserId: Scalars['String'];
}>;

export type ZoomMeetingsByZoomUserQuery = {
  __typename?: 'Query';
  zoomMeetingsByZoomUser?:
    | {
        __typename?: 'ZoomMeetingsResponse';
        total_records?: number | null | undefined;
        meetings?:
          | Array<
              | {
                  __typename?: 'ZoomMeetingResponse';
                  created_at?: any | null | undefined;
                  agenda?: string | null | undefined;
                  duration?: number | null | undefined;
                  host_id?: string | null | undefined;
                  id?: string | null | undefined;
                  join_url?: string | null | undefined;
                  pmi?: string | null | undefined;
                  start_time?: any | null | undefined;
                  timezone?: string | null | undefined;
                  topic?: string | null | undefined;
                  type?: number | null | undefined;
                  uuid?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type MyZoomMeetingsQueryVariables = Exact<{
  companyMemberId?: InputMaybe<Scalars['String']>;
}>;

export type MyZoomMeetingsQuery = {
  __typename?: 'Query';
  myZoomMeetings?:
    | Array<
        | {
            __typename?: 'ZoomMeetingByRegistrantFullResponse';
            id?: string | null | undefined;
            agenda?: string | null | undefined;
            created_at?: any | null | undefined;
            duration?: number | null | undefined;
            host_id?: string | null | undefined;
            join_url?: string | null | undefined;
            start_time?: any | null | undefined;
            timezone?: string | null | undefined;
            topic?: string | null | undefined;
            status?: Meeting_Status | null | undefined;
            registration_url?: string | null | undefined;
            uuid?: string | null | undefined;
            contact_name?: string | null | undefined;
            contact_email?: string | null | undefined;
            registrants?:
              | Array<
                  | {
                      __typename?: 'AddZoomMeetingRegistrantResponse';
                      id?: string | null | undefined;
                      email?: string | null | undefined;
                      first_name?: string | null | undefined;
                      last_name?: string | null | undefined;
                      join_url?: string | null | undefined;
                      registrant_id?: string | null | undefined;
                      create_time?: any | null | undefined;
                      topic?: string | null | undefined;
                      status?: ZoomMeetingRegistrantStatus | null | undefined;
                      address?: string | null | undefined;
                      zip?: string | null | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type ZoomMeetingFullByMeetingIdQueryVariables = Exact<{
  meetingId?: InputMaybe<Scalars['String']>;
  withPending?: InputMaybe<Scalars['Boolean']>;
}>;

export type ZoomMeetingFullByMeetingIdQuery = {
  __typename?: 'Query';
  zoomMeetingFullByMeetingId?:
    | {
        __typename?: 'ZoomMeetingByRegistrantFullResponse';
        id?: string | null | undefined;
        agenda?: string | null | undefined;
        created_at?: any | null | undefined;
        duration?: number | null | undefined;
        host_id?: string | null | undefined;
        join_url?: string | null | undefined;
        start_time?: any | null | undefined;
        timezone?: string | null | undefined;
        topic?: string | null | undefined;
        status?: Meeting_Status | null | undefined;
        registration_url?: string | null | undefined;
        uuid?: string | null | undefined;
        contact_name?: string | null | undefined;
        contact_email?: string | null | undefined;
        registrants?:
          | Array<
              | {
                  __typename?: 'AddZoomMeetingRegistrantResponse';
                  id?: string | null | undefined;
                  email?: string | null | undefined;
                  first_name?: string | null | undefined;
                  last_name?: string | null | undefined;
                  join_url?: string | null | undefined;
                  registrant_id?: string | null | undefined;
                  create_time?: any | null | undefined;
                  topic?: string | null | undefined;
                  status?: ZoomMeetingRegistrantStatus | null | undefined;
                  address?: string | null | undefined;
                  zip?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ZoomMeetingRegistrantsByMeeetingIdQueryVariables = Exact<{
  meetingId: Scalars['String'];
  status?: InputMaybe<ZoomMeetingRegistrantStatus>;
}>;

export type ZoomMeetingRegistrantsByMeeetingIdQuery = {
  __typename?: 'Query';
  zoomMeetingRegistrantsByMeeetingId?:
    | {
        __typename?: 'ZoomMeetingRegistrantResponse';
        total_records?: number | null | undefined;
        registrants?:
          | Array<
              | {
                  __typename?: 'AddZoomMeetingRegistrantResponse';
                  id?: string | null | undefined;
                  email?: string | null | undefined;
                  first_name?: string | null | undefined;
                  last_name?: string | null | undefined;
                  join_url?: string | null | undefined;
                  registrant_id?: string | null | undefined;
                  create_time?: any | null | undefined;
                  topic?: string | null | undefined;
                  status?: ZoomMeetingRegistrantStatus | null | undefined;
                  address?: string | null | undefined;
                  zip?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type RefreshAccessTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type RefreshAccessTokenQuery = {
  __typename?: 'Query';
  refreshAccessToken?:
    | {
        __typename?: 'ZoomRefreshTokenResponse';
        access_token?: string | null | undefined;
        token_type?: string | null | undefined;
        refresh_token?: string | null | undefined;
        expires_in?: string | null | undefined;
      }
    | null
    | undefined;
};

export type MyMeetingsQueryVariables = Exact<{
  status?: InputMaybe<RegistrantStatus>;
  dateRange?: InputMaybe<CalendarDateRangeInput>;
}>;

export type MyMeetingsQuery = {
  __typename?: 'Query';
  myMeetings: Array<{
    __typename?: 'Meeting';
    id: string;
    uuid?: string | null | undefined;
    topic?: string | null | undefined;
    duration?: number | null | undefined;
    timezone?: string | null | undefined;
    dueDate?: any | null | undefined;
    details?: string | null | undefined;
    createdAt?: any | null | undefined;
    passcode?: string | null | undefined;
    invitationLink?: string | null | undefined;
    icsLink?: string | null | undefined;
    icsPublicLink?: string | null | undefined;
    humanDueDate?: string | null | undefined;
    isMine?: boolean | null | undefined;
    isPublic?: boolean | null | undefined;
    isPast?: boolean | null | undefined;
    myRegistrantId?: string | null | undefined;
    isAcceptedByMe?: boolean | null | undefined;
    isDeclinedByMe?: boolean | null | undefined;
    company?: { __typename?: 'Company'; id: string } | null | undefined;
    registrants?:
      | Array<{
          __typename?: 'MeetingRegistrant';
          uuid: string;
          status?: RegistrantStatus | null | undefined;
          companyMemberId: string;
          zoomRegistrantId?: string | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      email?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  }>;
};

export type MeetingByIdQueryVariables = Exact<{
  uuid: Scalars['ID'];
}>;

export type MeetingByIdQuery = {
  __typename?: 'Query';
  meetingById?:
    | {
        __typename?: 'Meeting';
        id: string;
        uuid?: string | null | undefined;
        topic?: string | null | undefined;
        duration?: number | null | undefined;
        timezone?: string | null | undefined;
        dueDate?: any | null | undefined;
        details?: string | null | undefined;
        createdAt?: any | null | undefined;
        removed?: boolean | null | undefined;
        passcode?: string | null | undefined;
        invitationLink?: string | null | undefined;
        icsLink?: string | null | undefined;
        icsPublicLink?: string | null | undefined;
        humanDueDate?: string | null | undefined;
        myRegistrantId?: string | null | undefined;
        creatorRegistrantId?: string | null | undefined;
        currentConnectedJoinUrl?: string | null | undefined;
        joinUrl?: string | null | undefined;
        isMine?: boolean | null | undefined;
        isPublic?: boolean | null | undefined;
        isPast?: boolean | null | undefined;
        isAlreadyApproved?: boolean | null | undefined;
        isAlreadyDenied?: boolean | null | undefined;
        externalEmails?:
          | Array<{
              __typename?: 'ExternalEmail';
              id: string;
              status?: RegistrantStatus | null | undefined;
              email: string;
            }>
          | null
          | undefined;
        company?: { __typename?: 'Company'; id: string } | null | undefined;
        createdBy?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
        registrants?:
          | Array<{
              __typename?: 'MeetingRegistrant';
              uuid: string;
              status?: RegistrantStatus | null | undefined;
              zoomRegistrantId?: string | null | undefined;
              companyMemberId: string;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          fullName?: string | null | undefined;
                          email?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        comments?:
          | Array<{
              __typename?: 'Comment';
              id: string;
              content?: string | null | undefined;
              createdAt?: any | null | undefined;
              isMine?: boolean | null | undefined;
              likeCount?: number | null | undefined;
              isLikedByMe?: boolean | null | undefined;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              likedBy?:
                | Array<{
                    __typename?: 'CompanyMember';
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                          currentCompanyMember?:
                            | { __typename?: 'CompanyMember'; id: string }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
  userTimezone?:
    | {
        __typename?: 'UserTimezone';
        id: string;
        label?: string | null | undefined;
        name?: string | null | undefined;
        tzCode?: string | null | undefined;
        utc?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetMeetingsByCalendarFilterHistoryQueryVariables = Exact<{
  perPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  keyWord?: InputMaybe<Scalars['String']>;
  dateRangeStart?: InputMaybe<Scalars['DateTime']>;
  dateRangeEnd?: InputMaybe<Scalars['DateTime']>;
}>;

export type GetMeetingsByCalendarFilterHistoryQuery = {
  __typename?: 'Query';
  meetingsByCalendarFilterHistory: Array<{
    __typename?: 'Meeting';
    id: string;
    uuid?: string | null | undefined;
    topic?: string | null | undefined;
    dueDate?: any | null | undefined;
    timezone?: string | null | undefined;
    duration?: number | null | undefined;
    joinUrl?: string | null | undefined;
    isMine?: boolean | null | undefined;
    icsPublicLink?: string | null | undefined;
    isAlreadyApproved?: boolean | null | undefined;
    isAlreadyDenied?: boolean | null | undefined;
    myRegistrantId?: string | null | undefined;
    registrants?:
      | Array<{
          __typename?: 'MeetingRegistrant';
          uuid: string;
          status?: RegistrantStatus | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    createdBy?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
  }>;
  _meetingsByCalendarFilterHistoryMeta: { __typename?: 'ListMetadata'; count: number };
};

export type NotificationInfoFragment = {
  __typename?: 'Notification';
  id: string;
  title?: string | null | undefined;
  description?: string | null | undefined;
  date?: any | null | undefined;
  linkTo?: string | null | undefined;
  isSeen?: boolean | null | undefined;
  seenDate?: any | null | undefined;
  createdAt?: any | null | undefined;
  isValid?: boolean | null | undefined;
  parametres?: Array<string | null | undefined> | null | undefined;
};

export type MarkAllAsReadMutationVariables = Exact<{ [key: string]: never }>;

export type MarkAllAsReadMutation = {
  __typename?: 'Mutation';
  markAllAsRead?: boolean | null | undefined;
};

export type SeeNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SeeNotificationMutation = {
  __typename?: 'Mutation';
  seeNotification?:
    | {
        __typename?: 'Notification';
        id: string;
        title?: string | null | undefined;
        description?: string | null | undefined;
        date?: any | null | undefined;
        linkTo?: string | null | undefined;
        isSeen?: boolean | null | undefined;
        seenDate?: any | null | undefined;
        createdAt?: any | null | undefined;
        isValid?: boolean | null | undefined;
        parametres?: Array<string | null | undefined> | null | undefined;
        companyMemberSender?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type CreateNotificationMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  receiverIds: Array<Scalars['ID']> | Scalars['ID'];
  linkTo?: InputMaybe<Scalars['String']>;
}>;

export type CreateNotificationMutation = {
  __typename?: 'Mutation';
  createNotification?: Array<{ __typename?: 'Notification'; id: string }> | null | undefined;
};

export type GetNotificationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetNotificationQuery = {
  __typename?: 'Query';
  notification: {
    __typename?: 'Notification';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    date?: any | null | undefined;
    linkTo?: string | null | undefined;
    isSeen?: boolean | null | undefined;
    seenDate?: any | null | undefined;
    createdAt?: any | null | undefined;
    isValid?: boolean | null | undefined;
    parametres?: Array<string | null | undefined> | null | undefined;
  };
};

export type GetUnSeenNotificationsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetUnSeenNotificationsQuery = {
  __typename?: 'Query';
  notifications: Array<{
    __typename?: 'Notification';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    date?: any | null | undefined;
    linkTo?: string | null | undefined;
    isSeen?: boolean | null | undefined;
    seenDate?: any | null | undefined;
    createdAt?: any | null | undefined;
    isValid?: boolean | null | undefined;
    parametres?: Array<string | null | undefined> | null | undefined;
    companyMemberSender?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
  meta: { __typename?: 'ListMetadata'; count: number };
};

export type GetSeenNotificationsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
}>;

export type GetSeenNotificationsQuery = {
  __typename?: 'Query';
  notifications: Array<{
    __typename?: 'Notification';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    date?: any | null | undefined;
    linkTo?: string | null | undefined;
    isSeen?: boolean | null | undefined;
    seenDate?: any | null | undefined;
    createdAt?: any | null | undefined;
    isValid?: boolean | null | undefined;
    parametres?: Array<string | null | undefined> | null | undefined;
    companyMemberSender?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
  meta: { __typename?: 'ListMetadata'; count: number };
};

export type GetUnSeenNotificationsMetaQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type GetUnSeenNotificationsMetaQuery = {
  __typename?: 'Query';
  meta: { __typename?: 'ListMetadata'; count: number };
};

export type RoadBlockFragment = {
  __typename?: 'RoadBlock';
  id: string;
  roadBlockId?: string | null | undefined;
  description?: string | null | undefined;
  createdAt?: any | null | undefined;
};

export type MissionInfoFragment = {
  __typename?: 'Mission';
  id: string;
  title?: string | null | undefined;
  deadline?: any | null | undefined;
  estimateDate?: any | null | undefined;
  priorityLevel?: number | null | undefined;
  progressStatus?: MissionProgressStatus | null | undefined;
  createdAt?: any | null | undefined;
};

export type FeedbackInfoFragment = {
  __typename?: 'Feedback';
  id: string;
  content?: string | null | undefined;
  response?: string | null | undefined;
  alreadySeen?: boolean | null | undefined;
  createdAt?: any | null | undefined;
  companyMemberSenderId?: string | null | undefined;
};

export type NeedInfoFragment = {
  __typename?: 'Need';
  id: string;
  title?: string | null | undefined;
  description?: string | null | undefined;
};

export type CreateOneToOneMutationVariables = Exact<{
  hostCompanyMemberId: Scalars['ID'];
  guestCompanyMemberId: Scalars['ID'];
  archive?: InputMaybe<ArchiveInput>;
}>;

export type CreateOneToOneMutation = {
  __typename?: 'Mutation';
  createOneToOne: { __typename?: 'OneToOne'; id: string };
};

export type UpdateNeedMutationVariables = Exact<{
  id: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  professionnalWeatherId: Scalars['ID'];
}>;

export type UpdateNeedMutation = {
  __typename?: 'Mutation';
  updateNeed: {
    __typename?: 'Need';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    professionnalWeather?: { __typename?: 'ProfessionnalWeather'; id: string } | null | undefined;
  };
};

export type CreateRoadBlockMutationVariables = Exact<{
  description: Scalars['String'];
  companyMemberId: Scalars['ID'];
}>;

export type CreateRoadBlockMutation = {
  __typename?: 'Mutation';
  createRoadBlock: {
    __typename?: 'RoadBlock';
    id: string;
    roadBlockId?: string | null | undefined;
    description?: string | null | undefined;
    createdAt?: any | null | undefined;
  };
};

export type DeleteRoadBlockMutationVariables = Exact<{
  deleteRoadBlockId: Scalars['ID'];
}>;

export type DeleteRoadBlockMutation = {
  __typename?: 'Mutation';
  deleteRoadBlock: {
    __typename?: 'RoadBlock';
    id: string;
    roadBlockId?: string | null | undefined;
    description?: string | null | undefined;
    createdAt?: any | null | undefined;
  };
};

export type UpdateRoadBlockMutationVariables = Exact<{
  updateRoadBlockId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
}>;

export type UpdateRoadBlockMutation = {
  __typename?: 'Mutation';
  updateRoadBlock: {
    __typename?: 'RoadBlock';
    id: string;
    roadBlockId?: string | null | undefined;
    description?: string | null | undefined;
    createdAt?: any | null | undefined;
  };
};

export type CreateMissionMutationVariables = Exact<{
  title: Scalars['String'];
  deadline?: InputMaybe<Scalars['Date']>;
  estimateDate?: InputMaybe<Scalars['Date']>;
  communityId: Scalars['ID'];
  companyMemberId: Scalars['ID'];
  priorityLevel?: InputMaybe<Scalars['Int']>;
  progressStatus?: InputMaybe<MissionProgressStatus>;
  expertIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
  oneToOneSubordonateId?: InputMaybe<Scalars['ID']>;
}>;

export type CreateMissionMutation = {
  __typename?: 'Mutation';
  createMission: {
    __typename?: 'Mission';
    id: string;
    title?: string | null | undefined;
    deadline?: any | null | undefined;
    estimateDate?: any | null | undefined;
    priorityLevel?: number | null | undefined;
    progressStatus?: MissionProgressStatus | null | undefined;
    createdAt?: any | null | undefined;
  };
};

export type UpdateMissionMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  deadline?: InputMaybe<Scalars['Date']>;
  estimateDate?: InputMaybe<Scalars['Date']>;
  priorityLevel?: InputMaybe<Scalars['Int']>;
  progressStatus?: InputMaybe<MissionProgressStatus>;
  expertIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>>;
}>;

export type UpdateMissionMutation = {
  __typename?: 'Mutation';
  updateMission: {
    __typename?: 'Mission';
    id: string;
    title?: string | null | undefined;
    deadline?: any | null | undefined;
    estimateDate?: any | null | undefined;
    priorityLevel?: number | null | undefined;
    progressStatus?: MissionProgressStatus | null | undefined;
    createdAt?: any | null | undefined;
  };
};

export type DeleteMissionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteMissionMutation = {
  __typename?: 'Mutation';
  deleteMission: {
    __typename?: 'Mission';
    id: string;
    title?: string | null | undefined;
    deadline?: any | null | undefined;
    estimateDate?: any | null | undefined;
    priorityLevel?: number | null | undefined;
    progressStatus?: MissionProgressStatus | null | undefined;
    createdAt?: any | null | undefined;
  };
};

export type SendFeedbackMutationVariables = Exact<{
  content: Scalars['String'];
  companyMemberReceiverId: Scalars['ID'];
}>;

export type SendFeedbackMutation = {
  __typename?: 'Mutation';
  sendFeedback: {
    __typename?: 'Feedback';
    id: string;
    content?: string | null | undefined;
    response?: string | null | undefined;
    alreadySeen?: boolean | null | undefined;
    createdAt?: any | null | undefined;
    companyMemberSenderId?: string | null | undefined;
  };
};

export type ReplyFeedbackMutationVariables = Exact<{
  id: Scalars['ID'];
  response: Scalars['String'];
}>;

export type ReplyFeedbackMutation = {
  __typename?: 'Mutation';
  replyFeedback: {
    __typename?: 'Feedback';
    id: string;
    content?: string | null | undefined;
    response?: string | null | undefined;
    alreadySeen?: boolean | null | undefined;
    createdAt?: any | null | undefined;
    companyMemberSenderId?: string | null | undefined;
    companyMemberSender?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type GetMyOneToOneArchiveQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMyOneToOneArchiveQuery = {
  __typename?: 'Query';
  getMyOneToOneArchive?:
    | Array<{
        __typename?: 'OneToOneArchive';
        id: string;
        file?:
          | {
              __typename?: 'File';
              id: string;
              path?: string | null | undefined;
              publicUrl?: string | null | undefined;
              createdAt?: any | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type GetNeedsByCompanyMemberIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetNeedsByCompanyMemberIdQuery = {
  __typename?: 'Query';
  getNeedsByCompanyMemberId?:
    | Array<{
        __typename?: 'Need';
        id: string;
        title?: string | null | undefined;
        description?: string | null | undefined;
        professionnalWeather?:
          | { __typename?: 'ProfessionnalWeather'; id: string; icon?: string | null | undefined }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type GetNeedByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetNeedByIdQuery = {
  __typename?: 'Query';
  needById: {
    __typename?: 'Need';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    professionnalWeather?:
      | { __typename?: 'ProfessionnalWeather'; id: string; icon?: string | null | undefined }
      | null
      | undefined;
  };
};

export type SeeFeedbackQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SeeFeedbackQuery = {
  __typename?: 'Query';
  seeFeedback: {
    __typename?: 'Feedback';
    id: string;
    content?: string | null | undefined;
    response?: string | null | undefined;
    alreadySeen?: boolean | null | undefined;
    createdAt?: any | null | undefined;
    companyMemberSenderId?: string | null | undefined;
    companyMemberSender?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    companyMemberReceiver?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type CreateCompanyMemberOrderMutationVariables = Exact<{
  productId: Scalars['ID'];
  cost: Scalars['Int'];
}>;

export type CreateCompanyMemberOrderMutation = {
  __typename?: 'Mutation';
  createCompanyMemberOrder?: { __typename?: 'CompanyMemberOrder'; id: string } | null | undefined;
};

export type DeleteAdviceCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteAdviceCategoryMutation = {
  __typename?: 'Mutation';
  deleteAdviceCategory: { __typename?: 'AdviceCategory'; id: string };
};

export type CreateAdviceCategoryMutationVariables = Exact<{
  title: Scalars['String'];
}>;

export type CreateAdviceCategoryMutation = {
  __typename?: 'Mutation';
  createAdviceCategory: { __typename?: 'AdviceCategory'; id: string };
};

export type GetCurrentCompanyAdminAdvicesQueryVariables = Exact<{
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
}>;

export type GetCurrentCompanyAdminAdvicesQuery = {
  __typename?: 'Query';
  currentCompanyAdminAdvices: {
    __typename?: 'PostList';
    total?: number | null | undefined;
    advices?:
      | Array<{
          __typename?: 'Post';
          id: string;
          postType?: PostType | null | undefined;
          title?: string | null | undefined;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isSeenByMe?: boolean | null | undefined;
          isReported?: boolean | null | undefined;
          isHidden?: boolean | null | undefined;
          communities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
                logo?: string | null | undefined;
              }>
            | null
            | undefined;
          cmCreatedBy?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type AdviceCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type AdviceCategoriesQuery = {
  __typename?: 'Query';
  categories: Array<{
    __typename?: 'AdviceCategory';
    id: string;
    title?: string | null | undefined;
    removed?: boolean | null | undefined;
    updatedAt?: any | null | undefined;
    createdAt?: any | null | undefined;
  }>;
};

export type PostInfoFragment = {
  __typename?: 'Post';
  id: string;
  commentCount?: number | null | undefined;
  content?: string | null | undefined;
  contentImageUrls?: Array<string> | null | undefined;
  createdAt?: any | null | undefined;
  demand?: Demand_Type | null | undefined;
  demandImproveStatus?: Demand_Improve_Status | null | undefined;
  demandSolveStatus?: Demand_Solve_Status | null | undefined;
  draft?: boolean | null | undefined;
  how?: string | null | undefined;
  impact?: string | null | undefined;
  isAlreadyUseA3Process?: boolean | null | undefined;
  isAuthorHidden?: boolean | null | undefined;
  isCMValidator?: boolean | null | undefined;
  isHidden?: boolean | null | undefined;
  isIssueSolved?: boolean | null | undefined;
  isLikedByMe?: boolean | null | undefined;
  isMine?: boolean | null | undefined;
  isRatingEnded?: boolean | null | undefined;
  isSeenByMe?: boolean | null | undefined;
  likeCount?: number | null | undefined;
  postedAnonymously?: boolean | null | undefined;
  postType?: PostType | null | undefined;
  problemLevel?: number | null | undefined;
  problemTo?: any | null | undefined;
  processStatus?: Process_Status | null | undefined;
  ratingPeriod?: any | null | undefined;
  removed?: boolean | null | undefined;
  reopened?: boolean | null | undefined;
  shareCount?: number | null | undefined;
  status?: Post_Status | null | undefined;
  title?: string | null | undefined;
  updatedAt?: any | null | undefined;
  what?: string | null | undefined;
  when?: string | null | undefined;
  where?: string | null | undefined;
  who?: string | null | undefined;
  issuePrivacy?: IssuePrivacy | null | undefined;
};

export type RatingSummaryFragment = {
  __typename?: 'Post';
  ratingSummary?:
    | {
        __typename?: 'PostRatingSummary';
        two?: number | null | undefined;
        one?: number | null | undefined;
        three?: number | null | undefined;
        four?: number | null | undefined;
        five?: number | null | undefined;
        totalRatingCount?: number | null | undefined;
        globalRating?: number | null | undefined;
      }
    | null
    | undefined;
};

export type CreateIssueCategoryMutationVariables = Exact<{
  title: Scalars['String'];
}>;

export type CreateIssueCategoryMutation = {
  __typename?: 'Mutation';
  createProblemCategory: { __typename?: 'ProblemCategory'; id: string };
};

export type DeleteIssueCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteIssueCategoryMutation = {
  __typename?: 'Mutation';
  deleteProblemCategory: { __typename?: 'ProblemCategory'; id: string };
};

export type GetCurrentCompanyAdminIssuesQueryVariables = Exact<{
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
}>;

export type GetCurrentCompanyAdminIssuesQuery = {
  __typename?: 'Query';
  currentCompanyAdminProblems: {
    __typename?: 'PostList';
    total?: number | null | undefined;
    issues?:
      | Array<{
          __typename?: 'Post';
          id: string;
          postType?: PostType | null | undefined;
          title?: string | null | undefined;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isSeenByMe?: boolean | null | undefined;
          isReported?: boolean | null | undefined;
          isHidden?: boolean | null | undefined;
          issuePrivacy?: IssuePrivacy | null | undefined;
          communities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
                logo?: string | null | undefined;
              }>
            | null
            | undefined;
          cmCreatedBy?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type CreatePostMutationVariables = Exact<{
  title: Scalars['String'];
  content: Scalars['String'];
  postType: PostType;
  status?: InputMaybe<Post_Status>;
  problemFrom?: InputMaybe<Scalars['DateTime']>;
  problemTo?: InputMaybe<Scalars['DateTime']>;
  problemLevel?: InputMaybe<Scalars['Int']>;
  problemCategoryId?: InputMaybe<Scalars['ID']>;
  problemCircuitId?: InputMaybe<Scalars['ID']>;
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  responsibleIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  companyMemberResponsibleIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  fileIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  companyMemberExpertIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  validatorIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  problemCommunitySourceId?: InputMaybe<Scalars['ID']>;
  recipientCommunityId?: InputMaybe<Scalars['ID']>;
  presentationFileId?: InputMaybe<Scalars['ID']>;
  sharedToUsersIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  sharedToCompanyMembersIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  sharedToCommunitiesIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  who?: InputMaybe<Scalars['String']>;
  what?: InputMaybe<Scalars['String']>;
  how?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<Scalars['String']>;
  when?: InputMaybe<Scalars['String']>;
  impact?: InputMaybe<Scalars['String']>;
  demand?: InputMaybe<Demand_Type>;
  processStatus?: InputMaybe<Process_Status>;
  questionCategoryIid?: InputMaybe<Scalars['ID']>;
  ratingPeriod?: InputMaybe<Scalars['DateTime']>;
  issuePrivacy?: InputMaybe<IssuePrivacy>;
}>;

export type CreatePostMutation = {
  __typename?: 'Mutation';
  createPost: {
    __typename?: 'Post';
    id: string;
    createdBy?: { __typename?: 'User'; id: string } | null | undefined;
  };
};

export type CreatePostIdeaOrAdviceMutationVariables = Exact<{
  title: Scalars['String'];
  content: Scalars['String'];
  postType: PostType;
  status?: InputMaybe<Post_Status>;
  ideaCategoryId?: InputMaybe<Scalars['ID']>;
  adviceCategoryId?: InputMaybe<Scalars['ID']>;
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  fileIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  ratingPeriod?: InputMaybe<Scalars['DateTime']>;
  postedAnonymously?: InputMaybe<Scalars['Boolean']>;
}>;

export type CreatePostIdeaOrAdviceMutation = {
  __typename?: 'Mutation';
  post: {
    __typename?: 'Post';
    id: string;
    createdBy?: { __typename?: 'User'; id: string } | null | undefined;
    ideaCategory?: { __typename?: 'IdeaCategory'; id: string } | null | undefined;
    adviceCategory?: { __typename?: 'AdviceCategory'; id: string } | null | undefined;
  };
};

export type UpdatePostMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  postType?: InputMaybe<PostType>;
  status?: InputMaybe<Post_Status>;
  problemFrom?: InputMaybe<Scalars['DateTime']>;
  problemTo?: InputMaybe<Scalars['DateTime']>;
  problemLevel?: InputMaybe<Scalars['Int']>;
  problemCategoryId?: InputMaybe<Scalars['ID']>;
  ideaCategoryId?: InputMaybe<Scalars['ID']>;
  problemCircuitId?: InputMaybe<Scalars['ID']>;
  communityIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  responsibleIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  companyMemberResponsibleIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  fileIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  companyMemberExpertIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  validatorIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  removedFilesIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  problemCommunitySourceId?: InputMaybe<Scalars['ID']>;
  recipientCommunityId?: InputMaybe<Scalars['ID']>;
  presentationFileId?: InputMaybe<Scalars['ID']>;
  sharedToUsersIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  sharedToCommunitiesIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  sharedToCompanyMembersIds?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  who?: InputMaybe<Scalars['String']>;
  what?: InputMaybe<Scalars['String']>;
  how?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<Scalars['String']>;
  when?: InputMaybe<Scalars['String']>;
  impact?: InputMaybe<Scalars['String']>;
  demand?: InputMaybe<Demand_Type>;
  processStatus?: InputMaybe<Process_Status>;
  questionCategoryIid?: InputMaybe<Scalars['ID']>;
  ratingPeriod?: InputMaybe<Scalars['DateTime']>;
  issuePrivacy?: InputMaybe<IssuePrivacy>;
}>;

export type UpdatePostMutation = {
  __typename?: 'Mutation';
  updatePost: {
    __typename?: 'Post';
    id: string;
    title?: string | null | undefined;
    content?: string | null | undefined;
    postType?: PostType | null | undefined;
    who?: string | null | undefined;
    what?: string | null | undefined;
    how?: string | null | undefined;
    where?: string | null | undefined;
    when?: string | null | undefined;
    impact?: string | null | undefined;
    demand?: Demand_Type | null | undefined;
    processStatus?: Process_Status | null | undefined;
    status?: Post_Status | null | undefined;
    problemFrom?: any | null | undefined;
    problemTo?: any | null | undefined;
    problemLevel?: number | null | undefined;
    ratingPeriod?: any | null | undefined;
    createdBy?: { __typename?: 'User'; id: string } | null | undefined;
    ideaCategory?: { __typename?: 'IdeaCategory'; id: string } | null | undefined;
    problemCategory?: { __typename?: 'ProblemCategory'; id: string } | null | undefined;
    files?: Array<{ __typename?: 'File'; id: string }> | null | undefined;
  };
};

export type DeletePostMutationVariables = Exact<{
  deletePostId: Scalars['ID'];
}>;

export type DeletePostMutation = {
  __typename?: 'Mutation';
  deletePost?:
    | {
        __typename?: 'Post';
        title?: string | null | undefined;
        removed?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type CreatePostRatingMutationVariables = Exact<{
  createPostRatingPostId: Scalars['ID'];
  rating: Scalars['Int'];
}>;

export type CreatePostRatingMutation = {
  __typename?: 'Mutation';
  createPostRating?:
    | {
        __typename?: 'Post';
        id: string;
        title?: string | null | undefined;
        myRating?: { __typename?: 'PostRating'; id: string; rating: number } | null | undefined;
      }
    | null
    | undefined;
};

export type AddOrRemoveLikePostMutationVariables = Exact<{
  postId: Scalars['ID'];
}>;

export type AddOrRemoveLikePostMutation = {
  __typename?: 'Mutation';
  addOrRemoveLikePost?:
    | {
        __typename?: 'Post';
        id: string;
        isLikedByMe?: boolean | null | undefined;
        likeCount?: number | null | undefined;
        likedBy?: Array<{ __typename?: 'User'; id: string }> | null | undefined;
      }
    | null
    | undefined;
};

export type SharePostMutationVariables = Exact<{
  postId: Scalars['ID'];
  communityIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type SharePostMutation = {
  __typename?: 'Mutation';
  sharePost?:
    | {
        __typename?: 'CompanyMemberPostShare';
        id: string;
        post?:
          | { __typename?: 'Post'; id: string; shareCount?: number | null | undefined }
          | null
          | undefined;
        communities?:
          | Array<{
              __typename?: 'Community';
              id: string;
              members?:
                | Array<{
                    __typename?: 'UserCommunity';
                    id: string;
                    companyMemberId?: string | null | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ChangePostStatusA3MutationVariables = Exact<{
  postId: Scalars['ID'];
  a3Status: Post_A3_Status;
}>;

export type ChangePostStatusA3Mutation = {
  __typename?: 'Mutation';
  changePostStatusA3?:
    | { __typename?: 'Post'; id: string; a3Status?: Post_A3_Status | null | undefined }
    | null
    | undefined;
};

export type UpdatePostDemandStatusMutationVariables = Exact<{
  postId: Scalars['ID'];
  solveStatus?: InputMaybe<Demand_Solve_Status>;
  improveStatus?: InputMaybe<Demand_Improve_Status>;
}>;

export type UpdatePostDemandStatusMutation = {
  __typename?: 'Mutation';
  updateDemandStatus?:
    | {
        __typename?: 'Post';
        id: string;
        demandSolveStatus?: Demand_Solve_Status | null | undefined;
        demandImproveStatus?: Demand_Improve_Status | null | undefined;
        isIssueSolved?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type UpdatePostVisibilityMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UpdatePostVisibilityMutation = {
  __typename?: 'Mutation';
  updatePostVisibility: { __typename?: 'Post'; id: string; isHidden?: boolean | null | undefined };
};

export type FinalizePostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FinalizePostMutation = {
  __typename?: 'Mutation';
  finalizePost: { __typename?: 'Post'; id: string };
};

export type ResetNewPostSummaryMutationVariables = Exact<{
  postType: PostType;
}>;

export type ResetNewPostSummaryMutation = {
  __typename?: 'Mutation';
  resetNewPostsCountByType: boolean;
};

export type MarkAsAlreadyUseA3ProcessMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MarkAsAlreadyUseA3ProcessMutation = {
  __typename?: 'Mutation';
  markAsAlreadyUseA3Process?:
    | { __typename?: 'Post'; id: string; isAlreadyUseA3Process?: boolean | null | undefined }
    | null
    | undefined;
};

export type SaveFiltersMutationVariables = Exact<{
  filters?: InputMaybe<Array<InputMaybe<Filter>> | InputMaybe<Filter>>;
  postType: PostType;
}>;

export type SaveFiltersMutation = {
  __typename?: 'Mutation';
  saveCurrentFilters: Array<{ __typename?: 'PostFilterHistory'; id: string }>;
};

export type UpdatePostPrivacyMutationVariables = Exact<{
  postId: Scalars['ID'];
  issuePrivacy: IssuePrivacy;
}>;

export type UpdatePostPrivacyMutation = {
  __typename?: 'Mutation';
  updatePostPrivacy: {
    __typename?: 'Post';
    id: string;
    issuePrivacy?: IssuePrivacy | null | undefined;
  };
};

export type DeleteProcessCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteProcessCategoryMutation = {
  __typename?: 'Mutation';
  deleteProcessCategory: { __typename?: 'ProcessCategory'; id: string };
};

export type CreateProcessCategoryMutationVariables = Exact<{
  title: Scalars['String'];
}>;

export type CreateProcessCategoryMutation = {
  __typename?: 'Mutation';
  createProcessCategory: { __typename?: 'ProcessCategory'; id: string };
};

export type GetCurrentCompanyAdminProcessQueryVariables = Exact<{
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
}>;

export type GetCurrentCompanyAdminProcessQuery = {
  __typename?: 'Query';
  currentCompanyAdminProcess: {
    __typename?: 'PostList';
    total?: number | null | undefined;
    allProcess?:
      | Array<{
          __typename?: 'Post';
          id: string;
          postType?: PostType | null | undefined;
          title?: string | null | undefined;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isSeenByMe?: boolean | null | undefined;
          isReported?: boolean | null | undefined;
          isHidden?: boolean | null | undefined;
          communities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
                logo?: string | null | undefined;
              }>
            | null
            | undefined;
          recipientCommunity?:
            | {
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logo?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
          cmCreatedBy?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetCommunitiesByCompanyQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
}>;

export type GetCommunitiesByCompanyQuery = {
  __typename?: 'Query';
  getCommunityByCompanyId?:
    | Array<{
        __typename?: 'Community';
        id: string;
        name?: string | null | undefined;
        logo?: string | null | undefined;
        logoPublicUrl?: string | null | undefined;
      }>
    | null
    | undefined;
};

export type GetCommunitiesByCompanyWithHistoryQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  fromTunnel?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCommunitiesByCompanyWithHistoryQuery = {
  __typename?: 'Query';
  teams: Array<{
    __typename?: 'Community';
    id: string;
    name?: string | null | undefined;
    logo?: string | null | undefined;
    logoPublicUrl?: string | null | undefined;
  }>;
};

export type GetPostByIdQueryVariables = Exact<{
  postId: Scalars['ID'];
}>;

export type GetPostByIdQuery = {
  __typename?: 'Query';
  Post?:
    | {
        __typename?: 'Post';
        commentCount?: number | null | undefined;
        publicCommentCount?: number | null | undefined;
        fileCount?: number | null | undefined;
        id: string;
        content?: string | null | undefined;
        contentImageUrls?: Array<string> | null | undefined;
        createdAt?: any | null | undefined;
        demand?: Demand_Type | null | undefined;
        demandImproveStatus?: Demand_Improve_Status | null | undefined;
        demandSolveStatus?: Demand_Solve_Status | null | undefined;
        draft?: boolean | null | undefined;
        how?: string | null | undefined;
        impact?: string | null | undefined;
        isAlreadyUseA3Process?: boolean | null | undefined;
        isAuthorHidden?: boolean | null | undefined;
        isCMValidator?: boolean | null | undefined;
        isHidden?: boolean | null | undefined;
        isIssueSolved?: boolean | null | undefined;
        isLikedByMe?: boolean | null | undefined;
        isMine?: boolean | null | undefined;
        isRatingEnded?: boolean | null | undefined;
        isSeenByMe?: boolean | null | undefined;
        likeCount?: number | null | undefined;
        postedAnonymously?: boolean | null | undefined;
        postType?: PostType | null | undefined;
        problemLevel?: number | null | undefined;
        problemTo?: any | null | undefined;
        processStatus?: Process_Status | null | undefined;
        ratingPeriod?: any | null | undefined;
        removed?: boolean | null | undefined;
        reopened?: boolean | null | undefined;
        shareCount?: number | null | undefined;
        status?: Post_Status | null | undefined;
        title?: string | null | undefined;
        updatedAt?: any | null | undefined;
        what?: string | null | undefined;
        when?: string | null | undefined;
        where?: string | null | undefined;
        who?: string | null | undefined;
        issuePrivacy?: IssuePrivacy | null | undefined;
        postRequests?:
          | Array<{ __typename?: 'PostRequest'; id: string; report?: string | null | undefined }>
          | null
          | undefined;
        problemCircuit?:
          | { __typename?: 'ProblemCircuit'; id: string; title?: string | null | undefined }
          | null
          | undefined;
        problemCategory?:
          | { __typename?: 'ProblemCategory'; id: string; title?: string | null | undefined }
          | null
          | undefined;
        likedBy?:
          | Array<{
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              currentCompanyMember?:
                | { __typename?: 'CompanyMember'; id: string }
                | null
                | undefined;
            }>
          | null
          | undefined;
        processValidators?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        responsibles?:
          | Array<
              | {
                  __typename?: 'User';
                  id: string;
                  fullName?: string | null | undefined;
                  firstName?: string | null | undefined;
                  lastName?: string | null | undefined;
                  photoUrl?: string | null | undefined;
                  photoPublicUrl?: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        companyMemberResponsibles?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        cmCreatedBy?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        createdBy?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        comments?:
          | Array<{
              __typename?: 'Comment';
              id: string;
              content?: string | null | undefined;
              createdAt?: any | null | undefined;
              isMine?: boolean | null | undefined;
              likeCount?: number | null | undefined;
              isLikedByMe?: boolean | null | undefined;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              likedBy?:
                | Array<{
                    __typename?: 'CompanyMember';
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          lastName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          fullName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                          currentCompanyMember?:
                            | { __typename?: 'CompanyMember'; id: string }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
        publicComments?:
          | Array<{
              __typename?: 'Comment';
              id: string;
              content?: string | null | undefined;
              createdAt?: any | null | undefined;
              isMine?: boolean | null | undefined;
              likeCount?: number | null | undefined;
              isLikedByMe?: boolean | null | undefined;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              likedBy?:
                | Array<{
                    __typename?: 'CompanyMember';
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          lastName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          fullName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                          currentCompanyMember?:
                            | { __typename?: 'CompanyMember'; id: string }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
        myRating?: { __typename?: 'PostRating'; id: string; rating: number } | null | undefined;
        files?:
          | Array<{
              __typename?: 'File';
              id: string;
              type?: string | null | undefined;
              size?: number | null | undefined;
              path?: string | null | undefined;
              name?: string | null | undefined;
              publicUrl?: string | null | undefined;
              extension?: string | null | undefined;
            }>
          | null
          | undefined;
        imageFiles?:
          | Array<{
              __typename?: 'File';
              id: string;
              type?: string | null | undefined;
              size?: number | null | undefined;
              path?: string | null | undefined;
              name?: string | null | undefined;
              publicUrl?: string | null | undefined;
              extension?: string | null | undefined;
            }>
          | null
          | undefined;
        ideaCategory?:
          | { __typename?: 'IdeaCategory'; id: string; title?: string | null | undefined }
          | null
          | undefined;
        companyMemberExperts?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        communities?:
          | Array<{
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
              members?:
                | Array<{
                    __typename?: 'UserCommunity';
                    id: string;
                    companyMemberId?: string | null | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
        recipientCommunity?:
          | {
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        presentationFile?:
          | {
              __typename?: 'File';
              id: string;
              type?: string | null | undefined;
              size?: number | null | undefined;
              path?: string | null | undefined;
              name?: string | null | undefined;
              publicUrl?: string | null | undefined;
              extension?: string | null | undefined;
            }
          | null
          | undefined;
        sharedToSpecificUsers?:
          | Array<{
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              fullName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              currentCompanyMember?:
                | { __typename?: 'CompanyMember'; id: string }
                | null
                | undefined;
            }>
          | null
          | undefined;
        sharedToSpecificCommunities?:
          | Array<{
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }>
          | null
          | undefined;
        sharedToSpecificCompanyMembers?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    lastName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                    firstName?: string | null | undefined;
                    currentCompanyMember?:
                      | { __typename?: 'CompanyMember'; id: string }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        problemCommunitySource?:
          | {
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        questionCategory?:
          | { __typename?: 'QuestionCategory'; id: string; title?: string | null | undefined }
          | null
          | undefined;
        sharedCompanyMemberPosts?:
          | Array<{
              __typename?: 'CompanyMemberPostShare';
              communities?:
                | Array<{
                    __typename?: 'Community';
                    id: string;
                    name?: string | null | undefined;
                    logo?: string | null | undefined;
                    logoPublicUrl?: string | null | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
        ratingSummary?:
          | {
              __typename?: 'PostRatingSummary';
              two?: number | null | undefined;
              one?: number | null | undefined;
              three?: number | null | undefined;
              four?: number | null | undefined;
              five?: number | null | undefined;
              totalRatingCount?: number | null | undefined;
              globalRating?: number | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCurrentCompanyMembersQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentCompanyMembersQuery = {
  __typename?: 'Query';
  currentCompany: {
    __typename?: 'Company';
    members?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                id: string;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type GetCurrentCompanyAdviceQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  filterString?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['ID']>;
  page?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Array<Filter> | Filter>;
  postType?: InputMaybe<PostType>;
}>;

export type GetCurrentCompanyAdviceQuery = {
  __typename?: 'Query';
  isFiltersApplied?: boolean | null | undefined;
  count?: number | null | undefined;
  currentCompanyAdvice: Array<{
    __typename?: 'Post';
    id: string;
    title?: string | null | undefined;
    content?: string | null | undefined;
    contentImageUrls?: Array<string> | null | undefined;
    createdAt?: any | null | undefined;
    commentCount?: number | null | undefined;
    isSeenByMe?: boolean | null | undefined;
    isMine?: boolean | null | undefined;
    isLikedByMe?: boolean | null | undefined;
    likeCount?: number | null | undefined;
    shareCount?: number | null | undefined;
    isReported?: boolean | null | undefined;
    postType?: PostType | null | undefined;
    fileCount?: number | null | undefined;
    responsibles?:
      | Array<
          | {
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              fullName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    createdBy?:
      | {
          __typename?: 'User';
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          fullName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    cmCreatedBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    imageFiles?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    myRating?: { __typename?: 'PostRating'; id: string; rating: number } | null | undefined;
    ratingSummary?:
      | {
          __typename?: 'PostRatingSummary';
          two?: number | null | undefined;
          one?: number | null | undefined;
          three?: number | null | undefined;
          four?: number | null | undefined;
          five?: number | null | undefined;
          totalRatingCount?: number | null | undefined;
          globalRating?: number | null | undefined;
        }
      | null
      | undefined;
  }>;
  currentWholeCompany: { __typename?: 'Community'; id: string; name?: string | null | undefined };
};

export type NewPostSummaryQueryVariables = Exact<{ [key: string]: never }>;

export type NewPostSummaryQuery = {
  __typename?: 'Query';
  newPostSummary?:
    | {
        __typename?: 'NewPostSummary';
        problem?: number | null | undefined;
        idea?: number | null | undefined;
        story?: number | null | undefined;
        process?: number | null | undefined;
        advice?: number | null | undefined;
        fun?: number | null | undefined;
      }
    | null
    | undefined;
};

export type GetAllPostsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<PostFilter>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
}>;

export type GetAllPostsQuery = {
  __typename?: 'Query';
  allPosts?:
    | Array<{
        __typename?: 'Post';
        id: string;
        title?: string | null | undefined;
        content?: string | null | undefined;
        postType?: PostType | null | undefined;
        issuePrivacy?: IssuePrivacy | null | undefined;
      }>
    | null
    | undefined;
};

export type AllReportedPostsQueryVariables = Exact<{ [key: string]: never }>;

export type AllReportedPostsQuery = {
  __typename?: 'Query';
  requestedPostIds?: Array<string> | null | undefined;
};

export type IdeaCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type IdeaCategoriesQuery = {
  __typename?: 'Query';
  IdeaCategories: Array<{
    __typename?: 'IdeaCategory';
    id: string;
    title?: string | null | undefined;
    removed?: boolean | null | undefined;
    updatedAt?: any | null | undefined;
    createdAt?: any | null | undefined;
  }>;
};

export type ProblemCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type ProblemCategoriesQuery = {
  __typename?: 'Query';
  allProblemCategorys: Array<{
    __typename?: 'ProblemCategory';
    id: string;
    title?: string | null | undefined;
  }>;
};

export type CompanyProblemCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyProblemCategoriesQuery = {
  __typename?: 'Query';
  companyProblemCategories: Array<{
    __typename?: 'ProblemCategory';
    id: string;
    title?: string | null | undefined;
  }>;
};

export type GetProblemsCountBetweenCommunityQueryVariables = Exact<{
  sourceId: Scalars['ID'];
  targetId: Scalars['ID'];
}>;

export type GetProblemsCountBetweenCommunityQuery = {
  __typename?: 'Query';
  getProblemsCountBetweenCommunity: {
    __typename?: 'ProblemsCountPayload';
    resolvedProblemCount: number;
    unresolvedProblemCount: number;
  };
};

export type CurrentCompanyProblemsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  filterBy?: InputMaybe<PostFilterByInput>;
  filterString?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['ID']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  postType?: InputMaybe<PostType>;
  cacheKey?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<Filter> | Filter>;
  issuePrivacy?: InputMaybe<IssuePrivacy>;
}>;

export type CurrentCompanyProblemsQuery = {
  __typename?: 'Query';
  isFiltersApplied?: boolean | null | undefined;
  count?: number | null | undefined;
  currentCompanyProblems: Array<{
    __typename?: 'Post';
    finalized?: boolean | null | undefined;
    isChargedByMe?: boolean | null | undefined;
    commentCount?: number | null | undefined;
    publicCommentCount?: number | null | undefined;
    fileCount?: number | null | undefined;
    id: string;
    content?: string | null | undefined;
    contentImageUrls?: Array<string> | null | undefined;
    createdAt?: any | null | undefined;
    demand?: Demand_Type | null | undefined;
    demandImproveStatus?: Demand_Improve_Status | null | undefined;
    demandSolveStatus?: Demand_Solve_Status | null | undefined;
    draft?: boolean | null | undefined;
    how?: string | null | undefined;
    impact?: string | null | undefined;
    isAlreadyUseA3Process?: boolean | null | undefined;
    isAuthorHidden?: boolean | null | undefined;
    isCMValidator?: boolean | null | undefined;
    isHidden?: boolean | null | undefined;
    isIssueSolved?: boolean | null | undefined;
    isLikedByMe?: boolean | null | undefined;
    isMine?: boolean | null | undefined;
    isRatingEnded?: boolean | null | undefined;
    isSeenByMe?: boolean | null | undefined;
    likeCount?: number | null | undefined;
    postedAnonymously?: boolean | null | undefined;
    postType?: PostType | null | undefined;
    problemLevel?: number | null | undefined;
    problemTo?: any | null | undefined;
    processStatus?: Process_Status | null | undefined;
    ratingPeriod?: any | null | undefined;
    removed?: boolean | null | undefined;
    reopened?: boolean | null | undefined;
    shareCount?: number | null | undefined;
    status?: Post_Status | null | undefined;
    title?: string | null | undefined;
    updatedAt?: any | null | undefined;
    what?: string | null | undefined;
    when?: string | null | undefined;
    where?: string | null | undefined;
    who?: string | null | undefined;
    issuePrivacy?: IssuePrivacy | null | undefined;
    problemCategory?:
      | { __typename?: 'ProblemCategory'; id: string; title?: string | null | undefined }
      | null
      | undefined;
    problemCircuit?:
      | { __typename?: 'ProblemCircuit'; id: string; title?: string | null | undefined }
      | null
      | undefined;
    cmCreatedBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    communities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
    problemCommunitySource?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    sharedCompanyMemberPosts?:
      | Array<{
          __typename?: 'CompanyMemberPostShare';
          communities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logo?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    responsibles?:
      | Array<
          | {
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              fullName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    companyMemberResponsibles?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    companyMemberExperts?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    imageFiles?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    actions?:
      | Array<
          | {
              __typename?: 'Action';
              id: string;
              description?: string | null | undefined;
              targetDate?: any | null | undefined;
              isCompleted?: boolean | null | undefined;
              responsibles?:
                | Array<{
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    presentationFile?:
      | {
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
  currentWholeCompany: { __typename?: 'Community'; id: string; name?: string | null | undefined };
};

export type CurrentCompanyProcessQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  filterString?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['ID']>;
  processStatus?: InputMaybe<Process_Status>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortField?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  postType?: InputMaybe<PostType>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;

export type CurrentCompanyProcessQuery = {
  __typename?: 'Query';
  isFiltersApplied?: boolean | null | undefined;
  count?: number | null | undefined;
  currentCompanyProcess: Array<{
    __typename?: 'Post';
    id: string;
    postType?: PostType | null | undefined;
    title?: string | null | undefined;
    content?: string | null | undefined;
    contentImageUrls?: Array<string> | null | undefined;
    commentCount?: number | null | undefined;
    isSeenByMe?: boolean | null | undefined;
    isMine?: boolean | null | undefined;
    createdAt?: any | null | undefined;
    isLikedByMe?: boolean | null | undefined;
    likeCount?: number | null | undefined;
    shareCount?: number | null | undefined;
    draft?: boolean | null | undefined;
    reopened?: boolean | null | undefined;
    processStatus?: Process_Status | null | undefined;
    isCMValidator?: boolean | null | undefined;
    fileCount?: number | null | undefined;
    processValidators?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    createdBy?:
      | {
          __typename?: 'User';
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          fullName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    cmCreatedBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    responsibles?:
      | Array<
          | {
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              fullName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    communities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
    sharedCompanyMemberPosts?:
      | Array<{
          __typename?: 'CompanyMemberPostShare';
          communities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logo?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    companyMemberExperts?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    comments?:
      | Array<{
          __typename?: 'Comment';
          id: string;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isMine?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          isLikedByMe?: boolean | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          likedBy?:
            | Array<{
                __typename?: 'CompanyMember';
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      currentCompanyMember?:
                        | { __typename?: 'CompanyMember'; id: string }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    imageFiles?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    recipientCommunity?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    presentationFile?:
      | {
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }
      | null
      | undefined;
    myRating?: { __typename?: 'PostRating'; id: string; rating: number } | null | undefined;
    ratingSummary?:
      | {
          __typename?: 'PostRatingSummary';
          two?: number | null | undefined;
          one?: number | null | undefined;
          three?: number | null | undefined;
          four?: number | null | undefined;
          five?: number | null | undefined;
          totalRatingCount?: number | null | undefined;
          globalRating?: number | null | undefined;
        }
      | null
      | undefined;
  }>;
  currentWholeCompany: { __typename?: 'Community'; id: string; name?: string | null | undefined };
};

export type CurrentCompanyStoriesQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  filterString?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  postType?: InputMaybe<PostType>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;

export type CurrentCompanyStoriesQuery = {
  __typename?: 'Query';
  isFiltersApplied?: boolean | null | undefined;
  count?: number | null | undefined;
  currentCompanyStories: Array<{
    __typename?: 'Post';
    id: string;
    postType?: PostType | null | undefined;
    title?: string | null | undefined;
    content?: string | null | undefined;
    contentImageUrls?: Array<string> | null | undefined;
    createdAt?: any | null | undefined;
    isSeenByMe?: boolean | null | undefined;
    isMine?: boolean | null | undefined;
    isLikedByMe?: boolean | null | undefined;
    likeCount?: number | null | undefined;
    shareCount?: number | null | undefined;
    commentCount?: number | null | undefined;
    fileCount?: number | null | undefined;
    cmCreatedBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                photoUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    comments?:
      | Array<{
          __typename?: 'Comment';
          id: string;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isMine?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          isLikedByMe?: boolean | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          likedBy?:
            | Array<{
                __typename?: 'CompanyMember';
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      currentCompanyMember?:
                        | { __typename?: 'CompanyMember'; id: string }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    imageFiles?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    presentationFile?:
      | {
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }
      | null
      | undefined;
    sharedToSpecificUsers?:
      | Array<{
          __typename?: 'User';
          id: string;
          firstName?: string | null | undefined;
          lastName?: string | null | undefined;
          fullName?: string | null | undefined;
          photoUrl?: string | null | undefined;
          photoPublicUrl?: string | null | undefined;
          currentCompanyMember?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
        }>
      | null
      | undefined;
    sharedToSpecificCommunities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
    sharedToSpecificCompanyMembers?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                firstName?: string | null | undefined;
                currentCompanyMember?:
                  | { __typename?: 'CompanyMember'; id: string }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  }>;
  currentWholeCompany: { __typename?: 'Community'; id: string; name?: string | null | undefined };
};

export type CurrentCompanyFunsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  postToShow?: InputMaybe<Post_To_Show>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  filterString?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  postType?: InputMaybe<PostType>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;

export type CurrentCompanyFunsQuery = {
  __typename?: 'Query';
  isFiltersApplied?: boolean | null | undefined;
  count?: number | null | undefined;
  currentCompanyFuns: Array<{
    __typename?: 'Post';
    id: string;
    postType?: PostType | null | undefined;
    title?: string | null | undefined;
    content?: string | null | undefined;
    contentImageUrls?: Array<string> | null | undefined;
    createdAt?: any | null | undefined;
    isSeenByMe?: boolean | null | undefined;
    isMine?: boolean | null | undefined;
    isLikedByMe?: boolean | null | undefined;
    likeCount?: number | null | undefined;
    shareCount?: number | null | undefined;
    commentCount?: number | null | undefined;
    fileCount?: number | null | undefined;
    cmCreatedBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                photoUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    comments?:
      | Array<{
          __typename?: 'Comment';
          id: string;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isMine?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          isLikedByMe?: boolean | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          likedBy?:
            | Array<{
                __typename?: 'CompanyMember';
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      currentCompanyMember?:
                        | { __typename?: 'CompanyMember'; id: string }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    imageFiles?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    presentationFile?:
      | {
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }
      | null
      | undefined;
    sharedToSpecificCommunities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
  }>;
  currentWholeCompany: { __typename?: 'Community'; id: string; name?: string | null | undefined };
};

export type ProblemCircuitsQueryVariables = Exact<{ [key: string]: never }>;

export type ProblemCircuitsQuery = {
  __typename?: 'Query';
  allProblemCircuits?:
    | Array<{ __typename?: 'ProblemCircuit'; id: string; title?: string | null | undefined }>
    | null
    | undefined;
};

export type CurrentCompanyQuestionsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
  communityId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Post_Sort_Type>;
  postToShow?: InputMaybe<Post_To_Show>;
  filterBy?: InputMaybe<PostFilterByInput>;
  filterString?: InputMaybe<Scalars['String']>;
  postType?: InputMaybe<PostType>;
  page?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<Array<Filter> | Filter>;
}>;

export type CurrentCompanyQuestionsQuery = {
  __typename?: 'Query';
  isFiltersApplied?: boolean | null | undefined;
  count?: number | null | undefined;
  currentCompanyQuestions?:
    | Array<{
        __typename?: 'Post';
        id: string;
        postType?: PostType | null | undefined;
        title?: string | null | undefined;
        shareCount?: number | null | undefined;
        removed?: boolean | null | undefined;
        isSeenByMe?: boolean | null | undefined;
        isMine?: boolean | null | undefined;
        createdAt?: any | null | undefined;
        commentCount?: number | null | undefined;
        createdBy?:
          | {
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              fullName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        cmCreatedBy?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        recipientCommunity?:
          | {
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        sharedToSpecificCommunities?:
          | Array<{
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }>
          | null
          | undefined;
        comments?:
          | Array<{
              __typename?: 'Comment';
              id: string;
              content?: string | null | undefined;
              createdAt?: any | null | undefined;
              isMine?: boolean | null | undefined;
              likeCount?: number | null | undefined;
              isLikedByMe?: boolean | null | undefined;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              likedBy?:
                | Array<{
                    __typename?: 'CompanyMember';
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          lastName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          fullName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                          currentCompanyMember?:
                            | { __typename?: 'CompanyMember'; id: string }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
        questionCategory?:
          | { __typename?: 'QuestionCategory'; id: string; title?: string | null | undefined }
          | null
          | undefined;
        sharedCompanyMemberPosts?:
          | Array<{
              __typename?: 'CompanyMemberPostShare';
              communities?:
                | Array<{
                    __typename?: 'Community';
                    id: string;
                    name?: string | null | undefined;
                    logo?: string | null | undefined;
                    logoPublicUrl?: string | null | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }>
    | null
    | undefined;
  currentWholeCompany: { __typename?: 'Community'; id: string; name?: string | null | undefined };
};

export type GetProblemByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProblemByIdQuery = {
  __typename?: 'Query';
  problem: {
    __typename?: 'Post';
    id: string;
    title?: string | null | undefined;
    content?: string | null | undefined;
    contentImageUrls?: Array<string> | null | undefined;
    problemLevel?: number | null | undefined;
    problemTo?: any | null | undefined;
    who?: string | null | undefined;
    what?: string | null | undefined;
    how?: string | null | undefined;
    where?: string | null | undefined;
    when?: string | null | undefined;
    impact?: string | null | undefined;
    demand?: Demand_Type | null | undefined;
    demandSolveStatus?: Demand_Solve_Status | null | undefined;
    demandImproveStatus?: Demand_Improve_Status | null | undefined;
    createdAt?: any | null | undefined;
    isIssueSolved?: boolean | null | undefined;
    issuePrivacy?: IssuePrivacy | null | undefined;
    finalized?: boolean | null | undefined;
    isAlreadyUseA3Process?: boolean | null | undefined;
    isMeAMemberOfProblemCommunitySource?: boolean | null | undefined;
    commentCount?: number | null | undefined;
    publicCommentCount?: number | null | undefined;
    privateCommentCount?: number | null | undefined;
    fileCount?: number | null | undefined;
    problemCategory?:
      | { __typename?: 'ProblemCategory'; id: string; title?: string | null | undefined }
      | null
      | undefined;
    cmCreatedBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    communities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
    problemCommunitySource?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    companyMemberResponsibles?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    companyMemberExperts?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    comments?:
      | Array<{
          __typename?: 'Comment';
          id: string;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isMine?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          isLikedByMe?: boolean | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          likedBy?:
            | Array<{
                __typename?: 'CompanyMember';
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      currentCompanyMember?:
                        | { __typename?: 'CompanyMember'; id: string }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    publicComments?:
      | Array<{
          __typename?: 'Comment';
          id: string;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isMine?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          isLikedByMe?: boolean | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          likedBy?:
            | Array<{
                __typename?: 'CompanyMember';
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      currentCompanyMember?:
                        | { __typename?: 'CompanyMember'; id: string }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    privateComments?:
      | Array<{
          __typename?: 'Comment';
          id: string;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isMine?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          isLikedByMe?: boolean | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          likedBy?:
            | Array<{
                __typename?: 'CompanyMember';
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      currentCompanyMember?:
                        | { __typename?: 'CompanyMember'; id: string }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    imageFiles?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    presentationFile?:
      | {
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type GetProcessByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProcessByIdQuery = {
  __typename?: 'Query';
  process: {
    __typename?: 'Post';
    id: string;
    title?: string | null | undefined;
    content?: string | null | undefined;
    contentImageUrls?: Array<string> | null | undefined;
    commentCount?: number | null | undefined;
    isSeenByMe?: boolean | null | undefined;
    isMine?: boolean | null | undefined;
    createdAt?: any | null | undefined;
    isLikedByMe?: boolean | null | undefined;
    likeCount?: number | null | undefined;
    shareCount?: number | null | undefined;
    draft?: boolean | null | undefined;
    reopened?: boolean | null | undefined;
    processStatus?: Process_Status | null | undefined;
    isCMValidator?: boolean | null | undefined;
    fileCount?: number | null | undefined;
    processValidators?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    cmCreatedBy?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    companyMemberResponsibles?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    communities?:
      | Array<{
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }>
      | null
      | undefined;
    recipientCommunity?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    sharedCompanyMemberPosts?:
      | Array<{
          __typename?: 'CompanyMemberPostShare';
          id: string;
          communities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logo?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    companyMemberExperts?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
    comments?:
      | Array<{
          __typename?: 'Comment';
          id: string;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isMine?: boolean | null | undefined;
          likeCount?: number | null | undefined;
          isLikedByMe?: boolean | null | undefined;
          companyMember?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          likedBy?:
            | Array<{
                __typename?: 'CompanyMember';
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      lastName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      fullName?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      currentCompanyMember?:
                        | { __typename?: 'CompanyMember'; id: string }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
        }>
      | null
      | undefined;
    files?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    imageFiles?:
      | Array<{
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }>
      | null
      | undefined;
    presentationFile?:
      | {
          __typename?: 'File';
          id: string;
          type?: string | null | undefined;
          size?: number | null | undefined;
          path?: string | null | undefined;
          name?: string | null | undefined;
          publicUrl?: string | null | undefined;
          extension?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type IssueCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type IssueCategoriesQuery = {
  __typename?: 'Query';
  problemCategories: Array<{
    __typename?: 'ProblemCategory';
    id: string;
    title?: string | null | undefined;
    removed?: boolean | null | undefined;
    updatedAt?: any | null | undefined;
    createdAt?: any | null | undefined;
  }>;
};

export type CurrentPostFiltersQueryVariables = Exact<{
  postType: PostType;
}>;

export type CurrentPostFiltersQuery = {
  __typename?: 'Query';
  currentPostFilters: Array<
    | {
        __typename?: 'PostFilterHistory';
        postField: string;
        selectedChip?: string | null | undefined;
        selectedChips: Array<string>;
        selectedDynamicMultiselectItems: Array<string>;
        sliderValue?: Array<number> | null | undefined;
        dateRange?:
          | {
              __typename?: 'DateRangeType';
              endDate?: any | null | undefined;
              startDate?: any | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type GetCurrentCompanyMemberInChargeOfQueryVariables = Exact<{
  postId: Scalars['ID'];
}>;

export type GetCurrentCompanyMemberInChargeOfQuery = {
  __typename?: 'Query';
  isCurrentCompanyMemberInChargeOf?: boolean | null | undefined;
};

export type CreateQuestionCategoryMutationVariables = Exact<{
  title: Scalars['String'];
}>;

export type CreateQuestionCategoryMutation = {
  __typename?: 'Mutation';
  createQuestionCategory: { __typename?: 'QuestionCategory'; id: string };
};

export type DeleteQuestionCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteQuestionCategoryMutation = {
  __typename?: 'Mutation';
  deleteQuestionCategory: { __typename?: 'QuestionCategory'; id: string };
};

export type GetCurrentCompanyAdminQuestionsQueryVariables = Exact<{
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
}>;

export type GetCurrentCompanyAdminQuestionsQuery = {
  __typename?: 'Query';
  currentCompanyAdminQuestions: {
    __typename?: 'PostList';
    total?: number | null | undefined;
    questions?:
      | Array<{
          __typename?: 'Post';
          id: string;
          postType?: PostType | null | undefined;
          title?: string | null | undefined;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isSeenByMe?: boolean | null | undefined;
          isReported?: boolean | null | undefined;
          isHidden?: boolean | null | undefined;
          communities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
                logo?: string | null | undefined;
              }>
            | null
            | undefined;
          recipientCommunity?:
            | {
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logo?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
          cmCreatedBy?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type QuestionCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type QuestionCategoriesQuery = {
  __typename?: 'Query';
  categories: Array<{
    __typename?: 'QuestionCategory';
    id: string;
    title?: string | null | undefined;
    removed?: boolean | null | undefined;
    updatedAt?: any | null | undefined;
    createdAt?: any | null | undefined;
  }>;
};

export type GetCurrentCompanyAdminWhatIsUpQueryVariables = Exact<{
  filter?: InputMaybe<PostFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
}>;

export type GetCurrentCompanyAdminWhatIsUpQuery = {
  __typename?: 'Query';
  currentCompanyAdminStories: {
    __typename?: 'PostList';
    total?: number | null | undefined;
    allWhatsUp?:
      | Array<{
          __typename?: 'Post';
          id: string;
          postType?: PostType | null | undefined;
          title?: string | null | undefined;
          content?: string | null | undefined;
          createdAt?: any | null | undefined;
          isSeenByMe?: boolean | null | undefined;
          isReported?: boolean | null | undefined;
          isHidden?: boolean | null | undefined;
          communities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
                logo?: string | null | undefined;
              }>
            | null
            | undefined;
          sharedToSpecificUsers?:
            | Array<{
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                currentCompanyMember?:
                  | { __typename?: 'CompanyMember'; id: string }
                  | null
                  | undefined;
              }>
            | null
            | undefined;
          sharedToSpecificCommunities?:
            | Array<{
                __typename?: 'Community';
                id: string;
                name?: string | null | undefined;
                logo?: string | null | undefined;
                logoPublicUrl?: string | null | undefined;
              }>
            | null
            | undefined;
          cmCreatedBy?:
            | {
                __typename?: 'CompanyMember';
                id: string;
                user?:
                  | {
                      __typename?: 'User';
                      id: string;
                      fullName?: string | null | undefined;
                      firstName?: string | null | undefined;
                      lastName?: string | null | undefined;
                      photoPublicUrl?: string | null | undefined;
                      photoUrl?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type ProductInfoFragment = {
  __typename?: 'Product';
  id: string;
  title?: string | null | undefined;
  price?: number | null | undefined;
};

export type GetCurrentCompanyProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentCompanyProductsQuery = {
  __typename?: 'Query';
  getCurrentCompanyProducts?:
    | Array<
        | {
            __typename?: 'Product';
            id: string;
            title?: string | null | undefined;
            price?: number | null | undefined;
            thumbnail?:
              | {
                  __typename?: 'File';
                  id: string;
                  name?: string | null | undefined;
                  path?: string | null | undefined;
                  publicUrl?: string | null | undefined;
                }
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetProductByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProductByIdQuery = {
  __typename?: 'Query';
  Product?:
    | {
        __typename?: 'Product';
        id: string;
        title?: string | null | undefined;
        price?: number | null | undefined;
        thumbnail?:
          | {
              __typename?: 'File';
              id: string;
              name?: string | null | undefined;
              path?: string | null | undefined;
              publicUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ProjectInA3FragmentFragment = {
  __typename?: 'Project';
  id: string;
  title?: string | null | undefined;
  description?: string | null | undefined;
  isCompleted?: boolean | null | undefined;
  targetDate?: any | null | undefined;
  status?: ProjectStatus | null | undefined;
  priorityLevel?: number | null | undefined;
  finalized?: boolean | null | undefined;
  companyMemberResponsible?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        user?:
          | {
              __typename?: 'User';
              id: string;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              fullName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  convertedFrom?:
    | { __typename?: 'Post'; id: string; postType?: PostType | null | undefined }
    | null
    | undefined;
};

export type CreateProjectMutationVariables = Exact<{
  title: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  targetDate?: InputMaybe<Scalars['Date']>;
  priorityLevel?: InputMaybe<Scalars['Int']>;
  responsibleId?: InputMaybe<Scalars['ID']>;
  communityId: Scalars['ID'];
  convertedFromId?: InputMaybe<Scalars['ID']>;
  fromSelectedCommunityId?: InputMaybe<Scalars['ID']>;
  postId?: InputMaybe<Scalars['ID']>;
}>;

export type CreateProjectMutation = {
  __typename?: 'Mutation';
  createProject: { __typename?: 'Project'; id: string };
};

export type UpdateProjectMutationVariables = Exact<{
  id: Scalars['ID'];
  title: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  targetDate?: InputMaybe<Scalars['Date']>;
  priorityLevel?: InputMaybe<Scalars['Int']>;
  responsibleId?: InputMaybe<Scalars['ID']>;
  communityId: Scalars['ID'];
}>;

export type UpdateProjectMutation = {
  __typename?: 'Mutation';
  updateProject: {
    __typename?: 'Project';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    priorityLevel?: number | null | undefined;
    targetDate?: any | null | undefined;
    isCompleted?: boolean | null | undefined;
    companyMemberResponsible?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?: { __typename?: 'User'; id: string } | null | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateProjectStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  status: ProjectStatus;
}>;

export type UpdateProjectStatusMutation = {
  __typename?: 'Mutation';
  updateProjectStatus: {
    __typename?: 'Project';
    id: string;
    status?: ProjectStatus | null | undefined;
  };
};

export type FinalizeProjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type FinalizeProjectMutation = {
  __typename?: 'Mutation';
  finalizeProject: { __typename?: 'Project'; id: string; finalized?: boolean | null | undefined };
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteProjectMutation = {
  __typename?: 'Mutation';
  deleteProject: { __typename?: 'Project'; id: string };
};

export type GetProjectsByCommunityQueryVariables = Exact<{
  id: Scalars['ID'];
  perPage?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
}>;

export type GetProjectsByCommunityQuery = {
  __typename?: 'Query';
  projects: Array<{
    __typename?: 'Project';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    isCompleted?: boolean | null | undefined;
    targetDate?: any | null | undefined;
    status?: ProjectStatus | null | undefined;
    priorityLevel?: number | null | undefined;
    finalized?: boolean | null | undefined;
    companyMemberResponsible?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    convertedFrom?:
      | { __typename?: 'Post'; id: string; postType?: PostType | null | undefined }
      | null
      | undefined;
  }>;
  total: { __typename?: 'ListMetadata'; count: number };
};

export type GetProjectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetProjectQuery = {
  __typename?: 'Query';
  project: {
    __typename?: 'Project';
    id: string;
    title?: string | null | undefined;
    description?: string | null | undefined;
    isCompleted?: boolean | null | undefined;
    targetDate?: any | null | undefined;
    status?: ProjectStatus | null | undefined;
    priorityLevel?: number | null | undefined;
    finalized?: boolean | null | undefined;
    createdByCompanyMember?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    companyMemberResponsible?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    community?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logo?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
        }
      | null
      | undefined;
    convertedFrom?:
      | { __typename?: 'Post'; id: string; postType?: PostType | null | undefined }
      | null
      | undefined;
    comments?:
      | Array<
          | {
              __typename?: 'Comment';
              id: string;
              content?: string | null | undefined;
              createdAt?: any | null | undefined;
              isMine?: boolean | null | undefined;
              likeCount?: number | null | undefined;
              isLikedByMe?: boolean | null | undefined;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          fullName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              likedBy?:
                | Array<{
                    __typename?: 'CompanyMember';
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                          lastName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          currentCompanyMember?:
                            | { __typename?: 'CompanyMember'; id: string }
                            | null
                            | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type UserThankInfoFragment = {
  __typename?: 'UserThank';
  id: string;
  content?: string | null | undefined;
  response?: string | null | undefined;
  alreadySeen?: boolean | null | undefined;
  score?: number | null | undefined;
  createdAt?: any | null | undefined;
  companyMemberSenderId?: string | null | undefined;
};

export type CommunityBadgeFragmentFragment = {
  __typename?: 'CommunityBadge';
  badgeId: string;
  communityId: string;
  status?: number | null | undefined;
  done?: boolean | null | undefined;
};

export type IndividualBadgeFragmentFragment = {
  __typename?: 'IndividualBadge';
  badgeId: string;
  companyMemberId: string;
  status?: number | null | undefined;
};

export type BadgeFragmentFragment = {
  __typename?: 'Badge';
  id: string;
  name?: string | null | undefined;
  description?: string | null | undefined;
  icon?: string | null | undefined;
  iconPublicUrl?: string | null | undefined;
  type?: BadgeType | null | undefined;
  category?: BadgeCategory | null | undefined;
  points?: number | null | undefined;
};

export type CreateMottoMutationVariables = Exact<{
  content: Scalars['String'];
}>;

export type CreateMottoMutation = {
  __typename?: 'Mutation';
  createMotto?: { __typename?: 'Motto'; id: string; content: string } | null | undefined;
};

export type ChooseProfessionnalWeatherMutationVariables = Exact<{
  professionnalWeatherId: Scalars['ID'];
}>;

export type ChooseProfessionnalWeatherMutation = {
  __typename?: 'Mutation';
  chooseProfessionnalWeather?:
    | {
        __typename?: 'CompanyMemberProfessionnalWeather';
        id: string;
        professionnalWeatherId?: string | null | undefined;
        companyId?: string | null | undefined;
        companyMember?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              currentProfessionnalWeather?:
                | {
                    __typename?: 'ProfessionnalWeather';
                    id: string;
                    title?: string | null | undefined;
                    icon?: string | null | undefined;
                    description?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ThanksSomeoneMutationVariables = Exact<{
  companyMemberReceiverIds: Array<InputMaybe<Scalars['ID']>> | InputMaybe<Scalars['ID']>;
  content: Scalars['String'];
  type?: InputMaybe<UserThankType>;
}>;

export type ThanksSomeoneMutation = {
  __typename?: 'Mutation';
  thanksSomeone: { __typename?: 'UserThank'; id: string };
};

export type ReplyThanksMutationVariables = Exact<{
  id: Scalars['ID'];
  response: Scalars['String'];
}>;

export type ReplyThanksMutation = {
  __typename?: 'Mutation';
  replyThanks: {
    __typename?: 'UserThank';
    id: string;
    response?: string | null | undefined;
    companyMemberSender?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          removed?: boolean | null | undefined;
          user?:
            | {
                __typename?: 'User';
                id: string;
                email?: string | null | undefined;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
                suspended?: boolean | null | undefined;
              }
            | null
            | undefined;
          company?:
            | { __typename?: 'Company'; id: string; slug?: string | null | undefined }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type WishSomeoneMutationVariables = Exact<{
  userId: Scalars['ID'];
  wish: Scalars['String'];
}>;

export type WishSomeoneMutation = {
  __typename?: 'Mutation';
  wishSomeone?: string | null | undefined;
};

export type UpdateUserPictureMutationVariables = Exact<{
  picturesToAdd?: InputMaybe<Array<PictureInput> | PictureInput>;
  picturesIdToRemove?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type UpdateUserPictureMutation = {
  __typename?: 'Mutation';
  updateUserPicture: {
    __typename?: 'User';
    id: string;
    pictures?: Array<{ __typename?: 'UserPicture'; id: string }> | null | undefined;
  };
};

export type UpdateLeaderMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['String'];
}>;

export type UpdateLeaderMessageMutation = {
  __typename?: 'Mutation';
  updateLeaderMessage: {
    __typename?: 'LeaderMessage';
    id: string;
    message?: string | null | undefined;
  };
};

export type ChooseProfessionalStatusMutationVariables = Exact<{
  professionalStatusId?: InputMaybe<Scalars['ID']>;
}>;

export type ChooseProfessionalStatusMutation = {
  __typename?: 'Mutation';
  chooseProfessionalStatus?:
    | { __typename?: 'ProfessionalStatus'; title?: string | null | undefined }
    | null
    | undefined;
};

export type UpdateCustomBadgeMutationVariables = Exact<{
  input: UpdateCustomBadgeInput;
}>;

export type UpdateCustomBadgeMutation = {
  __typename?: 'Mutation';
  updateCustomBadge: {
    __typename?: 'CustomBadge';
    badgeId: string;
    companyId: string;
    minExp?: number | null | undefined;
    maxExp?: number | null | undefined;
    prevMinExp?: number | null | undefined;
    nextMaxExp?: number | null | undefined;
    badge?:
      | {
          __typename?: 'Badge';
          id: string;
          name?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
          type?: BadgeType | null | undefined;
          minExp?: number | null | undefined;
          maxExp?: number | null | undefined;
        }
      | null
      | undefined;
  };
};

export type UpdateExpEventMutationVariables = Exact<{
  input: UpdateExpEventInput;
}>;

export type UpdateExpEventMutation = {
  __typename?: 'Mutation';
  updateExpEvent: {
    __typename?: 'ExpEvent';
    id: string;
    exp?: number | null | undefined;
    communityExp?: number | null | undefined;
  };
};

export type AllProfessionnalWeathersQueryVariables = Exact<{ [key: string]: never }>;

export type AllProfessionnalWeathersQuery = {
  __typename?: 'Query';
  allProfessionnalWeathers: Array<{
    __typename?: 'ProfessionnalWeather';
    id: string;
    title?: string | null | undefined;
    icon?: string | null | undefined;
  }>;
};

export type AllProfessionalStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type AllProfessionalStatusesQuery = {
  __typename?: 'Query';
  allProfessionalStatuses: Array<{
    __typename?: 'ProfessionalStatus';
    id: string;
    title?: string | null | undefined;
    icon?: string | null | undefined;
    order?: number | null | undefined;
  }>;
};

export type GetEventsTodayQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetEventsTodayQuery = {
  __typename?: 'Query';
  getEventsToday?:
    | Array<{
        __typename?: 'Event';
        id: string;
        name?: string | null | undefined;
        description?: string | null | undefined;
        day?: any | null | undefined;
        eventType?:
          | {
              __typename?: 'EventType';
              id: string;
              name: string;
              icon?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
              eventMessageSuggestions?:
                | Array<
                    | { __typename?: 'EventMessageSuggestion'; id: string; content: string }
                    | null
                    | undefined
                  >
                | null
                | undefined;
            }
          | null
          | undefined;
        companyMembers?:
          | Array<
              | {
                  __typename?: 'CompanyMember';
                  id: string;
                  user?:
                    | {
                        __typename?: 'User';
                        id: string;
                        fullName?: string | null | undefined;
                        firstName?: string | null | undefined;
                        lastName?: string | null | undefined;
                        photoUrl?: string | null | undefined;
                        photoPublicUrl?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type GetMessageSuggestionsQueryVariables = Exact<{
  type?: InputMaybe<MessageType>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetMessageSuggestionsQuery = {
  __typename?: 'Query';
  getMessageSuggestions?:
    | Array<{
        __typename?: 'MessageSuggestion';
        id: string;
        content: string;
        type?: MessageType | null | undefined;
      }>
    | null
    | undefined;
};

export type CoworkersQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['ID']>;
}>;

export type CoworkersQuery = {
  __typename?: 'Query';
  coworkers?:
    | Array<
        | {
            __typename?: 'User';
            id: string;
            email?: string | null | undefined;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
            photoUrl?: string | null | undefined;
            photoPublicUrl?: string | null | undefined;
            role?: MainRole | null | undefined;
            companyMembers?:
              | Array<
                  | { __typename?: 'CompanyMember'; jobTitle?: string | null | undefined }
                  | null
                  | undefined
                >
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetAllEventsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllEventsQuery = {
  __typename?: 'Query';
  allEvents?:
    | Array<{
        __typename?: 'Event';
        id: string;
        name?: string | null | undefined;
        eventType?:
          | {
              __typename?: 'EventType';
              id: string;
              name: string;
              icon?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type GetAllEventTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllEventTypesQuery = {
  __typename?: 'Query';
  allEventTypes?:
    | Array<{
        __typename?: 'EventType';
        id: string;
        name: string;
        icon?: string | null | undefined;
        iconPublicUrl?: string | null | undefined;
        description?: string | null | undefined;
        events?:
          | Array<
              | {
                  __typename?: 'Event';
                  id: string;
                  name?: string | null | undefined;
                  description?: string | null | undefined;
                  day?: any | null | undefined;
                  removed?: boolean | null | undefined;
                  updatedAt?: any | null | undefined;
                  createdAt?: any | null | undefined;
                  companyMembers?:
                    | Array<
                        | {
                            __typename?: 'CompanyMember';
                            id: string;
                            hiringDate?: any | null | undefined;
                            userId?: string | null | undefined;
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined;
                  eventType?: { __typename?: 'EventType'; id: string } | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type CurrentCompanyLastLeaderMessageQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentCompanyLastLeaderMessageQuery = {
  __typename?: 'Query';
  currentCompanyLastLeaderMessage?:
    | {
        __typename?: 'LeaderMessage';
        id: string;
        message?: string | null | undefined;
        member?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              jobTitle?: string | null | undefined;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SeeThanksQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SeeThanksQuery = {
  __typename?: 'Query';
  seeThanks: {
    __typename?: 'UserThank';
    id: string;
    content?: string | null | undefined;
    response?: string | null | undefined;
    alreadySeen?: boolean | null | undefined;
    score?: number | null | undefined;
    createdAt?: any | null | undefined;
    companyMemberSenderId?: string | null | undefined;
    companyMemberSender?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    companyMemberReceivers?:
      | Array<{
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }>
      | null
      | undefined;
  };
};

export type CurrentProfessionnalWeatherQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentProfessionnalWeatherQuery = {
  __typename?: 'Query';
  currentCompanyMember: {
    __typename?: 'CompanyMember';
    id: string;
    currentProfessionnalWeather?:
      | {
          __typename?: 'ProfessionnalWeather';
          id: string;
          title?: string | null | undefined;
          icon?: string | null | undefined;
        }
      | null
      | undefined;
  };
};

export type CurrentProfessionalStatusQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentProfessionalStatusQuery = {
  __typename?: 'Query';
  currentCompanyMember: {
    __typename?: 'CompanyMember';
    currentProfessionalStatus?:
      | {
          __typename?: 'ProfessionalStatus';
          title?: string | null | undefined;
          icon?: string | null | undefined;
          description?: string | null | undefined;
          order?: number | null | undefined;
        }
      | null
      | undefined;
  };
};

export type NewIndividualBadgeSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewIndividualBadgeSubscription = {
  __typename?: 'Subscription';
  newIndividualBadge?:
    | {
        __typename?: 'IndividualBadge';
        badgeId: string;
        companyMemberId: string;
        badge?:
          | {
              __typename?: 'Badge';
              id: string;
              name?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
              customBadge?:
                | {
                    __typename?: 'CustomBadge';
                    badgeId: string;
                    companyId: string;
                    minExp?: number | null | undefined;
                  }
                | null
                | undefined;
              nextBadge?:
                | {
                    __typename?: 'Badge';
                    id: string;
                    name?: string | null | undefined;
                    customBadge?:
                      | {
                          __typename?: 'CustomBadge';
                          badgeId: string;
                          companyId: string;
                          minExp?: number | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type NewCommunityBadgeSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewCommunityBadgeSubscription = {
  __typename?: 'Subscription';
  newCommunityBadge?:
    | {
        __typename?: 'CommunityBadge';
        badgeId: string;
        communityId: string;
        community?:
          | {
              __typename?: 'Community';
              id: string;
              name?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
              exp?: number | null | undefined;
              isMeAMember?: boolean | null | undefined;
              coins?: number | null | undefined;
            }
          | null
          | undefined;
        badge?:
          | {
              __typename?: 'Badge';
              id: string;
              name?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
              customBadge?:
                | {
                    __typename?: 'CustomBadge';
                    badgeId: string;
                    companyId: string;
                    minExp?: number | null | undefined;
                  }
                | null
                | undefined;
              nextBadge?:
                | {
                    __typename?: 'Badge';
                    id: string;
                    name?: string | null | undefined;
                    customBadge?:
                      | {
                          __typename?: 'CustomBadge';
                          badgeId: string;
                          companyId: string;
                          minExp?: number | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetBadgesByCommunityQueryVariables = Exact<{
  communityId: Scalars['ID'];
}>;

export type GetBadgesByCommunityQuery = {
  __typename?: 'Query';
  badgesByCommunity?:
    | Array<{
        __typename?: 'CommunityBadge';
        badgeId: string;
        communityId: string;
        status?: number | null | undefined;
        done?: boolean | null | undefined;
        badge?:
          | {
              __typename?: 'Badge';
              id: string;
              name?: string | null | undefined;
              description?: string | null | undefined;
              icon?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
              type?: BadgeType | null | undefined;
              category?: BadgeCategory | null | undefined;
              points?: number | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type GetDailyDonesCommunityBadgesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDailyDonesCommunityBadgesQuery = {
  __typename?: 'Query';
  getDailyDonesCommunityBadges?:
    | Array<{
        __typename?: 'CommunityBadge';
        badgeId: string;
        communityId: string;
        status?: number | null | undefined;
        done?: boolean | null | undefined;
        badge?:
          | {
              __typename?: 'Badge';
              id: string;
              name?: string | null | undefined;
              description?: string | null | undefined;
              icon?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
              type?: BadgeType | null | undefined;
              category?: BadgeCategory | null | undefined;
              points?: number | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type GetBadgesByCompanyMemberQueryVariables = Exact<{
  companyMemberId: Scalars['ID'];
}>;

export type GetBadgesByCompanyMemberQuery = {
  __typename?: 'Query';
  getBadgesByCompanyMember?:
    | Array<{
        __typename?: 'IndividualBadge';
        badgeId: string;
        companyMemberId: string;
        status?: number | null | undefined;
        badge?:
          | {
              __typename?: 'Badge';
              id: string;
              name?: string | null | undefined;
              description?: string | null | undefined;
              icon?: string | null | undefined;
              iconPublicUrl?: string | null | undefined;
              type?: BadgeType | null | undefined;
              category?: BadgeCategory | null | undefined;
              points?: number | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type GetBadgesByCategoryQueryVariables = Exact<{
  communityId?: InputMaybe<Scalars['ID']>;
  companyMemberId?: InputMaybe<Scalars['ID']>;
}>;

export type GetBadgesByCategoryQuery = {
  __typename?: 'Query';
  getBadgesByCategory: {
    __typename?: 'BadgesByCategory';
    community?:
      | {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
          mission?: string | null | undefined;
          myCommunityRole?: CommunityRole | null | undefined;
          exp?: number | null | undefined;
          coins?: number | null | undefined;
          expLevel?:
            | {
                __typename?: 'Badge';
                id: string;
                name?: string | null | undefined;
                iconPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    achivements: Array<{
      __typename?: 'Badges';
      badgeId: string;
      status: number;
      badge: {
        __typename?: 'Badge';
        id: string;
        name?: string | null | undefined;
        description?: string | null | undefined;
        icon?: string | null | undefined;
        iconPublicUrl?: string | null | undefined;
        type?: BadgeType | null | undefined;
        category?: BadgeCategory | null | undefined;
        points?: number | null | undefined;
      };
    }>;
    special: Array<{
      __typename?: 'Badges';
      badgeId: string;
      status: number;
      badge: {
        __typename?: 'Badge';
        id: string;
        name?: string | null | undefined;
        description?: string | null | undefined;
        icon?: string | null | undefined;
        iconPublicUrl?: string | null | undefined;
        type?: BadgeType | null | undefined;
        category?: BadgeCategory | null | undefined;
        points?: number | null | undefined;
      };
    }>;
    pointsCollection: Array<{
      __typename?: 'Badges';
      badgeId: string;
      status: number;
      badge: {
        __typename?: 'Badge';
        id: string;
        name?: string | null | undefined;
        description?: string | null | undefined;
        icon?: string | null | undefined;
        iconPublicUrl?: string | null | undefined;
        type?: BadgeType | null | undefined;
        category?: BadgeCategory | null | undefined;
        points?: number | null | undefined;
        customBadge?:
          | {
              __typename?: 'CustomBadge';
              minExp?: number | null | undefined;
              maxExp?: number | null | undefined;
            }
          | null
          | undefined;
      };
    }>;
  };
};

export type GetDailyDonesBadgesByCategoryQueryVariables = Exact<{ [key: string]: never }>;

export type GetDailyDonesBadgesByCategoryQuery = {
  __typename?: 'Query';
  getDailyDonesBadgesByCategory: {
    __typename?: 'BadgesByCategory';
    achivements: Array<{
      __typename?: 'Badges';
      badgeId: string;
      status: number;
      badge: {
        __typename?: 'Badge';
        id: string;
        name?: string | null | undefined;
        description?: string | null | undefined;
        icon?: string | null | undefined;
        iconPublicUrl?: string | null | undefined;
        type?: BadgeType | null | undefined;
        category?: BadgeCategory | null | undefined;
        points?: number | null | undefined;
      };
    }>;
    special: Array<{
      __typename?: 'Badges';
      badgeId: string;
      status: number;
      badge: {
        __typename?: 'Badge';
        id: string;
        name?: string | null | undefined;
        description?: string | null | undefined;
        icon?: string | null | undefined;
        iconPublicUrl?: string | null | undefined;
        type?: BadgeType | null | undefined;
        category?: BadgeCategory | null | undefined;
        points?: number | null | undefined;
      };
    }>;
    pointsCollection: Array<{
      __typename?: 'Badges';
      badgeId: string;
      status: number;
      badge: {
        __typename?: 'Badge';
        id: string;
        name?: string | null | undefined;
        description?: string | null | undefined;
        icon?: string | null | undefined;
        iconPublicUrl?: string | null | undefined;
        type?: BadgeType | null | undefined;
        category?: BadgeCategory | null | undefined;
        points?: number | null | undefined;
      };
    }>;
  };
};

export type GetEarnedExpEventsQueryVariables = Exact<{ [key: string]: never }>;

export type GetEarnedExpEventsQuery = {
  __typename?: 'Query';
  earnedExpEvents: Array<{
    __typename?: 'EarnedExpEvent';
    id: string;
    earnedAt?: any | null | undefined;
    expEvent?:
      | { __typename?: 'ExpEvent'; id: string; exp?: number | null | undefined }
      | null
      | undefined;
  }>;
};

export type GetDailyExpQueryVariables = Exact<{ [key: string]: never }>;

export type GetDailyExpQuery = {
  __typename?: 'Query';
  dailyExp: Array<{ __typename?: 'DailyExp'; value: number; date: string }>;
};

export type GetTodaysEarnedExpQueryVariables = Exact<{ [key: string]: never }>;

export type GetTodaysEarnedExpQuery = {
  __typename?: 'Query';
  todaysEarnedExp: {
    __typename?: 'TodaysEarnedExp';
    exp: number;
    earnedExp: Array<{
      __typename?: 'EarnedExpEvent';
      id: string;
      description?: string | null | undefined;
      earnedAt?: any | null | undefined;
    }>;
  };
};

export type GetCustomBadgesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomBadgesQuery = {
  __typename?: 'Query';
  allCustomBadges: Array<{
    __typename?: 'CustomBadge';
    badgeId: string;
    companyId: string;
    minExp?: number | null | undefined;
    maxExp?: number | null | undefined;
    prevMinExp?: number | null | undefined;
    nextMaxExp?: number | null | undefined;
    badge?:
      | {
          __typename?: 'Badge';
          id: string;
          name?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
          type?: BadgeType | null | undefined;
          minExp?: number | null | undefined;
          maxExp?: number | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetAllExpEventsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sortOrder?: InputMaybe<Scalars['String']>;
  sortField?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ExpEventFilter>;
}>;

export type GetAllExpEventsQuery = {
  __typename?: 'Query';
  expEvents: Array<{
    __typename?: 'ExpEvent';
    id: string;
    exp?: number | null | undefined;
    communityExp?: number | null | undefined;
    genericExpEvent?:
      | {
          __typename?: 'GenericExpEvent';
          id: string;
          name?: string | null | undefined;
          description?: string | null | undefined;
          expEventType?: ExpEventType | null | undefined;
        }
      | null
      | undefined;
  }>;
  total: { __typename?: 'ListMetadata'; count: number };
};

export type GetExpEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetExpEventQuery = {
  __typename?: 'Query';
  expEvent?:
    | {
        __typename?: 'ExpEvent';
        id: string;
        exp?: number | null | undefined;
        communityExp?: number | null | undefined;
        genericExpEvent?:
          | {
              __typename?: 'GenericExpEvent';
              id: string;
              name?: string | null | undefined;
              description?: string | null | undefined;
              expEventType?: ExpEventType | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IncomingSemiInstantCallSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type IncomingSemiInstantCallSubscription = {
  __typename?: 'Subscription';
  incomingSemiInstantCall?:
    | {
        __typename?: 'SemiInstantCall';
        id: string;
        name?: string | null | undefined;
        amITheHost?: boolean | null | undefined;
        description?: string | null | undefined;
        jitsiMeetUrl?: string | null | undefined;
        createdAt?: any | null | undefined;
        createdBy?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        guests?:
          | Array<{
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type NewEarnedCoinSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewEarnedCoinSubscription = {
  __typename?: 'Subscription';
  newEarnedCoin?:
    | {
        __typename?: 'CoinHistory';
        id: string;
        companyMemberId?: string | null | undefined;
        type?: CoinHistoryType | null | undefined;
        description?: string | null | undefined;
        value?: number | null | undefined;
        isSeen?: boolean | null | undefined;
        createdAt?: any | null | undefined;
      }
    | null
    | undefined;
};

export type NewTeamEarnedCoinSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewTeamEarnedCoinSubscription = {
  __typename?: 'Subscription';
  newTeamEarnedCoin?:
    | {
        __typename?: 'CoinHistory';
        id: string;
        communityId?: string | null | undefined;
        type?: CoinHistoryType | null | undefined;
        description?: string | null | undefined;
        value?: number | null | undefined;
        isSeen?: boolean | null | undefined;
        createdAt?: any | null | undefined;
      }
    | null
    | undefined;
};

export type NewCommunityEarnedExpEventSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewCommunityEarnedExpEventSubscription = {
  __typename?: 'Subscription';
  newCommunityEarnedExpEvent?:
    | {
        __typename?: 'EarnedExpEvent';
        id: string;
        earnedByTeam?:
          | {
              __typename?: 'Community';
              id: string;
              exp?: number | null | undefined;
              coins?: number | null | undefined;
              expLevel?:
                | {
                    __typename?: 'Badge';
                    id: string;
                    name?: string | null | undefined;
                    iconPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type NewCommunityExpLevelSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewCommunityExpLevelSubscription = {
  __typename?: 'Subscription';
  newCommunityExpLevel?:
    | {
        __typename?: 'NewCommunityExpLevel';
        earnedByTeam: {
          __typename?: 'Community';
          id: string;
          name?: string | null | undefined;
          logoPublicUrl?: string | null | undefined;
          exp?: number | null | undefined;
          isMeAMember?: boolean | null | undefined;
          coins?: number | null | undefined;
        };
        newExpLevel: {
          __typename?: 'Badge';
          id: string;
          name?: string | null | undefined;
          iconPublicUrl?: string | null | undefined;
          customBadge?:
            | {
                __typename?: 'CustomBadge';
                badgeId: string;
                companyId: string;
                minExp?: number | null | undefined;
              }
            | null
            | undefined;
          nextBadge?:
            | {
                __typename?: 'Badge';
                id: string;
                name?: string | null | undefined;
                customBadge?:
                  | {
                      __typename?: 'CustomBadge';
                      badgeId: string;
                      companyId: string;
                      minExp?: number | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        };
      }
    | null
    | undefined;
};

export type NewEarnedExpEventSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewEarnedExpEventSubscription = {
  __typename?: 'Subscription';
  newEarnedExpEvent?:
    | {
        __typename?: 'EarnedExpEvent';
        id: string;
        earnedBy?:
          | { __typename?: 'CompanyMember'; id: string; exp?: number | null | undefined }
          | null
          | undefined;
        earnedByTeam?:
          | {
              __typename?: 'Community';
              id: string;
              exp?: number | null | undefined;
              coins?: number | null | undefined;
              expLevel?:
                | {
                    __typename?: 'Badge';
                    id: string;
                    name?: string | null | undefined;
                    iconPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        expEvent?:
          | { __typename?: 'ExpEvent'; id: string; communityExp?: number | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type NewExpLevelSubscriptionVariables = Exact<{ [key: string]: never }>;

export type NewExpLevelSubscription = {
  __typename?: 'Subscription';
  newExpLevel?:
    | {
        __typename?: 'Badge';
        id: string;
        name?: string | null | undefined;
        iconPublicUrl?: string | null | undefined;
        customBadge?:
          | {
              __typename?: 'CustomBadge';
              badgeId: string;
              companyId: string;
              minExp?: number | null | undefined;
            }
          | null
          | undefined;
        nextBadge?:
          | {
              __typename?: 'Badge';
              id: string;
              name?: string | null | undefined;
              customBadge?:
                | {
                    __typename?: 'CustomBadge';
                    badgeId: string;
                    companyId: string;
                    minExp?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type NotificationCreatedSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type NotificationCreatedSubscription = {
  __typename?: 'Subscription';
  notificationCreated?:
    | {
        __typename?: 'Notification';
        id: string;
        title?: string | null | undefined;
        description?: string | null | undefined;
        date?: any | null | undefined;
        linkTo?: string | null | undefined;
        isSeen?: boolean | null | undefined;
        seenDate?: any | null | undefined;
        createdAt?: any | null | undefined;
        isValid?: boolean | null | undefined;
        parametres?: Array<string | null | undefined> | null | undefined;
        companyMemberSender?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SentFeedbackSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type SentFeedbackSubscription = {
  __typename?: 'Subscription';
  sentFeedback: {
    __typename?: 'Feedback';
    id: string;
    content?: string | null | undefined;
    response?: string | null | undefined;
    alreadySeen?: boolean | null | undefined;
    createdAt?: any | null | undefined;
    companyMemberSenderId?: string | null | undefined;
    companyMemberSender?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    companyMemberReceiver?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type IsUserOnlineSubscriptionVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IsUserOnlineSubscription = {
  __typename?: 'Subscription';
  isUserOnline?: boolean | null | undefined;
};

export type CreateUserPreferenceMutationVariables = Exact<{
  userTimezone: UserTimezoneInput;
}>;

export type CreateUserPreferenceMutation = {
  __typename?: 'Mutation';
  createUserPreference: {
    __typename?: 'UserPreference';
    id: string;
    companyMember?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
    userTimezone?: { __typename?: 'UserTimezone'; id: string } | null | undefined;
  };
};

export type UpdateUserPreferenceMutationVariables = Exact<{
  userTimezone?: InputMaybe<UserTimezoneInput>;
  coach?: InputMaybe<CoachInput>;
}>;

export type UpdateUserPreferenceMutation = {
  __typename?: 'Mutation';
  updateUserPreference?:
    | {
        __typename?: 'UserPreference';
        id: string;
        showEntryTunnelTime?: any | null | undefined;
        showExitTunnelTime?: any | null | undefined;
        coach?:
          | { __typename?: 'CoachPreference'; enable?: boolean | null | undefined }
          | null
          | undefined;
        companyMember?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
        userTimezone?:
          | {
              __typename?: 'UserTimezone';
              id: string;
              label?: string | null | undefined;
              name?: string | null | undefined;
              tzCode?: string | null | undefined;
              utc?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type SetShowEntryTunnelTodayMutationVariables = Exact<{
  value: Scalars['Boolean'];
}>;

export type SetShowEntryTunnelTodayMutation = {
  __typename?: 'Mutation';
  setShowEntryTunnelToday: {
    __typename?: 'UserPreference';
    id: string;
    isEntryTunnelShownToday?: boolean | null | undefined;
  };
};

export type SetShowExitTunnelTodayMutationVariables = Exact<{
  value: Scalars['Boolean'];
}>;

export type SetShowExitTunnelTodayMutation = {
  __typename?: 'Mutation';
  setShowExitTunnelToday: {
    __typename?: 'UserPreference';
    id: string;
    isExitTunnelShownToday?: boolean | null | undefined;
  };
};

export type SetShowEntryTunnelPragmaTodayMutationVariables = Exact<{
  value: Scalars['Boolean'];
  incrementDay?: InputMaybe<Scalars['Boolean']>;
}>;

export type SetShowEntryTunnelPragmaTodayMutation = {
  __typename?: 'Mutation';
  setShowEntryTunnelPragmaToday: {
    __typename?: 'UserPreference';
    id: string;
    isEntryTunnelShownPragmaToday?: boolean | null | undefined;
  };
};

export type SetShowExitTunnelPragmaTodayMutationVariables = Exact<{
  value: Scalars['Boolean'];
  incrementDay?: InputMaybe<Scalars['Boolean']>;
}>;

export type SetShowExitTunnelPragmaTodayMutation = {
  __typename?: 'Mutation';
  setShowExitTunnelPragmaToday: {
    __typename?: 'UserPreference';
    id: string;
    isExitTunnelShownPragmaToday?: boolean | null | undefined;
  };
};

export type UpdateCoachPreferenceMutationVariables = Exact<{
  input: CoachInput;
}>;

export type UpdateCoachPreferenceMutation = {
  __typename?: 'Mutation';
  updateCoachPreference?:
    | { __typename?: 'CoachPreference'; enable?: boolean | null | undefined }
    | null
    | undefined;
};

export type SetEnableContextualNotificationValueMutationVariables = Exact<{
  value: Scalars['Boolean'];
}>;

export type SetEnableContextualNotificationValueMutation = {
  __typename?: 'Mutation';
  setEnableContextualNotificationValue: {
    __typename?: 'UserPreference';
    id: string;
    enableContextualNotification?: boolean | null | undefined;
  };
};

export type SetEnableNavigateurNotificationValueMutationVariables = Exact<{
  value: Scalars['Boolean'];
}>;

export type SetEnableNavigateurNotificationValueMutation = {
  __typename?: 'Mutation';
  setEnableNavigateurNotificationValue: {
    __typename?: 'UserPreference';
    id: string;
    enableNavigateurNotification?: boolean | null | undefined;
  };
};

export type GetMyCurrentTimezoneQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyCurrentTimezoneQuery = {
  __typename?: 'Query';
  userTimezone?:
    | {
        __typename?: 'UserTimezone';
        id: string;
        label?: string | null | undefined;
        name?: string | null | undefined;
        tzCode?: string | null | undefined;
        utc?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetMyPreferencesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyPreferencesQuery = {
  __typename?: 'Query';
  userPreference?:
    | {
        __typename?: 'UserPreference';
        id: string;
        enableShowEntryTunnel?: boolean | null | undefined;
        enableShowExitTunnel?: boolean | null | undefined;
        showEntryTunnelTime?: any | null | undefined;
        showExitTunnelTime?: any | null | undefined;
        isEntryTunnelShownToday?: boolean | null | undefined;
        isEntryTunnelShownPragmaToday?: boolean | null | undefined;
        isExitTunnelShownToday?: boolean | null | undefined;
        isExitTunnelShownPragmaToday?: boolean | null | undefined;
        professionalStatusActiveHoursTimeEnd?: any | null | undefined;
        professionalStatusActiveHoursTimeStart?: any | null | undefined;
        enableProfessionalStatusActiveHours?: boolean | null | undefined;
        enableNavigateurNotification?: boolean | null | undefined;
        enableContextualNotification?: boolean | null | undefined;
        companyMember?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              company?:
                | { __typename?: 'Company'; id: string; slug?: string | null | undefined }
                | null
                | undefined;
            }
          | null
          | undefined;
        userTimezone?:
          | {
              __typename?: 'UserTimezone';
              id: string;
              label?: string | null | undefined;
              name?: string | null | undefined;
              tzCode?: string | null | undefined;
              utc?: string | null | undefined;
            }
          | null
          | undefined;
        coach?:
          | { __typename?: 'CoachPreference'; enable?: boolean | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UserOnPostInfoFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null | undefined;
  fullName?: string | null | undefined;
  lastName?: string | null | undefined;
  email?: string | null | undefined;
  photoUrl?: string | null | undefined;
  photoPublicUrl?: string | null | undefined;
};

export type NplusAndMinusOneInfoFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  fullName?: string | null | undefined;
  isOnline?: boolean | null | undefined;
  photoUrl?: string | null | undefined;
  photoPublicUrl?: string | null | undefined;
};

export type UserInfoFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  fullName?: string | null | undefined;
  isItMyDay?: boolean | null | undefined;
  email?: string | null | undefined;
  bio?: string | null | undefined;
  isOnline?: boolean | null | undefined;
  photoPublicUrl?: string | null | undefined;
  photoUrl?: string | null | undefined;
  birthday?: any | null | undefined;
  familySituation?: string | null | undefined;
  personality?: UserPersonality | null | undefined;
  mySharedHorizon?: string | null | undefined;
  role?: MainRole | null | undefined;
  is2FA?: boolean | null | undefined;
  suspended?: boolean | null | undefined;
  removed?: boolean | null | undefined;
};

export type UserWithPhotoInLeaveFragmentFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  fullName?: string | null | undefined;
  photoPublicUrl?: string | null | undefined;
  photoUrl?: string | null | undefined;
};

export type UserInLeaveFragmentFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  fullName?: string | null | undefined;
  email?: string | null | undefined;
};

export type UserInProjectFragmentFragment = {
  __typename?: 'User';
  id: string;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  fullName?: string | null | undefined;
  photoUrl?: string | null | undefined;
  photoPublicUrl?: string | null | undefined;
};

export type UserMeInfoFragment = {
  __typename?: 'User';
  id: string;
  fullName?: string | null | undefined;
  firstName?: string | null | undefined;
  lastName?: string | null | undefined;
  photoUrl?: string | null | undefined;
  photoPublicUrl?: string | null | undefined;
  mySharedHorizon?: string | null | undefined;
  bio?: string | null | undefined;
  email?: string | null | undefined;
  birthday?: any | null | undefined;
  familySituation?: string | null | undefined;
  isOnline?: boolean | null | undefined;
  personality?: UserPersonality | null | undefined;
  skills?: Array<{ __typename?: 'Skill'; id: string; title: string }> | null | undefined;
  successes?: Array<{ __typename?: 'UserSuccess'; id: string; title: string }> | null | undefined;
  passions?: Array<{ __typename?: 'Passion'; id: string; title: string }> | null | undefined;
  previousCompanies?:
    | Array<{ __typename?: 'PreviousCompany'; id: string; name: string }>
    | null
    | undefined;
  pictures?:
    | Array<{
        __typename?: 'UserPicture';
        id: string;
        title: string;
        imageUrl: string;
        imagePublicUrl?: string | null | undefined;
      }>
    | null
    | undefined;
  presentationVideo?:
    | { __typename?: 'File'; id: string; publicUrl?: string | null | undefined }
    | null
    | undefined;
  communities?:
    | Array<{
        __typename?: 'UserCommunity';
        id: string;
        community?:
          | {
              __typename?: 'Community';
              id: string;
              companyId?: string | null | undefined;
              name?: string | null | undefined;
            }
          | null
          | undefined;
      }>
    | null
    | undefined;
  currentCompanyMember?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        jobTitle?: string | null | undefined;
        isFirstEntry?: boolean | null | undefined;
        hiringDate?: any | null | undefined;
        userAccess?: MainRole | null | undefined;
        role?: MemberRole | null | undefined;
        businessUnitRole?: BusinessUnitRole | null | undefined;
        businessUnit?:
          | {
              __typename?: 'BusinessUnit';
              id: string;
              name?: string | null | undefined;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
            }
          | null
          | undefined;
        user?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              email?: string | null | undefined;
            }
          | null
          | undefined;
        currentMotto?: { __typename?: 'Motto'; id: string; content: string } | null | undefined;
        nPlusOne?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        currentProfessionnalWeather?:
          | {
              __typename?: 'ProfessionnalWeather';
              id: string;
              title?: string | null | undefined;
              description?: string | null | undefined;
              icon?: string | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type UpdateMottoMutationVariables = Exact<{
  updateMottoId: Scalars['ID'];
  content?: InputMaybe<Scalars['String']>;
}>;

export type UpdateMottoMutation = {
  __typename?: 'Mutation';
  updateMotto?: { __typename?: 'Motto'; id: string; content: string } | null | undefined;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  lastname?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  familySituation?: InputMaybe<Scalars['String']>;
  personality?: InputMaybe<UserPersonality>;
  mySharedHorizon?: InputMaybe<Scalars['String']>;
  skills?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  successes?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  passions?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  previousCompanies?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  presentationVideoId?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<MainRole>;
  suspended?: InputMaybe<Scalars['Boolean']>;
  adress?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser: {
    __typename?: 'User';
    id: string;
    bio?: string | null | undefined;
    email?: string | null | undefined;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
    photoUrl?: string | null | undefined;
    photoPublicUrl?: string | null | undefined;
    birthday?: any | null | undefined;
    familySituation?: string | null | undefined;
    personality?: UserPersonality | null | undefined;
    mySharedHorizon?: string | null | undefined;
    is2FA?: boolean | null | undefined;
    skills?: Array<{ __typename?: 'Skill'; id: string }> | null | undefined;
    successes?: Array<{ __typename?: 'UserSuccess'; id: string }> | null | undefined;
    passions?: Array<{ __typename?: 'Passion'; id: string }> | null | undefined;
    presentationVideo?:
      | { __typename?: 'File'; id: string; publicUrl?: string | null | undefined }
      | null
      | undefined;
  };
};

export type DeleteUserPresentationVideoMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;

export type DeleteUserPresentationVideoMutation = {
  __typename?: 'Mutation';
  deleteUserPresentationVideo: {
    __typename?: 'User';
    id: string;
    presentationVideo?: { __typename?: 'File'; id: string } | null | undefined;
  };
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser: {
    __typename?: 'User';
    id: string;
    removed?: boolean | null | undefined;
    firstName?: string | null | undefined;
    lastName?: string | null | undefined;
  };
};

export type GetMyCompaniesQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyCompaniesQuery = {
  __typename?: 'Query';
  me?:
    | {
        __typename?: 'User';
        id: string;
        companyMembers?:
          | Array<
              | {
                  __typename?: 'CompanyMember';
                  id: string;
                  suspended?: boolean | null | undefined;
                  company?:
                    | {
                        __typename?: 'Company';
                        id: string;
                        slug?: string | null | undefined;
                        logo?: string | null | undefined;
                        logoPublicUrl?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCoworkersQueryVariables = Exact<{ [key: string]: never }>;

export type GetCoworkersQuery = {
  __typename?: 'Query';
  coworkers?:
    | Array<
        | {
            __typename?: 'User';
            id: string;
            fullName?: string | null | undefined;
            firstName?: string | null | undefined;
            lastName?: string | null | undefined;
            photoUrl?: string | null | undefined;
            photoPublicUrl?: string | null | undefined;
            birthday?: any | null | undefined;
            companyMembers?:
              | Array<
                  | {
                      __typename?: 'CompanyMember';
                      id: string;
                      currentMotto?:
                        | { __typename?: 'Motto'; id: string; content: string }
                        | null
                        | undefined;
                      currentProfessionnalWeather?:
                        | {
                            __typename?: 'ProfessionnalWeather';
                            id: string;
                            icon?: string | null | undefined;
                            title?: string | null | undefined;
                          }
                        | null
                        | undefined;
                      company?:
                        | { __typename?: 'Company'; id: string; name?: string | null | undefined }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined
                >
              | null
              | undefined;
            communities?:
              | Array<{
                  __typename?: 'UserCommunity';
                  id: string;
                  community?:
                    | {
                        __typename?: 'Community';
                        id: string;
                        name?: string | null | undefined;
                        slug?: string | null | undefined;
                        logo?: string | null | undefined;
                        logoPublicUrl?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }>
              | null
              | undefined;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type GetMissionsActionsByCompanyMemberQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetMissionsActionsByCompanyMemberQuery = {
  __typename?: 'Query';
  companyMember?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        user?:
          | {
              __typename?: 'User';
              id: string;
              fullName?: string | null | undefined;
              photoUrl?: string | null | undefined;
              photoPublicUrl?: string | null | undefined;
              firstName?: string | null | undefined;
              lastName?: string | null | undefined;
              myCommunity?: { __typename?: 'Community'; id: string } | null | undefined;
            }
          | null
          | undefined;
        myOneToOneActions?:
          | Array<{
              __typename?: 'Action';
              id: string;
              title?: string | null | undefined;
              description?: string | null | undefined;
              targetDate?: any | null | undefined;
              actionType?: ActionType | null | undefined;
              isCompleted?: boolean | null | undefined;
              completionDate?: any | null | undefined;
              isOverdue?: boolean | null | undefined;
              updatedAt?: any | null | undefined;
              createdAt?: any | null | undefined;
              createdBycompanyMemberId?: string | null | undefined;
              localCreationType?: LocalCreationActionType | null | undefined;
              responsibles?:
                | Array<{
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
              roadBlock?:
                | { __typename?: 'RoadBlock'; id: string; roadBlockId?: string | null | undefined }
                | null
                | undefined;
            }>
          | null
          | undefined;
        myOneToOneMissions?:
          | Array<{
              __typename?: 'Mission';
              id: string;
              title?: string | null | undefined;
              deadline?: any | null | undefined;
              estimateDate?: any | null | undefined;
              priorityLevel?: number | null | undefined;
              progressStatus?: MissionProgressStatus | null | undefined;
              createdAt?: any | null | undefined;
              community?:
                | {
                    __typename?: 'Community';
                    id: string;
                    logo?: string | null | undefined;
                    logoPublicUrl?: string | null | undefined;
                    name?: string | null | undefined;
                  }
                | null
                | undefined;
              experts?:
                | Array<
                    | {
                        __typename?: 'CompanyMember';
                        id: string;
                        user?:
                          | {
                              __typename?: 'User';
                              id: string;
                              fullName?: string | null | undefined;
                              firstName?: string | null | undefined;
                              lastName?: string | null | undefined;
                              photoUrl?: string | null | undefined;
                              photoPublicUrl?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined;
              companyMember?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        myCommunities?:
          | Array<{
              __typename?: 'Community';
              id: string;
              logo?: string | null | undefined;
              logoPublicUrl?: string | null | undefined;
              name?: string | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetUnfinishedActionsByCmQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUnfinishedActionsByCmQuery = {
  __typename?: 'Query';
  unfinishedActionsByCM?:
    | {
        __typename?: 'CompanyMember';
        id: string;
        myUnfinishedActions?:
          | Array<{
              __typename?: 'Action';
              id: string;
              title?: string | null | undefined;
              description?: string | null | undefined;
              targetDate?: any | null | undefined;
              actionType?: ActionType | null | undefined;
              isCompleted?: boolean | null | undefined;
              completionDate?: any | null | undefined;
              isOverdue?: boolean | null | undefined;
              updatedAt?: any | null | undefined;
              createdAt?: any | null | undefined;
              createdBycompanyMemberId?: string | null | undefined;
              localCreationType?: LocalCreationActionType | null | undefined;
            }>
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMyThanksReceivedTodayQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyThanksReceivedTodayQuery = {
  __typename?: 'Query';
  getMyThanksReceivedToday: Array<{
    __typename?: 'UserThank';
    id: string;
    score?: number | null | undefined;
    content?: string | null | undefined;
    createdAt?: any | null | undefined;
    companyMemberSender?:
      | {
          __typename?: 'CompanyMember';
          id: string;
          user?:
            | {
                __typename?: 'User';
                id: string;
                fullName?: string | null | undefined;
                firstName?: string | null | undefined;
                lastName?: string | null | undefined;
                photoUrl?: string | null | undefined;
                photoPublicUrl?: string | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetMyCurrentIdsQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyCurrentIdsQuery = {
  __typename?: 'Query';
  me?:
    | {
        __typename?: 'User';
        id: string;
        currentCompanyMember?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              myCommunities?: Array<{ __typename?: 'Community'; id: string }> | null | undefined;
            }
          | null
          | undefined;
        myCommunity?: { __typename?: 'Community'; id: string } | null | undefined;
      }
    | null
    | undefined;
};

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  __typename?: 'Query';
  me?:
    | {
        __typename?: 'User';
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        fullName?: string | null | undefined;
        photoUrl?: string | null | undefined;
        photoPublicUrl?: string | null | undefined;
        mySharedHorizon?: string | null | undefined;
        bio?: string | null | undefined;
        email?: string | null | undefined;
        birthday?: any | null | undefined;
        familySituation?: string | null | undefined;
        isOnline?: boolean | null | undefined;
        personality?: UserPersonality | null | undefined;
        adress?: string | null | undefined;
        phone?: string | null | undefined;
        skills?: Array<{ __typename?: 'Skill'; id: string; title: string }> | null | undefined;
        successes?:
          | Array<{ __typename?: 'UserSuccess'; id: string; title: string }>
          | null
          | undefined;
        passions?: Array<{ __typename?: 'Passion'; id: string; title: string }> | null | undefined;
        previousCompanies?:
          | Array<{ __typename?: 'PreviousCompany'; id: string; name: string }>
          | null
          | undefined;
        pictures?:
          | Array<{
              __typename?: 'UserPicture';
              id: string;
              title: string;
              imageUrl: string;
              imagePublicUrl?: string | null | undefined;
            }>
          | null
          | undefined;
        presentationVideo?:
          | { __typename?: 'File'; id: string; publicUrl?: string | null | undefined }
          | null
          | undefined;
        communities?:
          | Array<{
              __typename?: 'UserCommunity';
              id: string;
              community?:
                | {
                    __typename?: 'Community';
                    id: string;
                    companyId?: string | null | undefined;
                    name?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        currentCompanyMember?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              jobTitle?: string | null | undefined;
              isFirstEntry?: boolean | null | undefined;
              hiringDate?: any | null | undefined;
              userAccess?: MainRole | null | undefined;
              role?: MemberRole | null | undefined;
              businessUnitRole?: BusinessUnitRole | null | undefined;
              exp?: number | null | undefined;
              expLevel?:
                | {
                    __typename?: 'Badge';
                    id: string;
                    name?: string | null | undefined;
                    iconPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
              businessUnit?:
                | {
                    __typename?: 'BusinessUnit';
                    id: string;
                    name?: string | null | undefined;
                    logo?: string | null | undefined;
                    logoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                    email?: string | null | undefined;
                  }
                | null
                | undefined;
              currentMotto?:
                | { __typename?: 'Motto'; id: string; content: string }
                | null
                | undefined;
              nPlusOne?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              currentProfessionnalWeather?:
                | {
                    __typename?: 'ProfessionnalWeather';
                    id: string;
                    title?: string | null | undefined;
                    description?: string | null | undefined;
                    icon?: string | null | undefined;
                  }
                | null
                | undefined;
              currentProfessionalStatus?:
                | {
                    __typename?: 'ProfessionalStatus';
                    title?: string | null | undefined;
                    order?: number | null | undefined;
                    id: string;
                    icon?: string | null | undefined;
                    description?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMeWelcomeBackQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeWelcomeBackQuery = {
  __typename?: 'Query';
  me?:
    | {
        __typename?: 'User';
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        photoUrl?: string | null | undefined;
        photoPublicUrl?: string | null | undefined;
        currentCompanyMember?:
          | { __typename?: 'CompanyMember'; id: string; isFirstEntry?: boolean | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetMeNotifQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeNotifQuery = {
  __typename?: 'Query';
  me?:
    | {
        __typename?: 'User';
        id: string;
        fullName?: string | null | undefined;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetMeOneToOneQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeOneToOneQuery = {
  __typename?: 'Query';
  me?:
    | {
        __typename?: 'User';
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        fullName?: string | null | undefined;
        photoUrl?: string | null | undefined;
        photoPublicUrl?: string | null | undefined;
        currentCompanyMember?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              isManager?: boolean | null | undefined;
              currentProfessionnalWeather?:
                | { __typename?: 'ProfessionnalWeather'; icon?: string | null | undefined }
                | null
                | undefined;
              nPlusOne?: { __typename?: 'CompanyMember'; id: string } | null | undefined;
              nMinusOnes?: Array<{ __typename?: 'CompanyMember'; id: string }> | null | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetTwoFactorAuthenticationQueryVariables = Exact<{ [key: string]: never }>;

export type GetTwoFactorAuthenticationQuery = {
  __typename?: 'Query';
  me?: { __typename?: 'User'; id: string; is2FA?: boolean | null | undefined } | null | undefined;
};

export type GetPasswordQueryVariables = Exact<{ [key: string]: never }>;

export type GetPasswordQuery = { __typename?: 'Query'; hashedPassword: string };

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserByIdQuery = {
  __typename?: 'Query';
  user?:
    | {
        __typename?: 'User';
        id: string;
        firstName?: string | null | undefined;
        lastName?: string | null | undefined;
        photoUrl?: string | null | undefined;
        photoPublicUrl?: string | null | undefined;
        mySharedHorizon?: string | null | undefined;
        bio?: string | null | undefined;
        email?: string | null | undefined;
        birthday?: any | null | undefined;
        familySituation?: string | null | undefined;
        isOnline?: boolean | null | undefined;
        personality?: UserPersonality | null | undefined;
        adress?: string | null | undefined;
        phone?: string | null | undefined;
        skills?: Array<{ __typename?: 'Skill'; id: string; title: string }> | null | undefined;
        successes?:
          | Array<{ __typename?: 'UserSuccess'; id: string; title: string }>
          | null
          | undefined;
        passions?: Array<{ __typename?: 'Passion'; id: string; title: string }> | null | undefined;
        previousCompanies?:
          | Array<{ __typename?: 'PreviousCompany'; id: string; name: string }>
          | null
          | undefined;
        pictures?:
          | Array<{
              __typename?: 'UserPicture';
              id: string;
              title: string;
              imageUrl: string;
              imagePublicUrl?: string | null | undefined;
            }>
          | null
          | undefined;
        presentationVideo?:
          | { __typename?: 'File'; id: string; publicUrl?: string | null | undefined }
          | null
          | undefined;
        communities?:
          | Array<{
              __typename?: 'UserCommunity';
              id: string;
              community?:
                | {
                    __typename?: 'Community';
                    id: string;
                    companyId?: string | null | undefined;
                    name?: string | null | undefined;
                  }
                | null
                | undefined;
            }>
          | null
          | undefined;
        currentCompanyMember?:
          | {
              __typename?: 'CompanyMember';
              id: string;
              jobTitle?: string | null | undefined;
              isFirstEntry?: boolean | null | undefined;
              hiringDate?: any | null | undefined;
              userAccess?: MainRole | null | undefined;
              role?: MemberRole | null | undefined;
              businessUnitRole?: BusinessUnitRole | null | undefined;
              businessUnit?:
                | {
                    __typename?: 'BusinessUnit';
                    id: string;
                    name?: string | null | undefined;
                    logo?: string | null | undefined;
                    logoPublicUrl?: string | null | undefined;
                  }
                | null
                | undefined;
              user?:
                | {
                    __typename?: 'User';
                    id: string;
                    fullName?: string | null | undefined;
                    firstName?: string | null | undefined;
                    lastName?: string | null | undefined;
                    photoUrl?: string | null | undefined;
                    photoPublicUrl?: string | null | undefined;
                    email?: string | null | undefined;
                  }
                | null
                | undefined;
              currentMotto?:
                | { __typename?: 'Motto'; id: string; content: string }
                | null
                | undefined;
              nPlusOne?:
                | {
                    __typename?: 'CompanyMember';
                    id: string;
                    user?:
                      | {
                          __typename?: 'User';
                          id: string;
                          fullName?: string | null | undefined;
                          firstName?: string | null | undefined;
                          lastName?: string | null | undefined;
                          photoUrl?: string | null | undefined;
                          photoPublicUrl?: string | null | undefined;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
              currentProfessionnalWeather?:
                | {
                    __typename?: 'ProfessionnalWeather';
                    id: string;
                    title?: string | null | undefined;
                    description?: string | null | undefined;
                    icon?: string | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const CommentInCalendarFragmentFragmentDoc = gql`
  fragment CommentInCalendarFragment on Comment {
    id
    content
    createdAt
    isMine
    likeCount
    isLikedByMe
    companyMember {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
    }
    likedBy {
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
        }
      }
    }
  }
`;
export const CommentInPostFragmentFragmentDoc = gql`
  fragment CommentInPostFragment on Comment {
    id
    content
    createdAt
    isMine
    likeCount
    isLikedByMe
    companyMember {
      id
      user {
        id
        fullName
        lastName
        firstName
        photoUrl
        photoPublicUrl
      }
    }
    likedBy {
      user {
        id
        lastName
        firstName
        fullName
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
        }
      }
    }
  }
`;
export const CommentInActionFragmentFragmentDoc = gql`
  fragment CommentInActionFragment on Comment {
    id
    content
    createdAt
    isMine
    likeCount
    isLikedByMe
    companyMember {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
    }
    likedBy {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
        }
      }
    }
  }
`;
export const SimpleCommunityInfoFragmentDoc = gql`
  fragment SimpleCommunityInfo on Community {
    id
    name
    logo
    slug
    logoPublicUrl
  }
`;
export const CommunityFragmentDoc = gql`
  fragment Community on Community {
    id
    name
    slug
    logo
    logoPublicUrl
    mission
    rommance
  }
`;
export const CommunityEventTypeInfoFragmentDoc = gql`
  fragment CommunityEventTypeInfo on CommunityEventType {
    id
    title
    icon
    iconPublicUrl
  }
`;
export const MapCommunityInfoFragmentDoc = gql`
  fragment MapCommunityInfo on Community {
    id
    name
    mission
    memberCount
    coins
  }
`;
export const SubActionInfoFragmentDoc = gql`
  fragment SubActionInfo on Action {
    id
    description
    isCompleted
    createdAt
  }
`;
export const CommunityMembersUserInfoFragmentDoc = gql`
  fragment CommunityMembersUserInfo on User {
    id
    fullName
    firstName
    lastName
    photoUrl
    photoPublicUrl
    email
  }
`;
export const ActionInfoFragmentDoc = gql`
  fragment ActionInfo on Action {
    id
    title
    description
    targetDate
    actionType
    isCompleted
    completionDate
    isOverdue
    updatedAt
    createdAt
    createdBycompanyMemberId
    localCreationType
  }
`;
export const FileInfoFragmentDoc = gql`
  fragment FileInfo on File {
    id
    type
    size
    path
    name
    publicUrl
    extension
  }
`;
export const ProductThumbnailInfoFragmentDoc = gql`
  fragment ProductThumbnailInfo on File {
    id
    name
    path
    publicUrl
  }
`;
export const FileInLeaveFragmentFragmentDoc = gql`
  fragment FileInLeaveFragment on File {
    id
    type
    size
    path
    name
    publicUrl
    extension
  }
`;
export const IndicatorInfoFragmentDoc = gql`
  fragment IndicatorInfo on Indicator {
    id
    name
    date
    description
    value
    action
    trend
    level
    createdAt
  }
`;
export const MapIndicatorInfoFragmentDoc = gql`
  fragment MapIndicatorInfo on Indicator {
    id
    name
    action
    trend
    level
    value
  }
`;
export const IndicatorHistoryInfoFragmentDoc = gql`
  fragment IndicatorHistoryInfo on IndicatorHistory {
    id
    nameFrom
    nameTo
    dateFrom
    dateTo
    descriptionFrom
    descriptionTo
    valueFrom
    valueTo
    actionFrom
    actionTo
    trendFrom
    trendTo
    levelFrom
    levelTo
    createdAt
  }
`;
export const MeetingLocalInfoFragmentDoc = gql`
  fragment MeetingLocalInfo on Meeting {
    id
    topic
    dueDate
    details
    createdAt
    passcode
    invitationLink
    timezone
    status
    isMine
    duration
    isPublic
    isPast
  }
`;
export const MeetingInfoFragmentDoc = gql`
  fragment MeetingInfo on ZoomMeetingByRegistrantFullResponse {
    id
    agenda
    created_at
    duration
    host_id
    join_url
    start_time
    timezone
    topic
    status
    registration_url
    uuid
    contact_name
    contact_email
  }
`;
export const RegistrantInfoFragmentDoc = gql`
  fragment RegistrantInfo on AddZoomMeetingRegistrantResponse {
    id
    email
    first_name
    last_name
    join_url
    registrant_id
    create_time
    topic
    status
    address
    zip
  }
`;
export const NotificationInfoFragmentDoc = gql`
  fragment NotificationInfo on Notification {
    id
    title
    description
    date
    linkTo
    isSeen
    seenDate
    createdAt
    isValid
    parametres
  }
`;
export const RoadBlockFragmentDoc = gql`
  fragment RoadBlock on RoadBlock {
    id
    roadBlockId
    description
    createdAt
  }
`;
export const MissionInfoFragmentDoc = gql`
  fragment MissionInfo on Mission {
    id
    title
    deadline
    estimateDate
    priorityLevel
    progressStatus
    createdAt
  }
`;
export const FeedbackInfoFragmentDoc = gql`
  fragment FeedbackInfo on Feedback {
    id
    content
    response
    alreadySeen
    createdAt
    companyMemberSenderId
  }
`;
export const NeedInfoFragmentDoc = gql`
  fragment NeedInfo on Need {
    id
    title
    description
  }
`;
export const PostInfoFragmentDoc = gql`
  fragment PostInfo on Post {
    id
    commentCount
    content
    contentImageUrls
    createdAt
    demand
    demandImproveStatus
    demandSolveStatus
    draft
    how
    impact
    isAlreadyUseA3Process
    isAuthorHidden
    isCMValidator
    isHidden
    isIssueSolved
    isLikedByMe
    isMine
    isRatingEnded
    isSeenByMe
    likeCount
    postedAnonymously
    postType
    problemLevel
    problemTo
    processStatus
    ratingPeriod
    removed
    reopened
    shareCount
    status
    title
    updatedAt
    what
    when
    where
    who
    issuePrivacy
  }
`;
export const RatingSummaryFragmentDoc = gql`
  fragment RatingSummary on Post {
    ratingSummary {
      two
      one
      three
      four
      five
      totalRatingCount
      globalRating
    }
  }
`;
export const ProductInfoFragmentDoc = gql`
  fragment ProductInfo on Product {
    id
    title
    price
  }
`;
export const UserInProjectFragmentFragmentDoc = gql`
  fragment UserInProjectFragment on User {
    id
    firstName
    lastName
    fullName
    photoUrl
    photoPublicUrl
  }
`;
export const ProjectInA3FragmentFragmentDoc = gql`
  fragment ProjectInA3Fragment on Project {
    id
    title
    description
    isCompleted
    targetDate
    status
    priorityLevel
    finalized
    companyMemberResponsible {
      id
      user {
        ...UserInProjectFragment
      }
    }
    convertedFrom {
      id
      postType
    }
  }
  ${UserInProjectFragmentFragmentDoc}
`;
export const UserThankInfoFragmentDoc = gql`
  fragment UserThankInfo on UserThank {
    id
    content
    response
    alreadySeen
    score
    createdAt
    companyMemberSenderId
  }
`;
export const CommunityBadgeFragmentFragmentDoc = gql`
  fragment CommunityBadgeFragment on CommunityBadge {
    badgeId
    communityId
    status
    done
  }
`;
export const IndividualBadgeFragmentFragmentDoc = gql`
  fragment IndividualBadgeFragment on IndividualBadge {
    badgeId
    companyMemberId
    status
  }
`;
export const BadgeFragmentFragmentDoc = gql`
  fragment BadgeFragment on Badge {
    id
    name
    description
    icon
    iconPublicUrl
    type
    category
    points
  }
`;
export const UserOnPostInfoFragmentDoc = gql`
  fragment UserOnPostInfo on User {
    id
    firstName
    fullName
    lastName
    email
    photoUrl
    photoPublicUrl
  }
`;
export const NplusAndMinusOneInfoFragmentDoc = gql`
  fragment NplusAndMinusOneInfo on User {
    id
    firstName
    lastName
    fullName
    isOnline
    photoUrl
    photoPublicUrl
  }
`;
export const UserInfoFragmentDoc = gql`
  fragment UserInfo on User {
    id
    firstName
    lastName
    fullName
    isItMyDay
    email
    bio
    isOnline
    photoPublicUrl
    photoUrl
    birthday
    familySituation
    personality
    mySharedHorizon
    role
    is2FA
    suspended
    removed
    isOnline
  }
`;
export const UserWithPhotoInLeaveFragmentFragmentDoc = gql`
  fragment UserWithPhotoInLeaveFragment on User {
    id
    firstName
    lastName
    fullName
    photoPublicUrl
    photoUrl
  }
`;
export const UserInLeaveFragmentFragmentDoc = gql`
  fragment UserInLeaveFragment on User {
    id
    firstName
    lastName
    fullName
    email
  }
`;
export const UserMeInfoFragmentDoc = gql`
  fragment UserMeInfo on User {
    id
    fullName
    firstName
    lastName
    photoUrl
    photoPublicUrl
    mySharedHorizon
    bio
    email
    birthday
    familySituation
    isOnline
    personality
    skills {
      id
      title
    }
    successes {
      id
      title
    }
    passions {
      id
      title
    }
    previousCompanies {
      id
      name
    }
    pictures {
      id
      title
      imageUrl
      imagePublicUrl
    }
    presentationVideo {
      id
      publicUrl
    }
    communities {
      id
      community {
        id
        companyId
        name
      }
    }
    currentCompanyMember {
      id
      jobTitle
      isFirstEntry
      hiringDate
      userAccess
      role
      businessUnitRole
      businessUnit {
        id
        name
        logo
        logoPublicUrl
      }
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        email
      }
      currentMotto {
        id
        content
      }
      nPlusOne {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      currentProfessionnalWeather {
        id
        title
        description
        icon
      }
    }
  }
`;
export const DeviceInfoManagementDocument = gql`
  mutation DeviceInfoManagement($info: DeviceInfoInput!) {
    deviceInfoManagement(info: $info) {
      id
      deviceId
      token
      owner {
        id
      }
    }
  }
`;
export type DeviceInfoManagementMutationFn = Apollo.MutationFunction<
  DeviceInfoManagementMutation,
  DeviceInfoManagementMutationVariables
>;

/**
 * __useDeviceInfoManagementMutation__
 *
 * To run a mutation, you first call `useDeviceInfoManagementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeviceInfoManagementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deviceInfoManagementMutation, { data, loading, error }] = useDeviceInfoManagementMutation({
 *   variables: {
 *      info: // value for 'info'
 *   },
 * });
 */
export function useDeviceInfoManagementMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeviceInfoManagementMutation,
    DeviceInfoManagementMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeviceInfoManagementMutation, DeviceInfoManagementMutationVariables>(
    DeviceInfoManagementDocument,
    options,
  );
}
export type DeviceInfoManagementMutationHookResult = ReturnType<
  typeof useDeviceInfoManagementMutation
>;
export type DeviceInfoManagementMutationResult =
  Apollo.MutationResult<DeviceInfoManagementMutation>;
export type DeviceInfoManagementMutationOptions = Apollo.BaseMutationOptions<
  DeviceInfoManagementMutation,
  DeviceInfoManagementMutationVariables
>;
export const CreateFollowUpDocument = gql`
  mutation CreateFollowUp($description: String!, $postId: ID!) {
    createFollowUp(description: $description, postId: $postId) {
      id
      description
    }
  }
`;
export type CreateFollowUpMutationFn = Apollo.MutationFunction<
  CreateFollowUpMutation,
  CreateFollowUpMutationVariables
>;

/**
 * __useCreateFollowUpMutation__
 *
 * To run a mutation, you first call `useCreateFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFollowUpMutation, { data, loading, error }] = useCreateFollowUpMutation({
 *   variables: {
 *      description: // value for 'description'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreateFollowUpMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFollowUpMutation, CreateFollowUpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFollowUpMutation, CreateFollowUpMutationVariables>(
    CreateFollowUpDocument,
    options,
  );
}
export type CreateFollowUpMutationHookResult = ReturnType<typeof useCreateFollowUpMutation>;
export type CreateFollowUpMutationResult = Apollo.MutationResult<CreateFollowUpMutation>;
export type CreateFollowUpMutationOptions = Apollo.BaseMutationOptions<
  CreateFollowUpMutation,
  CreateFollowUpMutationVariables
>;
export const UpdateFollowUpDocument = gql`
  mutation UpdateFollowUp($id: ID!, $description: String!) {
    updateFollowUp(id: $id, description: $description) {
      id
      description
    }
  }
`;
export type UpdateFollowUpMutationFn = Apollo.MutationFunction<
  UpdateFollowUpMutation,
  UpdateFollowUpMutationVariables
>;

/**
 * __useUpdateFollowUpMutation__
 *
 * To run a mutation, you first call `useUpdateFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFollowUpMutation, { data, loading, error }] = useUpdateFollowUpMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateFollowUpMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateFollowUpMutation, UpdateFollowUpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateFollowUpMutation, UpdateFollowUpMutationVariables>(
    UpdateFollowUpDocument,
    options,
  );
}
export type UpdateFollowUpMutationHookResult = ReturnType<typeof useUpdateFollowUpMutation>;
export type UpdateFollowUpMutationResult = Apollo.MutationResult<UpdateFollowUpMutation>;
export type UpdateFollowUpMutationOptions = Apollo.BaseMutationOptions<
  UpdateFollowUpMutation,
  UpdateFollowUpMutationVariables
>;
export const DeleteFollowUpDocument = gql`
  mutation DeleteFollowUp($id: ID!) {
    deleteFollowUp(id: $id) {
      id
    }
  }
`;
export type DeleteFollowUpMutationFn = Apollo.MutationFunction<
  DeleteFollowUpMutation,
  DeleteFollowUpMutationVariables
>;

/**
 * __useDeleteFollowUpMutation__
 *
 * To run a mutation, you first call `useDeleteFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFollowUpMutation, { data, loading, error }] = useDeleteFollowUpMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFollowUpMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFollowUpMutation, DeleteFollowUpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFollowUpMutation, DeleteFollowUpMutationVariables>(
    DeleteFollowUpDocument,
    options,
  );
}
export type DeleteFollowUpMutationHookResult = ReturnType<typeof useDeleteFollowUpMutation>;
export type DeleteFollowUpMutationResult = Apollo.MutationResult<DeleteFollowUpMutation>;
export type DeleteFollowUpMutationOptions = Apollo.BaseMutationOptions<
  DeleteFollowUpMutation,
  DeleteFollowUpMutationVariables
>;
export const CreateObjectiveDocument = gql`
  mutation CreateObjective($description: String!, $postId: ID!) {
    createObjective(description: $description, postId: $postId) {
      id
      description
    }
  }
`;
export type CreateObjectiveMutationFn = Apollo.MutationFunction<
  CreateObjectiveMutation,
  CreateObjectiveMutationVariables
>;

/**
 * __useCreateObjectiveMutation__
 *
 * To run a mutation, you first call `useCreateObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createObjectiveMutation, { data, loading, error }] = useCreateObjectiveMutation({
 *   variables: {
 *      description: // value for 'description'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreateObjectiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateObjectiveMutation,
    CreateObjectiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateObjectiveMutation, CreateObjectiveMutationVariables>(
    CreateObjectiveDocument,
    options,
  );
}
export type CreateObjectiveMutationHookResult = ReturnType<typeof useCreateObjectiveMutation>;
export type CreateObjectiveMutationResult = Apollo.MutationResult<CreateObjectiveMutation>;
export type CreateObjectiveMutationOptions = Apollo.BaseMutationOptions<
  CreateObjectiveMutation,
  CreateObjectiveMutationVariables
>;
export const UpdateObjectiveDocument = gql`
  mutation UpdateObjective($id: ID!, $description: String) {
    updateObjective(id: $id, description: $description) {
      id
      description
    }
  }
`;
export type UpdateObjectiveMutationFn = Apollo.MutationFunction<
  UpdateObjectiveMutation,
  UpdateObjectiveMutationVariables
>;

/**
 * __useUpdateObjectiveMutation__
 *
 * To run a mutation, you first call `useUpdateObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateObjectiveMutation, { data, loading, error }] = useUpdateObjectiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateObjectiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateObjectiveMutation,
    UpdateObjectiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateObjectiveMutation, UpdateObjectiveMutationVariables>(
    UpdateObjectiveDocument,
    options,
  );
}
export type UpdateObjectiveMutationHookResult = ReturnType<typeof useUpdateObjectiveMutation>;
export type UpdateObjectiveMutationResult = Apollo.MutationResult<UpdateObjectiveMutation>;
export type UpdateObjectiveMutationOptions = Apollo.BaseMutationOptions<
  UpdateObjectiveMutation,
  UpdateObjectiveMutationVariables
>;
export const DeleteObjectiveDocument = gql`
  mutation DeleteObjective($id: ID!) {
    deleteObjective(id: $id) {
      id
    }
  }
`;
export type DeleteObjectiveMutationFn = Apollo.MutationFunction<
  DeleteObjectiveMutation,
  DeleteObjectiveMutationVariables
>;

/**
 * __useDeleteObjectiveMutation__
 *
 * To run a mutation, you first call `useDeleteObjectiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteObjectiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteObjectiveMutation, { data, loading, error }] = useDeleteObjectiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteObjectiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteObjectiveMutation,
    DeleteObjectiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteObjectiveMutation, DeleteObjectiveMutationVariables>(
    DeleteObjectiveDocument,
    options,
  );
}
export type DeleteObjectiveMutationHookResult = ReturnType<typeof useDeleteObjectiveMutation>;
export type DeleteObjectiveMutationResult = Apollo.MutationResult<DeleteObjectiveMutation>;
export type DeleteObjectiveMutationOptions = Apollo.BaseMutationOptions<
  DeleteObjectiveMutation,
  DeleteObjectiveMutationVariables
>;
export const CreateAnalysisRootCauseDocument = gql`
  mutation CreateAnalysisRootCause($description: String!, $postId: ID!) {
    createAnalysisRootCause(description: $description, postId: $postId) {
      id
      description
    }
  }
`;
export type CreateAnalysisRootCauseMutationFn = Apollo.MutationFunction<
  CreateAnalysisRootCauseMutation,
  CreateAnalysisRootCauseMutationVariables
>;

/**
 * __useCreateAnalysisRootCauseMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisRootCauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisRootCauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisRootCauseMutation, { data, loading, error }] = useCreateAnalysisRootCauseMutation({
 *   variables: {
 *      description: // value for 'description'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreateAnalysisRootCauseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalysisRootCauseMutation,
    CreateAnalysisRootCauseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAnalysisRootCauseMutation,
    CreateAnalysisRootCauseMutationVariables
  >(CreateAnalysisRootCauseDocument, options);
}
export type CreateAnalysisRootCauseMutationHookResult = ReturnType<
  typeof useCreateAnalysisRootCauseMutation
>;
export type CreateAnalysisRootCauseMutationResult =
  Apollo.MutationResult<CreateAnalysisRootCauseMutation>;
export type CreateAnalysisRootCauseMutationOptions = Apollo.BaseMutationOptions<
  CreateAnalysisRootCauseMutation,
  CreateAnalysisRootCauseMutationVariables
>;
export const UpdateAnalysisRootCauseDocument = gql`
  mutation UpdateAnalysisRootCause($id: ID!, $description: String!) {
    updateAnalysisRootCause(id: $id, description: $description) {
      id
      description
    }
  }
`;
export type UpdateAnalysisRootCauseMutationFn = Apollo.MutationFunction<
  UpdateAnalysisRootCauseMutation,
  UpdateAnalysisRootCauseMutationVariables
>;

/**
 * __useUpdateAnalysisRootCauseMutation__
 *
 * To run a mutation, you first call `useUpdateAnalysisRootCauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalysisRootCauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalysisRootCauseMutation, { data, loading, error }] = useUpdateAnalysisRootCauseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateAnalysisRootCauseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnalysisRootCauseMutation,
    UpdateAnalysisRootCauseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAnalysisRootCauseMutation,
    UpdateAnalysisRootCauseMutationVariables
  >(UpdateAnalysisRootCauseDocument, options);
}
export type UpdateAnalysisRootCauseMutationHookResult = ReturnType<
  typeof useUpdateAnalysisRootCauseMutation
>;
export type UpdateAnalysisRootCauseMutationResult =
  Apollo.MutationResult<UpdateAnalysisRootCauseMutation>;
export type UpdateAnalysisRootCauseMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnalysisRootCauseMutation,
  UpdateAnalysisRootCauseMutationVariables
>;
export const DeleteAnalysisRootCauseDocument = gql`
  mutation DeleteAnalysisRootCause($id: ID!) {
    deleteAnalysisRootCause(id: $id) {
      id
    }
  }
`;
export type DeleteAnalysisRootCauseMutationFn = Apollo.MutationFunction<
  DeleteAnalysisRootCauseMutation,
  DeleteAnalysisRootCauseMutationVariables
>;

/**
 * __useDeleteAnalysisRootCauseMutation__
 *
 * To run a mutation, you first call `useDeleteAnalysisRootCauseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnalysisRootCauseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnalysisRootCauseMutation, { data, loading, error }] = useDeleteAnalysisRootCauseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnalysisRootCauseMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAnalysisRootCauseMutation,
    DeleteAnalysisRootCauseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteAnalysisRootCauseMutation,
    DeleteAnalysisRootCauseMutationVariables
  >(DeleteAnalysisRootCauseDocument, options);
}
export type DeleteAnalysisRootCauseMutationHookResult = ReturnType<
  typeof useDeleteAnalysisRootCauseMutation
>;
export type DeleteAnalysisRootCauseMutationResult =
  Apollo.MutationResult<DeleteAnalysisRootCauseMutation>;
export type DeleteAnalysisRootCauseMutationOptions = Apollo.BaseMutationOptions<
  DeleteAnalysisRootCauseMutation,
  DeleteAnalysisRootCauseMutationVariables
>;
export const CreateAnalysisRootCauseCommentDocument = gql`
  mutation CreateAnalysisRootCauseComment($content: String!, $rootCauseId: ID!) {
    createRootCauseComment(content: $content, rootCauseId: $rootCauseId) {
      id
    }
  }
`;
export type CreateAnalysisRootCauseCommentMutationFn = Apollo.MutationFunction<
  CreateAnalysisRootCauseCommentMutation,
  CreateAnalysisRootCauseCommentMutationVariables
>;

/**
 * __useCreateAnalysisRootCauseCommentMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisRootCauseCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisRootCauseCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisRootCauseCommentMutation, { data, loading, error }] = useCreateAnalysisRootCauseCommentMutation({
 *   variables: {
 *      content: // value for 'content'
 *      rootCauseId: // value for 'rootCauseId'
 *   },
 * });
 */
export function useCreateAnalysisRootCauseCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalysisRootCauseCommentMutation,
    CreateAnalysisRootCauseCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAnalysisRootCauseCommentMutation,
    CreateAnalysisRootCauseCommentMutationVariables
  >(CreateAnalysisRootCauseCommentDocument, options);
}
export type CreateAnalysisRootCauseCommentMutationHookResult = ReturnType<
  typeof useCreateAnalysisRootCauseCommentMutation
>;
export type CreateAnalysisRootCauseCommentMutationResult =
  Apollo.MutationResult<CreateAnalysisRootCauseCommentMutation>;
export type CreateAnalysisRootCauseCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateAnalysisRootCauseCommentMutation,
  CreateAnalysisRootCauseCommentMutationVariables
>;
export const CreateAnalysisWhyDocument = gql`
  mutation CreateAnalysisWhy($description: String!, $postId: ID!) {
    createAnalysisWhy(description: $description, postId: $postId) {
      id
      description
    }
  }
`;
export type CreateAnalysisWhyMutationFn = Apollo.MutationFunction<
  CreateAnalysisWhyMutation,
  CreateAnalysisWhyMutationVariables
>;

/**
 * __useCreateAnalysisWhyMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisWhyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisWhyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisWhyMutation, { data, loading, error }] = useCreateAnalysisWhyMutation({
 *   variables: {
 *      description: // value for 'description'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreateAnalysisWhyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalysisWhyMutation,
    CreateAnalysisWhyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAnalysisWhyMutation, CreateAnalysisWhyMutationVariables>(
    CreateAnalysisWhyDocument,
    options,
  );
}
export type CreateAnalysisWhyMutationHookResult = ReturnType<typeof useCreateAnalysisWhyMutation>;
export type CreateAnalysisWhyMutationResult = Apollo.MutationResult<CreateAnalysisWhyMutation>;
export type CreateAnalysisWhyMutationOptions = Apollo.BaseMutationOptions<
  CreateAnalysisWhyMutation,
  CreateAnalysisWhyMutationVariables
>;
export const UpdateAnalysisWhyDocument = gql`
  mutation UpdateAnalysisWhy($id: ID!, $description: String!) {
    updateAnalysisWhy(id: $id, description: $description) {
      id
      description
    }
  }
`;
export type UpdateAnalysisWhyMutationFn = Apollo.MutationFunction<
  UpdateAnalysisWhyMutation,
  UpdateAnalysisWhyMutationVariables
>;

/**
 * __useUpdateAnalysisWhyMutation__
 *
 * To run a mutation, you first call `useUpdateAnalysisWhyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalysisWhyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalysisWhyMutation, { data, loading, error }] = useUpdateAnalysisWhyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateAnalysisWhyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnalysisWhyMutation,
    UpdateAnalysisWhyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAnalysisWhyMutation, UpdateAnalysisWhyMutationVariables>(
    UpdateAnalysisWhyDocument,
    options,
  );
}
export type UpdateAnalysisWhyMutationHookResult = ReturnType<typeof useUpdateAnalysisWhyMutation>;
export type UpdateAnalysisWhyMutationResult = Apollo.MutationResult<UpdateAnalysisWhyMutation>;
export type UpdateAnalysisWhyMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnalysisWhyMutation,
  UpdateAnalysisWhyMutationVariables
>;
export const DeleteAnalysisWhyDocument = gql`
  mutation DeleteAnalysisWhy($id: ID!) {
    deleteAnalysisWhy(id: $id) {
      id
    }
  }
`;
export type DeleteAnalysisWhyMutationFn = Apollo.MutationFunction<
  DeleteAnalysisWhyMutation,
  DeleteAnalysisWhyMutationVariables
>;

/**
 * __useDeleteAnalysisWhyMutation__
 *
 * To run a mutation, you first call `useDeleteAnalysisWhyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnalysisWhyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnalysisWhyMutation, { data, loading, error }] = useDeleteAnalysisWhyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnalysisWhyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAnalysisWhyMutation,
    DeleteAnalysisWhyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAnalysisWhyMutation, DeleteAnalysisWhyMutationVariables>(
    DeleteAnalysisWhyDocument,
    options,
  );
}
export type DeleteAnalysisWhyMutationHookResult = ReturnType<typeof useDeleteAnalysisWhyMutation>;
export type DeleteAnalysisWhyMutationResult = Apollo.MutationResult<DeleteAnalysisWhyMutation>;
export type DeleteAnalysisWhyMutationOptions = Apollo.BaseMutationOptions<
  DeleteAnalysisWhyMutation,
  DeleteAnalysisWhyMutationVariables
>;
export const CreateAnalysis5WhyDocument = gql`
  mutation CreateAnalysis5Why($analysisWhyId: ID!, $content: String!, $rank: Int) {
    createAnalysis5Why(analysisWhyId: $analysisWhyId, content: $content, rank: $rank) {
      id
    }
  }
`;
export type CreateAnalysis5WhyMutationFn = Apollo.MutationFunction<
  CreateAnalysis5WhyMutation,
  CreateAnalysis5WhyMutationVariables
>;

/**
 * __useCreateAnalysis5WhyMutation__
 *
 * To run a mutation, you first call `useCreateAnalysis5WhyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysis5WhyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysis5WhyMutation, { data, loading, error }] = useCreateAnalysis5WhyMutation({
 *   variables: {
 *      analysisWhyId: // value for 'analysisWhyId'
 *      content: // value for 'content'
 *      rank: // value for 'rank'
 *   },
 * });
 */
export function useCreateAnalysis5WhyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalysis5WhyMutation,
    CreateAnalysis5WhyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAnalysis5WhyMutation, CreateAnalysis5WhyMutationVariables>(
    CreateAnalysis5WhyDocument,
    options,
  );
}
export type CreateAnalysis5WhyMutationHookResult = ReturnType<typeof useCreateAnalysis5WhyMutation>;
export type CreateAnalysis5WhyMutationResult = Apollo.MutationResult<CreateAnalysis5WhyMutation>;
export type CreateAnalysis5WhyMutationOptions = Apollo.BaseMutationOptions<
  CreateAnalysis5WhyMutation,
  CreateAnalysis5WhyMutationVariables
>;
export const CreateAnalysisCompareDocument = gql`
  mutation CreateAnalysisCompare($subject1: String!, $subject2: String!, $postId: ID!) {
    createAnalysisCompare(subject1: $subject1, subject2: $subject2, postId: $postId) {
      id
    }
  }
`;
export type CreateAnalysisCompareMutationFn = Apollo.MutationFunction<
  CreateAnalysisCompareMutation,
  CreateAnalysisCompareMutationVariables
>;

/**
 * __useCreateAnalysisCompareMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisCompareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisCompareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisCompareMutation, { data, loading, error }] = useCreateAnalysisCompareMutation({
 *   variables: {
 *      subject1: // value for 'subject1'
 *      subject2: // value for 'subject2'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreateAnalysisCompareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalysisCompareMutation,
    CreateAnalysisCompareMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAnalysisCompareMutation, CreateAnalysisCompareMutationVariables>(
    CreateAnalysisCompareDocument,
    options,
  );
}
export type CreateAnalysisCompareMutationHookResult = ReturnType<
  typeof useCreateAnalysisCompareMutation
>;
export type CreateAnalysisCompareMutationResult =
  Apollo.MutationResult<CreateAnalysisCompareMutation>;
export type CreateAnalysisCompareMutationOptions = Apollo.BaseMutationOptions<
  CreateAnalysisCompareMutation,
  CreateAnalysisCompareMutationVariables
>;
export const UpdateAnalysisCompareDocument = gql`
  mutation UpdateAnalysisCompare($id: ID!, $subject1: String, $subject2: String) {
    updateAnalysisCompare(id: $id, subject1: $subject1, subject2: $subject2) {
      id
    }
  }
`;
export type UpdateAnalysisCompareMutationFn = Apollo.MutationFunction<
  UpdateAnalysisCompareMutation,
  UpdateAnalysisCompareMutationVariables
>;

/**
 * __useUpdateAnalysisCompareMutation__
 *
 * To run a mutation, you first call `useUpdateAnalysisCompareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnalysisCompareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnalysisCompareMutation, { data, loading, error }] = useUpdateAnalysisCompareMutation({
 *   variables: {
 *      id: // value for 'id'
 *      subject1: // value for 'subject1'
 *      subject2: // value for 'subject2'
 *   },
 * });
 */
export function useUpdateAnalysisCompareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAnalysisCompareMutation,
    UpdateAnalysisCompareMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAnalysisCompareMutation, UpdateAnalysisCompareMutationVariables>(
    UpdateAnalysisCompareDocument,
    options,
  );
}
export type UpdateAnalysisCompareMutationHookResult = ReturnType<
  typeof useUpdateAnalysisCompareMutation
>;
export type UpdateAnalysisCompareMutationResult =
  Apollo.MutationResult<UpdateAnalysisCompareMutation>;
export type UpdateAnalysisCompareMutationOptions = Apollo.BaseMutationOptions<
  UpdateAnalysisCompareMutation,
  UpdateAnalysisCompareMutationVariables
>;
export const DeleteAnalysisCompareDocument = gql`
  mutation DeleteAnalysisCompare($id: ID!) {
    deleteAnalysisCompare(id: $id) {
      id
    }
  }
`;
export type DeleteAnalysisCompareMutationFn = Apollo.MutationFunction<
  DeleteAnalysisCompareMutation,
  DeleteAnalysisCompareMutationVariables
>;

/**
 * __useDeleteAnalysisCompareMutation__
 *
 * To run a mutation, you first call `useDeleteAnalysisCompareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnalysisCompareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnalysisCompareMutation, { data, loading, error }] = useDeleteAnalysisCompareMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnalysisCompareMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAnalysisCompareMutation,
    DeleteAnalysisCompareMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAnalysisCompareMutation, DeleteAnalysisCompareMutationVariables>(
    DeleteAnalysisCompareDocument,
    options,
  );
}
export type DeleteAnalysisCompareMutationHookResult = ReturnType<
  typeof useDeleteAnalysisCompareMutation
>;
export type DeleteAnalysisCompareMutationResult =
  Apollo.MutationResult<DeleteAnalysisCompareMutation>;
export type DeleteAnalysisCompareMutationOptions = Apollo.BaseMutationOptions<
  DeleteAnalysisCompareMutation,
  DeleteAnalysisCompareMutationVariables
>;
export const CreateAnalysisCompareCommentDocument = gql`
  mutation CreateAnalysisCompareComment($content: String!, $compareId: ID!) {
    createCompareComment(content: $content, compareId: $compareId) {
      id
    }
  }
`;
export type CreateAnalysisCompareCommentMutationFn = Apollo.MutationFunction<
  CreateAnalysisCompareCommentMutation,
  CreateAnalysisCompareCommentMutationVariables
>;

/**
 * __useCreateAnalysisCompareCommentMutation__
 *
 * To run a mutation, you first call `useCreateAnalysisCompareCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnalysisCompareCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnalysisCompareCommentMutation, { data, loading, error }] = useCreateAnalysisCompareCommentMutation({
 *   variables: {
 *      content: // value for 'content'
 *      compareId: // value for 'compareId'
 *   },
 * });
 */
export function useCreateAnalysisCompareCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAnalysisCompareCommentMutation,
    CreateAnalysisCompareCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAnalysisCompareCommentMutation,
    CreateAnalysisCompareCommentMutationVariables
  >(CreateAnalysisCompareCommentDocument, options);
}
export type CreateAnalysisCompareCommentMutationHookResult = ReturnType<
  typeof useCreateAnalysisCompareCommentMutation
>;
export type CreateAnalysisCompareCommentMutationResult =
  Apollo.MutationResult<CreateAnalysisCompareCommentMutation>;
export type CreateAnalysisCompareCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateAnalysisCompareCommentMutation,
  CreateAnalysisCompareCommentMutationVariables
>;
export const CreateCounterMeasureDocument = gql`
  mutation CreateCounterMeasure(
    $title: String!
    $description: String
    $fileName: String
    $filePath: String
    $fileSize: Int
    $fileType: String
    $postId: ID!
  ) {
    createCounterMeasure(
      title: $title
      description: $description
      fileName: $fileName
      filePath: $filePath
      fileSize: $fileSize
      fileType: $fileType
      postId: $postId
    ) {
      id
      createdAt
      title
      description
    }
  }
`;
export type CreateCounterMeasureMutationFn = Apollo.MutationFunction<
  CreateCounterMeasureMutation,
  CreateCounterMeasureMutationVariables
>;

/**
 * __useCreateCounterMeasureMutation__
 *
 * To run a mutation, you first call `useCreateCounterMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCounterMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCounterMeasureMutation, { data, loading, error }] = useCreateCounterMeasureMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      fileName: // value for 'fileName'
 *      filePath: // value for 'filePath'
 *      fileSize: // value for 'fileSize'
 *      fileType: // value for 'fileType'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreateCounterMeasureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCounterMeasureMutation,
    CreateCounterMeasureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCounterMeasureMutation, CreateCounterMeasureMutationVariables>(
    CreateCounterMeasureDocument,
    options,
  );
}
export type CreateCounterMeasureMutationHookResult = ReturnType<
  typeof useCreateCounterMeasureMutation
>;
export type CreateCounterMeasureMutationResult =
  Apollo.MutationResult<CreateCounterMeasureMutation>;
export type CreateCounterMeasureMutationOptions = Apollo.BaseMutationOptions<
  CreateCounterMeasureMutation,
  CreateCounterMeasureMutationVariables
>;
export const UpdateCounterMeasureDocument = gql`
  mutation UpdateCounterMeasure(
    $id: ID!
    $title: String!
    $description: String
    $fileName: String
    $filePath: String
    $fileSize: Int
    $fileType: String
    $fileIdToRemove: String
  ) {
    updateCounterMeasure(
      id: $id
      title: $title
      description: $description
      fileName: $fileName
      filePath: $filePath
      fileSize: $fileSize
      fileType: $fileType
      fileIdToRemove: $fileIdToRemove
    ) {
      id
      updatedAt
      title
      description
    }
  }
`;
export type UpdateCounterMeasureMutationFn = Apollo.MutationFunction<
  UpdateCounterMeasureMutation,
  UpdateCounterMeasureMutationVariables
>;

/**
 * __useUpdateCounterMeasureMutation__
 *
 * To run a mutation, you first call `useUpdateCounterMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCounterMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCounterMeasureMutation, { data, loading, error }] = useUpdateCounterMeasureMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      fileName: // value for 'fileName'
 *      filePath: // value for 'filePath'
 *      fileSize: // value for 'fileSize'
 *      fileType: // value for 'fileType'
 *      fileIdToRemove: // value for 'fileIdToRemove'
 *   },
 * });
 */
export function useUpdateCounterMeasureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCounterMeasureMutation,
    UpdateCounterMeasureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCounterMeasureMutation, UpdateCounterMeasureMutationVariables>(
    UpdateCounterMeasureDocument,
    options,
  );
}
export type UpdateCounterMeasureMutationHookResult = ReturnType<
  typeof useUpdateCounterMeasureMutation
>;
export type UpdateCounterMeasureMutationResult =
  Apollo.MutationResult<UpdateCounterMeasureMutation>;
export type UpdateCounterMeasureMutationOptions = Apollo.BaseMutationOptions<
  UpdateCounterMeasureMutation,
  UpdateCounterMeasureMutationVariables
>;
export const DeleteCounterMeasureDocument = gql`
  mutation DeleteCounterMeasure($id: ID!) {
    deleteCounterMeasure(id: $id) {
      id
    }
  }
`;
export type DeleteCounterMeasureMutationFn = Apollo.MutationFunction<
  DeleteCounterMeasureMutation,
  DeleteCounterMeasureMutationVariables
>;

/**
 * __useDeleteCounterMeasureMutation__
 *
 * To run a mutation, you first call `useDeleteCounterMeasureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCounterMeasureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCounterMeasureMutation, { data, loading, error }] = useDeleteCounterMeasureMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCounterMeasureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCounterMeasureMutation,
    DeleteCounterMeasureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCounterMeasureMutation, DeleteCounterMeasureMutationVariables>(
    DeleteCounterMeasureDocument,
    options,
  );
}
export type DeleteCounterMeasureMutationHookResult = ReturnType<
  typeof useDeleteCounterMeasureMutation
>;
export type DeleteCounterMeasureMutationResult =
  Apollo.MutationResult<DeleteCounterMeasureMutation>;
export type DeleteCounterMeasureMutationOptions = Apollo.BaseMutationOptions<
  DeleteCounterMeasureMutation,
  DeleteCounterMeasureMutationVariables
>;
export const DeleteCounterMeasureFileDocument = gql`
  mutation DeleteCounterMeasureFile($id: ID!) {
    deleteCounterMeasureFile(id: $id) {
      id
    }
  }
`;
export type DeleteCounterMeasureFileMutationFn = Apollo.MutationFunction<
  DeleteCounterMeasureFileMutation,
  DeleteCounterMeasureFileMutationVariables
>;

/**
 * __useDeleteCounterMeasureFileMutation__
 *
 * To run a mutation, you first call `useDeleteCounterMeasureFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCounterMeasureFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCounterMeasureFileMutation, { data, loading, error }] = useDeleteCounterMeasureFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCounterMeasureFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCounterMeasureFileMutation,
    DeleteCounterMeasureFileMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCounterMeasureFileMutation,
    DeleteCounterMeasureFileMutationVariables
  >(DeleteCounterMeasureFileDocument, options);
}
export type DeleteCounterMeasureFileMutationHookResult = ReturnType<
  typeof useDeleteCounterMeasureFileMutation
>;
export type DeleteCounterMeasureFileMutationResult =
  Apollo.MutationResult<DeleteCounterMeasureFileMutation>;
export type DeleteCounterMeasureFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteCounterMeasureFileMutation,
  DeleteCounterMeasureFileMutationVariables
>;
export const GetFollowUpsByPostDocument = gql`
  query GetFollowUpsByPost($postId: ID!) {
    allFollowUpsByPost(postId: $postId) {
      id
      description
    }
    currentWholeCompany {
      id
      name
    }
  }
`;

/**
 * __useGetFollowUpsByPostQuery__
 *
 * To run a query within a React component, call `useGetFollowUpsByPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowUpsByPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowUpsByPostQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetFollowUpsByPostQuery(
  baseOptions: Apollo.QueryHookOptions<GetFollowUpsByPostQuery, GetFollowUpsByPostQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFollowUpsByPostQuery, GetFollowUpsByPostQueryVariables>(
    GetFollowUpsByPostDocument,
    options,
  );
}
export function useGetFollowUpsByPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFollowUpsByPostQuery,
    GetFollowUpsByPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFollowUpsByPostQuery, GetFollowUpsByPostQueryVariables>(
    GetFollowUpsByPostDocument,
    options,
  );
}
export type GetFollowUpsByPostQueryHookResult = ReturnType<typeof useGetFollowUpsByPostQuery>;
export type GetFollowUpsByPostLazyQueryHookResult = ReturnType<
  typeof useGetFollowUpsByPostLazyQuery
>;
export type GetFollowUpsByPostQueryResult = Apollo.QueryResult<
  GetFollowUpsByPostQuery,
  GetFollowUpsByPostQueryVariables
>;
export const GetFollowUpsByIdDocument = gql`
  query GetFollowUpsById($id: ID!) {
    FollowUp(id: $id) {
      id
      description
    }
  }
`;

/**
 * __useGetFollowUpsByIdQuery__
 *
 * To run a query within a React component, call `useGetFollowUpsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFollowUpsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFollowUpsByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFollowUpsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetFollowUpsByIdQuery, GetFollowUpsByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFollowUpsByIdQuery, GetFollowUpsByIdQueryVariables>(
    GetFollowUpsByIdDocument,
    options,
  );
}
export function useGetFollowUpsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFollowUpsByIdQuery, GetFollowUpsByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFollowUpsByIdQuery, GetFollowUpsByIdQueryVariables>(
    GetFollowUpsByIdDocument,
    options,
  );
}
export type GetFollowUpsByIdQueryHookResult = ReturnType<typeof useGetFollowUpsByIdQuery>;
export type GetFollowUpsByIdLazyQueryHookResult = ReturnType<typeof useGetFollowUpsByIdLazyQuery>;
export type GetFollowUpsByIdQueryResult = Apollo.QueryResult<
  GetFollowUpsByIdQuery,
  GetFollowUpsByIdQueryVariables
>;
export const GetA3InfoByPostIdDocument = gql`
  query GetA3InfoByPostId($postId: ID!) {
    Post(id: $postId) {
      id
      title
      postType
      content
      problemLevel
      problemTo
      who
      what
      how
      where
      when
      impact
      demand
      demandSolveStatus
      demandImproveStatus
      a3Status
      problemCommunitySource {
        id
        name
        logo
        logoPublicUrl
        members {
          id
          companyMember {
            id
            user {
              ...UserOnPostInfo
            }
          }
        }
      }
      problemCategory {
        id
        title
      }
      createdAt
      commentCount
      isSeenByMe
      isMine
      isLikedByMe
      likeCount
      likedBy {
        ...UserOnPostInfo
        currentCompanyMember {
          id
        }
      }
      draft
      reopened
      isAuthorHidden
      shareCount
      companyMemberResponsibles {
        id
        user {
          ...UserOnPostInfo
        }
      }
      responsibles {
        id
        lastName
        firstName
        email
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
          user {
            ...UserOnPostInfo
          }
        }
      }
      createdBy {
        ...UserOnPostInfo
      }
      comments {
        id
        content
        createdAt
        isMine
        user {
          ...UserOnPostInfo
        }
      }
      myRating {
        id
        rating
      }
      ...RatingSummary
      createdBy {
        ...UserOnPostInfo
      }
      files {
        id
        name
        path
        publicUrl
        type
      }
      ideaCategory {
        id
        title
      }
      companyMemberExperts {
        id
        user {
          ...UserOnPostInfo
        }
      }
      experts {
        id
        photoUrl
        photoPublicUrl
        firstName
        lastName
        currentCompanyMember {
          id
          user {
            ...UserOnPostInfo
          }
        }
      }
      communities {
        id
        name
        logo
        logoPublicUrl
      }
      recipientCommunity {
        id
        name
        logo
        logoPublicUrl
      }
      presentationFile {
        name
        path
        publicUrl
        type
      }
      sharedToSpecificUsers {
        id
        firstName
        lastName
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
        }
      }
      sharedToSpecificCommunities {
        id
        name
        logo
        logoPublicUrl
      }
      goals {
        ...IndicatorInfo
      }
      projectConversion {
        ...ProjectInA3Fragment
      }
      ideaToActionConversion {
        ...ActionInfo
        createdBy {
          id
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
        responsibles {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        indicator {
          id
        }
        subActions {
          ...SubActionInfo
        }
      }
      counterMeasures {
        id
        title
        description
        file {
          id
          path
          publicUrl
          name
          size
          type
        }
      }
      objectives {
        id
        description
      }
      analisisWhys {
        id
        description
        analysis5Why {
          id
          content
          rank
        }
      }
      analisisRootCauses {
        id
        description
        comments {
          id
          content
          createdAt
        }
      }
      followUps {
        id
        description
        createdAt
      }
    }
  }
  ${UserOnPostInfoFragmentDoc}
  ${RatingSummaryFragmentDoc}
  ${IndicatorInfoFragmentDoc}
  ${ProjectInA3FragmentFragmentDoc}
  ${ActionInfoFragmentDoc}
  ${SubActionInfoFragmentDoc}
`;

/**
 * __useGetA3InfoByPostIdQuery__
 *
 * To run a query within a React component, call `useGetA3InfoByPostIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetA3InfoByPostIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetA3InfoByPostIdQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetA3InfoByPostIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetA3InfoByPostIdQuery, GetA3InfoByPostIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetA3InfoByPostIdQuery, GetA3InfoByPostIdQueryVariables>(
    GetA3InfoByPostIdDocument,
    options,
  );
}
export function useGetA3InfoByPostIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetA3InfoByPostIdQuery,
    GetA3InfoByPostIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetA3InfoByPostIdQuery, GetA3InfoByPostIdQueryVariables>(
    GetA3InfoByPostIdDocument,
    options,
  );
}
export type GetA3InfoByPostIdQueryHookResult = ReturnType<typeof useGetA3InfoByPostIdQuery>;
export type GetA3InfoByPostIdLazyQueryHookResult = ReturnType<typeof useGetA3InfoByPostIdLazyQuery>;
export type GetA3InfoByPostIdQueryResult = Apollo.QueryResult<
  GetA3InfoByPostIdQuery,
  GetA3InfoByPostIdQueryVariables
>;
export const GetAnalysisRootCausesByPostDocument = gql`
  query GetAnalysisRootCausesByPost($postId: ID!) {
    allAnalysisRootCausesByPost(postId: $postId) {
      id
      description
      comments {
        id
        content
      }
    }
  }
`;

/**
 * __useGetAnalysisRootCausesByPostQuery__
 *
 * To run a query within a React component, call `useGetAnalysisRootCausesByPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalysisRootCausesByPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalysisRootCausesByPostQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetAnalysisRootCausesByPostQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAnalysisRootCausesByPostQuery,
    GetAnalysisRootCausesByPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAnalysisRootCausesByPostQuery,
    GetAnalysisRootCausesByPostQueryVariables
  >(GetAnalysisRootCausesByPostDocument, options);
}
export function useGetAnalysisRootCausesByPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnalysisRootCausesByPostQuery,
    GetAnalysisRootCausesByPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAnalysisRootCausesByPostQuery,
    GetAnalysisRootCausesByPostQueryVariables
  >(GetAnalysisRootCausesByPostDocument, options);
}
export type GetAnalysisRootCausesByPostQueryHookResult = ReturnType<
  typeof useGetAnalysisRootCausesByPostQuery
>;
export type GetAnalysisRootCausesByPostLazyQueryHookResult = ReturnType<
  typeof useGetAnalysisRootCausesByPostLazyQuery
>;
export type GetAnalysisRootCausesByPostQueryResult = Apollo.QueryResult<
  GetAnalysisRootCausesByPostQuery,
  GetAnalysisRootCausesByPostQueryVariables
>;
export const GetAnalysisWhysByPostDocument = gql`
  query GetAnalysisWhysByPost($postId: ID!) {
    allAnalysisWhysByPost(postId: $postId) {
      id
      description
      analysis5Why {
        id
        content
        rank
      }
    }
  }
`;

/**
 * __useGetAnalysisWhysByPostQuery__
 *
 * To run a query within a React component, call `useGetAnalysisWhysByPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalysisWhysByPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalysisWhysByPostQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetAnalysisWhysByPostQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAnalysisWhysByPostQuery,
    GetAnalysisWhysByPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnalysisWhysByPostQuery, GetAnalysisWhysByPostQueryVariables>(
    GetAnalysisWhysByPostDocument,
    options,
  );
}
export function useGetAnalysisWhysByPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnalysisWhysByPostQuery,
    GetAnalysisWhysByPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnalysisWhysByPostQuery, GetAnalysisWhysByPostQueryVariables>(
    GetAnalysisWhysByPostDocument,
    options,
  );
}
export type GetAnalysisWhysByPostQueryHookResult = ReturnType<typeof useGetAnalysisWhysByPostQuery>;
export type GetAnalysisWhysByPostLazyQueryHookResult = ReturnType<
  typeof useGetAnalysisWhysByPostLazyQuery
>;
export type GetAnalysisWhysByPostQueryResult = Apollo.QueryResult<
  GetAnalysisWhysByPostQuery,
  GetAnalysisWhysByPostQueryVariables
>;
export const GetAnalysisCompareByPostDocument = gql`
  query GetAnalysisCompareByPost($postId: ID!) {
    allAnalysisCompareByPost(postId: $postId) {
      id
      subject1
      subject2
      comments {
        id
        content
      }
    }
  }
`;

/**
 * __useGetAnalysisCompareByPostQuery__
 *
 * To run a query within a React component, call `useGetAnalysisCompareByPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalysisCompareByPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalysisCompareByPostQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetAnalysisCompareByPostQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAnalysisCompareByPostQuery,
    GetAnalysisCompareByPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnalysisCompareByPostQuery, GetAnalysisCompareByPostQueryVariables>(
    GetAnalysisCompareByPostDocument,
    options,
  );
}
export function useGetAnalysisCompareByPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAnalysisCompareByPostQuery,
    GetAnalysisCompareByPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnalysisCompareByPostQuery, GetAnalysisCompareByPostQueryVariables>(
    GetAnalysisCompareByPostDocument,
    options,
  );
}
export type GetAnalysisCompareByPostQueryHookResult = ReturnType<
  typeof useGetAnalysisCompareByPostQuery
>;
export type GetAnalysisCompareByPostLazyQueryHookResult = ReturnType<
  typeof useGetAnalysisCompareByPostLazyQuery
>;
export type GetAnalysisCompareByPostQueryResult = Apollo.QueryResult<
  GetAnalysisCompareByPostQuery,
  GetAnalysisCompareByPostQueryVariables
>;
export const GetObjectiveDocument = gql`
  query GetObjective($id: ID!) {
    Objective(id: $id) {
      id
      description
    }
  }
`;

/**
 * __useGetObjectiveQuery__
 *
 * To run a query within a React component, call `useGetObjectiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetObjectiveQuery(
  baseOptions: Apollo.QueryHookOptions<GetObjectiveQuery, GetObjectiveQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetObjectiveQuery, GetObjectiveQueryVariables>(
    GetObjectiveDocument,
    options,
  );
}
export function useGetObjectiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetObjectiveQuery, GetObjectiveQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetObjectiveQuery, GetObjectiveQueryVariables>(
    GetObjectiveDocument,
    options,
  );
}
export type GetObjectiveQueryHookResult = ReturnType<typeof useGetObjectiveQuery>;
export type GetObjectiveLazyQueryHookResult = ReturnType<typeof useGetObjectiveLazyQuery>;
export type GetObjectiveQueryResult = Apollo.QueryResult<
  GetObjectiveQuery,
  GetObjectiveQueryVariables
>;
export const GetObjectivesDocument = gql`
  query GetObjectives {
    allObjectives {
      id
      description
    }
  }
`;

/**
 * __useGetObjectivesQuery__
 *
 * To run a query within a React component, call `useGetObjectivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObjectivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObjectivesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetObjectivesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetObjectivesQuery, GetObjectivesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetObjectivesQuery, GetObjectivesQueryVariables>(
    GetObjectivesDocument,
    options,
  );
}
export function useGetObjectivesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetObjectivesQuery, GetObjectivesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetObjectivesQuery, GetObjectivesQueryVariables>(
    GetObjectivesDocument,
    options,
  );
}
export type GetObjectivesQueryHookResult = ReturnType<typeof useGetObjectivesQuery>;
export type GetObjectivesLazyQueryHookResult = ReturnType<typeof useGetObjectivesLazyQuery>;
export type GetObjectivesQueryResult = Apollo.QueryResult<
  GetObjectivesQuery,
  GetObjectivesQueryVariables
>;
export const GetCounterMeasuresByPostDocument = gql`
  query GetCounterMeasuresByPost($postId: ID!) {
    getCounterMeasuresByPost(postId: $postId) {
      id
      createdAt
      title
      description
    }
  }
`;

/**
 * __useGetCounterMeasuresByPostQuery__
 *
 * To run a query within a React component, call `useGetCounterMeasuresByPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCounterMeasuresByPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCounterMeasuresByPostQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetCounterMeasuresByPostQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCounterMeasuresByPostQuery,
    GetCounterMeasuresByPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCounterMeasuresByPostQuery, GetCounterMeasuresByPostQueryVariables>(
    GetCounterMeasuresByPostDocument,
    options,
  );
}
export function useGetCounterMeasuresByPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCounterMeasuresByPostQuery,
    GetCounterMeasuresByPostQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCounterMeasuresByPostQuery, GetCounterMeasuresByPostQueryVariables>(
    GetCounterMeasuresByPostDocument,
    options,
  );
}
export type GetCounterMeasuresByPostQueryHookResult = ReturnType<
  typeof useGetCounterMeasuresByPostQuery
>;
export type GetCounterMeasuresByPostLazyQueryHookResult = ReturnType<
  typeof useGetCounterMeasuresByPostLazyQuery
>;
export type GetCounterMeasuresByPostQueryResult = Apollo.QueryResult<
  GetCounterMeasuresByPostQuery,
  GetCounterMeasuresByPostQueryVariables
>;
export const ActivateApplicationDocument = gql`
  mutation activateApplication($id: ID!, $value: Boolean!) {
    application: activateApplication(id: $id, value: $value) {
      id
      isActivated
    }
  }
`;
export type ActivateApplicationMutationFn = Apollo.MutationFunction<
  ActivateApplicationMutation,
  ActivateApplicationMutationVariables
>;

/**
 * __useActivateApplicationMutation__
 *
 * To run a mutation, you first call `useActivateApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateApplicationMutation, { data, loading, error }] = useActivateApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useActivateApplicationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateApplicationMutation,
    ActivateApplicationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateApplicationMutation, ActivateApplicationMutationVariables>(
    ActivateApplicationDocument,
    options,
  );
}
export type ActivateApplicationMutationHookResult = ReturnType<
  typeof useActivateApplicationMutation
>;
export type ActivateApplicationMutationResult = Apollo.MutationResult<ActivateApplicationMutation>;
export type ActivateApplicationMutationOptions = Apollo.BaseMutationOptions<
  ActivateApplicationMutation,
  ActivateApplicationMutationVariables
>;
export const GetAllApplicationsDocument = gql`
  query GetAllApplications(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ApplicationFilterInput
  ) {
    applications: allApplications(
      input: {
        page: $page
        perPage: $perPage
        sortField: $sortField
        sortOrder: $sortOrder
        filter: $filter
      }
    ) {
      id
      isActivated
      name
      shortDescription
      logo {
        id
        name
        publicUrl
      }
    }
  }
`;

/**
 * __useGetAllApplicationsQuery__
 *
 * To run a query within a React component, call `useGetAllApplicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllApplicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllApplicationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllApplicationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllApplicationsQuery, GetAllApplicationsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllApplicationsQuery, GetAllApplicationsQueryVariables>(
    GetAllApplicationsDocument,
    options,
  );
}
export function useGetAllApplicationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllApplicationsQuery,
    GetAllApplicationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllApplicationsQuery, GetAllApplicationsQueryVariables>(
    GetAllApplicationsDocument,
    options,
  );
}
export type GetAllApplicationsQueryHookResult = ReturnType<typeof useGetAllApplicationsQuery>;
export type GetAllApplicationsLazyQueryHookResult = ReturnType<
  typeof useGetAllApplicationsLazyQuery
>;
export type GetAllApplicationsQueryResult = Apollo.QueryResult<
  GetAllApplicationsQuery,
  GetAllApplicationsQueryVariables
>;
export const GetApplicationDocument = gql`
  query GetApplication($id: ID!) {
    application: Application(id: $id) {
      id
      companyId
      isActivated
      name
      shortDescription
      longDescription
      featureList
      price
      logo {
        id
        name
        publicUrl
      }
      imageList {
        id
        name
        publicUrl
      }
    }
  }
`;

/**
 * __useGetApplicationQuery__
 *
 * To run a query within a React component, call `useGetApplicationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApplicationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApplicationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApplicationQuery(
  baseOptions: Apollo.QueryHookOptions<GetApplicationQuery, GetApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetApplicationQuery, GetApplicationQueryVariables>(
    GetApplicationDocument,
    options,
  );
}
export function useGetApplicationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApplicationQuery, GetApplicationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetApplicationQuery, GetApplicationQueryVariables>(
    GetApplicationDocument,
    options,
  );
}
export type GetApplicationQueryHookResult = ReturnType<typeof useGetApplicationQuery>;
export type GetApplicationLazyQueryHookResult = ReturnType<typeof useGetApplicationLazyQuery>;
export type GetApplicationQueryResult = Apollo.QueryResult<
  GetApplicationQuery,
  GetApplicationQueryVariables
>;
export const GetAllApplicationCategoriesDocument = gql`
  query GetAllApplicationCategories(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ApplicationCategoryFilterInput
  ) {
    applicationCategories: allApplicationCategories(
      input: {
        page: $page
        perPage: $perPage
        sortField: $sortField
        sortOrder: $sortOrder
        filter: $filter
      }
    ) {
      id
      title
    }
  }
`;

/**
 * __useGetAllApplicationCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllApplicationCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllApplicationCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllApplicationCategoriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllApplicationCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllApplicationCategoriesQuery,
    GetAllApplicationCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllApplicationCategoriesQuery,
    GetAllApplicationCategoriesQueryVariables
  >(GetAllApplicationCategoriesDocument, options);
}
export function useGetAllApplicationCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllApplicationCategoriesQuery,
    GetAllApplicationCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllApplicationCategoriesQuery,
    GetAllApplicationCategoriesQueryVariables
  >(GetAllApplicationCategoriesDocument, options);
}
export type GetAllApplicationCategoriesQueryHookResult = ReturnType<
  typeof useGetAllApplicationCategoriesQuery
>;
export type GetAllApplicationCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetAllApplicationCategoriesLazyQuery
>;
export type GetAllApplicationCategoriesQueryResult = Apollo.QueryResult<
  GetAllApplicationCategoriesQuery,
  GetAllApplicationCategoriesQueryVariables
>;
export const SignUpDocument = gql`
  mutation SignUp(
    $firstname: String!
    $lastname: String
    $password: String!
    $invitationToken: String!
  ) {
    payload: signup(
      input: {
        firstname: $firstname
        lastname: $lastname
        password: $password
        invitationToken: $invitationToken
      }
    ) {
      token
      success
      message
    }
  }
`;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      password: // value for 'password'
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const SignInDocument = gql`
  mutation SignIn($email: String!, $password: String!) {
    signin(input: { email: $email, password: $password }) {
      token
      is2FA
      noCompany
      success
      message
      user {
        id
        companyMembers {
          id
          suspended
          company {
            id
            slug
          }
        }
      }
    }
  }
`;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInMutation(
  baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
}
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const ForgotPasswordAskingDocument = gql`
  mutation ForgotPasswordAsking($email: String!) {
    forgotPasswordAsking(email: $email) {
      success
      message
      accessToken
    }
  }
`;
export type ForgotPasswordAskingMutationFn = Apollo.MutationFunction<
  ForgotPasswordAskingMutation,
  ForgotPasswordAskingMutationVariables
>;

/**
 * __useForgotPasswordAskingMutation__
 *
 * To run a mutation, you first call `useForgotPasswordAskingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordAskingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordAskingMutation, { data, loading, error }] = useForgotPasswordAskingMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordAskingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordAskingMutation,
    ForgotPasswordAskingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ForgotPasswordAskingMutation, ForgotPasswordAskingMutationVariables>(
    ForgotPasswordAskingDocument,
    options,
  );
}
export type ForgotPasswordAskingMutationHookResult = ReturnType<
  typeof useForgotPasswordAskingMutation
>;
export type ForgotPasswordAskingMutationResult =
  Apollo.MutationResult<ForgotPasswordAskingMutation>;
export type ForgotPasswordAskingMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordAskingMutation,
  ForgotPasswordAskingMutationVariables
>;
export const ForgotPasswordConfirmDocument = gql`
  mutation ForgotPasswordConfirm($token: String!, $password: String!) {
    forgotPasswordConfirm(input: { token: $token, password: $password }) {
      success
      message
      accessToken
    }
  }
`;
export type ForgotPasswordConfirmMutationFn = Apollo.MutationFunction<
  ForgotPasswordConfirmMutation,
  ForgotPasswordConfirmMutationVariables
>;

/**
 * __useForgotPasswordConfirmMutation__
 *
 * To run a mutation, you first call `useForgotPasswordConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordConfirmMutation, { data, loading, error }] = useForgotPasswordConfirmMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useForgotPasswordConfirmMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordConfirmMutation,
    ForgotPasswordConfirmMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ForgotPasswordConfirmMutation, ForgotPasswordConfirmMutationVariables>(
    ForgotPasswordConfirmDocument,
    options,
  );
}
export type ForgotPasswordConfirmMutationHookResult = ReturnType<
  typeof useForgotPasswordConfirmMutation
>;
export type ForgotPasswordConfirmMutationResult =
  Apollo.MutationResult<ForgotPasswordConfirmMutation>;
export type ForgotPasswordConfirmMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordConfirmMutation,
  ForgotPasswordConfirmMutationVariables
>;
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      success
      message
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const ChangePasswordDocument = gql`
  mutation ChangePassword($newPassword: String!, $oldPassword: String!) {
    changePassword(input: { newPassword: $newPassword, oldPassword: $oldPassword }) {
      success
      message
    }
  }
`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      oldPassword: // value for 'oldPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(
    ChangePasswordDocument,
    options,
  );
}
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const TwoFactorAuthenticationDocument = gql`
  mutation twoFactorAuthentication($code: String!) {
    twoFactorAuthentication(code: $code) {
      token
      success
      message
    }
  }
`;
export type TwoFactorAuthenticationMutationFn = Apollo.MutationFunction<
  TwoFactorAuthenticationMutation,
  TwoFactorAuthenticationMutationVariables
>;

/**
 * __useTwoFactorAuthenticationMutation__
 *
 * To run a mutation, you first call `useTwoFactorAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTwoFactorAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [twoFactorAuthenticationMutation, { data, loading, error }] = useTwoFactorAuthenticationMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useTwoFactorAuthenticationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TwoFactorAuthenticationMutation,
    TwoFactorAuthenticationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TwoFactorAuthenticationMutation,
    TwoFactorAuthenticationMutationVariables
  >(TwoFactorAuthenticationDocument, options);
}
export type TwoFactorAuthenticationMutationHookResult = ReturnType<
  typeof useTwoFactorAuthenticationMutation
>;
export type TwoFactorAuthenticationMutationResult =
  Apollo.MutationResult<TwoFactorAuthenticationMutation>;
export type TwoFactorAuthenticationMutationOptions = Apollo.BaseMutationOptions<
  TwoFactorAuthenticationMutation,
  TwoFactorAuthenticationMutationVariables
>;
export const Resend2FaCodeDocument = gql`
  mutation resend2FACode {
    resend2FACode {
      success
      message
    }
  }
`;
export type Resend2FaCodeMutationFn = Apollo.MutationFunction<
  Resend2FaCodeMutation,
  Resend2FaCodeMutationVariables
>;

/**
 * __useResend2FaCodeMutation__
 *
 * To run a mutation, you first call `useResend2FaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResend2FaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resend2FaCodeMutation, { data, loading, error }] = useResend2FaCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResend2FaCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<Resend2FaCodeMutation, Resend2FaCodeMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Resend2FaCodeMutation, Resend2FaCodeMutationVariables>(
    Resend2FaCodeDocument,
    options,
  );
}
export type Resend2FaCodeMutationHookResult = ReturnType<typeof useResend2FaCodeMutation>;
export type Resend2FaCodeMutationResult = Apollo.MutationResult<Resend2FaCodeMutation>;
export type Resend2FaCodeMutationOptions = Apollo.BaseMutationOptions<
  Resend2FaCodeMutation,
  Resend2FaCodeMutationVariables
>;
export const Toggle2FaDocument = gql`
  mutation toggle2FA($id: ID!, $active: Boolean!, $password: String!) {
    toggle2FA(id: $id, active: $active, password: $password) {
      id
      is2FA
    }
  }
`;
export type Toggle2FaMutationFn = Apollo.MutationFunction<
  Toggle2FaMutation,
  Toggle2FaMutationVariables
>;

/**
 * __useToggle2FaMutation__
 *
 * To run a mutation, you first call `useToggle2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggle2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggle2FaMutation, { data, loading, error }] = useToggle2FaMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useToggle2FaMutation(
  baseOptions?: Apollo.MutationHookOptions<Toggle2FaMutation, Toggle2FaMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Toggle2FaMutation, Toggle2FaMutationVariables>(
    Toggle2FaDocument,
    options,
  );
}
export type Toggle2FaMutationHookResult = ReturnType<typeof useToggle2FaMutation>;
export type Toggle2FaMutationResult = Apollo.MutationResult<Toggle2FaMutation>;
export type Toggle2FaMutationOptions = Apollo.BaseMutationOptions<
  Toggle2FaMutation,
  Toggle2FaMutationVariables
>;
export const CreateUserFromSignUpDocument = gql`
  mutation CreateUserFromSignUp(
    $email: String!
    $password: String!
    $token: String!
    $firstname: String!
    $lastname: String
    $hiringDate: DateTime
  ) {
    createUserFromSignUp(
      email: $email
      password: $password
      token: $token
      firstname: $firstname
      lastname: $lastname
      hiringDate: $hiringDate
    ) {
      success
      message
      accessToken
    }
  }
`;
export type CreateUserFromSignUpMutationFn = Apollo.MutationFunction<
  CreateUserFromSignUpMutation,
  CreateUserFromSignUpMutationVariables
>;

/**
 * __useCreateUserFromSignUpMutation__
 *
 * To run a mutation, you first call `useCreateUserFromSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFromSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFromSignUpMutation, { data, loading, error }] = useCreateUserFromSignUpMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *      firstname: // value for 'firstname'
 *      lastname: // value for 'lastname'
 *      hiringDate: // value for 'hiringDate'
 *   },
 * });
 */
export function useCreateUserFromSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserFromSignUpMutation,
    CreateUserFromSignUpMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserFromSignUpMutation, CreateUserFromSignUpMutationVariables>(
    CreateUserFromSignUpDocument,
    options,
  );
}
export type CreateUserFromSignUpMutationHookResult = ReturnType<
  typeof useCreateUserFromSignUpMutation
>;
export type CreateUserFromSignUpMutationResult =
  Apollo.MutationResult<CreateUserFromSignUpMutation>;
export type CreateUserFromSignUpMutationOptions = Apollo.BaseMutationOptions<
  CreateUserFromSignUpMutation,
  CreateUserFromSignUpMutationVariables
>;
export const SignUpInvitationDocument = gql`
  mutation SignUpInvitation($invitationToken: String!) {
    payload: signUpInvitation(invitationToken: $invitationToken) {
      success
      message
      isInvalidToken
      targetEmail
      token
      is2FA
    }
  }
`;
export type SignUpInvitationMutationFn = Apollo.MutationFunction<
  SignUpInvitationMutation,
  SignUpInvitationMutationVariables
>;

/**
 * __useSignUpInvitationMutation__
 *
 * To run a mutation, you first call `useSignUpInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpInvitationMutation, { data, loading, error }] = useSignUpInvitationMutation({
 *   variables: {
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useSignUpInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpInvitationMutation,
    SignUpInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpInvitationMutation, SignUpInvitationMutationVariables>(
    SignUpInvitationDocument,
    options,
  );
}
export type SignUpInvitationMutationHookResult = ReturnType<typeof useSignUpInvitationMutation>;
export type SignUpInvitationMutationResult = Apollo.MutationResult<SignUpInvitationMutation>;
export type SignUpInvitationMutationOptions = Apollo.BaseMutationOptions<
  SignUpInvitationMutation,
  SignUpInvitationMutationVariables
>;
export const CreateBusinessUnitDocument = gql`
  mutation CreateBusinessUnit(
    $companyId: ID
    $name: String!
    $mission: String!
    $description: String
    $email: String!
    $logo: String
    $romance: String!
  ) {
    createBusinessUnit(
      input: {
        companyId: $companyId
        name: $name
        mission: $mission
        description: $description
        email: $email
        logo: $logo
        romance: $romance
      }
    ) {
      id
    }
  }
`;
export type CreateBusinessUnitMutationFn = Apollo.MutationFunction<
  CreateBusinessUnitMutation,
  CreateBusinessUnitMutationVariables
>;

/**
 * __useCreateBusinessUnitMutation__
 *
 * To run a mutation, you first call `useCreateBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessUnitMutation, { data, loading, error }] = useCreateBusinessUnitMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      mission: // value for 'mission'
 *      description: // value for 'description'
 *      email: // value for 'email'
 *      logo: // value for 'logo'
 *      romance: // value for 'romance'
 *   },
 * });
 */
export function useCreateBusinessUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBusinessUnitMutation,
    CreateBusinessUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateBusinessUnitMutation, CreateBusinessUnitMutationVariables>(
    CreateBusinessUnitDocument,
    options,
  );
}
export type CreateBusinessUnitMutationHookResult = ReturnType<typeof useCreateBusinessUnitMutation>;
export type CreateBusinessUnitMutationResult = Apollo.MutationResult<CreateBusinessUnitMutation>;
export type CreateBusinessUnitMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessUnitMutation,
  CreateBusinessUnitMutationVariables
>;
export const UpdateBusinessUnitDocument = gql`
  mutation UpdateBusinessUnit(
    $id: ID!
    $name: String
    $mission: String
    $description: String
    $email: String
    $logo: String
    $romance: String
  ) {
    updateBusinessUnit(
      input: {
        id: $id
        name: $name
        mission: $mission
        description: $description
        email: $email
        logo: $logo
        romance: $romance
      }
    ) {
      id
      name
      mission
      description
      romance
      email
      logo
      logoPublicUrl
    }
  }
`;
export type UpdateBusinessUnitMutationFn = Apollo.MutationFunction<
  UpdateBusinessUnitMutation,
  UpdateBusinessUnitMutationVariables
>;

/**
 * __useUpdateBusinessUnitMutation__
 *
 * To run a mutation, you first call `useUpdateBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBusinessUnitMutation, { data, loading, error }] = useUpdateBusinessUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      mission: // value for 'mission'
 *      description: // value for 'description'
 *      email: // value for 'email'
 *      logo: // value for 'logo'
 *      romance: // value for 'romance'
 *   },
 * });
 */
export function useUpdateBusinessUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBusinessUnitMutation,
    UpdateBusinessUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBusinessUnitMutation, UpdateBusinessUnitMutationVariables>(
    UpdateBusinessUnitDocument,
    options,
  );
}
export type UpdateBusinessUnitMutationHookResult = ReturnType<typeof useUpdateBusinessUnitMutation>;
export type UpdateBusinessUnitMutationResult = Apollo.MutationResult<UpdateBusinessUnitMutation>;
export type UpdateBusinessUnitMutationOptions = Apollo.BaseMutationOptions<
  UpdateBusinessUnitMutation,
  UpdateBusinessUnitMutationVariables
>;
export const DeleteBusinessUnitDocument = gql`
  mutation DeleteBusinessUnit($id: ID!) {
    deleteBusinessUnit(id: $id) {
      id
    }
  }
`;
export type DeleteBusinessUnitMutationFn = Apollo.MutationFunction<
  DeleteBusinessUnitMutation,
  DeleteBusinessUnitMutationVariables
>;

/**
 * __useDeleteBusinessUnitMutation__
 *
 * To run a mutation, you first call `useDeleteBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBusinessUnitMutation, { data, loading, error }] = useDeleteBusinessUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBusinessUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteBusinessUnitMutation,
    DeleteBusinessUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteBusinessUnitMutation, DeleteBusinessUnitMutationVariables>(
    DeleteBusinessUnitDocument,
    options,
  );
}
export type DeleteBusinessUnitMutationHookResult = ReturnType<typeof useDeleteBusinessUnitMutation>;
export type DeleteBusinessUnitMutationResult = Apollo.MutationResult<DeleteBusinessUnitMutation>;
export type DeleteBusinessUnitMutationOptions = Apollo.BaseMutationOptions<
  DeleteBusinessUnitMutation,
  DeleteBusinessUnitMutationVariables
>;
export const AddMemberToBusinessUnitDocument = gql`
  mutation AddMemberToBusinessUnit(
    $businessUnitId: ID!
    $companyMemberId: ID!
    $businessUnitRole: BusinessUnitRole
  ) {
    addMemberToBusinessUnit(
      input: {
        businessUnitId: $businessUnitId
        companyMemberId: $companyMemberId
        businessUnitRole: $businessUnitRole
      }
    ) {
      id
      members {
        id
        businessUnitRole
        user {
          id
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
`;
export type AddMemberToBusinessUnitMutationFn = Apollo.MutationFunction<
  AddMemberToBusinessUnitMutation,
  AddMemberToBusinessUnitMutationVariables
>;

/**
 * __useAddMemberToBusinessUnitMutation__
 *
 * To run a mutation, you first call `useAddMemberToBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberToBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberToBusinessUnitMutation, { data, loading, error }] = useAddMemberToBusinessUnitMutation({
 *   variables: {
 *      businessUnitId: // value for 'businessUnitId'
 *      companyMemberId: // value for 'companyMemberId'
 *      businessUnitRole: // value for 'businessUnitRole'
 *   },
 * });
 */
export function useAddMemberToBusinessUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMemberToBusinessUnitMutation,
    AddMemberToBusinessUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddMemberToBusinessUnitMutation,
    AddMemberToBusinessUnitMutationVariables
  >(AddMemberToBusinessUnitDocument, options);
}
export type AddMemberToBusinessUnitMutationHookResult = ReturnType<
  typeof useAddMemberToBusinessUnitMutation
>;
export type AddMemberToBusinessUnitMutationResult =
  Apollo.MutationResult<AddMemberToBusinessUnitMutation>;
export type AddMemberToBusinessUnitMutationOptions = Apollo.BaseMutationOptions<
  AddMemberToBusinessUnitMutation,
  AddMemberToBusinessUnitMutationVariables
>;
export const AddMembersToBusinessUnitDocument = gql`
  mutation AddMembersToBusinessUnit(
    $businessUnitId: ID!
    $membersAndRoles: [MemberAndRoleInput!]!
  ) {
    addMembersToBusinessUnit(
      input: { businessUnitId: $businessUnitId, membersAndRoles: $membersAndRoles }
    ) {
      id
      members {
        id
        businessUnitRole
        user {
          id
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
`;
export type AddMembersToBusinessUnitMutationFn = Apollo.MutationFunction<
  AddMembersToBusinessUnitMutation,
  AddMembersToBusinessUnitMutationVariables
>;

/**
 * __useAddMembersToBusinessUnitMutation__
 *
 * To run a mutation, you first call `useAddMembersToBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMembersToBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMembersToBusinessUnitMutation, { data, loading, error }] = useAddMembersToBusinessUnitMutation({
 *   variables: {
 *      businessUnitId: // value for 'businessUnitId'
 *      membersAndRoles: // value for 'membersAndRoles'
 *   },
 * });
 */
export function useAddMembersToBusinessUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMembersToBusinessUnitMutation,
    AddMembersToBusinessUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddMembersToBusinessUnitMutation,
    AddMembersToBusinessUnitMutationVariables
  >(AddMembersToBusinessUnitDocument, options);
}
export type AddMembersToBusinessUnitMutationHookResult = ReturnType<
  typeof useAddMembersToBusinessUnitMutation
>;
export type AddMembersToBusinessUnitMutationResult =
  Apollo.MutationResult<AddMembersToBusinessUnitMutation>;
export type AddMembersToBusinessUnitMutationOptions = Apollo.BaseMutationOptions<
  AddMembersToBusinessUnitMutation,
  AddMembersToBusinessUnitMutationVariables
>;
export const RemoveMembersToBusinessUnitDocument = gql`
  mutation RemoveMembersToBusinessUnit($id: ID!, $memberIds: [ID!]) {
    removeMembersToBusinessUnit(id: $id, memberIds: $memberIds) {
      id
      members {
        id
        businessUnitRole
        user {
          id
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
`;
export type RemoveMembersToBusinessUnitMutationFn = Apollo.MutationFunction<
  RemoveMembersToBusinessUnitMutation,
  RemoveMembersToBusinessUnitMutationVariables
>;

/**
 * __useRemoveMembersToBusinessUnitMutation__
 *
 * To run a mutation, you first call `useRemoveMembersToBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMembersToBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMembersToBusinessUnitMutation, { data, loading, error }] = useRemoveMembersToBusinessUnitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      memberIds: // value for 'memberIds'
 *   },
 * });
 */
export function useRemoveMembersToBusinessUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveMembersToBusinessUnitMutation,
    RemoveMembersToBusinessUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveMembersToBusinessUnitMutation,
    RemoveMembersToBusinessUnitMutationVariables
  >(RemoveMembersToBusinessUnitDocument, options);
}
export type RemoveMembersToBusinessUnitMutationHookResult = ReturnType<
  typeof useRemoveMembersToBusinessUnitMutation
>;
export type RemoveMembersToBusinessUnitMutationResult =
  Apollo.MutationResult<RemoveMembersToBusinessUnitMutation>;
export type RemoveMembersToBusinessUnitMutationOptions = Apollo.BaseMutationOptions<
  RemoveMembersToBusinessUnitMutation,
  RemoveMembersToBusinessUnitMutationVariables
>;
export const GetCompanyBusinessUnitsDocument = gql`
  query GetCompanyBusinessUnits(
    $companyId: ID
    $page: Int
    $perPage: Int
    $filter: BusinessUnitFilter
    $sortField: String
    $sortOrder: String
  ) {
    businessUnits: getCompanyBusinessUnits(
      companyId: $companyId
      page: $page
      perPage: $perPage
      filter: $filter
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      id
      logo
      logoPublicUrl
      name
      mission
      romance
      email
      description
      indicatorsCount
      members {
        id
        businessUnitRole
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
`;

/**
 * __useGetCompanyBusinessUnitsQuery__
 *
 * To run a query within a React component, call `useGetCompanyBusinessUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyBusinessUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyBusinessUnitsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetCompanyBusinessUnitsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyBusinessUnitsQuery,
    GetCompanyBusinessUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyBusinessUnitsQuery, GetCompanyBusinessUnitsQueryVariables>(
    GetCompanyBusinessUnitsDocument,
    options,
  );
}
export function useGetCompanyBusinessUnitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyBusinessUnitsQuery,
    GetCompanyBusinessUnitsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyBusinessUnitsQuery, GetCompanyBusinessUnitsQueryVariables>(
    GetCompanyBusinessUnitsDocument,
    options,
  );
}
export type GetCompanyBusinessUnitsQueryHookResult = ReturnType<
  typeof useGetCompanyBusinessUnitsQuery
>;
export type GetCompanyBusinessUnitsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyBusinessUnitsLazyQuery
>;
export type GetCompanyBusinessUnitsQueryResult = Apollo.QueryResult<
  GetCompanyBusinessUnitsQuery,
  GetCompanyBusinessUnitsQueryVariables
>;
export const GetBusinessUnitByIdDocument = gql`
  query getBusinessUnitById($id: ID!) {
    businessUnit: getBusinessUnitById(id: $id) {
      id
      logo
      logoPublicUrl
      name
      mission
      romance
      email
      description
      indicatorsCount
      members {
        id
        businessUnitRole
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      indicators {
        id
        name
        value
        trend
        level
        description
        date
      }
      businessUnitImages {
        id
        name
        path
        publicUrl
      }
    }
  }
`;

/**
 * __useGetBusinessUnitByIdQuery__
 *
 * To run a query within a React component, call `useGetBusinessUnitByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBusinessUnitByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBusinessUnitByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBusinessUnitByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetBusinessUnitByIdQuery, GetBusinessUnitByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBusinessUnitByIdQuery, GetBusinessUnitByIdQueryVariables>(
    GetBusinessUnitByIdDocument,
    options,
  );
}
export function useGetBusinessUnitByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBusinessUnitByIdQuery,
    GetBusinessUnitByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBusinessUnitByIdQuery, GetBusinessUnitByIdQueryVariables>(
    GetBusinessUnitByIdDocument,
    options,
  );
}
export type GetBusinessUnitByIdQueryHookResult = ReturnType<typeof useGetBusinessUnitByIdQuery>;
export type GetBusinessUnitByIdLazyQueryHookResult = ReturnType<
  typeof useGetBusinessUnitByIdLazyQuery
>;
export type GetBusinessUnitByIdQueryResult = Apollo.QueryResult<
  GetBusinessUnitByIdQuery,
  GetBusinessUnitByIdQueryVariables
>;
export const GetCommunityEventTypeInputOptionsDocument = gql`
  query GetCommunityEventTypeInputOptions {
    allCommunityEventsType {
      id
      title
      iconPublicUrl
      order
    }
  }
`;

/**
 * __useGetCommunityEventTypeInputOptionsQuery__
 *
 * To run a query within a React component, call `useGetCommunityEventTypeInputOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityEventTypeInputOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityEventTypeInputOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommunityEventTypeInputOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCommunityEventTypeInputOptionsQuery,
    GetCommunityEventTypeInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCommunityEventTypeInputOptionsQuery,
    GetCommunityEventTypeInputOptionsQueryVariables
  >(GetCommunityEventTypeInputOptionsDocument, options);
}
export function useGetCommunityEventTypeInputOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunityEventTypeInputOptionsQuery,
    GetCommunityEventTypeInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommunityEventTypeInputOptionsQuery,
    GetCommunityEventTypeInputOptionsQueryVariables
  >(GetCommunityEventTypeInputOptionsDocument, options);
}
export type GetCommunityEventTypeInputOptionsQueryHookResult = ReturnType<
  typeof useGetCommunityEventTypeInputOptionsQuery
>;
export type GetCommunityEventTypeInputOptionsLazyQueryHookResult = ReturnType<
  typeof useGetCommunityEventTypeInputOptionsLazyQuery
>;
export type GetCommunityEventTypeInputOptionsQueryResult = Apollo.QueryResult<
  GetCommunityEventTypeInputOptionsQuery,
  GetCommunityEventTypeInputOptionsQueryVariables
>;
export const GetCalendarCommunityEventsDocument = gql`
  query GetCalendarCommunityEvents(
    $date: DateTime!
    $view: String!
    $dateRange: CalendarDateRangeInput
    $communityIds: [ID!]
    $communitiesFilter: CommunitiesFilter
  ) {
    calendarCommunityEvents(
      input: {
        date: $date
        view: $view
        dateRange: $dateRange
        communityIds: $communityIds
        communitiesFilter: $communitiesFilter
      }
    ) {
      id
      type
      title
      start
      end
      days
      canIEdit
      canIDelete
      communityEventType {
        id
        title
        iconPublicUrl
        order
      }
      companyMemberOwner {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      community {
        id
        name
        logoPublicUrl
      }
      calendarEventOwners {
        companyMemberId
        companyMember {
          id
          user {
            id
            fullName
            photoPublicUrl
          }
        }
      }
      calendarEventCommunities {
        communityId
        community {
          id
          name
          logoPublicUrl
        }
      }
    }
  }
`;

/**
 * __useGetCalendarCommunityEventsQuery__
 *
 * To run a query within a React component, call `useGetCalendarCommunityEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarCommunityEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarCommunityEventsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      view: // value for 'view'
 *      dateRange: // value for 'dateRange'
 *      communityIds: // value for 'communityIds'
 *      communitiesFilter: // value for 'communitiesFilter'
 *   },
 * });
 */
export function useGetCalendarCommunityEventsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarCommunityEventsQuery,
    GetCalendarCommunityEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCalendarCommunityEventsQuery, GetCalendarCommunityEventsQueryVariables>(
    GetCalendarCommunityEventsDocument,
    options,
  );
}
export function useGetCalendarCommunityEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarCommunityEventsQuery,
    GetCalendarCommunityEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCalendarCommunityEventsQuery,
    GetCalendarCommunityEventsQueryVariables
  >(GetCalendarCommunityEventsDocument, options);
}
export type GetCalendarCommunityEventsQueryHookResult = ReturnType<
  typeof useGetCalendarCommunityEventsQuery
>;
export type GetCalendarCommunityEventsLazyQueryHookResult = ReturnType<
  typeof useGetCalendarCommunityEventsLazyQuery
>;
export type GetCalendarCommunityEventsQueryResult = Apollo.QueryResult<
  GetCalendarCommunityEventsQuery,
  GetCalendarCommunityEventsQueryVariables
>;
export const GetCalendarCommunityEventDetailsDocument = gql`
  query GetCalendarCommunityEventDetails(
    $date: DateTime!
    $view: String!
    $communityEventTypeTitle: String
    $dateRange: CalendarDateRangeInput
    $communityIds: [ID!]
    $communitiesFilter: CommunitiesFilter
  ) {
    calendarCommunityEventDetails(
      input: {
        date: $date
        view: $view
        communityEventTypeTitle: $communityEventTypeTitle
        dateRange: $dateRange
        communityIds: $communityIds
        communitiesFilter: $communitiesFilter
      }
    ) {
      id
      type
      isPublic
      title
      description
      start
      end
      days
      canIEdit
      canIDelete
      eventPlace
      communityEventType {
        id
        title
        iconPublicUrl
        order
      }
      companyMemberOwner {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      community {
        id
        name
        logoPublicUrl
      }
      calendarEventOwners {
        companyMemberId
        companyMember {
          id
          user {
            id
            fullName
            photoPublicUrl
          }
        }
      }
      calendarEventCommunities {
        communityId
        community {
          id
          name
          logoPublicUrl
        }
      }
      comments {
        ...CommentInCalendarFragment
      }
      createdBy {
        id
        userPreference {
          id
          userTimezone {
            id
            tzCode
            label
          }
        }
      }
    }
  }
  ${CommentInCalendarFragmentFragmentDoc}
`;

/**
 * __useGetCalendarCommunityEventDetailsQuery__
 *
 * To run a query within a React component, call `useGetCalendarCommunityEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarCommunityEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarCommunityEventDetailsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      view: // value for 'view'
 *      communityEventTypeTitle: // value for 'communityEventTypeTitle'
 *      dateRange: // value for 'dateRange'
 *      communityIds: // value for 'communityIds'
 *      communitiesFilter: // value for 'communitiesFilter'
 *   },
 * });
 */
export function useGetCalendarCommunityEventDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarCommunityEventDetailsQuery,
    GetCalendarCommunityEventDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCalendarCommunityEventDetailsQuery,
    GetCalendarCommunityEventDetailsQueryVariables
  >(GetCalendarCommunityEventDetailsDocument, options);
}
export function useGetCalendarCommunityEventDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarCommunityEventDetailsQuery,
    GetCalendarCommunityEventDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCalendarCommunityEventDetailsQuery,
    GetCalendarCommunityEventDetailsQueryVariables
  >(GetCalendarCommunityEventDetailsDocument, options);
}
export type GetCalendarCommunityEventDetailsQueryHookResult = ReturnType<
  typeof useGetCalendarCommunityEventDetailsQuery
>;
export type GetCalendarCommunityEventDetailsLazyQueryHookResult = ReturnType<
  typeof useGetCalendarCommunityEventDetailsLazyQuery
>;
export type GetCalendarCommunityEventDetailsQueryResult = Apollo.QueryResult<
  GetCalendarCommunityEventDetailsQuery,
  GetCalendarCommunityEventDetailsQueryVariables
>;
export const GetLocationInputOptionsDocument = gql`
  query GetLocationInputOptions {
    locations: locationInputOptions {
      id
      title
      iconUrl
    }
  }
`;

/**
 * __useGetLocationInputOptionsQuery__
 *
 * To run a query within a React component, call `useGetLocationInputOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationInputOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationInputOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationInputOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationInputOptionsQuery,
    GetLocationInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationInputOptionsQuery, GetLocationInputOptionsQueryVariables>(
    GetLocationInputOptionsDocument,
    options,
  );
}
export function useGetLocationInputOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationInputOptionsQuery,
    GetLocationInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationInputOptionsQuery, GetLocationInputOptionsQueryVariables>(
    GetLocationInputOptionsDocument,
    options,
  );
}
export type GetLocationInputOptionsQueryHookResult = ReturnType<
  typeof useGetLocationInputOptionsQuery
>;
export type GetLocationInputOptionsLazyQueryHookResult = ReturnType<
  typeof useGetLocationInputOptionsLazyQuery
>;
export type GetLocationInputOptionsQueryResult = Apollo.QueryResult<
  GetLocationInputOptionsQuery,
  GetLocationInputOptionsQueryVariables
>;
export const GetLocationEventsDetailPerDayDocument = gql`
  query GetLocationEventsDetailPerDay(
    $date: DateTime!
    $communityIds: [ID!]
    $communitiesFilter: CommunitiesFilter
    $companyMemberOwnerId: ID
    $locationId: ID
  ) {
    locationEventsDetailPerDay(
      input: {
        date: $date
        communityIds: $communityIds
        communitiesFilter: $communitiesFilter
        companyMemberOwnerId: $companyMemberOwnerId
        locationId: $locationId
      }
    ) {
      id
      type
      start
      end
      canIEdit
      canIDelete
      companyMemberOwner {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      location {
        id
        title
        iconUrl
      }
      comments {
        ...CommentInCalendarFragment
      }
    }
    owners: calendarLocationEventsCompanyMemberOwnersPerDay(
      date: $date
      communityIds: $communityIds
      communitiesFilter: $communitiesFilter
    ) {
      id
      user {
        id
        fullName
        photoPublicUrl
      }
    }
  }
  ${CommentInCalendarFragmentFragmentDoc}
`;

/**
 * __useGetLocationEventsDetailPerDayQuery__
 *
 * To run a query within a React component, call `useGetLocationEventsDetailPerDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationEventsDetailPerDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationEventsDetailPerDayQuery({
 *   variables: {
 *      date: // value for 'date'
 *      communityIds: // value for 'communityIds'
 *      communitiesFilter: // value for 'communitiesFilter'
 *      companyMemberOwnerId: // value for 'companyMemberOwnerId'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationEventsDetailPerDayQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLocationEventsDetailPerDayQuery,
    GetLocationEventsDetailPerDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLocationEventsDetailPerDayQuery,
    GetLocationEventsDetailPerDayQueryVariables
  >(GetLocationEventsDetailPerDayDocument, options);
}
export function useGetLocationEventsDetailPerDayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationEventsDetailPerDayQuery,
    GetLocationEventsDetailPerDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLocationEventsDetailPerDayQuery,
    GetLocationEventsDetailPerDayQueryVariables
  >(GetLocationEventsDetailPerDayDocument, options);
}
export type GetLocationEventsDetailPerDayQueryHookResult = ReturnType<
  typeof useGetLocationEventsDetailPerDayQuery
>;
export type GetLocationEventsDetailPerDayLazyQueryHookResult = ReturnType<
  typeof useGetLocationEventsDetailPerDayLazyQuery
>;
export type GetLocationEventsDetailPerDayQueryResult = Apollo.QueryResult<
  GetLocationEventsDetailPerDayQuery,
  GetLocationEventsDetailPerDayQueryVariables
>;
export const GetMyLocationEventByDayDocument = gql`
  query GetMyLocationEventByDay($date: DateTime!) {
    myLocationEventByDay(date: $date) {
      id
      start
      end
      location {
        id
        title
        iconUrl
      }
    }
  }
`;

/**
 * __useGetMyLocationEventByDayQuery__
 *
 * To run a query within a React component, call `useGetMyLocationEventByDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLocationEventByDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLocationEventByDayQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetMyLocationEventByDayQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyLocationEventByDayQuery,
    GetMyLocationEventByDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyLocationEventByDayQuery, GetMyLocationEventByDayQueryVariables>(
    GetMyLocationEventByDayDocument,
    options,
  );
}
export function useGetMyLocationEventByDayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyLocationEventByDayQuery,
    GetMyLocationEventByDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyLocationEventByDayQuery, GetMyLocationEventByDayQueryVariables>(
    GetMyLocationEventByDayDocument,
    options,
  );
}
export type GetMyLocationEventByDayQueryHookResult = ReturnType<
  typeof useGetMyLocationEventByDayQuery
>;
export type GetMyLocationEventByDayLazyQueryHookResult = ReturnType<
  typeof useGetMyLocationEventByDayLazyQuery
>;
export type GetMyLocationEventByDayQueryResult = Apollo.QueryResult<
  GetMyLocationEventByDayQuery,
  GetMyLocationEventByDayQueryVariables
>;
export const CreateCalendarLocationEventDocument = gql`
  mutation CreateCalendarLocationEvent($locationId: ID!, $start: DateTime!, $end: DateTime!) {
    createCalendarLocationEvent(input: { locationId: $locationId, start: $start, end: $end }) {
      id
      start
      end
      days
      title
    }
  }
`;
export type CreateCalendarLocationEventMutationFn = Apollo.MutationFunction<
  CreateCalendarLocationEventMutation,
  CreateCalendarLocationEventMutationVariables
>;

/**
 * __useCreateCalendarLocationEventMutation__
 *
 * To run a mutation, you first call `useCreateCalendarLocationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarLocationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarLocationEventMutation, { data, loading, error }] = useCreateCalendarLocationEventMutation({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useCreateCalendarLocationEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarLocationEventMutation,
    CreateCalendarLocationEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarLocationEventMutation,
    CreateCalendarLocationEventMutationVariables
  >(CreateCalendarLocationEventDocument, options);
}
export type CreateCalendarLocationEventMutationHookResult = ReturnType<
  typeof useCreateCalendarLocationEventMutation
>;
export type CreateCalendarLocationEventMutationResult =
  Apollo.MutationResult<CreateCalendarLocationEventMutation>;
export type CreateCalendarLocationEventMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarLocationEventMutation,
  CreateCalendarLocationEventMutationVariables
>;
export const UpdateCalendarLocationEventDocument = gql`
  mutation UpdateCalendarLocationEvent(
    $id: ID!
    $locationId: ID!
    $start: DateTime!
    $end: DateTime!
    $fromEntryTunel: Boolean
  ) {
    updateCalendarLocationEvent(
      input: {
        id: $id
        locationId: $locationId
        start: $start
        end: $end
        fromEntryTunel: $fromEntryTunel
      }
    ) {
      id
      start
      end
      days
      title
    }
  }
`;
export type UpdateCalendarLocationEventMutationFn = Apollo.MutationFunction<
  UpdateCalendarLocationEventMutation,
  UpdateCalendarLocationEventMutationVariables
>;

/**
 * __useUpdateCalendarLocationEventMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarLocationEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarLocationEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarLocationEventMutation, { data, loading, error }] = useUpdateCalendarLocationEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      locationId: // value for 'locationId'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      fromEntryTunel: // value for 'fromEntryTunel'
 *   },
 * });
 */
export function useUpdateCalendarLocationEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarLocationEventMutation,
    UpdateCalendarLocationEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCalendarLocationEventMutation,
    UpdateCalendarLocationEventMutationVariables
  >(UpdateCalendarLocationEventDocument, options);
}
export type UpdateCalendarLocationEventMutationHookResult = ReturnType<
  typeof useUpdateCalendarLocationEventMutation
>;
export type UpdateCalendarLocationEventMutationResult =
  Apollo.MutationResult<UpdateCalendarLocationEventMutation>;
export type UpdateCalendarLocationEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarLocationEventMutation,
  UpdateCalendarLocationEventMutationVariables
>;
export const DeleteCalendarEventDocument = gql`
  mutation DeleteCalendarEvent($id: ID!, $fromEntryTunel: Boolean) {
    deleteCalendarEvent(id: $id, fromEntryTunel: $fromEntryTunel) {
      id
    }
  }
`;
export type DeleteCalendarEventMutationFn = Apollo.MutationFunction<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables
>;

/**
 * __useDeleteCalendarEventMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarEventMutation, { data, loading, error }] = useDeleteCalendarEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fromEntryTunel: // value for 'fromEntryTunel'
 *   },
 * });
 */
export function useDeleteCalendarEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCalendarEventMutation,
    DeleteCalendarEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCalendarEventMutation, DeleteCalendarEventMutationVariables>(
    DeleteCalendarEventDocument,
    options,
  );
}
export type DeleteCalendarEventMutationHookResult = ReturnType<
  typeof useDeleteCalendarEventMutation
>;
export type DeleteCalendarEventMutationResult = Apollo.MutationResult<DeleteCalendarEventMutation>;
export type DeleteCalendarEventMutationOptions = Apollo.BaseMutationOptions<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables
>;
export const CreateCalendarCommunityEventDocument = gql`
  mutation CreateCalendarCommunityEvent(
    $communityEventTypeId: ID!
    $title: String!
    $description: String
    $start: DateTime!
    $end: DateTime!
    $eventPlace: String
    $communityId: ID
    $ownerId: ID
    $ownerIds: [ID]
    $communityIds: [ID]
    $makePublic: Boolean
    $meetingId: String
  ) {
    createCalendarCommunityEvent(
      input: {
        communityEventTypeId: $communityEventTypeId
        title: $title
        description: $description
        start: $start
        end: $end
        eventPlace: $eventPlace
        communityId: $communityId
        ownerId: $ownerId
        ownerIds: $ownerIds
        communityIds: $communityIds
        makePublic: $makePublic
        meetingId: $meetingId
      }
    ) {
      id
    }
  }
`;
export type CreateCalendarCommunityEventMutationFn = Apollo.MutationFunction<
  CreateCalendarCommunityEventMutation,
  CreateCalendarCommunityEventMutationVariables
>;

/**
 * __useCreateCalendarCommunityEventMutation__
 *
 * To run a mutation, you first call `useCreateCalendarCommunityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarCommunityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarCommunityEventMutation, { data, loading, error }] = useCreateCalendarCommunityEventMutation({
 *   variables: {
 *      communityEventTypeId: // value for 'communityEventTypeId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      eventPlace: // value for 'eventPlace'
 *      communityId: // value for 'communityId'
 *      ownerId: // value for 'ownerId'
 *      ownerIds: // value for 'ownerIds'
 *      communityIds: // value for 'communityIds'
 *      makePublic: // value for 'makePublic'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useCreateCalendarCommunityEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarCommunityEventMutation,
    CreateCalendarCommunityEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarCommunityEventMutation,
    CreateCalendarCommunityEventMutationVariables
  >(CreateCalendarCommunityEventDocument, options);
}
export type CreateCalendarCommunityEventMutationHookResult = ReturnType<
  typeof useCreateCalendarCommunityEventMutation
>;
export type CreateCalendarCommunityEventMutationResult =
  Apollo.MutationResult<CreateCalendarCommunityEventMutation>;
export type CreateCalendarCommunityEventMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarCommunityEventMutation,
  CreateCalendarCommunityEventMutationVariables
>;
export const UpdateCalendarCommunityEventDocument = gql`
  mutation UpdateCalendarCommunityEvent(
    $id: ID!
    $communityEventTypeId: ID!
    $title: String!
    $description: String
    $start: DateTime!
    $end: DateTime!
    $eventPlace: String
    $communityId: ID
    $ownerId: ID
    $ownerIds: [ID]
    $communityIds: [ID]
    $makePublic: Boolean
    $meetingId: String
  ) {
    updateCalendarCommunityEvent(
      input: {
        id: $id
        communityEventTypeId: $communityEventTypeId
        title: $title
        description: $description
        start: $start
        end: $end
        eventPlace: $eventPlace
        communityId: $communityId
        ownerId: $ownerId
        ownerIds: $ownerIds
        communityIds: $communityIds
        makePublic: $makePublic
        meetingId: $meetingId
      }
    ) {
      id
      start
      end
      days
      title
      description
      eventPlace
      isPublic
    }
  }
`;
export type UpdateCalendarCommunityEventMutationFn = Apollo.MutationFunction<
  UpdateCalendarCommunityEventMutation,
  UpdateCalendarCommunityEventMutationVariables
>;

/**
 * __useUpdateCalendarCommunityEventMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarCommunityEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarCommunityEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarCommunityEventMutation, { data, loading, error }] = useUpdateCalendarCommunityEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      communityEventTypeId: // value for 'communityEventTypeId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      start: // value for 'start'
 *      end: // value for 'end'
 *      eventPlace: // value for 'eventPlace'
 *      communityId: // value for 'communityId'
 *      ownerId: // value for 'ownerId'
 *      ownerIds: // value for 'ownerIds'
 *      communityIds: // value for 'communityIds'
 *      makePublic: // value for 'makePublic'
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useUpdateCalendarCommunityEventMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarCommunityEventMutation,
    UpdateCalendarCommunityEventMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCalendarCommunityEventMutation,
    UpdateCalendarCommunityEventMutationVariables
  >(UpdateCalendarCommunityEventDocument, options);
}
export type UpdateCalendarCommunityEventMutationHookResult = ReturnType<
  typeof useUpdateCalendarCommunityEventMutation
>;
export type UpdateCalendarCommunityEventMutationResult =
  Apollo.MutationResult<UpdateCalendarCommunityEventMutation>;
export type UpdateCalendarCommunityEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarCommunityEventMutation,
  UpdateCalendarCommunityEventMutationVariables
>;
export const GetAllCommunityEventsTypeDocument = gql`
  query GetAllCommunityEventsType {
    allCommunityEventsType {
      id
      title
      icon
      iconPublicUrl
      order
    }
  }
`;

/**
 * __useGetAllCommunityEventsTypeQuery__
 *
 * To run a query within a React component, call `useGetAllCommunityEventsTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCommunityEventsTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCommunityEventsTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCommunityEventsTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCommunityEventsTypeQuery,
    GetAllCommunityEventsTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCommunityEventsTypeQuery, GetAllCommunityEventsTypeQueryVariables>(
    GetAllCommunityEventsTypeDocument,
    options,
  );
}
export function useGetAllCommunityEventsTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCommunityEventsTypeQuery,
    GetAllCommunityEventsTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCommunityEventsTypeQuery,
    GetAllCommunityEventsTypeQueryVariables
  >(GetAllCommunityEventsTypeDocument, options);
}
export type GetAllCommunityEventsTypeQueryHookResult = ReturnType<
  typeof useGetAllCommunityEventsTypeQuery
>;
export type GetAllCommunityEventsTypeLazyQueryHookResult = ReturnType<
  typeof useGetAllCommunityEventsTypeLazyQuery
>;
export type GetAllCommunityEventsTypeQueryResult = Apollo.QueryResult<
  GetAllCommunityEventsTypeQuery,
  GetAllCommunityEventsTypeQueryVariables
>;
export const GetCalendarEventsPerDayDocument = gql`
  query GetCalendarEventsPerDay(
    $date: DateTime!
    $view: String!
    $dateRange: CalendarDateRangeInput
    $communityIds: [ID!]
    $communitiesFilter: CommunitiesFilter
  ) {
    calendarEventsPerDay(
      input: {
        date: $date
        view: $view
        dateRange: $dateRange
        communityIds: $communityIds
        communitiesFilter: $communitiesFilter
      }
    ) {
      locations {
        day
        locationId
        type
        isMeACompanyMemberOwner
        title
        iconUrl
        companyMemberOwners {
          id
          user {
            id
            fullName
            photoPublicUrl
          }
        }
      }
      communityEvents {
        id
        type
        title
        start
        end
        days
        canIEdit
        canIDelete
        communityEventType {
          id
          title
          iconPublicUrl
          order
        }
        companyMemberOwner {
          id
          user {
            id
            fullName
            photoPublicUrl
          }
        }
        community {
          id
          name
          logoPublicUrl
        }
        calendarEventOwners {
          companyMemberId
          companyMember {
            id
            user {
              id
              fullName
              photoPublicUrl
            }
          }
        }
        calendarEventCommunities {
          communityId
          community {
            id
            name
            logoPublicUrl
          }
        }
      }
    }
  }
`;

/**
 * __useGetCalendarEventsPerDayQuery__
 *
 * To run a query within a React component, call `useGetCalendarEventsPerDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarEventsPerDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarEventsPerDayQuery({
 *   variables: {
 *      date: // value for 'date'
 *      view: // value for 'view'
 *      dateRange: // value for 'dateRange'
 *      communityIds: // value for 'communityIds'
 *      communitiesFilter: // value for 'communitiesFilter'
 *   },
 * });
 */
export function useGetCalendarEventsPerDayQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarEventsPerDayQuery,
    GetCalendarEventsPerDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCalendarEventsPerDayQuery, GetCalendarEventsPerDayQueryVariables>(
    GetCalendarEventsPerDayDocument,
    options,
  );
}
export function useGetCalendarEventsPerDayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarEventsPerDayQuery,
    GetCalendarEventsPerDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCalendarEventsPerDayQuery, GetCalendarEventsPerDayQueryVariables>(
    GetCalendarEventsPerDayDocument,
    options,
  );
}
export type GetCalendarEventsPerDayQueryHookResult = ReturnType<
  typeof useGetCalendarEventsPerDayQuery
>;
export type GetCalendarEventsPerDayLazyQueryHookResult = ReturnType<
  typeof useGetCalendarEventsPerDayLazyQuery
>;
export type GetCalendarEventsPerDayQueryResult = Apollo.QueryResult<
  GetCalendarEventsPerDayQuery,
  GetCalendarEventsPerDayQueryVariables
>;
export const GetCalendarEventByIdDocument = gql`
  query GetCalendarEventById($id: ID!) {
    calendarEvent: calendarEventById(id: $id) {
      id
      type
      isPublic
      title
      description
      start
      end
      days
      eventPlace
      canIEdit
      canIDelete
      location {
        id
        title
        iconUrl
      }
      communityEventType {
        id
        title
        iconPublicUrl
        order
      }
      companyMemberOwner {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      community {
        id
        name
        logoPublicUrl
      }
      calendarEventOwners {
        companyMemberId
        companyMember {
          id
          user {
            id
            fullName
            photoPublicUrl
          }
        }
      }
      calendarEventCommunities {
        communityId
        community {
          id
          name
          logoPublicUrl
        }
      }
      comments {
        ...CommentInCalendarFragment
      }
      createdBy {
        id
        userPreference {
          id
          userTimezone {
            id
            tzCode
            label
          }
        }
      }
    }
  }
  ${CommentInCalendarFragmentFragmentDoc}
`;

/**
 * __useGetCalendarEventByIdQuery__
 *
 * To run a query within a React component, call `useGetCalendarEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarEventByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCalendarEventByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarEventByIdQuery,
    GetCalendarEventByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCalendarEventByIdQuery, GetCalendarEventByIdQueryVariables>(
    GetCalendarEventByIdDocument,
    options,
  );
}
export function useGetCalendarEventByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarEventByIdQuery,
    GetCalendarEventByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCalendarEventByIdQuery, GetCalendarEventByIdQueryVariables>(
    GetCalendarEventByIdDocument,
    options,
  );
}
export type GetCalendarEventByIdQueryHookResult = ReturnType<typeof useGetCalendarEventByIdQuery>;
export type GetCalendarEventByIdLazyQueryHookResult = ReturnType<
  typeof useGetCalendarEventByIdLazyQuery
>;
export type GetCalendarEventByIdQueryResult = Apollo.QueryResult<
  GetCalendarEventByIdQuery,
  GetCalendarEventByIdQueryVariables
>;
export const GetEventsByCalendarFilterHistoryDocument = gql`
  query getEventsByCalendarFilterHistory(
    $perPage: Int
    $page: Int
    $locationOwnerId: ID
    $keyWord: String
    $type: CalendarEventType
    $dateRangeStart: DateTime
    $dateRangeEnd: DateTime
    $locationId: ID
    $communityEventTypeId: ID
  ) {
    eventsByCalendarFilterHistory(
      perPage: $perPage
      page: $page
      locationOwnerId: $locationOwnerId
      keyWord: $keyWord
      input: {
        type: $type
        dateRangeEnd: $dateRangeEnd
        dateRangeStart: $dateRangeStart
        locationId: $locationId
        communityEventTypeId: $communityEventTypeId
      }
    ) {
      id
      type
      title
      start
      end
      canIEdit
      canIDelete
      location {
        id
        title
        iconUrl
      }
      communityEventType {
        id
        title
        iconPublicUrl
        order
      }
      companyMemberOwner {
        id
        user {
          ...UserWithPhotoInLeaveFragment
        }
      }
      calendarEventOwners {
        companyMember {
          id
          user {
            ...UserWithPhotoInLeaveFragment
          }
        }
      }
      calendarEventCommunities {
        community {
          id
          name
          logoPublicUrl
        }
      }
      createdBy {
        id
        userPreference {
          id
          userTimezone {
            id
            tzCode
            label
          }
        }
      }
    }
    _eventsByCalendarFilterHistoryMeta(
      input: {
        type: $type
        dateRangeEnd: $dateRangeEnd
        dateRangeStart: $dateRangeStart
        locationId: $locationId
        communityEventTypeId: $communityEventTypeId
      }
    ) {
      count
      locationOwners {
        id
        user {
          ...UserWithPhotoInLeaveFragment
        }
      }
    }
  }
  ${UserWithPhotoInLeaveFragmentFragmentDoc}
`;

/**
 * __useGetEventsByCalendarFilterHistoryQuery__
 *
 * To run a query within a React component, call `useGetEventsByCalendarFilterHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsByCalendarFilterHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsByCalendarFilterHistoryQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      locationOwnerId: // value for 'locationOwnerId'
 *      keyWord: // value for 'keyWord'
 *      type: // value for 'type'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      locationId: // value for 'locationId'
 *      communityEventTypeId: // value for 'communityEventTypeId'
 *   },
 * });
 */
export function useGetEventsByCalendarFilterHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEventsByCalendarFilterHistoryQuery,
    GetEventsByCalendarFilterHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEventsByCalendarFilterHistoryQuery,
    GetEventsByCalendarFilterHistoryQueryVariables
  >(GetEventsByCalendarFilterHistoryDocument, options);
}
export function useGetEventsByCalendarFilterHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEventsByCalendarFilterHistoryQuery,
    GetEventsByCalendarFilterHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEventsByCalendarFilterHistoryQuery,
    GetEventsByCalendarFilterHistoryQueryVariables
  >(GetEventsByCalendarFilterHistoryDocument, options);
}
export type GetEventsByCalendarFilterHistoryQueryHookResult = ReturnType<
  typeof useGetEventsByCalendarFilterHistoryQuery
>;
export type GetEventsByCalendarFilterHistoryLazyQueryHookResult = ReturnType<
  typeof useGetEventsByCalendarFilterHistoryLazyQuery
>;
export type GetEventsByCalendarFilterHistoryQueryResult = Apollo.QueryResult<
  GetEventsByCalendarFilterHistoryQuery,
  GetEventsByCalendarFilterHistoryQueryVariables
>;
export const CreateCalendarFilterHistoryDocument = gql`
  mutation CreateCalendarFilterHistory(
    $type: CalendarEventType
    $title: String
    $dateRangeStart: DateTime
    $dateRangeEnd: DateTime
    $communityId: String
    $communityFilter: CommunitiesFilter
    $statusFilter: LeaveStatus
    $validatorIds: [ID!]
  ) {
    createCalendarFilterHistory(
      input: {
        type: $type
        title: $title
        dateRangeStart: $dateRangeStart
        dateRangeEnd: $dateRangeEnd
        communityId: $communityId
        communityFilter: $communityFilter
        statusFilter: $statusFilter
        validatorIds: $validatorIds
      }
    ) {
      id
      type
      title
      dateRangeStart
      dateRangeEnd
      communityId
      communityFilter
      statusFilter
      validators {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      location {
        id
        title
        iconUrl
      }
      communityEventType {
        id
        title
        iconPublicUrl
        order
      }
    }
  }
`;
export type CreateCalendarFilterHistoryMutationFn = Apollo.MutationFunction<
  CreateCalendarFilterHistoryMutation,
  CreateCalendarFilterHistoryMutationVariables
>;

/**
 * __useCreateCalendarFilterHistoryMutation__
 *
 * To run a mutation, you first call `useCreateCalendarFilterHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCalendarFilterHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCalendarFilterHistoryMutation, { data, loading, error }] = useCreateCalendarFilterHistoryMutation({
 *   variables: {
 *      type: // value for 'type'
 *      title: // value for 'title'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      communityId: // value for 'communityId'
 *      communityFilter: // value for 'communityFilter'
 *      statusFilter: // value for 'statusFilter'
 *      validatorIds: // value for 'validatorIds'
 *   },
 * });
 */
export function useCreateCalendarFilterHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCalendarFilterHistoryMutation,
    CreateCalendarFilterHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCalendarFilterHistoryMutation,
    CreateCalendarFilterHistoryMutationVariables
  >(CreateCalendarFilterHistoryDocument, options);
}
export type CreateCalendarFilterHistoryMutationHookResult = ReturnType<
  typeof useCreateCalendarFilterHistoryMutation
>;
export type CreateCalendarFilterHistoryMutationResult =
  Apollo.MutationResult<CreateCalendarFilterHistoryMutation>;
export type CreateCalendarFilterHistoryMutationOptions = Apollo.BaseMutationOptions<
  CreateCalendarFilterHistoryMutation,
  CreateCalendarFilterHistoryMutationVariables
>;
export const UpdateCalendarFilterHistoryDocument = gql`
  mutation UpdateCalendarFilterHistory(
    $calendarFilterHistoryId: ID!
    $isOnlyTitle: Boolean
    $type: CalendarEventType
    $title: String
    $dateRangeStart: DateTime
    $dateRangeEnd: DateTime
    $communityId: String
    $communityFilter: CommunitiesFilter
    $statusFilter: LeaveStatus
    $validatorIds: [ID!]
    $locationId: ID
    $communityEventTypeId: ID
  ) {
    updateCalendarFilterHistory(
      calendarFilterHistoryId: $calendarFilterHistoryId
      isOnlyTitle: $isOnlyTitle
      input: {
        type: $type
        title: $title
        dateRangeStart: $dateRangeStart
        dateRangeEnd: $dateRangeEnd
        communityId: $communityId
        communityFilter: $communityFilter
        statusFilter: $statusFilter
        validatorIds: $validatorIds
        locationId: $locationId
        communityEventTypeId: $communityEventTypeId
      }
    ) {
      id
      type
      title
      dateRangeStart
      dateRangeEnd
      communityId
      communityFilter
      statusFilter
      validators {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      location {
        id
        title
        iconUrl
      }
      communityEventType {
        id
        title
        iconPublicUrl
        order
      }
    }
  }
`;
export type UpdateCalendarFilterHistoryMutationFn = Apollo.MutationFunction<
  UpdateCalendarFilterHistoryMutation,
  UpdateCalendarFilterHistoryMutationVariables
>;

/**
 * __useUpdateCalendarFilterHistoryMutation__
 *
 * To run a mutation, you first call `useUpdateCalendarFilterHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCalendarFilterHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCalendarFilterHistoryMutation, { data, loading, error }] = useUpdateCalendarFilterHistoryMutation({
 *   variables: {
 *      calendarFilterHistoryId: // value for 'calendarFilterHistoryId'
 *      isOnlyTitle: // value for 'isOnlyTitle'
 *      type: // value for 'type'
 *      title: // value for 'title'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      communityId: // value for 'communityId'
 *      communityFilter: // value for 'communityFilter'
 *      statusFilter: // value for 'statusFilter'
 *      validatorIds: // value for 'validatorIds'
 *      locationId: // value for 'locationId'
 *      communityEventTypeId: // value for 'communityEventTypeId'
 *   },
 * });
 */
export function useUpdateCalendarFilterHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCalendarFilterHistoryMutation,
    UpdateCalendarFilterHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCalendarFilterHistoryMutation,
    UpdateCalendarFilterHistoryMutationVariables
  >(UpdateCalendarFilterHistoryDocument, options);
}
export type UpdateCalendarFilterHistoryMutationHookResult = ReturnType<
  typeof useUpdateCalendarFilterHistoryMutation
>;
export type UpdateCalendarFilterHistoryMutationResult =
  Apollo.MutationResult<UpdateCalendarFilterHistoryMutation>;
export type UpdateCalendarFilterHistoryMutationOptions = Apollo.BaseMutationOptions<
  UpdateCalendarFilterHistoryMutation,
  UpdateCalendarFilterHistoryMutationVariables
>;
export const DeleteCalendarFilterHistoryDocument = gql`
  mutation DeleteCalendarFilterHistory($calendarFilterHistoryId: ID!) {
    deleteCalendarFilterHistory(calendarFilterHistoryId: $calendarFilterHistoryId) {
      id
    }
  }
`;
export type DeleteCalendarFilterHistoryMutationFn = Apollo.MutationFunction<
  DeleteCalendarFilterHistoryMutation,
  DeleteCalendarFilterHistoryMutationVariables
>;

/**
 * __useDeleteCalendarFilterHistoryMutation__
 *
 * To run a mutation, you first call `useDeleteCalendarFilterHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCalendarFilterHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCalendarFilterHistoryMutation, { data, loading, error }] = useDeleteCalendarFilterHistoryMutation({
 *   variables: {
 *      calendarFilterHistoryId: // value for 'calendarFilterHistoryId'
 *   },
 * });
 */
export function useDeleteCalendarFilterHistoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCalendarFilterHistoryMutation,
    DeleteCalendarFilterHistoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCalendarFilterHistoryMutation,
    DeleteCalendarFilterHistoryMutationVariables
  >(DeleteCalendarFilterHistoryDocument, options);
}
export type DeleteCalendarFilterHistoryMutationHookResult = ReturnType<
  typeof useDeleteCalendarFilterHistoryMutation
>;
export type DeleteCalendarFilterHistoryMutationResult =
  Apollo.MutationResult<DeleteCalendarFilterHistoryMutation>;
export type DeleteCalendarFilterHistoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteCalendarFilterHistoryMutation,
  DeleteCalendarFilterHistoryMutationVariables
>;
export const GetCalendarFilterHistoriesDocument = gql`
  query getCalendarFilterHistories {
    calendarFilterHistories {
      id
      type
      title
      dateRangeStart
      dateRangeEnd
      communityId
      communityFilter
      statusFilter
      validators {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      location {
        id
        title
        iconUrl
      }
      communityEventType {
        id
        title
        iconPublicUrl
        order
      }
    }
    meta: _calendarFilterHistoriesMeta {
      count
    }
  }
`;

/**
 * __useGetCalendarFilterHistoriesQuery__
 *
 * To run a query within a React component, call `useGetCalendarFilterHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarFilterHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarFilterHistoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCalendarFilterHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCalendarFilterHistoriesQuery,
    GetCalendarFilterHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCalendarFilterHistoriesQuery, GetCalendarFilterHistoriesQueryVariables>(
    GetCalendarFilterHistoriesDocument,
    options,
  );
}
export function useGetCalendarFilterHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarFilterHistoriesQuery,
    GetCalendarFilterHistoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCalendarFilterHistoriesQuery,
    GetCalendarFilterHistoriesQueryVariables
  >(GetCalendarFilterHistoriesDocument, options);
}
export type GetCalendarFilterHistoriesQueryHookResult = ReturnType<
  typeof useGetCalendarFilterHistoriesQuery
>;
export type GetCalendarFilterHistoriesLazyQueryHookResult = ReturnType<
  typeof useGetCalendarFilterHistoriesLazyQuery
>;
export type GetCalendarFilterHistoriesQueryResult = Apollo.QueryResult<
  GetCalendarFilterHistoriesQuery,
  GetCalendarFilterHistoriesQueryVariables
>;
export const GetCalendarFilterHistoryByIdDocument = gql`
  query getCalendarFilterHistoryById($calendarFilterHistoryId: ID!) {
    calendarFilterHistoryById(calendarFilterHistoryId: $calendarFilterHistoryId) {
      id
      type
      title
      dateRangeStart
      dateRangeEnd
      communityId
      communityFilter
      statusFilter
      validators {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
    }
  }
`;

/**
 * __useGetCalendarFilterHistoryByIdQuery__
 *
 * To run a query within a React component, call `useGetCalendarFilterHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCalendarFilterHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCalendarFilterHistoryByIdQuery({
 *   variables: {
 *      calendarFilterHistoryId: // value for 'calendarFilterHistoryId'
 *   },
 * });
 */
export function useGetCalendarFilterHistoryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCalendarFilterHistoryByIdQuery,
    GetCalendarFilterHistoryByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCalendarFilterHistoryByIdQuery,
    GetCalendarFilterHistoryByIdQueryVariables
  >(GetCalendarFilterHistoryByIdDocument, options);
}
export function useGetCalendarFilterHistoryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCalendarFilterHistoryByIdQuery,
    GetCalendarFilterHistoryByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCalendarFilterHistoryByIdQuery,
    GetCalendarFilterHistoryByIdQueryVariables
  >(GetCalendarFilterHistoryByIdDocument, options);
}
export type GetCalendarFilterHistoryByIdQueryHookResult = ReturnType<
  typeof useGetCalendarFilterHistoryByIdQuery
>;
export type GetCalendarFilterHistoryByIdLazyQueryHookResult = ReturnType<
  typeof useGetCalendarFilterHistoryByIdLazyQuery
>;
export type GetCalendarFilterHistoryByIdQueryResult = Apollo.QueryResult<
  GetCalendarFilterHistoryByIdQuery,
  GetCalendarFilterHistoryByIdQueryVariables
>;
export const CreateSemiInstantCallDocument = gql`
  mutation CreateSemiInstantCall(
    $type: SemiInstantCallType!
    $name: String
    $description: ID
    $creatorId: ID
    $teamId: ID
    $guestIds: [ID!]
    $chatTeamName: String
  ) {
    semiInstantCall: createSemiInstantCall(
      input: {
        type: $type
        name: $name
        description: $description
        creatorId: $creatorId
        teamId: $teamId
        guestIds: $guestIds
        chatTeamName: $chatTeamName
      }
    ) {
      id
      name
      amITheHost
      jitsiMeetUrl
      createdAt
      createdBy {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      guests {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
    }
  }
`;
export type CreateSemiInstantCallMutationFn = Apollo.MutationFunction<
  CreateSemiInstantCallMutation,
  CreateSemiInstantCallMutationVariables
>;

/**
 * __useCreateSemiInstantCallMutation__
 *
 * To run a mutation, you first call `useCreateSemiInstantCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSemiInstantCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSemiInstantCallMutation, { data, loading, error }] = useCreateSemiInstantCallMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      creatorId: // value for 'creatorId'
 *      teamId: // value for 'teamId'
 *      guestIds: // value for 'guestIds'
 *      chatTeamName: // value for 'chatTeamName'
 *   },
 * });
 */
export function useCreateSemiInstantCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSemiInstantCallMutation,
    CreateSemiInstantCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSemiInstantCallMutation, CreateSemiInstantCallMutationVariables>(
    CreateSemiInstantCallDocument,
    options,
  );
}
export type CreateSemiInstantCallMutationHookResult = ReturnType<
  typeof useCreateSemiInstantCallMutation
>;
export type CreateSemiInstantCallMutationResult =
  Apollo.MutationResult<CreateSemiInstantCallMutation>;
export type CreateSemiInstantCallMutationOptions = Apollo.BaseMutationOptions<
  CreateSemiInstantCallMutation,
  CreateSemiInstantCallMutationVariables
>;
export const UpdateSemiInstantCallDocument = gql`
  mutation UpdateSemiInstantCall(
    $id: ID!
    $name: String
    $description: ID
    $guestIds: [ID!]
    $teamId: ID
  ) {
    semiInstantCall: updateSemiInstantCall(
      input: {
        id: $id
        name: $name
        description: $description
        guestIds: $guestIds
        teamId: $teamId
      }
    ) {
      id
      name
      amITheHost
      jitsiMeetUrl
      createdAt
      createdBy {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      guests {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
    }
  }
`;
export type UpdateSemiInstantCallMutationFn = Apollo.MutationFunction<
  UpdateSemiInstantCallMutation,
  UpdateSemiInstantCallMutationVariables
>;

/**
 * __useUpdateSemiInstantCallMutation__
 *
 * To run a mutation, you first call `useUpdateSemiInstantCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSemiInstantCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSemiInstantCallMutation, { data, loading, error }] = useUpdateSemiInstantCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      guestIds: // value for 'guestIds'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useUpdateSemiInstantCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSemiInstantCallMutation,
    UpdateSemiInstantCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSemiInstantCallMutation, UpdateSemiInstantCallMutationVariables>(
    UpdateSemiInstantCallDocument,
    options,
  );
}
export type UpdateSemiInstantCallMutationHookResult = ReturnType<
  typeof useUpdateSemiInstantCallMutation
>;
export type UpdateSemiInstantCallMutationResult =
  Apollo.MutationResult<UpdateSemiInstantCallMutation>;
export type UpdateSemiInstantCallMutationOptions = Apollo.BaseMutationOptions<
  UpdateSemiInstantCallMutation,
  UpdateSemiInstantCallMutationVariables
>;
export const CloseSemiInstantCallDocument = gql`
  mutation CloseSemiInstantCall($id: ID!) {
    semiInstantCall: closeSemiInstatntCall(id: $id) {
      id
    }
  }
`;
export type CloseSemiInstantCallMutationFn = Apollo.MutationFunction<
  CloseSemiInstantCallMutation,
  CloseSemiInstantCallMutationVariables
>;

/**
 * __useCloseSemiInstantCallMutation__
 *
 * To run a mutation, you first call `useCloseSemiInstantCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseSemiInstantCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeSemiInstantCallMutation, { data, loading, error }] = useCloseSemiInstantCallMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseSemiInstantCallMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CloseSemiInstantCallMutation,
    CloseSemiInstantCallMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CloseSemiInstantCallMutation, CloseSemiInstantCallMutationVariables>(
    CloseSemiInstantCallDocument,
    options,
  );
}
export type CloseSemiInstantCallMutationHookResult = ReturnType<
  typeof useCloseSemiInstantCallMutation
>;
export type CloseSemiInstantCallMutationResult =
  Apollo.MutationResult<CloseSemiInstantCallMutation>;
export type CloseSemiInstantCallMutationOptions = Apollo.BaseMutationOptions<
  CloseSemiInstantCallMutation,
  CloseSemiInstantCallMutationVariables
>;
export const GetAllSemiInstantCallsDocument = gql`
  query GetAllSemiInstantCalls(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: SemiInstantCallFilterInput
  ) {
    semiInstantCalls: allSemiInstantCalls(
      input: {
        page: $page
        perPage: $perPage
        sortField: $sortField
        sortOrder: $sortOrder
        filter: $filter
      }
    ) {
      id
      name
      amITheHost
      jitsiMeetUrl
      createdAt
      createdBy {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      guests {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
    }
    total: _allSemiInstantCallsMeta(filter: $filter) {
      count
    }
  }
`;

/**
 * __useGetAllSemiInstantCallsQuery__
 *
 * To run a query within a React component, call `useGetAllSemiInstantCallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSemiInstantCallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSemiInstantCallsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllSemiInstantCallsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllSemiInstantCallsQuery,
    GetAllSemiInstantCallsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllSemiInstantCallsQuery, GetAllSemiInstantCallsQueryVariables>(
    GetAllSemiInstantCallsDocument,
    options,
  );
}
export function useGetAllSemiInstantCallsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllSemiInstantCallsQuery,
    GetAllSemiInstantCallsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllSemiInstantCallsQuery, GetAllSemiInstantCallsQueryVariables>(
    GetAllSemiInstantCallsDocument,
    options,
  );
}
export type GetAllSemiInstantCallsQueryHookResult = ReturnType<
  typeof useGetAllSemiInstantCallsQuery
>;
export type GetAllSemiInstantCallsLazyQueryHookResult = ReturnType<
  typeof useGetAllSemiInstantCallsLazyQuery
>;
export type GetAllSemiInstantCallsQueryResult = Apollo.QueryResult<
  GetAllSemiInstantCallsQuery,
  GetAllSemiInstantCallsQueryVariables
>;
export const GetSemiInstantCallDocument = gql`
  query GetSemiInstantCall($id: ID!) {
    semiInstantCall: SemiInstantCall(id: $id) {
      id
      name
      amITheHost
      description
      jitsiMeetUrl
      createdAt
      createdBy {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      guests {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      community {
        id
        name
        logoPublicUrl
      }
    }
  }
`;

/**
 * __useGetSemiInstantCallQuery__
 *
 * To run a query within a React component, call `useGetSemiInstantCallQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSemiInstantCallQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSemiInstantCallQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSemiInstantCallQuery(
  baseOptions: Apollo.QueryHookOptions<GetSemiInstantCallQuery, GetSemiInstantCallQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSemiInstantCallQuery, GetSemiInstantCallQueryVariables>(
    GetSemiInstantCallDocument,
    options,
  );
}
export function useGetSemiInstantCallLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSemiInstantCallQuery,
    GetSemiInstantCallQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSemiInstantCallQuery, GetSemiInstantCallQueryVariables>(
    GetSemiInstantCallDocument,
    options,
  );
}
export type GetSemiInstantCallQueryHookResult = ReturnType<typeof useGetSemiInstantCallQuery>;
export type GetSemiInstantCallLazyQueryHookResult = ReturnType<
  typeof useGetSemiInstantCallLazyQuery
>;
export type GetSemiInstantCallQueryResult = Apollo.QueryResult<
  GetSemiInstantCallQuery,
  GetSemiInstantCallQueryVariables
>;
export const MakeSeenCoinHistoriesDocument = gql`
  mutation MakeSeenCoinHistories {
    makeSeenCoinHistories
  }
`;
export type MakeSeenCoinHistoriesMutationFn = Apollo.MutationFunction<
  MakeSeenCoinHistoriesMutation,
  MakeSeenCoinHistoriesMutationVariables
>;

/**
 * __useMakeSeenCoinHistoriesMutation__
 *
 * To run a mutation, you first call `useMakeSeenCoinHistoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeSeenCoinHistoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeSeenCoinHistoriesMutation, { data, loading, error }] = useMakeSeenCoinHistoriesMutation({
 *   variables: {
 *   },
 * });
 */
export function useMakeSeenCoinHistoriesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MakeSeenCoinHistoriesMutation,
    MakeSeenCoinHistoriesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MakeSeenCoinHistoriesMutation, MakeSeenCoinHistoriesMutationVariables>(
    MakeSeenCoinHistoriesDocument,
    options,
  );
}
export type MakeSeenCoinHistoriesMutationHookResult = ReturnType<
  typeof useMakeSeenCoinHistoriesMutation
>;
export type MakeSeenCoinHistoriesMutationResult =
  Apollo.MutationResult<MakeSeenCoinHistoriesMutation>;
export type MakeSeenCoinHistoriesMutationOptions = Apollo.BaseMutationOptions<
  MakeSeenCoinHistoriesMutation,
  MakeSeenCoinHistoriesMutationVariables
>;
export const AllCoinHistoriesDocument = gql`
  query allCoinHistories(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CoinHistoryFilter
  ) {
    coinHistories: allCoinHistories(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      type
      description
      value
      expSource
      isSeen
      createdAt
    }
    total: _allCoinHistoriesMeta(filter: $filter) {
      count
      sum
    }
  }
`;

/**
 * __useAllCoinHistoriesQuery__
 *
 * To run a query within a React component, call `useAllCoinHistoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCoinHistoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCoinHistoriesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllCoinHistoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllCoinHistoriesQuery, AllCoinHistoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllCoinHistoriesQuery, AllCoinHistoriesQueryVariables>(
    AllCoinHistoriesDocument,
    options,
  );
}
export function useAllCoinHistoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllCoinHistoriesQuery, AllCoinHistoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllCoinHistoriesQuery, AllCoinHistoriesQueryVariables>(
    AllCoinHistoriesDocument,
    options,
  );
}
export type AllCoinHistoriesQueryHookResult = ReturnType<typeof useAllCoinHistoriesQuery>;
export type AllCoinHistoriesLazyQueryHookResult = ReturnType<typeof useAllCoinHistoriesLazyQuery>;
export type AllCoinHistoriesQueryResult = Apollo.QueryResult<
  AllCoinHistoriesQuery,
  AllCoinHistoriesQueryVariables
>;
export const CreateCommentDocument = gql`
  mutation CreateComment(
    $content: String!
    $isPrivate: Boolean
    $postId: ID
    $indicatorId: ID
    $projectId: ID
    $leaveId: ID
    $actionId: ID
    $calendarEventId: ID
    $meetingId: ID
    $communityEventId: ID
  ) {
    createComment(
      content: $content
      isPrivate: $isPrivate
      postId: $postId
      indicatorId: $indicatorId
      projectId: $projectId
      leaveId: $leaveId
      actionId: $actionId
      calendarEventId: $calendarEventId
      meetingId: $meetingId
      communityEventId: $communityEventId
    ) {
      id
      content
    }
  }
`;
export type CreateCommentMutationFn = Apollo.MutationFunction<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      content: // value for 'content'
 *      isPrivate: // value for 'isPrivate'
 *      postId: // value for 'postId'
 *      indicatorId: // value for 'indicatorId'
 *      projectId: // value for 'projectId'
 *      leaveId: // value for 'leaveId'
 *      actionId: // value for 'actionId'
 *      calendarEventId: // value for 'calendarEventId'
 *      meetingId: // value for 'meetingId'
 *      communityEventId: // value for 'communityEventId'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(
    CreateCommentDocument,
    options,
  );
}
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;
export const UpdateCommentDocument = gql`
  mutation UpdateComment($id: ID!, $content: String) {
    updateComment(id: $id, content: $content) {
      id
      content
    }
  }
`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(
    UpdateCommentDocument,
    options,
  );
}
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>;
export const DeleteCommentDocument = gql`
  mutation DeleteComment($id: ID!) {
    deleteComment(id: $id) {
      id
      content
    }
  }
`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(
    DeleteCommentDocument,
    options,
  );
}
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const LikeCommentDocument = gql`
  mutation LikeComment($commentId: String!) {
    likeComment(commentId: $commentId) {
      id
      content
      likedBy {
        user {
          id
          photoPublicUrl
          lastName
          firstName
          currentCompanyMember {
            id
          }
        }
      }
      likeCount
      isLikedByMe
    }
  }
`;
export type LikeCommentMutationFn = Apollo.MutationFunction<
  LikeCommentMutation,
  LikeCommentMutationVariables
>;

/**
 * __useLikeCommentMutation__
 *
 * To run a mutation, you first call `useLikeCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeCommentMutation, { data, loading, error }] = useLikeCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useLikeCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<LikeCommentMutation, LikeCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LikeCommentMutation, LikeCommentMutationVariables>(
    LikeCommentDocument,
    options,
  );
}
export type LikeCommentMutationHookResult = ReturnType<typeof useLikeCommentMutation>;
export type LikeCommentMutationResult = Apollo.MutationResult<LikeCommentMutation>;
export type LikeCommentMutationOptions = Apollo.BaseMutationOptions<
  LikeCommentMutation,
  LikeCommentMutationVariables
>;
export const UnlikeCommentDocument = gql`
  mutation UnlikeComment($commentId: String!) {
    unlikeComment(commentId: $commentId) {
      id
      content
      likedBy {
        user {
          id
          photoPublicUrl
          lastName
          firstName
          currentCompanyMember {
            id
          }
        }
      }
      likeCount
      isLikedByMe
    }
  }
`;
export type UnlikeCommentMutationFn = Apollo.MutationFunction<
  UnlikeCommentMutation,
  UnlikeCommentMutationVariables
>;

/**
 * __useUnlikeCommentMutation__
 *
 * To run a mutation, you first call `useUnlikeCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlikeCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlikeCommentMutation, { data, loading, error }] = useUnlikeCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useUnlikeCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<UnlikeCommentMutation, UnlikeCommentMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnlikeCommentMutation, UnlikeCommentMutationVariables>(
    UnlikeCommentDocument,
    options,
  );
}
export type UnlikeCommentMutationHookResult = ReturnType<typeof useUnlikeCommentMutation>;
export type UnlikeCommentMutationResult = Apollo.MutationResult<UnlikeCommentMutation>;
export type UnlikeCommentMutationOptions = Apollo.BaseMutationOptions<
  UnlikeCommentMutation,
  UnlikeCommentMutationVariables
>;
export const AddCompanyMemberToCommunityDocument = gql`
  mutation AddCompanyMemberToCommunity(
    $communityId: ID!
    $role: CommunityRole!
    $companyMemberId: ID!
  ) {
    addCompanyMemberToCommunity(
      communityId: $communityId
      role: $role
      companyMemberId: $companyMemberId
    ) {
      id
    }
  }
`;
export type AddCompanyMemberToCommunityMutationFn = Apollo.MutationFunction<
  AddCompanyMemberToCommunityMutation,
  AddCompanyMemberToCommunityMutationVariables
>;

/**
 * __useAddCompanyMemberToCommunityMutation__
 *
 * To run a mutation, you first call `useAddCompanyMemberToCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompanyMemberToCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompanyMemberToCommunityMutation, { data, loading, error }] = useAddCompanyMemberToCommunityMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      role: // value for 'role'
 *      companyMemberId: // value for 'companyMemberId'
 *   },
 * });
 */
export function useAddCompanyMemberToCommunityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCompanyMemberToCommunityMutation,
    AddCompanyMemberToCommunityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddCompanyMemberToCommunityMutation,
    AddCompanyMemberToCommunityMutationVariables
  >(AddCompanyMemberToCommunityDocument, options);
}
export type AddCompanyMemberToCommunityMutationHookResult = ReturnType<
  typeof useAddCompanyMemberToCommunityMutation
>;
export type AddCompanyMemberToCommunityMutationResult =
  Apollo.MutationResult<AddCompanyMemberToCommunityMutation>;
export type AddCompanyMemberToCommunityMutationOptions = Apollo.BaseMutationOptions<
  AddCompanyMemberToCommunityMutation,
  AddCompanyMemberToCommunityMutationVariables
>;
export const UpdateCommunityDocument = gql`
  mutation UpdateCommunity(
    $id: ID!
    $name: String
    $logo: String
    $mission: String
    $rommance: String
  ) {
    updateCommunity(id: $id, name: $name, logo: $logo, mission: $mission, rommance: $rommance) {
      ...Community
    }
  }
  ${CommunityFragmentDoc}
`;
export type UpdateCommunityMutationFn = Apollo.MutationFunction<
  UpdateCommunityMutation,
  UpdateCommunityMutationVariables
>;

/**
 * __useUpdateCommunityMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityMutation, { data, loading, error }] = useUpdateCommunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      logo: // value for 'logo'
 *      mission: // value for 'mission'
 *      rommance: // value for 'rommance'
 *   },
 * });
 */
export function useUpdateCommunityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommunityMutation,
    UpdateCommunityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCommunityMutation, UpdateCommunityMutationVariables>(
    UpdateCommunityDocument,
    options,
  );
}
export type UpdateCommunityMutationHookResult = ReturnType<typeof useUpdateCommunityMutation>;
export type UpdateCommunityMutationResult = Apollo.MutationResult<UpdateCommunityMutation>;
export type UpdateCommunityMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommunityMutation,
  UpdateCommunityMutationVariables
>;
export const DeleteActionDocument = gql`
  mutation DeleteAction($id: ID!) {
    deleteAction(id: $id) {
      id
    }
  }
`;
export type DeleteActionMutationFn = Apollo.MutationFunction<
  DeleteActionMutation,
  DeleteActionMutationVariables
>;

/**
 * __useDeleteActionMutation__
 *
 * To run a mutation, you first call `useDeleteActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteActionMutation, { data, loading, error }] = useDeleteActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteActionMutation, DeleteActionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteActionMutation, DeleteActionMutationVariables>(
    DeleteActionDocument,
    options,
  );
}
export type DeleteActionMutationHookResult = ReturnType<typeof useDeleteActionMutation>;
export type DeleteActionMutationResult = Apollo.MutationResult<DeleteActionMutation>;
export type DeleteActionMutationOptions = Apollo.BaseMutationOptions<
  DeleteActionMutation,
  DeleteActionMutationVariables
>;
export const CreateActionDocument = gql`
  mutation CreateAction(
    $title: String
    $description: String
    $targetDate: Date
    $actionType: ActionType
    $communityId: ID
    $companyId: ID
    $businessUnitId: ID
    $indicatorId: ID
    $roadBlockId: ID
    $responsibleIds: [ID!]
    $convertedFromIdeaId: ID
    $parentActionId: ID
    $postId: ID
    $projectId: ID
    $localCreationType: LocalCreationActionType!
    $oneToOneSubordonateId: ID
  ) {
    createAction(
      title: $title
      description: $description
      targetDate: $targetDate
      actionType: $actionType
      communityId: $communityId
      companyId: $companyId
      businessUnitId: $businessUnitId
      indicatorId: $indicatorId
      roadBlockId: $roadBlockId
      responsibleIds: $responsibleIds
      convertedFromIdeaId: $convertedFromIdeaId
      parentActionId: $parentActionId
      postId: $postId
      projectId: $projectId
      localCreationType: $localCreationType
      oneToOneSubordonateId: $oneToOneSubordonateId
    ) {
      id
    }
  }
`;
export type CreateActionMutationFn = Apollo.MutationFunction<
  CreateActionMutation,
  CreateActionMutationVariables
>;

/**
 * __useCreateActionMutation__
 *
 * To run a mutation, you first call `useCreateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createActionMutation, { data, loading, error }] = useCreateActionMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      targetDate: // value for 'targetDate'
 *      actionType: // value for 'actionType'
 *      communityId: // value for 'communityId'
 *      companyId: // value for 'companyId'
 *      businessUnitId: // value for 'businessUnitId'
 *      indicatorId: // value for 'indicatorId'
 *      roadBlockId: // value for 'roadBlockId'
 *      responsibleIds: // value for 'responsibleIds'
 *      convertedFromIdeaId: // value for 'convertedFromIdeaId'
 *      parentActionId: // value for 'parentActionId'
 *      postId: // value for 'postId'
 *      projectId: // value for 'projectId'
 *      localCreationType: // value for 'localCreationType'
 *      oneToOneSubordonateId: // value for 'oneToOneSubordonateId'
 *   },
 * });
 */
export function useCreateActionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateActionMutation, CreateActionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateActionMutation, CreateActionMutationVariables>(
    CreateActionDocument,
    options,
  );
}
export type CreateActionMutationHookResult = ReturnType<typeof useCreateActionMutation>;
export type CreateActionMutationResult = Apollo.MutationResult<CreateActionMutation>;
export type CreateActionMutationOptions = Apollo.BaseMutationOptions<
  CreateActionMutation,
  CreateActionMutationVariables
>;
export const DeleteActionToProjectDocument = gql`
  mutation deleteProjectAction($id: ID!) {
    Action: deleteProjectAction(id: $id) {
      id
      title
      description
      postId
      project {
        id
      }
    }
  }
`;
export type DeleteActionToProjectMutationFn = Apollo.MutationFunction<
  DeleteActionToProjectMutation,
  DeleteActionToProjectMutationVariables
>;
/**
 * useDeleteActionToProjectMutation
 *
 * To run a query within a React component, call `useDeleteActionToProjectMutation` and pass it any options that fit your needs.
 * When your component renders, `useDeleteActionToProjectMutation` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteActionToProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteActionToProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteActionToProjectMutation,
    DeleteActionToProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteActionToProjectMutation, DeleteActionToProjectMutationVariables>(
    DeleteActionToProjectDocument,
    options,
  );
}

export type DeleteActionToProjectMutationHookResult = ReturnType<
  typeof useDeleteActionToProjectMutation
>;
export type DeleteActionToProjectMutationResult = Apollo.QueryResult<
  DeleteActionToProjectMutation,
  DeleteActionToProjectMutationVariables
>;
export type DeleteActionToProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteActionToProjectMutation,
  DeleteActionToProjectMutationVariables
>;
export const UpdateActionDocument = gql`
  mutation UpdateAction(
    $id: ID!
    $description: String
    $targetDate: Date
    $actionType: ActionType
    $isCompleted: Boolean
    $responsibleIds: [ID!]
    $title: String
  ) {
    updateAction(
      id: $id
      description: $description
      targetDate: $targetDate
      actionType: $actionType
      isCompleted: $isCompleted
      responsibleIds: $responsibleIds
      title: $title
    ) {
      ...ActionInfo
      responsibles {
        id
      }
    }
  }
  ${ActionInfoFragmentDoc}
`;
export type UpdateActionMutationFn = Apollo.MutationFunction<
  UpdateActionMutation,
  UpdateActionMutationVariables
>;

/**
 * __useUpdateActionMutation__
 *
 * To run a mutation, you first call `useUpdateActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionMutation, { data, loading, error }] = useUpdateActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      targetDate: // value for 'targetDate'
 *      actionType: // value for 'actionType'
 *      isCompleted: // value for 'isCompleted'
 *      responsibleIds: // value for 'responsibleIds'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useUpdateActionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateActionMutation, UpdateActionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateActionMutation, UpdateActionMutationVariables>(
    UpdateActionDocument,
    options,
  );
}
export type UpdateActionMutationHookResult = ReturnType<typeof useUpdateActionMutation>;
export type UpdateActionMutationResult = Apollo.MutationResult<UpdateActionMutation>;
export type UpdateActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateActionMutation,
  UpdateActionMutationVariables
>;
export const DeleteProjectActionDocument = gql`
  mutation DeleteProjectAction($id: ID!) {
    deleteProjectAction(id: $id) {
      id
      title
      description
      postId
    }
  }
`;
export type DeleteProjectActionMutationFn = Apollo.MutationFunction<
  DeleteProjectActionMutation,
  DeleteProjectActionMutationVariables
>;

/**
 * __useDeleteProjectActionMutation__
 *
 * To run a mutation, you first call `useDeleteProjectActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectActionMutation, { data, loading, error }] = useDeleteProjectActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectActionMutation,
    DeleteProjectActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProjectActionMutation, DeleteProjectActionMutationVariables>(
    DeleteProjectActionDocument,
    options,
  );
}
export type DeleteProjectActionMutationHookResult = ReturnType<
  typeof useDeleteProjectActionMutation
>;
export type DeleteProjectActionMutationResult = Apollo.MutationResult<DeleteProjectActionMutation>;
export type DeleteProjectActionMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectActionMutation,
  DeleteProjectActionMutationVariables
>;

export const CheckedActionDocument = gql`
  mutation CheckedAction($actionId: ID!, $isCompleted: Boolean!) {
    checkedAction(actionId: $actionId, isCompleted: $isCompleted) {
      id
      isCompleted
    }
  }
`;
export type CheckedActionMutationFn = Apollo.MutationFunction<
  CheckedActionMutation,
  CheckedActionMutationVariables
>;

/**
 * __useCheckedActionMutation__
 *
 * To run a mutation, you first call `useCheckedActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckedActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkedActionMutation, { data, loading, error }] = useCheckedActionMutation({
 *   variables: {
 *      actionId: // value for 'actionId'
 *      isCompleted: // value for 'isCompleted'
 *   },
 * });
 */
export function useCheckedActionMutation(
  baseOptions?: Apollo.MutationHookOptions<CheckedActionMutation, CheckedActionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CheckedActionMutation, CheckedActionMutationVariables>(
    CheckedActionDocument,
    options,
  );
}
export type CheckedActionMutationHookResult = ReturnType<typeof useCheckedActionMutation>;
export type CheckedActionMutationResult = Apollo.MutationResult<CheckedActionMutation>;
export type CheckedActionMutationOptions = Apollo.BaseMutationOptions<
  CheckedActionMutation,
  CheckedActionMutationVariables
>;
export const UpdateProjectActionDocument = gql`
  mutation UpdateProjectAction($id: ID!, $projectId: String) {
    updateProjectAction(id: $id, projectId: $projectId) {
      ...ActionInfo
      responsibles {
        id
      }
      project {
        id
      }
    }
  }
  ${ActionInfoFragmentDoc}
`;
export type UpdateProjectActionMutationFn = Apollo.MutationFunction<
  UpdateProjectActionMutation,
  UpdateProjectActionMutationVariables
>;

/**
 * __useUpdateProjectActionMutation__
 *
 * To run a mutation, you first call `useUpdateProjectActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActionMutation, { data, loading, error }] = useUpdateProjectActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      projectId: // id for 'project'
 *   },
 * });
 */
export function useUpdateProjectActionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectActionMutation,
    UpdateProjectActionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectActionMutation, UpdateProjectActionMutationVariables>(
    UpdateProjectActionDocument,
    options,
  );
}
export type UpdateProjectActionMutationHookResult = ReturnType<
  typeof useUpdateProjectActionMutation
>;
export type UpdateProjectActionMutationResult = Apollo.MutationResult<UpdateProjectActionMutation>;
export type UpdateProjectActionMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectActionMutation,
  UpdateProjectActionMutationVariables
>;
export const DeleteCommunityDocument = gql`
  mutation DeleteCommunity($id: ID!) {
    deleteCommunity(id: $id) {
      id
    }
  }
`;
export type DeleteCommunityMutationFn = Apollo.MutationFunction<
  DeleteCommunityMutation,
  DeleteCommunityMutationVariables
>;

/**
 * __useDeleteCommunityMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityMutation, { data, loading, error }] = useDeleteCommunityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommunityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCommunityMutation,
    DeleteCommunityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCommunityMutation, DeleteCommunityMutationVariables>(
    DeleteCommunityDocument,
    options,
  );
}
export type DeleteCommunityMutationHookResult = ReturnType<typeof useDeleteCommunityMutation>;
export type DeleteCommunityMutationResult = Apollo.MutationResult<DeleteCommunityMutation>;
export type DeleteCommunityMutationOptions = Apollo.BaseMutationOptions<
  DeleteCommunityMutation,
  DeleteCommunityMutationVariables
>;
export const CreateCommunityDocument = gql`
  mutation CreateCommunity(
    $name: String!
    $companyId: ID!
    $mission: String
    $logo: String
    $rommance: String
  ) {
    createCommunity(
      name: $name
      companyId: $companyId
      mission: $mission
      logo: $logo
      rommance: $rommance
    ) {
      id
      name
      mission
      logo
      rommance
    }
  }
`;
export type CreateCommunityMutationFn = Apollo.MutationFunction<
  CreateCommunityMutation,
  CreateCommunityMutationVariables
>;

/**
 * __useCreateCommunityMutation__
 *
 * To run a mutation, you first call `useCreateCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityMutation, { data, loading, error }] = useCreateCommunityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *      mission: // value for 'mission'
 *      logo: // value for 'logo'
 *      rommance: // value for 'rommance'
 *   },
 * });
 */
export function useCreateCommunityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommunityMutation,
    CreateCommunityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCommunityMutation, CreateCommunityMutationVariables>(
    CreateCommunityDocument,
    options,
  );
}
export type CreateCommunityMutationHookResult = ReturnType<typeof useCreateCommunityMutation>;
export type CreateCommunityMutationResult = Apollo.MutationResult<CreateCommunityMutation>;
export type CreateCommunityMutationOptions = Apollo.BaseMutationOptions<
  CreateCommunityMutation,
  CreateCommunityMutationVariables
>;
export const RemoveCompanyMemberToCommunityDocument = gql`
  mutation RemoveCompanyMemberToCommunity($userCommunityId: ID!) {
    removeCompanyMemberToCommunity(userCommunityId: $userCommunityId) {
      id
    }
  }
`;
export type RemoveCompanyMemberToCommunityMutationFn = Apollo.MutationFunction<
  RemoveCompanyMemberToCommunityMutation,
  RemoveCompanyMemberToCommunityMutationVariables
>;

/**
 * __useRemoveCompanyMemberToCommunityMutation__
 *
 * To run a mutation, you first call `useRemoveCompanyMemberToCommunityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCompanyMemberToCommunityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCompanyMemberToCommunityMutation, { data, loading, error }] = useRemoveCompanyMemberToCommunityMutation({
 *   variables: {
 *      userCommunityId: // value for 'userCommunityId'
 *   },
 * });
 */
export function useRemoveCompanyMemberToCommunityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCompanyMemberToCommunityMutation,
    RemoveCompanyMemberToCommunityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCompanyMemberToCommunityMutation,
    RemoveCompanyMemberToCommunityMutationVariables
  >(RemoveCompanyMemberToCommunityDocument, options);
}
export type RemoveCompanyMemberToCommunityMutationHookResult = ReturnType<
  typeof useRemoveCompanyMemberToCommunityMutation
>;
export type RemoveCompanyMemberToCommunityMutationResult =
  Apollo.MutationResult<RemoveCompanyMemberToCommunityMutation>;
export type RemoveCompanyMemberToCommunityMutationOptions = Apollo.BaseMutationOptions<
  RemoveCompanyMemberToCommunityMutation,
  RemoveCompanyMemberToCommunityMutationVariables
>;
export const UpdateTeamMemberRoleDocument = gql`
  mutation UpdateTeamMemberRole($userCommunityId: ID!, $role: CommunityRole!) {
    updateTeamMemberRole(userCommunityId: $userCommunityId, role: $role) {
      id
      role
    }
  }
`;
export type UpdateTeamMemberRoleMutationFn = Apollo.MutationFunction<
  UpdateTeamMemberRoleMutation,
  UpdateTeamMemberRoleMutationVariables
>;

/**
 * __useUpdateTeamMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMemberRoleMutation, { data, loading, error }] = useUpdateTeamMemberRoleMutation({
 *   variables: {
 *      userCommunityId: // value for 'userCommunityId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateTeamMemberRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTeamMemberRoleMutation,
    UpdateTeamMemberRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTeamMemberRoleMutation, UpdateTeamMemberRoleMutationVariables>(
    UpdateTeamMemberRoleDocument,
    options,
  );
}
export type UpdateTeamMemberRoleMutationHookResult = ReturnType<
  typeof useUpdateTeamMemberRoleMutation
>;
export type UpdateTeamMemberRoleMutationResult =
  Apollo.MutationResult<UpdateTeamMemberRoleMutation>;
export type UpdateTeamMemberRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateTeamMemberRoleMutation,
  UpdateTeamMemberRoleMutationVariables
>;
export const CreateCommunityArchiveDocument = gql`
  mutation CreateCommunityArchive(
    $id: ID!
    $name: String!
    $path: String!
    $type: String
    $size: Int
    $createdAt: DateTime
  ) {
    createCommunityArchive(
      id: $id
      name: $name
      path: $path
      type: $type
      size: $size
      createdAt: $createdAt
    ) {
      id
      archives {
        id
        path
        publicUrl
        createdAt
      }
    }
  }
`;
export type CreateCommunityArchiveMutationFn = Apollo.MutationFunction<
  CreateCommunityArchiveMutation,
  CreateCommunityArchiveMutationVariables
>;

/**
 * __useCreateCommunityArchiveMutation__
 *
 * To run a mutation, you first call `useCreateCommunityArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityArchiveMutation, { data, loading, error }] = useCreateCommunityArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      path: // value for 'path'
 *      type: // value for 'type'
 *      size: // value for 'size'
 *      createdAt: // value for 'createdAt'
 *   },
 * });
 */
export function useCreateCommunityArchiveMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommunityArchiveMutation,
    CreateCommunityArchiveMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCommunityArchiveMutation,
    CreateCommunityArchiveMutationVariables
  >(CreateCommunityArchiveDocument, options);
}
export type CreateCommunityArchiveMutationHookResult = ReturnType<
  typeof useCreateCommunityArchiveMutation
>;
export type CreateCommunityArchiveMutationResult =
  Apollo.MutationResult<CreateCommunityArchiveMutation>;
export type CreateCommunityArchiveMutationOptions = Apollo.BaseMutationOptions<
  CreateCommunityArchiveMutation,
  CreateCommunityArchiveMutationVariables
>;
export const RemoveCommunityInPendingInvitationDocument = gql`
  mutation RemoveCommunityInPendingInvitation($id: ID!, $communityId: ID!) {
    removeCommunityInPendingInvitation(id: $id, communityId: $communityId) {
      id
      pendingTeamsIds
    }
  }
`;
export type RemoveCommunityInPendingInvitationMutationFn = Apollo.MutationFunction<
  RemoveCommunityInPendingInvitationMutation,
  RemoveCommunityInPendingInvitationMutationVariables
>;

/**
 * __useRemoveCommunityInPendingInvitationMutation__
 *
 * To run a mutation, you first call `useRemoveCommunityInPendingInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommunityInPendingInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommunityInPendingInvitationMutation, { data, loading, error }] = useRemoveCommunityInPendingInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useRemoveCommunityInPendingInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveCommunityInPendingInvitationMutation,
    RemoveCommunityInPendingInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveCommunityInPendingInvitationMutation,
    RemoveCommunityInPendingInvitationMutationVariables
  >(RemoveCommunityInPendingInvitationDocument, options);
}
export type RemoveCommunityInPendingInvitationMutationHookResult = ReturnType<
  typeof useRemoveCommunityInPendingInvitationMutation
>;
export type RemoveCommunityInPendingInvitationMutationResult =
  Apollo.MutationResult<RemoveCommunityInPendingInvitationMutation>;
export type RemoveCommunityInPendingInvitationMutationOptions = Apollo.BaseMutationOptions<
  RemoveCommunityInPendingInvitationMutation,
  RemoveCommunityInPendingInvitationMutationVariables
>;
export const UpdateCommunityRoleInPendingInvitationDocument = gql`
  mutation UpdateCommunityRoleInPendingInvitation($id: ID!, $value: CommunityRole!) {
    updateCommunityRoleInPendingInvitation(id: $id, value: $value) {
      id
      communityMemberRole
    }
  }
`;
export type UpdateCommunityRoleInPendingInvitationMutationFn = Apollo.MutationFunction<
  UpdateCommunityRoleInPendingInvitationMutation,
  UpdateCommunityRoleInPendingInvitationMutationVariables
>;

/**
 * __useUpdateCommunityRoleInPendingInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityRoleInPendingInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityRoleInPendingInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityRoleInPendingInvitationMutation, { data, loading, error }] = useUpdateCommunityRoleInPendingInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateCommunityRoleInPendingInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommunityRoleInPendingInvitationMutation,
    UpdateCommunityRoleInPendingInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCommunityRoleInPendingInvitationMutation,
    UpdateCommunityRoleInPendingInvitationMutationVariables
  >(UpdateCommunityRoleInPendingInvitationDocument, options);
}
export type UpdateCommunityRoleInPendingInvitationMutationHookResult = ReturnType<
  typeof useUpdateCommunityRoleInPendingInvitationMutation
>;
export type UpdateCommunityRoleInPendingInvitationMutationResult =
  Apollo.MutationResult<UpdateCommunityRoleInPendingInvitationMutation>;
export type UpdateCommunityRoleInPendingInvitationMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommunityRoleInPendingInvitationMutation,
  UpdateCommunityRoleInPendingInvitationMutationVariables
>;
export const GetMyCommunityDocument = gql`
  query GetMyCommunity {
    me {
      id
      myCommunity {
        ...Community
      }
    }
  }
  ${CommunityFragmentDoc}
`;

/**
 * __useGetMyCommunityQuery__
 *
 * To run a query within a React component, call `useGetMyCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCommunityQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCommunityQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyCommunityQuery, GetMyCommunityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyCommunityQuery, GetMyCommunityQueryVariables>(
    GetMyCommunityDocument,
    options,
  );
}
export function useGetMyCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyCommunityQuery, GetMyCommunityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyCommunityQuery, GetMyCommunityQueryVariables>(
    GetMyCommunityDocument,
    options,
  );
}
export type GetMyCommunityQueryHookResult = ReturnType<typeof useGetMyCommunityQuery>;
export type GetMyCommunityLazyQueryHookResult = ReturnType<typeof useGetMyCommunityLazyQuery>;
export type GetMyCommunityQueryResult = Apollo.QueryResult<
  GetMyCommunityQuery,
  GetMyCommunityQueryVariables
>;
export const GetCommunityDocument = gql`
  query GetCommunity($communityId: ID!) {
    Community(id: $communityId) {
      id
      mission
      rommance
      members {
        id
        user {
          ...UserOnPostInfo
        }
        companyMemberId
        companyMember {
          id
          currentMotto {
            id
            content
          }
          currentProfessionnalWeather {
            id
            title
            icon
          }
        }
      }
      memberCount
      memberRequestCount
    }
  }
  ${UserOnPostInfoFragmentDoc}
`;

/**
 * __useGetCommunityQuery__
 *
 * To run a query within a React component, call `useGetCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetCommunityQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommunityQuery, GetCommunityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommunityQuery, GetCommunityQueryVariables>(
    GetCommunityDocument,
    options,
  );
}
export function useGetCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCommunityQuery, GetCommunityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommunityQuery, GetCommunityQueryVariables>(
    GetCommunityDocument,
    options,
  );
}
export type GetCommunityQueryHookResult = ReturnType<typeof useGetCommunityQuery>;
export type GetCommunityLazyQueryHookResult = ReturnType<typeof useGetCommunityLazyQuery>;
export type GetCommunityQueryResult = Apollo.QueryResult<
  GetCommunityQuery,
  GetCommunityQueryVariables
>;
export const CommunityDocument = gql`
  query Community($communityId: ID!) {
    community: Community(id: $communityId) {
      ...SimpleCommunityInfo
    }
  }
  ${SimpleCommunityInfoFragmentDoc}
`;

/**
 * __useCommunityQuery__
 *
 * To run a query within a React component, call `useCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useCommunityQuery(
  baseOptions: Apollo.QueryHookOptions<CommunityQuery, CommunityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, options);
}
export function useCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CommunityQuery, CommunityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CommunityQuery, CommunityQueryVariables>(CommunityDocument, options);
}
export type CommunityQueryHookResult = ReturnType<typeof useCommunityQuery>;
export type CommunityLazyQueryHookResult = ReturnType<typeof useCommunityLazyQuery>;
export type CommunityQueryResult = Apollo.QueryResult<CommunityQuery, CommunityQueryVariables>;
export const GetCommunityActionsDocument = gql`
  query GetCommunityActions($id: ID!, $orderBy: String) {
    Community(id: $id) {
      id
      actions(orderBy: $orderBy) {
        ...ActionInfo
        responsibles {
          id
          user {
            ...UserOnPostInfo
          }
        }
        indicator {
          id
        }
        createdByCompanyMember {
          id
          user {
            ...UserOnPostInfo
          }
        }
        post {
          id
        }
        project {
          id
        }
      }
    }
  }
  ${ActionInfoFragmentDoc}
  ${UserOnPostInfoFragmentDoc}
`;

/**
 * __useGetCommunityActionsQuery__
 *
 * To run a query within a React component, call `useGetCommunityActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityActionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCommunityActionsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommunityActionsQuery, GetCommunityActionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommunityActionsQuery, GetCommunityActionsQueryVariables>(
    GetCommunityActionsDocument,
    options,
  );
}
export function useGetCommunityActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunityActionsQuery,
    GetCommunityActionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommunityActionsQuery, GetCommunityActionsQueryVariables>(
    GetCommunityActionsDocument,
    options,
  );
}
export type GetCommunityActionsQueryHookResult = ReturnType<typeof useGetCommunityActionsQuery>;
export type GetCommunityActionsLazyQueryHookResult = ReturnType<
  typeof useGetCommunityActionsLazyQuery
>;
export type GetCommunityActionsQueryResult = Apollo.QueryResult<
  GetCommunityActionsQuery,
  GetCommunityActionsQueryVariables
>;
export const CurrentCommunityDocument = gql`
  query CurrentCommunity {
    currentCommunity {
      id
      name
      slug
      logo
      logoPublicUrl
    }
  }
`;

/**
 * __useCurrentCommunityQuery__
 *
 * To run a query within a React component, call `useCurrentCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCommunityQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCommunityQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentCommunityQuery, CurrentCommunityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentCommunityQuery, CurrentCommunityQueryVariables>(
    CurrentCommunityDocument,
    options,
  );
}
export function useCurrentCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CurrentCommunityQuery, CurrentCommunityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentCommunityQuery, CurrentCommunityQueryVariables>(
    CurrentCommunityDocument,
    options,
  );
}
export type CurrentCommunityQueryHookResult = ReturnType<typeof useCurrentCommunityQuery>;
export type CurrentCommunityLazyQueryHookResult = ReturnType<typeof useCurrentCommunityLazyQuery>;
export type CurrentCommunityQueryResult = Apollo.QueryResult<
  CurrentCommunityQuery,
  CurrentCommunityQueryVariables
>;
export const GetActionsByPostIdDocument = gql`
  query GetActionsByPostId($id: ID!) {
    getActionsByPostId(id: $id) {
      id
      title
      description
      targetDate
      isCompleted
      responsibles {
        id
        user {
          ...UserOnPostInfo
        }
      }
      createdAt
    }
  }
  ${UserOnPostInfoFragmentDoc}
`;

/**
 * __useGetActionsByPostIdQuery__
 *
 * To run a query within a React component, call `useGetActionsByPostIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionsByPostIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionsByPostIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetActionsByPostIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetActionsByPostIdQuery, GetActionsByPostIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActionsByPostIdQuery, GetActionsByPostIdQueryVariables>(
    GetActionsByPostIdDocument,
    options,
  );
}
export function useGetActionsByPostIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetActionsByPostIdQuery,
    GetActionsByPostIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActionsByPostIdQuery, GetActionsByPostIdQueryVariables>(
    GetActionsByPostIdDocument,
    options,
  );
}
export type GetActionsByPostIdQueryHookResult = ReturnType<typeof useGetActionsByPostIdQuery>;
export type GetActionsByPostIdLazyQueryHookResult = ReturnType<
  typeof useGetActionsByPostIdLazyQuery
>;
export type GetActionsByPostIdQueryResult = Apollo.QueryResult<
  GetActionsByPostIdQuery,
  GetActionsByPostIdQueryVariables
>;
export const GetAllActionsDocument = gql`
  query GetAllActions(
    $postId: ID
    $projectId: ID
    $filter: ActionFilter
    $page: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
    $targedDate: DateRange
  ) {
    actions: allActions(
      postId: $postId
      projectId: $projectId
      filter: $filter
      page: $page
      perPage: $perPage
      sortOrder: $sortOrder
      sortField: $sortField
      targedDate: $targedDate
    ) {
      ...ActionInfo
      projectId
      project {
        id
      }
      createdBy {
        ...UserOnPostInfo
      }
      createdByCompanyMember {
        id
        user {
          ...UserOnPostInfo
        }
      }
      responsibles {
        id
        user {
          ...UserOnPostInfo
        }
      }
      indicator {
        id
      }
      community {
        ...Community
      }
    }
    total: _allActionsMeta(postId: $postId, projectId: $projectId, filter: $filter) {
      count
    }
  }
  ${ActionInfoFragmentDoc}
  ${UserOnPostInfoFragmentDoc}
  ${CommunityFragmentDoc}
`;

/**
 * __useGetAllActionsQuery__
 *
 * To run a query within a React component, call `useGetAllActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllActionsQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      projectId: // value for 'projectId'
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      targedDate: // value for 'targedDate'
 *   },
 * });
 */
export function useGetAllActionsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllActionsQuery, GetAllActionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllActionsQuery, GetAllActionsQueryVariables>(
    GetAllActionsDocument,
    options,
  );
}
export function useGetAllActionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllActionsQuery, GetAllActionsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllActionsQuery, GetAllActionsQueryVariables>(
    GetAllActionsDocument,
    options,
  );
}
export type GetAllActionsQueryHookResult = ReturnType<typeof useGetAllActionsQuery>;
export type GetAllActionsLazyQueryHookResult = ReturnType<typeof useGetAllActionsLazyQuery>;
export type GetAllActionsQueryResult = Apollo.QueryResult<
  GetAllActionsQuery,
  GetAllActionsQueryVariables
>;
export const GetCommunityMembersDocument = gql`
  query GetCommunityMembers($id: ID!) {
    Community(id: $id) {
      id
      name
      members {
        id
        role
        companyMember {
          id
          currentProfessionnalWeather {
            id
            title
            icon
          }
          user {
            ...UserOnPostInfo
          }
        }
      }
    }
  }
  ${UserOnPostInfoFragmentDoc}
`;

/**
 * __useGetCommunityMembersQuery__
 *
 * To run a query within a React component, call `useGetCommunityMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityMembersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommunityMembersQuery(
  baseOptions: Apollo.QueryHookOptions<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>(
    GetCommunityMembersDocument,
    options,
  );
}
export function useGetCommunityMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunityMembersQuery,
    GetCommunityMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommunityMembersQuery, GetCommunityMembersQueryVariables>(
    GetCommunityMembersDocument,
    options,
  );
}
export type GetCommunityMembersQueryHookResult = ReturnType<typeof useGetCommunityMembersQuery>;
export type GetCommunityMembersLazyQueryHookResult = ReturnType<
  typeof useGetCommunityMembersLazyQuery
>;
export type GetCommunityMembersQueryResult = Apollo.QueryResult<
  GetCommunityMembersQuery,
  GetCommunityMembersQueryVariables
>;
export const GetCommunityByCompanyIdDocument = gql`
  query GetCommunityByCompanyId(
    $companyId: ID
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CommunityFilter
  ) {
    getCommunityByCompanyId(
      companyId: $companyId
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      name
      slug
      logo
      logoPublicUrl
      mission
      rommance
      indicators {
        id
        name
        value
        level
        description
        date
        trend
        createdAt
      }
      problems {
        id
        title
        who
        what
        how
        where
        when
        impact
        problemCategory {
          title
        }
        problemLevel
        demand
        communities {
          id
        }
        experts {
          id
        }
        companyMemberExperts {
          id
        }
        createdAt
      }
      actions {
        id
        description
        targetDate
        responsibles {
          id
        }
        actionType
        createdAt
      }
      projects {
        id
      }
      members {
        id
        companyMemberId
        companyMember {
          id
          currentProfessionnalWeather {
            id
            title
            icon
          }
          user {
            ...CommunityMembersUserInfo
          }
        }
        role
      }
    }
    currentWholeCompany {
      id
      slug
    }
  }
  ${CommunityMembersUserInfoFragmentDoc}
`;

/**
 * __useGetCommunityByCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCommunityByCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityByCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityByCompanyIdQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCommunityByCompanyIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCommunityByCompanyIdQuery,
    GetCommunityByCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommunityByCompanyIdQuery, GetCommunityByCompanyIdQueryVariables>(
    GetCommunityByCompanyIdDocument,
    options,
  );
}
export function useGetCommunityByCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunityByCompanyIdQuery,
    GetCommunityByCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommunityByCompanyIdQuery, GetCommunityByCompanyIdQueryVariables>(
    GetCommunityByCompanyIdDocument,
    options,
  );
}
export type GetCommunityByCompanyIdQueryHookResult = ReturnType<
  typeof useGetCommunityByCompanyIdQuery
>;
export type GetCommunityByCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCommunityByCompanyIdLazyQuery
>;
export type GetCommunityByCompanyIdQueryResult = Apollo.QueryResult<
  GetCommunityByCompanyIdQuery,
  GetCommunityByCompanyIdQueryVariables
>;
export const GetMembersByCommunityIdDocument = gql`
  query GetMembersByCommunityId($communityId: ID!) {
    community: Community(id: $communityId) {
      id
      name
      members {
        id
        role
        companyMember {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
          currentProfessionnalWeather {
            id
            title
            icon
          }
        }
      }
    }
  }
`;

/**
 * __useGetMembersByCommunityIdQuery__
 *
 * To run a query within a React component, call `useGetMembersByCommunityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMembersByCommunityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMembersByCommunityIdQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetMembersByCommunityIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMembersByCommunityIdQuery,
    GetMembersByCommunityIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMembersByCommunityIdQuery, GetMembersByCommunityIdQueryVariables>(
    GetMembersByCommunityIdDocument,
    options,
  );
}
export function useGetMembersByCommunityIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMembersByCommunityIdQuery,
    GetMembersByCommunityIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMembersByCommunityIdQuery, GetMembersByCommunityIdQueryVariables>(
    GetMembersByCommunityIdDocument,
    options,
  );
}
export type GetMembersByCommunityIdQueryHookResult = ReturnType<
  typeof useGetMembersByCommunityIdQuery
>;
export type GetMembersByCommunityIdLazyQueryHookResult = ReturnType<
  typeof useGetMembersByCommunityIdLazyQuery
>;
export type GetMembersByCommunityIdQueryResult = Apollo.QueryResult<
  GetMembersByCommunityIdQuery,
  GetMembersByCommunityIdQueryVariables
>;
export const GetCommunityArchivesDocument = gql`
  query GetCommunityArchives($id: ID!) {
    community: Community(id: $id) {
      id
      archives {
        id
        name
        path
        publicUrl
        createdAt
      }
    }
  }
`;

/**
 * __useGetCommunityArchivesQuery__
 *
 * To run a query within a React component, call `useGetCommunityArchivesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityArchivesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityArchivesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommunityArchivesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommunityArchivesQuery,
    GetCommunityArchivesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommunityArchivesQuery, GetCommunityArchivesQueryVariables>(
    GetCommunityArchivesDocument,
    options,
  );
}
export function useGetCommunityArchivesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunityArchivesQuery,
    GetCommunityArchivesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommunityArchivesQuery, GetCommunityArchivesQueryVariables>(
    GetCommunityArchivesDocument,
    options,
  );
}
export type GetCommunityArchivesQueryHookResult = ReturnType<typeof useGetCommunityArchivesQuery>;
export type GetCommunityArchivesLazyQueryHookResult = ReturnType<
  typeof useGetCommunityArchivesLazyQuery
>;
export type GetCommunityArchivesQueryResult = Apollo.QueryResult<
  GetCommunityArchivesQuery,
  GetCommunityArchivesQueryVariables
>;
export const GetCommunityMembersRoleBySelectedCommunityDocument = gql`
  query getCommunityMembersRoleBySelectedCommunity($teamId: ID!) {
    communityRole: getCommunityMembersRoleBySelectedCommunity(teamId: $teamId)
  }
`;

/**
 * __useGetCommunityMembersRoleBySelectedCommunityQuery__
 *
 * To run a query within a React component, call `useGetCommunityMembersRoleBySelectedCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityMembersRoleBySelectedCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityMembersRoleBySelectedCommunityQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useGetCommunityMembersRoleBySelectedCommunityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommunityMembersRoleBySelectedCommunityQuery,
    GetCommunityMembersRoleBySelectedCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCommunityMembersRoleBySelectedCommunityQuery,
    GetCommunityMembersRoleBySelectedCommunityQueryVariables
  >(GetCommunityMembersRoleBySelectedCommunityDocument, options);
}
export function useGetCommunityMembersRoleBySelectedCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunityMembersRoleBySelectedCommunityQuery,
    GetCommunityMembersRoleBySelectedCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommunityMembersRoleBySelectedCommunityQuery,
    GetCommunityMembersRoleBySelectedCommunityQueryVariables
  >(GetCommunityMembersRoleBySelectedCommunityDocument, options);
}
export type GetCommunityMembersRoleBySelectedCommunityQueryHookResult = ReturnType<
  typeof useGetCommunityMembersRoleBySelectedCommunityQuery
>;
export type GetCommunityMembersRoleBySelectedCommunityLazyQueryHookResult = ReturnType<
  typeof useGetCommunityMembersRoleBySelectedCommunityLazyQuery
>;
export type GetCommunityMembersRoleBySelectedCommunityQueryResult = Apollo.QueryResult<
  GetCommunityMembersRoleBySelectedCommunityQuery,
  GetCommunityMembersRoleBySelectedCommunityQueryVariables
>;
export const GetCommunitiesByCompanyMemberDocument = gql`
  query getCommunitiesByCompanyMember($companyMemberId: ID, $orderBy: String) {
    communitiesByCompanyMember: getCommunitiesByCompanyMember(
      input: { companyMemberId: $companyMemberId, orderBy: $orderBy }
    ) {
      myCommunities {
        id
        name
        logoPublicUrl
      }
      otherCommunities {
        id
        name
        logoPublicUrl
      }
    }
    companyMember: getCompanyMemberById(id: $companyMemberId) {
      id
      role
      userAccess
      user {
        id
        fullName
      }
    }
  }
`;

/**
 * __useGetCommunitiesByCompanyMemberQuery__
 *
 * To run a query within a React component, call `useGetCommunitiesByCompanyMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunitiesByCompanyMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunitiesByCompanyMemberQuery({
 *   variables: {
 *      companyMemberId: // value for 'companyMemberId'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetCommunitiesByCompanyMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCommunitiesByCompanyMemberQuery,
    GetCommunitiesByCompanyMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCommunitiesByCompanyMemberQuery,
    GetCommunitiesByCompanyMemberQueryVariables
  >(GetCommunitiesByCompanyMemberDocument, options);
}
export function useGetCommunitiesByCompanyMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunitiesByCompanyMemberQuery,
    GetCommunitiesByCompanyMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommunitiesByCompanyMemberQuery,
    GetCommunitiesByCompanyMemberQueryVariables
  >(GetCommunitiesByCompanyMemberDocument, options);
}
export type GetCommunitiesByCompanyMemberQueryHookResult = ReturnType<
  typeof useGetCommunitiesByCompanyMemberQuery
>;
export type GetCommunitiesByCompanyMemberLazyQueryHookResult = ReturnType<
  typeof useGetCommunitiesByCompanyMemberLazyQuery
>;
export type GetCommunitiesByCompanyMemberQueryResult = Apollo.QueryResult<
  GetCommunitiesByCompanyMemberQuery,
  GetCommunitiesByCompanyMemberQueryVariables
>;
export const GetActionByIdDocument = gql`
  query getActionById($actionId: ID!) {
    action: actionById(id: $actionId) {
      ...ActionInfo
      createdBy {
        ...UserOnPostInfo
      }
      createdByCompanyMember {
        id
        user {
          ...UserOnPostInfo
        }
      }
      responsibles {
        id
        user {
          ...UserOnPostInfo
        }
      }
      indicator {
        id
      }
      community {
        ...Community
      }
      comments {
        ...CommentInActionFragment
      }
      project {
        id
      }
    }
  }
  ${ActionInfoFragmentDoc}
  ${UserOnPostInfoFragmentDoc}
  ${CommunityFragmentDoc}
  ${CommentInActionFragmentFragmentDoc}
`;

/**
 * __useGetActionByIdQuery__
 *
 * To run a query within a React component, call `useGetActionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActionByIdQuery({
 *   variables: {
 *      actionId: // value for 'actionId'
 *   },
 * });
 */
export function useGetActionByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetActionByIdQuery, GetActionByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActionByIdQuery, GetActionByIdQueryVariables>(
    GetActionByIdDocument,
    options,
  );
}
export function useGetActionByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActionByIdQuery, GetActionByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActionByIdQuery, GetActionByIdQueryVariables>(
    GetActionByIdDocument,
    options,
  );
}
export type GetActionByIdQueryHookResult = ReturnType<typeof useGetActionByIdQuery>;
export type GetActionByIdLazyQueryHookResult = ReturnType<typeof useGetActionByIdLazyQuery>;
export type GetActionByIdQueryResult = Apollo.QueryResult<
  GetActionByIdQuery,
  GetActionByIdQueryVariables
>;
export const UpdateMyTotalCoinsDocument = gql`
  mutation updateMyTotalCoins($cost: Int) {
    updateMyTotalCoins(cost: $cost)
  }
`;
export type UpdateMyTotalCoinsMutationFn = Apollo.MutationFunction<
  UpdateMyTotalCoinsMutation,
  UpdateMyTotalCoinsMutationVariables
>;

/**
 * __useUpdateMyTotalCoinsMutation__
 *
 * To run a mutation, you first call `useUpdateMyTotalCoinsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyTotalCoinsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyTotalCoinsMutation, { data, loading, error }] = useUpdateMyTotalCoinsMutation({
 *   variables: {
 *      cost: // value for 'cost'
 *   },
 * });
 */
export function useUpdateMyTotalCoinsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMyTotalCoinsMutation,
    UpdateMyTotalCoinsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMyTotalCoinsMutation, UpdateMyTotalCoinsMutationVariables>(
    UpdateMyTotalCoinsDocument,
    options,
  );
}
export type UpdateMyTotalCoinsMutationHookResult = ReturnType<typeof useUpdateMyTotalCoinsMutation>;
export type UpdateMyTotalCoinsMutationResult = Apollo.MutationResult<UpdateMyTotalCoinsMutation>;
export type UpdateMyTotalCoinsMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyTotalCoinsMutation,
  UpdateMyTotalCoinsMutationVariables
>;
export const SwitchCompanyDocument = gql`
  mutation SwitchCompany($newSlug: String!, $timezone: UserTimezoneInput!) {
    payload: switchCompany(newSlug: $newSlug, timezone: $timezone) {
      companyMember {
        id
        user {
          id
          firstName
          lastName
          email
          photoUrl
        }
        company {
          id
          slug
        }
        userPreference {
          id
          userTimezone {
            id
            tzCode
          }
          enableShowEntryTunnel
          language {
            id
            key
          }
        }
      }
      streamToken
      success
      message
    }
  }
`;
export type SwitchCompanyMutationFn = Apollo.MutationFunction<
  SwitchCompanyMutation,
  SwitchCompanyMutationVariables
>;

/**
 * __useSwitchCompanyMutation__
 *
 * To run a mutation, you first call `useSwitchCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchCompanyMutation, { data, loading, error }] = useSwitchCompanyMutation({
 *   variables: {
 *      newSlug: // value for 'newSlug'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useSwitchCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<SwitchCompanyMutation, SwitchCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SwitchCompanyMutation, SwitchCompanyMutationVariables>(
    SwitchCompanyDocument,
    options,
  );
}
export type SwitchCompanyMutationHookResult = ReturnType<typeof useSwitchCompanyMutation>;
export type SwitchCompanyMutationResult = Apollo.MutationResult<SwitchCompanyMutation>;
export type SwitchCompanyMutationOptions = Apollo.BaseMutationOptions<
  SwitchCompanyMutation,
  SwitchCompanyMutationVariables
>;
export const UserInvitationDocument = gql`
  mutation UserInvitation(
    $targetEmail: String!
    $pendingTeamsIds: [ID!]
    $hiringDate: DateTime
    $role: MemberRole
    $userAccess: MainRole
  ) {
    userInvitation(
      targetEmail: $targetEmail
      pendingTeamsIds: $pendingTeamsIds
      hiringDate: $hiringDate
      role: $role
      userAccess: $userAccess
    ) {
      success
      message
      accessToken
    }
  }
`;
export type UserInvitationMutationFn = Apollo.MutationFunction<
  UserInvitationMutation,
  UserInvitationMutationVariables
>;

/**
 * __useUserInvitationMutation__
 *
 * To run a mutation, you first call `useUserInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userInvitationMutation, { data, loading, error }] = useUserInvitationMutation({
 *   variables: {
 *      targetEmail: // value for 'targetEmail'
 *      pendingTeamsIds: // value for 'pendingTeamsIds'
 *      hiringDate: // value for 'hiringDate'
 *      role: // value for 'role'
 *      userAccess: // value for 'userAccess'
 *   },
 * });
 */
export function useUserInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<UserInvitationMutation, UserInvitationMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UserInvitationMutation, UserInvitationMutationVariables>(
    UserInvitationDocument,
    options,
  );
}
export type UserInvitationMutationHookResult = ReturnType<typeof useUserInvitationMutation>;
export type UserInvitationMutationResult = Apollo.MutationResult<UserInvitationMutation>;
export type UserInvitationMutationOptions = Apollo.BaseMutationOptions<
  UserInvitationMutation,
  UserInvitationMutationVariables
>;
export const ResendInvitationDocument = gql`
  mutation ResendInvitation($targetEmail: String!, $token: String!) {
    resendInvitation(targetEmail: $targetEmail, token: $token) {
      success
      message
      accessToken
    }
  }
`;
export type ResendInvitationMutationFn = Apollo.MutationFunction<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      targetEmail: // value for 'targetEmail'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInvitationMutation,
    ResendInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(
    ResendInvitationDocument,
    options,
  );
}
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>;
export const DeletePendingInvitationDocument = gql`
  mutation DeletePendingInvitation($id: ID!) {
    deletePendingInvitation(id: $id) {
      id
    }
  }
`;
export type DeletePendingInvitationMutationFn = Apollo.MutationFunction<
  DeletePendingInvitationMutation,
  DeletePendingInvitationMutationVariables
>;

/**
 * __useDeletePendingInvitationMutation__
 *
 * To run a mutation, you first call `useDeletePendingInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePendingInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePendingInvitationMutation, { data, loading, error }] = useDeletePendingInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePendingInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePendingInvitationMutation,
    DeletePendingInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeletePendingInvitationMutation,
    DeletePendingInvitationMutationVariables
  >(DeletePendingInvitationDocument, options);
}
export type DeletePendingInvitationMutationHookResult = ReturnType<
  typeof useDeletePendingInvitationMutation
>;
export type DeletePendingInvitationMutationResult =
  Apollo.MutationResult<DeletePendingInvitationMutation>;
export type DeletePendingInvitationMutationOptions = Apollo.BaseMutationOptions<
  DeletePendingInvitationMutation,
  DeletePendingInvitationMutationVariables
>;
export const UpdateProfileDocument = gql`
  mutation UpdateProfile(
    $id: ID!
    $firstName: String
    $lastName: String
    $jobTitle: String
    $personality: String
  ) {
    updateProfile(
      input: {
        id: $id
        firstName: $firstName
        lastName: $lastName
        jobTitle: $jobTitle
        personality: $personality
      }
    ) {
      id
      jobTitle
      user {
        id
        firstName
        lastName
        personality
      }
    }
  }
`;
export type UpdateProfileMutationFn = Apollo.MutationFunction<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      jobTitle: // value for 'jobTitle'
 *      personality: // value for 'personality'
 *   },
 * });
 */
export function useUpdateProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(
    UpdateProfileDocument,
    options,
  );
}
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateProfileMutation,
  UpdateProfileMutationVariables
>;
export const ChangeSupervisorDocument = gql`
  mutation ChangeSupervisor($companyMemberId: ID!, $supervisorId: ID) {
    changeSupervisor(companyMemberId: $companyMemberId, supervisorId: $supervisorId) {
      id
      supervisors {
        id
      }
    }
  }
`;
export type ChangeSupervisorMutationFn = Apollo.MutationFunction<
  ChangeSupervisorMutation,
  ChangeSupervisorMutationVariables
>;

/**
 * __useChangeSupervisorMutation__
 *
 * To run a mutation, you first call `useChangeSupervisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeSupervisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeSupervisorMutation, { data, loading, error }] = useChangeSupervisorMutation({
 *   variables: {
 *      companyMemberId: // value for 'companyMemberId'
 *      supervisorId: // value for 'supervisorId'
 *   },
 * });
 */
export function useChangeSupervisorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeSupervisorMutation,
    ChangeSupervisorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangeSupervisorMutation, ChangeSupervisorMutationVariables>(
    ChangeSupervisorDocument,
    options,
  );
}
export type ChangeSupervisorMutationHookResult = ReturnType<typeof useChangeSupervisorMutation>;
export type ChangeSupervisorMutationResult = Apollo.MutationResult<ChangeSupervisorMutation>;
export type ChangeSupervisorMutationOptions = Apollo.BaseMutationOptions<
  ChangeSupervisorMutation,
  ChangeSupervisorMutationVariables
>;
export const UpdateCompanyMemberDocument = gql`
  mutation UpdateCompanyMember(
    $id: ID!
    $role: MemberRole
    $userAccess: MainRole
    $hiringDate: DateTime
    $businessUnitId: String
    $suspended: Boolean
  ) {
    updateCompanyMember(
      id: $id
      role: $role
      userAccess: $userAccess
      hiringDate: $hiringDate
      businessUnitId: $businessUnitId
      suspended: $suspended
    ) {
      id
      role
      userAccess
      hiringDate
      suspended
    }
  }
`;
export type UpdateCompanyMemberMutationFn = Apollo.MutationFunction<
  UpdateCompanyMemberMutation,
  UpdateCompanyMemberMutationVariables
>;

/**
 * __useUpdateCompanyMemberMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMemberMutation, { data, loading, error }] = useUpdateCompanyMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *      userAccess: // value for 'userAccess'
 *      hiringDate: // value for 'hiringDate'
 *      businessUnitId: // value for 'businessUnitId'
 *      suspended: // value for 'suspended'
 *   },
 * });
 */
export function useUpdateCompanyMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyMemberMutation,
    UpdateCompanyMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyMemberMutation, UpdateCompanyMemberMutationVariables>(
    UpdateCompanyMemberDocument,
    options,
  );
}
export type UpdateCompanyMemberMutationHookResult = ReturnType<
  typeof useUpdateCompanyMemberMutation
>;
export type UpdateCompanyMemberMutationResult = Apollo.MutationResult<UpdateCompanyMemberMutation>;
export type UpdateCompanyMemberMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMemberMutation,
  UpdateCompanyMemberMutationVariables
>;
export const UpdateCompanyDocument = gql`
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      description
      email
      logo
      name
      romance
      leaveWeekendsCount
    }
  }
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(
    UpdateCompanyDocument,
    options,
  );
}
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const UpdateInvitationDocument = gql`
  mutation UpdateInvitation(
    $id: ID!
    $companyMemberRole: MemberRole
    $companyMemberAccess: MainRole
    $communityMemberRole: CommunityRole
    $pendingTeamsIds: [ID!]
    $hiringDate: DateTime
  ) {
    updateInvitation(
      id: $id
      companyMemberRole: $companyMemberRole
      companyMemberAccess: $companyMemberAccess
      pendingTeamsIds: $pendingTeamsIds
      communityMemberRole: $communityMemberRole
      hiringDate: $hiringDate
    ) {
      id
      companyMemberRole
      companyMemberAccess
      pendingTeamsIds
      communityMemberRole
      hiringDate
    }
  }
`;
export type UpdateInvitationMutationFn = Apollo.MutationFunction<
  UpdateInvitationMutation,
  UpdateInvitationMutationVariables
>;

/**
 * __useUpdateInvitationMutation__
 *
 * To run a mutation, you first call `useUpdateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvitationMutation, { data, loading, error }] = useUpdateInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      companyMemberRole: // value for 'companyMemberRole'
 *      companyMemberAccess: // value for 'companyMemberAccess'
 *      communityMemberRole: // value for 'communityMemberRole'
 *      pendingTeamsIds: // value for 'pendingTeamsIds'
 *      hiringDate: // value for 'hiringDate'
 *   },
 * });
 */
export function useUpdateInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateInvitationMutation,
    UpdateInvitationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInvitationMutation, UpdateInvitationMutationVariables>(
    UpdateInvitationDocument,
    options,
  );
}
export type UpdateInvitationMutationHookResult = ReturnType<typeof useUpdateInvitationMutation>;
export type UpdateInvitationMutationResult = Apollo.MutationResult<UpdateInvitationMutation>;
export type UpdateInvitationMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvitationMutation,
  UpdateInvitationMutationVariables
>;
export const SetCurrentImageDocument = gql`
  mutation SetCurrentImage($path: String) {
    setCurrentImage(path: $path) {
      id
      currentImagePath
    }
  }
`;
export type SetCurrentImageMutationFn = Apollo.MutationFunction<
  SetCurrentImageMutation,
  SetCurrentImageMutationVariables
>;

/**
 * __useSetCurrentImageMutation__
 *
 * To run a mutation, you first call `useSetCurrentImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCurrentImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCurrentImageMutation, { data, loading, error }] = useSetCurrentImageMutation({
 *   variables: {
 *      path: // value for 'path'
 *   },
 * });
 */
export function useSetCurrentImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCurrentImageMutation,
    SetCurrentImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetCurrentImageMutation, SetCurrentImageMutationVariables>(
    SetCurrentImageDocument,
    options,
  );
}
export type SetCurrentImageMutationHookResult = ReturnType<typeof useSetCurrentImageMutation>;
export type SetCurrentImageMutationResult = Apollo.MutationResult<SetCurrentImageMutation>;
export type SetCurrentImageMutationOptions = Apollo.BaseMutationOptions<
  SetCurrentImageMutation,
  SetCurrentImageMutationVariables
>;
export const UpdateCeoWordDocument = gql`
  mutation UpdateCeoWord($ceoWord: String!) {
    updateCeoWord(ceoWord: $ceoWord) {
      id
      ceoWord
    }
  }
`;
export type UpdateCeoWordMutationFn = Apollo.MutationFunction<
  UpdateCeoWordMutation,
  UpdateCeoWordMutationVariables
>;

/**
 * __useUpdateCeoWordMutation__
 *
 * To run a mutation, you first call `useUpdateCeoWordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCeoWordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCeoWordMutation, { data, loading, error }] = useUpdateCeoWordMutation({
 *   variables: {
 *      ceoWord: // value for 'ceoWord'
 *   },
 * });
 */
export function useUpdateCeoWordMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCeoWordMutation, UpdateCeoWordMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCeoWordMutation, UpdateCeoWordMutationVariables>(
    UpdateCeoWordDocument,
    options,
  );
}
export type UpdateCeoWordMutationHookResult = ReturnType<typeof useUpdateCeoWordMutation>;
export type UpdateCeoWordMutationResult = Apollo.MutationResult<UpdateCeoWordMutation>;
export type UpdateCeoWordMutationOptions = Apollo.BaseMutationOptions<
  UpdateCeoWordMutation,
  UpdateCeoWordMutationVariables
>;
export const SetEnableShowEntryTunnelValueDocument = gql`
  mutation SetEnableShowEntryTunnelValue($value: Boolean!) {
    setEnableShowEntryTunnelValue(value: $value) {
      id
      enableShowEntryTunnel
    }
  }
`;
export type SetEnableShowEntryTunnelValueMutationFn = Apollo.MutationFunction<
  SetEnableShowEntryTunnelValueMutation,
  SetEnableShowEntryTunnelValueMutationVariables
>;

/**
 * __useSetEnableShowEntryTunnelValueMutation__
 *
 * To run a mutation, you first call `useSetEnableShowEntryTunnelValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEnableShowEntryTunnelValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEnableShowEntryTunnelValueMutation, { data, loading, error }] = useSetEnableShowEntryTunnelValueMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetEnableShowEntryTunnelValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEnableShowEntryTunnelValueMutation,
    SetEnableShowEntryTunnelValueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetEnableShowEntryTunnelValueMutation,
    SetEnableShowEntryTunnelValueMutationVariables
  >(SetEnableShowEntryTunnelValueDocument, options);
}
export type SetEnableShowEntryTunnelValueMutationHookResult = ReturnType<
  typeof useSetEnableShowEntryTunnelValueMutation
>;
export type SetEnableShowEntryTunnelValueMutationResult =
  Apollo.MutationResult<SetEnableShowEntryTunnelValueMutation>;
export type SetEnableShowEntryTunnelValueMutationOptions = Apollo.BaseMutationOptions<
  SetEnableShowEntryTunnelValueMutation,
  SetEnableShowEntryTunnelValueMutationVariables
>;
export const SetEnableShowExitTunnelValueDocument = gql`
  mutation SetEnableShowExitTunnelValue($value: Boolean!) {
    setEnableShowExitTunnelValue(value: $value) {
      id
      enableShowExitTunnel
    }
  }
`;
export type SetEnableShowExitTunnelValueMutationFn = Apollo.MutationFunction<
  SetEnableShowExitTunnelValueMutation,
  SetEnableShowExitTunnelValueMutationVariables
>;

/**
 * __useSetEnableShowExitTunnelValueMutation__
 *
 * To run a mutation, you first call `useSetEnableShowExitTunnelValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEnableShowExitTunnelValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEnableShowExitTunnelValueMutation, { data, loading, error }] = useSetEnableShowExitTunnelValueMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetEnableShowExitTunnelValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEnableShowExitTunnelValueMutation,
    SetEnableShowExitTunnelValueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetEnableShowExitTunnelValueMutation,
    SetEnableShowExitTunnelValueMutationVariables
  >(SetEnableShowExitTunnelValueDocument, options);
}
export type SetEnableShowExitTunnelValueMutationHookResult = ReturnType<
  typeof useSetEnableShowExitTunnelValueMutation
>;
export type SetEnableShowExitTunnelValueMutationResult =
  Apollo.MutationResult<SetEnableShowExitTunnelValueMutation>;
export type SetEnableShowExitTunnelValueMutationOptions = Apollo.BaseMutationOptions<
  SetEnableShowExitTunnelValueMutation,
  SetEnableShowExitTunnelValueMutationVariables
>;
export const SetEntryTunnelTimeDocument = gql`
  mutation SetEntryTunnelTime($time: DateTime) {
    setEntryTunnelTime(time: $time) {
      id
      showEntryTunnelTime
    }
  }
`;
export type SetEntryTunnelTimeMutationFn = Apollo.MutationFunction<
  SetEntryTunnelTimeMutation,
  SetEntryTunnelTimeMutationVariables
>;

/**
 * __useSetEntryTunnelTimeMutation__
 *
 * To run a mutation, you first call `useSetEntryTunnelTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEntryTunnelTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEntryTunnelTimeMutation, { data, loading, error }] = useSetEntryTunnelTimeMutation({
 *   variables: {
 *      time: // value for 'time'
 *   },
 * });
 */
export function useSetEntryTunnelTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEntryTunnelTimeMutation,
    SetEntryTunnelTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetEntryTunnelTimeMutation, SetEntryTunnelTimeMutationVariables>(
    SetEntryTunnelTimeDocument,
    options,
  );
}
export type SetEntryTunnelTimeMutationHookResult = ReturnType<typeof useSetEntryTunnelTimeMutation>;
export type SetEntryTunnelTimeMutationResult = Apollo.MutationResult<SetEntryTunnelTimeMutation>;
export type SetEntryTunnelTimeMutationOptions = Apollo.BaseMutationOptions<
  SetEntryTunnelTimeMutation,
  SetEntryTunnelTimeMutationVariables
>;
export const SetExitTunnelTimeDocument = gql`
  mutation SetExitTunnelTime($time: DateTime) {
    setExitTunnelTime(time: $time) {
      id
      showExitTunnelTime
    }
  }
`;
export type SetExitTunnelTimeMutationFn = Apollo.MutationFunction<
  SetExitTunnelTimeMutation,
  SetExitTunnelTimeMutationVariables
>;

/**
 * __useSetExitTunnelTimeMutation__
 *
 * To run a mutation, you first call `useSetExitTunnelTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetExitTunnelTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setExitTunnelTimeMutation, { data, loading, error }] = useSetExitTunnelTimeMutation({
 *   variables: {
 *      time: // value for 'time'
 *   },
 * });
 */
export function useSetExitTunnelTimeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetExitTunnelTimeMutation,
    SetExitTunnelTimeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetExitTunnelTimeMutation, SetExitTunnelTimeMutationVariables>(
    SetExitTunnelTimeDocument,
    options,
  );
}
export type SetExitTunnelTimeMutationHookResult = ReturnType<typeof useSetExitTunnelTimeMutation>;
export type SetExitTunnelTimeMutationResult = Apollo.MutationResult<SetExitTunnelTimeMutation>;
export type SetExitTunnelTimeMutationOptions = Apollo.BaseMutationOptions<
  SetExitTunnelTimeMutation,
  SetExitTunnelTimeMutationVariables
>;
export const SetCompanyDirectorDocument = gql`
  mutation SetCompanyDirector($directorId: String!) {
    setDirector(directorId: $directorId) {
      id
    }
  }
`;
export type SetCompanyDirectorMutationFn = Apollo.MutationFunction<
  SetCompanyDirectorMutation,
  SetCompanyDirectorMutationVariables
>;

/**
 * __useSetCompanyDirectorMutation__
 *
 * To run a mutation, you first call `useSetCompanyDirectorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCompanyDirectorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCompanyDirectorMutation, { data, loading, error }] = useSetCompanyDirectorMutation({
 *   variables: {
 *      directorId: // value for 'directorId'
 *   },
 * });
 */
export function useSetCompanyDirectorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetCompanyDirectorMutation,
    SetCompanyDirectorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetCompanyDirectorMutation, SetCompanyDirectorMutationVariables>(
    SetCompanyDirectorDocument,
    options,
  );
}
export type SetCompanyDirectorMutationHookResult = ReturnType<typeof useSetCompanyDirectorMutation>;
export type SetCompanyDirectorMutationResult = Apollo.MutationResult<SetCompanyDirectorMutation>;
export type SetCompanyDirectorMutationOptions = Apollo.BaseMutationOptions<
  SetCompanyDirectorMutation,
  SetCompanyDirectorMutationVariables
>;
export const SetEnableActiveHoursValueDocument = gql`
  mutation SetEnableActiveHoursValue($value: Boolean!) {
    setEnableActiveHoursValue(value: $value) {
      id
      enableProfessionalStatusActiveHours
    }
  }
`;
export type SetEnableActiveHoursValueMutationFn = Apollo.MutationFunction<
  SetEnableActiveHoursValueMutation,
  SetEnableActiveHoursValueMutationVariables
>;

/**
 * __useSetEnableActiveHoursValueMutation__
 *
 * To run a mutation, you first call `useSetEnableActiveHoursValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEnableActiveHoursValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEnableActiveHoursValueMutation, { data, loading, error }] = useSetEnableActiveHoursValueMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetEnableActiveHoursValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEnableActiveHoursValueMutation,
    SetEnableActiveHoursValueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetEnableActiveHoursValueMutation,
    SetEnableActiveHoursValueMutationVariables
  >(SetEnableActiveHoursValueDocument, options);
}
export type SetEnableActiveHoursValueMutationHookResult = ReturnType<
  typeof useSetEnableActiveHoursValueMutation
>;
export type SetEnableActiveHoursValueMutationResult =
  Apollo.MutationResult<SetEnableActiveHoursValueMutation>;
export type SetEnableActiveHoursValueMutationOptions = Apollo.BaseMutationOptions<
  SetEnableActiveHoursValueMutation,
  SetEnableActiveHoursValueMutationVariables
>;
export const SetActiveHoursTimeStartDocument = gql`
  mutation SetActiveHoursTimeStart($time: DateTime) {
    setActiveHoursTimeStart(time: $time) {
      id
      professionalStatusActiveHoursTimeStart
    }
  }
`;
export type SetActiveHoursTimeStartMutationFn = Apollo.MutationFunction<
  SetActiveHoursTimeStartMutation,
  SetActiveHoursTimeStartMutationVariables
>;

/**
 * __useSetActiveHoursTimeStartMutation__
 *
 * To run a mutation, you first call `useSetActiveHoursTimeStartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActiveHoursTimeStartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActiveHoursTimeStartMutation, { data, loading, error }] = useSetActiveHoursTimeStartMutation({
 *   variables: {
 *      time: // value for 'time'
 *   },
 * });
 */
export function useSetActiveHoursTimeStartMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetActiveHoursTimeStartMutation,
    SetActiveHoursTimeStartMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetActiveHoursTimeStartMutation,
    SetActiveHoursTimeStartMutationVariables
  >(SetActiveHoursTimeStartDocument, options);
}
export type SetActiveHoursTimeStartMutationHookResult = ReturnType<
  typeof useSetActiveHoursTimeStartMutation
>;
export type SetActiveHoursTimeStartMutationResult =
  Apollo.MutationResult<SetActiveHoursTimeStartMutation>;
export type SetActiveHoursTimeStartMutationOptions = Apollo.BaseMutationOptions<
  SetActiveHoursTimeStartMutation,
  SetActiveHoursTimeStartMutationVariables
>;
export const SetActiveHoursTimeEndDocument = gql`
  mutation SetActiveHoursTimeEnd($time: DateTime) {
    setActiveHoursTimeEnd(time: $time) {
      professionalStatusActiveHoursTimeEnd
      id
    }
  }
`;
export type SetActiveHoursTimeEndMutationFn = Apollo.MutationFunction<
  SetActiveHoursTimeEndMutation,
  SetActiveHoursTimeEndMutationVariables
>;

/**
 * __useSetActiveHoursTimeEndMutation__
 *
 * To run a mutation, you first call `useSetActiveHoursTimeEndMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetActiveHoursTimeEndMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setActiveHoursTimeEndMutation, { data, loading, error }] = useSetActiveHoursTimeEndMutation({
 *   variables: {
 *      time: // value for 'time'
 *   },
 * });
 */
export function useSetActiveHoursTimeEndMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetActiveHoursTimeEndMutation,
    SetActiveHoursTimeEndMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetActiveHoursTimeEndMutation, SetActiveHoursTimeEndMutationVariables>(
    SetActiveHoursTimeEndDocument,
    options,
  );
}
export type SetActiveHoursTimeEndMutationHookResult = ReturnType<
  typeof useSetActiveHoursTimeEndMutation
>;
export type SetActiveHoursTimeEndMutationResult =
  Apollo.MutationResult<SetActiveHoursTimeEndMutation>;
export type SetActiveHoursTimeEndMutationOptions = Apollo.BaseMutationOptions<
  SetActiveHoursTimeEndMutation,
  SetActiveHoursTimeEndMutationVariables
>;
export const NewArrivalCoworkersDocument = gql`
  query NewArrivalCoworkers {
    newArrivalCoworkers {
      id
      jobTitle
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        bio
        communities {
          community {
            id
            logo
            logoPublicUrl
            name
          }
        }
        skills {
          id
          title
        }
        successes {
          id
          title
        }
        passions {
          id
          title
        }
        previousCompanies {
          id
          name
        }
        pictures {
          id
          imageUrl
          imagePublicUrl
          title
        }
      }
    }
  }
`;

/**
 * __useNewArrivalCoworkersQuery__
 *
 * To run a query within a React component, call `useNewArrivalCoworkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewArrivalCoworkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewArrivalCoworkersQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewArrivalCoworkersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewArrivalCoworkersQuery,
    NewArrivalCoworkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewArrivalCoworkersQuery, NewArrivalCoworkersQueryVariables>(
    NewArrivalCoworkersDocument,
    options,
  );
}
export function useNewArrivalCoworkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewArrivalCoworkersQuery,
    NewArrivalCoworkersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewArrivalCoworkersQuery, NewArrivalCoworkersQueryVariables>(
    NewArrivalCoworkersDocument,
    options,
  );
}
export type NewArrivalCoworkersQueryHookResult = ReturnType<typeof useNewArrivalCoworkersQuery>;
export type NewArrivalCoworkersLazyQueryHookResult = ReturnType<
  typeof useNewArrivalCoworkersLazyQuery
>;
export type NewArrivalCoworkersQueryResult = Apollo.QueryResult<
  NewArrivalCoworkersQuery,
  NewArrivalCoworkersQueryVariables
>;
export const GetCurrentCompanyDocument = gql`
  query getCurrentCompany {
    currentCompany {
      id
      description
      email
      logo
      logoPublicUrl
      name
      romance
      slug
      mission
      leaveWeekendsCount
      removed
      communities {
        id
        name
        mission
        logo
        logoPublicUrl
      }
    }
  }
`;

/**
 * __useGetCurrentCompanyQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCurrentCompanyQuery, GetCurrentCompanyQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentCompanyQuery, GetCurrentCompanyQueryVariables>(
    GetCurrentCompanyDocument,
    options,
  );
}
export function useGetCurrentCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyQuery,
    GetCurrentCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentCompanyQuery, GetCurrentCompanyQueryVariables>(
    GetCurrentCompanyDocument,
    options,
  );
}
export type GetCurrentCompanyQueryHookResult = ReturnType<typeof useGetCurrentCompanyQuery>;
export type GetCurrentCompanyLazyQueryHookResult = ReturnType<typeof useGetCurrentCompanyLazyQuery>;
export type GetCurrentCompanyQueryResult = Apollo.QueryResult<
  GetCurrentCompanyQuery,
  GetCurrentCompanyQueryVariables
>;
export const GetCurrentCompanyIdDocument = gql`
  query getCurrentCompanyId {
    currentCompany {
      id
    }
    currentWholeCompany {
      id
      slug
      name
      logo
      logoPublicUrl
    }
  }
`;

/**
 * __useGetCurrentCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyIdQuery,
    GetCurrentCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentCompanyIdQuery, GetCurrentCompanyIdQueryVariables>(
    GetCurrentCompanyIdDocument,
    options,
  );
}
export function useGetCurrentCompanyIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyIdQuery,
    GetCurrentCompanyIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentCompanyIdQuery, GetCurrentCompanyIdQueryVariables>(
    GetCurrentCompanyIdDocument,
    options,
  );
}
export type GetCurrentCompanyIdQueryHookResult = ReturnType<typeof useGetCurrentCompanyIdQuery>;
export type GetCurrentCompanyIdLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyIdLazyQuery
>;
export type GetCurrentCompanyIdQueryResult = Apollo.QueryResult<
  GetCurrentCompanyIdQuery,
  GetCurrentCompanyIdQueryVariables
>;
export const GetCompanyCommunitiesDocument = gql`
  query getCompanyCommunities {
    currentCompany {
      id
      communities {
        ...Community
        members {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
          companyMemberId
        }
      }
    }
  }
  ${CommunityFragmentDoc}
`;

/**
 * __useGetCompanyCommunitiesQuery__
 *
 * To run a query within a React component, call `useGetCompanyCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCommunitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyCommunitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyCommunitiesQuery,
    GetCompanyCommunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyCommunitiesQuery, GetCompanyCommunitiesQueryVariables>(
    GetCompanyCommunitiesDocument,
    options,
  );
}
export function useGetCompanyCommunitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyCommunitiesQuery,
    GetCompanyCommunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyCommunitiesQuery, GetCompanyCommunitiesQueryVariables>(
    GetCompanyCommunitiesDocument,
    options,
  );
}
export type GetCompanyCommunitiesQueryHookResult = ReturnType<typeof useGetCompanyCommunitiesQuery>;
export type GetCompanyCommunitiesLazyQueryHookResult = ReturnType<
  typeof useGetCompanyCommunitiesLazyQuery
>;
export type GetCompanyCommunitiesQueryResult = Apollo.QueryResult<
  GetCompanyCommunitiesQuery,
  GetCompanyCommunitiesQueryVariables
>;
export const GetCompanyCommunitiesWithCmDocument = gql`
  query getCompanyCommunitiesWithCm {
    currentCompany {
      id
      communities {
        ...Community
        members {
          id
          user {
            id
            fullName
            firstName
            lastName
            email
            photoUrl
            photoPublicUrl
          }
          companyMember {
            id
            user {
              id
              fullName
              firstName
              lastName
              email
              photoUrl
              photoPublicUrl
            }
          }
          companyMemberId
        }
      }
    }
  }
  ${CommunityFragmentDoc}
`;

/**
 * __useGetCompanyCommunitiesWithCmQuery__
 *
 * To run a query within a React component, call `useGetCompanyCommunitiesWithCmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyCommunitiesWithCmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyCommunitiesWithCmQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyCommunitiesWithCmQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyCommunitiesWithCmQuery,
    GetCompanyCommunitiesWithCmQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyCommunitiesWithCmQuery,
    GetCompanyCommunitiesWithCmQueryVariables
  >(GetCompanyCommunitiesWithCmDocument, options);
}
export function useGetCompanyCommunitiesWithCmLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyCommunitiesWithCmQuery,
    GetCompanyCommunitiesWithCmQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyCommunitiesWithCmQuery,
    GetCompanyCommunitiesWithCmQueryVariables
  >(GetCompanyCommunitiesWithCmDocument, options);
}
export type GetCompanyCommunitiesWithCmQueryHookResult = ReturnType<
  typeof useGetCompanyCommunitiesWithCmQuery
>;
export type GetCompanyCommunitiesWithCmLazyQueryHookResult = ReturnType<
  typeof useGetCompanyCommunitiesWithCmLazyQuery
>;
export type GetCompanyCommunitiesWithCmQueryResult = Apollo.QueryResult<
  GetCompanyCommunitiesWithCmQuery,
  GetCompanyCommunitiesWithCmQueryVariables
>;
export const GetCompanyMemberByIdDocument = gql`
  query getCompanyMemberById($id: ID!) {
    getCompanyMemberById(id: $id) {
      id
      isManager
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        email
        myCommunity {
          id
        }
      }
      roadBlocks {
        ...RoadBlock
        actions {
          ...ActionInfo
          responsibles {
            id
            user {
              fullName
              firstName
              lastName
              photoUrl
              photoPublicUrl
            }
          }
        }
      }
      currentProfessionnalWeather {
        id
        icon
        title
      }
      currentMotto {
        id
        content
      }
      myCommunities {
        id
      }
      nPlusOne {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
    }
  }
  ${RoadBlockFragmentDoc}
  ${ActionInfoFragmentDoc}
`;

/**
 * __useGetCompanyMemberByIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyMemberByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMemberByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMemberByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyMemberByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyMemberByIdQuery,
    GetCompanyMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyMemberByIdQuery, GetCompanyMemberByIdQueryVariables>(
    GetCompanyMemberByIdDocument,
    options,
  );
}
export function useGetCompanyMemberByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMemberByIdQuery,
    GetCompanyMemberByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyMemberByIdQuery, GetCompanyMemberByIdQueryVariables>(
    GetCompanyMemberByIdDocument,
    options,
  );
}
export type GetCompanyMemberByIdQueryHookResult = ReturnType<typeof useGetCompanyMemberByIdQuery>;
export type GetCompanyMemberByIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMemberByIdLazyQuery
>;
export type GetCompanyMemberByIdQueryResult = Apollo.QueryResult<
  GetCompanyMemberByIdQuery,
  GetCompanyMemberByIdQueryVariables
>;
export const CoworkersMemberDocument = gql`
  query CoworkersMember(
    $ids: [ID!]
    $withoutMe: Boolean
    $filterByWeatherId: ID
    $filterByCommunityId: ID
    $filterByEventTypeId: ID
    $queryString: String
    $jobTitle: String
    $sortField: String
    $sortOrder: String
    $page: Int
    $perPage: Int
    $cacheKey: String
  ) {
    coworkersMember(
      cacheKey: $cacheKey
      ids: $ids
      withoutMe: $withoutMe
      filterByWeatherId: $filterByWeatherId
      filterByCommunityId: $filterByCommunityId
      filterByEventTypeId: $filterByEventTypeId
      queryString: $queryString
      jobTitle: $jobTitle
      sortField: $sortField
      sortOrder: $sortOrder
      page: $page
      perPage: $perPage
    ) {
      id
      jobTitle
      isItMyDay
      removed
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        isItMyDay
        email
        isOnline
        removed
        suspended
        companyMembers {
          id
        }
      }
      currentMotto {
        id
        content
      }
      currentProfessionnalWeather {
        id
        icon
        title
      }
      currentProfessionalStatus {
        id
        title
        icon
      }
      nPlusOne {
        id
        jobTitle
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      nMinusOnes {
        id
        jobTitle
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      myCommunities {
        id
      }
      dayOff
      currentLocation {
        id
        title
        iconUrl
      }
    }
    total: _allCompanyMembersMeta(
      filter: { ids: $ids, q: $queryString, isByCurrentCompany: true }
    ) {
      count
    }
  }
`;

/**
 * __useCoworkersMemberQuery__
 *
 * To run a query within a React component, call `useCoworkersMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoworkersMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoworkersMemberQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      withoutMe: // value for 'withoutMe'
 *      filterByWeatherId: // value for 'filterByWeatherId'
 *      filterByCommunityId: // value for 'filterByCommunityId'
 *      filterByEventTypeId: // value for 'filterByEventTypeId'
 *      queryString: // value for 'queryString'
 *      jobTitle: // value for 'jobTitle'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      cacheKey: // value for 'cacheKey'
 *   },
 * });
 */
export function useCoworkersMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<CoworkersMemberQuery, CoworkersMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoworkersMemberQuery, CoworkersMemberQueryVariables>(
    CoworkersMemberDocument,
    options,
  );
}
export function useCoworkersMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoworkersMemberQuery, CoworkersMemberQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoworkersMemberQuery, CoworkersMemberQueryVariables>(
    CoworkersMemberDocument,
    options,
  );
}
export type CoworkersMemberQueryHookResult = ReturnType<typeof useCoworkersMemberQuery>;
export type CoworkersMemberLazyQueryHookResult = ReturnType<typeof useCoworkersMemberLazyQuery>;
export type CoworkersMemberQueryResult = Apollo.QueryResult<
  CoworkersMemberQuery,
  CoworkersMemberQueryVariables
>;
export const CurrentCompanyMemberDocument = gql`
  query CurrentCompanyMember($seen: Boolean) {
    currentCompanyMember {
      id
      coins
      jobTitle
      incomingSemiInstantCallsCount
      nPlusOne {
        id
        jobTitle
        user {
          id
          fullName
          firstName
          lastName
          email
          photoUrl
          photoPublicUrl
        }
      }
      nMinusOnes {
        id
        jobTitle
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
          email
        }
      }
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        email
      }
      feedbacksReceived(seen: $seen) {
        ...FeedbackInfo
        companyMemberReceiver {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        companyMemberSender {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
            email
          }
        }
      }
      thanksReceived(seen: $seen) {
        ...UserThankInfo
        companyMemberSender {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
            email
          }
        }
      }
      myCommunities {
        id
        name
        logo
      }
      missions {
        ...MissionInfo
        community {
          id
          logo
          logoPublicUrl
          name
        }
        experts {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
            email
          }
        }
        companyMember {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
            email
          }
        }
      }
      myActions {
        ...ActionInfo
        responsibles {
          id
          user {
            id
            fullName
            photoUrl
            photoPublicUrl
            firstName
            lastName
          }
        }
        roadBlock {
          id
          roadBlockId
        }
      }
    }
  }
  ${FeedbackInfoFragmentDoc}
  ${UserThankInfoFragmentDoc}
  ${MissionInfoFragmentDoc}
  ${ActionInfoFragmentDoc}
`;

/**
 * __useCurrentCompanyMemberQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyMemberQuery({
 *   variables: {
 *      seen: // value for 'seen'
 *   },
 * });
 */
export function useCurrentCompanyMemberQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyMemberQuery,
    CurrentCompanyMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentCompanyMemberQuery, CurrentCompanyMemberQueryVariables>(
    CurrentCompanyMemberDocument,
    options,
  );
}
export function useCurrentCompanyMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyMemberQuery,
    CurrentCompanyMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentCompanyMemberQuery, CurrentCompanyMemberQueryVariables>(
    CurrentCompanyMemberDocument,
    options,
  );
}
export type CurrentCompanyMemberQueryHookResult = ReturnType<typeof useCurrentCompanyMemberQuery>;
export type CurrentCompanyMemberLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyMemberLazyQuery
>;
export type CurrentCompanyMemberQueryResult = Apollo.QueryResult<
  CurrentCompanyMemberQuery,
  CurrentCompanyMemberQueryVariables
>;
export const GetFeedbacksByCmDocument = gql`
  query GetFeedbacksByCM(
    $id: ID!
    $sentToId: ID
    $receivedFromId: ID
    $sentByMe: Boolean
    $receivedByMe: Boolean
  ) {
    getCompanyMemberById(id: $id) {
      id
      feedbacksSent: feedbacksSended(receiverId: $sentToId, receivedByMe: $receivedByMe) {
        ...FeedbackInfo
        companyMemberReceiver {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        companyMemberSender {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
      }
      feedbacksReceived(senderId: $receivedFromId, sentByMe: $sentByMe) {
        ...FeedbackInfo
        companyMemberReceiver {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        companyMemberSender {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
      }
    }
  }
  ${FeedbackInfoFragmentDoc}
`;

/**
 * __useGetFeedbacksByCmQuery__
 *
 * To run a query within a React component, call `useGetFeedbacksByCmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbacksByCmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbacksByCmQuery({
 *   variables: {
 *      id: // value for 'id'
 *      sentToId: // value for 'sentToId'
 *      receivedFromId: // value for 'receivedFromId'
 *      sentByMe: // value for 'sentByMe'
 *      receivedByMe: // value for 'receivedByMe'
 *   },
 * });
 */
export function useGetFeedbacksByCmQuery(
  baseOptions: Apollo.QueryHookOptions<GetFeedbacksByCmQuery, GetFeedbacksByCmQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFeedbacksByCmQuery, GetFeedbacksByCmQueryVariables>(
    GetFeedbacksByCmDocument,
    options,
  );
}
export function useGetFeedbacksByCmLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFeedbacksByCmQuery, GetFeedbacksByCmQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFeedbacksByCmQuery, GetFeedbacksByCmQueryVariables>(
    GetFeedbacksByCmDocument,
    options,
  );
}
export type GetFeedbacksByCmQueryHookResult = ReturnType<typeof useGetFeedbacksByCmQuery>;
export type GetFeedbacksByCmLazyQueryHookResult = ReturnType<typeof useGetFeedbacksByCmLazyQuery>;
export type GetFeedbacksByCmQueryResult = Apollo.QueryResult<
  GetFeedbacksByCmQuery,
  GetFeedbacksByCmQueryVariables
>;
export const GetCompaniesDocument = gql`
  query GetCompanies {
    companies: myCompanies {
      id
      name
      slug
    }
  }
`;

/**
 * __useGetCompaniesQuery__
 *
 * To run a query within a React component, call `useGetCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(
    GetCompaniesDocument,
    options,
  );
}
export function useGetCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompaniesQuery, GetCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompaniesQuery, GetCompaniesQueryVariables>(
    GetCompaniesDocument,
    options,
  );
}
export type GetCompaniesQueryHookResult = ReturnType<typeof useGetCompaniesQuery>;
export type GetCompaniesLazyQueryHookResult = ReturnType<typeof useGetCompaniesLazyQuery>;
export type GetCompaniesQueryResult = Apollo.QueryResult<
  GetCompaniesQuery,
  GetCompaniesQueryVariables
>;
export const GetCompaniesWithLogoDocument = gql`
  query GetCompaniesWithLogo {
    companies: myCompanies {
      id
      name
      slug
      logo
      logoPublicUrl
    }
  }
`;

/**
 * __useGetCompaniesWithLogoQuery__
 *
 * To run a query within a React component, call `useGetCompaniesWithLogoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompaniesWithLogoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompaniesWithLogoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompaniesWithLogoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompaniesWithLogoQuery,
    GetCompaniesWithLogoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompaniesWithLogoQuery, GetCompaniesWithLogoQueryVariables>(
    GetCompaniesWithLogoDocument,
    options,
  );
}
export function useGetCompaniesWithLogoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompaniesWithLogoQuery,
    GetCompaniesWithLogoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompaniesWithLogoQuery, GetCompaniesWithLogoQueryVariables>(
    GetCompaniesWithLogoDocument,
    options,
  );
}
export type GetCompaniesWithLogoQueryHookResult = ReturnType<typeof useGetCompaniesWithLogoQuery>;
export type GetCompaniesWithLogoLazyQueryHookResult = ReturnType<
  typeof useGetCompaniesWithLogoLazyQuery
>;
export type GetCompaniesWithLogoQueryResult = Apollo.QueryResult<
  GetCompaniesWithLogoQuery,
  GetCompaniesWithLogoQueryVariables
>;
export const GetEmployeeOfTheDayDocument = gql`
  query GetEmployeeOfTheDay {
    getEmployeeOfTheDay {
      id
      day
      companyMember {
        id
        jobTitle
        hiringDate
        user {
          id
          fullName
          firstName
          lastName
          bio
          email
          photoUrl
          photoPublicUrl
          communities {
            id
            community {
              id
              name
              logo
              logoPublicUrl
            }
          }
          skills {
            id
            title
          }
          successes {
            id
            title
          }
          passions {
            id
            title
          }
          pictures {
            id
            title
            imageUrl
            imagePublicUrl
          }
          previousCompanies {
            id
            name
          }
        }
      }
    }
  }
`;

/**
 * __useGetEmployeeOfTheDayQuery__
 *
 * To run a query within a React component, call `useGetEmployeeOfTheDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeOfTheDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeOfTheDayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmployeeOfTheDayQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmployeeOfTheDayQuery,
    GetEmployeeOfTheDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEmployeeOfTheDayQuery, GetEmployeeOfTheDayQueryVariables>(
    GetEmployeeOfTheDayDocument,
    options,
  );
}
export function useGetEmployeeOfTheDayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmployeeOfTheDayQuery,
    GetEmployeeOfTheDayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEmployeeOfTheDayQuery, GetEmployeeOfTheDayQueryVariables>(
    GetEmployeeOfTheDayDocument,
    options,
  );
}
export type GetEmployeeOfTheDayQueryHookResult = ReturnType<typeof useGetEmployeeOfTheDayQuery>;
export type GetEmployeeOfTheDayLazyQueryHookResult = ReturnType<
  typeof useGetEmployeeOfTheDayLazyQuery
>;
export type GetEmployeeOfTheDayQueryResult = Apollo.QueryResult<
  GetEmployeeOfTheDayQuery,
  GetEmployeeOfTheDayQueryVariables
>;
export const GetMyCommunitiesDocument = gql`
  query GetMyCommunities {
    currentCompanyMember {
      id
      myCommunities {
        id
        name
        logo
        logoPublicUrl
        slug
        mission
        rommance
        myCommunityRole
        exp
        coins
        expLevel {
          id
          name
          iconPublicUrl
        }
      }
    }
    currentWholeCompany {
      id
      slug
    }
  }
`;

/**
 * __useGetMyCommunitiesQuery__
 *
 * To run a query within a React component, call `useGetMyCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCommunitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCommunitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyCommunitiesQuery, GetMyCommunitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyCommunitiesQuery, GetMyCommunitiesQueryVariables>(
    GetMyCommunitiesDocument,
    options,
  );
}
export function useGetMyCommunitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyCommunitiesQuery, GetMyCommunitiesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyCommunitiesQuery, GetMyCommunitiesQueryVariables>(
    GetMyCommunitiesDocument,
    options,
  );
}
export type GetMyCommunitiesQueryHookResult = ReturnType<typeof useGetMyCommunitiesQuery>;
export type GetMyCommunitiesLazyQueryHookResult = ReturnType<typeof useGetMyCommunitiesLazyQuery>;
export type GetMyCommunitiesQueryResult = Apollo.QueryResult<
  GetMyCommunitiesQuery,
  GetMyCommunitiesQueryVariables
>;
export const GetCurrentCompanyPostCategoriesDocument = gql`
  query getCurrentCompanyPostCategories {
    currentCompany {
      id
      questionCategories {
        id
        title
      }
      ideaCategories {
        id
        title
      }
      problemCategories {
        id
        title
      }
      adviceCategories {
        id
        title
      }
    }
  }
`;

/**
 * __useGetCurrentCompanyPostCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyPostCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyPostCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyPostCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyPostCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyPostCategoriesQuery,
    GetCurrentCompanyPostCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentCompanyPostCategoriesQuery,
    GetCurrentCompanyPostCategoriesQueryVariables
  >(GetCurrentCompanyPostCategoriesDocument, options);
}
export function useGetCurrentCompanyPostCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyPostCategoriesQuery,
    GetCurrentCompanyPostCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyPostCategoriesQuery,
    GetCurrentCompanyPostCategoriesQueryVariables
  >(GetCurrentCompanyPostCategoriesDocument, options);
}
export type GetCurrentCompanyPostCategoriesQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyPostCategoriesQuery
>;
export type GetCurrentCompanyPostCategoriesLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyPostCategoriesLazyQuery
>;
export type GetCurrentCompanyPostCategoriesQueryResult = Apollo.QueryResult<
  GetCurrentCompanyPostCategoriesQuery,
  GetCurrentCompanyPostCategoriesQueryVariables
>;
export const CoworkersMemberWithMeDocument = gql`
  query CoworkersMemberWithMe($queryString: String, $fromAdmin: Boolean) {
    coworkersMember(queryString: $queryString, fromAdmin: $fromAdmin) {
      id
      user {
        id
        email
        fullName
        firstName
        lastName
        suspended
      }
      role
      userAccess
      hiringDate
      nPlusOne {
        id
        user {
          id
          fullName
          firstName
          photoUrl
          photoPublicUrl
        }
      }
      myCommunities {
        id
        logo
        logoPublicUrl
        name
      }
      businessUnit {
        id
        name
        logo
        logoPublicUrl
      }
    }
  }
`;

/**
 * __useCoworkersMemberWithMeQuery__
 *
 * To run a query within a React component, call `useCoworkersMemberWithMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoworkersMemberWithMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoworkersMemberWithMeQuery({
 *   variables: {
 *      queryString: // value for 'queryString'
 *      fromAdmin: // value for 'fromAdmin'
 *   },
 * });
 */
export function useCoworkersMemberWithMeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CoworkersMemberWithMeQuery,
    CoworkersMemberWithMeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoworkersMemberWithMeQuery, CoworkersMemberWithMeQueryVariables>(
    CoworkersMemberWithMeDocument,
    options,
  );
}
export function useCoworkersMemberWithMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CoworkersMemberWithMeQuery,
    CoworkersMemberWithMeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoworkersMemberWithMeQuery, CoworkersMemberWithMeQueryVariables>(
    CoworkersMemberWithMeDocument,
    options,
  );
}
export type CoworkersMemberWithMeQueryHookResult = ReturnType<typeof useCoworkersMemberWithMeQuery>;
export type CoworkersMemberWithMeLazyQueryHookResult = ReturnType<
  typeof useCoworkersMemberWithMeLazyQuery
>;
export type CoworkersMemberWithMeQueryResult = Apollo.QueryResult<
  CoworkersMemberWithMeQuery,
  CoworkersMemberWithMeQueryVariables
>;
export const GetPendingInvitationsByCompanyDocument = gql`
  query GetPendingInvitationsByCompany {
    pendingInvitationByCompany: getPendingInvitationsByCompany {
      id
      targetEmail
      companyMemberRole
      companyMemberAccess
      token
      pendingTeams {
        id
        name
        logoPublicUrl
      }
    }
  }
`;

/**
 * __useGetPendingInvitationsByCompanyQuery__
 *
 * To run a query within a React component, call `useGetPendingInvitationsByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingInvitationsByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingInvitationsByCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPendingInvitationsByCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPendingInvitationsByCompanyQuery,
    GetPendingInvitationsByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPendingInvitationsByCompanyQuery,
    GetPendingInvitationsByCompanyQueryVariables
  >(GetPendingInvitationsByCompanyDocument, options);
}
export function useGetPendingInvitationsByCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPendingInvitationsByCompanyQuery,
    GetPendingInvitationsByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPendingInvitationsByCompanyQuery,
    GetPendingInvitationsByCompanyQueryVariables
  >(GetPendingInvitationsByCompanyDocument, options);
}
export type GetPendingInvitationsByCompanyQueryHookResult = ReturnType<
  typeof useGetPendingInvitationsByCompanyQuery
>;
export type GetPendingInvitationsByCompanyLazyQueryHookResult = ReturnType<
  typeof useGetPendingInvitationsByCompanyLazyQuery
>;
export type GetPendingInvitationsByCompanyQueryResult = Apollo.QueryResult<
  GetPendingInvitationsByCompanyQuery,
  GetPendingInvitationsByCompanyQueryVariables
>;
export const GetPendingInvitationsByTeamIdDocument = gql`
  query GetPendingInvitationsByTeamId($id: ID!) {
    pendingInvitationsByTeam: getPendingInvitationsByTeamId(id: $id) {
      id
      targetEmail
      communityMemberRole
    }
  }
`;

/**
 * __useGetPendingInvitationsByTeamIdQuery__
 *
 * To run a query within a React component, call `useGetPendingInvitationsByTeamIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingInvitationsByTeamIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingInvitationsByTeamIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPendingInvitationsByTeamIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPendingInvitationsByTeamIdQuery,
    GetPendingInvitationsByTeamIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPendingInvitationsByTeamIdQuery,
    GetPendingInvitationsByTeamIdQueryVariables
  >(GetPendingInvitationsByTeamIdDocument, options);
}
export function useGetPendingInvitationsByTeamIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPendingInvitationsByTeamIdQuery,
    GetPendingInvitationsByTeamIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPendingInvitationsByTeamIdQuery,
    GetPendingInvitationsByTeamIdQueryVariables
  >(GetPendingInvitationsByTeamIdDocument, options);
}
export type GetPendingInvitationsByTeamIdQueryHookResult = ReturnType<
  typeof useGetPendingInvitationsByTeamIdQuery
>;
export type GetPendingInvitationsByTeamIdLazyQueryHookResult = ReturnType<
  typeof useGetPendingInvitationsByTeamIdLazyQuery
>;
export type GetPendingInvitationsByTeamIdQueryResult = Apollo.QueryResult<
  GetPendingInvitationsByTeamIdQuery,
  GetPendingInvitationsByTeamIdQueryVariables
>;
export const GetCurrentWelcomeScreenDocument = gql`
  query GetCurrentWelcomeScreen {
    getCurrentWelcomeScreen
  }
`;

/**
 * __useGetCurrentWelcomeScreenQuery__
 *
 * To run a query within a React component, call `useGetCurrentWelcomeScreenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentWelcomeScreenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentWelcomeScreenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentWelcomeScreenQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentWelcomeScreenQuery,
    GetCurrentWelcomeScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentWelcomeScreenQuery, GetCurrentWelcomeScreenQueryVariables>(
    GetCurrentWelcomeScreenDocument,
    options,
  );
}
export function useGetCurrentWelcomeScreenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentWelcomeScreenQuery,
    GetCurrentWelcomeScreenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentWelcomeScreenQuery, GetCurrentWelcomeScreenQueryVariables>(
    GetCurrentWelcomeScreenDocument,
    options,
  );
}
export type GetCurrentWelcomeScreenQueryHookResult = ReturnType<
  typeof useGetCurrentWelcomeScreenQuery
>;
export type GetCurrentWelcomeScreenLazyQueryHookResult = ReturnType<
  typeof useGetCurrentWelcomeScreenLazyQuery
>;
export type GetCurrentWelcomeScreenQueryResult = Apollo.QueryResult<
  GetCurrentWelcomeScreenQuery,
  GetCurrentWelcomeScreenQueryVariables
>;
export const GetPendingInvitationDocument = gql`
  query GetPendingInvitation($token: String!) {
    pendingInvitation: getPendingInvitation(token: $token) {
      id
      targetEmail
      hiringDate
    }
  }
`;

/**
 * __useGetPendingInvitationQuery__
 *
 * To run a query within a React component, call `useGetPendingInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingInvitationQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetPendingInvitationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetPendingInvitationQuery,
    GetPendingInvitationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPendingInvitationQuery, GetPendingInvitationQueryVariables>(
    GetPendingInvitationDocument,
    options,
  );
}
export function useGetPendingInvitationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPendingInvitationQuery,
    GetPendingInvitationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPendingInvitationQuery, GetPendingInvitationQueryVariables>(
    GetPendingInvitationDocument,
    options,
  );
}
export type GetPendingInvitationQueryHookResult = ReturnType<typeof useGetPendingInvitationQuery>;
export type GetPendingInvitationLazyQueryHookResult = ReturnType<
  typeof useGetPendingInvitationLazyQuery
>;
export type GetPendingInvitationQueryResult = Apollo.QueryResult<
  GetPendingInvitationQuery,
  GetPendingInvitationQueryVariables
>;
export const GetAllCompanyImagesDocument = gql`
  query getAllCompanyImages {
    companyImages: getCompanyImages {
      id
      name
      type
      size
      path
      publicUrl
    }
  }
`;

/**
 * __useGetAllCompanyImagesQuery__
 *
 * To run a query within a React component, call `useGetAllCompanyImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCompanyImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCompanyImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCompanyImagesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCompanyImagesQuery,
    GetAllCompanyImagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCompanyImagesQuery, GetAllCompanyImagesQueryVariables>(
    GetAllCompanyImagesDocument,
    options,
  );
}
export function useGetAllCompanyImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCompanyImagesQuery,
    GetAllCompanyImagesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCompanyImagesQuery, GetAllCompanyImagesQueryVariables>(
    GetAllCompanyImagesDocument,
    options,
  );
}
export type GetAllCompanyImagesQueryHookResult = ReturnType<typeof useGetAllCompanyImagesQuery>;
export type GetAllCompanyImagesLazyQueryHookResult = ReturnType<
  typeof useGetAllCompanyImagesLazyQuery
>;
export type GetAllCompanyImagesQueryResult = Apollo.QueryResult<
  GetAllCompanyImagesQuery,
  GetAllCompanyImagesQueryVariables
>;
export const IsAlreadySignedUpDocument = gql`
  query isAlreadySignedUp($token: String!) {
    isAlreadySignedUp(token: $token)
  }
`;

/**
 * __useIsAlreadySignedUpQuery__
 *
 * To run a query within a React component, call `useIsAlreadySignedUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsAlreadySignedUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsAlreadySignedUpQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useIsAlreadySignedUpQuery(
  baseOptions: Apollo.QueryHookOptions<IsAlreadySignedUpQuery, IsAlreadySignedUpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IsAlreadySignedUpQuery, IsAlreadySignedUpQueryVariables>(
    IsAlreadySignedUpDocument,
    options,
  );
}
export function useIsAlreadySignedUpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IsAlreadySignedUpQuery,
    IsAlreadySignedUpQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IsAlreadySignedUpQuery, IsAlreadySignedUpQueryVariables>(
    IsAlreadySignedUpDocument,
    options,
  );
}
export type IsAlreadySignedUpQueryHookResult = ReturnType<typeof useIsAlreadySignedUpQuery>;
export type IsAlreadySignedUpLazyQueryHookResult = ReturnType<typeof useIsAlreadySignedUpLazyQuery>;
export type IsAlreadySignedUpQueryResult = Apollo.QueryResult<
  IsAlreadySignedUpQuery,
  IsAlreadySignedUpQueryVariables
>;
export const GetMyNPlusOneDocument = gql`
  query GetMyNPlusOne {
    me: currentCompanyMember {
      id
      user {
        id
        fullName
        photoPublicUrl
      }
      nPlusOne {
        id
      }
    }
  }
`;

/**
 * __useGetMyNPlusOneQuery__
 *
 * To run a query within a React component, call `useGetMyNPlusOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyNPlusOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyNPlusOneQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyNPlusOneQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyNPlusOneQuery, GetMyNPlusOneQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyNPlusOneQuery, GetMyNPlusOneQueryVariables>(
    GetMyNPlusOneDocument,
    options,
  );
}
export function useGetMyNPlusOneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyNPlusOneQuery, GetMyNPlusOneQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyNPlusOneQuery, GetMyNPlusOneQueryVariables>(
    GetMyNPlusOneDocument,
    options,
  );
}
export type GetMyNPlusOneQueryHookResult = ReturnType<typeof useGetMyNPlusOneQuery>;
export type GetMyNPlusOneLazyQueryHookResult = ReturnType<typeof useGetMyNPlusOneLazyQuery>;
export type GetMyNPlusOneQueryResult = Apollo.QueryResult<
  GetMyNPlusOneQuery,
  GetMyNPlusOneQueryVariables
>;
export const GetCeoWordDocument = gql`
  query getCeoWord {
    speech: getCeoWord
  }
`;

/**
 * __useGetCeoWordQuery__
 *
 * To run a query within a React component, call `useGetCeoWordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCeoWordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCeoWordQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCeoWordQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCeoWordQuery, GetCeoWordQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCeoWordQuery, GetCeoWordQueryVariables>(GetCeoWordDocument, options);
}
export function useGetCeoWordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCeoWordQuery, GetCeoWordQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCeoWordQuery, GetCeoWordQueryVariables>(
    GetCeoWordDocument,
    options,
  );
}
export type GetCeoWordQueryHookResult = ReturnType<typeof useGetCeoWordQuery>;
export type GetCeoWordLazyQueryHookResult = ReturnType<typeof useGetCeoWordLazyQuery>;
export type GetCeoWordQueryResult = Apollo.QueryResult<GetCeoWordQuery, GetCeoWordQueryVariables>;
export const CheckClientDocument = gql`
  query CheckClient($timezone: UserTimezoneInput!) {
    payload: checkClient(timezone: $timezone) {
      companyMember {
        id
        removed
        user {
          id
          fullName
          firstName
          lastName
          email
          photoUrl
          photoPublicUrl
        }
        company {
          id
          slug
        }
        userPreference {
          id
          userTimezone {
            id
            tzCode
          }
          enableShowEntryTunnel
          language {
            id
            key
          }
        }
      }
      streamToken
      success
      message
    }
  }
`;

/**
 * __useCheckClientQuery__
 *
 * To run a query within a React component, call `useCheckClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckClientQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useCheckClientQuery(
  baseOptions: Apollo.QueryHookOptions<CheckClientQuery, CheckClientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckClientQuery, CheckClientQueryVariables>(CheckClientDocument, options);
}
export function useCheckClientLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckClientQuery, CheckClientQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckClientQuery, CheckClientQueryVariables>(
    CheckClientDocument,
    options,
  );
}
export type CheckClientQueryHookResult = ReturnType<typeof useCheckClientQuery>;
export type CheckClientLazyQueryHookResult = ReturnType<typeof useCheckClientLazyQuery>;
export type CheckClientQueryResult = Apollo.QueryResult<
  CheckClientQuery,
  CheckClientQueryVariables
>;
export const CheckAdminDocument = gql`
  query CheckAdmin {
    admin: checkAdmin {
      userAccess
      slug
    }
  }
`;

/**
 * __useCheckAdminQuery__
 *
 * To run a query within a React component, call `useCheckAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckAdminQuery(
  baseOptions?: Apollo.QueryHookOptions<CheckAdminQuery, CheckAdminQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CheckAdminQuery, CheckAdminQueryVariables>(CheckAdminDocument, options);
}
export function useCheckAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CheckAdminQuery, CheckAdminQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CheckAdminQuery, CheckAdminQueryVariables>(
    CheckAdminDocument,
    options,
  );
}
export type CheckAdminQueryHookResult = ReturnType<typeof useCheckAdminQuery>;
export type CheckAdminLazyQueryHookResult = ReturnType<typeof useCheckAdminLazyQuery>;
export type CheckAdminQueryResult = Apollo.QueryResult<CheckAdminQuery, CheckAdminQueryVariables>;
export const GetCommunitiesInputOptionsDocument = gql`
  query GetCommunitiesInputOptions($id: ID, $mine: Boolean) {
    communities: getCommunitiesByCompany(id: $id, mine: $mine) {
      id
      name
      logo
      logoPublicUrl
    }
  }
`;

/**
 * __useGetCommunitiesInputOptionsQuery__
 *
 * To run a query within a React component, call `useGetCommunitiesInputOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunitiesInputOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunitiesInputOptionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      mine: // value for 'mine'
 *   },
 * });
 */
export function useGetCommunitiesInputOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCommunitiesInputOptionsQuery,
    GetCommunitiesInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommunitiesInputOptionsQuery, GetCommunitiesInputOptionsQueryVariables>(
    GetCommunitiesInputOptionsDocument,
    options,
  );
}
export function useGetCommunitiesInputOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunitiesInputOptionsQuery,
    GetCommunitiesInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommunitiesInputOptionsQuery,
    GetCommunitiesInputOptionsQueryVariables
  >(GetCommunitiesInputOptionsDocument, options);
}
export type GetCommunitiesInputOptionsQueryHookResult = ReturnType<
  typeof useGetCommunitiesInputOptionsQuery
>;
export type GetCommunitiesInputOptionsLazyQueryHookResult = ReturnType<
  typeof useGetCommunitiesInputOptionsLazyQuery
>;
export type GetCommunitiesInputOptionsQueryResult = Apollo.QueryResult<
  GetCommunitiesInputOptionsQuery,
  GetCommunitiesInputOptionsQueryVariables
>;
export const GetCoworkersInputOptionsDocument = gql`
  query GetCoworkersInputOptions(
    $ids: [ID!]
    $withoutMe: Boolean
    $queryString: String
    $teamId: ID
    $filterByCommunityId: ID
    $perPage: Int
  ) {
    coworkers: coworkersInputOptions(
      ids: $ids
      withoutMe: $withoutMe
      queryString: $queryString
      teamId: $teamId
      filterByCommunityId: $filterByCommunityId
      perPage: $perPage
    ) {
      id
      removed
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        isOnline
        email
        currentCompanyMember {
          id
        }
      }
    }
  }
`;

/**
 * __useGetCoworkersInputOptionsQuery__
 *
 * To run a query within a React component, call `useGetCoworkersInputOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoworkersInputOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoworkersInputOptionsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      withoutMe: // value for 'withoutMe'
 *      queryString: // value for 'queryString'
 *      teamId: // value for 'teamId'
 *      filterByCommunityId: // value for 'filterByCommunityId'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetCoworkersInputOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCoworkersInputOptionsQuery,
    GetCoworkersInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoworkersInputOptionsQuery, GetCoworkersInputOptionsQueryVariables>(
    GetCoworkersInputOptionsDocument,
    options,
  );
}
export function useGetCoworkersInputOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoworkersInputOptionsQuery,
    GetCoworkersInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoworkersInputOptionsQuery, GetCoworkersInputOptionsQueryVariables>(
    GetCoworkersInputOptionsDocument,
    options,
  );
}
export type GetCoworkersInputOptionsQueryHookResult = ReturnType<
  typeof useGetCoworkersInputOptionsQuery
>;
export type GetCoworkersInputOptionsLazyQueryHookResult = ReturnType<
  typeof useGetCoworkersInputOptionsLazyQuery
>;
export type GetCoworkersInputOptionsQueryResult = Apollo.QueryResult<
  GetCoworkersInputOptionsQuery,
  GetCoworkersInputOptionsQueryVariables
>;
export const GetTunnelsActivationValueDocument = gql`
  query GetTunnelsActivationValue {
    tunnelsActivationValue: getTunnelsActivationValue {
      entry
      exit
    }
  }
`;

/**
 * __useGetTunnelsActivationValueQuery__
 *
 * To run a query within a React component, call `useGetTunnelsActivationValueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTunnelsActivationValueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTunnelsActivationValueQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTunnelsActivationValueQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTunnelsActivationValueQuery,
    GetTunnelsActivationValueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTunnelsActivationValueQuery, GetTunnelsActivationValueQueryVariables>(
    GetTunnelsActivationValueDocument,
    options,
  );
}
export function useGetTunnelsActivationValueLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTunnelsActivationValueQuery,
    GetTunnelsActivationValueQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTunnelsActivationValueQuery,
    GetTunnelsActivationValueQueryVariables
  >(GetTunnelsActivationValueDocument, options);
}
export type GetTunnelsActivationValueQueryHookResult = ReturnType<
  typeof useGetTunnelsActivationValueQuery
>;
export type GetTunnelsActivationValueLazyQueryHookResult = ReturnType<
  typeof useGetTunnelsActivationValueLazyQuery
>;
export type GetTunnelsActivationValueQueryResult = Apollo.QueryResult<
  GetTunnelsActivationValueQuery,
  GetTunnelsActivationValueQueryVariables
>;
export const GetCoworkersMeetingInputOptionsDocument = gql`
  query GetCoworkersMeetingInputOptions($withoutMe: Boolean) {
    coworkers: coworkersMember(withoutMe: $withoutMe) {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        email
      }
    }
  }
`;

/**
 * __useGetCoworkersMeetingInputOptionsQuery__
 *
 * To run a query within a React component, call `useGetCoworkersMeetingInputOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoworkersMeetingInputOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoworkersMeetingInputOptionsQuery({
 *   variables: {
 *      withoutMe: // value for 'withoutMe'
 *   },
 * });
 */
export function useGetCoworkersMeetingInputOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCoworkersMeetingInputOptionsQuery,
    GetCoworkersMeetingInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCoworkersMeetingInputOptionsQuery,
    GetCoworkersMeetingInputOptionsQueryVariables
  >(GetCoworkersMeetingInputOptionsDocument, options);
}
export function useGetCoworkersMeetingInputOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoworkersMeetingInputOptionsQuery,
    GetCoworkersMeetingInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoworkersMeetingInputOptionsQuery,
    GetCoworkersMeetingInputOptionsQueryVariables
  >(GetCoworkersMeetingInputOptionsDocument, options);
}
export type GetCoworkersMeetingInputOptionsQueryHookResult = ReturnType<
  typeof useGetCoworkersMeetingInputOptionsQuery
>;
export type GetCoworkersMeetingInputOptionsLazyQueryHookResult = ReturnType<
  typeof useGetCoworkersMeetingInputOptionsLazyQuery
>;
export type GetCoworkersMeetingInputOptionsQueryResult = Apollo.QueryResult<
  GetCoworkersMeetingInputOptionsQuery,
  GetCoworkersMeetingInputOptionsQueryVariables
>;
export const GetCoworkersForBusinessUnitInputOptionsDocument = gql`
  query GetCoworkersForBusinessUnitInputOptions($fromBusinessUnit: Boolean, $queryString: String) {
    coworkers: coworkersMember(fromBusinessUnit: $fromBusinessUnit, queryString: $queryString) {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
    }
  }
`;

/**
 * __useGetCoworkersForBusinessUnitInputOptionsQuery__
 *
 * To run a query within a React component, call `useGetCoworkersForBusinessUnitInputOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoworkersForBusinessUnitInputOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoworkersForBusinessUnitInputOptionsQuery({
 *   variables: {
 *      fromBusinessUnit: // value for 'fromBusinessUnit'
 *      queryString: // value for 'queryString'
 *   },
 * });
 */
export function useGetCoworkersForBusinessUnitInputOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCoworkersForBusinessUnitInputOptionsQuery,
    GetCoworkersForBusinessUnitInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCoworkersForBusinessUnitInputOptionsQuery,
    GetCoworkersForBusinessUnitInputOptionsQueryVariables
  >(GetCoworkersForBusinessUnitInputOptionsDocument, options);
}
export function useGetCoworkersForBusinessUnitInputOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCoworkersForBusinessUnitInputOptionsQuery,
    GetCoworkersForBusinessUnitInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCoworkersForBusinessUnitInputOptionsQuery,
    GetCoworkersForBusinessUnitInputOptionsQueryVariables
  >(GetCoworkersForBusinessUnitInputOptionsDocument, options);
}
export type GetCoworkersForBusinessUnitInputOptionsQueryHookResult = ReturnType<
  typeof useGetCoworkersForBusinessUnitInputOptionsQuery
>;
export type GetCoworkersForBusinessUnitInputOptionsLazyQueryHookResult = ReturnType<
  typeof useGetCoworkersForBusinessUnitInputOptionsLazyQuery
>;
export type GetCoworkersForBusinessUnitInputOptionsQueryResult = Apollo.QueryResult<
  GetCoworkersForBusinessUnitInputOptionsQuery,
  GetCoworkersForBusinessUnitInputOptionsQueryVariables
>;
export const GetIndicatorSwitchItemsDocument = gql`
  query GetIndicatorSwitchItems {
    switchItems: getIndicatorSwitchItems {
      id
      name
      logo
      logoPublicUrl
      indicators {
        ...IndicatorInfo
        actions {
          ...ActionInfo
          createdBy {
            ...UserOnPostInfo
          }
          responsibles {
            id
            user {
              ...UserOnPostInfo
            }
          }
          indicator {
            id
          }
        }
        actionsCount
        comments {
          id
          content
          createdAt
          isMine
          user {
            ...UserOnPostInfo
          }
        }
      }
      communities: switchItemCommunities {
        id
        name
        logo
        logoPublicUrl
        indicators {
          ...IndicatorInfo
          actions {
            ...ActionInfo
            createdBy {
              ...UserOnPostInfo
            }
            responsibles {
              id
              user {
                ...UserOnPostInfo
              }
            }
            indicator {
              id
            }
          }
          actionsCount
          comments {
            id
            content
            createdAt
            isMine
            user {
              ...UserOnPostInfo
            }
          }
        }
      }
      businessUnits: switchItemBusinessUnits {
        id
        name
        logo
        logoPublicUrl
        indicators {
          ...IndicatorInfo
          actions {
            ...ActionInfo
            createdBy {
              ...UserOnPostInfo
            }
            responsibles {
              id
              user {
                ...UserOnPostInfo
              }
            }
            indicator {
              id
            }
          }
          actionsCount
          comments {
            id
            content
            createdAt
            isMine
            user {
              ...UserOnPostInfo
            }
          }
        }
      }
    }
  }
  ${IndicatorInfoFragmentDoc}
  ${ActionInfoFragmentDoc}
  ${UserOnPostInfoFragmentDoc}
`;

/**
 * __useGetIndicatorSwitchItemsQuery__
 *
 * To run a query within a React component, call `useGetIndicatorSwitchItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndicatorSwitchItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndicatorSwitchItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIndicatorSwitchItemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetIndicatorSwitchItemsQuery,
    GetIndicatorSwitchItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIndicatorSwitchItemsQuery, GetIndicatorSwitchItemsQueryVariables>(
    GetIndicatorSwitchItemsDocument,
    options,
  );
}
export function useGetIndicatorSwitchItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIndicatorSwitchItemsQuery,
    GetIndicatorSwitchItemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIndicatorSwitchItemsQuery, GetIndicatorSwitchItemsQueryVariables>(
    GetIndicatorSwitchItemsDocument,
    options,
  );
}
export type GetIndicatorSwitchItemsQueryHookResult = ReturnType<
  typeof useGetIndicatorSwitchItemsQuery
>;
export type GetIndicatorSwitchItemsLazyQueryHookResult = ReturnType<
  typeof useGetIndicatorSwitchItemsLazyQuery
>;
export type GetIndicatorSwitchItemsQueryResult = Apollo.QueryResult<
  GetIndicatorSwitchItemsQuery,
  GetIndicatorSwitchItemsQueryVariables
>;
export const GetAllTeamMembersSuggestionDocument = gql`
  query GetAllTeamMembersSuggestion(
    $teamId: ID
    $filterValue: String
    $fromAdmin: Boolean
    $page: Int
    $perPage: Int
  ) {
    data: getAllTeamMembersSuggestion(
      teamId: $teamId
      filterValue: $filterValue
      fromAdmin: $fromAdmin
      page: $page
      perPage: $perPage
    ) {
      ... on UserCompanyInvitation {
        id
        companyMemberAccess
        isConfirmed
        targetEmail
        hiringDate
        companyMemberRole
        token
        pendingTeamsIds
        communityMemberRole
        suspended
        user {
          ...UserOnPostInfo
        }
        pendingTeams {
          ...SimpleCommunityInfo
        }
      }
      ... on CompanyMember {
        id
        hiringDate
        userAccess
        role
        suspended
        businessUnit {
          id
          name
          slug
          logoPublicUrl
        }
        myCommunities {
          ...SimpleCommunityInfo
        }
        user {
          ...UserOnPostInfo
          role
        }
        nPlusOne {
          id
          user {
            ...UserOnPostInfo
          }
        }
      }
    }
  }
  ${UserOnPostInfoFragmentDoc}
  ${SimpleCommunityInfoFragmentDoc}
`;

/**
 * __useGetAllTeamMembersSuggestionQuery__
 *
 * To run a query within a React component, call `useGetAllTeamMembersSuggestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllTeamMembersSuggestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllTeamMembersSuggestionQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      filterValue: // value for 'filterValue'
 *      fromAdmin: // value for 'fromAdmin'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetAllTeamMembersSuggestionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllTeamMembersSuggestionQuery,
    GetAllTeamMembersSuggestionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAllTeamMembersSuggestionQuery,
    GetAllTeamMembersSuggestionQueryVariables
  >(GetAllTeamMembersSuggestionDocument, options);
}
export function useGetAllTeamMembersSuggestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllTeamMembersSuggestionQuery,
    GetAllTeamMembersSuggestionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllTeamMembersSuggestionQuery,
    GetAllTeamMembersSuggestionQueryVariables
  >(GetAllTeamMembersSuggestionDocument, options);
}
export type GetAllTeamMembersSuggestionQueryHookResult = ReturnType<
  typeof useGetAllTeamMembersSuggestionQuery
>;
export type GetAllTeamMembersSuggestionLazyQueryHookResult = ReturnType<
  typeof useGetAllTeamMembersSuggestionLazyQuery
>;
export type GetAllTeamMembersSuggestionQueryResult = Apollo.QueryResult<
  GetAllTeamMembersSuggestionQuery,
  GetAllTeamMembersSuggestionQueryVariables
>;
export const GetAllLanguagesDocument = gql`
  query GetAllLanguages {
    languages: getAllLanguages {
      id
      key
      label
    }
  }
`;

/**
 * __useGetAllLanguagesQuery__
 *
 * To run a query within a React component, call `useGetAllLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLanguagesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>(
    GetAllLanguagesDocument,
    options,
  );
}
export function useGetAllLanguagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllLanguagesQuery, GetAllLanguagesQueryVariables>(
    GetAllLanguagesDocument,
    options,
  );
}
export type GetAllLanguagesQueryHookResult = ReturnType<typeof useGetAllLanguagesQuery>;
export type GetAllLanguagesLazyQueryHookResult = ReturnType<typeof useGetAllLanguagesLazyQuery>;
export type GetAllLanguagesQueryResult = Apollo.QueryResult<
  GetAllLanguagesQuery,
  GetAllLanguagesQueryVariables
>;
export const UpdateEmployeeOfDayRankDocument = gql`
  mutation UpdateEmployeeOfDayRank($employeeId: ID!, $destination: Int) {
    updateEmployeeOfDayRank(employeeId: $employeeId, destination: $destination) {
      id
      employeeOfDayStatus
      employeeOfDayRank
    }
  }
`;
export type UpdateEmployeeOfDayRankMutationFn = Apollo.MutationFunction<
  UpdateEmployeeOfDayRankMutation,
  UpdateEmployeeOfDayRankMutationVariables
>;

/**
 * __useUpdateEmployeeOfDayRankMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeOfDayRankMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeOfDayRankMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeOfDayRankMutation, { data, loading, error }] = useUpdateEmployeeOfDayRankMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      destination: // value for 'destination'
 *   },
 * });
 */
export function useUpdateEmployeeOfDayRankMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmployeeOfDayRankMutation,
    UpdateEmployeeOfDayRankMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmployeeOfDayRankMutation,
    UpdateEmployeeOfDayRankMutationVariables
  >(UpdateEmployeeOfDayRankDocument, options);
}
export type UpdateEmployeeOfDayRankMutationHookResult = ReturnType<
  typeof useUpdateEmployeeOfDayRankMutation
>;
export type UpdateEmployeeOfDayRankMutationResult =
  Apollo.MutationResult<UpdateEmployeeOfDayRankMutation>;
export type UpdateEmployeeOfDayRankMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmployeeOfDayRankMutation,
  UpdateEmployeeOfDayRankMutationVariables
>;
export const UpdateEmployeeOfDayStatusDocument = gql`
  mutation UpdateEmployeeOfDayStatus(
    $employeeId: ID!
    $status: EmployeeOfDayStatus!
    $force: Boolean
  ) {
    updateEmployeeOfDayStatus(employeeId: $employeeId, status: $status, force: $force) {
      id
      employeeOfDayStatus
      employeeOfDayRank
    }
  }
`;
export type UpdateEmployeeOfDayStatusMutationFn = Apollo.MutationFunction<
  UpdateEmployeeOfDayStatusMutation,
  UpdateEmployeeOfDayStatusMutationVariables
>;

/**
 * __useUpdateEmployeeOfDayStatusMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeOfDayStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeOfDayStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeOfDayStatusMutation, { data, loading, error }] = useUpdateEmployeeOfDayStatusMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      status: // value for 'status'
 *      force: // value for 'force'
 *   },
 * });
 */
export function useUpdateEmployeeOfDayStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmployeeOfDayStatusMutation,
    UpdateEmployeeOfDayStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateEmployeeOfDayStatusMutation,
    UpdateEmployeeOfDayStatusMutationVariables
  >(UpdateEmployeeOfDayStatusDocument, options);
}
export type UpdateEmployeeOfDayStatusMutationHookResult = ReturnType<
  typeof useUpdateEmployeeOfDayStatusMutation
>;
export type UpdateEmployeeOfDayStatusMutationResult =
  Apollo.MutationResult<UpdateEmployeeOfDayStatusMutation>;
export type UpdateEmployeeOfDayStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmployeeOfDayStatusMutation,
  UpdateEmployeeOfDayStatusMutationVariables
>;
export const DeleteCompanyMemberDocument = gql`
  mutation DeleteCompanyMember($id: ID!) {
    deleteCompanyMember(id: $id) {
      id
    }
  }
`;
export type DeleteCompanyMemberMutationFn = Apollo.MutationFunction<
  DeleteCompanyMemberMutation,
  DeleteCompanyMemberMutationVariables
>;

/**
 * __useDeleteCompanyMemberMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMemberMutation, { data, loading, error }] = useDeleteCompanyMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCompanyMemberMutation,
    DeleteCompanyMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCompanyMemberMutation, DeleteCompanyMemberMutationVariables>(
    DeleteCompanyMemberDocument,
    options,
  );
}
export type DeleteCompanyMemberMutationHookResult = ReturnType<
  typeof useDeleteCompanyMemberMutation
>;
export type DeleteCompanyMemberMutationResult = Apollo.MutationResult<DeleteCompanyMemberMutation>;
export type DeleteCompanyMemberMutationOptions = Apollo.BaseMutationOptions<
  DeleteCompanyMemberMutation,
  DeleteCompanyMemberMutationVariables
>;
export const SuspendCompanyMemberDocument = gql`
  mutation SuspendCompanyMember($id: ID!, $suspended: Boolean!) {
    suspendCompanyMember(id: $id, suspended: $suspended) {
      id
      suspended
    }
  }
`;
export type SuspendCompanyMemberMutationFn = Apollo.MutationFunction<
  SuspendCompanyMemberMutation,
  SuspendCompanyMemberMutationVariables
>;

/**
 * __useSuspendCompanyMemberMutation__
 *
 * To run a mutation, you first call `useSuspendCompanyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuspendCompanyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suspendCompanyMemberMutation, { data, loading, error }] = useSuspendCompanyMemberMutation({
 *   variables: {
 *      id: // value for 'id'
 *      suspended: // value for 'suspended'
 *   },
 * });
 */
export function useSuspendCompanyMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SuspendCompanyMemberMutation,
    SuspendCompanyMemberMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SuspendCompanyMemberMutation, SuspendCompanyMemberMutationVariables>(
    SuspendCompanyMemberDocument,
    options,
  );
}
export type SuspendCompanyMemberMutationHookResult = ReturnType<
  typeof useSuspendCompanyMemberMutation
>;
export type SuspendCompanyMemberMutationResult =
  Apollo.MutationResult<SuspendCompanyMemberMutation>;
export type SuspendCompanyMemberMutationOptions = Apollo.BaseMutationOptions<
  SuspendCompanyMemberMutation,
  SuspendCompanyMemberMutationVariables
>;
export const SetIsFirstEntryDocument = gql`
  mutation SetIsFirstEntry {
    setIsFirstEntry
  }
`;
export type SetIsFirstEntryMutationFn = Apollo.MutationFunction<
  SetIsFirstEntryMutation,
  SetIsFirstEntryMutationVariables
>;

/**
 * __useSetIsFirstEntryMutation__
 *
 * To run a mutation, you first call `useSetIsFirstEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIsFirstEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIsFirstEntryMutation, { data, loading, error }] = useSetIsFirstEntryMutation({
 *   variables: {
 *   },
 * });
 */
export function useSetIsFirstEntryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetIsFirstEntryMutation,
    SetIsFirstEntryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetIsFirstEntryMutation, SetIsFirstEntryMutationVariables>(
    SetIsFirstEntryDocument,
    options,
  );
}
export type SetIsFirstEntryMutationHookResult = ReturnType<typeof useSetIsFirstEntryMutation>;
export type SetIsFirstEntryMutationResult = Apollo.MutationResult<SetIsFirstEntryMutation>;
export type SetIsFirstEntryMutationOptions = Apollo.BaseMutationOptions<
  SetIsFirstEntryMutation,
  SetIsFirstEntryMutationVariables
>;
export const SetLocaleDocument = gql`
  mutation SetLocale($key: String!) {
    setLocale(key: $key) {
      id
    }
  }
`;
export type SetLocaleMutationFn = Apollo.MutationFunction<
  SetLocaleMutation,
  SetLocaleMutationVariables
>;

/**
 * __useSetLocaleMutation__
 *
 * To run a mutation, you first call `useSetLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLocaleMutation, { data, loading, error }] = useSetLocaleMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useSetLocaleMutation(
  baseOptions?: Apollo.MutationHookOptions<SetLocaleMutation, SetLocaleMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetLocaleMutation, SetLocaleMutationVariables>(
    SetLocaleDocument,
    options,
  );
}
export type SetLocaleMutationHookResult = ReturnType<typeof useSetLocaleMutation>;
export type SetLocaleMutationResult = Apollo.MutationResult<SetLocaleMutation>;
export type SetLocaleMutationOptions = Apollo.BaseMutationOptions<
  SetLocaleMutation,
  SetLocaleMutationVariables
>;
export const ResetIncomingCallsCountDocument = gql`
  mutation ResetIncomingCallsCount($id: ID) {
    resetIncomingCallsCount(id: $id) {
      id
      incomingSemiInstantCallsCount
    }
  }
`;
export type ResetIncomingCallsCountMutationFn = Apollo.MutationFunction<
  ResetIncomingCallsCountMutation,
  ResetIncomingCallsCountMutationVariables
>;

/**
 * __useResetIncomingCallsCountMutation__
 *
 * To run a mutation, you first call `useResetIncomingCallsCountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetIncomingCallsCountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetIncomingCallsCountMutation, { data, loading, error }] = useResetIncomingCallsCountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResetIncomingCallsCountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetIncomingCallsCountMutation,
    ResetIncomingCallsCountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResetIncomingCallsCountMutation,
    ResetIncomingCallsCountMutationVariables
  >(ResetIncomingCallsCountDocument, options);
}
export type ResetIncomingCallsCountMutationHookResult = ReturnType<
  typeof useResetIncomingCallsCountMutation
>;
export type ResetIncomingCallsCountMutationResult =
  Apollo.MutationResult<ResetIncomingCallsCountMutation>;
export type ResetIncomingCallsCountMutationOptions = Apollo.BaseMutationOptions<
  ResetIncomingCallsCountMutation,
  ResetIncomingCallsCountMutationVariables
>;
export const GetRankedCompanyMembersDocument = gql`
  query GetRankedCompanyMembers($shuffle: Boolean) {
    getRankedCompanyMembers(shuffle: $shuffle) {
      id
      employeeOfDayStatus
      employeeOfDayRank
      suspended
      user {
        ...UserOnPostInfo
      }
      employeesOfDay {
        id
        day
      }
    }
  }
  ${UserOnPostInfoFragmentDoc}
`;

/**
 * __useGetRankedCompanyMembersQuery__
 *
 * To run a query within a React component, call `useGetRankedCompanyMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRankedCompanyMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRankedCompanyMembersQuery({
 *   variables: {
 *      shuffle: // value for 'shuffle'
 *   },
 * });
 */
export function useGetRankedCompanyMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRankedCompanyMembersQuery,
    GetRankedCompanyMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRankedCompanyMembersQuery, GetRankedCompanyMembersQueryVariables>(
    GetRankedCompanyMembersDocument,
    options,
  );
}
export function useGetRankedCompanyMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRankedCompanyMembersQuery,
    GetRankedCompanyMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRankedCompanyMembersQuery, GetRankedCompanyMembersQueryVariables>(
    GetRankedCompanyMembersDocument,
    options,
  );
}
export type GetRankedCompanyMembersQueryHookResult = ReturnType<
  typeof useGetRankedCompanyMembersQuery
>;
export type GetRankedCompanyMembersLazyQueryHookResult = ReturnType<
  typeof useGetRankedCompanyMembersLazyQuery
>;
export type GetRankedCompanyMembersQueryResult = Apollo.QueryResult<
  GetRankedCompanyMembersQuery,
  GetRankedCompanyMembersQueryVariables
>;
export const GetMyAccessDocument = gql`
  query GetMyAccess {
    currentCompanyMember {
      id
      userAccess
      suspended
    }
  }
`;

/**
 * __useGetMyAccessQuery__
 *
 * To run a query within a React component, call `useGetMyAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAccessQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyAccessQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyAccessQuery, GetMyAccessQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyAccessQuery, GetMyAccessQueryVariables>(GetMyAccessDocument, options);
}
export function useGetMyAccessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyAccessQuery, GetMyAccessQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyAccessQuery, GetMyAccessQueryVariables>(
    GetMyAccessDocument,
    options,
  );
}
export type GetMyAccessQueryHookResult = ReturnType<typeof useGetMyAccessQuery>;
export type GetMyAccessLazyQueryHookResult = ReturnType<typeof useGetMyAccessLazyQuery>;
export type GetMyAccessQueryResult = Apollo.QueryResult<
  GetMyAccessQuery,
  GetMyAccessQueryVariables
>;
export const GetMyRoleDocument = gql`
  query GetMyRole {
    currentCompanyMember {
      id
      role
    }
  }
`;

/**
 * __useGetMyRoleQuery__
 *
 * To run a query within a React component, call `useGetMyRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyRoleQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyRoleQuery, GetMyRoleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyRoleQuery, GetMyRoleQueryVariables>(GetMyRoleDocument, options);
}
export function useGetMyRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyRoleQuery, GetMyRoleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyRoleQuery, GetMyRoleQueryVariables>(GetMyRoleDocument, options);
}
export type GetMyRoleQueryHookResult = ReturnType<typeof useGetMyRoleQuery>;
export type GetMyRoleLazyQueryHookResult = ReturnType<typeof useGetMyRoleLazyQuery>;
export type GetMyRoleQueryResult = Apollo.QueryResult<GetMyRoleQuery, GetMyRoleQueryVariables>;
export const GetMyNPlusAndMinusOneDocument = gql`
  query GetMyNPlusAndMinusOne {
    currentCompanyMember {
      id
      nMinusOnes {
        id
        user {
          ...NplusAndMinusOneInfo
          companyMembers {
            id
            currentMotto {
              id
              content
            }
            currentProfessionnalWeather {
              id
              icon
            }
          }
        }
      }
    }
  }
  ${NplusAndMinusOneInfoFragmentDoc}
`;

/**
 * __useGetMyNPlusAndMinusOneQuery__
 *
 * To run a query within a React component, call `useGetMyNPlusAndMinusOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyNPlusAndMinusOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyNPlusAndMinusOneQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyNPlusAndMinusOneQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyNPlusAndMinusOneQuery,
    GetMyNPlusAndMinusOneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyNPlusAndMinusOneQuery, GetMyNPlusAndMinusOneQueryVariables>(
    GetMyNPlusAndMinusOneDocument,
    options,
  );
}
export function useGetMyNPlusAndMinusOneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyNPlusAndMinusOneQuery,
    GetMyNPlusAndMinusOneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyNPlusAndMinusOneQuery, GetMyNPlusAndMinusOneQueryVariables>(
    GetMyNPlusAndMinusOneDocument,
    options,
  );
}
export type GetMyNPlusAndMinusOneQueryHookResult = ReturnType<typeof useGetMyNPlusAndMinusOneQuery>;
export type GetMyNPlusAndMinusOneLazyQueryHookResult = ReturnType<
  typeof useGetMyNPlusAndMinusOneLazyQuery
>;
export type GetMyNPlusAndMinusOneQueryResult = Apollo.QueryResult<
  GetMyNPlusAndMinusOneQuery,
  GetMyNPlusAndMinusOneQueryVariables
>;
export const GetMyCurrentMottoDocument = gql`
  query GetMyCurrentMotto {
    currentCompanyMember {
      id
      currentMotto {
        id
        content
      }
    }
  }
`;

/**
 * __useGetMyCurrentMottoQuery__
 *
 * To run a query within a React component, call `useGetMyCurrentMottoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCurrentMottoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCurrentMottoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCurrentMottoQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyCurrentMottoQuery, GetMyCurrentMottoQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyCurrentMottoQuery, GetMyCurrentMottoQueryVariables>(
    GetMyCurrentMottoDocument,
    options,
  );
}
export function useGetMyCurrentMottoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyCurrentMottoQuery,
    GetMyCurrentMottoQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyCurrentMottoQuery, GetMyCurrentMottoQueryVariables>(
    GetMyCurrentMottoDocument,
    options,
  );
}
export type GetMyCurrentMottoQueryHookResult = ReturnType<typeof useGetMyCurrentMottoQuery>;
export type GetMyCurrentMottoLazyQueryHookResult = ReturnType<typeof useGetMyCurrentMottoLazyQuery>;
export type GetMyCurrentMottoQueryResult = Apollo.QueryResult<
  GetMyCurrentMottoQuery,
  GetMyCurrentMottoQueryVariables
>;
export const GetPeoplesDocument = gql`
  query GetPeoples(
    $field: PeopleFilterField
    $filters: [String!]
    $sortOrder: SortEnum
    $page: Int
    $perPage: Int
  ) {
    companyMembers: peoples(
      field: $field
      filters: $filters
      sortOrder: $sortOrder
      page: $page
      perPage: $perPage
    ) {
      id
      jobTitle
      hiringDate
      isMyBirthDay
      isMyChildBirthDay
      isMyWeddingDay
      exp
      expLevel {
        id
        name
        iconPublicUrl
      }
      currentProfessionalStatus {
        id
        icon
        title
      }
      user {
        id
        fullName
        email
        isOnline
        photoPublicUrl
        birthday
        bio
        mySharedHorizon
        familySituation
        personality
        adress
        phone
        skills {
          id
          title
        }
        successes {
          id
          title
        }
        passions {
          id
          title
        }
        previousCompanies {
          id
          name
        }
        pictures {
          id
          title
          imagePublicUrl
        }
        presentationVideo {
          id
          publicUrl
        }
      }
      nPlusOne {
        id
        jobTitle
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      myCommunities {
        id
        name
        logoPublicUrl
      }
      currentMotto {
        id
        content
      }
      businessUnit {
        id
        name
      }
    }
    meta: _peoplesMeta(field: $field, filters: $filters) {
      count
    }
  }
`;

/**
 * __useGetPeoplesQuery__
 *
 * To run a query within a React component, call `useGetPeoplesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeoplesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeoplesQuery({
 *   variables: {
 *      field: // value for 'field'
 *      filters: // value for 'filters'
 *      sortOrder: // value for 'sortOrder'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetPeoplesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPeoplesQuery, GetPeoplesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeoplesQuery, GetPeoplesQueryVariables>(GetPeoplesDocument, options);
}
export function useGetPeoplesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPeoplesQuery, GetPeoplesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeoplesQuery, GetPeoplesQueryVariables>(
    GetPeoplesDocument,
    options,
  );
}
export type GetPeoplesQueryHookResult = ReturnType<typeof useGetPeoplesQuery>;
export type GetPeoplesLazyQueryHookResult = ReturnType<typeof useGetPeoplesLazyQuery>;
export type GetPeoplesQueryResult = Apollo.QueryResult<GetPeoplesQuery, GetPeoplesQueryVariables>;
export const GetPeopleByIdDocument = gql`
  query GetPeopleById($id: ID!) {
    companyMember: getCompanyMemberById(id: $id) {
      id
      jobTitle
      hiringDate
      isMyBirthDay
      isMyChildBirthDay
      isMyWeddingDay
      exp
      expLevel {
        id
        name
        iconPublicUrl
      }
      user {
        id
        firstName
        lastName
        fullName
        email
        isOnline
        photoUrl
        photoPublicUrl
        birthday
        bio
        mySharedHorizon
        familySituation
        personality
        adress
        phone
        skills {
          id
          title
        }
        successes {
          id
          title
        }
        passions {
          id
          title
        }
        previousCompanies {
          id
          name
        }
        pictures {
          id
          title
          imageUrl
          imagePublicUrl
        }
        presentationVideo {
          id
          publicUrl
        }
      }
      nPlusOne {
        id
        jobTitle
        user {
          id
          fullName
          photoUrl
          photoPublicUrl
        }
      }
      myCommunities {
        id
        name
        logo
        logoPublicUrl
      }
      currentMotto {
        id
        content
      }
      businessUnit {
        id
        name
      }
    }
  }
`;

/**
 * __useGetPeopleByIdQuery__
 *
 * To run a query within a React component, call `useGetPeopleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPeopleByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPeopleByIdQuery, GetPeopleByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleByIdQuery, GetPeopleByIdQueryVariables>(
    GetPeopleByIdDocument,
    options,
  );
}
export function useGetPeopleByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPeopleByIdQuery, GetPeopleByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleByIdQuery, GetPeopleByIdQueryVariables>(
    GetPeopleByIdDocument,
    options,
  );
}
export type GetPeopleByIdQueryHookResult = ReturnType<typeof useGetPeopleByIdQuery>;
export type GetPeopleByIdLazyQueryHookResult = ReturnType<typeof useGetPeopleByIdLazyQuery>;
export type GetPeopleByIdQueryResult = Apollo.QueryResult<
  GetPeopleByIdQuery,
  GetPeopleByIdQueryVariables
>;
export const CoworkersListDocument = gql`
  query CoworkersList(
    $ids: [ID!]
    $withoutMe: Boolean
    $filterByWeatherId: ID
    $filterByCommunityId: ID
    $filterByEventTypeId: ID
    $filterByLocationId: ID
    $queryString: String
    $jobTitle: String
    $sortField: String
    $sortOrder: String
    $page: Int
    $perPage: Int
    $cacheKey: String
  ) {
    members: coworkersMember(
      ids: $ids
      withoutMe: $withoutMe
      filterByWeatherId: $filterByWeatherId
      filterByCommunityId: $filterByCommunityId
      filterByEventTypeId: $filterByEventTypeId
      filterByLocationId: $filterByLocationId
      queryString: $queryString
      jobTitle: $jobTitle
      sortField: $sortField
      sortOrder: $sortOrder
      page: $page
      perPage: $perPage
      cacheKey: $cacheKey
    ) {
      id
      jobTitle
      isItMyDay
      removed
      suspended
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        isItMyDay
        email
        isOnline
        removed
        suspended
        companyMembers {
          id
        }
      }
      currentLeave {
        id
      }
      currentEvents {
        title
        id
        communityEventType {
          iconPublicUrl
        }
      }
      currentMotto {
        id
        content
      }
      currentProfessionnalWeather {
        id
        icon
        title
      }
      currentProfessionalStatus {
        id
        title
        icon
      }
      nPlusOne {
        id
        jobTitle
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      nMinusOnes {
        id
        jobTitle
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      myCommunities {
        id
      }
      dayOff
      currentLocation {
        id
        title
        iconUrl
      }
    }
    total: _allCompanyMembersMeta(
      filter: { ids: $ids, q: $queryString, isByCurrentCompany: true }
    ) {
      count
    }
  }
`;

/**
 * __useCoworkersListQuery__
 *
 * To run a query within a React component, call `useCoworkersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoworkersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoworkersListQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      withoutMe: // value for 'withoutMe'
 *      filterByWeatherId: // value for 'filterByWeatherId'
 *      filterByCommunityId: // value for 'filterByCommunityId'
 *      filterByEventTypeId: // value for 'filterByEventTypeId'
 *      filterByLocationId: // value for 'filterByLocationId'
 *      queryString: // value for 'queryString'
 *      jobTitle: // value for 'jobTitle'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      cacheKey: // value for 'cacheKey'
 *   },
 * });
 */
export function useCoworkersListQuery(
  baseOptions?: Apollo.QueryHookOptions<CoworkersListQuery, CoworkersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoworkersListQuery, CoworkersListQueryVariables>(
    CoworkersListDocument,
    options,
  );
}
export function useCoworkersListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoworkersListQuery, CoworkersListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoworkersListQuery, CoworkersListQueryVariables>(
    CoworkersListDocument,
    options,
  );
}
export type CoworkersListQueryHookResult = ReturnType<typeof useCoworkersListQuery>;
export type CoworkersListLazyQueryHookResult = ReturnType<typeof useCoworkersListLazyQuery>;
export type CoworkersListQueryResult = Apollo.QueryResult<
  CoworkersListQuery,
  CoworkersListQueryVariables
>;
export const SearchPeopleDocument = gql`
  query SearchPeople($withoutMe: Boolean, $perPage: Int, $queryString: String) {
    peoples: people(withoutMe: $withoutMe, perPage: $perPage, queryString: $queryString) {
      id
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
    }
  }
`;

/**
 * __useSearchPeopleQuery__
 *
 * To run a query within a React component, call `useSearchPeopleQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPeopleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPeopleQuery({
 *   variables: {
 *      withoutMe: // value for 'withoutMe'
 *      perPage: // value for 'perPage'
 *      queryString: // value for 'queryString'
 *   },
 * });
 */
export function useSearchPeopleQuery(
  baseOptions?: Apollo.QueryHookOptions<SearchPeopleQuery, SearchPeopleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SearchPeopleQuery, SearchPeopleQueryVariables>(
    SearchPeopleDocument,
    options,
  );
}
export function useSearchPeopleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SearchPeopleQuery, SearchPeopleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SearchPeopleQuery, SearchPeopleQueryVariables>(
    SearchPeopleDocument,
    options,
  );
}
export type SearchPeopleQueryHookResult = ReturnType<typeof useSearchPeopleQuery>;
export type SearchPeopleLazyQueryHookResult = ReturnType<typeof useSearchPeopleLazyQuery>;
export type SearchPeopleQueryResult = Apollo.QueryResult<
  SearchPeopleQuery,
  SearchPeopleQueryVariables
>;
export const GetCompanyDirectorDocument = gql`
  query GetCompanyDirector {
    director: getCompanyDirector {
      id
      jobTitle
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
    }
  }
`;

/**
 * __useGetCompanyDirectorQuery__
 *
 * To run a query within a React component, call `useGetCompanyDirectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyDirectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyDirectorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyDirectorQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyDirectorQuery, GetCompanyDirectorQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyDirectorQuery, GetCompanyDirectorQueryVariables>(
    GetCompanyDirectorDocument,
    options,
  );
}
export function useGetCompanyDirectorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyDirectorQuery,
    GetCompanyDirectorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyDirectorQuery, GetCompanyDirectorQueryVariables>(
    GetCompanyDirectorDocument,
    options,
  );
}
export type GetCompanyDirectorQueryHookResult = ReturnType<typeof useGetCompanyDirectorQuery>;
export type GetCompanyDirectorLazyQueryHookResult = ReturnType<
  typeof useGetCompanyDirectorLazyQuery
>;
export type GetCompanyDirectorQueryResult = Apollo.QueryResult<
  GetCompanyDirectorQuery,
  GetCompanyDirectorQueryVariables
>;
export const GetCompanyMemberDirectorsDocument = gql`
  query GetCompanyMemberDirectors {
    directors: getCompanyMemberDirectors {
      id
      jobTitle
      user {
        id
        fullName
        photoUrl
        photoPublicUrl
      }
    }
  }
`;

/**
 * __useGetCompanyMemberDirectorsQuery__
 *
 * To run a query within a React component, call `useGetCompanyMemberDirectorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMemberDirectorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMemberDirectorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyMemberDirectorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyMemberDirectorsQuery,
    GetCompanyMemberDirectorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyMemberDirectorsQuery, GetCompanyMemberDirectorsQueryVariables>(
    GetCompanyMemberDirectorsDocument,
    options,
  );
}
export function useGetCompanyMemberDirectorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMemberDirectorsQuery,
    GetCompanyMemberDirectorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyMemberDirectorsQuery,
    GetCompanyMemberDirectorsQueryVariables
  >(GetCompanyMemberDirectorsDocument, options);
}
export type GetCompanyMemberDirectorsQueryHookResult = ReturnType<
  typeof useGetCompanyMemberDirectorsQuery
>;
export type GetCompanyMemberDirectorsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMemberDirectorsLazyQuery
>;
export type GetCompanyMemberDirectorsQueryResult = Apollo.QueryResult<
  GetCompanyMemberDirectorsQuery,
  GetCompanyMemberDirectorsQueryVariables
>;
export const GetCompanyMemberIsRemovedByIdDocument = gql`
  query GetCompanyMemberIsRemovedById($id: ID!) {
    companyMember: getCompanyMemberById(id: $id) {
      id
      removed
    }
  }
`;

/**
 * __useGetCompanyMemberIsRemovedByIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyMemberIsRemovedByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMemberIsRemovedByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMemberIsRemovedByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyMemberIsRemovedByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyMemberIsRemovedByIdQuery,
    GetCompanyMemberIsRemovedByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyMemberIsRemovedByIdQuery,
    GetCompanyMemberIsRemovedByIdQueryVariables
  >(GetCompanyMemberIsRemovedByIdDocument, options);
}
export function useGetCompanyMemberIsRemovedByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMemberIsRemovedByIdQuery,
    GetCompanyMemberIsRemovedByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyMemberIsRemovedByIdQuery,
    GetCompanyMemberIsRemovedByIdQueryVariables
  >(GetCompanyMemberIsRemovedByIdDocument, options);
}
export type GetCompanyMemberIsRemovedByIdQueryHookResult = ReturnType<
  typeof useGetCompanyMemberIsRemovedByIdQuery
>;
export type GetCompanyMemberIsRemovedByIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMemberIsRemovedByIdLazyQuery
>;
export type GetCompanyMemberIsRemovedByIdQueryResult = Apollo.QueryResult<
  GetCompanyMemberIsRemovedByIdQuery,
  GetCompanyMemberIsRemovedByIdQueryVariables
>;
export const GetCompanyMemberIsRemovedByIdsDocument = gql`
  query GetCompanyMemberIsRemovedByIds($ids: [ID!]) {
    companyMembers: getCompanyMemberByIds(ids: $ids) {
      id
      removed
    }
  }
`;

/**
 * __useGetCompanyMemberIsRemovedByIdsQuery__
 *
 * To run a query within a React component, call `useGetCompanyMemberIsRemovedByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMemberIsRemovedByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMemberIsRemovedByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetCompanyMemberIsRemovedByIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyMemberIsRemovedByIdsQuery,
    GetCompanyMemberIsRemovedByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyMemberIsRemovedByIdsQuery,
    GetCompanyMemberIsRemovedByIdsQueryVariables
  >(GetCompanyMemberIsRemovedByIdsDocument, options);
}
export function useGetCompanyMemberIsRemovedByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMemberIsRemovedByIdsQuery,
    GetCompanyMemberIsRemovedByIdsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyMemberIsRemovedByIdsQuery,
    GetCompanyMemberIsRemovedByIdsQueryVariables
  >(GetCompanyMemberIsRemovedByIdsDocument, options);
}
export type GetCompanyMemberIsRemovedByIdsQueryHookResult = ReturnType<
  typeof useGetCompanyMemberIsRemovedByIdsQuery
>;
export type GetCompanyMemberIsRemovedByIdsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMemberIsRemovedByIdsLazyQuery
>;
export type GetCompanyMemberIsRemovedByIdsQueryResult = Apollo.QueryResult<
  GetCompanyMemberIsRemovedByIdsQuery,
  GetCompanyMemberIsRemovedByIdsQueryVariables
>;
export const GetCommonAvatarInfoByMemberIdDocument = gql`
  query GetCommonAvatarInfoByMemberId($id: ID!) {
    companyMember: getCompanyMemberById(id: $id) {
      id
      dayOff
      exp
      expLevel {
        id
        name
        iconPublicUrl
      }
      currentProfessionnalWeather {
        id
        icon
        title
      }
      currentProfessionalStatus {
        id
        icon
        title
      }
      currentMotto {
        id
        content
      }
      currentLeave {
        id
      }
      currentLocation {
        id
        title
        iconUrl
      }
      currentEvents {
        title
        id
        communityEventType {
          iconPublicUrl
        }
      }
      user {
        id
        firstName
        lastName
        fullName
        photoUrl
        photoPublicUrl
        email
      }
      removed
    }
  }
`;

/**
 * __useGetCommonAvatarInfoByMemberIdQuery__
 *
 * To run a query within a React component, call `useGetCommonAvatarInfoByMemberIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommonAvatarInfoByMemberIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommonAvatarInfoByMemberIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCommonAvatarInfoByMemberIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommonAvatarInfoByMemberIdQuery,
    GetCommonAvatarInfoByMemberIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCommonAvatarInfoByMemberIdQuery,
    GetCommonAvatarInfoByMemberIdQueryVariables
  >(GetCommonAvatarInfoByMemberIdDocument, options);
}
export function useGetCommonAvatarInfoByMemberIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommonAvatarInfoByMemberIdQuery,
    GetCommonAvatarInfoByMemberIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommonAvatarInfoByMemberIdQuery,
    GetCommonAvatarInfoByMemberIdQueryVariables
  >(GetCommonAvatarInfoByMemberIdDocument, options);
}
export type GetCommonAvatarInfoByMemberIdQueryHookResult = ReturnType<
  typeof useGetCommonAvatarInfoByMemberIdQuery
>;
export type GetCommonAvatarInfoByMemberIdLazyQueryHookResult = ReturnType<
  typeof useGetCommonAvatarInfoByMemberIdLazyQuery
>;
export type GetCommonAvatarInfoByMemberIdQueryResult = Apollo.QueryResult<
  GetCommonAvatarInfoByMemberIdQuery,
  GetCommonAvatarInfoByMemberIdQueryVariables
>;
export const GetMyLocaleDocument = gql`
  query GetMyLocale {
    locale: getMyLocale {
      id
      key
      label
    }
  }
`;

/**
 * __useGetMyLocaleQuery__
 *
 * To run a query within a React component, call `useGetMyLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyLocaleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyLocaleQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyLocaleQuery, GetMyLocaleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyLocaleQuery, GetMyLocaleQueryVariables>(GetMyLocaleDocument, options);
}
export function useGetMyLocaleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyLocaleQuery, GetMyLocaleQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyLocaleQuery, GetMyLocaleQueryVariables>(
    GetMyLocaleDocument,
    options,
  );
}
export type GetMyLocaleQueryHookResult = ReturnType<typeof useGetMyLocaleQuery>;
export type GetMyLocaleLazyQueryHookResult = ReturnType<typeof useGetMyLocaleLazyQuery>;
export type GetMyLocaleQueryResult = Apollo.QueryResult<
  GetMyLocaleQuery,
  GetMyLocaleQueryVariables
>;
export const GetMyCurrentIdDocument = gql`
  query GetMyCurrentId {
    companyMember: currentCompanyMember {
      id
      user {
        id
        fullName
      }
    }
  }
`;

/**
 * __useGetMyCurrentIdQuery__
 *
 * To run a query within a React component, call `useGetMyCurrentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCurrentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCurrentIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCurrentIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyCurrentIdQuery, GetMyCurrentIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyCurrentIdQuery, GetMyCurrentIdQueryVariables>(
    GetMyCurrentIdDocument,
    options,
  );
}
export function useGetMyCurrentIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyCurrentIdQuery, GetMyCurrentIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyCurrentIdQuery, GetMyCurrentIdQueryVariables>(
    GetMyCurrentIdDocument,
    options,
  );
}
export type GetMyCurrentIdQueryHookResult = ReturnType<typeof useGetMyCurrentIdQuery>;
export type GetMyCurrentIdLazyQueryHookResult = ReturnType<typeof useGetMyCurrentIdLazyQuery>;
export type GetMyCurrentIdQueryResult = Apollo.QueryResult<
  GetMyCurrentIdQuery,
  GetMyCurrentIdQueryVariables
>;
export const GetUserManagementDataCountDocument = gql`
  query GetUserManagementDataCount {
    count: getUserManagementDataCount
  }
`;

/**
 * __useGetUserManagementDataCountQuery__
 *
 * To run a query within a React component, call `useGetUserManagementDataCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserManagementDataCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserManagementDataCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserManagementDataCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserManagementDataCountQuery,
    GetUserManagementDataCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserManagementDataCountQuery, GetUserManagementDataCountQueryVariables>(
    GetUserManagementDataCountDocument,
    options,
  );
}
export function useGetUserManagementDataCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserManagementDataCountQuery,
    GetUserManagementDataCountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserManagementDataCountQuery,
    GetUserManagementDataCountQueryVariables
  >(GetUserManagementDataCountDocument, options);
}
export type GetUserManagementDataCountQueryHookResult = ReturnType<
  typeof useGetUserManagementDataCountQuery
>;
export type GetUserManagementDataCountLazyQueryHookResult = ReturnType<
  typeof useGetUserManagementDataCountLazyQuery
>;
export type GetUserManagementDataCountQueryResult = Apollo.QueryResult<
  GetUserManagementDataCountQuery,
  GetUserManagementDataCountQueryVariables
>;
export const CanICreateLeaveForSomeoneDocument = gql`
  query CanICreateLeaveForSomeone {
    companyMember: _canICreateLeaveForSomeoneMeta {
      id
      canICreateLeaveForSomeone
      someOneIds
    }
  }
`;

/**
 * __useCanICreateLeaveForSomeoneQuery__
 *
 * To run a query within a React component, call `useCanICreateLeaveForSomeoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useCanICreateLeaveForSomeoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCanICreateLeaveForSomeoneQuery({
 *   variables: {
 *   },
 * });
 */
export function useCanICreateLeaveForSomeoneQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CanICreateLeaveForSomeoneQuery,
    CanICreateLeaveForSomeoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CanICreateLeaveForSomeoneQuery, CanICreateLeaveForSomeoneQueryVariables>(
    CanICreateLeaveForSomeoneDocument,
    options,
  );
}
export function useCanICreateLeaveForSomeoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CanICreateLeaveForSomeoneQuery,
    CanICreateLeaveForSomeoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CanICreateLeaveForSomeoneQuery,
    CanICreateLeaveForSomeoneQueryVariables
  >(CanICreateLeaveForSomeoneDocument, options);
}
export type CanICreateLeaveForSomeoneQueryHookResult = ReturnType<
  typeof useCanICreateLeaveForSomeoneQuery
>;
export type CanICreateLeaveForSomeoneLazyQueryHookResult = ReturnType<
  typeof useCanICreateLeaveForSomeoneLazyQuery
>;
export type CanICreateLeaveForSomeoneQueryResult = Apollo.QueryResult<
  CanICreateLeaveForSomeoneQuery,
  CanICreateLeaveForSomeoneQueryVariables
>;
export const GetCompanyMemberNPlusOneByIdDocument = gql`
  query getCompanyMemberNPlusOneById($id: ID!) {
    companyMember: getCompanyMemberById(id: $id) {
      id
      nPlusOne {
        id
        user {
          ...NplusAndMinusOneInfo
        }
      }
    }
  }
  ${NplusAndMinusOneInfoFragmentDoc}
`;

/**
 * __useGetCompanyMemberNPlusOneByIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyMemberNPlusOneByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMemberNPlusOneByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMemberNPlusOneByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyMemberNPlusOneByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyMemberNPlusOneByIdQuery,
    GetCompanyMemberNPlusOneByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCompanyMemberNPlusOneByIdQuery,
    GetCompanyMemberNPlusOneByIdQueryVariables
  >(GetCompanyMemberNPlusOneByIdDocument, options);
}
export function useGetCompanyMemberNPlusOneByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMemberNPlusOneByIdQuery,
    GetCompanyMemberNPlusOneByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCompanyMemberNPlusOneByIdQuery,
    GetCompanyMemberNPlusOneByIdQueryVariables
  >(GetCompanyMemberNPlusOneByIdDocument, options);
}
export type GetCompanyMemberNPlusOneByIdQueryHookResult = ReturnType<
  typeof useGetCompanyMemberNPlusOneByIdQuery
>;
export type GetCompanyMemberNPlusOneByIdLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMemberNPlusOneByIdLazyQuery
>;
export type GetCompanyMemberNPlusOneByIdQueryResult = Apollo.QueryResult<
  GetCompanyMemberNPlusOneByIdQuery,
  GetCompanyMemberNPlusOneByIdQueryVariables
>;
export const GetPeopleInputOptionsDocument = gql`
  query GetPeopleInputOptions {
    peopleInputOptions {
      type
      user {
        id
        fullName
        photoPublicUrl
      }
      team {
        id
        name
        logoPublicUrl
      }
      personality
      supervisor {
        id
        fullName
        photoPublicUrl
      }
      skill
      businessUnit {
        id
        name
      }
      success
      passion
      previousCompany
      email
      jobTitle
    }
  }
`;

/**
 * __useGetPeopleInputOptionsQuery__
 *
 * To run a query within a React component, call `useGetPeopleInputOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPeopleInputOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPeopleInputOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPeopleInputOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPeopleInputOptionsQuery,
    GetPeopleInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPeopleInputOptionsQuery, GetPeopleInputOptionsQueryVariables>(
    GetPeopleInputOptionsDocument,
    options,
  );
}
export function useGetPeopleInputOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPeopleInputOptionsQuery,
    GetPeopleInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPeopleInputOptionsQuery, GetPeopleInputOptionsQueryVariables>(
    GetPeopleInputOptionsDocument,
    options,
  );
}
export type GetPeopleInputOptionsQueryHookResult = ReturnType<typeof useGetPeopleInputOptionsQuery>;
export type GetPeopleInputOptionsLazyQueryHookResult = ReturnType<
  typeof useGetPeopleInputOptionsLazyQuery
>;
export type GetPeopleInputOptionsQueryResult = Apollo.QueryResult<
  GetPeopleInputOptionsQuery,
  GetPeopleInputOptionsQueryVariables
>;
export const GetFilteredCompanyMemberInputOptionsDocument = gql`
  query GetFilteredCompanyMemberInputOptions(
    $queryString: String
    $ids: [ID!]
    $businessUnitIds: [ID!]
    $communityIds: [ID!]
    $page: Int
    $perPage: Int
  ) {
    companyMembers: filteredCompanyMembersInputOption(
      queryString: $queryString
      ids: $ids
      businessUnitIds: $businessUnitIds
      communityIds: $communityIds
      page: $page
      perPage: $perPage
    ) {
      id
      user {
        id
        fullName
        photoPublicUrl
        isOnline
      }
    }
  }
`;

/**
 * __useGetFilteredCompanyMemberInputOptionsQuery__
 *
 * To run a query within a React component, call `useGetFilteredCompanyMemberInputOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredCompanyMemberInputOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredCompanyMemberInputOptionsQuery({
 *   variables: {
 *      queryString: // value for 'queryString'
 *      ids: // value for 'ids'
 *      businessUnitIds: // value for 'businessUnitIds'
 *      communityIds: // value for 'communityIds'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetFilteredCompanyMemberInputOptionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFilteredCompanyMemberInputOptionsQuery,
    GetFilteredCompanyMemberInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFilteredCompanyMemberInputOptionsQuery,
    GetFilteredCompanyMemberInputOptionsQueryVariables
  >(GetFilteredCompanyMemberInputOptionsDocument, options);
}
export function useGetFilteredCompanyMemberInputOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFilteredCompanyMemberInputOptionsQuery,
    GetFilteredCompanyMemberInputOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFilteredCompanyMemberInputOptionsQuery,
    GetFilteredCompanyMemberInputOptionsQueryVariables
  >(GetFilteredCompanyMemberInputOptionsDocument, options);
}
export type GetFilteredCompanyMemberInputOptionsQueryHookResult = ReturnType<
  typeof useGetFilteredCompanyMemberInputOptionsQuery
>;
export type GetFilteredCompanyMemberInputOptionsLazyQueryHookResult = ReturnType<
  typeof useGetFilteredCompanyMemberInputOptionsLazyQuery
>;
export type GetFilteredCompanyMemberInputOptionsQueryResult = Apollo.QueryResult<
  GetFilteredCompanyMemberInputOptionsQuery,
  GetFilteredCompanyMemberInputOptionsQueryVariables
>;
export const GetFilteredCompanyMemberInputOptionsToRemoveDocument = gql`
  query GetFilteredCompanyMemberInputOptionsToRemove(
    $selectedIds: [ID!]!
    $businessUnitIds: [ID!]
    $communityIds: [ID!]
  ) {
    companyMemberToRemoveIds: checkFilteredCompanyMembersInputOptionToRemove(
      selectedIds: $selectedIds
      businessUnitIds: $businessUnitIds
      communityIds: $communityIds
    )
  }
`;

/**
 * __useGetFilteredCompanyMemberInputOptionsToRemoveQuery__
 *
 * To run a query within a React component, call `useGetFilteredCompanyMemberInputOptionsToRemoveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFilteredCompanyMemberInputOptionsToRemoveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFilteredCompanyMemberInputOptionsToRemoveQuery({
 *   variables: {
 *      selectedIds: // value for 'selectedIds'
 *      businessUnitIds: // value for 'businessUnitIds'
 *      communityIds: // value for 'communityIds'
 *   },
 * });
 */
export function useGetFilteredCompanyMemberInputOptionsToRemoveQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFilteredCompanyMemberInputOptionsToRemoveQuery,
    GetFilteredCompanyMemberInputOptionsToRemoveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFilteredCompanyMemberInputOptionsToRemoveQuery,
    GetFilteredCompanyMemberInputOptionsToRemoveQueryVariables
  >(GetFilteredCompanyMemberInputOptionsToRemoveDocument, options);
}
export function useGetFilteredCompanyMemberInputOptionsToRemoveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFilteredCompanyMemberInputOptionsToRemoveQuery,
    GetFilteredCompanyMemberInputOptionsToRemoveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFilteredCompanyMemberInputOptionsToRemoveQuery,
    GetFilteredCompanyMemberInputOptionsToRemoveQueryVariables
  >(GetFilteredCompanyMemberInputOptionsToRemoveDocument, options);
}
export type GetFilteredCompanyMemberInputOptionsToRemoveQueryHookResult = ReturnType<
  typeof useGetFilteredCompanyMemberInputOptionsToRemoveQuery
>;
export type GetFilteredCompanyMemberInputOptionsToRemoveLazyQueryHookResult = ReturnType<
  typeof useGetFilteredCompanyMemberInputOptionsToRemoveLazyQuery
>;
export type GetFilteredCompanyMemberInputOptionsToRemoveQueryResult = Apollo.QueryResult<
  GetFilteredCompanyMemberInputOptionsToRemoveQuery,
  GetFilteredCompanyMemberInputOptionsToRemoveQueryVariables
>;
export const GetNMinusOnesDocument = gql`
  query GetNMinusOnes($queryString: String, $page: Int, $perPage: Int) {
    companyMembers: getNMinusOnes(queryString: $queryString, page: $page, perPage: $perPage) {
      id
      suspended
      user {
        id
        fullName
        photoPublicUrl
      }
      currentProfessionnalWeather {
        id
        icon
        title
      }
    }
    total: _getNMinusOnesMeta {
      count
    }
  }
`;

/**
 * __useGetNMinusOnesQuery__
 *
 * To run a query within a React component, call `useGetNMinusOnesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNMinusOnesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNMinusOnesQuery({
 *   variables: {
 *      queryString: // value for 'queryString'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetNMinusOnesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNMinusOnesQuery, GetNMinusOnesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNMinusOnesQuery, GetNMinusOnesQueryVariables>(
    GetNMinusOnesDocument,
    options,
  );
}
export function useGetNMinusOnesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNMinusOnesQuery, GetNMinusOnesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNMinusOnesQuery, GetNMinusOnesQueryVariables>(
    GetNMinusOnesDocument,
    options,
  );
}
export type GetNMinusOnesQueryHookResult = ReturnType<typeof useGetNMinusOnesQuery>;
export type GetNMinusOnesLazyQueryHookResult = ReturnType<typeof useGetNMinusOnesLazyQuery>;
export type GetNMinusOnesQueryResult = Apollo.QueryResult<
  GetNMinusOnesQuery,
  GetNMinusOnesQueryVariables
>;
export const GetMyExpLevelDocument = gql`
  query GetMyExpLevel {
    companyMember: currentCompanyMember {
      id
      exp
      expLevelUpIn
      expLevelProgress
      expLevel {
        id
        name
        iconPublicUrl
      }
    }
  }
`;

/**
 * __useGetMyExpLevelQuery__
 *
 * To run a query within a React component, call `useGetMyExpLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyExpLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyExpLevelQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyExpLevelQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyExpLevelQuery, GetMyExpLevelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyExpLevelQuery, GetMyExpLevelQueryVariables>(
    GetMyExpLevelDocument,
    options,
  );
}
export function useGetMyExpLevelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyExpLevelQuery, GetMyExpLevelQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyExpLevelQuery, GetMyExpLevelQueryVariables>(
    GetMyExpLevelDocument,
    options,
  );
}
export type GetMyExpLevelQueryHookResult = ReturnType<typeof useGetMyExpLevelQuery>;
export type GetMyExpLevelLazyQueryHookResult = ReturnType<typeof useGetMyExpLevelLazyQuery>;
export type GetMyExpLevelQueryResult = Apollo.QueryResult<
  GetMyExpLevelQuery,
  GetMyExpLevelQueryVariables
>;
export const GetMyCoinDocument = gql`
  query GetMyCoin {
    companyMember: currentCompanyMember {
      id
      coins
      unSeenCoins
    }
  }
`;

/**
 * __useGetMyCoinQuery__
 *
 * To run a query within a React component, call `useGetMyCoinQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCoinQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCoinQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCoinQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyCoinQuery, GetMyCoinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyCoinQuery, GetMyCoinQueryVariables>(GetMyCoinDocument, options);
}
export function useGetMyCoinLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyCoinQuery, GetMyCoinQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyCoinQuery, GetMyCoinQueryVariables>(GetMyCoinDocument, options);
}
export type GetMyCoinQueryHookResult = ReturnType<typeof useGetMyCoinQuery>;
export type GetMyCoinLazyQueryHookResult = ReturnType<typeof useGetMyCoinLazyQuery>;
export type GetMyCoinQueryResult = Apollo.QueryResult<GetMyCoinQuery, GetMyCoinQueryVariables>;
export const GetCompanyMemberExpLevelDocument = gql`
  query GetCompanyMemberExpLevel($id: ID!) {
    companyMember: getCompanyMemberById(id: $id) {
      id
      exp
      expLevel {
        id
        name
        iconPublicUrl
      }
    }
  }
`;

/**
 * __useGetCompanyMemberExpLevelQuery__
 *
 * To run a query within a React component, call `useGetCompanyMemberExpLevelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyMemberExpLevelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyMemberExpLevelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyMemberExpLevelQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCompanyMemberExpLevelQuery,
    GetCompanyMemberExpLevelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyMemberExpLevelQuery, GetCompanyMemberExpLevelQueryVariables>(
    GetCompanyMemberExpLevelDocument,
    options,
  );
}
export function useGetCompanyMemberExpLevelLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyMemberExpLevelQuery,
    GetCompanyMemberExpLevelQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyMemberExpLevelQuery, GetCompanyMemberExpLevelQueryVariables>(
    GetCompanyMemberExpLevelDocument,
    options,
  );
}
export type GetCompanyMemberExpLevelQueryHookResult = ReturnType<
  typeof useGetCompanyMemberExpLevelQuery
>;
export type GetCompanyMemberExpLevelLazyQueryHookResult = ReturnType<
  typeof useGetCompanyMemberExpLevelLazyQuery
>;
export type GetCompanyMemberExpLevelQueryResult = Apollo.QueryResult<
  GetCompanyMemberExpLevelQuery,
  GetCompanyMemberExpLevelQueryVariables
>;
export const CreateFileDocument = gql`
  mutation CreateFile($name: String!, $path: String, $type: String, $size: Int) {
    createFile(name: $name, path: $path, type: $type, size: $size) {
      ...FileInfo
    }
  }
  ${FileInfoFragmentDoc}
`;
export type CreateFileMutationFn = Apollo.MutationFunction<
  CreateFileMutation,
  CreateFileMutationVariables
>;

/**
 * __useCreateFileMutation__
 *
 * To run a mutation, you first call `useCreateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileMutation, { data, loading, error }] = useCreateFileMutation({
 *   variables: {
 *      name: // value for 'name'
 *      path: // value for 'path'
 *      type: // value for 'type'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useCreateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateFileMutation, CreateFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateFileMutation, CreateFileMutationVariables>(
    CreateFileDocument,
    options,
  );
}
export type CreateFileMutationHookResult = ReturnType<typeof useCreateFileMutation>;
export type CreateFileMutationResult = Apollo.MutationResult<CreateFileMutation>;
export type CreateFileMutationOptions = Apollo.BaseMutationOptions<
  CreateFileMutation,
  CreateFileMutationVariables
>;
export const DeleteFileDocument = gql`
  mutation DeleteFile($id: ID!) {
    deleteFile(id: $id) {
      ...FileInfo
    }
  }
  ${FileInfoFragmentDoc}
`;
export type DeleteFileMutationFn = Apollo.MutationFunction<
  DeleteFileMutation,
  DeleteFileMutationVariables
>;

/**
 * __useDeleteFileMutation__
 *
 * To run a mutation, you first call `useDeleteFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFileMutation, { data, loading, error }] = useDeleteFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFileMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(
    DeleteFileDocument,
    options,
  );
}
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export type DeleteFileMutationOptions = Apollo.BaseMutationOptions<
  DeleteFileMutation,
  DeleteFileMutationVariables
>;
export const AttachFileToCompanyDocument = gql`
  mutation AttachFileToCompany($id: ID) {
    attachFileToCompany(id: $id) {
      id
      type
      name
    }
  }
`;
export type AttachFileToCompanyMutationFn = Apollo.MutationFunction<
  AttachFileToCompanyMutation,
  AttachFileToCompanyMutationVariables
>;

/**
 * __useAttachFileToCompanyMutation__
 *
 * To run a mutation, you first call `useAttachFileToCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachFileToCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachFileToCompanyMutation, { data, loading, error }] = useAttachFileToCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAttachFileToCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachFileToCompanyMutation,
    AttachFileToCompanyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AttachFileToCompanyMutation, AttachFileToCompanyMutationVariables>(
    AttachFileToCompanyDocument,
    options,
  );
}
export type AttachFileToCompanyMutationHookResult = ReturnType<
  typeof useAttachFileToCompanyMutation
>;
export type AttachFileToCompanyMutationResult = Apollo.MutationResult<AttachFileToCompanyMutation>;
export type AttachFileToCompanyMutationOptions = Apollo.BaseMutationOptions<
  AttachFileToCompanyMutation,
  AttachFileToCompanyMutationVariables
>;
export const AttachFileToBusinessUnitDocument = gql`
  mutation AttachFileToBusinessUnit($ids: [ID!]!, $businessUnitId: ID!) {
    attachFileToBusinessUnit(ids: $ids, businessUnitId: $businessUnitId) {
      id
      type
      name
    }
  }
`;
export type AttachFileToBusinessUnitMutationFn = Apollo.MutationFunction<
  AttachFileToBusinessUnitMutation,
  AttachFileToBusinessUnitMutationVariables
>;

/**
 * __useAttachFileToBusinessUnitMutation__
 *
 * To run a mutation, you first call `useAttachFileToBusinessUnitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachFileToBusinessUnitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachFileToBusinessUnitMutation, { data, loading, error }] = useAttachFileToBusinessUnitMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      businessUnitId: // value for 'businessUnitId'
 *   },
 * });
 */
export function useAttachFileToBusinessUnitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AttachFileToBusinessUnitMutation,
    AttachFileToBusinessUnitMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AttachFileToBusinessUnitMutation,
    AttachFileToBusinessUnitMutationVariables
  >(AttachFileToBusinessUnitDocument, options);
}
export type AttachFileToBusinessUnitMutationHookResult = ReturnType<
  typeof useAttachFileToBusinessUnitMutation
>;
export type AttachFileToBusinessUnitMutationResult =
  Apollo.MutationResult<AttachFileToBusinessUnitMutation>;
export type AttachFileToBusinessUnitMutationOptions = Apollo.BaseMutationOptions<
  AttachFileToBusinessUnitMutation,
  AttachFileToBusinessUnitMutationVariables
>;
export const GetS3SignedUrlDocument = gql`
  query GetS3SignedURL(
    $name: String!
    $extension: String!
    $repo: String
    $expires: Int
    $s3Operation: S3Operation
  ) {
    localFileSignedUrl(
      name: $name
      extension: $extension
      repo: $repo
      expires: $expires
      s3Operation: $s3Operation
    ) {
      url
      key
    }
  }
`;

/**
 * __useGetS3SignedUrlQuery__
 *
 * To run a query within a React component, call `useGetS3SignedUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetS3SignedUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetS3SignedUrlQuery({
 *   variables: {
 *      name: // value for 'name'
 *      extension: // value for 'extension'
 *      repo: // value for 'repo'
 *      expires: // value for 'expires'
 *      s3Operation: // value for 's3Operation'
 *   },
 * });
 */
export function useGetS3SignedUrlQuery(
  baseOptions: Apollo.QueryHookOptions<GetS3SignedUrlQuery, GetS3SignedUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetS3SignedUrlQuery, GetS3SignedUrlQueryVariables>(
    GetS3SignedUrlDocument,
    options,
  );
}
export function useGetS3SignedUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetS3SignedUrlQuery, GetS3SignedUrlQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetS3SignedUrlQuery, GetS3SignedUrlQueryVariables>(
    GetS3SignedUrlDocument,
    options,
  );
}
export type GetS3SignedUrlQueryHookResult = ReturnType<typeof useGetS3SignedUrlQuery>;
export type GetS3SignedUrlLazyQueryHookResult = ReturnType<typeof useGetS3SignedUrlLazyQuery>;
export type GetS3SignedUrlQueryResult = Apollo.QueryResult<
  GetS3SignedUrlQuery,
  GetS3SignedUrlQueryVariables
>;
export const GetCompanyImagesDocument = gql`
  query GetCompanyImages {
    getCompanyImages {
      id
      name
      path
      publicUrl
    }
  }
`;

/**
 * __useGetCompanyImagesQuery__
 *
 * To run a query within a React component, call `useGetCompanyImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyImagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCompanyImagesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>(
    GetCompanyImagesDocument,
    options,
  );
}
export function useGetCompanyImagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyImagesQuery, GetCompanyImagesQueryVariables>(
    GetCompanyImagesDocument,
    options,
  );
}
export type GetCompanyImagesQueryHookResult = ReturnType<typeof useGetCompanyImagesQuery>;
export type GetCompanyImagesLazyQueryHookResult = ReturnType<typeof useGetCompanyImagesLazyQuery>;
export type GetCompanyImagesQueryResult = Apollo.QueryResult<
  GetCompanyImagesQuery,
  GetCompanyImagesQueryVariables
>;
export const GetAllFilesDocument = gql`
  query GetAllFiles($ids: [ID!]) {
    getAllFiles(ids: $ids) {
      id
      name
      type
    }
  }
`;

/**
 * __useGetAllFilesQuery__
 *
 * To run a query within a React component, call `useGetAllFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFilesQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetAllFilesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllFilesQuery, GetAllFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllFilesQuery, GetAllFilesQueryVariables>(GetAllFilesDocument, options);
}
export function useGetAllFilesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllFilesQuery, GetAllFilesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllFilesQuery, GetAllFilesQueryVariables>(
    GetAllFilesDocument,
    options,
  );
}
export type GetAllFilesQueryHookResult = ReturnType<typeof useGetAllFilesQuery>;
export type GetAllFilesLazyQueryHookResult = ReturnType<typeof useGetAllFilesLazyQuery>;
export type GetAllFilesQueryResult = Apollo.QueryResult<
  GetAllFilesQuery,
  GetAllFilesQueryVariables
>;
export const GetHelpMarkByTypeDocument = gql`
  query GetHelpMarkByType($type: HelpMarkType!) {
    helpMark: getHelpMarkByType(type: $type) {
      id
      en_title
      en_description
      fr_description
      fr_title
      image {
        id
        path
      }
    }
  }
`;

/**
 * __useGetHelpMarkByTypeQuery__
 *
 * To run a query within a React component, call `useGetHelpMarkByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpMarkByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpMarkByTypeQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetHelpMarkByTypeQuery(
  baseOptions: Apollo.QueryHookOptions<GetHelpMarkByTypeQuery, GetHelpMarkByTypeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHelpMarkByTypeQuery, GetHelpMarkByTypeQueryVariables>(
    GetHelpMarkByTypeDocument,
    options,
  );
}
export function useGetHelpMarkByTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHelpMarkByTypeQuery,
    GetHelpMarkByTypeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHelpMarkByTypeQuery, GetHelpMarkByTypeQueryVariables>(
    GetHelpMarkByTypeDocument,
    options,
  );
}
export type GetHelpMarkByTypeQueryHookResult = ReturnType<typeof useGetHelpMarkByTypeQuery>;
export type GetHelpMarkByTypeLazyQueryHookResult = ReturnType<typeof useGetHelpMarkByTypeLazyQuery>;
export type GetHelpMarkByTypeQueryResult = Apollo.QueryResult<
  GetHelpMarkByTypeQuery,
  GetHelpMarkByTypeQueryVariables
>;
export const CreatePostRequestDocument = gql`
  mutation CreatePostRequest($report: String!, $postId: ID!) {
    createPostRequest(report: $report, postId: $postId) {
      id
      report
    }
  }
`;
export type CreatePostRequestMutationFn = Apollo.MutationFunction<
  CreatePostRequestMutation,
  CreatePostRequestMutationVariables
>;

/**
 * __useCreatePostRequestMutation__
 *
 * To run a mutation, you first call `useCreatePostRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostRequestMutation, { data, loading, error }] = useCreatePostRequestMutation({
 *   variables: {
 *      report: // value for 'report'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreatePostRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostRequestMutation,
    CreatePostRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePostRequestMutation, CreatePostRequestMutationVariables>(
    CreatePostRequestDocument,
    options,
  );
}
export type CreatePostRequestMutationHookResult = ReturnType<typeof useCreatePostRequestMutation>;
export type CreatePostRequestMutationResult = Apollo.MutationResult<CreatePostRequestMutation>;
export type CreatePostRequestMutationOptions = Apollo.BaseMutationOptions<
  CreatePostRequestMutation,
  CreatePostRequestMutationVariables
>;
export const DeletePostRequestDocument = gql`
  mutation DeletePostRequest($postId: ID!) {
    deletePostRequest(postId: $postId) {
      id
    }
  }
`;
export type DeletePostRequestMutationFn = Apollo.MutationFunction<
  DeletePostRequestMutation,
  DeletePostRequestMutationVariables
>;

/**
 * __useDeletePostRequestMutation__
 *
 * To run a mutation, you first call `useDeletePostRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostRequestMutation, { data, loading, error }] = useDeletePostRequestMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useDeletePostRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePostRequestMutation,
    DeletePostRequestMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePostRequestMutation, DeletePostRequestMutationVariables>(
    DeletePostRequestDocument,
    options,
  );
}
export type DeletePostRequestMutationHookResult = ReturnType<typeof useDeletePostRequestMutation>;
export type DeletePostRequestMutationResult = Apollo.MutationResult<DeletePostRequestMutation>;
export type DeletePostRequestMutationOptions = Apollo.BaseMutationOptions<
  DeletePostRequestMutation,
  DeletePostRequestMutationVariables
>;
export const CreateIdeaCategoryDocument = gql`
  mutation CreateIdeaCategory($title: String!) {
    createIdeaCategory(title: $title) {
      id
    }
  }
`;
export type CreateIdeaCategoryMutationFn = Apollo.MutationFunction<
  CreateIdeaCategoryMutation,
  CreateIdeaCategoryMutationVariables
>;

/**
 * __useCreateIdeaCategoryMutation__
 *
 * To run a mutation, you first call `useCreateIdeaCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIdeaCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIdeaCategoryMutation, { data, loading, error }] = useCreateIdeaCategoryMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateIdeaCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIdeaCategoryMutation,
    CreateIdeaCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIdeaCategoryMutation, CreateIdeaCategoryMutationVariables>(
    CreateIdeaCategoryDocument,
    options,
  );
}
export type CreateIdeaCategoryMutationHookResult = ReturnType<typeof useCreateIdeaCategoryMutation>;
export type CreateIdeaCategoryMutationResult = Apollo.MutationResult<CreateIdeaCategoryMutation>;
export type CreateIdeaCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateIdeaCategoryMutation,
  CreateIdeaCategoryMutationVariables
>;
export const DeleteIdeaCategoryDocument = gql`
  mutation DeleteIdeaCategory($id: ID!) {
    deleteIdeaCategory(id: $id) {
      id
    }
  }
`;
export type DeleteIdeaCategoryMutationFn = Apollo.MutationFunction<
  DeleteIdeaCategoryMutation,
  DeleteIdeaCategoryMutationVariables
>;

/**
 * __useDeleteIdeaCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteIdeaCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIdeaCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIdeaCategoryMutation, { data, loading, error }] = useDeleteIdeaCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIdeaCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIdeaCategoryMutation,
    DeleteIdeaCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteIdeaCategoryMutation, DeleteIdeaCategoryMutationVariables>(
    DeleteIdeaCategoryDocument,
    options,
  );
}
export type DeleteIdeaCategoryMutationHookResult = ReturnType<typeof useDeleteIdeaCategoryMutation>;
export type DeleteIdeaCategoryMutationResult = Apollo.MutationResult<DeleteIdeaCategoryMutation>;
export type DeleteIdeaCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteIdeaCategoryMutation,
  DeleteIdeaCategoryMutationVariables
>;
export const GetCurrentCompanyIdeasDocument = gql`
  query getCurrentCompanyIdeas(
    $companyId: ID
    $postToShow: POST_TO_SHOW
    $sortBy: POST_SORT_TYPE
    $filterString: String
    $communityId: ID
    $limit: Int
    $page: Int
    $postType: PostType = IDEA
    $filters: [Filter!]
  ) {
    currentCompanyIdeas(
      companyId: $companyId
      postToShow: $postToShow
      sortBy: $sortBy
      filterString: $filterString
      communityId: $communityId
      limit: $limit
      page: $page
      filters: $filters
    ) {
      id
      postType
      title
      content
      createdAt
      commentCount
      isSeenByMe
      isMine
      isLikedByMe
      likeCount
      shareCount
      isAuthorHidden
      isReported
      postedAnonymously
      sharedCompanyMemberPosts {
        communities {
          id
          name
          logo
          logoPublicUrl
        }
      }
      communities {
        id
        name
        logoPublicUrl
        logo
      }
      responsibles {
        id
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
      cmCreatedBy {
        id
        user {
          id
          fullName
          photoPublicUrl
          photoUrl
        }
      }
      files {
        name
        path
        type
        publicUrl
      }
      myRating {
        id
        rating
      }
      ratingSummary {
        two
        one
        three
        four
        five
        totalRatingCount
        globalRating
      }
      ratingPeriod
      isRatingEnded
    }
    count: currentCompanyPostNumberByType(postType: $postType, filters: $filters)
    isFiltersApplied(filters: $filters, postType: $postType)
    currentWholeCompany {
      id
      name
    }
  }
`;

/**
 * __useGetCurrentCompanyIdeasQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyIdeasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyIdeasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyIdeasQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      postToShow: // value for 'postToShow'
 *      sortBy: // value for 'sortBy'
 *      filterString: // value for 'filterString'
 *      communityId: // value for 'communityId'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *      postType: // value for 'postType'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetCurrentCompanyIdeasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyIdeasQuery,
    GetCurrentCompanyIdeasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentCompanyIdeasQuery, GetCurrentCompanyIdeasQueryVariables>(
    GetCurrentCompanyIdeasDocument,
    options,
  );
}
export function useGetCurrentCompanyIdeasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyIdeasQuery,
    GetCurrentCompanyIdeasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentCompanyIdeasQuery, GetCurrentCompanyIdeasQueryVariables>(
    GetCurrentCompanyIdeasDocument,
    options,
  );
}
export type GetCurrentCompanyIdeasQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyIdeasQuery
>;
export type GetCurrentCompanyIdeasLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyIdeasLazyQuery
>;
export type GetCurrentCompanyIdeasQueryResult = Apollo.QueryResult<
  GetCurrentCompanyIdeasQuery,
  GetCurrentCompanyIdeasQueryVariables
>;
export const ViewPostDocument = gql`
  query ViewPost($id: ID!) {
    viewPost(id: $id) {
      id
      isSeenByMe
    }
  }
`;

/**
 * __useViewPostQuery__
 *
 * To run a query within a React component, call `useViewPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewPostQuery(
  baseOptions: Apollo.QueryHookOptions<ViewPostQuery, ViewPostQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ViewPostQuery, ViewPostQueryVariables>(ViewPostDocument, options);
}
export function useViewPostLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ViewPostQuery, ViewPostQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ViewPostQuery, ViewPostQueryVariables>(ViewPostDocument, options);
}
export type ViewPostQueryHookResult = ReturnType<typeof useViewPostQuery>;
export type ViewPostLazyQueryHookResult = ReturnType<typeof useViewPostLazyQuery>;
export type ViewPostQueryResult = Apollo.QueryResult<ViewPostQuery, ViewPostQueryVariables>;
export const GetCurrentCompanyAdminIdeasDocument = gql`
  query getCurrentCompanyAdminIdeas(
    $filter: PostFilter
    $page: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
  ) {
    currentCompanyAdminIdeas(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      ideas: posts {
        id
        postType
        title
        content
        createdAt
        isSeenByMe
        isMine
        isReported
        isHidden
        communities {
          id
          name
          logoPublicUrl
          logo
        }
        cmCreatedBy {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoPublicUrl
            photoUrl
          }
        }
      }
      total
    }
  }
`;

/**
 * __useGetCurrentCompanyAdminIdeasQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyAdminIdeasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyAdminIdeasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyAdminIdeasQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetCurrentCompanyAdminIdeasQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyAdminIdeasQuery,
    GetCurrentCompanyAdminIdeasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentCompanyAdminIdeasQuery,
    GetCurrentCompanyAdminIdeasQueryVariables
  >(GetCurrentCompanyAdminIdeasDocument, options);
}
export function useGetCurrentCompanyAdminIdeasLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyAdminIdeasQuery,
    GetCurrentCompanyAdminIdeasQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyAdminIdeasQuery,
    GetCurrentCompanyAdminIdeasQueryVariables
  >(GetCurrentCompanyAdminIdeasDocument, options);
}
export type GetCurrentCompanyAdminIdeasQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminIdeasQuery
>;
export type GetCurrentCompanyAdminIdeasLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminIdeasLazyQuery
>;
export type GetCurrentCompanyAdminIdeasQueryResult = Apollo.QueryResult<
  GetCurrentCompanyAdminIdeasQuery,
  GetCurrentCompanyAdminIdeasQueryVariables
>;
export const CreateBusinessUnitIndicatorDocument = gql`
  mutation CreateBusinessUnitIndicator(
    $businessUnitId: ID!
    $name: String!
    $value: String!
    $level: IndicatorLevel!
    $date: DateTime!
    $description: String
  ) {
    createBusinessUnitIndicator(
      input: {
        businessUnitId: $businessUnitId
        name: $name
        value: $value
        level: $level
        date: $date
        description: $description
      }
    ) {
      id
      name
      value
      level
      date
      description
      trend
      action
    }
  }
`;
export type CreateBusinessUnitIndicatorMutationFn = Apollo.MutationFunction<
  CreateBusinessUnitIndicatorMutation,
  CreateBusinessUnitIndicatorMutationVariables
>;

/**
 * __useCreateBusinessUnitIndicatorMutation__
 *
 * To run a mutation, you first call `useCreateBusinessUnitIndicatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBusinessUnitIndicatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBusinessUnitIndicatorMutation, { data, loading, error }] = useCreateBusinessUnitIndicatorMutation({
 *   variables: {
 *      businessUnitId: // value for 'businessUnitId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *      level: // value for 'level'
 *      date: // value for 'date'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateBusinessUnitIndicatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBusinessUnitIndicatorMutation,
    CreateBusinessUnitIndicatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateBusinessUnitIndicatorMutation,
    CreateBusinessUnitIndicatorMutationVariables
  >(CreateBusinessUnitIndicatorDocument, options);
}
export type CreateBusinessUnitIndicatorMutationHookResult = ReturnType<
  typeof useCreateBusinessUnitIndicatorMutation
>;
export type CreateBusinessUnitIndicatorMutationResult =
  Apollo.MutationResult<CreateBusinessUnitIndicatorMutation>;
export type CreateBusinessUnitIndicatorMutationOptions = Apollo.BaseMutationOptions<
  CreateBusinessUnitIndicatorMutation,
  CreateBusinessUnitIndicatorMutationVariables
>;
export const CreateCompanyIndicatorDocument = gql`
  mutation CreateCompanyIndicator(
    $companyId: ID
    $name: String!
    $value: String!
    $level: IndicatorLevel!
    $date: DateTime!
    $description: String
  ) {
    createCompanyIndicator(
      input: {
        companyId: $companyId
        name: $name
        value: $value
        level: $level
        date: $date
        description: $description
      }
    ) {
      id
      name
      value
      level
      date
      description
      trend
      action
    }
  }
`;
export type CreateCompanyIndicatorMutationFn = Apollo.MutationFunction<
  CreateCompanyIndicatorMutation,
  CreateCompanyIndicatorMutationVariables
>;

/**
 * __useCreateCompanyIndicatorMutation__
 *
 * To run a mutation, you first call `useCreateCompanyIndicatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyIndicatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyIndicatorMutation, { data, loading, error }] = useCreateCompanyIndicatorMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *      level: // value for 'level'
 *      date: // value for 'date'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateCompanyIndicatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyIndicatorMutation,
    CreateCompanyIndicatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCompanyIndicatorMutation,
    CreateCompanyIndicatorMutationVariables
  >(CreateCompanyIndicatorDocument, options);
}
export type CreateCompanyIndicatorMutationHookResult = ReturnType<
  typeof useCreateCompanyIndicatorMutation
>;
export type CreateCompanyIndicatorMutationResult =
  Apollo.MutationResult<CreateCompanyIndicatorMutation>;
export type CreateCompanyIndicatorMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyIndicatorMutation,
  CreateCompanyIndicatorMutationVariables
>;
export const CreateCommunityIndicatorDocument = gql`
  mutation CreateCommunityIndicator(
    $communityId: ID!
    $name: String!
    $value: String!
    $level: IndicatorLevel!
    $date: DateTime!
    $description: String
  ) {
    createCommunityIndicator(
      input: {
        communityId: $communityId
        name: $name
        value: $value
        level: $level
        date: $date
        description: $description
      }
    ) {
      id
      name
      value
      level
      date
      description
      trend
      action
    }
  }
`;
export type CreateCommunityIndicatorMutationFn = Apollo.MutationFunction<
  CreateCommunityIndicatorMutation,
  CreateCommunityIndicatorMutationVariables
>;

/**
 * __useCreateCommunityIndicatorMutation__
 *
 * To run a mutation, you first call `useCreateCommunityIndicatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityIndicatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityIndicatorMutation, { data, loading, error }] = useCreateCommunityIndicatorMutation({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *      level: // value for 'level'
 *      date: // value for 'date'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateCommunityIndicatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCommunityIndicatorMutation,
    CreateCommunityIndicatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCommunityIndicatorMutation,
    CreateCommunityIndicatorMutationVariables
  >(CreateCommunityIndicatorDocument, options);
}
export type CreateCommunityIndicatorMutationHookResult = ReturnType<
  typeof useCreateCommunityIndicatorMutation
>;
export type CreateCommunityIndicatorMutationResult =
  Apollo.MutationResult<CreateCommunityIndicatorMutation>;
export type CreateCommunityIndicatorMutationOptions = Apollo.BaseMutationOptions<
  CreateCommunityIndicatorMutation,
  CreateCommunityIndicatorMutationVariables
>;
export const CreatePostIndicatorDocument = gql`
  mutation CreatePostIndicator(
    $postId: ID!
    $name: String!
    $value: String!
    $level: IndicatorLevel!
    $date: DateTime!
    $description: String
  ) {
    createPostIndicator(
      input: {
        postId: $postId
        name: $name
        value: $value
        level: $level
        date: $date
        description: $description
      }
    ) {
      id
      name
      value
      level
      date
      description
      trend
      action
    }
  }
`;
export type CreatePostIndicatorMutationFn = Apollo.MutationFunction<
  CreatePostIndicatorMutation,
  CreatePostIndicatorMutationVariables
>;

/**
 * __useCreatePostIndicatorMutation__
 *
 * To run a mutation, you first call `useCreatePostIndicatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostIndicatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostIndicatorMutation, { data, loading, error }] = useCreatePostIndicatorMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      name: // value for 'name'
 *      value: // value for 'value'
 *      level: // value for 'level'
 *      date: // value for 'date'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreatePostIndicatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostIndicatorMutation,
    CreatePostIndicatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePostIndicatorMutation, CreatePostIndicatorMutationVariables>(
    CreatePostIndicatorDocument,
    options,
  );
}
export type CreatePostIndicatorMutationHookResult = ReturnType<
  typeof useCreatePostIndicatorMutation
>;
export type CreatePostIndicatorMutationResult = Apollo.MutationResult<CreatePostIndicatorMutation>;
export type CreatePostIndicatorMutationOptions = Apollo.BaseMutationOptions<
  CreatePostIndicatorMutation,
  CreatePostIndicatorMutationVariables
>;
export const DeleteIndicatorDocument = gql`
  mutation DeleteIndicator($id: ID!) {
    deleteIndicator(id: $id) {
      id
    }
  }
`;
export type DeleteIndicatorMutationFn = Apollo.MutationFunction<
  DeleteIndicatorMutation,
  DeleteIndicatorMutationVariables
>;

/**
 * __useDeleteIndicatorMutation__
 *
 * To run a mutation, you first call `useDeleteIndicatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIndicatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIndicatorMutation, { data, loading, error }] = useDeleteIndicatorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIndicatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIndicatorMutation,
    DeleteIndicatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteIndicatorMutation, DeleteIndicatorMutationVariables>(
    DeleteIndicatorDocument,
    options,
  );
}
export type DeleteIndicatorMutationHookResult = ReturnType<typeof useDeleteIndicatorMutation>;
export type DeleteIndicatorMutationResult = Apollo.MutationResult<DeleteIndicatorMutation>;
export type DeleteIndicatorMutationOptions = Apollo.BaseMutationOptions<
  DeleteIndicatorMutation,
  DeleteIndicatorMutationVariables
>;
export const UpdateIndicatorDocument = gql`
  mutation UpdateIndicator(
    $id: ID!
    $name: String
    $date: DateTime
    $description: String
    $value: String
    $level: IndicatorLevel
  ) {
    updateIndicator(
      input: {
        id: $id
        name: $name
        date: $date
        description: $description
        value: $value
        level: $level
      }
    ) {
      id
      name
      value
      level
      date
      description
      trend
      action
    }
  }
`;
export type UpdateIndicatorMutationFn = Apollo.MutationFunction<
  UpdateIndicatorMutation,
  UpdateIndicatorMutationVariables
>;

/**
 * __useUpdateIndicatorMutation__
 *
 * To run a mutation, you first call `useUpdateIndicatorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIndicatorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIndicatorMutation, { data, loading, error }] = useUpdateIndicatorMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      date: // value for 'date'
 *      description: // value for 'description'
 *      value: // value for 'value'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useUpdateIndicatorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateIndicatorMutation,
    UpdateIndicatorMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateIndicatorMutation, UpdateIndicatorMutationVariables>(
    UpdateIndicatorDocument,
    options,
  );
}
export type UpdateIndicatorMutationHookResult = ReturnType<typeof useUpdateIndicatorMutation>;
export type UpdateIndicatorMutationResult = Apollo.MutationResult<UpdateIndicatorMutation>;
export type UpdateIndicatorMutationOptions = Apollo.BaseMutationOptions<
  UpdateIndicatorMutation,
  UpdateIndicatorMutationVariables
>;
export const GetCompanyIndicatorsDocument = gql`
  query GetCompanyIndicators(
    $companyId: ID
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: IndicatorFilter
  ) {
    indicators: getCompanyIndicators(
      companyId: $companyId
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      name
      value
      level
      date
      description
      trend
      action
    }
  }
`;

/**
 * __useGetCompanyIndicatorsQuery__
 *
 * To run a query within a React component, call `useGetCompanyIndicatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyIndicatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyIndicatorsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCompanyIndicatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCompanyIndicatorsQuery,
    GetCompanyIndicatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyIndicatorsQuery, GetCompanyIndicatorsQueryVariables>(
    GetCompanyIndicatorsDocument,
    options,
  );
}
export function useGetCompanyIndicatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyIndicatorsQuery,
    GetCompanyIndicatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyIndicatorsQuery, GetCompanyIndicatorsQueryVariables>(
    GetCompanyIndicatorsDocument,
    options,
  );
}
export type GetCompanyIndicatorsQueryHookResult = ReturnType<typeof useGetCompanyIndicatorsQuery>;
export type GetCompanyIndicatorsLazyQueryHookResult = ReturnType<
  typeof useGetCompanyIndicatorsLazyQuery
>;
export type GetCompanyIndicatorsQueryResult = Apollo.QueryResult<
  GetCompanyIndicatorsQuery,
  GetCompanyIndicatorsQueryVariables
>;
export const GetCommunityIndicatorsDocument = gql`
  query GetCommunityIndicators(
    $communityId: ID!
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: IndicatorFilter
  ) {
    indicators: getCommunityIndicators(
      communityId: $communityId
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      name
      value
      level
      date
      description
      trend
      action
      histories {
        ...IndicatorHistoryInfo
      }
    }
    total: _allIndicatorsMeta(filter: $filter, communityId: $communityId) {
      count
    }
  }
  ${IndicatorHistoryInfoFragmentDoc}
`;

/**
 * __useGetCommunityIndicatorsQuery__
 *
 * To run a query within a React component, call `useGetCommunityIndicatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityIndicatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityIndicatorsQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetCommunityIndicatorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCommunityIndicatorsQuery,
    GetCommunityIndicatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommunityIndicatorsQuery, GetCommunityIndicatorsQueryVariables>(
    GetCommunityIndicatorsDocument,
    options,
  );
}
export function useGetCommunityIndicatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunityIndicatorsQuery,
    GetCommunityIndicatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommunityIndicatorsQuery, GetCommunityIndicatorsQueryVariables>(
    GetCommunityIndicatorsDocument,
    options,
  );
}
export type GetCommunityIndicatorsQueryHookResult = ReturnType<
  typeof useGetCommunityIndicatorsQuery
>;
export type GetCommunityIndicatorsLazyQueryHookResult = ReturnType<
  typeof useGetCommunityIndicatorsLazyQuery
>;
export type GetCommunityIndicatorsQueryResult = Apollo.QueryResult<
  GetCommunityIndicatorsQuery,
  GetCommunityIndicatorsQueryVariables
>;
export const GetMyCommunitiesIndicatorsDocument = gql`
  query GetMyCommunitiesIndicators(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: IndicatorFilter
  ) {
    indicators: getMyCommunitiesIndicators(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      name
      value
      level
      date
      description
      trend
      action
      community {
        id
        name
        logo
        logoPublicUrl
      }
    }
  }
`;

/**
 * __useGetMyCommunitiesIndicatorsQuery__
 *
 * To run a query within a React component, call `useGetMyCommunitiesIndicatorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCommunitiesIndicatorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCommunitiesIndicatorsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetMyCommunitiesIndicatorsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyCommunitiesIndicatorsQuery,
    GetMyCommunitiesIndicatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyCommunitiesIndicatorsQuery, GetMyCommunitiesIndicatorsQueryVariables>(
    GetMyCommunitiesIndicatorsDocument,
    options,
  );
}
export function useGetMyCommunitiesIndicatorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyCommunitiesIndicatorsQuery,
    GetMyCommunitiesIndicatorsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMyCommunitiesIndicatorsQuery,
    GetMyCommunitiesIndicatorsQueryVariables
  >(GetMyCommunitiesIndicatorsDocument, options);
}
export type GetMyCommunitiesIndicatorsQueryHookResult = ReturnType<
  typeof useGetMyCommunitiesIndicatorsQuery
>;
export type GetMyCommunitiesIndicatorsLazyQueryHookResult = ReturnType<
  typeof useGetMyCommunitiesIndicatorsLazyQuery
>;
export type GetMyCommunitiesIndicatorsQueryResult = Apollo.QueryResult<
  GetMyCommunitiesIndicatorsQuery,
  GetMyCommunitiesIndicatorsQueryVariables
>;
export const GetIndicatorByIdDocument = gql`
  query GetIndicatorById($id: ID!) {
    indicator: getIndicatorById(id: $id) {
      ...IndicatorInfo
      actionsCount
    }
  }
  ${IndicatorInfoFragmentDoc}
`;

/**
 * __useGetIndicatorByIdQuery__
 *
 * To run a query within a React component, call `useGetIndicatorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndicatorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndicatorByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIndicatorByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetIndicatorByIdQuery, GetIndicatorByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIndicatorByIdQuery, GetIndicatorByIdQueryVariables>(
    GetIndicatorByIdDocument,
    options,
  );
}
export function useGetIndicatorByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetIndicatorByIdQuery, GetIndicatorByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIndicatorByIdQuery, GetIndicatorByIdQueryVariables>(
    GetIndicatorByIdDocument,
    options,
  );
}
export type GetIndicatorByIdQueryHookResult = ReturnType<typeof useGetIndicatorByIdQuery>;
export type GetIndicatorByIdLazyQueryHookResult = ReturnType<typeof useGetIndicatorByIdLazyQuery>;
export type GetIndicatorByIdQueryResult = Apollo.QueryResult<
  GetIndicatorByIdQuery,
  GetIndicatorByIdQueryVariables
>;
export const GetIndicatorByIdForTeamDocument = gql`
  query GetIndicatorByIdForTeam($id: ID!) {
    indicator: getIndicatorById(id: $id) {
      ...IndicatorInfo
      actions {
        ...ActionInfo
        createdBy {
          ...UserOnPostInfo
        }
        responsibles {
          id
          user {
            ...UserOnPostInfo
          }
        }
        indicator {
          id
        }
      }
      actionsCount
      comments {
        id
        content
        createdAt
        isMine
        companyMember {
          id
          user {
            id
            fullName
          }
        }
        user {
          ...UserOnPostInfo
        }
        likedBy {
          user {
            ...UserOnPostInfo
            currentCompanyMember {
              id
            }
          }
        }
        likeCount
        isLikedByMe
      }
      histories {
        ...IndicatorHistoryInfo
      }
    }
  }
  ${IndicatorInfoFragmentDoc}
  ${ActionInfoFragmentDoc}
  ${UserOnPostInfoFragmentDoc}
  ${IndicatorHistoryInfoFragmentDoc}
`;

/**
 * __useGetIndicatorByIdForTeamQuery__
 *
 * To run a query within a React component, call `useGetIndicatorByIdForTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndicatorByIdForTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndicatorByIdForTeamQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIndicatorByIdForTeamQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIndicatorByIdForTeamQuery,
    GetIndicatorByIdForTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIndicatorByIdForTeamQuery, GetIndicatorByIdForTeamQueryVariables>(
    GetIndicatorByIdForTeamDocument,
    options,
  );
}
export function useGetIndicatorByIdForTeamLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIndicatorByIdForTeamQuery,
    GetIndicatorByIdForTeamQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIndicatorByIdForTeamQuery, GetIndicatorByIdForTeamQueryVariables>(
    GetIndicatorByIdForTeamDocument,
    options,
  );
}
export type GetIndicatorByIdForTeamQueryHookResult = ReturnType<
  typeof useGetIndicatorByIdForTeamQuery
>;
export type GetIndicatorByIdForTeamLazyQueryHookResult = ReturnType<
  typeof useGetIndicatorByIdForTeamLazyQuery
>;
export type GetIndicatorByIdForTeamQueryResult = Apollo.QueryResult<
  GetIndicatorByIdForTeamQuery,
  GetIndicatorByIdForTeamQueryVariables
>;
export const GetIndicatorHistoriesByIndicatorIdDocument = gql`
  query GetIndicatorHistoriesByIndicatorId($indicatorId: ID!) {
    indicatorHistories: getIndicatorHistoriesByIndicatorId(indicatorId: $indicatorId) {
      ...IndicatorHistoryInfo
    }
  }
  ${IndicatorHistoryInfoFragmentDoc}
`;

/**
 * __useGetIndicatorHistoriesByIndicatorIdQuery__
 *
 * To run a query within a React component, call `useGetIndicatorHistoriesByIndicatorIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndicatorHistoriesByIndicatorIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndicatorHistoriesByIndicatorIdQuery({
 *   variables: {
 *      indicatorId: // value for 'indicatorId'
 *   },
 * });
 */
export function useGetIndicatorHistoriesByIndicatorIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIndicatorHistoriesByIndicatorIdQuery,
    GetIndicatorHistoriesByIndicatorIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetIndicatorHistoriesByIndicatorIdQuery,
    GetIndicatorHistoriesByIndicatorIdQueryVariables
  >(GetIndicatorHistoriesByIndicatorIdDocument, options);
}
export function useGetIndicatorHistoriesByIndicatorIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIndicatorHistoriesByIndicatorIdQuery,
    GetIndicatorHistoriesByIndicatorIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetIndicatorHistoriesByIndicatorIdQuery,
    GetIndicatorHistoriesByIndicatorIdQueryVariables
  >(GetIndicatorHistoriesByIndicatorIdDocument, options);
}
export type GetIndicatorHistoriesByIndicatorIdQueryHookResult = ReturnType<
  typeof useGetIndicatorHistoriesByIndicatorIdQuery
>;
export type GetIndicatorHistoriesByIndicatorIdLazyQueryHookResult = ReturnType<
  typeof useGetIndicatorHistoriesByIndicatorIdLazyQuery
>;
export type GetIndicatorHistoriesByIndicatorIdQueryResult = Apollo.QueryResult<
  GetIndicatorHistoriesByIndicatorIdQuery,
  GetIndicatorHistoriesByIndicatorIdQueryVariables
>;
export const GetIndicatorHistoryByIdDocument = gql`
  query getIndicatorHistoryById($id: ID!) {
    indicatorHistory: getIndicatorHistoryById(id: $id) {
      ...IndicatorHistoryInfo
    }
  }
  ${IndicatorHistoryInfoFragmentDoc}
`;

/**
 * __useGetIndicatorHistoryByIdQuery__
 *
 * To run a query within a React component, call `useGetIndicatorHistoryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIndicatorHistoryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIndicatorHistoryByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetIndicatorHistoryByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetIndicatorHistoryByIdQuery,
    GetIndicatorHistoryByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetIndicatorHistoryByIdQuery, GetIndicatorHistoryByIdQueryVariables>(
    GetIndicatorHistoryByIdDocument,
    options,
  );
}
export function useGetIndicatorHistoryByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetIndicatorHistoryByIdQuery,
    GetIndicatorHistoryByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetIndicatorHistoryByIdQuery, GetIndicatorHistoryByIdQueryVariables>(
    GetIndicatorHistoryByIdDocument,
    options,
  );
}
export type GetIndicatorHistoryByIdQueryHookResult = ReturnType<
  typeof useGetIndicatorHistoryByIdQuery
>;
export type GetIndicatorHistoryByIdLazyQueryHookResult = ReturnType<
  typeof useGetIndicatorHistoryByIdLazyQuery
>;
export type GetIndicatorHistoryByIdQueryResult = Apollo.QueryResult<
  GetIndicatorHistoryByIdQuery,
  GetIndicatorHistoryByIdQueryVariables
>;
export const CreateLeaveDocument = gql`
  mutation CreateLeave(
    $start: DateTime!
    $ending: DateTime!
    $reasonId: ID!
    $otherReason: String
    $specialDayOff: Boolean
    $makePublic: Boolean
    $isHalfDay: Boolean
    $ownerId: ID
    $creatorId: ID
    $attachementIds: [ID!]!
    $validatorIds: [ID!]!
    $peopleToNotifyIds: [ID!]!
    $daysDuration: [LeaveDayDuration!]
  ) {
    createLeave(
      input: {
        start: $start
        ending: $ending
        reasonId: $reasonId
        otherReason: $otherReason
        specialDayOff: $specialDayOff
        makePublic: $makePublic
        isHalfDay: $isHalfDay
        ownerId: $ownerId
        creatorId: $creatorId
        attachementIds: $attachementIds
        validatorIds: $validatorIds
        peopleToNotifyIds: $peopleToNotifyIds
        daysDuration: $daysDuration
      }
    ) {
      id
    }
  }
`;
export type CreateLeaveMutationFn = Apollo.MutationFunction<
  CreateLeaveMutation,
  CreateLeaveMutationVariables
>;

/**
 * __useCreateLeaveMutation__
 *
 * To run a mutation, you first call `useCreateLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeaveMutation, { data, loading, error }] = useCreateLeaveMutation({
 *   variables: {
 *      start: // value for 'start'
 *      ending: // value for 'ending'
 *      reasonId: // value for 'reasonId'
 *      otherReason: // value for 'otherReason'
 *      specialDayOff: // value for 'specialDayOff'
 *      makePublic: // value for 'makePublic'
 *      isHalfDay: // value for 'isHalfDay'
 *      ownerId: // value for 'ownerId'
 *      creatorId: // value for 'creatorId'
 *      attachementIds: // value for 'attachementIds'
 *      validatorIds: // value for 'validatorIds'
 *      peopleToNotifyIds: // value for 'peopleToNotifyIds'
 *      daysDuration: // value for 'daysDuration'
 *   },
 * });
 */
export function useCreateLeaveMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateLeaveMutation, CreateLeaveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateLeaveMutation, CreateLeaveMutationVariables>(
    CreateLeaveDocument,
    options,
  );
}
export type CreateLeaveMutationHookResult = ReturnType<typeof useCreateLeaveMutation>;
export type CreateLeaveMutationResult = Apollo.MutationResult<CreateLeaveMutation>;
export type CreateLeaveMutationOptions = Apollo.BaseMutationOptions<
  CreateLeaveMutation,
  CreateLeaveMutationVariables
>;
export const ValidateLeaveDocument = gql`
  mutation ValidateLeave($id: ID!, $validatorId: ID, $status: LeaveStatus!) {
    validateLeave(id: $id, validatorId: $validatorId, status: $status) {
      id
      status
    }
  }
`;
export type ValidateLeaveMutationFn = Apollo.MutationFunction<
  ValidateLeaveMutation,
  ValidateLeaveMutationVariables
>;

/**
 * __useValidateLeaveMutation__
 *
 * To run a mutation, you first call `useValidateLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateLeaveMutation, { data, loading, error }] = useValidateLeaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      validatorId: // value for 'validatorId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useValidateLeaveMutation(
  baseOptions?: Apollo.MutationHookOptions<ValidateLeaveMutation, ValidateLeaveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidateLeaveMutation, ValidateLeaveMutationVariables>(
    ValidateLeaveDocument,
    options,
  );
}
export type ValidateLeaveMutationHookResult = ReturnType<typeof useValidateLeaveMutation>;
export type ValidateLeaveMutationResult = Apollo.MutationResult<ValidateLeaveMutation>;
export type ValidateLeaveMutationOptions = Apollo.BaseMutationOptions<
  ValidateLeaveMutation,
  ValidateLeaveMutationVariables
>;
export const UpdateLeaveDocument = gql`
  mutation UpdateLeave(
    $id: ID!
    $start: DateTime!
    $ending: DateTime!
    $reasonId: ID!
    $otherReason: String
    $specialDayOff: Boolean!
    $makePublic: Boolean!
    $isHalfDay: Boolean
    $attachementIds: [ID!]!
    $validatorIds: [ID!]!
    $peopleToNotifyIds: [ID!]!
    $daysDuration: [LeaveDayDuration!]
  ) {
    updateLeave(
      input: {
        id: $id
        start: $start
        ending: $ending
        reasonId: $reasonId
        otherReason: $otherReason
        specialDayOff: $specialDayOff
        makePublic: $makePublic
        isHalfDay: $isHalfDay
        attachementIds: $attachementIds
        validatorIds: $validatorIds
        peopleToNotifyIds: $peopleToNotifyIds
        daysDuration: $daysDuration
      }
    ) {
      id
      start
      ending
      otherReason
      specialDayOff
      isPublic
      status
      isHalfDay
    }
  }
`;
export type UpdateLeaveMutationFn = Apollo.MutationFunction<
  UpdateLeaveMutation,
  UpdateLeaveMutationVariables
>;

/**
 * __useUpdateLeaveMutation__
 *
 * To run a mutation, you first call `useUpdateLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeaveMutation, { data, loading, error }] = useUpdateLeaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      start: // value for 'start'
 *      ending: // value for 'ending'
 *      reasonId: // value for 'reasonId'
 *      otherReason: // value for 'otherReason'
 *      specialDayOff: // value for 'specialDayOff'
 *      makePublic: // value for 'makePublic'
 *      isHalfDay: // value for 'isHalfDay'
 *      attachementIds: // value for 'attachementIds'
 *      validatorIds: // value for 'validatorIds'
 *      peopleToNotifyIds: // value for 'peopleToNotifyIds'
 *      daysDuration: // value for 'daysDuration'
 *   },
 * });
 */
export function useUpdateLeaveMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLeaveMutation, UpdateLeaveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLeaveMutation, UpdateLeaveMutationVariables>(
    UpdateLeaveDocument,
    options,
  );
}
export type UpdateLeaveMutationHookResult = ReturnType<typeof useUpdateLeaveMutation>;
export type UpdateLeaveMutationResult = Apollo.MutationResult<UpdateLeaveMutation>;
export type UpdateLeaveMutationOptions = Apollo.BaseMutationOptions<
  UpdateLeaveMutation,
  UpdateLeaveMutationVariables
>;
export const DeleteLeaveDocument = gql`
  mutation DeleteLeave($id: ID!) {
    deleteLeave(id: $id) {
      id
    }
  }
`;
export type DeleteLeaveMutationFn = Apollo.MutationFunction<
  DeleteLeaveMutation,
  DeleteLeaveMutationVariables
>;

/**
 * __useDeleteLeaveMutation__
 *
 * To run a mutation, you first call `useDeleteLeaveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLeaveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLeaveMutation, { data, loading, error }] = useDeleteLeaveMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLeaveMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLeaveMutation, DeleteLeaveMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLeaveMutation, DeleteLeaveMutationVariables>(
    DeleteLeaveDocument,
    options,
  );
}
export type DeleteLeaveMutationHookResult = ReturnType<typeof useDeleteLeaveMutation>;
export type DeleteLeaveMutationResult = Apollo.MutationResult<DeleteLeaveMutation>;
export type DeleteLeaveMutationOptions = Apollo.BaseMutationOptions<
  DeleteLeaveMutation,
  DeleteLeaveMutationVariables
>;
export const ValidatedLeavesDocument = gql`
  mutation validatedLeaves(
    $start: DateTime!
    $ending: DateTime!
    $companyMemberIds: [ID!]
    $reasonIds: [ID!]
    $leavesStatusToDownload: LeavesStatusToDownload
  ) {
    validatedLeaves(
      start: $start
      ending: $ending
      companyMemberIds: $companyMemberIds
      reasonIds: $reasonIds
      leavesStatusToDownload: $leavesStatusToDownload
    ) {
      day
      leaves {
        id
        start
        ending
        days
        otherReason
        specialDayOff
        isHalfDay
        isPublic
        status
        validationDate
        reasons {
          id
          title
        }
        owner {
          id
          businessUnit {
            id
            name
          }
          userCommunity {
            id
            community {
              id
              name
            }
          }
          user {
            ...UserInLeaveFragment
          }
          nPlusOne {
            id
            user {
              ...UserInLeaveFragment
            }
          }
        }
        attachements {
          id
          name
        }
        validators {
          id
          user {
            id
            fullName
          }
        }
      }
    }
  }
  ${UserInLeaveFragmentFragmentDoc}
`;
export type ValidatedLeavesMutationFn = Apollo.MutationFunction<
  ValidatedLeavesMutation,
  ValidatedLeavesMutationVariables
>;

/**
 * __useValidatedLeavesMutation__
 *
 * To run a mutation, you first call `useValidatedLeavesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidatedLeavesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validatedLeavesMutation, { data, loading, error }] = useValidatedLeavesMutation({
 *   variables: {
 *      start: // value for 'start'
 *      ending: // value for 'ending'
 *      companyMemberIds: // value for 'companyMemberIds'
 *      reasonIds: // value for 'reasonIds'
 *      leavesStatusToDownload: // value for 'leavesStatusToDownload'
 *   },
 * });
 */
export function useValidatedLeavesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidatedLeavesMutation,
    ValidatedLeavesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ValidatedLeavesMutation, ValidatedLeavesMutationVariables>(
    ValidatedLeavesDocument,
    options,
  );
}
export type ValidatedLeavesMutationHookResult = ReturnType<typeof useValidatedLeavesMutation>;
export type ValidatedLeavesMutationResult = Apollo.MutationResult<ValidatedLeavesMutation>;
export type ValidatedLeavesMutationOptions = Apollo.BaseMutationOptions<
  ValidatedLeavesMutation,
  ValidatedLeavesMutationVariables
>;
export const GetCurrentLeaveReasonsDocument = gql`
  query getCurrentLeaveReasons {
    leaveReasonsByCompany {
      id
      title
    }
  }
`;

/**
 * __useGetCurrentLeaveReasonsQuery__
 *
 * To run a query within a React component, call `useGetCurrentLeaveReasonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentLeaveReasonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentLeaveReasonsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentLeaveReasonsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentLeaveReasonsQuery,
    GetCurrentLeaveReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentLeaveReasonsQuery, GetCurrentLeaveReasonsQueryVariables>(
    GetCurrentLeaveReasonsDocument,
    options,
  );
}
export function useGetCurrentLeaveReasonsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentLeaveReasonsQuery,
    GetCurrentLeaveReasonsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentLeaveReasonsQuery, GetCurrentLeaveReasonsQueryVariables>(
    GetCurrentLeaveReasonsDocument,
    options,
  );
}
export type GetCurrentLeaveReasonsQueryHookResult = ReturnType<
  typeof useGetCurrentLeaveReasonsQuery
>;
export type GetCurrentLeaveReasonsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentLeaveReasonsLazyQuery
>;
export type GetCurrentLeaveReasonsQueryResult = Apollo.QueryResult<
  GetCurrentLeaveReasonsQuery,
  GetCurrentLeaveReasonsQueryVariables
>;
export const GetAllowedViewLeavesToMeDocument = gql`
  query getAllowedViewLeavesToMe(
    $date: DateTime!
    $view: String!
    $dateRange: CalendarDateRangeInput
    $communityId: ID
    $communitiesFilter: CommunitiesFilter
    $statusFilter: LeaveStatusFilter
  ) {
    leaves: allLeaves(
      input: {
        date: $date
        view: $view
        dateRange: $dateRange
        communityId: $communityId
        communitiesFilter: $communitiesFilter
        statusFilter: $statusFilter
      }
    ) {
      id
      start
      ending
      days
      isMine
      isHalfDay
      daysDuration
      allowToDelete
      owner {
        id
        user {
          ...UserWithPhotoInLeaveFragment
        }
        userPreference {
          id
          userTimezone {
            id
            tzCode
          }
        }
      }
      status
    }
  }
  ${UserWithPhotoInLeaveFragmentFragmentDoc}
`;

/**
 * __useGetAllowedViewLeavesToMeQuery__
 *
 * To run a query within a React component, call `useGetAllowedViewLeavesToMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllowedViewLeavesToMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllowedViewLeavesToMeQuery({
 *   variables: {
 *      date: // value for 'date'
 *      view: // value for 'view'
 *      dateRange: // value for 'dateRange'
 *      communityId: // value for 'communityId'
 *      communitiesFilter: // value for 'communitiesFilter'
 *      statusFilter: // value for 'statusFilter'
 *   },
 * });
 */
export function useGetAllowedViewLeavesToMeQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllowedViewLeavesToMeQuery,
    GetAllowedViewLeavesToMeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllowedViewLeavesToMeQuery, GetAllowedViewLeavesToMeQueryVariables>(
    GetAllowedViewLeavesToMeDocument,
    options,
  );
}
export function useGetAllowedViewLeavesToMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllowedViewLeavesToMeQuery,
    GetAllowedViewLeavesToMeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllowedViewLeavesToMeQuery, GetAllowedViewLeavesToMeQueryVariables>(
    GetAllowedViewLeavesToMeDocument,
    options,
  );
}
export type GetAllowedViewLeavesToMeQueryHookResult = ReturnType<
  typeof useGetAllowedViewLeavesToMeQuery
>;
export type GetAllowedViewLeavesToMeLazyQueryHookResult = ReturnType<
  typeof useGetAllowedViewLeavesToMeLazyQuery
>;
export type GetAllowedViewLeavesToMeQueryResult = Apollo.QueryResult<
  GetAllowedViewLeavesToMeQuery,
  GetAllowedViewLeavesToMeQueryVariables
>;
export const GetLeavesByDateDocument = gql`
  query getLeavesByDate(
    $date: String!
    $mine: Boolean
    $communityIds: [ID!]
    $communitiesFilter: CommunitiesFilter
    $statusFilter: LeaveStatusFilter
  ) {
    leavesByDate(
      input: {
        date: $date
        mine: $mine
        communityIds: $communityIds
        communitiesFilter: $communitiesFilter
        statusFilter: $statusFilter
      }
    ) {
      date
      leaveCount
      leaves {
        id
        start
        ending
        days
        otherReason
        specialDayOff
        isPublic
        status
        isHalfDay
        daysDuration
        allowToDelete
        isMine
        reasons {
          id
          title
        }
        owner {
          id
          user {
            ...UserWithPhotoInLeaveFragment
          }
          nPlusOne {
            id
          }
          userPreference {
            id
            userTimezone {
              id
              tzCode
            }
          }
        }
        fileCount
        attachements {
          ...FileInLeaveFragment
        }
        imageFiles {
          ...FileInLeaveFragment
        }
        validators {
          id
        }
        peoplesToNotify {
          id
        }
        comments {
          ...CommentInCalendarFragment
        }
      }
      owners {
        id
        user {
          ...UserWithPhotoInLeaveFragment
        }
      }
    }
  }
  ${UserWithPhotoInLeaveFragmentFragmentDoc}
  ${FileInLeaveFragmentFragmentDoc}
  ${CommentInCalendarFragmentFragmentDoc}
`;

/**
 * __useGetLeavesByDateQuery__
 *
 * To run a query within a React component, call `useGetLeavesByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeavesByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeavesByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      mine: // value for 'mine'
 *      communityIds: // value for 'communityIds'
 *      communitiesFilter: // value for 'communitiesFilter'
 *      statusFilter: // value for 'statusFilter'
 *   },
 * });
 */
export function useGetLeavesByDateQuery(
  baseOptions: Apollo.QueryHookOptions<GetLeavesByDateQuery, GetLeavesByDateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLeavesByDateQuery, GetLeavesByDateQueryVariables>(
    GetLeavesByDateDocument,
    options,
  );
}
export function useGetLeavesByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLeavesByDateQuery, GetLeavesByDateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLeavesByDateQuery, GetLeavesByDateQueryVariables>(
    GetLeavesByDateDocument,
    options,
  );
}
export type GetLeavesByDateQueryHookResult = ReturnType<typeof useGetLeavesByDateQuery>;
export type GetLeavesByDateLazyQueryHookResult = ReturnType<typeof useGetLeavesByDateLazyQuery>;
export type GetLeavesByDateQueryResult = Apollo.QueryResult<
  GetLeavesByDateQuery,
  GetLeavesByDateQueryVariables
>;
export const CheckIsCompanyMemberALeaveValidatorDocument = gql`
  query checkIsCompanyMemberALeaveValidator($id: ID!, $companyMemberId: ID) {
    isMeAValidator: isCompanyMemberALeaveValidator(id: $id, companyMemberId: $companyMemberId)
  }
`;

/**
 * __useCheckIsCompanyMemberALeaveValidatorQuery__
 *
 * To run a query within a React component, call `useCheckIsCompanyMemberALeaveValidatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckIsCompanyMemberALeaveValidatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckIsCompanyMemberALeaveValidatorQuery({
 *   variables: {
 *      id: // value for 'id'
 *      companyMemberId: // value for 'companyMemberId'
 *   },
 * });
 */
export function useCheckIsCompanyMemberALeaveValidatorQuery(
  baseOptions: Apollo.QueryHookOptions<
    CheckIsCompanyMemberALeaveValidatorQuery,
    CheckIsCompanyMemberALeaveValidatorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CheckIsCompanyMemberALeaveValidatorQuery,
    CheckIsCompanyMemberALeaveValidatorQueryVariables
  >(CheckIsCompanyMemberALeaveValidatorDocument, options);
}
export function useCheckIsCompanyMemberALeaveValidatorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CheckIsCompanyMemberALeaveValidatorQuery,
    CheckIsCompanyMemberALeaveValidatorQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CheckIsCompanyMemberALeaveValidatorQuery,
    CheckIsCompanyMemberALeaveValidatorQueryVariables
  >(CheckIsCompanyMemberALeaveValidatorDocument, options);
}
export type CheckIsCompanyMemberALeaveValidatorQueryHookResult = ReturnType<
  typeof useCheckIsCompanyMemberALeaveValidatorQuery
>;
export type CheckIsCompanyMemberALeaveValidatorLazyQueryHookResult = ReturnType<
  typeof useCheckIsCompanyMemberALeaveValidatorLazyQuery
>;
export type CheckIsCompanyMemberALeaveValidatorQueryResult = Apollo.QueryResult<
  CheckIsCompanyMemberALeaveValidatorQuery,
  CheckIsCompanyMemberALeaveValidatorQueryVariables
>;
export const GetLeaveDocument = gql`
  query getLeave($id: ID!) {
    leave(id: $id) {
      id
      start
      ending
      days
      otherReason
      specialDayOff
      isPublic
      status
      isHalfDay
      daysDuration
      allowToDelete
      isMine
      reasons {
        id
        title
      }
      owner {
        id
        user {
          ...UserWithPhotoInLeaveFragment
        }
        nPlusOne {
          id
        }
        userPreference {
          id
          userTimezone {
            id
            tzCode
          }
        }
      }
      attachements {
        ...FileInLeaveFragment
      }
      validators {
        id
        user {
          ...UserWithPhotoInLeaveFragment
        }
      }
      peoplesToNotify {
        id
        user {
          ...UserWithPhotoInLeaveFragment
        }
      }
      comments {
        ...CommentInCalendarFragment
      }
    }
  }
  ${UserWithPhotoInLeaveFragmentFragmentDoc}
  ${FileInLeaveFragmentFragmentDoc}
  ${CommentInCalendarFragmentFragmentDoc}
`;

/**
 * __useGetLeaveQuery__
 *
 * To run a query within a React component, call `useGetLeaveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeaveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeaveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLeaveQuery(
  baseOptions: Apollo.QueryHookOptions<GetLeaveQuery, GetLeaveQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLeaveQuery, GetLeaveQueryVariables>(GetLeaveDocument, options);
}
export function useGetLeaveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLeaveQuery, GetLeaveQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLeaveQuery, GetLeaveQueryVariables>(GetLeaveDocument, options);
}
export type GetLeaveQueryHookResult = ReturnType<typeof useGetLeaveQuery>;
export type GetLeaveLazyQueryHookResult = ReturnType<typeof useGetLeaveLazyQuery>;
export type GetLeaveQueryResult = Apollo.QueryResult<GetLeaveQuery, GetLeaveQueryVariables>;
export const GetLeavesByCalendarFilterHistoryDocument = gql`
  query getLeavesByCalendarFilterHistory(
    $perPage: Int
    $page: Int
    $ownerId: ID
    $dateRangeStart: DateTime
    $dateRangeEnd: DateTime
    $communityId: String
    $communityFilter: CommunitiesFilter
    $statusFilter: LeaveStatus
    $validatorIds: [ID!]
  ) {
    leavesByCalendarFilterHistory(
      perPage: $perPage
      page: $page
      ownerId: $ownerId
      input: {
        dateRangeStart: $dateRangeStart
        dateRangeEnd: $dateRangeEnd
        communityId: $communityId
        communityFilter: $communityFilter
        statusFilter: $statusFilter
        validatorIds: $validatorIds
      }
    ) {
      id
      start
      ending
      status
      allowToDelete
      owner {
        id
        user {
          ...UserWithPhotoInLeaveFragment
        }
      }
    }
    _leavesByCalendarFilterHistoryMeta(
      input: {
        dateRangeStart: $dateRangeStart
        dateRangeEnd: $dateRangeEnd
        communityId: $communityId
        communityFilter: $communityFilter
        statusFilter: $statusFilter
        validatorIds: $validatorIds
      }
    ) {
      count
      owners {
        id
        user {
          ...UserWithPhotoInLeaveFragment
        }
      }
    }
  }
  ${UserWithPhotoInLeaveFragmentFragmentDoc}
`;

/**
 * __useGetLeavesByCalendarFilterHistoryQuery__
 *
 * To run a query within a React component, call `useGetLeavesByCalendarFilterHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeavesByCalendarFilterHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeavesByCalendarFilterHistoryQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      ownerId: // value for 'ownerId'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *      communityId: // value for 'communityId'
 *      communityFilter: // value for 'communityFilter'
 *      statusFilter: // value for 'statusFilter'
 *      validatorIds: // value for 'validatorIds'
 *   },
 * });
 */
export function useGetLeavesByCalendarFilterHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLeavesByCalendarFilterHistoryQuery,
    GetLeavesByCalendarFilterHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLeavesByCalendarFilterHistoryQuery,
    GetLeavesByCalendarFilterHistoryQueryVariables
  >(GetLeavesByCalendarFilterHistoryDocument, options);
}
export function useGetLeavesByCalendarFilterHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLeavesByCalendarFilterHistoryQuery,
    GetLeavesByCalendarFilterHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLeavesByCalendarFilterHistoryQuery,
    GetLeavesByCalendarFilterHistoryQueryVariables
  >(GetLeavesByCalendarFilterHistoryDocument, options);
}
export type GetLeavesByCalendarFilterHistoryQueryHookResult = ReturnType<
  typeof useGetLeavesByCalendarFilterHistoryQuery
>;
export type GetLeavesByCalendarFilterHistoryLazyQueryHookResult = ReturnType<
  typeof useGetLeavesByCalendarFilterHistoryLazyQuery
>;
export type GetLeavesByCalendarFilterHistoryQueryResult = Apollo.QueryResult<
  GetLeavesByCalendarFilterHistoryQuery,
  GetLeavesByCalendarFilterHistoryQueryVariables
>;
export const ExportLeavesDocument = gql`
  query exportLeaves(
    $start: DateTime!
    $ending: DateTime!
    $reasonIds: [ID!]
    $businessUnitIds: [ID!]
    $communityIds: [ID!]
    $companyMemberIds: [ID!]
    $dateRangeType: LeavesDateRangeType!
  ) {
    exportLeaves(
      input: {
        start: $start
        ending: $ending
        reasonIds: $reasonIds
        businessUnitIds: $businessUnitIds
        communityIds: $communityIds
        companyMemberIds: $companyMemberIds
        dateRangeType: $dateRangeType
      }
    ) {
      day
      leaves {
        id
        start
        ending
        days
        otherReason
        specialDayOff
        isHalfDay
        daysDuration
        isPublic
        status
        validationDate
        reasons {
          id
          title
        }
        owner {
          id
          businessUnit {
            id
            name
          }
          userCommunity {
            id
            community {
              id
              name
            }
          }
          user {
            ...UserInLeaveFragment
          }
          nPlusOne {
            id
            user {
              ...UserInLeaveFragment
            }
          }
        }
        attachements {
          id
          name
        }
        validators {
          id
          user {
            id
            fullName
          }
        }
      }
    }
  }
  ${UserInLeaveFragmentFragmentDoc}
`;

/**
 * __useExportLeavesQuery__
 *
 * To run a query within a React component, call `useExportLeavesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportLeavesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportLeavesQuery({
 *   variables: {
 *      start: // value for 'start'
 *      ending: // value for 'ending'
 *      reasonIds: // value for 'reasonIds'
 *      businessUnitIds: // value for 'businessUnitIds'
 *      communityIds: // value for 'communityIds'
 *      companyMemberIds: // value for 'companyMemberIds'
 *      dateRangeType: // value for 'dateRangeType'
 *   },
 * });
 */
export function useExportLeavesQuery(
  baseOptions: Apollo.QueryHookOptions<ExportLeavesQuery, ExportLeavesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportLeavesQuery, ExportLeavesQueryVariables>(
    ExportLeavesDocument,
    options,
  );
}
export function useExportLeavesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportLeavesQuery, ExportLeavesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportLeavesQuery, ExportLeavesQueryVariables>(
    ExportLeavesDocument,
    options,
  );
}
export type ExportLeavesQueryHookResult = ReturnType<typeof useExportLeavesQuery>;
export type ExportLeavesLazyQueryHookResult = ReturnType<typeof useExportLeavesLazyQuery>;
export type ExportLeavesQueryResult = Apollo.QueryResult<
  ExportLeavesQuery,
  ExportLeavesQueryVariables
>;
export const GetCoachStateDocument = gql`
  query GetCoachState {
    coach @client {
      visible
      events {
        eventId
        message
      }
      emotion
    }
  }
`;

/**
 * __useGetCoachStateQuery__
 *
 * To run a query within a React component, call `useGetCoachStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoachStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoachStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoachStateQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCoachStateQuery, GetCoachStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoachStateQuery, GetCoachStateQueryVariables>(
    GetCoachStateDocument,
    options,
  );
}
export function useGetCoachStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoachStateQuery, GetCoachStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoachStateQuery, GetCoachStateQueryVariables>(
    GetCoachStateDocument,
    options,
  );
}
export type GetCoachStateQueryHookResult = ReturnType<typeof useGetCoachStateQuery>;
export type GetCoachStateLazyQueryHookResult = ReturnType<typeof useGetCoachStateLazyQuery>;
export type GetCoachStateQueryResult = Apollo.QueryResult<
  GetCoachStateQuery,
  GetCoachStateQueryVariables
>;
export const GetCongratsDialogStateDocument = gql`
  query GetCongratsDialogState {
    congratsDialog @client {
      title
      contentTitle
      contentDescription
      isOpen
      redirectUrl
    }
  }
`;

/**
 * __useGetCongratsDialogStateQuery__
 *
 * To run a query within a React component, call `useGetCongratsDialogStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCongratsDialogStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCongratsDialogStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCongratsDialogStateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCongratsDialogStateQuery,
    GetCongratsDialogStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCongratsDialogStateQuery, GetCongratsDialogStateQueryVariables>(
    GetCongratsDialogStateDocument,
    options,
  );
}
export function useGetCongratsDialogStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCongratsDialogStateQuery,
    GetCongratsDialogStateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCongratsDialogStateQuery, GetCongratsDialogStateQueryVariables>(
    GetCongratsDialogStateDocument,
    options,
  );
}
export type GetCongratsDialogStateQueryHookResult = ReturnType<
  typeof useGetCongratsDialogStateQuery
>;
export type GetCongratsDialogStateLazyQueryHookResult = ReturnType<
  typeof useGetCongratsDialogStateLazyQuery
>;
export type GetCongratsDialogStateQueryResult = Apollo.QueryResult<
  GetCongratsDialogStateQuery,
  GetCongratsDialogStateQueryVariables
>;
export const GetExpLevelUpDialogDocument = gql`
  query GetExpLevelUpDialog {
    expLevelUpDialog @client {
      open
    }
  }
`;

/**
 * __useGetExpLevelUpDialogQuery__
 *
 * To run a query within a React component, call `useGetExpLevelUpDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExpLevelUpDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExpLevelUpDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExpLevelUpDialogQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetExpLevelUpDialogQuery,
    GetExpLevelUpDialogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExpLevelUpDialogQuery, GetExpLevelUpDialogQueryVariables>(
    GetExpLevelUpDialogDocument,
    options,
  );
}
export function useGetExpLevelUpDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetExpLevelUpDialogQuery,
    GetExpLevelUpDialogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExpLevelUpDialogQuery, GetExpLevelUpDialogQueryVariables>(
    GetExpLevelUpDialogDocument,
    options,
  );
}
export type GetExpLevelUpDialogQueryHookResult = ReturnType<typeof useGetExpLevelUpDialogQuery>;
export type GetExpLevelUpDialogLazyQueryHookResult = ReturnType<
  typeof useGetExpLevelUpDialogLazyQuery
>;
export type GetExpLevelUpDialogQueryResult = Apollo.QueryResult<
  GetExpLevelUpDialogQuery,
  GetExpLevelUpDialogQueryVariables
>;
export const GetCommunityExpLevelUpDialogDocument = gql`
  query GetCommunityExpLevelUpDialog {
    communityExpLevelUpDialog @client {
      open
    }
  }
`;

/**
 * __useGetCommunityExpLevelUpDialogQuery__
 *
 * To run a query within a React component, call `useGetCommunityExpLevelUpDialogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunityExpLevelUpDialogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunityExpLevelUpDialogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCommunityExpLevelUpDialogQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCommunityExpLevelUpDialogQuery,
    GetCommunityExpLevelUpDialogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCommunityExpLevelUpDialogQuery,
    GetCommunityExpLevelUpDialogQueryVariables
  >(GetCommunityExpLevelUpDialogDocument, options);
}
export function useGetCommunityExpLevelUpDialogLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunityExpLevelUpDialogQuery,
    GetCommunityExpLevelUpDialogQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommunityExpLevelUpDialogQuery,
    GetCommunityExpLevelUpDialogQueryVariables
  >(GetCommunityExpLevelUpDialogDocument, options);
}
export type GetCommunityExpLevelUpDialogQueryHookResult = ReturnType<
  typeof useGetCommunityExpLevelUpDialogQuery
>;
export type GetCommunityExpLevelUpDialogLazyQueryHookResult = ReturnType<
  typeof useGetCommunityExpLevelUpDialogLazyQuery
>;
export type GetCommunityExpLevelUpDialogQueryResult = Apollo.QueryResult<
  GetCommunityExpLevelUpDialogQuery,
  GetCommunityExpLevelUpDialogQueryVariables
>;
export const GetSnackbarStateDocument = gql`
  query GetSnackbarState {
    snackBar @client {
      type
      message
      isOpen
    }
  }
`;

/**
 * __useGetSnackbarStateQuery__
 *
 * To run a query within a React component, call `useGetSnackbarStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSnackbarStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSnackbarStateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSnackbarStateQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSnackbarStateQuery, GetSnackbarStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSnackbarStateQuery, GetSnackbarStateQueryVariables>(
    GetSnackbarStateDocument,
    options,
  );
}
export function useGetSnackbarStateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSnackbarStateQuery, GetSnackbarStateQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSnackbarStateQuery, GetSnackbarStateQueryVariables>(
    GetSnackbarStateDocument,
    options,
  );
}
export type GetSnackbarStateQueryHookResult = ReturnType<typeof useGetSnackbarStateQuery>;
export type GetSnackbarStateLazyQueryHookResult = ReturnType<typeof useGetSnackbarStateLazyQuery>;
export type GetSnackbarStateQueryResult = Apollo.QueryResult<
  GetSnackbarStateQuery,
  GetSnackbarStateQueryVariables
>;
export const GetCurrentCompanyCommunitiesDocument = gql`
  query GetCurrentCompanyCommunities($communitySourceId: ID) {
    currentCompany {
      id
      communities {
        ...MapCommunityInfo
        members {
          id
          user {
            ...UserOnPostInfo
          }
          companyMember {
            id
            jobTitle
            user {
              ...UserOnPostInfo
            }
          }
        }
        indicators {
          ...MapIndicatorInfo
        }
        unresolvedProblemCount(communitySourceId: $communitySourceId)
        resolvedProblemCount(communitySourceId: $communitySourceId)
      }
    }
  }
  ${MapCommunityInfoFragmentDoc}
  ${UserOnPostInfoFragmentDoc}
  ${MapIndicatorInfoFragmentDoc}
`;

/**
 * __useGetCurrentCompanyCommunitiesQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyCommunitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyCommunitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyCommunitiesQuery({
 *   variables: {
 *      communitySourceId: // value for 'communitySourceId'
 *   },
 * });
 */
export function useGetCurrentCompanyCommunitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyCommunitiesQuery,
    GetCurrentCompanyCommunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentCompanyCommunitiesQuery,
    GetCurrentCompanyCommunitiesQueryVariables
  >(GetCurrentCompanyCommunitiesDocument, options);
}
export function useGetCurrentCompanyCommunitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyCommunitiesQuery,
    GetCurrentCompanyCommunitiesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyCommunitiesQuery,
    GetCurrentCompanyCommunitiesQueryVariables
  >(GetCurrentCompanyCommunitiesDocument, options);
}
export type GetCurrentCompanyCommunitiesQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyCommunitiesQuery
>;
export type GetCurrentCompanyCommunitiesLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyCommunitiesLazyQuery
>;
export type GetCurrentCompanyCommunitiesQueryResult = Apollo.QueryResult<
  GetCurrentCompanyCommunitiesQuery,
  GetCurrentCompanyCommunitiesQueryVariables
>;
export const GetTargetCommunityDocument = gql`
  query GetTargetCommunity($id: ID!, $communitySourceId: ID) {
    Community(id: $id) {
      ...MapCommunityInfo
      members {
        id
        user {
          ...UserOnPostInfo
        }
        companyMember {
          id
          jobTitle
          user {
            ...UserOnPostInfo
          }
        }
      }
      indicators {
        ...MapIndicatorInfo
      }
      unresolvedProblemCount(communitySourceId: $communitySourceId)
      resolvedProblemCount(communitySourceId: $communitySourceId)
      connectedCommunities {
        ...MapCommunityInfo
        members {
          id
          user {
            ...UserOnPostInfo
          }
          companyMember {
            id
            jobTitle
            user {
              ...UserOnPostInfo
            }
          }
        }
        indicators {
          ...MapIndicatorInfo
        }
        unresolvedProblemCount(communitySourceId: $communitySourceId)
        resolvedProblemCount(communitySourceId: $communitySourceId)
      }
    }
  }
  ${MapCommunityInfoFragmentDoc}
  ${UserOnPostInfoFragmentDoc}
  ${MapIndicatorInfoFragmentDoc}
`;

/**
 * __useGetTargetCommunityQuery__
 *
 * To run a query within a React component, call `useGetTargetCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTargetCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTargetCommunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      communitySourceId: // value for 'communitySourceId'
 *   },
 * });
 */
export function useGetTargetCommunityQuery(
  baseOptions: Apollo.QueryHookOptions<GetTargetCommunityQuery, GetTargetCommunityQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTargetCommunityQuery, GetTargetCommunityQueryVariables>(
    GetTargetCommunityDocument,
    options,
  );
}
export function useGetTargetCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTargetCommunityQuery,
    GetTargetCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTargetCommunityQuery, GetTargetCommunityQueryVariables>(
    GetTargetCommunityDocument,
    options,
  );
}
export type GetTargetCommunityQueryHookResult = ReturnType<typeof useGetTargetCommunityQuery>;
export type GetTargetCommunityLazyQueryHookResult = ReturnType<
  typeof useGetTargetCommunityLazyQuery
>;
export type GetTargetCommunityQueryResult = Apollo.QueryResult<
  GetTargetCommunityQuery,
  GetTargetCommunityQueryVariables
>;
export const CreateMeetingDocument = gql`
  mutation CreateMeeting(
    $makePublic: Boolean!
    $topic: String!
    $dueDate: DateTime!
    $timezone: String!
    $passcode: String!
    $duration: Int!
    $details: String
    $attendeeIds: [ID]!
    $invitees: [MeetingInviteeInput!]!
    $contact_name: String!
    $contact_email: String!
    $schedule_for: String
    $alternative_hosts: String
    $externalEmails: [CreateExternalEmailInput!]
  ) {
    createMeeting(
      input: {
        makePublic: $makePublic
        topic: $topic
        dueDate: $dueDate
        timezone: $timezone
        passcode: $passcode
        duration: $duration
        details: $details
        attendeeIds: $attendeeIds
        invitees: $invitees
        contact_name: $contact_name
        contact_email: $contact_email
        schedule_for: $schedule_for
        alternative_hosts: $alternative_hosts
        externalEmails: $externalEmails
      }
    ) {
      ...MeetingLocalInfo
      attendees {
        id
        user {
          id
          firstName
          lastName
          photoUrl
        }
        myCommunities {
          id
          name
        }
      }
    }
  }
  ${MeetingLocalInfoFragmentDoc}
`;
export type CreateMeetingMutationFn = Apollo.MutationFunction<
  CreateMeetingMutation,
  CreateMeetingMutationVariables
>;

/**
 * __useCreateMeetingMutation__
 *
 * To run a mutation, you first call `useCreateMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMeetingMutation, { data, loading, error }] = useCreateMeetingMutation({
 *   variables: {
 *      makePublic: // value for 'makePublic'
 *      topic: // value for 'topic'
 *      dueDate: // value for 'dueDate'
 *      timezone: // value for 'timezone'
 *      passcode: // value for 'passcode'
 *      duration: // value for 'duration'
 *      details: // value for 'details'
 *      attendeeIds: // value for 'attendeeIds'
 *      invitees: // value for 'invitees'
 *      contact_name: // value for 'contact_name'
 *      contact_email: // value for 'contact_email'
 *      schedule_for: // value for 'schedule_for'
 *      alternative_hosts: // value for 'alternative_hosts'
 *      externalEmails: // value for 'externalEmails'
 *   },
 * });
 */
export function useCreateMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMeetingMutation, CreateMeetingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMeetingMutation, CreateMeetingMutationVariables>(
    CreateMeetingDocument,
    options,
  );
}
export type CreateMeetingMutationHookResult = ReturnType<typeof useCreateMeetingMutation>;
export type CreateMeetingMutationResult = Apollo.MutationResult<CreateMeetingMutation>;
export type CreateMeetingMutationOptions = Apollo.BaseMutationOptions<
  CreateMeetingMutation,
  CreateMeetingMutationVariables
>;
export const UpdateMeetingDocument = gql`
  mutation UpdateMeeting(
    $makePublic: Boolean!
    $uuid: ID!
    $topic: String!
    $dueDate: DateTime!
    $timezone: String!
    $duration: Int!
    $passcode: String!
    $details: String
    $attendeeIds: [ID]!
    $invitees: [MeetingInviteeInput!]!
    $contact_name: String!
    $contact_email: String!
    $externalEmails: [UpdateExternalEmailInput!]
  ) {
    updateMeeting(
      input: {
        makePublic: $makePublic
        uuid: $uuid
        topic: $topic
        dueDate: $dueDate
        timezone: $timezone
        duration: $duration
        details: $details
        attendeeIds: $attendeeIds
        passcode: $passcode
        invitees: $invitees
        contact_name: $contact_name
        contact_email: $contact_email
        externalEmails: $externalEmails
      }
    ) {
      ...MeetingLocalInfo
      attendees {
        id
        user {
          id
          firstName
          lastName
          photoUrl
        }
        myCommunities {
          id
          name
        }
      }
    }
  }
  ${MeetingLocalInfoFragmentDoc}
`;
export type UpdateMeetingMutationFn = Apollo.MutationFunction<
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables
>;

/**
 * __useUpdateMeetingMutation__
 *
 * To run a mutation, you first call `useUpdateMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMeetingMutation, { data, loading, error }] = useUpdateMeetingMutation({
 *   variables: {
 *      makePublic: // value for 'makePublic'
 *      uuid: // value for 'uuid'
 *      topic: // value for 'topic'
 *      dueDate: // value for 'dueDate'
 *      timezone: // value for 'timezone'
 *      duration: // value for 'duration'
 *      passcode: // value for 'passcode'
 *      details: // value for 'details'
 *      attendeeIds: // value for 'attendeeIds'
 *      invitees: // value for 'invitees'
 *      contact_name: // value for 'contact_name'
 *      contact_email: // value for 'contact_email'
 *      externalEmails: // value for 'externalEmails'
 *   },
 * });
 */
export function useUpdateMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMeetingMutation, UpdateMeetingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMeetingMutation, UpdateMeetingMutationVariables>(
    UpdateMeetingDocument,
    options,
  );
}
export type UpdateMeetingMutationHookResult = ReturnType<typeof useUpdateMeetingMutation>;
export type UpdateMeetingMutationResult = Apollo.MutationResult<UpdateMeetingMutation>;
export type UpdateMeetingMutationOptions = Apollo.BaseMutationOptions<
  UpdateMeetingMutation,
  UpdateMeetingMutationVariables
>;
export const DeleteMeetingDocument = gql`
  mutation DeleteMeeting($uuid: ID!) {
    deleteMeeting(uuid: $uuid) {
      ...MeetingLocalInfo
      attendees {
        id
        user {
          id
          firstName
          lastName
          photoUrl
        }
        myCommunities {
          id
          name
        }
      }
    }
  }
  ${MeetingLocalInfoFragmentDoc}
`;
export type DeleteMeetingMutationFn = Apollo.MutationFunction<
  DeleteMeetingMutation,
  DeleteMeetingMutationVariables
>;

/**
 * __useDeleteMeetingMutation__
 *
 * To run a mutation, you first call `useDeleteMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMeetingMutation, { data, loading, error }] = useDeleteMeetingMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useDeleteMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMeetingMutation, DeleteMeetingMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMeetingMutation, DeleteMeetingMutationVariables>(
    DeleteMeetingDocument,
    options,
  );
}
export type DeleteMeetingMutationHookResult = ReturnType<typeof useDeleteMeetingMutation>;
export type DeleteMeetingMutationResult = Apollo.MutationResult<DeleteMeetingMutation>;
export type DeleteMeetingMutationOptions = Apollo.BaseMutationOptions<
  DeleteMeetingMutation,
  DeleteMeetingMutationVariables
>;
export const UpdateZoomMeetingRegistrantStatusDocument = gql`
  mutation UpdateZoomMeetingRegistrantStatus(
    $meetingId: String!
    $timezone: String!
    $action: MeetingRegistrantStatusAction!
    $registrants: [UpdateZoomMeetingRegistrantInput!]!
  ) {
    updateZoomMeetingRegistrantStatus(
      input: {
        meetingId: $meetingId
        timezone: $timezone
        action: $action
        registrants: $registrants
      }
    )
  }
`;
export type UpdateZoomMeetingRegistrantStatusMutationFn = Apollo.MutationFunction<
  UpdateZoomMeetingRegistrantStatusMutation,
  UpdateZoomMeetingRegistrantStatusMutationVariables
>;

/**
 * __useUpdateZoomMeetingRegistrantStatusMutation__
 *
 * To run a mutation, you first call `useUpdateZoomMeetingRegistrantStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateZoomMeetingRegistrantStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateZoomMeetingRegistrantStatusMutation, { data, loading, error }] = useUpdateZoomMeetingRegistrantStatusMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      timezone: // value for 'timezone'
 *      action: // value for 'action'
 *      registrants: // value for 'registrants'
 *   },
 * });
 */
export function useUpdateZoomMeetingRegistrantStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateZoomMeetingRegistrantStatusMutation,
    UpdateZoomMeetingRegistrantStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateZoomMeetingRegistrantStatusMutation,
    UpdateZoomMeetingRegistrantStatusMutationVariables
  >(UpdateZoomMeetingRegistrantStatusDocument, options);
}
export type UpdateZoomMeetingRegistrantStatusMutationHookResult = ReturnType<
  typeof useUpdateZoomMeetingRegistrantStatusMutation
>;
export type UpdateZoomMeetingRegistrantStatusMutationResult =
  Apollo.MutationResult<UpdateZoomMeetingRegistrantStatusMutation>;
export type UpdateZoomMeetingRegistrantStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateZoomMeetingRegistrantStatusMutation,
  UpdateZoomMeetingRegistrantStatusMutationVariables
>;
export const DeleteZoomMeetingRegistrantDocument = gql`
  mutation DeleteZoomMeetingRegistrant($meetingId: String!, $registrantId: String!) {
    deleteZoomMeetingRegistrant(input: { meetingId: $meetingId, registrantId: $registrantId })
  }
`;
export type DeleteZoomMeetingRegistrantMutationFn = Apollo.MutationFunction<
  DeleteZoomMeetingRegistrantMutation,
  DeleteZoomMeetingRegistrantMutationVariables
>;

/**
 * __useDeleteZoomMeetingRegistrantMutation__
 *
 * To run a mutation, you first call `useDeleteZoomMeetingRegistrantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteZoomMeetingRegistrantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteZoomMeetingRegistrantMutation, { data, loading, error }] = useDeleteZoomMeetingRegistrantMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      registrantId: // value for 'registrantId'
 *   },
 * });
 */
export function useDeleteZoomMeetingRegistrantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteZoomMeetingRegistrantMutation,
    DeleteZoomMeetingRegistrantMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteZoomMeetingRegistrantMutation,
    DeleteZoomMeetingRegistrantMutationVariables
  >(DeleteZoomMeetingRegistrantDocument, options);
}
export type DeleteZoomMeetingRegistrantMutationHookResult = ReturnType<
  typeof useDeleteZoomMeetingRegistrantMutation
>;
export type DeleteZoomMeetingRegistrantMutationResult =
  Apollo.MutationResult<DeleteZoomMeetingRegistrantMutation>;
export type DeleteZoomMeetingRegistrantMutationOptions = Apollo.BaseMutationOptions<
  DeleteZoomMeetingRegistrantMutation,
  DeleteZoomMeetingRegistrantMutationVariables
>;
export const DeleteZoomMeetingDocument = gql`
  mutation DeleteZoomMeeting($meetingId: String!) {
    deleteZoomMeeting(input: { meetingId: $meetingId })
  }
`;
export type DeleteZoomMeetingMutationFn = Apollo.MutationFunction<
  DeleteZoomMeetingMutation,
  DeleteZoomMeetingMutationVariables
>;

/**
 * __useDeleteZoomMeetingMutation__
 *
 * To run a mutation, you first call `useDeleteZoomMeetingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteZoomMeetingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteZoomMeetingMutation, { data, loading, error }] = useDeleteZoomMeetingMutation({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useDeleteZoomMeetingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteZoomMeetingMutation,
    DeleteZoomMeetingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteZoomMeetingMutation, DeleteZoomMeetingMutationVariables>(
    DeleteZoomMeetingDocument,
    options,
  );
}
export type DeleteZoomMeetingMutationHookResult = ReturnType<typeof useDeleteZoomMeetingMutation>;
export type DeleteZoomMeetingMutationResult = Apollo.MutationResult<DeleteZoomMeetingMutation>;
export type DeleteZoomMeetingMutationOptions = Apollo.BaseMutationOptions<
  DeleteZoomMeetingMutation,
  DeleteZoomMeetingMutationVariables
>;
export const ZoomMeetingByMeeetingIdDocument = gql`
  query ZoomMeetingByMeeetingId($meetingId: String!) {
    zoomMeetingByMeeetingId(meetingId: $meetingId) {
      id
      assistant_id
      host_email
      registration_url
      agenda
      created_at
      duration
      join_url
      password
      settings {
        contact_name
        contact_email
      }
      start_time
      start_url
      timezone
      topic
      type
    }
  }
`;

/**
 * __useZoomMeetingByMeeetingIdQuery__
 *
 * To run a query within a React component, call `useZoomMeetingByMeeetingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomMeetingByMeeetingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomMeetingByMeeetingIdQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *   },
 * });
 */
export function useZoomMeetingByMeeetingIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ZoomMeetingByMeeetingIdQuery,
    ZoomMeetingByMeeetingIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomMeetingByMeeetingIdQuery, ZoomMeetingByMeeetingIdQueryVariables>(
    ZoomMeetingByMeeetingIdDocument,
    options,
  );
}
export function useZoomMeetingByMeeetingIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoomMeetingByMeeetingIdQuery,
    ZoomMeetingByMeeetingIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoomMeetingByMeeetingIdQuery, ZoomMeetingByMeeetingIdQueryVariables>(
    ZoomMeetingByMeeetingIdDocument,
    options,
  );
}
export type ZoomMeetingByMeeetingIdQueryHookResult = ReturnType<
  typeof useZoomMeetingByMeeetingIdQuery
>;
export type ZoomMeetingByMeeetingIdLazyQueryHookResult = ReturnType<
  typeof useZoomMeetingByMeeetingIdLazyQuery
>;
export type ZoomMeetingByMeeetingIdQueryResult = Apollo.QueryResult<
  ZoomMeetingByMeeetingIdQuery,
  ZoomMeetingByMeeetingIdQueryVariables
>;
export const ZoomMeetingsByZoomUserDocument = gql`
  query ZoomMeetingsByZoomUser($zoomUserId: String!) {
    zoomMeetingsByZoomUser(zoomUserId: $zoomUserId) {
      total_records
      meetings {
        created_at
        agenda
        duration
        host_id
        id
        join_url
        pmi
        start_time
        timezone
        topic
        type
        uuid
      }
    }
  }
`;

/**
 * __useZoomMeetingsByZoomUserQuery__
 *
 * To run a query within a React component, call `useZoomMeetingsByZoomUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomMeetingsByZoomUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomMeetingsByZoomUserQuery({
 *   variables: {
 *      zoomUserId: // value for 'zoomUserId'
 *   },
 * });
 */
export function useZoomMeetingsByZoomUserQuery(
  baseOptions: Apollo.QueryHookOptions<
    ZoomMeetingsByZoomUserQuery,
    ZoomMeetingsByZoomUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomMeetingsByZoomUserQuery, ZoomMeetingsByZoomUserQueryVariables>(
    ZoomMeetingsByZoomUserDocument,
    options,
  );
}
export function useZoomMeetingsByZoomUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoomMeetingsByZoomUserQuery,
    ZoomMeetingsByZoomUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ZoomMeetingsByZoomUserQuery, ZoomMeetingsByZoomUserQueryVariables>(
    ZoomMeetingsByZoomUserDocument,
    options,
  );
}
export type ZoomMeetingsByZoomUserQueryHookResult = ReturnType<
  typeof useZoomMeetingsByZoomUserQuery
>;
export type ZoomMeetingsByZoomUserLazyQueryHookResult = ReturnType<
  typeof useZoomMeetingsByZoomUserLazyQuery
>;
export type ZoomMeetingsByZoomUserQueryResult = Apollo.QueryResult<
  ZoomMeetingsByZoomUserQuery,
  ZoomMeetingsByZoomUserQueryVariables
>;
export const MyZoomMeetingsDocument = gql`
  query MyZoomMeetings($companyMemberId: String) {
    myZoomMeetings(companyMemberId: $companyMemberId) {
      ...MeetingInfo
      registrants {
        ...RegistrantInfo
      }
    }
  }
  ${MeetingInfoFragmentDoc}
  ${RegistrantInfoFragmentDoc}
`;

/**
 * __useMyZoomMeetingsQuery__
 *
 * To run a query within a React component, call `useMyZoomMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyZoomMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyZoomMeetingsQuery({
 *   variables: {
 *      companyMemberId: // value for 'companyMemberId'
 *   },
 * });
 */
export function useMyZoomMeetingsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyZoomMeetingsQuery, MyZoomMeetingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyZoomMeetingsQuery, MyZoomMeetingsQueryVariables>(
    MyZoomMeetingsDocument,
    options,
  );
}
export function useMyZoomMeetingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyZoomMeetingsQuery, MyZoomMeetingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyZoomMeetingsQuery, MyZoomMeetingsQueryVariables>(
    MyZoomMeetingsDocument,
    options,
  );
}
export type MyZoomMeetingsQueryHookResult = ReturnType<typeof useMyZoomMeetingsQuery>;
export type MyZoomMeetingsLazyQueryHookResult = ReturnType<typeof useMyZoomMeetingsLazyQuery>;
export type MyZoomMeetingsQueryResult = Apollo.QueryResult<
  MyZoomMeetingsQuery,
  MyZoomMeetingsQueryVariables
>;
export const ZoomMeetingFullByMeetingIdDocument = gql`
  query ZoomMeetingFullByMeetingId($meetingId: String, $withPending: Boolean) {
    zoomMeetingFullByMeetingId(meetingId: $meetingId, withPending: $withPending) {
      ...MeetingInfo
      registrants {
        ...RegistrantInfo
      }
    }
  }
  ${MeetingInfoFragmentDoc}
  ${RegistrantInfoFragmentDoc}
`;

/**
 * __useZoomMeetingFullByMeetingIdQuery__
 *
 * To run a query within a React component, call `useZoomMeetingFullByMeetingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomMeetingFullByMeetingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomMeetingFullByMeetingIdQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      withPending: // value for 'withPending'
 *   },
 * });
 */
export function useZoomMeetingFullByMeetingIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ZoomMeetingFullByMeetingIdQuery,
    ZoomMeetingFullByMeetingIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ZoomMeetingFullByMeetingIdQuery, ZoomMeetingFullByMeetingIdQueryVariables>(
    ZoomMeetingFullByMeetingIdDocument,
    options,
  );
}
export function useZoomMeetingFullByMeetingIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoomMeetingFullByMeetingIdQuery,
    ZoomMeetingFullByMeetingIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ZoomMeetingFullByMeetingIdQuery,
    ZoomMeetingFullByMeetingIdQueryVariables
  >(ZoomMeetingFullByMeetingIdDocument, options);
}
export type ZoomMeetingFullByMeetingIdQueryHookResult = ReturnType<
  typeof useZoomMeetingFullByMeetingIdQuery
>;
export type ZoomMeetingFullByMeetingIdLazyQueryHookResult = ReturnType<
  typeof useZoomMeetingFullByMeetingIdLazyQuery
>;
export type ZoomMeetingFullByMeetingIdQueryResult = Apollo.QueryResult<
  ZoomMeetingFullByMeetingIdQuery,
  ZoomMeetingFullByMeetingIdQueryVariables
>;
export const ZoomMeetingRegistrantsByMeeetingIdDocument = gql`
  query ZoomMeetingRegistrantsByMeeetingId(
    $meetingId: String!
    $status: ZoomMeetingRegistrantStatus
  ) {
    zoomMeetingRegistrantsByMeeetingId(meetingId: $meetingId, status: $status) {
      total_records
      registrants {
        id
        email
        first_name
        last_name
        join_url
        registrant_id
        create_time
        topic
        status
        address
        zip
      }
    }
  }
`;

/**
 * __useZoomMeetingRegistrantsByMeeetingIdQuery__
 *
 * To run a query within a React component, call `useZoomMeetingRegistrantsByMeeetingIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useZoomMeetingRegistrantsByMeeetingIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useZoomMeetingRegistrantsByMeeetingIdQuery({
 *   variables: {
 *      meetingId: // value for 'meetingId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useZoomMeetingRegistrantsByMeeetingIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    ZoomMeetingRegistrantsByMeeetingIdQuery,
    ZoomMeetingRegistrantsByMeeetingIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ZoomMeetingRegistrantsByMeeetingIdQuery,
    ZoomMeetingRegistrantsByMeeetingIdQueryVariables
  >(ZoomMeetingRegistrantsByMeeetingIdDocument, options);
}
export function useZoomMeetingRegistrantsByMeeetingIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ZoomMeetingRegistrantsByMeeetingIdQuery,
    ZoomMeetingRegistrantsByMeeetingIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ZoomMeetingRegistrantsByMeeetingIdQuery,
    ZoomMeetingRegistrantsByMeeetingIdQueryVariables
  >(ZoomMeetingRegistrantsByMeeetingIdDocument, options);
}
export type ZoomMeetingRegistrantsByMeeetingIdQueryHookResult = ReturnType<
  typeof useZoomMeetingRegistrantsByMeeetingIdQuery
>;
export type ZoomMeetingRegistrantsByMeeetingIdLazyQueryHookResult = ReturnType<
  typeof useZoomMeetingRegistrantsByMeeetingIdLazyQuery
>;
export type ZoomMeetingRegistrantsByMeeetingIdQueryResult = Apollo.QueryResult<
  ZoomMeetingRegistrantsByMeeetingIdQuery,
  ZoomMeetingRegistrantsByMeeetingIdQueryVariables
>;
export const RefreshAccessTokenDocument = gql`
  query RefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      access_token
      token_type
      refresh_token
      expires_in
    }
  }
`;

/**
 * __useRefreshAccessTokenQuery__
 *
 * To run a query within a React component, call `useRefreshAccessTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshAccessTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshAccessTokenQuery({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshAccessTokenQuery(
  baseOptions: Apollo.QueryHookOptions<RefreshAccessTokenQuery, RefreshAccessTokenQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RefreshAccessTokenQuery, RefreshAccessTokenQueryVariables>(
    RefreshAccessTokenDocument,
    options,
  );
}
export function useRefreshAccessTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RefreshAccessTokenQuery,
    RefreshAccessTokenQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RefreshAccessTokenQuery, RefreshAccessTokenQueryVariables>(
    RefreshAccessTokenDocument,
    options,
  );
}
export type RefreshAccessTokenQueryHookResult = ReturnType<typeof useRefreshAccessTokenQuery>;
export type RefreshAccessTokenLazyQueryHookResult = ReturnType<
  typeof useRefreshAccessTokenLazyQuery
>;
export type RefreshAccessTokenQueryResult = Apollo.QueryResult<
  RefreshAccessTokenQuery,
  RefreshAccessTokenQueryVariables
>;
export const MyMeetingsDocument = gql`
  query myMeetings($status: RegistrantStatus, $dateRange: CalendarDateRangeInput) {
    myMeetings(status: $status, dateRange: $dateRange) {
      id
      uuid
      topic
      duration
      timezone
      dueDate
      details
      createdAt
      passcode
      invitationLink
      icsLink
      icsPublicLink
      humanDueDate
      isMine
      isPublic
      isPast
      myRegistrantId
      isAcceptedByMe
      isDeclinedByMe
      company {
        id
      }
      registrants {
        uuid
        status
        companyMemberId
        zoomRegistrantId
        companyMember {
          id
          user {
            id
            firstName
            lastName
            fullName
            email
            photoUrl
            photoPublicUrl
          }
        }
      }
    }
  }
`;

/**
 * __useMyMeetingsQuery__
 *
 * To run a query within a React component, call `useMyMeetingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyMeetingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyMeetingsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useMyMeetingsQuery(
  baseOptions?: Apollo.QueryHookOptions<MyMeetingsQuery, MyMeetingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MyMeetingsQuery, MyMeetingsQueryVariables>(MyMeetingsDocument, options);
}
export function useMyMeetingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MyMeetingsQuery, MyMeetingsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MyMeetingsQuery, MyMeetingsQueryVariables>(
    MyMeetingsDocument,
    options,
  );
}
export type MyMeetingsQueryHookResult = ReturnType<typeof useMyMeetingsQuery>;
export type MyMeetingsLazyQueryHookResult = ReturnType<typeof useMyMeetingsLazyQuery>;
export type MyMeetingsQueryResult = Apollo.QueryResult<MyMeetingsQuery, MyMeetingsQueryVariables>;
export const MeetingByIdDocument = gql`
  query MeetingById($uuid: ID!) {
    meetingById(uuid: $uuid) {
      id
      uuid
      topic
      duration
      timezone
      dueDate
      details
      createdAt
      removed
      passcode
      invitationLink
      icsLink
      icsPublicLink
      humanDueDate
      myRegistrantId
      creatorRegistrantId
      currentConnectedJoinUrl
      joinUrl
      isMine
      isPublic
      isPast
      isAlreadyApproved
      isAlreadyDenied
      externalEmails {
        id
        status
        email
      }
      company {
        id
      }
      createdBy {
        id
      }
      registrants {
        uuid
        status
        zoomRegistrantId
        companyMemberId
        companyMember {
          id
          user {
            id
            firstName
            lastName
            fullName
            email
            photoUrl
            photoPublicUrl
          }
        }
      }
      comments {
        ...CommentInCalendarFragment
      }
    }
    userTimezone: getMyCurrentTimezone {
      id
      label
      name
      tzCode
      utc
    }
  }
  ${CommentInCalendarFragmentFragmentDoc}
`;

/**
 * __useMeetingByIdQuery__
 *
 * To run a query within a React component, call `useMeetingByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeetingByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeetingByIdQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useMeetingByIdQuery(
  baseOptions: Apollo.QueryHookOptions<MeetingByIdQuery, MeetingByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeetingByIdQuery, MeetingByIdQueryVariables>(MeetingByIdDocument, options);
}
export function useMeetingByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeetingByIdQuery, MeetingByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeetingByIdQuery, MeetingByIdQueryVariables>(
    MeetingByIdDocument,
    options,
  );
}
export type MeetingByIdQueryHookResult = ReturnType<typeof useMeetingByIdQuery>;
export type MeetingByIdLazyQueryHookResult = ReturnType<typeof useMeetingByIdLazyQuery>;
export type MeetingByIdQueryResult = Apollo.QueryResult<
  MeetingByIdQuery,
  MeetingByIdQueryVariables
>;
export const GetMeetingsByCalendarFilterHistoryDocument = gql`
  query getMeetingsByCalendarFilterHistory(
    $perPage: Int
    $page: Int
    $keyWord: String
    $dateRangeStart: DateTime
    $dateRangeEnd: DateTime
  ) {
    meetingsByCalendarFilterHistory(
      perPage: $perPage
      page: $page
      keyWord: $keyWord
      input: { dateRangeEnd: $dateRangeEnd, dateRangeStart: $dateRangeStart }
    ) {
      id
      uuid
      topic
      dueDate
      timezone
      duration
      joinUrl
      isMine
      icsPublicLink
      isAlreadyApproved
      isAlreadyDenied
      myRegistrantId
      registrants {
        uuid
        status
        companyMember {
          id
          user {
            id
            fullName
            photoPublicUrl
          }
        }
      }
      createdBy {
        id
      }
    }
    _meetingsByCalendarFilterHistoryMeta(
      input: { dateRangeEnd: $dateRangeEnd, dateRangeStart: $dateRangeStart }
    ) {
      count
    }
  }
`;

/**
 * __useGetMeetingsByCalendarFilterHistoryQuery__
 *
 * To run a query within a React component, call `useGetMeetingsByCalendarFilterHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeetingsByCalendarFilterHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeetingsByCalendarFilterHistoryQuery({
 *   variables: {
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *      keyWord: // value for 'keyWord'
 *      dateRangeStart: // value for 'dateRangeStart'
 *      dateRangeEnd: // value for 'dateRangeEnd'
 *   },
 * });
 */
export function useGetMeetingsByCalendarFilterHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMeetingsByCalendarFilterHistoryQuery,
    GetMeetingsByCalendarFilterHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMeetingsByCalendarFilterHistoryQuery,
    GetMeetingsByCalendarFilterHistoryQueryVariables
  >(GetMeetingsByCalendarFilterHistoryDocument, options);
}
export function useGetMeetingsByCalendarFilterHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMeetingsByCalendarFilterHistoryQuery,
    GetMeetingsByCalendarFilterHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMeetingsByCalendarFilterHistoryQuery,
    GetMeetingsByCalendarFilterHistoryQueryVariables
  >(GetMeetingsByCalendarFilterHistoryDocument, options);
}
export type GetMeetingsByCalendarFilterHistoryQueryHookResult = ReturnType<
  typeof useGetMeetingsByCalendarFilterHistoryQuery
>;
export type GetMeetingsByCalendarFilterHistoryLazyQueryHookResult = ReturnType<
  typeof useGetMeetingsByCalendarFilterHistoryLazyQuery
>;
export type GetMeetingsByCalendarFilterHistoryQueryResult = Apollo.QueryResult<
  GetMeetingsByCalendarFilterHistoryQuery,
  GetMeetingsByCalendarFilterHistoryQueryVariables
>;
export const MarkAllAsReadDocument = gql`
  mutation MarkAllAsRead {
    markAllAsRead
  }
`;
export type MarkAllAsReadMutationFn = Apollo.MutationFunction<
  MarkAllAsReadMutation,
  MarkAllAsReadMutationVariables
>;

/**
 * __useMarkAllAsReadMutation__
 *
 * To run a mutation, you first call `useMarkAllAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAllAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAllAsReadMutation, { data, loading, error }] = useMarkAllAsReadMutation({
 *   variables: {
 *   },
 * });
 */
export function useMarkAllAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkAllAsReadMutation, MarkAllAsReadMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkAllAsReadMutation, MarkAllAsReadMutationVariables>(
    MarkAllAsReadDocument,
    options,
  );
}
export type MarkAllAsReadMutationHookResult = ReturnType<typeof useMarkAllAsReadMutation>;
export type MarkAllAsReadMutationResult = Apollo.MutationResult<MarkAllAsReadMutation>;
export type MarkAllAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkAllAsReadMutation,
  MarkAllAsReadMutationVariables
>;
export const SeeNotificationDocument = gql`
  mutation SeeNotification($id: ID!) {
    seeNotification(id: $id) {
      ...NotificationInfo
      companyMemberSender {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
    }
  }
  ${NotificationInfoFragmentDoc}
`;
export type SeeNotificationMutationFn = Apollo.MutationFunction<
  SeeNotificationMutation,
  SeeNotificationMutationVariables
>;

/**
 * __useSeeNotificationMutation__
 *
 * To run a mutation, you first call `useSeeNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSeeNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [seeNotificationMutation, { data, loading, error }] = useSeeNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSeeNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SeeNotificationMutation,
    SeeNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SeeNotificationMutation, SeeNotificationMutationVariables>(
    SeeNotificationDocument,
    options,
  );
}
export type SeeNotificationMutationHookResult = ReturnType<typeof useSeeNotificationMutation>;
export type SeeNotificationMutationResult = Apollo.MutationResult<SeeNotificationMutation>;
export type SeeNotificationMutationOptions = Apollo.BaseMutationOptions<
  SeeNotificationMutation,
  SeeNotificationMutationVariables
>;
export const CreateNotificationDocument = gql`
  mutation CreateNotification(
    $title: String!
    $description: String!
    $receiverIds: [ID!]!
    $linkTo: String
  ) {
    createNotification(
      title: $title
      description: $description
      receiverIds: $receiverIds
      linkTo: $linkTo
    ) {
      id
    }
  }
`;
export type CreateNotificationMutationFn = Apollo.MutationFunction<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;

/**
 * __useCreateNotificationMutation__
 *
 * To run a mutation, you first call `useCreateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationMutation, { data, loading, error }] = useCreateNotificationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      receiverIds: // value for 'receiverIds'
 *      linkTo: // value for 'linkTo'
 *   },
 * });
 */
export function useCreateNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateNotificationMutation,
    CreateNotificationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(
    CreateNotificationDocument,
    options,
  );
}
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export type CreateNotificationMutationOptions = Apollo.BaseMutationOptions<
  CreateNotificationMutation,
  CreateNotificationMutationVariables
>;
export const GetNotificationDocument = gql`
  query getNotification($id: ID!) {
    notification: Notification(id: $id) {
      ...NotificationInfo
    }
  }
  ${NotificationInfoFragmentDoc}
`;

/**
 * __useGetNotificationQuery__
 *
 * To run a query within a React component, call `useGetNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNotificationQuery, GetNotificationQueryVariables>(
    GetNotificationDocument,
    options,
  );
}
export function useGetNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationQuery, GetNotificationQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNotificationQuery, GetNotificationQueryVariables>(
    GetNotificationDocument,
    options,
  );
}
export type GetNotificationQueryHookResult = ReturnType<typeof useGetNotificationQuery>;
export type GetNotificationLazyQueryHookResult = ReturnType<typeof useGetNotificationLazyQuery>;
export type GetNotificationQueryResult = Apollo.QueryResult<
  GetNotificationQuery,
  GetNotificationQueryVariables
>;
export const GetUnSeenNotificationsDocument = gql`
  query GetUnSeenNotifications($id: ID, $page: Int, $perPage: Int) {
    notifications: unSeenNotifications(id: $id, page: $page, perPage: $perPage) {
      ...NotificationInfo
      companyMemberSender {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
    }
    meta: _unSeenNotificationsMeta(id: $id) {
      count
    }
  }
  ${NotificationInfoFragmentDoc}
`;

/**
 * __useGetUnSeenNotificationsQuery__
 *
 * To run a query within a React component, call `useGetUnSeenNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnSeenNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnSeenNotificationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetUnSeenNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnSeenNotificationsQuery,
    GetUnSeenNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnSeenNotificationsQuery, GetUnSeenNotificationsQueryVariables>(
    GetUnSeenNotificationsDocument,
    options,
  );
}
export function useGetUnSeenNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnSeenNotificationsQuery,
    GetUnSeenNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnSeenNotificationsQuery, GetUnSeenNotificationsQueryVariables>(
    GetUnSeenNotificationsDocument,
    options,
  );
}
export type GetUnSeenNotificationsQueryHookResult = ReturnType<
  typeof useGetUnSeenNotificationsQuery
>;
export type GetUnSeenNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetUnSeenNotificationsLazyQuery
>;
export type GetUnSeenNotificationsQueryResult = Apollo.QueryResult<
  GetUnSeenNotificationsQuery,
  GetUnSeenNotificationsQueryVariables
>;
export const GetSeenNotificationsDocument = gql`
  query GetSeenNotifications($id: ID, $page: Int, $perPage: Int) {
    notifications: seenNotifications(id: $id, page: $page, perPage: $perPage) {
      ...NotificationInfo
      companyMemberSender {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
    }
    meta: _seenNotificationsMeta(id: $id) {
      count
    }
  }
  ${NotificationInfoFragmentDoc}
`;

/**
 * __useGetSeenNotificationsQuery__
 *
 * To run a query within a React component, call `useGetSeenNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeenNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeenNotificationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *   },
 * });
 */
export function useGetSeenNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSeenNotificationsQuery,
    GetSeenNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSeenNotificationsQuery, GetSeenNotificationsQueryVariables>(
    GetSeenNotificationsDocument,
    options,
  );
}
export function useGetSeenNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSeenNotificationsQuery,
    GetSeenNotificationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSeenNotificationsQuery, GetSeenNotificationsQueryVariables>(
    GetSeenNotificationsDocument,
    options,
  );
}
export type GetSeenNotificationsQueryHookResult = ReturnType<typeof useGetSeenNotificationsQuery>;
export type GetSeenNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetSeenNotificationsLazyQuery
>;
export type GetSeenNotificationsQueryResult = Apollo.QueryResult<
  GetSeenNotificationsQuery,
  GetSeenNotificationsQueryVariables
>;
export const GetUnSeenNotificationsMetaDocument = gql`
  query GetUnSeenNotificationsMeta($id: ID) {
    meta: _unSeenNotificationsMeta(id: $id) {
      count
    }
  }
`;

/**
 * __useGetUnSeenNotificationsMetaQuery__
 *
 * To run a query within a React component, call `useGetUnSeenNotificationsMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnSeenNotificationsMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnSeenNotificationsMetaQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnSeenNotificationsMetaQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUnSeenNotificationsMetaQuery,
    GetUnSeenNotificationsMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnSeenNotificationsMetaQuery, GetUnSeenNotificationsMetaQueryVariables>(
    GetUnSeenNotificationsMetaDocument,
    options,
  );
}
export function useGetUnSeenNotificationsMetaLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnSeenNotificationsMetaQuery,
    GetUnSeenNotificationsMetaQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUnSeenNotificationsMetaQuery,
    GetUnSeenNotificationsMetaQueryVariables
  >(GetUnSeenNotificationsMetaDocument, options);
}
export type GetUnSeenNotificationsMetaQueryHookResult = ReturnType<
  typeof useGetUnSeenNotificationsMetaQuery
>;
export type GetUnSeenNotificationsMetaLazyQueryHookResult = ReturnType<
  typeof useGetUnSeenNotificationsMetaLazyQuery
>;
export type GetUnSeenNotificationsMetaQueryResult = Apollo.QueryResult<
  GetUnSeenNotificationsMetaQuery,
  GetUnSeenNotificationsMetaQueryVariables
>;
export const CreateOneToOneDocument = gql`
  mutation CreateOneToOne(
    $hostCompanyMemberId: ID!
    $guestCompanyMemberId: ID!
    $archive: ArchiveInput
  ) {
    createOneToOne(
      input: {
        hostCompanyMemberId: $hostCompanyMemberId
        guestCompanyMemberId: $guestCompanyMemberId
        archive: $archive
      }
    ) {
      id
    }
  }
`;
export type CreateOneToOneMutationFn = Apollo.MutationFunction<
  CreateOneToOneMutation,
  CreateOneToOneMutationVariables
>;

/**
 * __useCreateOneToOneMutation__
 *
 * To run a mutation, you first call `useCreateOneToOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneToOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneToOneMutation, { data, loading, error }] = useCreateOneToOneMutation({
 *   variables: {
 *      hostCompanyMemberId: // value for 'hostCompanyMemberId'
 *      guestCompanyMemberId: // value for 'guestCompanyMemberId'
 *      archive: // value for 'archive'
 *   },
 * });
 */
export function useCreateOneToOneMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateOneToOneMutation, CreateOneToOneMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateOneToOneMutation, CreateOneToOneMutationVariables>(
    CreateOneToOneDocument,
    options,
  );
}
export type CreateOneToOneMutationHookResult = ReturnType<typeof useCreateOneToOneMutation>;
export type CreateOneToOneMutationResult = Apollo.MutationResult<CreateOneToOneMutation>;
export type CreateOneToOneMutationOptions = Apollo.BaseMutationOptions<
  CreateOneToOneMutation,
  CreateOneToOneMutationVariables
>;
export const UpdateNeedDocument = gql`
  mutation UpdateNeed($id: ID!, $description: String, $professionnalWeatherId: ID!) {
    updateNeed(
      input: { id: $id, description: $description, professionnalWeatherId: $professionnalWeatherId }
    ) {
      ...NeedInfo
      professionnalWeather {
        id
      }
    }
  }
  ${NeedInfoFragmentDoc}
`;
export type UpdateNeedMutationFn = Apollo.MutationFunction<
  UpdateNeedMutation,
  UpdateNeedMutationVariables
>;

/**
 * __useUpdateNeedMutation__
 *
 * To run a mutation, you first call `useUpdateNeedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNeedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNeedMutation, { data, loading, error }] = useUpdateNeedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      professionnalWeatherId: // value for 'professionnalWeatherId'
 *   },
 * });
 */
export function useUpdateNeedMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNeedMutation, UpdateNeedMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNeedMutation, UpdateNeedMutationVariables>(
    UpdateNeedDocument,
    options,
  );
}
export type UpdateNeedMutationHookResult = ReturnType<typeof useUpdateNeedMutation>;
export type UpdateNeedMutationResult = Apollo.MutationResult<UpdateNeedMutation>;
export type UpdateNeedMutationOptions = Apollo.BaseMutationOptions<
  UpdateNeedMutation,
  UpdateNeedMutationVariables
>;
export const CreateRoadBlockDocument = gql`
  mutation CreateRoadBlock($description: String!, $companyMemberId: ID!) {
    createRoadBlock(description: $description, companyMemberId: $companyMemberId) {
      ...RoadBlock
    }
  }
  ${RoadBlockFragmentDoc}
`;
export type CreateRoadBlockMutationFn = Apollo.MutationFunction<
  CreateRoadBlockMutation,
  CreateRoadBlockMutationVariables
>;

/**
 * __useCreateRoadBlockMutation__
 *
 * To run a mutation, you first call `useCreateRoadBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoadBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoadBlockMutation, { data, loading, error }] = useCreateRoadBlockMutation({
 *   variables: {
 *      description: // value for 'description'
 *      companyMemberId: // value for 'companyMemberId'
 *   },
 * });
 */
export function useCreateRoadBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoadBlockMutation,
    CreateRoadBlockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateRoadBlockMutation, CreateRoadBlockMutationVariables>(
    CreateRoadBlockDocument,
    options,
  );
}
export type CreateRoadBlockMutationHookResult = ReturnType<typeof useCreateRoadBlockMutation>;
export type CreateRoadBlockMutationResult = Apollo.MutationResult<CreateRoadBlockMutation>;
export type CreateRoadBlockMutationOptions = Apollo.BaseMutationOptions<
  CreateRoadBlockMutation,
  CreateRoadBlockMutationVariables
>;
export const DeleteRoadBlockDocument = gql`
  mutation DeleteRoadBlock($deleteRoadBlockId: ID!) {
    deleteRoadBlock(id: $deleteRoadBlockId) {
      ...RoadBlock
    }
  }
  ${RoadBlockFragmentDoc}
`;
export type DeleteRoadBlockMutationFn = Apollo.MutationFunction<
  DeleteRoadBlockMutation,
  DeleteRoadBlockMutationVariables
>;

/**
 * __useDeleteRoadBlockMutation__
 *
 * To run a mutation, you first call `useDeleteRoadBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoadBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoadBlockMutation, { data, loading, error }] = useDeleteRoadBlockMutation({
 *   variables: {
 *      deleteRoadBlockId: // value for 'deleteRoadBlockId'
 *   },
 * });
 */
export function useDeleteRoadBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoadBlockMutation,
    DeleteRoadBlockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRoadBlockMutation, DeleteRoadBlockMutationVariables>(
    DeleteRoadBlockDocument,
    options,
  );
}
export type DeleteRoadBlockMutationHookResult = ReturnType<typeof useDeleteRoadBlockMutation>;
export type DeleteRoadBlockMutationResult = Apollo.MutationResult<DeleteRoadBlockMutation>;
export type DeleteRoadBlockMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoadBlockMutation,
  DeleteRoadBlockMutationVariables
>;
export const UpdateRoadBlockDocument = gql`
  mutation UpdateRoadBlock($updateRoadBlockId: ID!, $description: String) {
    updateRoadBlock(id: $updateRoadBlockId, description: $description) {
      ...RoadBlock
    }
  }
  ${RoadBlockFragmentDoc}
`;
export type UpdateRoadBlockMutationFn = Apollo.MutationFunction<
  UpdateRoadBlockMutation,
  UpdateRoadBlockMutationVariables
>;

/**
 * __useUpdateRoadBlockMutation__
 *
 * To run a mutation, you first call `useUpdateRoadBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoadBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoadBlockMutation, { data, loading, error }] = useUpdateRoadBlockMutation({
 *   variables: {
 *      updateRoadBlockId: // value for 'updateRoadBlockId'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateRoadBlockMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoadBlockMutation,
    UpdateRoadBlockMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoadBlockMutation, UpdateRoadBlockMutationVariables>(
    UpdateRoadBlockDocument,
    options,
  );
}
export type UpdateRoadBlockMutationHookResult = ReturnType<typeof useUpdateRoadBlockMutation>;
export type UpdateRoadBlockMutationResult = Apollo.MutationResult<UpdateRoadBlockMutation>;
export type UpdateRoadBlockMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoadBlockMutation,
  UpdateRoadBlockMutationVariables
>;
export const CreateMissionDocument = gql`
  mutation CreateMission(
    $title: String!
    $deadline: Date
    $estimateDate: Date
    $communityId: ID!
    $companyMemberId: ID!
    $priorityLevel: Int
    $progressStatus: MissionProgressStatus
    $expertIds: [ID]
    $oneToOneSubordonateId: ID
  ) {
    createMission(
      title: $title
      deadline: $deadline
      estimateDate: $estimateDate
      communityId: $communityId
      companyMemberId: $companyMemberId
      priorityLevel: $priorityLevel
      progressStatus: $progressStatus
      expertIds: $expertIds
      oneToOneSubordonateId: $oneToOneSubordonateId
    ) {
      ...MissionInfo
    }
  }
  ${MissionInfoFragmentDoc}
`;
export type CreateMissionMutationFn = Apollo.MutationFunction<
  CreateMissionMutation,
  CreateMissionMutationVariables
>;

/**
 * __useCreateMissionMutation__
 *
 * To run a mutation, you first call `useCreateMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMissionMutation, { data, loading, error }] = useCreateMissionMutation({
 *   variables: {
 *      title: // value for 'title'
 *      deadline: // value for 'deadline'
 *      estimateDate: // value for 'estimateDate'
 *      communityId: // value for 'communityId'
 *      companyMemberId: // value for 'companyMemberId'
 *      priorityLevel: // value for 'priorityLevel'
 *      progressStatus: // value for 'progressStatus'
 *      expertIds: // value for 'expertIds'
 *      oneToOneSubordonateId: // value for 'oneToOneSubordonateId'
 *   },
 * });
 */
export function useCreateMissionMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMissionMutation, CreateMissionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMissionMutation, CreateMissionMutationVariables>(
    CreateMissionDocument,
    options,
  );
}
export type CreateMissionMutationHookResult = ReturnType<typeof useCreateMissionMutation>;
export type CreateMissionMutationResult = Apollo.MutationResult<CreateMissionMutation>;
export type CreateMissionMutationOptions = Apollo.BaseMutationOptions<
  CreateMissionMutation,
  CreateMissionMutationVariables
>;
export const UpdateMissionDocument = gql`
  mutation UpdateMission(
    $id: ID!
    $title: String
    $deadline: Date
    $estimateDate: Date
    $priorityLevel: Int
    $progressStatus: MissionProgressStatus
    $expertIds: [ID]
  ) {
    updateMission(
      id: $id
      title: $title
      deadline: $deadline
      estimateDate: $estimateDate
      priorityLevel: $priorityLevel
      progressStatus: $progressStatus
      expertIds: $expertIds
    ) {
      ...MissionInfo
    }
  }
  ${MissionInfoFragmentDoc}
`;
export type UpdateMissionMutationFn = Apollo.MutationFunction<
  UpdateMissionMutation,
  UpdateMissionMutationVariables
>;

/**
 * __useUpdateMissionMutation__
 *
 * To run a mutation, you first call `useUpdateMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMissionMutation, { data, loading, error }] = useUpdateMissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      deadline: // value for 'deadline'
 *      estimateDate: // value for 'estimateDate'
 *      priorityLevel: // value for 'priorityLevel'
 *      progressStatus: // value for 'progressStatus'
 *      expertIds: // value for 'expertIds'
 *   },
 * });
 */
export function useUpdateMissionMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMissionMutation, UpdateMissionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMissionMutation, UpdateMissionMutationVariables>(
    UpdateMissionDocument,
    options,
  );
}
export type UpdateMissionMutationHookResult = ReturnType<typeof useUpdateMissionMutation>;
export type UpdateMissionMutationResult = Apollo.MutationResult<UpdateMissionMutation>;
export type UpdateMissionMutationOptions = Apollo.BaseMutationOptions<
  UpdateMissionMutation,
  UpdateMissionMutationVariables
>;
export const DeleteMissionDocument = gql`
  mutation DeleteMission($id: ID!) {
    deleteMission(id: $id) {
      ...MissionInfo
    }
  }
  ${MissionInfoFragmentDoc}
`;
export type DeleteMissionMutationFn = Apollo.MutationFunction<
  DeleteMissionMutation,
  DeleteMissionMutationVariables
>;

/**
 * __useDeleteMissionMutation__
 *
 * To run a mutation, you first call `useDeleteMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMissionMutation, { data, loading, error }] = useDeleteMissionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMissionMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMissionMutation, DeleteMissionMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMissionMutation, DeleteMissionMutationVariables>(
    DeleteMissionDocument,
    options,
  );
}
export type DeleteMissionMutationHookResult = ReturnType<typeof useDeleteMissionMutation>;
export type DeleteMissionMutationResult = Apollo.MutationResult<DeleteMissionMutation>;
export type DeleteMissionMutationOptions = Apollo.BaseMutationOptions<
  DeleteMissionMutation,
  DeleteMissionMutationVariables
>;
export const SendFeedbackDocument = gql`
  mutation SendFeedback($content: String!, $companyMemberReceiverId: ID!) {
    sendFeedback(content: $content, companyMemberReceiverId: $companyMemberReceiverId) {
      ...FeedbackInfo
    }
  }
  ${FeedbackInfoFragmentDoc}
`;
export type SendFeedbackMutationFn = Apollo.MutationFunction<
  SendFeedbackMutation,
  SendFeedbackMutationVariables
>;

/**
 * __useSendFeedbackMutation__
 *
 * To run a mutation, you first call `useSendFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackMutation, { data, loading, error }] = useSendFeedbackMutation({
 *   variables: {
 *      content: // value for 'content'
 *      companyMemberReceiverId: // value for 'companyMemberReceiverId'
 *   },
 * });
 */
export function useSendFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<SendFeedbackMutation, SendFeedbackMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SendFeedbackMutation, SendFeedbackMutationVariables>(
    SendFeedbackDocument,
    options,
  );
}
export type SendFeedbackMutationHookResult = ReturnType<typeof useSendFeedbackMutation>;
export type SendFeedbackMutationResult = Apollo.MutationResult<SendFeedbackMutation>;
export type SendFeedbackMutationOptions = Apollo.BaseMutationOptions<
  SendFeedbackMutation,
  SendFeedbackMutationVariables
>;
export const ReplyFeedbackDocument = gql`
  mutation ReplyFeedback($id: ID!, $response: String!) {
    replyFeedback(id: $id, response: $response) {
      ...FeedbackInfo
      companyMemberSender {
        id
        user {
          id
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
  ${FeedbackInfoFragmentDoc}
`;
export type ReplyFeedbackMutationFn = Apollo.MutationFunction<
  ReplyFeedbackMutation,
  ReplyFeedbackMutationVariables
>;

/**
 * __useReplyFeedbackMutation__
 *
 * To run a mutation, you first call `useReplyFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyFeedbackMutation, { data, loading, error }] = useReplyFeedbackMutation({
 *   variables: {
 *      id: // value for 'id'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useReplyFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<ReplyFeedbackMutation, ReplyFeedbackMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReplyFeedbackMutation, ReplyFeedbackMutationVariables>(
    ReplyFeedbackDocument,
    options,
  );
}
export type ReplyFeedbackMutationHookResult = ReturnType<typeof useReplyFeedbackMutation>;
export type ReplyFeedbackMutationResult = Apollo.MutationResult<ReplyFeedbackMutation>;
export type ReplyFeedbackMutationOptions = Apollo.BaseMutationOptions<
  ReplyFeedbackMutation,
  ReplyFeedbackMutationVariables
>;
export const GetMyOneToOneArchiveDocument = gql`
  query GetMyOneToOneArchive($id: ID!) {
    getMyOneToOneArchive(id: $id) {
      id
      file {
        id
        path
        publicUrl
        createdAt
      }
    }
  }
`;

/**
 * __useGetMyOneToOneArchiveQuery__
 *
 * To run a query within a React component, call `useGetMyOneToOneArchiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyOneToOneArchiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyOneToOneArchiveQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMyOneToOneArchiveQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMyOneToOneArchiveQuery,
    GetMyOneToOneArchiveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyOneToOneArchiveQuery, GetMyOneToOneArchiveQueryVariables>(
    GetMyOneToOneArchiveDocument,
    options,
  );
}
export function useGetMyOneToOneArchiveLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyOneToOneArchiveQuery,
    GetMyOneToOneArchiveQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyOneToOneArchiveQuery, GetMyOneToOneArchiveQueryVariables>(
    GetMyOneToOneArchiveDocument,
    options,
  );
}
export type GetMyOneToOneArchiveQueryHookResult = ReturnType<typeof useGetMyOneToOneArchiveQuery>;
export type GetMyOneToOneArchiveLazyQueryHookResult = ReturnType<
  typeof useGetMyOneToOneArchiveLazyQuery
>;
export type GetMyOneToOneArchiveQueryResult = Apollo.QueryResult<
  GetMyOneToOneArchiveQuery,
  GetMyOneToOneArchiveQueryVariables
>;
export const GetNeedsByCompanyMemberIdDocument = gql`
  query GetNeedsByCompanyMemberId($id: ID!) {
    getNeedsByCompanyMemberId(id: $id) {
      ...NeedInfo
      professionnalWeather {
        id
        icon
      }
    }
  }
  ${NeedInfoFragmentDoc}
`;

/**
 * __useGetNeedsByCompanyMemberIdQuery__
 *
 * To run a query within a React component, call `useGetNeedsByCompanyMemberIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNeedsByCompanyMemberIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNeedsByCompanyMemberIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNeedsByCompanyMemberIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetNeedsByCompanyMemberIdQuery,
    GetNeedsByCompanyMemberIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNeedsByCompanyMemberIdQuery, GetNeedsByCompanyMemberIdQueryVariables>(
    GetNeedsByCompanyMemberIdDocument,
    options,
  );
}
export function useGetNeedsByCompanyMemberIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNeedsByCompanyMemberIdQuery,
    GetNeedsByCompanyMemberIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNeedsByCompanyMemberIdQuery,
    GetNeedsByCompanyMemberIdQueryVariables
  >(GetNeedsByCompanyMemberIdDocument, options);
}
export type GetNeedsByCompanyMemberIdQueryHookResult = ReturnType<
  typeof useGetNeedsByCompanyMemberIdQuery
>;
export type GetNeedsByCompanyMemberIdLazyQueryHookResult = ReturnType<
  typeof useGetNeedsByCompanyMemberIdLazyQuery
>;
export type GetNeedsByCompanyMemberIdQueryResult = Apollo.QueryResult<
  GetNeedsByCompanyMemberIdQuery,
  GetNeedsByCompanyMemberIdQueryVariables
>;
export const GetNeedByIdDocument = gql`
  query GetNeedById($id: ID!) {
    needById: getNeedById(id: $id) {
      ...NeedInfo
      professionnalWeather {
        id
        icon
      }
    }
  }
  ${NeedInfoFragmentDoc}
`;

/**
 * __useGetNeedByIdQuery__
 *
 * To run a query within a React component, call `useGetNeedByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNeedByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNeedByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNeedByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetNeedByIdQuery, GetNeedByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNeedByIdQuery, GetNeedByIdQueryVariables>(GetNeedByIdDocument, options);
}
export function useGetNeedByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNeedByIdQuery, GetNeedByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNeedByIdQuery, GetNeedByIdQueryVariables>(
    GetNeedByIdDocument,
    options,
  );
}
export type GetNeedByIdQueryHookResult = ReturnType<typeof useGetNeedByIdQuery>;
export type GetNeedByIdLazyQueryHookResult = ReturnType<typeof useGetNeedByIdLazyQuery>;
export type GetNeedByIdQueryResult = Apollo.QueryResult<
  GetNeedByIdQuery,
  GetNeedByIdQueryVariables
>;
export const SeeFeedbackDocument = gql`
  query SeeFeedback($id: ID!) {
    seeFeedback(id: $id) {
      ...FeedbackInfo
      companyMemberSender {
        id
        user {
          id
          fullName
          photoUrl
          photoPublicUrl
        }
      }
      companyMemberReceiver {
        id
        user {
          id
          fullName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
  ${FeedbackInfoFragmentDoc}
`;

/**
 * __useSeeFeedbackQuery__
 *
 * To run a query within a React component, call `useSeeFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeeFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeeFeedbackQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSeeFeedbackQuery(
  baseOptions: Apollo.QueryHookOptions<SeeFeedbackQuery, SeeFeedbackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeeFeedbackQuery, SeeFeedbackQueryVariables>(SeeFeedbackDocument, options);
}
export function useSeeFeedbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SeeFeedbackQuery, SeeFeedbackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SeeFeedbackQuery, SeeFeedbackQueryVariables>(
    SeeFeedbackDocument,
    options,
  );
}
export type SeeFeedbackQueryHookResult = ReturnType<typeof useSeeFeedbackQuery>;
export type SeeFeedbackLazyQueryHookResult = ReturnType<typeof useSeeFeedbackLazyQuery>;
export type SeeFeedbackQueryResult = Apollo.QueryResult<
  SeeFeedbackQuery,
  SeeFeedbackQueryVariables
>;
export const CreateCompanyMemberOrderDocument = gql`
  mutation CreateCompanyMemberOrder($productId: ID!, $cost: Int!) {
    createCompanyMemberOrder(input: { productId: $productId, cost: $cost }) {
      id
    }
  }
`;
export type CreateCompanyMemberOrderMutationFn = Apollo.MutationFunction<
  CreateCompanyMemberOrderMutation,
  CreateCompanyMemberOrderMutationVariables
>;

/**
 * __useCreateCompanyMemberOrderMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMemberOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMemberOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMemberOrderMutation, { data, loading, error }] = useCreateCompanyMemberOrderMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *      cost: // value for 'cost'
 *   },
 * });
 */
export function useCreateCompanyMemberOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMemberOrderMutation,
    CreateCompanyMemberOrderMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCompanyMemberOrderMutation,
    CreateCompanyMemberOrderMutationVariables
  >(CreateCompanyMemberOrderDocument, options);
}
export type CreateCompanyMemberOrderMutationHookResult = ReturnType<
  typeof useCreateCompanyMemberOrderMutation
>;
export type CreateCompanyMemberOrderMutationResult =
  Apollo.MutationResult<CreateCompanyMemberOrderMutation>;
export type CreateCompanyMemberOrderMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMemberOrderMutation,
  CreateCompanyMemberOrderMutationVariables
>;
export const DeleteAdviceCategoryDocument = gql`
  mutation DeleteAdviceCategory($id: ID!) {
    deleteAdviceCategory(id: $id) {
      id
    }
  }
`;
export type DeleteAdviceCategoryMutationFn = Apollo.MutationFunction<
  DeleteAdviceCategoryMutation,
  DeleteAdviceCategoryMutationVariables
>;

/**
 * __useDeleteAdviceCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteAdviceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAdviceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAdviceCategoryMutation, { data, loading, error }] = useDeleteAdviceCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAdviceCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteAdviceCategoryMutation,
    DeleteAdviceCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAdviceCategoryMutation, DeleteAdviceCategoryMutationVariables>(
    DeleteAdviceCategoryDocument,
    options,
  );
}
export type DeleteAdviceCategoryMutationHookResult = ReturnType<
  typeof useDeleteAdviceCategoryMutation
>;
export type DeleteAdviceCategoryMutationResult =
  Apollo.MutationResult<DeleteAdviceCategoryMutation>;
export type DeleteAdviceCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteAdviceCategoryMutation,
  DeleteAdviceCategoryMutationVariables
>;
export const CreateAdviceCategoryDocument = gql`
  mutation CreateAdviceCategory($title: String!) {
    createAdviceCategory(title: $title) {
      id
    }
  }
`;
export type CreateAdviceCategoryMutationFn = Apollo.MutationFunction<
  CreateAdviceCategoryMutation,
  CreateAdviceCategoryMutationVariables
>;

/**
 * __useCreateAdviceCategoryMutation__
 *
 * To run a mutation, you first call `useCreateAdviceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdviceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdviceCategoryMutation, { data, loading, error }] = useCreateAdviceCategoryMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateAdviceCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAdviceCategoryMutation,
    CreateAdviceCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAdviceCategoryMutation, CreateAdviceCategoryMutationVariables>(
    CreateAdviceCategoryDocument,
    options,
  );
}
export type CreateAdviceCategoryMutationHookResult = ReturnType<
  typeof useCreateAdviceCategoryMutation
>;
export type CreateAdviceCategoryMutationResult =
  Apollo.MutationResult<CreateAdviceCategoryMutation>;
export type CreateAdviceCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateAdviceCategoryMutation,
  CreateAdviceCategoryMutationVariables
>;
export const GetCurrentCompanyAdminAdvicesDocument = gql`
  query getCurrentCompanyAdminAdvices(
    $filter: PostFilter
    $page: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
  ) {
    currentCompanyAdminAdvices(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      advices: posts {
        id
        postType
        title
        content
        createdAt
        isSeenByMe
        isReported
        isHidden
        communities {
          id
          name
          logoPublicUrl
          logo
        }
        cmCreatedBy {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoPublicUrl
            photoUrl
          }
        }
      }
      total
    }
  }
`;

/**
 * __useGetCurrentCompanyAdminAdvicesQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyAdminAdvicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyAdminAdvicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyAdminAdvicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetCurrentCompanyAdminAdvicesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyAdminAdvicesQuery,
    GetCurrentCompanyAdminAdvicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentCompanyAdminAdvicesQuery,
    GetCurrentCompanyAdminAdvicesQueryVariables
  >(GetCurrentCompanyAdminAdvicesDocument, options);
}
export function useGetCurrentCompanyAdminAdvicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyAdminAdvicesQuery,
    GetCurrentCompanyAdminAdvicesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyAdminAdvicesQuery,
    GetCurrentCompanyAdminAdvicesQueryVariables
  >(GetCurrentCompanyAdminAdvicesDocument, options);
}
export type GetCurrentCompanyAdminAdvicesQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminAdvicesQuery
>;
export type GetCurrentCompanyAdminAdvicesLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminAdvicesLazyQuery
>;
export type GetCurrentCompanyAdminAdvicesQueryResult = Apollo.QueryResult<
  GetCurrentCompanyAdminAdvicesQuery,
  GetCurrentCompanyAdminAdvicesQueryVariables
>;
export const AdviceCategoriesDocument = gql`
  query AdviceCategories {
    categories: adviceCategories {
      id
      title
      removed
      updatedAt
      createdAt
    }
  }
`;

/**
 * __useAdviceCategoriesQuery__
 *
 * To run a query within a React component, call `useAdviceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdviceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdviceCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdviceCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<AdviceCategoriesQuery, AdviceCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AdviceCategoriesQuery, AdviceCategoriesQueryVariables>(
    AdviceCategoriesDocument,
    options,
  );
}
export function useAdviceCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AdviceCategoriesQuery, AdviceCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AdviceCategoriesQuery, AdviceCategoriesQueryVariables>(
    AdviceCategoriesDocument,
    options,
  );
}
export type AdviceCategoriesQueryHookResult = ReturnType<typeof useAdviceCategoriesQuery>;
export type AdviceCategoriesLazyQueryHookResult = ReturnType<typeof useAdviceCategoriesLazyQuery>;
export type AdviceCategoriesQueryResult = Apollo.QueryResult<
  AdviceCategoriesQuery,
  AdviceCategoriesQueryVariables
>;
export const CreateIssueCategoryDocument = gql`
  mutation CreateIssueCategory($title: String!) {
    createProblemCategory(title: $title) {
      id
    }
  }
`;
export type CreateIssueCategoryMutationFn = Apollo.MutationFunction<
  CreateIssueCategoryMutation,
  CreateIssueCategoryMutationVariables
>;

/**
 * __useCreateIssueCategoryMutation__
 *
 * To run a mutation, you first call `useCreateIssueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssueCategoryMutation, { data, loading, error }] = useCreateIssueCategoryMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateIssueCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateIssueCategoryMutation,
    CreateIssueCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateIssueCategoryMutation, CreateIssueCategoryMutationVariables>(
    CreateIssueCategoryDocument,
    options,
  );
}
export type CreateIssueCategoryMutationHookResult = ReturnType<
  typeof useCreateIssueCategoryMutation
>;
export type CreateIssueCategoryMutationResult = Apollo.MutationResult<CreateIssueCategoryMutation>;
export type CreateIssueCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateIssueCategoryMutation,
  CreateIssueCategoryMutationVariables
>;
export const DeleteIssueCategoryDocument = gql`
  mutation DeleteIssueCategory($id: ID!) {
    deleteProblemCategory(id: $id) {
      id
    }
  }
`;
export type DeleteIssueCategoryMutationFn = Apollo.MutationFunction<
  DeleteIssueCategoryMutation,
  DeleteIssueCategoryMutationVariables
>;

/**
 * __useDeleteIssueCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteIssueCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIssueCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIssueCategoryMutation, { data, loading, error }] = useDeleteIssueCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIssueCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteIssueCategoryMutation,
    DeleteIssueCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteIssueCategoryMutation, DeleteIssueCategoryMutationVariables>(
    DeleteIssueCategoryDocument,
    options,
  );
}
export type DeleteIssueCategoryMutationHookResult = ReturnType<
  typeof useDeleteIssueCategoryMutation
>;
export type DeleteIssueCategoryMutationResult = Apollo.MutationResult<DeleteIssueCategoryMutation>;
export type DeleteIssueCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteIssueCategoryMutation,
  DeleteIssueCategoryMutationVariables
>;
export const GetCurrentCompanyAdminIssuesDocument = gql`
  query getCurrentCompanyAdminIssues(
    $filter: PostFilter
    $page: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
  ) {
    currentCompanyAdminProblems(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      issues: posts {
        id
        postType
        title
        content
        createdAt
        isSeenByMe
        isReported
        isHidden
        issuePrivacy
        communities {
          id
          name
          logoPublicUrl
          logo
        }
        cmCreatedBy {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoPublicUrl
            photoUrl
          }
        }
      }
      total
    }
  }
`;

/**
 * __useGetCurrentCompanyAdminIssuesQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyAdminIssuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyAdminIssuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyAdminIssuesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetCurrentCompanyAdminIssuesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyAdminIssuesQuery,
    GetCurrentCompanyAdminIssuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentCompanyAdminIssuesQuery,
    GetCurrentCompanyAdminIssuesQueryVariables
  >(GetCurrentCompanyAdminIssuesDocument, options);
}
export function useGetCurrentCompanyAdminIssuesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyAdminIssuesQuery,
    GetCurrentCompanyAdminIssuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyAdminIssuesQuery,
    GetCurrentCompanyAdminIssuesQueryVariables
  >(GetCurrentCompanyAdminIssuesDocument, options);
}
export type GetCurrentCompanyAdminIssuesQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminIssuesQuery
>;
export type GetCurrentCompanyAdminIssuesLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminIssuesLazyQuery
>;
export type GetCurrentCompanyAdminIssuesQueryResult = Apollo.QueryResult<
  GetCurrentCompanyAdminIssuesQuery,
  GetCurrentCompanyAdminIssuesQueryVariables
>;
export const CreatePostDocument = gql`
  mutation CreatePost(
    $title: String!
    $content: String!
    $postType: PostType!
    $status: POST_STATUS
    $problemFrom: DateTime
    $problemTo: DateTime
    $problemLevel: Int
    $problemCategoryId: ID
    $problemCircuitId: ID
    $communityIds: [ID!]
    $responsibleIds: [ID!]
    $companyMemberResponsibleIds: [ID!]
    $fileIds: [ID!]
    $companyMemberExpertIds: [ID!]
    $validatorIds: [ID!]
    $problemCommunitySourceId: ID
    $recipientCommunityId: ID
    $presentationFileId: ID
    $sharedToUsersIds: [ID!]
    $sharedToCompanyMembersIds: [ID!]
    $sharedToCommunitiesIds: [ID!]
    $who: String
    $what: String
    $how: String
    $where: String
    $when: String
    $impact: String
    $demand: DEMAND_TYPE
    $processStatus: PROCESS_STATUS
    $questionCategoryIid: ID
    $ratingPeriod: DateTime
    $issuePrivacy: IssuePrivacy
  ) {
    createPost(
      title: $title
      content: $content
      postType: $postType
      status: $status
      problemFrom: $problemFrom
      problemTo: $problemTo
      problemLevel: $problemLevel
      problemCategoryId: $problemCategoryId
      problemCircuitId: $problemCircuitId
      communityIds: $communityIds
      responsibleIds: $responsibleIds
      companyMemberResponsibleIds: $companyMemberResponsibleIds
      fileIds: $fileIds
      companyMemberExpertIds: $companyMemberExpertIds
      validatorIds: $validatorIds
      problemCommunitySourceId: $problemCommunitySourceId
      recipientCommunityId: $recipientCommunityId
      presentationFileId: $presentationFileId
      sharedToUsersIds: $sharedToUsersIds
      sharedToCommunitiesIds: $sharedToCommunitiesIds
      sharedToCompanyMembersIds: $sharedToCompanyMembersIds
      who: $who
      what: $what
      how: $how
      where: $where
      when: $when
      impact: $impact
      demand: $demand
      processStatus: $processStatus
      questionCategoryIid: $questionCategoryIid
      ratingPeriod: $ratingPeriod
      issuePrivacy: $issuePrivacy
    ) {
      id
      createdBy {
        id
      }
    }
  }
`;
export type CreatePostMutationFn = Apollo.MutationFunction<
  CreatePostMutation,
  CreatePostMutationVariables
>;

/**
 * __useCreatePostMutation__
 *
 * To run a mutation, you first call `useCreatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostMutation, { data, loading, error }] = useCreatePostMutation({
 *   variables: {
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      postType: // value for 'postType'
 *      status: // value for 'status'
 *      problemFrom: // value for 'problemFrom'
 *      problemTo: // value for 'problemTo'
 *      problemLevel: // value for 'problemLevel'
 *      problemCategoryId: // value for 'problemCategoryId'
 *      problemCircuitId: // value for 'problemCircuitId'
 *      communityIds: // value for 'communityIds'
 *      responsibleIds: // value for 'responsibleIds'
 *      companyMemberResponsibleIds: // value for 'companyMemberResponsibleIds'
 *      fileIds: // value for 'fileIds'
 *      companyMemberExpertIds: // value for 'companyMemberExpertIds'
 *      validatorIds: // value for 'validatorIds'
 *      problemCommunitySourceId: // value for 'problemCommunitySourceId'
 *      recipientCommunityId: // value for 'recipientCommunityId'
 *      presentationFileId: // value for 'presentationFileId'
 *      sharedToUsersIds: // value for 'sharedToUsersIds'
 *      sharedToCompanyMembersIds: // value for 'sharedToCompanyMembersIds'
 *      sharedToCommunitiesIds: // value for 'sharedToCommunitiesIds'
 *      who: // value for 'who'
 *      what: // value for 'what'
 *      how: // value for 'how'
 *      where: // value for 'where'
 *      when: // value for 'when'
 *      impact: // value for 'impact'
 *      demand: // value for 'demand'
 *      processStatus: // value for 'processStatus'
 *      questionCategoryIid: // value for 'questionCategoryIid'
 *      ratingPeriod: // value for 'ratingPeriod'
 *      issuePrivacy: // value for 'issuePrivacy'
 *   },
 * });
 */
export function useCreatePostMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePostMutation, CreatePostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePostMutation, CreatePostMutationVariables>(
    CreatePostDocument,
    options,
  );
}
export type CreatePostMutationHookResult = ReturnType<typeof useCreatePostMutation>;
export type CreatePostMutationResult = Apollo.MutationResult<CreatePostMutation>;
export type CreatePostMutationOptions = Apollo.BaseMutationOptions<
  CreatePostMutation,
  CreatePostMutationVariables
>;
export const CreatePostIdeaOrAdviceDocument = gql`
  mutation CreatePostIdeaOrAdvice(
    $title: String!
    $content: String!
    $postType: PostType!
    $status: POST_STATUS
    $ideaCategoryId: ID
    $adviceCategoryId: ID
    $communityIds: [ID!]
    $fileIds: [ID!]
    $ratingPeriod: DateTime
    $postedAnonymously: Boolean
  ) {
    post: createPost(
      title: $title
      content: $content
      postType: $postType
      status: $status
      ideaCategoryId: $ideaCategoryId
      adviceCategoryId: $adviceCategoryId
      communityIds: $communityIds
      fileIds: $fileIds
      ratingPeriod: $ratingPeriod
      postedAnonymously: $postedAnonymously
    ) {
      id
      createdBy {
        id
      }
      ideaCategory {
        id
      }
      adviceCategory {
        id
      }
    }
  }
`;
export type CreatePostIdeaOrAdviceMutationFn = Apollo.MutationFunction<
  CreatePostIdeaOrAdviceMutation,
  CreatePostIdeaOrAdviceMutationVariables
>;

/**
 * __useCreatePostIdeaOrAdviceMutation__
 *
 * To run a mutation, you first call `useCreatePostIdeaOrAdviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostIdeaOrAdviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostIdeaOrAdviceMutation, { data, loading, error }] = useCreatePostIdeaOrAdviceMutation({
 *   variables: {
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      postType: // value for 'postType'
 *      status: // value for 'status'
 *      ideaCategoryId: // value for 'ideaCategoryId'
 *      adviceCategoryId: // value for 'adviceCategoryId'
 *      communityIds: // value for 'communityIds'
 *      fileIds: // value for 'fileIds'
 *      ratingPeriod: // value for 'ratingPeriod'
 *      postedAnonymously: // value for 'postedAnonymously'
 *   },
 * });
 */
export function useCreatePostIdeaOrAdviceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostIdeaOrAdviceMutation,
    CreatePostIdeaOrAdviceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePostIdeaOrAdviceMutation,
    CreatePostIdeaOrAdviceMutationVariables
  >(CreatePostIdeaOrAdviceDocument, options);
}
export type CreatePostIdeaOrAdviceMutationHookResult = ReturnType<
  typeof useCreatePostIdeaOrAdviceMutation
>;
export type CreatePostIdeaOrAdviceMutationResult =
  Apollo.MutationResult<CreatePostIdeaOrAdviceMutation>;
export type CreatePostIdeaOrAdviceMutationOptions = Apollo.BaseMutationOptions<
  CreatePostIdeaOrAdviceMutation,
  CreatePostIdeaOrAdviceMutationVariables
>;
export const UpdatePostDocument = gql`
  mutation UpdatePost(
    $id: ID!
    $title: String
    $content: String
    $postType: PostType
    $status: POST_STATUS
    $problemFrom: DateTime
    $problemTo: DateTime
    $problemLevel: Int
    $problemCategoryId: ID
    $ideaCategoryId: ID
    $problemCircuitId: ID
    $communityIds: [ID!]
    $responsibleIds: [ID!]
    $companyMemberResponsibleIds: [ID!]
    $fileIds: [ID!]
    $companyMemberExpertIds: [ID!]
    $validatorIds: [ID!]
    $removedFilesIds: [ID!]
    $problemCommunitySourceId: ID
    $recipientCommunityId: ID
    $presentationFileId: ID
    $sharedToUsersIds: [ID!]
    $sharedToCommunitiesIds: [ID!]
    $sharedToCompanyMembersIds: [ID!]
    $who: String
    $what: String
    $how: String
    $where: String
    $when: String
    $impact: String
    $demand: DEMAND_TYPE
    $processStatus: PROCESS_STATUS
    $questionCategoryIid: ID
    $ratingPeriod: DateTime
    $issuePrivacy: IssuePrivacy
  ) {
    updatePost(
      id: $id
      title: $title
      content: $content
      postType: $postType
      status: $status
      problemFrom: $problemFrom
      problemTo: $problemTo
      problemLevel: $problemLevel
      problemCategoryId: $problemCategoryId
      problemCircuitId: $problemCircuitId
      communityIds: $communityIds
      fileIds: $fileIds
      responsibleIds: $responsibleIds
      companyMemberResponsibleIds: $companyMemberResponsibleIds
      removedFilesIds: $removedFilesIds
      companyMemberExpertIds: $companyMemberExpertIds
      validatorIds: $validatorIds
      ideaCategoryId: $ideaCategoryId
      problemCommunitySourceId: $problemCommunitySourceId
      recipientCommunityId: $recipientCommunityId
      presentationFileId: $presentationFileId
      sharedToUsersIds: $sharedToUsersIds
      sharedToCommunitiesIds: $sharedToCommunitiesIds
      sharedToCompanyMembersIds: $sharedToCompanyMembersIds
      who: $who
      what: $what
      how: $how
      where: $where
      when: $when
      impact: $impact
      demand: $demand
      processStatus: $processStatus
      questionCategoryIid: $questionCategoryIid
      ratingPeriod: $ratingPeriod
      issuePrivacy: $issuePrivacy
    ) {
      id
      title
      content
      postType
      who
      what
      how
      where
      when
      impact
      demand
      processStatus
      status
      problemFrom
      problemTo
      problemLevel
      ratingPeriod
      createdBy {
        id
      }
      ideaCategory {
        id
      }
      problemCategory {
        id
      }
      files {
        id
      }
    }
  }
`;
export type UpdatePostMutationFn = Apollo.MutationFunction<
  UpdatePostMutation,
  UpdatePostMutationVariables
>;

/**
 * __useUpdatePostMutation__
 *
 * To run a mutation, you first call `useUpdatePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostMutation, { data, loading, error }] = useUpdatePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      content: // value for 'content'
 *      postType: // value for 'postType'
 *      status: // value for 'status'
 *      problemFrom: // value for 'problemFrom'
 *      problemTo: // value for 'problemTo'
 *      problemLevel: // value for 'problemLevel'
 *      problemCategoryId: // value for 'problemCategoryId'
 *      ideaCategoryId: // value for 'ideaCategoryId'
 *      problemCircuitId: // value for 'problemCircuitId'
 *      communityIds: // value for 'communityIds'
 *      responsibleIds: // value for 'responsibleIds'
 *      companyMemberResponsibleIds: // value for 'companyMemberResponsibleIds'
 *      fileIds: // value for 'fileIds'
 *      companyMemberExpertIds: // value for 'companyMemberExpertIds'
 *      validatorIds: // value for 'validatorIds'
 *      removedFilesIds: // value for 'removedFilesIds'
 *      problemCommunitySourceId: // value for 'problemCommunitySourceId'
 *      recipientCommunityId: // value for 'recipientCommunityId'
 *      presentationFileId: // value for 'presentationFileId'
 *      sharedToUsersIds: // value for 'sharedToUsersIds'
 *      sharedToCommunitiesIds: // value for 'sharedToCommunitiesIds'
 *      sharedToCompanyMembersIds: // value for 'sharedToCompanyMembersIds'
 *      who: // value for 'who'
 *      what: // value for 'what'
 *      how: // value for 'how'
 *      where: // value for 'where'
 *      when: // value for 'when'
 *      impact: // value for 'impact'
 *      demand: // value for 'demand'
 *      processStatus: // value for 'processStatus'
 *      questionCategoryIid: // value for 'questionCategoryIid'
 *      ratingPeriod: // value for 'ratingPeriod'
 *      issuePrivacy: // value for 'issuePrivacy'
 *   },
 * });
 */
export function useUpdatePostMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePostMutation, UpdatePostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePostMutation, UpdatePostMutationVariables>(
    UpdatePostDocument,
    options,
  );
}
export type UpdatePostMutationHookResult = ReturnType<typeof useUpdatePostMutation>;
export type UpdatePostMutationResult = Apollo.MutationResult<UpdatePostMutation>;
export type UpdatePostMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostMutation,
  UpdatePostMutationVariables
>;
export const DeletePostDocument = gql`
  mutation DeletePost($deletePostId: ID!) {
    deletePost(id: $deletePostId) {
      title
      removed
    }
  }
`;
export type DeletePostMutationFn = Apollo.MutationFunction<
  DeletePostMutation,
  DeletePostMutationVariables
>;

/**
 * __useDeletePostMutation__
 *
 * To run a mutation, you first call `useDeletePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostMutation, { data, loading, error }] = useDeletePostMutation({
 *   variables: {
 *      deletePostId: // value for 'deletePostId'
 *   },
 * });
 */
export function useDeletePostMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePostMutation, DeletePostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePostMutation, DeletePostMutationVariables>(
    DeletePostDocument,
    options,
  );
}
export type DeletePostMutationHookResult = ReturnType<typeof useDeletePostMutation>;
export type DeletePostMutationResult = Apollo.MutationResult<DeletePostMutation>;
export type DeletePostMutationOptions = Apollo.BaseMutationOptions<
  DeletePostMutation,
  DeletePostMutationVariables
>;
export const CreatePostRatingDocument = gql`
  mutation CreatePostRating($createPostRatingPostId: ID!, $rating: Int!) {
    createPostRating(postId: $createPostRatingPostId, rating: $rating) {
      id
      title
      myRating {
        id
        rating
      }
    }
  }
`;
export type CreatePostRatingMutationFn = Apollo.MutationFunction<
  CreatePostRatingMutation,
  CreatePostRatingMutationVariables
>;

/**
 * __useCreatePostRatingMutation__
 *
 * To run a mutation, you first call `useCreatePostRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostRatingMutation, { data, loading, error }] = useCreatePostRatingMutation({
 *   variables: {
 *      createPostRatingPostId: // value for 'createPostRatingPostId'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useCreatePostRatingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostRatingMutation,
    CreatePostRatingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePostRatingMutation, CreatePostRatingMutationVariables>(
    CreatePostRatingDocument,
    options,
  );
}
export type CreatePostRatingMutationHookResult = ReturnType<typeof useCreatePostRatingMutation>;
export type CreatePostRatingMutationResult = Apollo.MutationResult<CreatePostRatingMutation>;
export type CreatePostRatingMutationOptions = Apollo.BaseMutationOptions<
  CreatePostRatingMutation,
  CreatePostRatingMutationVariables
>;
export const AddOrRemoveLikePostDocument = gql`
  mutation AddOrRemoveLikePost($postId: ID!) {
    addOrRemoveLikePost(postId: $postId) {
      id
      isLikedByMe
      likeCount
      likedBy {
        id
      }
    }
  }
`;
export type AddOrRemoveLikePostMutationFn = Apollo.MutationFunction<
  AddOrRemoveLikePostMutation,
  AddOrRemoveLikePostMutationVariables
>;

/**
 * __useAddOrRemoveLikePostMutation__
 *
 * To run a mutation, you first call `useAddOrRemoveLikePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrRemoveLikePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrRemoveLikePostMutation, { data, loading, error }] = useAddOrRemoveLikePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useAddOrRemoveLikePostMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOrRemoveLikePostMutation,
    AddOrRemoveLikePostMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOrRemoveLikePostMutation, AddOrRemoveLikePostMutationVariables>(
    AddOrRemoveLikePostDocument,
    options,
  );
}
export type AddOrRemoveLikePostMutationHookResult = ReturnType<
  typeof useAddOrRemoveLikePostMutation
>;
export type AddOrRemoveLikePostMutationResult = Apollo.MutationResult<AddOrRemoveLikePostMutation>;
export type AddOrRemoveLikePostMutationOptions = Apollo.BaseMutationOptions<
  AddOrRemoveLikePostMutation,
  AddOrRemoveLikePostMutationVariables
>;
export const SharePostDocument = gql`
  mutation SharePost($postId: ID!, $communityIds: [ID!]!) {
    sharePost(postId: $postId, communityIds: $communityIds) {
      id
      post {
        id
        shareCount
      }
      communities {
        id
        members {
          id
          companyMemberId
        }
      }
    }
  }
`;
export type SharePostMutationFn = Apollo.MutationFunction<
  SharePostMutation,
  SharePostMutationVariables
>;

/**
 * __useSharePostMutation__
 *
 * To run a mutation, you first call `useSharePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSharePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sharePostMutation, { data, loading, error }] = useSharePostMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      communityIds: // value for 'communityIds'
 *   },
 * });
 */
export function useSharePostMutation(
  baseOptions?: Apollo.MutationHookOptions<SharePostMutation, SharePostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SharePostMutation, SharePostMutationVariables>(
    SharePostDocument,
    options,
  );
}
export type SharePostMutationHookResult = ReturnType<typeof useSharePostMutation>;
export type SharePostMutationResult = Apollo.MutationResult<SharePostMutation>;
export type SharePostMutationOptions = Apollo.BaseMutationOptions<
  SharePostMutation,
  SharePostMutationVariables
>;
export const ChangePostStatusA3Document = gql`
  mutation ChangePostStatusA3($postId: ID!, $a3Status: POST_A3_STATUS!) {
    changePostStatusA3(postId: $postId, a3Status: $a3Status) {
      id
      a3Status
    }
  }
`;
export type ChangePostStatusA3MutationFn = Apollo.MutationFunction<
  ChangePostStatusA3Mutation,
  ChangePostStatusA3MutationVariables
>;

/**
 * __useChangePostStatusA3Mutation__
 *
 * To run a mutation, you first call `useChangePostStatusA3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePostStatusA3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePostStatusA3Mutation, { data, loading, error }] = useChangePostStatusA3Mutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      a3Status: // value for 'a3Status'
 *   },
 * });
 */
export function useChangePostStatusA3Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePostStatusA3Mutation,
    ChangePostStatusA3MutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePostStatusA3Mutation, ChangePostStatusA3MutationVariables>(
    ChangePostStatusA3Document,
    options,
  );
}
export type ChangePostStatusA3MutationHookResult = ReturnType<typeof useChangePostStatusA3Mutation>;
export type ChangePostStatusA3MutationResult = Apollo.MutationResult<ChangePostStatusA3Mutation>;
export type ChangePostStatusA3MutationOptions = Apollo.BaseMutationOptions<
  ChangePostStatusA3Mutation,
  ChangePostStatusA3MutationVariables
>;
export const UpdatePostDemandStatusDocument = gql`
  mutation UpdatePostDemandStatus(
    $postId: ID!
    $solveStatus: DEMAND_SOLVE_STATUS
    $improveStatus: DEMAND_IMPROVE_STATUS
  ) {
    updateDemandStatus(postId: $postId, solveStatus: $solveStatus, improveStatus: $improveStatus) {
      id
      demandSolveStatus
      demandImproveStatus
      isIssueSolved
    }
  }
`;
export type UpdatePostDemandStatusMutationFn = Apollo.MutationFunction<
  UpdatePostDemandStatusMutation,
  UpdatePostDemandStatusMutationVariables
>;

/**
 * __useUpdatePostDemandStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePostDemandStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostDemandStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostDemandStatusMutation, { data, loading, error }] = useUpdatePostDemandStatusMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      solveStatus: // value for 'solveStatus'
 *      improveStatus: // value for 'improveStatus'
 *   },
 * });
 */
export function useUpdatePostDemandStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePostDemandStatusMutation,
    UpdatePostDemandStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePostDemandStatusMutation,
    UpdatePostDemandStatusMutationVariables
  >(UpdatePostDemandStatusDocument, options);
}
export type UpdatePostDemandStatusMutationHookResult = ReturnType<
  typeof useUpdatePostDemandStatusMutation
>;
export type UpdatePostDemandStatusMutationResult =
  Apollo.MutationResult<UpdatePostDemandStatusMutation>;
export type UpdatePostDemandStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostDemandStatusMutation,
  UpdatePostDemandStatusMutationVariables
>;
export const UpdatePostVisibilityDocument = gql`
  mutation UpdatePostVisibility($id: ID!) {
    updatePostVisibility(id: $id) {
      id
      isHidden
    }
  }
`;
export type UpdatePostVisibilityMutationFn = Apollo.MutationFunction<
  UpdatePostVisibilityMutation,
  UpdatePostVisibilityMutationVariables
>;

/**
 * __useUpdatePostVisibilityMutation__
 *
 * To run a mutation, you first call `useUpdatePostVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostVisibilityMutation, { data, loading, error }] = useUpdatePostVisibilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePostVisibilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePostVisibilityMutation,
    UpdatePostVisibilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePostVisibilityMutation, UpdatePostVisibilityMutationVariables>(
    UpdatePostVisibilityDocument,
    options,
  );
}
export type UpdatePostVisibilityMutationHookResult = ReturnType<
  typeof useUpdatePostVisibilityMutation
>;
export type UpdatePostVisibilityMutationResult =
  Apollo.MutationResult<UpdatePostVisibilityMutation>;
export type UpdatePostVisibilityMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostVisibilityMutation,
  UpdatePostVisibilityMutationVariables
>;
export const FinalizePostDocument = gql`
  mutation FinalizePost($id: ID!) {
    finalizePost(id: $id) {
      id
    }
  }
`;
export type FinalizePostMutationFn = Apollo.MutationFunction<
  FinalizePostMutation,
  FinalizePostMutationVariables
>;

/**
 * __useFinalizePostMutation__
 *
 * To run a mutation, you first call `useFinalizePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizePostMutation, { data, loading, error }] = useFinalizePostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinalizePostMutation(
  baseOptions?: Apollo.MutationHookOptions<FinalizePostMutation, FinalizePostMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinalizePostMutation, FinalizePostMutationVariables>(
    FinalizePostDocument,
    options,
  );
}
export type FinalizePostMutationHookResult = ReturnType<typeof useFinalizePostMutation>;
export type FinalizePostMutationResult = Apollo.MutationResult<FinalizePostMutation>;
export type FinalizePostMutationOptions = Apollo.BaseMutationOptions<
  FinalizePostMutation,
  FinalizePostMutationVariables
>;
export const ResetNewPostSummaryDocument = gql`
  mutation ResetNewPostSummary($postType: PostType!) {
    resetNewPostsCountByType(postType: $postType)
  }
`;
export type ResetNewPostSummaryMutationFn = Apollo.MutationFunction<
  ResetNewPostSummaryMutation,
  ResetNewPostSummaryMutationVariables
>;

/**
 * __useResetNewPostSummaryMutation__
 *
 * To run a mutation, you first call `useResetNewPostSummaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetNewPostSummaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetNewPostSummaryMutation, { data, loading, error }] = useResetNewPostSummaryMutation({
 *   variables: {
 *      postType: // value for 'postType'
 *   },
 * });
 */
export function useResetNewPostSummaryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetNewPostSummaryMutation,
    ResetNewPostSummaryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetNewPostSummaryMutation, ResetNewPostSummaryMutationVariables>(
    ResetNewPostSummaryDocument,
    options,
  );
}
export type ResetNewPostSummaryMutationHookResult = ReturnType<
  typeof useResetNewPostSummaryMutation
>;
export type ResetNewPostSummaryMutationResult = Apollo.MutationResult<ResetNewPostSummaryMutation>;
export type ResetNewPostSummaryMutationOptions = Apollo.BaseMutationOptions<
  ResetNewPostSummaryMutation,
  ResetNewPostSummaryMutationVariables
>;
export const MarkAsAlreadyUseA3ProcessDocument = gql`
  mutation MarkAsAlreadyUseA3Process($id: ID!) {
    markAsAlreadyUseA3Process(id: $id) {
      id
      isAlreadyUseA3Process
    }
  }
`;
export type MarkAsAlreadyUseA3ProcessMutationFn = Apollo.MutationFunction<
  MarkAsAlreadyUseA3ProcessMutation,
  MarkAsAlreadyUseA3ProcessMutationVariables
>;

/**
 * __useMarkAsAlreadyUseA3ProcessMutation__
 *
 * To run a mutation, you first call `useMarkAsAlreadyUseA3ProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsAlreadyUseA3ProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsAlreadyUseA3ProcessMutation, { data, loading, error }] = useMarkAsAlreadyUseA3ProcessMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkAsAlreadyUseA3ProcessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkAsAlreadyUseA3ProcessMutation,
    MarkAsAlreadyUseA3ProcessMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkAsAlreadyUseA3ProcessMutation,
    MarkAsAlreadyUseA3ProcessMutationVariables
  >(MarkAsAlreadyUseA3ProcessDocument, options);
}
export type MarkAsAlreadyUseA3ProcessMutationHookResult = ReturnType<
  typeof useMarkAsAlreadyUseA3ProcessMutation
>;
export type MarkAsAlreadyUseA3ProcessMutationResult =
  Apollo.MutationResult<MarkAsAlreadyUseA3ProcessMutation>;
export type MarkAsAlreadyUseA3ProcessMutationOptions = Apollo.BaseMutationOptions<
  MarkAsAlreadyUseA3ProcessMutation,
  MarkAsAlreadyUseA3ProcessMutationVariables
>;
export const SaveFiltersDocument = gql`
  mutation SaveFilters($filters: [Filter], $postType: PostType!) {
    saveCurrentFilters(filters: $filters, postType: $postType) {
      id
    }
  }
`;
export type SaveFiltersMutationFn = Apollo.MutationFunction<
  SaveFiltersMutation,
  SaveFiltersMutationVariables
>;

/**
 * __useSaveFiltersMutation__
 *
 * To run a mutation, you first call `useSaveFiltersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFiltersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFiltersMutation, { data, loading, error }] = useSaveFiltersMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      postType: // value for 'postType'
 *   },
 * });
 */
export function useSaveFiltersMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveFiltersMutation, SaveFiltersMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveFiltersMutation, SaveFiltersMutationVariables>(
    SaveFiltersDocument,
    options,
  );
}
export type SaveFiltersMutationHookResult = ReturnType<typeof useSaveFiltersMutation>;
export type SaveFiltersMutationResult = Apollo.MutationResult<SaveFiltersMutation>;
export type SaveFiltersMutationOptions = Apollo.BaseMutationOptions<
  SaveFiltersMutation,
  SaveFiltersMutationVariables
>;
export const UpdatePostPrivacyDocument = gql`
  mutation UpdatePostPrivacy($postId: ID!, $issuePrivacy: IssuePrivacy!) {
    updatePostPrivacy(postId: $postId, issuePrivacy: $issuePrivacy) {
      id
      issuePrivacy
    }
  }
`;
export type UpdatePostPrivacyMutationFn = Apollo.MutationFunction<
  UpdatePostPrivacyMutation,
  UpdatePostPrivacyMutationVariables
>;

/**
 * __useUpdatePostPrivacyMutation__
 *
 * To run a mutation, you first call `useUpdatePostPrivacyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostPrivacyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostPrivacyMutation, { data, loading, error }] = useUpdatePostPrivacyMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *      issuePrivacy: // value for 'issuePrivacy'
 *   },
 * });
 */
export function useUpdatePostPrivacyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePostPrivacyMutation,
    UpdatePostPrivacyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePostPrivacyMutation, UpdatePostPrivacyMutationVariables>(
    UpdatePostPrivacyDocument,
    options,
  );
}
export type UpdatePostPrivacyMutationHookResult = ReturnType<typeof useUpdatePostPrivacyMutation>;
export type UpdatePostPrivacyMutationResult = Apollo.MutationResult<UpdatePostPrivacyMutation>;
export type UpdatePostPrivacyMutationOptions = Apollo.BaseMutationOptions<
  UpdatePostPrivacyMutation,
  UpdatePostPrivacyMutationVariables
>;
export const DeleteProcessCategoryDocument = gql`
  mutation DeleteProcessCategory($id: ID!) {
    deleteProcessCategory(id: $id) {
      id
    }
  }
`;
export type DeleteProcessCategoryMutationFn = Apollo.MutationFunction<
  DeleteProcessCategoryMutation,
  DeleteProcessCategoryMutationVariables
>;

/**
 * __useDeleteProcessCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteProcessCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProcessCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProcessCategoryMutation, { data, loading, error }] = useDeleteProcessCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProcessCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProcessCategoryMutation,
    DeleteProcessCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProcessCategoryMutation, DeleteProcessCategoryMutationVariables>(
    DeleteProcessCategoryDocument,
    options,
  );
}
export type DeleteProcessCategoryMutationHookResult = ReturnType<
  typeof useDeleteProcessCategoryMutation
>;
export type DeleteProcessCategoryMutationResult =
  Apollo.MutationResult<DeleteProcessCategoryMutation>;
export type DeleteProcessCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteProcessCategoryMutation,
  DeleteProcessCategoryMutationVariables
>;
export const CreateProcessCategoryDocument = gql`
  mutation CreateProcessCategory($title: String!) {
    createProcessCategory(title: $title) {
      id
    }
  }
`;
export type CreateProcessCategoryMutationFn = Apollo.MutationFunction<
  CreateProcessCategoryMutation,
  CreateProcessCategoryMutationVariables
>;

/**
 * __useCreateProcessCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProcessCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProcessCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProcessCategoryMutation, { data, loading, error }] = useCreateProcessCategoryMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateProcessCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateProcessCategoryMutation,
    CreateProcessCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProcessCategoryMutation, CreateProcessCategoryMutationVariables>(
    CreateProcessCategoryDocument,
    options,
  );
}
export type CreateProcessCategoryMutationHookResult = ReturnType<
  typeof useCreateProcessCategoryMutation
>;
export type CreateProcessCategoryMutationResult =
  Apollo.MutationResult<CreateProcessCategoryMutation>;
export type CreateProcessCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateProcessCategoryMutation,
  CreateProcessCategoryMutationVariables
>;
export const GetCurrentCompanyAdminProcessDocument = gql`
  query getCurrentCompanyAdminProcess(
    $filter: PostFilter
    $page: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
  ) {
    currentCompanyAdminProcess(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      allProcess: posts {
        id
        postType
        title
        content
        createdAt
        isSeenByMe
        isReported
        isHidden
        communities {
          id
          name
          logoPublicUrl
          logo
        }
        recipientCommunity {
          id
          name
          logo
          logoPublicUrl
        }
        cmCreatedBy {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoPublicUrl
            photoUrl
          }
        }
      }
      total
    }
  }
`;

/**
 * __useGetCurrentCompanyAdminProcessQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyAdminProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyAdminProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyAdminProcessQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetCurrentCompanyAdminProcessQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyAdminProcessQuery,
    GetCurrentCompanyAdminProcessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentCompanyAdminProcessQuery,
    GetCurrentCompanyAdminProcessQueryVariables
  >(GetCurrentCompanyAdminProcessDocument, options);
}
export function useGetCurrentCompanyAdminProcessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyAdminProcessQuery,
    GetCurrentCompanyAdminProcessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyAdminProcessQuery,
    GetCurrentCompanyAdminProcessQueryVariables
  >(GetCurrentCompanyAdminProcessDocument, options);
}
export type GetCurrentCompanyAdminProcessQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminProcessQuery
>;
export type GetCurrentCompanyAdminProcessLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminProcessLazyQuery
>;
export type GetCurrentCompanyAdminProcessQueryResult = Apollo.QueryResult<
  GetCurrentCompanyAdminProcessQuery,
  GetCurrentCompanyAdminProcessQueryVariables
>;
export const GetCommunitiesByCompanyDocument = gql`
  query GetCommunitiesByCompany($companyId: ID) {
    getCommunityByCompanyId(companyId: $companyId) {
      id
      name
      logo
      logoPublicUrl
    }
  }
`;

/**
 * __useGetCommunitiesByCompanyQuery__
 *
 * To run a query within a React component, call `useGetCommunitiesByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunitiesByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunitiesByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetCommunitiesByCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCommunitiesByCompanyQuery,
    GetCommunitiesByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCommunitiesByCompanyQuery, GetCommunitiesByCompanyQueryVariables>(
    GetCommunitiesByCompanyDocument,
    options,
  );
}
export function useGetCommunitiesByCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunitiesByCompanyQuery,
    GetCommunitiesByCompanyQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCommunitiesByCompanyQuery, GetCommunitiesByCompanyQueryVariables>(
    GetCommunitiesByCompanyDocument,
    options,
  );
}
export type GetCommunitiesByCompanyQueryHookResult = ReturnType<
  typeof useGetCommunitiesByCompanyQuery
>;
export type GetCommunitiesByCompanyLazyQueryHookResult = ReturnType<
  typeof useGetCommunitiesByCompanyLazyQuery
>;
export type GetCommunitiesByCompanyQueryResult = Apollo.QueryResult<
  GetCommunitiesByCompanyQuery,
  GetCommunitiesByCompanyQueryVariables
>;
export const GetCommunitiesByCompanyWithHistoryDocument = gql`
  query GetCommunitiesByCompanyWithHistory($companyId: ID, $fromTunnel: Boolean) {
    teams: getCommunitiesByCompany(id: $companyId, fromTunnel: $fromTunnel) {
      id
      name
      logo
      logoPublicUrl
    }
  }
`;

/**
 * __useGetCommunitiesByCompanyWithHistoryQuery__
 *
 * To run a query within a React component, call `useGetCommunitiesByCompanyWithHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommunitiesByCompanyWithHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommunitiesByCompanyWithHistoryQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      fromTunnel: // value for 'fromTunnel'
 *   },
 * });
 */
export function useGetCommunitiesByCompanyWithHistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCommunitiesByCompanyWithHistoryQuery,
    GetCommunitiesByCompanyWithHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCommunitiesByCompanyWithHistoryQuery,
    GetCommunitiesByCompanyWithHistoryQueryVariables
  >(GetCommunitiesByCompanyWithHistoryDocument, options);
}
export function useGetCommunitiesByCompanyWithHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCommunitiesByCompanyWithHistoryQuery,
    GetCommunitiesByCompanyWithHistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCommunitiesByCompanyWithHistoryQuery,
    GetCommunitiesByCompanyWithHistoryQueryVariables
  >(GetCommunitiesByCompanyWithHistoryDocument, options);
}
export type GetCommunitiesByCompanyWithHistoryQueryHookResult = ReturnType<
  typeof useGetCommunitiesByCompanyWithHistoryQuery
>;
export type GetCommunitiesByCompanyWithHistoryLazyQueryHookResult = ReturnType<
  typeof useGetCommunitiesByCompanyWithHistoryLazyQuery
>;
export type GetCommunitiesByCompanyWithHistoryQueryResult = Apollo.QueryResult<
  GetCommunitiesByCompanyWithHistoryQuery,
  GetCommunitiesByCompanyWithHistoryQueryVariables
>;
export const GetPostByIdDocument = gql`
  query GetPostById($postId: ID!) {
    Post(id: $postId) {
      ...PostInfo
      postRequests {
        id
        report
      }
      problemCircuit {
        id
        title
      }
      problemCategory {
        id
        title
      }
      likedBy {
        id
        firstName
        lastName
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
        }
      }
      processValidators {
        id
        user {
          id
          firstName
          lastName
          fullName
          photoUrl
          photoPublicUrl
        }
      }
      responsibles {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
      companyMemberResponsibles {
        id
        user {
          id
          firstName
          lastName
          fullName
          photoUrl
          photoPublicUrl
        }
      }
      cmCreatedBy {
        id
        user {
          id
          firstName
          lastName
          fullName
          photoUrl
          photoPublicUrl
        }
      }
      createdBy {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
      }
      commentCount
      comments {
        ...CommentInPostFragment
      }
      publicCommentCount
      publicComments {
        ...CommentInPostFragment
      }
      myRating {
        id
        rating
      }
      ...RatingSummary
      fileCount
      files {
        ...FileInfo
      }
      imageFiles {
        ...FileInfo
      }
      ideaCategory {
        id
        title
      }
      companyMemberExperts {
        id
        user {
          firstName
          lastName
          fullName
          photoPublicUrl
          photoUrl
        }
      }
      communities {
        id
        name
        logo
        logoPublicUrl
        members {
          id
          companyMemberId
        }
      }
      recipientCommunity {
        id
        name
        logo
        logoPublicUrl
      }
      presentationFile {
        ...FileInfo
      }
      sharedToSpecificUsers {
        id
        firstName
        lastName
        fullName
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
        }
      }
      sharedToSpecificCommunities {
        id
        name
        logo
        logoPublicUrl
      }
      sharedToSpecificCompanyMembers {
        id
        user {
          id
          lastName
          fullName
          photoUrl
          photoPublicUrl
          firstName
          currentCompanyMember {
            id
          }
        }
      }
      problemCommunitySource {
        id
        name
        logo
        logoPublicUrl
      }
      questionCategory {
        id
        title
      }
      sharedCompanyMemberPosts {
        communities {
          id
          name
          logo
          logoPublicUrl
        }
      }
    }
  }
  ${PostInfoFragmentDoc}
  ${CommentInPostFragmentFragmentDoc}
  ${RatingSummaryFragmentDoc}
  ${FileInfoFragmentDoc}
`;

/**
 * __useGetPostByIdQuery__
 *
 * To run a query within a React component, call `useGetPostByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostByIdQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetPostByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPostByIdQuery, GetPostByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPostByIdQuery, GetPostByIdQueryVariables>(GetPostByIdDocument, options);
}
export function useGetPostByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPostByIdQuery, GetPostByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPostByIdQuery, GetPostByIdQueryVariables>(
    GetPostByIdDocument,
    options,
  );
}
export type GetPostByIdQueryHookResult = ReturnType<typeof useGetPostByIdQuery>;
export type GetPostByIdLazyQueryHookResult = ReturnType<typeof useGetPostByIdLazyQuery>;
export type GetPostByIdQueryResult = Apollo.QueryResult<
  GetPostByIdQuery,
  GetPostByIdQueryVariables
>;
export const GetCurrentCompanyMembersDocument = gql`
  query getCurrentCompanyMembers {
    currentCompany {
      members {
        id
        user {
          fullName
          firstName
          id
        }
      }
    }
  }
`;

/**
 * __useGetCurrentCompanyMembersQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyMembersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyMembersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyMembersQuery,
    GetCurrentCompanyMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentCompanyMembersQuery, GetCurrentCompanyMembersQueryVariables>(
    GetCurrentCompanyMembersDocument,
    options,
  );
}
export function useGetCurrentCompanyMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyMembersQuery,
    GetCurrentCompanyMembersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentCompanyMembersQuery, GetCurrentCompanyMembersQueryVariables>(
    GetCurrentCompanyMembersDocument,
    options,
  );
}
export type GetCurrentCompanyMembersQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyMembersQuery
>;
export type GetCurrentCompanyMembersLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyMembersLazyQuery
>;
export type GetCurrentCompanyMembersQueryResult = Apollo.QueryResult<
  GetCurrentCompanyMembersQuery,
  GetCurrentCompanyMembersQueryVariables
>;
export const GetCurrentCompanyAdviceDocument = gql`
  query getCurrentCompanyAdvice(
    $companyId: ID
    $limit: Int
    $postToShow: POST_TO_SHOW
    $sortBy: POST_SORT_TYPE
    $filterString: String
    $communityId: ID
    $page: Int
    $filters: [Filter!]
    $postType: PostType = ADVICE
  ) {
    currentCompanyAdvice(
      companyId: $companyId
      limit: $limit
      postToShow: $postToShow
      sortBy: $sortBy
      filters: $filters
      filterString: $filterString
      communityId: $communityId
      page: $page
    ) {
      id
      title
      content
      contentImageUrls
      createdAt
      commentCount
      isSeenByMe
      isMine
      isLikedByMe
      likeCount
      shareCount
      isReported
      postType
      responsibles {
        id
        firstName
        lastName
        fullName
        photoUrl
        photoPublicUrl
      }
      createdBy {
        id
        firstName
        lastName
        fullName
        photoUrl
        photoPublicUrl
      }
      cmCreatedBy {
        id
        user {
          id
          firstName
          lastName
          fullName
          photoUrl
          photoPublicUrl
        }
      }
      fileCount
      files {
        ...FileInfo
      }
      imageFiles {
        ...FileInfo
      }
      myRating {
        id
        rating
      }
      ...RatingSummary
    }
    count: currentCompanyPostNumberByType(postType: $postType, filters: $filters)
    isFiltersApplied(filters: $filters, postType: $postType)
    currentWholeCompany {
      id
      name
    }
  }
  ${FileInfoFragmentDoc}
  ${RatingSummaryFragmentDoc}
`;

/**
 * __useGetCurrentCompanyAdviceQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyAdviceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyAdviceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyAdviceQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      limit: // value for 'limit'
 *      postToShow: // value for 'postToShow'
 *      sortBy: // value for 'sortBy'
 *      filterString: // value for 'filterString'
 *      communityId: // value for 'communityId'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *      postType: // value for 'postType'
 *   },
 * });
 */
export function useGetCurrentCompanyAdviceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyAdviceQuery,
    GetCurrentCompanyAdviceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentCompanyAdviceQuery, GetCurrentCompanyAdviceQueryVariables>(
    GetCurrentCompanyAdviceDocument,
    options,
  );
}
export function useGetCurrentCompanyAdviceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyAdviceQuery,
    GetCurrentCompanyAdviceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentCompanyAdviceQuery, GetCurrentCompanyAdviceQueryVariables>(
    GetCurrentCompanyAdviceDocument,
    options,
  );
}
export type GetCurrentCompanyAdviceQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdviceQuery
>;
export type GetCurrentCompanyAdviceLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdviceLazyQuery
>;
export type GetCurrentCompanyAdviceQueryResult = Apollo.QueryResult<
  GetCurrentCompanyAdviceQuery,
  GetCurrentCompanyAdviceQueryVariables
>;
export const NewPostSummaryDocument = gql`
  query NewPostSummary {
    newPostSummary {
      problem
      idea
      story
      process
      advice
      fun
    }
  }
`;

/**
 * __useNewPostSummaryQuery__
 *
 * To run a query within a React component, call `useNewPostSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewPostSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewPostSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewPostSummaryQuery(
  baseOptions?: Apollo.QueryHookOptions<NewPostSummaryQuery, NewPostSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NewPostSummaryQuery, NewPostSummaryQueryVariables>(
    NewPostSummaryDocument,
    options,
  );
}
export function useNewPostSummaryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NewPostSummaryQuery, NewPostSummaryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NewPostSummaryQuery, NewPostSummaryQueryVariables>(
    NewPostSummaryDocument,
    options,
  );
}
export type NewPostSummaryQueryHookResult = ReturnType<typeof useNewPostSummaryQuery>;
export type NewPostSummaryLazyQueryHookResult = ReturnType<typeof useNewPostSummaryLazyQuery>;
export type NewPostSummaryQueryResult = Apollo.QueryResult<
  NewPostSummaryQuery,
  NewPostSummaryQueryVariables
>;
export const GetAllPostsDocument = gql`
  query GetAllPosts(
    $page: Int
    $perPage: Int
    $filter: PostFilter
    $sortField: String
    $sortOrder: String
  ) {
    allPosts(
      page: $page
      perPage: $perPage
      filter: $filter
      sortOrder: $sortOrder
      sortField: $sortField
    ) {
      id
      title
      content
      postType
      issuePrivacy
    }
  }
`;

/**
 * __useGetAllPostsQuery__
 *
 * To run a query within a React component, call `useGetAllPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPostsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      filter: // value for 'filter'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useGetAllPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllPostsQuery, GetAllPostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllPostsQuery, GetAllPostsQueryVariables>(GetAllPostsDocument, options);
}
export function useGetAllPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllPostsQuery, GetAllPostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllPostsQuery, GetAllPostsQueryVariables>(
    GetAllPostsDocument,
    options,
  );
}
export type GetAllPostsQueryHookResult = ReturnType<typeof useGetAllPostsQuery>;
export type GetAllPostsLazyQueryHookResult = ReturnType<typeof useGetAllPostsLazyQuery>;
export type GetAllPostsQueryResult = Apollo.QueryResult<
  GetAllPostsQuery,
  GetAllPostsQueryVariables
>;
export const AllReportedPostsDocument = gql`
  query AllReportedPosts {
    requestedPostIds
  }
`;

/**
 * __useAllReportedPostsQuery__
 *
 * To run a query within a React component, call `useAllReportedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllReportedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllReportedPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllReportedPostsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllReportedPostsQuery, AllReportedPostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllReportedPostsQuery, AllReportedPostsQueryVariables>(
    AllReportedPostsDocument,
    options,
  );
}
export function useAllReportedPostsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllReportedPostsQuery, AllReportedPostsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllReportedPostsQuery, AllReportedPostsQueryVariables>(
    AllReportedPostsDocument,
    options,
  );
}
export type AllReportedPostsQueryHookResult = ReturnType<typeof useAllReportedPostsQuery>;
export type AllReportedPostsLazyQueryHookResult = ReturnType<typeof useAllReportedPostsLazyQuery>;
export type AllReportedPostsQueryResult = Apollo.QueryResult<
  AllReportedPostsQuery,
  AllReportedPostsQueryVariables
>;
export const IdeaCategoriesDocument = gql`
  query IdeaCategories {
    IdeaCategories {
      id
      title
      removed
      updatedAt
      createdAt
    }
  }
`;

/**
 * __useIdeaCategoriesQuery__
 *
 * To run a query within a React component, call `useIdeaCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdeaCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdeaCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIdeaCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<IdeaCategoriesQuery, IdeaCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IdeaCategoriesQuery, IdeaCategoriesQueryVariables>(
    IdeaCategoriesDocument,
    options,
  );
}
export function useIdeaCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IdeaCategoriesQuery, IdeaCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IdeaCategoriesQuery, IdeaCategoriesQueryVariables>(
    IdeaCategoriesDocument,
    options,
  );
}
export type IdeaCategoriesQueryHookResult = ReturnType<typeof useIdeaCategoriesQuery>;
export type IdeaCategoriesLazyQueryHookResult = ReturnType<typeof useIdeaCategoriesLazyQuery>;
export type IdeaCategoriesQueryResult = Apollo.QueryResult<
  IdeaCategoriesQuery,
  IdeaCategoriesQueryVariables
>;
export const ProblemCategoriesDocument = gql`
  query ProblemCategories {
    allProblemCategorys {
      id
      title
    }
  }
`;

/**
 * __useProblemCategoriesQuery__
 *
 * To run a query within a React component, call `useProblemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProblemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProblemCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useProblemCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<ProblemCategoriesQuery, ProblemCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProblemCategoriesQuery, ProblemCategoriesQueryVariables>(
    ProblemCategoriesDocument,
    options,
  );
}
export function useProblemCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProblemCategoriesQuery,
    ProblemCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProblemCategoriesQuery, ProblemCategoriesQueryVariables>(
    ProblemCategoriesDocument,
    options,
  );
}
export type ProblemCategoriesQueryHookResult = ReturnType<typeof useProblemCategoriesQuery>;
export type ProblemCategoriesLazyQueryHookResult = ReturnType<typeof useProblemCategoriesLazyQuery>;
export type ProblemCategoriesQueryResult = Apollo.QueryResult<
  ProblemCategoriesQuery,
  ProblemCategoriesQueryVariables
>;
export const CompanyProblemCategoriesDocument = gql`
  query CompanyProblemCategories {
    companyProblemCategories {
      id
      title
    }
  }
`;

/**
 * __useCompanyProblemCategoriesQuery__
 *
 * To run a query within a React component, call `useCompanyProblemCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyProblemCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyProblemCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyProblemCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyProblemCategoriesQuery,
    CompanyProblemCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CompanyProblemCategoriesQuery, CompanyProblemCategoriesQueryVariables>(
    CompanyProblemCategoriesDocument,
    options,
  );
}
export function useCompanyProblemCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyProblemCategoriesQuery,
    CompanyProblemCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CompanyProblemCategoriesQuery, CompanyProblemCategoriesQueryVariables>(
    CompanyProblemCategoriesDocument,
    options,
  );
}
export type CompanyProblemCategoriesQueryHookResult = ReturnType<
  typeof useCompanyProblemCategoriesQuery
>;
export type CompanyProblemCategoriesLazyQueryHookResult = ReturnType<
  typeof useCompanyProblemCategoriesLazyQuery
>;
export type CompanyProblemCategoriesQueryResult = Apollo.QueryResult<
  CompanyProblemCategoriesQuery,
  CompanyProblemCategoriesQueryVariables
>;
export const GetProblemsCountBetweenCommunityDocument = gql`
  query GetProblemsCountBetweenCommunity($sourceId: ID!, $targetId: ID!) {
    getProblemsCountBetweenCommunity(sourceId: $sourceId, targetId: $targetId) {
      resolvedProblemCount
      unresolvedProblemCount
    }
  }
`;

/**
 * __useGetProblemsCountBetweenCommunityQuery__
 *
 * To run a query within a React component, call `useGetProblemsCountBetweenCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProblemsCountBetweenCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProblemsCountBetweenCommunityQuery({
 *   variables: {
 *      sourceId: // value for 'sourceId'
 *      targetId: // value for 'targetId'
 *   },
 * });
 */
export function useGetProblemsCountBetweenCommunityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProblemsCountBetweenCommunityQuery,
    GetProblemsCountBetweenCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProblemsCountBetweenCommunityQuery,
    GetProblemsCountBetweenCommunityQueryVariables
  >(GetProblemsCountBetweenCommunityDocument, options);
}
export function useGetProblemsCountBetweenCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProblemsCountBetweenCommunityQuery,
    GetProblemsCountBetweenCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProblemsCountBetweenCommunityQuery,
    GetProblemsCountBetweenCommunityQueryVariables
  >(GetProblemsCountBetweenCommunityDocument, options);
}
export type GetProblemsCountBetweenCommunityQueryHookResult = ReturnType<
  typeof useGetProblemsCountBetweenCommunityQuery
>;
export type GetProblemsCountBetweenCommunityLazyQueryHookResult = ReturnType<
  typeof useGetProblemsCountBetweenCommunityLazyQuery
>;
export type GetProblemsCountBetweenCommunityQueryResult = Apollo.QueryResult<
  GetProblemsCountBetweenCommunityQuery,
  GetProblemsCountBetweenCommunityQueryVariables
>;
export const CurrentCompanyProblemsDocument = gql`
  query CurrentCompanyProblems(
    $companyId: ID
    $limit: Int
    $sortBy: POST_SORT_TYPE
    $filterBy: PostFilterByInput
    $filterString: String
    $communityId: ID
    $sortField: String
    $sortOrder: String
    $page: Int
    $postType: PostType = PROBLEM
    $cacheKey: String
    $filters: [Filter!]
    $issuePrivacy: IssuePrivacy
  ) {
    currentCompanyProblems(
      companyId: $companyId
      limit: $limit
      sortBy: $sortBy
      filterBy: $filterBy
      filterString: $filterString
      communityId: $communityId
      sortField: $sortField
      sortOrder: $sortOrder
      page: $page
      cacheKey: $cacheKey
      filters: $filters
      issuePrivacy: $issuePrivacy
    ) {
      ...PostInfo
      finalized
      isChargedByMe
      problemCategory {
        id
        title
      }
      problemCircuit {
        id
        title
      }
      cmCreatedBy {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      communities {
        id
        name
        logo
        logoPublicUrl
      }
      problemCommunitySource {
        id
        name
        logo
        logoPublicUrl
      }
      sharedCompanyMemberPosts {
        communities {
          id
          name
          logo
          logoPublicUrl
        }
      }
      responsibles {
        id
        firstName
        lastName
        fullName
        photoUrl
        photoPublicUrl
      }
      companyMemberResponsibles {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      companyMemberExperts {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      commentCount
      publicCommentCount
      fileCount
      files {
        ...FileInfo
      }
      imageFiles {
        ...FileInfo
      }
      actions {
        id
        description
        targetDate
        isCompleted
        responsibles {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
      }
      problemCommunitySource {
        id
        name
        logo
        logoPublicUrl
      }
      presentationFile {
        ...FileInfo
      }
    }
    count: currentCompanyPostNumberByType(
      postType: $postType
      filters: $filters
      issuePrivacy: $issuePrivacy
    )
    currentWholeCompany {
      id
      name
    }
    isFiltersApplied(filters: $filters, postType: $postType)
  }
  ${PostInfoFragmentDoc}
  ${FileInfoFragmentDoc}
`;

/**
 * __useCurrentCompanyProblemsQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyProblemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyProblemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyProblemsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      filterBy: // value for 'filterBy'
 *      filterString: // value for 'filterString'
 *      communityId: // value for 'communityId'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      page: // value for 'page'
 *      postType: // value for 'postType'
 *      cacheKey: // value for 'cacheKey'
 *      filters: // value for 'filters'
 *      issuePrivacy: // value for 'issuePrivacy'
 *   },
 * });
 */
export function useCurrentCompanyProblemsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyProblemsQuery,
    CurrentCompanyProblemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentCompanyProblemsQuery, CurrentCompanyProblemsQueryVariables>(
    CurrentCompanyProblemsDocument,
    options,
  );
}
export function useCurrentCompanyProblemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyProblemsQuery,
    CurrentCompanyProblemsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentCompanyProblemsQuery, CurrentCompanyProblemsQueryVariables>(
    CurrentCompanyProblemsDocument,
    options,
  );
}
export type CurrentCompanyProblemsQueryHookResult = ReturnType<
  typeof useCurrentCompanyProblemsQuery
>;
export type CurrentCompanyProblemsLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyProblemsLazyQuery
>;
export type CurrentCompanyProblemsQueryResult = Apollo.QueryResult<
  CurrentCompanyProblemsQuery,
  CurrentCompanyProblemsQueryVariables
>;
export const CurrentCompanyProcessDocument = gql`
  query CurrentCompanyProcess(
    $companyId: ID
    $limit: Int
    $postToShow: POST_TO_SHOW
    $sortBy: POST_SORT_TYPE
    $filterString: String
    $communityId: ID
    $processStatus: PROCESS_STATUS
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $postType: PostType = PROCESS
    $filters: [Filter!]
  ) {
    currentCompanyProcess(
      companyId: $companyId
      limit: $limit
      postToShow: $postToShow
      sortBy: $sortBy
      filterString: $filterString
      communityId: $communityId
      processStatus: $processStatus
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filters: $filters
    ) {
      id
      postType
      title
      content
      contentImageUrls
      commentCount
      isSeenByMe
      isMine
      createdAt
      isLikedByMe
      likeCount
      shareCount
      draft
      reopened
      processStatus
      isCMValidator
      processValidators {
        id
        user {
          firstName
          lastName
          fullName
          photoUrl
          photoPublicUrl
        }
      }
      createdBy {
        id
        firstName
        lastName
        fullName
        photoUrl
        photoPublicUrl
      }
      cmCreatedBy {
        id
        user {
          id
          firstName
          lastName
          fullName
          photoUrl
          photoPublicUrl
        }
      }
      responsibles {
        id
        firstName
        lastName
        fullName
        photoUrl
        photoPublicUrl
      }
      communities {
        id
        name
        logo
        logoPublicUrl
      }
      sharedCompanyMemberPosts {
        communities {
          id
          name
          logo
          logoPublicUrl
        }
      }
      companyMemberExperts {
        id
        user {
          firstName
          lastName
          fullName
          photoUrl
          photoPublicUrl
        }
      }
      comments {
        ...CommentInPostFragment
      }
      fileCount
      files {
        ...FileInfo
      }
      imageFiles {
        ...FileInfo
      }
      recipientCommunity {
        id
        name
        logo
        logoPublicUrl
      }
      presentationFile {
        ...FileInfo
      }
      myRating {
        id
        rating
      }
      ...RatingSummary
    }
    count: currentCompanyPostNumberByType(postType: $postType, filters: $filters)
    currentWholeCompany {
      id
      name
    }
    isFiltersApplied(filters: $filters, postType: $postType)
  }
  ${CommentInPostFragmentFragmentDoc}
  ${FileInfoFragmentDoc}
  ${RatingSummaryFragmentDoc}
`;

/**
 * __useCurrentCompanyProcessQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyProcessQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      limit: // value for 'limit'
 *      postToShow: // value for 'postToShow'
 *      sortBy: // value for 'sortBy'
 *      filterString: // value for 'filterString'
 *      communityId: // value for 'communityId'
 *      processStatus: // value for 'processStatus'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortField: // value for 'sortField'
 *      sortOrder: // value for 'sortOrder'
 *      postType: // value for 'postType'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCurrentCompanyProcessQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyProcessQuery,
    CurrentCompanyProcessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentCompanyProcessQuery, CurrentCompanyProcessQueryVariables>(
    CurrentCompanyProcessDocument,
    options,
  );
}
export function useCurrentCompanyProcessLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyProcessQuery,
    CurrentCompanyProcessQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentCompanyProcessQuery, CurrentCompanyProcessQueryVariables>(
    CurrentCompanyProcessDocument,
    options,
  );
}
export type CurrentCompanyProcessQueryHookResult = ReturnType<typeof useCurrentCompanyProcessQuery>;
export type CurrentCompanyProcessLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyProcessLazyQuery
>;
export type CurrentCompanyProcessQueryResult = Apollo.QueryResult<
  CurrentCompanyProcessQuery,
  CurrentCompanyProcessQueryVariables
>;
export const CurrentCompanyStoriesDocument = gql`
  query currentCompanyStories(
    $companyId: ID
    $limit: Int
    $postToShow: POST_TO_SHOW
    $sortBy: POST_SORT_TYPE
    $filterString: String
    $page: Int
    $postType: PostType = STORY
    $filters: [Filter!]
  ) {
    currentCompanyStories(
      companyId: $companyId
      limit: $limit
      postToShow: $postToShow
      sortBy: $sortBy
      page: $page
      filterString: $filterString
      filters: $filters
    ) {
      id
      postType
      title
      content
      contentImageUrls
      createdAt
      isSeenByMe
      isMine
      isLikedByMe
      likeCount
      shareCount
      isReported
      cmCreatedBy {
        id
        user {
          id
          firstName
          lastName
          fullName
          photoPublicUrl
          photoUrl
        }
      }
      commentCount
      comments {
        ...CommentInPostFragment
      }
      fileCount
      files {
        ...FileInfo
      }
      imageFiles {
        ...FileInfo
      }
      presentationFile {
        ...FileInfo
      }
      sharedToSpecificUsers {
        id
        firstName
        lastName
        fullName
        photoUrl
        photoPublicUrl
        currentCompanyMember {
          id
        }
      }
      sharedToSpecificCommunities {
        id
        name
        logo
        logoPublicUrl
      }
      sharedToSpecificCompanyMembers {
        id
        user {
          id
          lastName
          fullName
          photoUrl
          photoPublicUrl
          firstName
          currentCompanyMember {
            id
          }
        }
      }
    }
    count: currentCompanyPostNumberByType(postType: $postType, filters: $filters)
    isFiltersApplied(filters: $filters, postType: $postType)
    currentWholeCompany {
      id
      name
    }
  }
  ${CommentInPostFragmentFragmentDoc}
  ${FileInfoFragmentDoc}
`;

/**
 * __useCurrentCompanyStoriesQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyStoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyStoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyStoriesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      limit: // value for 'limit'
 *      postToShow: // value for 'postToShow'
 *      sortBy: // value for 'sortBy'
 *      filterString: // value for 'filterString'
 *      page: // value for 'page'
 *      postType: // value for 'postType'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCurrentCompanyStoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyStoriesQuery,
    CurrentCompanyStoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentCompanyStoriesQuery, CurrentCompanyStoriesQueryVariables>(
    CurrentCompanyStoriesDocument,
    options,
  );
}
export function useCurrentCompanyStoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyStoriesQuery,
    CurrentCompanyStoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentCompanyStoriesQuery, CurrentCompanyStoriesQueryVariables>(
    CurrentCompanyStoriesDocument,
    options,
  );
}
export type CurrentCompanyStoriesQueryHookResult = ReturnType<typeof useCurrentCompanyStoriesQuery>;
export type CurrentCompanyStoriesLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyStoriesLazyQuery
>;
export type CurrentCompanyStoriesQueryResult = Apollo.QueryResult<
  CurrentCompanyStoriesQuery,
  CurrentCompanyStoriesQueryVariables
>;
export const CurrentCompanyFunsDocument = gql`
  query currentCompanyFuns(
    $companyId: ID
    $limit: Int
    $postToShow: POST_TO_SHOW
    $sortBy: POST_SORT_TYPE
    $filterString: String
    $page: Int
    $postType: PostType = FUN
    $filters: [Filter!]
  ) {
    currentCompanyFuns(
      companyId: $companyId
      limit: $limit
      postToShow: $postToShow
      sortBy: $sortBy
      page: $page
      filterString: $filterString
      filters: $filters
    ) {
      id
      postType
      title
      content
      contentImageUrls
      createdAt
      isSeenByMe
      isMine
      isLikedByMe
      likeCount
      shareCount
      cmCreatedBy {
        id
        user {
          id
          firstName
          lastName
          fullName
          photoPublicUrl
          photoUrl
        }
      }
      commentCount
      comments {
        ...CommentInPostFragment
      }
      fileCount
      files {
        ...FileInfo
      }
      imageFiles {
        ...FileInfo
      }
      presentationFile {
        ...FileInfo
      }
      sharedToSpecificCommunities {
        id
        name
        logo
        logoPublicUrl
      }
    }
    count: currentCompanyPostNumberByType(postType: $postType, filters: $filters)
    isFiltersApplied(filters: $filters, postType: $postType)
    currentWholeCompany {
      id
      name
    }
  }
  ${CommentInPostFragmentFragmentDoc}
  ${FileInfoFragmentDoc}
`;

/**
 * __useCurrentCompanyFunsQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyFunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyFunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyFunsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      limit: // value for 'limit'
 *      postToShow: // value for 'postToShow'
 *      sortBy: // value for 'sortBy'
 *      filterString: // value for 'filterString'
 *      page: // value for 'page'
 *      postType: // value for 'postType'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCurrentCompanyFunsQuery(
  baseOptions?: Apollo.QueryHookOptions<CurrentCompanyFunsQuery, CurrentCompanyFunsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentCompanyFunsQuery, CurrentCompanyFunsQueryVariables>(
    CurrentCompanyFunsDocument,
    options,
  );
}
export function useCurrentCompanyFunsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyFunsQuery,
    CurrentCompanyFunsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentCompanyFunsQuery, CurrentCompanyFunsQueryVariables>(
    CurrentCompanyFunsDocument,
    options,
  );
}
export type CurrentCompanyFunsQueryHookResult = ReturnType<typeof useCurrentCompanyFunsQuery>;
export type CurrentCompanyFunsLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyFunsLazyQuery
>;
export type CurrentCompanyFunsQueryResult = Apollo.QueryResult<
  CurrentCompanyFunsQuery,
  CurrentCompanyFunsQueryVariables
>;
export const ProblemCircuitsDocument = gql`
  query ProblemCircuits {
    allProblemCircuits {
      id
      title
    }
  }
`;

/**
 * __useProblemCircuitsQuery__
 *
 * To run a query within a React component, call `useProblemCircuitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProblemCircuitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProblemCircuitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProblemCircuitsQuery(
  baseOptions?: Apollo.QueryHookOptions<ProblemCircuitsQuery, ProblemCircuitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProblemCircuitsQuery, ProblemCircuitsQueryVariables>(
    ProblemCircuitsDocument,
    options,
  );
}
export function useProblemCircuitsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProblemCircuitsQuery, ProblemCircuitsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProblemCircuitsQuery, ProblemCircuitsQueryVariables>(
    ProblemCircuitsDocument,
    options,
  );
}
export type ProblemCircuitsQueryHookResult = ReturnType<typeof useProblemCircuitsQuery>;
export type ProblemCircuitsLazyQueryHookResult = ReturnType<typeof useProblemCircuitsLazyQuery>;
export type ProblemCircuitsQueryResult = Apollo.QueryResult<
  ProblemCircuitsQuery,
  ProblemCircuitsQueryVariables
>;
export const CurrentCompanyQuestionsDocument = gql`
  query currentCompanyQuestions(
    $companyId: ID
    $communityId: ID
    $limit: Int
    $sortBy: POST_SORT_TYPE
    $postToShow: POST_TO_SHOW
    $filterBy: PostFilterByInput
    $filterString: String
    $postType: PostType = QUESTION
    $page: Int
    $filters: [Filter!]
  ) {
    currentCompanyQuestions(
      companyId: $companyId
      communityId: $communityId
      limit: $limit
      sortBy: $sortBy
      postToShow: $postToShow
      filterBy: $filterBy
      filterString: $filterString
      page: $page
      filters: $filters
    ) {
      id
      postType
      title
      shareCount
      removed
      isSeenByMe
      isMine
      createdAt
      createdBy {
        id
        firstName
        lastName
        fullName
        photoUrl
        photoPublicUrl
      }
      cmCreatedBy {
        id
        user {
          id
          firstName
          lastName
          fullName
          photoPublicUrl
          photoUrl
        }
      }
      recipientCommunity {
        id
        name
        logo
        logoPublicUrl
      }
      sharedToSpecificCommunities {
        id
        name
        logo
        logoPublicUrl
      }
      commentCount
      comments {
        ...CommentInPostFragment
      }
      questionCategory {
        id
        title
      }
      sharedCompanyMemberPosts {
        communities {
          id
          name
          logo
          logoPublicUrl
        }
      }
    }
    count: currentCompanyPostNumberByType(postType: $postType, filters: $filters)
    isFiltersApplied(filters: $filters, postType: $postType)
    currentWholeCompany {
      id
      name
    }
  }
  ${CommentInPostFragmentFragmentDoc}
`;

/**
 * __useCurrentCompanyQuestionsQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyQuestionsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      communityId: // value for 'communityId'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      postToShow: // value for 'postToShow'
 *      filterBy: // value for 'filterBy'
 *      filterString: // value for 'filterString'
 *      postType: // value for 'postType'
 *      page: // value for 'page'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useCurrentCompanyQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyQuestionsQuery,
    CurrentCompanyQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentCompanyQuestionsQuery, CurrentCompanyQuestionsQueryVariables>(
    CurrentCompanyQuestionsDocument,
    options,
  );
}
export function useCurrentCompanyQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyQuestionsQuery,
    CurrentCompanyQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentCompanyQuestionsQuery, CurrentCompanyQuestionsQueryVariables>(
    CurrentCompanyQuestionsDocument,
    options,
  );
}
export type CurrentCompanyQuestionsQueryHookResult = ReturnType<
  typeof useCurrentCompanyQuestionsQuery
>;
export type CurrentCompanyQuestionsLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyQuestionsLazyQuery
>;
export type CurrentCompanyQuestionsQueryResult = Apollo.QueryResult<
  CurrentCompanyQuestionsQuery,
  CurrentCompanyQuestionsQueryVariables
>;
export const GetProblemByIdDocument = gql`
  query GetProblemById($id: ID!) {
    problem: getPostById(id: $id) {
      id
      title
      content
      contentImageUrls
      problemLevel
      problemTo
      who
      what
      how
      where
      when
      impact
      demand
      demandSolveStatus
      demandImproveStatus
      createdAt
      isIssueSolved
      issuePrivacy
      finalized
      isAlreadyUseA3Process
      problemCategory {
        id
        title
      }
      cmCreatedBy {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      communities {
        id
        name
        logo
        logoPublicUrl
      }
      isMeAMemberOfProblemCommunitySource
      problemCommunitySource {
        id
        name
        logo
        logoPublicUrl
      }
      companyMemberResponsibles {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      companyMemberExperts {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      commentCount
      comments {
        ...CommentInPostFragment
      }
      publicCommentCount
      publicComments {
        ...CommentInPostFragment
      }
      privateCommentCount
      privateComments {
        ...CommentInPostFragment
      }
      fileCount
      files {
        ...FileInfo
      }
      imageFiles {
        ...FileInfo
      }
      presentationFile {
        ...FileInfo
      }
    }
  }
  ${CommentInPostFragmentFragmentDoc}
  ${FileInfoFragmentDoc}
`;

/**
 * __useGetProblemByIdQuery__
 *
 * To run a query within a React component, call `useGetProblemByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProblemByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProblemByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProblemByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetProblemByIdQuery, GetProblemByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProblemByIdQuery, GetProblemByIdQueryVariables>(
    GetProblemByIdDocument,
    options,
  );
}
export function useGetProblemByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProblemByIdQuery, GetProblemByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProblemByIdQuery, GetProblemByIdQueryVariables>(
    GetProblemByIdDocument,
    options,
  );
}
export type GetProblemByIdQueryHookResult = ReturnType<typeof useGetProblemByIdQuery>;
export type GetProblemByIdLazyQueryHookResult = ReturnType<typeof useGetProblemByIdLazyQuery>;
export type GetProblemByIdQueryResult = Apollo.QueryResult<
  GetProblemByIdQuery,
  GetProblemByIdQueryVariables
>;
export const GetProcessByIdDocument = gql`
  query GetProcessById($id: ID!) {
    process: getPostById(id: $id) {
      id
      title
      content
      contentImageUrls
      commentCount
      isSeenByMe
      isMine
      createdAt
      isLikedByMe
      likeCount
      shareCount
      draft
      reopened
      processStatus
      isCMValidator
      processValidators {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      cmCreatedBy {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      companyMemberResponsibles {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      communities {
        id
        name
        logo
        logoPublicUrl
      }
      recipientCommunity {
        id
        name
        logo
        logoPublicUrl
      }
      sharedCompanyMemberPosts {
        id
        communities {
          id
          name
          logo
          logoPublicUrl
        }
      }
      companyMemberExperts {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      comments {
        ...CommentInPostFragment
      }
      fileCount
      files {
        ...FileInfo
      }
      imageFiles {
        ...FileInfo
      }
      presentationFile {
        ...FileInfo
      }
    }
  }
  ${CommentInPostFragmentFragmentDoc}
  ${FileInfoFragmentDoc}
`;

/**
 * __useGetProcessByIdQuery__
 *
 * To run a query within a React component, call `useGetProcessByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcessByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProcessByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetProcessByIdQuery, GetProcessByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProcessByIdQuery, GetProcessByIdQueryVariables>(
    GetProcessByIdDocument,
    options,
  );
}
export function useGetProcessByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProcessByIdQuery, GetProcessByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProcessByIdQuery, GetProcessByIdQueryVariables>(
    GetProcessByIdDocument,
    options,
  );
}
export type GetProcessByIdQueryHookResult = ReturnType<typeof useGetProcessByIdQuery>;
export type GetProcessByIdLazyQueryHookResult = ReturnType<typeof useGetProcessByIdLazyQuery>;
export type GetProcessByIdQueryResult = Apollo.QueryResult<
  GetProcessByIdQuery,
  GetProcessByIdQueryVariables
>;
export const IssueCategoriesDocument = gql`
  query IssueCategories {
    problemCategories {
      id
      title
      removed
      updatedAt
      createdAt
    }
  }
`;

/**
 * __useIssueCategoriesQuery__
 *
 * To run a query within a React component, call `useIssueCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIssueCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIssueCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIssueCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<IssueCategoriesQuery, IssueCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<IssueCategoriesQuery, IssueCategoriesQueryVariables>(
    IssueCategoriesDocument,
    options,
  );
}
export function useIssueCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IssueCategoriesQuery, IssueCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<IssueCategoriesQuery, IssueCategoriesQueryVariables>(
    IssueCategoriesDocument,
    options,
  );
}
export type IssueCategoriesQueryHookResult = ReturnType<typeof useIssueCategoriesQuery>;
export type IssueCategoriesLazyQueryHookResult = ReturnType<typeof useIssueCategoriesLazyQuery>;
export type IssueCategoriesQueryResult = Apollo.QueryResult<
  IssueCategoriesQuery,
  IssueCategoriesQueryVariables
>;
export const CurrentPostFiltersDocument = gql`
  query CurrentPostFilters($postType: PostType!) {
    currentPostFilters(postType: $postType) {
      postField
      selectedChip
      selectedChips
      selectedDynamicMultiselectItems
      sliderValue
      dateRange {
        endDate
        startDate
      }
    }
  }
`;

/**
 * __useCurrentPostFiltersQuery__
 *
 * To run a query within a React component, call `useCurrentPostFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPostFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentPostFiltersQuery({
 *   variables: {
 *      postType: // value for 'postType'
 *   },
 * });
 */
export function useCurrentPostFiltersQuery(
  baseOptions: Apollo.QueryHookOptions<CurrentPostFiltersQuery, CurrentPostFiltersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentPostFiltersQuery, CurrentPostFiltersQueryVariables>(
    CurrentPostFiltersDocument,
    options,
  );
}
export function useCurrentPostFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentPostFiltersQuery,
    CurrentPostFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CurrentPostFiltersQuery, CurrentPostFiltersQueryVariables>(
    CurrentPostFiltersDocument,
    options,
  );
}
export type CurrentPostFiltersQueryHookResult = ReturnType<typeof useCurrentPostFiltersQuery>;
export type CurrentPostFiltersLazyQueryHookResult = ReturnType<
  typeof useCurrentPostFiltersLazyQuery
>;
export type CurrentPostFiltersQueryResult = Apollo.QueryResult<
  CurrentPostFiltersQuery,
  CurrentPostFiltersQueryVariables
>;
export const GetCurrentCompanyMemberInChargeOfDocument = gql`
  query getCurrentCompanyMemberInChargeOf($postId: ID!) {
    isCurrentCompanyMemberInChargeOf(postId: $postId)
  }
`;

/**
 * __useGetCurrentCompanyMemberInChargeOfQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyMemberInChargeOfQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyMemberInChargeOfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyMemberInChargeOfQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useGetCurrentCompanyMemberInChargeOfQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentCompanyMemberInChargeOfQuery,
    GetCurrentCompanyMemberInChargeOfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentCompanyMemberInChargeOfQuery,
    GetCurrentCompanyMemberInChargeOfQueryVariables
  >(GetCurrentCompanyMemberInChargeOfDocument, options);
}
export function useGetCurrentCompanyMemberInChargeOfLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyMemberInChargeOfQuery,
    GetCurrentCompanyMemberInChargeOfQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyMemberInChargeOfQuery,
    GetCurrentCompanyMemberInChargeOfQueryVariables
  >(GetCurrentCompanyMemberInChargeOfDocument, options);
}
export type GetCurrentCompanyMemberInChargeOfQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyMemberInChargeOfQuery
>;
export type GetCurrentCompanyMemberInChargeOfLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyMemberInChargeOfLazyQuery
>;
export type GetCurrentCompanyMemberInChargeOfQueryResult = Apollo.QueryResult<
  GetCurrentCompanyMemberInChargeOfQuery,
  GetCurrentCompanyMemberInChargeOfQueryVariables
>;
export const CreateQuestionCategoryDocument = gql`
  mutation CreateQuestionCategory($title: String!) {
    createQuestionCategory(title: $title) {
      id
    }
  }
`;
export type CreateQuestionCategoryMutationFn = Apollo.MutationFunction<
  CreateQuestionCategoryMutation,
  CreateQuestionCategoryMutationVariables
>;

/**
 * __useCreateQuestionCategoryMutation__
 *
 * To run a mutation, you first call `useCreateQuestionCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuestionCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuestionCategoryMutation, { data, loading, error }] = useCreateQuestionCategoryMutation({
 *   variables: {
 *      title: // value for 'title'
 *   },
 * });
 */
export function useCreateQuestionCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQuestionCategoryMutation,
    CreateQuestionCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQuestionCategoryMutation,
    CreateQuestionCategoryMutationVariables
  >(CreateQuestionCategoryDocument, options);
}
export type CreateQuestionCategoryMutationHookResult = ReturnType<
  typeof useCreateQuestionCategoryMutation
>;
export type CreateQuestionCategoryMutationResult =
  Apollo.MutationResult<CreateQuestionCategoryMutation>;
export type CreateQuestionCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateQuestionCategoryMutation,
  CreateQuestionCategoryMutationVariables
>;
export const DeleteQuestionCategoryDocument = gql`
  mutation DeleteQuestionCategory($id: ID!) {
    deleteQuestionCategory(id: $id) {
      id
    }
  }
`;
export type DeleteQuestionCategoryMutationFn = Apollo.MutationFunction<
  DeleteQuestionCategoryMutation,
  DeleteQuestionCategoryMutationVariables
>;

/**
 * __useDeleteQuestionCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteQuestionCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQuestionCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQuestionCategoryMutation, { data, loading, error }] = useDeleteQuestionCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQuestionCategoryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteQuestionCategoryMutation,
    DeleteQuestionCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteQuestionCategoryMutation,
    DeleteQuestionCategoryMutationVariables
  >(DeleteQuestionCategoryDocument, options);
}
export type DeleteQuestionCategoryMutationHookResult = ReturnType<
  typeof useDeleteQuestionCategoryMutation
>;
export type DeleteQuestionCategoryMutationResult =
  Apollo.MutationResult<DeleteQuestionCategoryMutation>;
export type DeleteQuestionCategoryMutationOptions = Apollo.BaseMutationOptions<
  DeleteQuestionCategoryMutation,
  DeleteQuestionCategoryMutationVariables
>;
export const GetCurrentCompanyAdminQuestionsDocument = gql`
  query getCurrentCompanyAdminQuestions(
    $filter: PostFilter
    $page: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
  ) {
    currentCompanyAdminQuestions(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      questions: posts {
        id
        postType
        title
        content
        createdAt
        isSeenByMe
        isReported
        isHidden
        communities {
          id
          name
          logoPublicUrl
          logo
        }
        recipientCommunity {
          id
          name
          logo
          logoPublicUrl
        }
        cmCreatedBy {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoPublicUrl
            photoUrl
          }
        }
      }
      total
    }
  }
`;

/**
 * __useGetCurrentCompanyAdminQuestionsQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyAdminQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyAdminQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyAdminQuestionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetCurrentCompanyAdminQuestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyAdminQuestionsQuery,
    GetCurrentCompanyAdminQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentCompanyAdminQuestionsQuery,
    GetCurrentCompanyAdminQuestionsQueryVariables
  >(GetCurrentCompanyAdminQuestionsDocument, options);
}
export function useGetCurrentCompanyAdminQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyAdminQuestionsQuery,
    GetCurrentCompanyAdminQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyAdminQuestionsQuery,
    GetCurrentCompanyAdminQuestionsQueryVariables
  >(GetCurrentCompanyAdminQuestionsDocument, options);
}
export type GetCurrentCompanyAdminQuestionsQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminQuestionsQuery
>;
export type GetCurrentCompanyAdminQuestionsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminQuestionsLazyQuery
>;
export type GetCurrentCompanyAdminQuestionsQueryResult = Apollo.QueryResult<
  GetCurrentCompanyAdminQuestionsQuery,
  GetCurrentCompanyAdminQuestionsQueryVariables
>;
export const QuestionCategoriesDocument = gql`
  query QuestionCategories {
    categories: questionCategories {
      id
      title
      removed
      updatedAt
      createdAt
    }
  }
`;

/**
 * __useQuestionCategoriesQuery__
 *
 * To run a query within a React component, call `useQuestionCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuestionCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuestionCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuestionCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<QuestionCategoriesQuery, QuestionCategoriesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<QuestionCategoriesQuery, QuestionCategoriesQueryVariables>(
    QuestionCategoriesDocument,
    options,
  );
}
export function useQuestionCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QuestionCategoriesQuery,
    QuestionCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<QuestionCategoriesQuery, QuestionCategoriesQueryVariables>(
    QuestionCategoriesDocument,
    options,
  );
}
export type QuestionCategoriesQueryHookResult = ReturnType<typeof useQuestionCategoriesQuery>;
export type QuestionCategoriesLazyQueryHookResult = ReturnType<
  typeof useQuestionCategoriesLazyQuery
>;
export type QuestionCategoriesQueryResult = Apollo.QueryResult<
  QuestionCategoriesQuery,
  QuestionCategoriesQueryVariables
>;
export const GetCurrentCompanyAdminWhatIsUpDocument = gql`
  query getCurrentCompanyAdminWhatIsUp(
    $filter: PostFilter
    $page: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
  ) {
    currentCompanyAdminStories(
      filter: $filter
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      allWhatsUp: posts {
        id
        postType
        title
        content
        createdAt
        isSeenByMe
        isReported
        isHidden
        communities {
          id
          name
          logoPublicUrl
          logo
        }
        sharedToSpecificUsers {
          id
          firstName
          lastName
          photoUrl
          photoPublicUrl
          currentCompanyMember {
            id
          }
        }
        sharedToSpecificCommunities {
          id
          name
          logo
          logoPublicUrl
        }
        cmCreatedBy {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoPublicUrl
            photoUrl
          }
        }
      }
      total
    }
  }
`;

/**
 * __useGetCurrentCompanyAdminWhatIsUpQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyAdminWhatIsUpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyAdminWhatIsUpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyAdminWhatIsUpQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *   },
 * });
 */
export function useGetCurrentCompanyAdminWhatIsUpQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyAdminWhatIsUpQuery,
    GetCurrentCompanyAdminWhatIsUpQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCurrentCompanyAdminWhatIsUpQuery,
    GetCurrentCompanyAdminWhatIsUpQueryVariables
  >(GetCurrentCompanyAdminWhatIsUpDocument, options);
}
export function useGetCurrentCompanyAdminWhatIsUpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyAdminWhatIsUpQuery,
    GetCurrentCompanyAdminWhatIsUpQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyAdminWhatIsUpQuery,
    GetCurrentCompanyAdminWhatIsUpQueryVariables
  >(GetCurrentCompanyAdminWhatIsUpDocument, options);
}
export type GetCurrentCompanyAdminWhatIsUpQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminWhatIsUpQuery
>;
export type GetCurrentCompanyAdminWhatIsUpLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyAdminWhatIsUpLazyQuery
>;
export type GetCurrentCompanyAdminWhatIsUpQueryResult = Apollo.QueryResult<
  GetCurrentCompanyAdminWhatIsUpQuery,
  GetCurrentCompanyAdminWhatIsUpQueryVariables
>;
export const GetCurrentCompanyProductsDocument = gql`
  query GetCurrentCompanyProducts {
    getCurrentCompanyProducts {
      ...ProductInfo
      thumbnail {
        ...ProductThumbnailInfo
      }
    }
  }
  ${ProductInfoFragmentDoc}
  ${ProductThumbnailInfoFragmentDoc}
`;

/**
 * __useGetCurrentCompanyProductsQuery__
 *
 * To run a query within a React component, call `useGetCurrentCompanyProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentCompanyProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentCompanyProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentCompanyProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentCompanyProductsQuery,
    GetCurrentCompanyProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentCompanyProductsQuery, GetCurrentCompanyProductsQueryVariables>(
    GetCurrentCompanyProductsDocument,
    options,
  );
}
export function useGetCurrentCompanyProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentCompanyProductsQuery,
    GetCurrentCompanyProductsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCurrentCompanyProductsQuery,
    GetCurrentCompanyProductsQueryVariables
  >(GetCurrentCompanyProductsDocument, options);
}
export type GetCurrentCompanyProductsQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyProductsQuery
>;
export type GetCurrentCompanyProductsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentCompanyProductsLazyQuery
>;
export type GetCurrentCompanyProductsQueryResult = Apollo.QueryResult<
  GetCurrentCompanyProductsQuery,
  GetCurrentCompanyProductsQueryVariables
>;
export const GetProductByIdDocument = gql`
  query GetProductById($id: ID!) {
    Product(id: $id) {
      ...ProductInfo
      thumbnail {
        ...ProductThumbnailInfo
      }
    }
  }
  ${ProductInfoFragmentDoc}
  ${ProductThumbnailInfoFragmentDoc}
`;

/**
 * __useGetProductByIdQuery__
 *
 * To run a query within a React component, call `useGetProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProductByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductByIdQuery, GetProductByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductByIdQuery, GetProductByIdQueryVariables>(
    GetProductByIdDocument,
    options,
  );
}
export function useGetProductByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductByIdQuery, GetProductByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductByIdQuery, GetProductByIdQueryVariables>(
    GetProductByIdDocument,
    options,
  );
}
export type GetProductByIdQueryHookResult = ReturnType<typeof useGetProductByIdQuery>;
export type GetProductByIdLazyQueryHookResult = ReturnType<typeof useGetProductByIdLazyQuery>;
export type GetProductByIdQueryResult = Apollo.QueryResult<
  GetProductByIdQuery,
  GetProductByIdQueryVariables
>;
export const CreateProjectDocument = gql`
  mutation CreateProject(
    $title: String!
    $description: String
    $targetDate: Date
    $priorityLevel: Int
    $responsibleId: ID
    $communityId: ID!
    $convertedFromId: ID
    $fromSelectedCommunityId: ID
    $postId: ID
  ) {
    createProject(
      title: $title
      description: $description
      targetDate: $targetDate
      priorityLevel: $priorityLevel
      communityId: $communityId
      responsibleId: $responsibleId
      convertedFromId: $convertedFromId
      fromSelectedCommunityId: $fromSelectedCommunityId
      postId: $postId
    ) {
      id
    }
  }
`;
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      targetDate: // value for 'targetDate'
 *      priorityLevel: // value for 'priorityLevel'
 *      responsibleId: // value for 'responsibleId'
 *      communityId: // value for 'communityId'
 *      convertedFromId: // value for 'convertedFromId'
 *      fromSelectedCommunityId: // value for 'fromSelectedCommunityId'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(
    CreateProjectDocument,
    options,
  );
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>;
export const UpdateProjectDocument = gql`
  mutation UpdateProject(
    $id: ID!
    $title: String!
    $description: String
    $targetDate: Date
    $priorityLevel: Int
    $responsibleId: ID
    $communityId: ID!
  ) {
    updateProject(
      id: $id
      title: $title
      description: $description
      priorityLevel: $priorityLevel
      targetDate: $targetDate
      responsibleId: $responsibleId
      communityId: $communityId
    ) {
      id
      title
      description
      priorityLevel
      targetDate
      isCompleted
      companyMemberResponsible {
        id
        user {
          id
        }
      }
    }
  }
`;
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      targetDate: // value for 'targetDate'
 *      priorityLevel: // value for 'priorityLevel'
 *      responsibleId: // value for 'responsibleId'
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    options,
  );
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>;
export const UpdateProjectStatusDocument = gql`
  mutation UpdateProjectStatus($id: ID!, $status: ProjectStatus!) {
    updateProjectStatus(id: $id, status: $status) {
      id
      status
    }
  }
`;
export type UpdateProjectStatusMutationFn = Apollo.MutationFunction<
  UpdateProjectStatusMutation,
  UpdateProjectStatusMutationVariables
>;

/**
 * __useUpdateProjectStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProjectStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectStatusMutation, { data, loading, error }] = useUpdateProjectStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateProjectStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectStatusMutation,
    UpdateProjectStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProjectStatusMutation, UpdateProjectStatusMutationVariables>(
    UpdateProjectStatusDocument,
    options,
  );
}
export type UpdateProjectStatusMutationHookResult = ReturnType<
  typeof useUpdateProjectStatusMutation
>;
export type UpdateProjectStatusMutationResult = Apollo.MutationResult<UpdateProjectStatusMutation>;
export type UpdateProjectStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectStatusMutation,
  UpdateProjectStatusMutationVariables
>;
export const FinalizeProjectDocument = gql`
  mutation FinalizeProject($id: ID!) {
    finalizeProject(id: $id) {
      id
      finalized
    }
  }
`;
export type FinalizeProjectMutationFn = Apollo.MutationFunction<
  FinalizeProjectMutation,
  FinalizeProjectMutationVariables
>;

/**
 * __useFinalizeProjectMutation__
 *
 * To run a mutation, you first call `useFinalizeProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeProjectMutation, { data, loading, error }] = useFinalizeProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinalizeProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    FinalizeProjectMutation,
    FinalizeProjectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<FinalizeProjectMutation, FinalizeProjectMutationVariables>(
    FinalizeProjectDocument,
    options,
  );
}
export type FinalizeProjectMutationHookResult = ReturnType<typeof useFinalizeProjectMutation>;
export type FinalizeProjectMutationResult = Apollo.MutationResult<FinalizeProjectMutation>;
export type FinalizeProjectMutationOptions = Apollo.BaseMutationOptions<
  FinalizeProjectMutation,
  FinalizeProjectMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
    }
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(
    DeleteProjectDocument,
    options,
  );
}
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = Apollo.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const GetProjectsByCommunityDocument = gql`
  query GetProjectsByCommunity($id: ID!, $perPage: Int, $page: Int) {
    projects: getProjectsByCommunity(id: $id, perPage: $perPage, page: $page) {
      id
      title
      description
      isCompleted
      targetDate
      status
      priorityLevel
      finalized
      companyMemberResponsible {
        id
        user {
          ...UserInProjectFragment
        }
      }
      convertedFrom {
        id
        postType
      }
    }
    total: _allProjectsMeta(filter: { teamId: $id }) {
      count
    }
  }
  ${UserInProjectFragmentFragmentDoc}
`;

/**
 * __useGetProjectsByCommunityQuery__
 *
 * To run a query within a React component, call `useGetProjectsByCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsByCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsByCommunityQuery({
 *   variables: {
 *      id: // value for 'id'
 *      perPage: // value for 'perPage'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetProjectsByCommunityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsByCommunityQuery,
    GetProjectsByCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsByCommunityQuery, GetProjectsByCommunityQueryVariables>(
    GetProjectsByCommunityDocument,
    options,
  );
}
export function useGetProjectsByCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsByCommunityQuery,
    GetProjectsByCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectsByCommunityQuery, GetProjectsByCommunityQueryVariables>(
    GetProjectsByCommunityDocument,
    options,
  );
}
export type GetProjectsByCommunityQueryHookResult = ReturnType<
  typeof useGetProjectsByCommunityQuery
>;
export type GetProjectsByCommunityLazyQueryHookResult = ReturnType<
  typeof useGetProjectsByCommunityLazyQuery
>;
export type GetProjectsByCommunityQueryResult = Apollo.QueryResult<
  GetProjectsByCommunityQuery,
  GetProjectsByCommunityQueryVariables
>;
export const GetProjectDocument = gql`
  query GetProject($id: ID!) {
    project: getProject(id: $id) {
      id
      title
      description
      isCompleted
      targetDate
      status
      priorityLevel
      finalized
      createdByCompanyMember {
        id
        user {
          ...UserInProjectFragment
        }
      }
      companyMemberResponsible {
        id
        user {
          ...UserInProjectFragment
        }
      }
      community {
        id
        name
        logo
        logoPublicUrl
      }
      convertedFrom {
        id
        postType
      }
      comments {
        id
        content
        createdAt
        isMine
        companyMember {
          id
          user {
            ...UserInProjectFragment
          }
        }
        likedBy {
          user {
            id
            fullName
            photoPublicUrl
            lastName
            firstName
            currentCompanyMember {
              id
            }
          }
        }
        likeCount
        isLikedByMe
      }
    }
  }
  ${UserInProjectFragmentFragmentDoc}
`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectQuery(
  baseOptions: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, options);
}
export function useGetProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(
    GetProjectDocument,
    options,
  );
}
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const CreateMottoDocument = gql`
  mutation CreateMotto($content: String!) {
    createMotto(content: $content) {
      id
      content
    }
  }
`;
export type CreateMottoMutationFn = Apollo.MutationFunction<
  CreateMottoMutation,
  CreateMottoMutationVariables
>;

/**
 * __useCreateMottoMutation__
 *
 * To run a mutation, you first call `useCreateMottoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMottoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMottoMutation, { data, loading, error }] = useCreateMottoMutation({
 *   variables: {
 *      content: // value for 'content'
 *   },
 * });
 */
export function useCreateMottoMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateMottoMutation, CreateMottoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateMottoMutation, CreateMottoMutationVariables>(
    CreateMottoDocument,
    options,
  );
}
export type CreateMottoMutationHookResult = ReturnType<typeof useCreateMottoMutation>;
export type CreateMottoMutationResult = Apollo.MutationResult<CreateMottoMutation>;
export type CreateMottoMutationOptions = Apollo.BaseMutationOptions<
  CreateMottoMutation,
  CreateMottoMutationVariables
>;
export const ChooseProfessionnalWeatherDocument = gql`
  mutation ChooseProfessionnalWeather($professionnalWeatherId: ID!) {
    chooseProfessionnalWeather(professionnalWeatherId: $professionnalWeatherId) {
      id
      professionnalWeatherId
      companyId
      companyMember {
        id
        currentProfessionnalWeather {
          id
          title
          icon
          description
        }
      }
    }
  }
`;
export type ChooseProfessionnalWeatherMutationFn = Apollo.MutationFunction<
  ChooseProfessionnalWeatherMutation,
  ChooseProfessionnalWeatherMutationVariables
>;

/**
 * __useChooseProfessionnalWeatherMutation__
 *
 * To run a mutation, you first call `useChooseProfessionnalWeatherMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseProfessionnalWeatherMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseProfessionnalWeatherMutation, { data, loading, error }] = useChooseProfessionnalWeatherMutation({
 *   variables: {
 *      professionnalWeatherId: // value for 'professionnalWeatherId'
 *   },
 * });
 */
export function useChooseProfessionnalWeatherMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChooseProfessionnalWeatherMutation,
    ChooseProfessionnalWeatherMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChooseProfessionnalWeatherMutation,
    ChooseProfessionnalWeatherMutationVariables
  >(ChooseProfessionnalWeatherDocument, options);
}
export type ChooseProfessionnalWeatherMutationHookResult = ReturnType<
  typeof useChooseProfessionnalWeatherMutation
>;
export type ChooseProfessionnalWeatherMutationResult =
  Apollo.MutationResult<ChooseProfessionnalWeatherMutation>;
export type ChooseProfessionnalWeatherMutationOptions = Apollo.BaseMutationOptions<
  ChooseProfessionnalWeatherMutation,
  ChooseProfessionnalWeatherMutationVariables
>;
export const ThanksSomeoneDocument = gql`
  mutation ThanksSomeone(
    $companyMemberReceiverIds: [ID]!
    $content: String!
    $type: UserThankType
  ) {
    thanksSomeone(
      companyMemberReceiverIds: $companyMemberReceiverIds
      content: $content
      type: $type
    ) {
      id
    }
  }
`;
export type ThanksSomeoneMutationFn = Apollo.MutationFunction<
  ThanksSomeoneMutation,
  ThanksSomeoneMutationVariables
>;

/**
 * __useThanksSomeoneMutation__
 *
 * To run a mutation, you first call `useThanksSomeoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useThanksSomeoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [thanksSomeoneMutation, { data, loading, error }] = useThanksSomeoneMutation({
 *   variables: {
 *      companyMemberReceiverIds: // value for 'companyMemberReceiverIds'
 *      content: // value for 'content'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useThanksSomeoneMutation(
  baseOptions?: Apollo.MutationHookOptions<ThanksSomeoneMutation, ThanksSomeoneMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ThanksSomeoneMutation, ThanksSomeoneMutationVariables>(
    ThanksSomeoneDocument,
    options,
  );
}
export type ThanksSomeoneMutationHookResult = ReturnType<typeof useThanksSomeoneMutation>;
export type ThanksSomeoneMutationResult = Apollo.MutationResult<ThanksSomeoneMutation>;
export type ThanksSomeoneMutationOptions = Apollo.BaseMutationOptions<
  ThanksSomeoneMutation,
  ThanksSomeoneMutationVariables
>;
export const ReplyThanksDocument = gql`
  mutation ReplyThanks($id: ID!, $response: String!) {
    replyThanks(id: $id, response: $response) {
      id
      response
      companyMemberSender {
        id
        removed
        user {
          id
          email
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
          suspended
        }
        company {
          id
          slug
        }
      }
    }
  }
`;
export type ReplyThanksMutationFn = Apollo.MutationFunction<
  ReplyThanksMutation,
  ReplyThanksMutationVariables
>;

/**
 * __useReplyThanksMutation__
 *
 * To run a mutation, you first call `useReplyThanksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReplyThanksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [replyThanksMutation, { data, loading, error }] = useReplyThanksMutation({
 *   variables: {
 *      id: // value for 'id'
 *      response: // value for 'response'
 *   },
 * });
 */
export function useReplyThanksMutation(
  baseOptions?: Apollo.MutationHookOptions<ReplyThanksMutation, ReplyThanksMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ReplyThanksMutation, ReplyThanksMutationVariables>(
    ReplyThanksDocument,
    options,
  );
}
export type ReplyThanksMutationHookResult = ReturnType<typeof useReplyThanksMutation>;
export type ReplyThanksMutationResult = Apollo.MutationResult<ReplyThanksMutation>;
export type ReplyThanksMutationOptions = Apollo.BaseMutationOptions<
  ReplyThanksMutation,
  ReplyThanksMutationVariables
>;
export const WishSomeoneDocument = gql`
  mutation WishSomeone($userId: ID!, $wish: String!) {
    wishSomeone(userId: $userId, wish: $wish)
  }
`;
export type WishSomeoneMutationFn = Apollo.MutationFunction<
  WishSomeoneMutation,
  WishSomeoneMutationVariables
>;

/**
 * __useWishSomeoneMutation__
 *
 * To run a mutation, you first call `useWishSomeoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWishSomeoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [wishSomeoneMutation, { data, loading, error }] = useWishSomeoneMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      wish: // value for 'wish'
 *   },
 * });
 */
export function useWishSomeoneMutation(
  baseOptions?: Apollo.MutationHookOptions<WishSomeoneMutation, WishSomeoneMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<WishSomeoneMutation, WishSomeoneMutationVariables>(
    WishSomeoneDocument,
    options,
  );
}
export type WishSomeoneMutationHookResult = ReturnType<typeof useWishSomeoneMutation>;
export type WishSomeoneMutationResult = Apollo.MutationResult<WishSomeoneMutation>;
export type WishSomeoneMutationOptions = Apollo.BaseMutationOptions<
  WishSomeoneMutation,
  WishSomeoneMutationVariables
>;
export const UpdateUserPictureDocument = gql`
  mutation updateUserPicture($picturesToAdd: [pictureInput!], $picturesIdToRemove: [String!]) {
    updateUserPicture(picturesToAdd: $picturesToAdd, picturesIdToRemove: $picturesIdToRemove) {
      id
      pictures {
        id
      }
    }
  }
`;
export type UpdateUserPictureMutationFn = Apollo.MutationFunction<
  UpdateUserPictureMutation,
  UpdateUserPictureMutationVariables
>;

/**
 * __useUpdateUserPictureMutation__
 *
 * To run a mutation, you first call `useUpdateUserPictureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPictureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPictureMutation, { data, loading, error }] = useUpdateUserPictureMutation({
 *   variables: {
 *      picturesToAdd: // value for 'picturesToAdd'
 *      picturesIdToRemove: // value for 'picturesIdToRemove'
 *   },
 * });
 */
export function useUpdateUserPictureMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPictureMutation,
    UpdateUserPictureMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserPictureMutation, UpdateUserPictureMutationVariables>(
    UpdateUserPictureDocument,
    options,
  );
}
export type UpdateUserPictureMutationHookResult = ReturnType<typeof useUpdateUserPictureMutation>;
export type UpdateUserPictureMutationResult = Apollo.MutationResult<UpdateUserPictureMutation>;
export type UpdateUserPictureMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPictureMutation,
  UpdateUserPictureMutationVariables
>;
export const UpdateLeaderMessageDocument = gql`
  mutation UpdateLeaderMessage($id: ID!, $message: String!) {
    updateLeaderMessage(id: $id, message: $message) {
      id
      message
    }
  }
`;
export type UpdateLeaderMessageMutationFn = Apollo.MutationFunction<
  UpdateLeaderMessageMutation,
  UpdateLeaderMessageMutationVariables
>;

/**
 * __useUpdateLeaderMessageMutation__
 *
 * To run a mutation, you first call `useUpdateLeaderMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeaderMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeaderMessageMutation, { data, loading, error }] = useUpdateLeaderMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useUpdateLeaderMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLeaderMessageMutation,
    UpdateLeaderMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLeaderMessageMutation, UpdateLeaderMessageMutationVariables>(
    UpdateLeaderMessageDocument,
    options,
  );
}
export type UpdateLeaderMessageMutationHookResult = ReturnType<
  typeof useUpdateLeaderMessageMutation
>;
export type UpdateLeaderMessageMutationResult = Apollo.MutationResult<UpdateLeaderMessageMutation>;
export type UpdateLeaderMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateLeaderMessageMutation,
  UpdateLeaderMessageMutationVariables
>;
export const ChooseProfessionalStatusDocument = gql`
  mutation ChooseProfessionalStatus($professionalStatusId: ID) {
    chooseProfessionalStatus(professionalStatusId: $professionalStatusId) {
      title
    }
  }
`;
export type ChooseProfessionalStatusMutationFn = Apollo.MutationFunction<
  ChooseProfessionalStatusMutation,
  ChooseProfessionalStatusMutationVariables
>;

/**
 * __useChooseProfessionalStatusMutation__
 *
 * To run a mutation, you first call `useChooseProfessionalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChooseProfessionalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chooseProfessionalStatusMutation, { data, loading, error }] = useChooseProfessionalStatusMutation({
 *   variables: {
 *      professionalStatusId: // value for 'professionalStatusId'
 *   },
 * });
 */
export function useChooseProfessionalStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChooseProfessionalStatusMutation,
    ChooseProfessionalStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChooseProfessionalStatusMutation,
    ChooseProfessionalStatusMutationVariables
  >(ChooseProfessionalStatusDocument, options);
}
export type ChooseProfessionalStatusMutationHookResult = ReturnType<
  typeof useChooseProfessionalStatusMutation
>;
export type ChooseProfessionalStatusMutationResult =
  Apollo.MutationResult<ChooseProfessionalStatusMutation>;
export type ChooseProfessionalStatusMutationOptions = Apollo.BaseMutationOptions<
  ChooseProfessionalStatusMutation,
  ChooseProfessionalStatusMutationVariables
>;
export const UpdateCustomBadgeDocument = gql`
  mutation UpdateCustomBadge($input: UpdateCustomBadgeInput!) {
    updateCustomBadge(input: $input) {
      badgeId
      companyId
      minExp
      maxExp
      prevMinExp
      nextMaxExp
      badge {
        id
        name
        iconPublicUrl
        type
        minExp
        maxExp
      }
    }
  }
`;
export type UpdateCustomBadgeMutationFn = Apollo.MutationFunction<
  UpdateCustomBadgeMutation,
  UpdateCustomBadgeMutationVariables
>;

/**
 * __useUpdateCustomBadgeMutation__
 *
 * To run a mutation, you first call `useUpdateCustomBadgeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomBadgeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomBadgeMutation, { data, loading, error }] = useUpdateCustomBadgeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomBadgeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomBadgeMutation,
    UpdateCustomBadgeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCustomBadgeMutation, UpdateCustomBadgeMutationVariables>(
    UpdateCustomBadgeDocument,
    options,
  );
}
export type UpdateCustomBadgeMutationHookResult = ReturnType<typeof useUpdateCustomBadgeMutation>;
export type UpdateCustomBadgeMutationResult = Apollo.MutationResult<UpdateCustomBadgeMutation>;
export type UpdateCustomBadgeMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomBadgeMutation,
  UpdateCustomBadgeMutationVariables
>;
export const UpdateExpEventDocument = gql`
  mutation UpdateExpEvent($input: UpdateExpEventInput!) {
    updateExpEvent(input: $input) {
      id
      exp
      communityExp
    }
  }
`;
export type UpdateExpEventMutationFn = Apollo.MutationFunction<
  UpdateExpEventMutation,
  UpdateExpEventMutationVariables
>;

/**
 * __useUpdateExpEventMutation__
 *
 * To run a mutation, you first call `useUpdateExpEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpEventMutation, { data, loading, error }] = useUpdateExpEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateExpEventMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateExpEventMutation, UpdateExpEventMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateExpEventMutation, UpdateExpEventMutationVariables>(
    UpdateExpEventDocument,
    options,
  );
}
export type UpdateExpEventMutationHookResult = ReturnType<typeof useUpdateExpEventMutation>;
export type UpdateExpEventMutationResult = Apollo.MutationResult<UpdateExpEventMutation>;
export type UpdateExpEventMutationOptions = Apollo.BaseMutationOptions<
  UpdateExpEventMutation,
  UpdateExpEventMutationVariables
>;
export const AllProfessionnalWeathersDocument = gql`
  query AllProfessionnalWeathers {
    allProfessionnalWeathers {
      id
      title
      icon
    }
  }
`;

/**
 * __useAllProfessionnalWeathersQuery__
 *
 * To run a query within a React component, call `useAllProfessionnalWeathersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProfessionnalWeathersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProfessionnalWeathersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProfessionnalWeathersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllProfessionnalWeathersQuery,
    AllProfessionnalWeathersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllProfessionnalWeathersQuery, AllProfessionnalWeathersQueryVariables>(
    AllProfessionnalWeathersDocument,
    options,
  );
}
export function useAllProfessionnalWeathersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllProfessionnalWeathersQuery,
    AllProfessionnalWeathersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllProfessionnalWeathersQuery, AllProfessionnalWeathersQueryVariables>(
    AllProfessionnalWeathersDocument,
    options,
  );
}
export type AllProfessionnalWeathersQueryHookResult = ReturnType<
  typeof useAllProfessionnalWeathersQuery
>;
export type AllProfessionnalWeathersLazyQueryHookResult = ReturnType<
  typeof useAllProfessionnalWeathersLazyQuery
>;
export type AllProfessionnalWeathersQueryResult = Apollo.QueryResult<
  AllProfessionnalWeathersQuery,
  AllProfessionnalWeathersQueryVariables
>;
export const AllProfessionalStatusesDocument = gql`
  query AllProfessionalStatuses {
    allProfessionalStatuses {
      id
      title
      icon
      order
    }
  }
`;

/**
 * __useAllProfessionalStatusesQuery__
 *
 * To run a query within a React component, call `useAllProfessionalStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProfessionalStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProfessionalStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllProfessionalStatusesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllProfessionalStatusesQuery,
    AllProfessionalStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllProfessionalStatusesQuery, AllProfessionalStatusesQueryVariables>(
    AllProfessionalStatusesDocument,
    options,
  );
}
export function useAllProfessionalStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllProfessionalStatusesQuery,
    AllProfessionalStatusesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllProfessionalStatusesQuery, AllProfessionalStatusesQueryVariables>(
    AllProfessionalStatusesDocument,
    options,
  );
}
export type AllProfessionalStatusesQueryHookResult = ReturnType<
  typeof useAllProfessionalStatusesQuery
>;
export type AllProfessionalStatusesLazyQueryHookResult = ReturnType<
  typeof useAllProfessionalStatusesLazyQuery
>;
export type AllProfessionalStatusesQueryResult = Apollo.QueryResult<
  AllProfessionalStatusesQuery,
  AllProfessionalStatusesQueryVariables
>;
export const GetEventsTodayDocument = gql`
  query getEventsToday($limit: Int) {
    getEventsToday(limit: $limit) {
      id
      name
      description
      eventType {
        id
        name
        icon
        iconPublicUrl
        eventMessageSuggestions {
          id
          content
        }
      }
      companyMembers {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      day
    }
  }
`;

/**
 * __useGetEventsTodayQuery__
 *
 * To run a query within a React component, call `useGetEventsTodayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsTodayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsTodayQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetEventsTodayQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEventsTodayQuery, GetEventsTodayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEventsTodayQuery, GetEventsTodayQueryVariables>(
    GetEventsTodayDocument,
    options,
  );
}
export function useGetEventsTodayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEventsTodayQuery, GetEventsTodayQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEventsTodayQuery, GetEventsTodayQueryVariables>(
    GetEventsTodayDocument,
    options,
  );
}
export type GetEventsTodayQueryHookResult = ReturnType<typeof useGetEventsTodayQuery>;
export type GetEventsTodayLazyQueryHookResult = ReturnType<typeof useGetEventsTodayLazyQuery>;
export type GetEventsTodayQueryResult = Apollo.QueryResult<
  GetEventsTodayQuery,
  GetEventsTodayQueryVariables
>;
export const GetMessageSuggestionsDocument = gql`
  query GetMessageSuggestions($type: MessageType, $limit: Int) {
    getMessageSuggestions(type: $type, limit: $limit) {
      id
      content
      type
    }
  }
`;

/**
 * __useGetMessageSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetMessageSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMessageSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMessageSuggestionsQuery({
 *   variables: {
 *      type: // value for 'type'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetMessageSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMessageSuggestionsQuery,
    GetMessageSuggestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMessageSuggestionsQuery, GetMessageSuggestionsQueryVariables>(
    GetMessageSuggestionsDocument,
    options,
  );
}
export function useGetMessageSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMessageSuggestionsQuery,
    GetMessageSuggestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMessageSuggestionsQuery, GetMessageSuggestionsQueryVariables>(
    GetMessageSuggestionsDocument,
    options,
  );
}
export type GetMessageSuggestionsQueryHookResult = ReturnType<typeof useGetMessageSuggestionsQuery>;
export type GetMessageSuggestionsLazyQueryHookResult = ReturnType<
  typeof useGetMessageSuggestionsLazyQuery
>;
export type GetMessageSuggestionsQueryResult = Apollo.QueryResult<
  GetMessageSuggestionsQuery,
  GetMessageSuggestionsQueryVariables
>;
export const CoworkersDocument = gql`
  query Coworkers($companyId: ID) {
    coworkers(companyId: $companyId) {
      id
      email
      firstName
      lastName
      photoUrl
      photoPublicUrl
      role
      companyMembers {
        jobTitle
      }
    }
  }
`;

/**
 * __useCoworkersQuery__
 *
 * To run a query within a React component, call `useCoworkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoworkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoworkersQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCoworkersQuery(
  baseOptions?: Apollo.QueryHookOptions<CoworkersQuery, CoworkersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CoworkersQuery, CoworkersQueryVariables>(CoworkersDocument, options);
}
export function useCoworkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CoworkersQuery, CoworkersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CoworkersQuery, CoworkersQueryVariables>(CoworkersDocument, options);
}
export type CoworkersQueryHookResult = ReturnType<typeof useCoworkersQuery>;
export type CoworkersLazyQueryHookResult = ReturnType<typeof useCoworkersLazyQuery>;
export type CoworkersQueryResult = Apollo.QueryResult<CoworkersQuery, CoworkersQueryVariables>;
export const GetAllEventsDocument = gql`
  query GetAllEvents {
    allEvents {
      id
      name
      eventType {
        id
        name
        icon
        iconPublicUrl
      }
    }
  }
`;

/**
 * __useGetAllEventsQuery__
 *
 * To run a query within a React component, call `useGetAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllEventsQuery, GetAllEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllEventsQuery, GetAllEventsQueryVariables>(
    GetAllEventsDocument,
    options,
  );
}
export function useGetAllEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventsQuery, GetAllEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllEventsQuery, GetAllEventsQueryVariables>(
    GetAllEventsDocument,
    options,
  );
}
export type GetAllEventsQueryHookResult = ReturnType<typeof useGetAllEventsQuery>;
export type GetAllEventsLazyQueryHookResult = ReturnType<typeof useGetAllEventsLazyQuery>;
export type GetAllEventsQueryResult = Apollo.QueryResult<
  GetAllEventsQuery,
  GetAllEventsQueryVariables
>;
export const GetAllEventTypesDocument = gql`
  query GetAllEventTypes {
    allEventTypes {
      id
      name
      icon
      iconPublicUrl
      description
      events {
        id
        name
        companyMembers {
          id
          hiringDate
          userId
        }
        name
        description
        eventType {
          id
        }
        day
        removed
        updatedAt
        createdAt
      }
    }
  }
`;

/**
 * __useGetAllEventTypesQuery__
 *
 * To run a query within a React component, call `useGetAllEventTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEventTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllEventTypesQuery, GetAllEventTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllEventTypesQuery, GetAllEventTypesQueryVariables>(
    GetAllEventTypesDocument,
    options,
  );
}
export function useGetAllEventTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventTypesQuery, GetAllEventTypesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllEventTypesQuery, GetAllEventTypesQueryVariables>(
    GetAllEventTypesDocument,
    options,
  );
}
export type GetAllEventTypesQueryHookResult = ReturnType<typeof useGetAllEventTypesQuery>;
export type GetAllEventTypesLazyQueryHookResult = ReturnType<typeof useGetAllEventTypesLazyQuery>;
export type GetAllEventTypesQueryResult = Apollo.QueryResult<
  GetAllEventTypesQuery,
  GetAllEventTypesQueryVariables
>;
export const CurrentCompanyLastLeaderMessageDocument = gql`
  query CurrentCompanyLastLeaderMessage {
    currentCompanyLastLeaderMessage {
      id
      message
      member {
        id
        jobTitle
        user {
          id
          fullName
          photoPublicUrl
          photoUrl
          firstName
          lastName
        }
      }
    }
  }
`;

/**
 * __useCurrentCompanyLastLeaderMessageQuery__
 *
 * To run a query within a React component, call `useCurrentCompanyLastLeaderMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentCompanyLastLeaderMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentCompanyLastLeaderMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentCompanyLastLeaderMessageQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentCompanyLastLeaderMessageQuery,
    CurrentCompanyLastLeaderMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentCompanyLastLeaderMessageQuery,
    CurrentCompanyLastLeaderMessageQueryVariables
  >(CurrentCompanyLastLeaderMessageDocument, options);
}
export function useCurrentCompanyLastLeaderMessageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentCompanyLastLeaderMessageQuery,
    CurrentCompanyLastLeaderMessageQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentCompanyLastLeaderMessageQuery,
    CurrentCompanyLastLeaderMessageQueryVariables
  >(CurrentCompanyLastLeaderMessageDocument, options);
}
export type CurrentCompanyLastLeaderMessageQueryHookResult = ReturnType<
  typeof useCurrentCompanyLastLeaderMessageQuery
>;
export type CurrentCompanyLastLeaderMessageLazyQueryHookResult = ReturnType<
  typeof useCurrentCompanyLastLeaderMessageLazyQuery
>;
export type CurrentCompanyLastLeaderMessageQueryResult = Apollo.QueryResult<
  CurrentCompanyLastLeaderMessageQuery,
  CurrentCompanyLastLeaderMessageQueryVariables
>;
export const SeeThanksDocument = gql`
  query SeeThanks($id: ID!) {
    seeThanks(id: $id) {
      ...UserThankInfo
      companyMemberSender {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      companyMemberReceivers {
        id
        user {
          id
          fullName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
  ${UserThankInfoFragmentDoc}
`;

/**
 * __useSeeThanksQuery__
 *
 * To run a query within a React component, call `useSeeThanksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeeThanksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeeThanksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSeeThanksQuery(
  baseOptions: Apollo.QueryHookOptions<SeeThanksQuery, SeeThanksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SeeThanksQuery, SeeThanksQueryVariables>(SeeThanksDocument, options);
}
export function useSeeThanksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SeeThanksQuery, SeeThanksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SeeThanksQuery, SeeThanksQueryVariables>(SeeThanksDocument, options);
}
export type SeeThanksQueryHookResult = ReturnType<typeof useSeeThanksQuery>;
export type SeeThanksLazyQueryHookResult = ReturnType<typeof useSeeThanksLazyQuery>;
export type SeeThanksQueryResult = Apollo.QueryResult<SeeThanksQuery, SeeThanksQueryVariables>;
export const CurrentProfessionnalWeatherDocument = gql`
  query CurrentProfessionnalWeather {
    currentCompanyMember {
      id
      currentProfessionnalWeather {
        id
        title
        icon
      }
    }
  }
`;

/**
 * __useCurrentProfessionnalWeatherQuery__
 *
 * To run a query within a React component, call `useCurrentProfessionnalWeatherQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProfessionnalWeatherQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProfessionnalWeatherQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentProfessionnalWeatherQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentProfessionnalWeatherQuery,
    CurrentProfessionnalWeatherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentProfessionnalWeatherQuery,
    CurrentProfessionnalWeatherQueryVariables
  >(CurrentProfessionnalWeatherDocument, options);
}
export function useCurrentProfessionnalWeatherLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentProfessionnalWeatherQuery,
    CurrentProfessionnalWeatherQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentProfessionnalWeatherQuery,
    CurrentProfessionnalWeatherQueryVariables
  >(CurrentProfessionnalWeatherDocument, options);
}
export type CurrentProfessionnalWeatherQueryHookResult = ReturnType<
  typeof useCurrentProfessionnalWeatherQuery
>;
export type CurrentProfessionnalWeatherLazyQueryHookResult = ReturnType<
  typeof useCurrentProfessionnalWeatherLazyQuery
>;
export type CurrentProfessionnalWeatherQueryResult = Apollo.QueryResult<
  CurrentProfessionnalWeatherQuery,
  CurrentProfessionnalWeatherQueryVariables
>;
export const CurrentProfessionalStatusDocument = gql`
  query CurrentProfessionalStatus {
    currentCompanyMember {
      currentProfessionalStatus {
        title
        icon
        description
        order
      }
    }
  }
`;

/**
 * __useCurrentProfessionalStatusQuery__
 *
 * To run a query within a React component, call `useCurrentProfessionalStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentProfessionalStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentProfessionalStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentProfessionalStatusQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentProfessionalStatusQuery,
    CurrentProfessionalStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CurrentProfessionalStatusQuery, CurrentProfessionalStatusQueryVariables>(
    CurrentProfessionalStatusDocument,
    options,
  );
}
export function useCurrentProfessionalStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentProfessionalStatusQuery,
    CurrentProfessionalStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentProfessionalStatusQuery,
    CurrentProfessionalStatusQueryVariables
  >(CurrentProfessionalStatusDocument, options);
}
export type CurrentProfessionalStatusQueryHookResult = ReturnType<
  typeof useCurrentProfessionalStatusQuery
>;
export type CurrentProfessionalStatusLazyQueryHookResult = ReturnType<
  typeof useCurrentProfessionalStatusLazyQuery
>;
export type CurrentProfessionalStatusQueryResult = Apollo.QueryResult<
  CurrentProfessionalStatusQuery,
  CurrentProfessionalStatusQueryVariables
>;
export const NewIndividualBadgeDocument = gql`
  subscription NewIndividualBadge {
    newIndividualBadge {
      badgeId
      companyMemberId
      badge {
        id
        name
        iconPublicUrl
        customBadge {
          badgeId
          companyId
          minExp
        }
        nextBadge {
          id
          name
          customBadge {
            badgeId
            companyId
            minExp
          }
        }
      }
    }
  }
`;

/**
 * __useNewIndividualBadgeSubscription__
 *
 * To run a query within a React component, call `useNewIndividualBadgeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewIndividualBadgeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewIndividualBadgeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewIndividualBadgeSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewIndividualBadgeSubscription,
    NewIndividualBadgeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewIndividualBadgeSubscription,
    NewIndividualBadgeSubscriptionVariables
  >(NewIndividualBadgeDocument, options);
}
export type NewIndividualBadgeSubscriptionHookResult = ReturnType<
  typeof useNewIndividualBadgeSubscription
>;
export type NewIndividualBadgeSubscriptionResult =
  Apollo.SubscriptionResult<NewIndividualBadgeSubscription>;
export const NewCommunityBadgeDocument = gql`
  subscription NewCommunityBadge {
    newCommunityBadge {
      badgeId
      communityId
      community {
        id
        name
        logoPublicUrl
        exp
        isMeAMember
        coins
      }
      badge {
        id
        name
        iconPublicUrl
        customBadge {
          badgeId
          companyId
          minExp
        }
        nextBadge {
          id
          name
          customBadge {
            badgeId
            companyId
            minExp
          }
        }
      }
    }
  }
`;

/**
 * __useNewCommunityBadgeSubscription__
 *
 * To run a query within a React component, call `useNewCommunityBadgeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewCommunityBadgeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCommunityBadgeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewCommunityBadgeSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewCommunityBadgeSubscription,
    NewCommunityBadgeSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewCommunityBadgeSubscription,
    NewCommunityBadgeSubscriptionVariables
  >(NewCommunityBadgeDocument, options);
}
export type NewCommunityBadgeSubscriptionHookResult = ReturnType<
  typeof useNewCommunityBadgeSubscription
>;
export type NewCommunityBadgeSubscriptionResult =
  Apollo.SubscriptionResult<NewCommunityBadgeSubscription>;
export const GetBadgesByCommunityDocument = gql`
  query GetBadgesByCommunity($communityId: ID!) {
    badgesByCommunity(communityId: $communityId) {
      ...CommunityBadgeFragment
      badge {
        ...BadgeFragment
      }
    }
  }
  ${CommunityBadgeFragmentFragmentDoc}
  ${BadgeFragmentFragmentDoc}
`;

/**
 * __useGetBadgesByCommunityQuery__
 *
 * To run a query within a React component, call `useGetBadgesByCommunityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBadgesByCommunityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBadgesByCommunityQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *   },
 * });
 */
export function useGetBadgesByCommunityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBadgesByCommunityQuery,
    GetBadgesByCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBadgesByCommunityQuery, GetBadgesByCommunityQueryVariables>(
    GetBadgesByCommunityDocument,
    options,
  );
}
export function useGetBadgesByCommunityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBadgesByCommunityQuery,
    GetBadgesByCommunityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBadgesByCommunityQuery, GetBadgesByCommunityQueryVariables>(
    GetBadgesByCommunityDocument,
    options,
  );
}
export type GetBadgesByCommunityQueryHookResult = ReturnType<typeof useGetBadgesByCommunityQuery>;
export type GetBadgesByCommunityLazyQueryHookResult = ReturnType<
  typeof useGetBadgesByCommunityLazyQuery
>;
export type GetBadgesByCommunityQueryResult = Apollo.QueryResult<
  GetBadgesByCommunityQuery,
  GetBadgesByCommunityQueryVariables
>;
export const GetDailyDonesCommunityBadgesDocument = gql`
  query GetDailyDonesCommunityBadges {
    getDailyDonesCommunityBadges {
      ...CommunityBadgeFragment
      badge {
        ...BadgeFragment
      }
    }
  }
  ${CommunityBadgeFragmentFragmentDoc}
  ${BadgeFragmentFragmentDoc}
`;

/**
 * __useGetDailyDonesCommunityBadgesQuery__
 *
 * To run a query within a React component, call `useGetDailyDonesCommunityBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyDonesCommunityBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyDonesCommunityBadgesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDailyDonesCommunityBadgesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDailyDonesCommunityBadgesQuery,
    GetDailyDonesCommunityBadgesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDailyDonesCommunityBadgesQuery,
    GetDailyDonesCommunityBadgesQueryVariables
  >(GetDailyDonesCommunityBadgesDocument, options);
}
export function useGetDailyDonesCommunityBadgesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDailyDonesCommunityBadgesQuery,
    GetDailyDonesCommunityBadgesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDailyDonesCommunityBadgesQuery,
    GetDailyDonesCommunityBadgesQueryVariables
  >(GetDailyDonesCommunityBadgesDocument, options);
}
export type GetDailyDonesCommunityBadgesQueryHookResult = ReturnType<
  typeof useGetDailyDonesCommunityBadgesQuery
>;
export type GetDailyDonesCommunityBadgesLazyQueryHookResult = ReturnType<
  typeof useGetDailyDonesCommunityBadgesLazyQuery
>;
export type GetDailyDonesCommunityBadgesQueryResult = Apollo.QueryResult<
  GetDailyDonesCommunityBadgesQuery,
  GetDailyDonesCommunityBadgesQueryVariables
>;
export const GetBadgesByCompanyMemberDocument = gql`
  query GetBadgesByCompanyMember($companyMemberId: ID!) {
    getBadgesByCompanyMember(companyMemberId: $companyMemberId) {
      ...IndividualBadgeFragment
      badge {
        ...BadgeFragment
      }
    }
  }
  ${IndividualBadgeFragmentFragmentDoc}
  ${BadgeFragmentFragmentDoc}
`;

/**
 * __useGetBadgesByCompanyMemberQuery__
 *
 * To run a query within a React component, call `useGetBadgesByCompanyMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBadgesByCompanyMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBadgesByCompanyMemberQuery({
 *   variables: {
 *      companyMemberId: // value for 'companyMemberId'
 *   },
 * });
 */
export function useGetBadgesByCompanyMemberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBadgesByCompanyMemberQuery,
    GetBadgesByCompanyMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBadgesByCompanyMemberQuery, GetBadgesByCompanyMemberQueryVariables>(
    GetBadgesByCompanyMemberDocument,
    options,
  );
}
export function useGetBadgesByCompanyMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBadgesByCompanyMemberQuery,
    GetBadgesByCompanyMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBadgesByCompanyMemberQuery, GetBadgesByCompanyMemberQueryVariables>(
    GetBadgesByCompanyMemberDocument,
    options,
  );
}
export type GetBadgesByCompanyMemberQueryHookResult = ReturnType<
  typeof useGetBadgesByCompanyMemberQuery
>;
export type GetBadgesByCompanyMemberLazyQueryHookResult = ReturnType<
  typeof useGetBadgesByCompanyMemberLazyQuery
>;
export type GetBadgesByCompanyMemberQueryResult = Apollo.QueryResult<
  GetBadgesByCompanyMemberQuery,
  GetBadgesByCompanyMemberQueryVariables
>;
export const GetBadgesByCategoryDocument = gql`
  query GetBadgesByCategory($communityId: ID, $companyMemberId: ID) {
    getBadgesByCategory(communityId: $communityId, companyMemberId: $companyMemberId) {
      community {
        id
        name
        logoPublicUrl
        mission
        myCommunityRole
        exp
        coins
        expLevel {
          id
          name
          iconPublicUrl
        }
      }
      achivements {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      special {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      pointsCollection {
        badgeId
        badge {
          ...BadgeFragment
          customBadge {
            minExp
            maxExp
          }
        }
        status
      }
    }
  }
  ${BadgeFragmentFragmentDoc}
`;

/**
 * __useGetBadgesByCategoryQuery__
 *
 * To run a query within a React component, call `useGetBadgesByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBadgesByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBadgesByCategoryQuery({
 *   variables: {
 *      communityId: // value for 'communityId'
 *      companyMemberId: // value for 'companyMemberId'
 *   },
 * });
 */
export function useGetBadgesByCategoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBadgesByCategoryQuery,
    GetBadgesByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBadgesByCategoryQuery, GetBadgesByCategoryQueryVariables>(
    GetBadgesByCategoryDocument,
    options,
  );
}
export function useGetBadgesByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBadgesByCategoryQuery,
    GetBadgesByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBadgesByCategoryQuery, GetBadgesByCategoryQueryVariables>(
    GetBadgesByCategoryDocument,
    options,
  );
}
export type GetBadgesByCategoryQueryHookResult = ReturnType<typeof useGetBadgesByCategoryQuery>;
export type GetBadgesByCategoryLazyQueryHookResult = ReturnType<
  typeof useGetBadgesByCategoryLazyQuery
>;
export type GetBadgesByCategoryQueryResult = Apollo.QueryResult<
  GetBadgesByCategoryQuery,
  GetBadgesByCategoryQueryVariables
>;
export const GetDailyDonesBadgesByCategoryDocument = gql`
  query GetDailyDonesBadgesByCategory {
    getDailyDonesBadgesByCategory {
      achivements {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      special {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
      pointsCollection {
        badgeId
        badge {
          ...BadgeFragment
        }
        status
      }
    }
  }
  ${BadgeFragmentFragmentDoc}
`;

/**
 * __useGetDailyDonesBadgesByCategoryQuery__
 *
 * To run a query within a React component, call `useGetDailyDonesBadgesByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyDonesBadgesByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyDonesBadgesByCategoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDailyDonesBadgesByCategoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDailyDonesBadgesByCategoryQuery,
    GetDailyDonesBadgesByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDailyDonesBadgesByCategoryQuery,
    GetDailyDonesBadgesByCategoryQueryVariables
  >(GetDailyDonesBadgesByCategoryDocument, options);
}
export function useGetDailyDonesBadgesByCategoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDailyDonesBadgesByCategoryQuery,
    GetDailyDonesBadgesByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDailyDonesBadgesByCategoryQuery,
    GetDailyDonesBadgesByCategoryQueryVariables
  >(GetDailyDonesBadgesByCategoryDocument, options);
}
export type GetDailyDonesBadgesByCategoryQueryHookResult = ReturnType<
  typeof useGetDailyDonesBadgesByCategoryQuery
>;
export type GetDailyDonesBadgesByCategoryLazyQueryHookResult = ReturnType<
  typeof useGetDailyDonesBadgesByCategoryLazyQuery
>;
export type GetDailyDonesBadgesByCategoryQueryResult = Apollo.QueryResult<
  GetDailyDonesBadgesByCategoryQuery,
  GetDailyDonesBadgesByCategoryQueryVariables
>;
export const GetEarnedExpEventsDocument = gql`
  query GetEarnedExpEvents {
    earnedExpEvents {
      id
      expEvent {
        id
        exp
      }
      earnedAt
    }
  }
`;

/**
 * __useGetEarnedExpEventsQuery__
 *
 * To run a query within a React component, call `useGetEarnedExpEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEarnedExpEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEarnedExpEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEarnedExpEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetEarnedExpEventsQuery, GetEarnedExpEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEarnedExpEventsQuery, GetEarnedExpEventsQueryVariables>(
    GetEarnedExpEventsDocument,
    options,
  );
}
export function useGetEarnedExpEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEarnedExpEventsQuery,
    GetEarnedExpEventsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEarnedExpEventsQuery, GetEarnedExpEventsQueryVariables>(
    GetEarnedExpEventsDocument,
    options,
  );
}
export type GetEarnedExpEventsQueryHookResult = ReturnType<typeof useGetEarnedExpEventsQuery>;
export type GetEarnedExpEventsLazyQueryHookResult = ReturnType<
  typeof useGetEarnedExpEventsLazyQuery
>;
export type GetEarnedExpEventsQueryResult = Apollo.QueryResult<
  GetEarnedExpEventsQuery,
  GetEarnedExpEventsQueryVariables
>;
export const GetDailyExpDocument = gql`
  query GetDailyExp {
    dailyExp {
      value
      date
    }
  }
`;

/**
 * __useGetDailyExpQuery__
 *
 * To run a query within a React component, call `useGetDailyExpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDailyExpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDailyExpQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDailyExpQuery(
  baseOptions?: Apollo.QueryHookOptions<GetDailyExpQuery, GetDailyExpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDailyExpQuery, GetDailyExpQueryVariables>(GetDailyExpDocument, options);
}
export function useGetDailyExpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDailyExpQuery, GetDailyExpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetDailyExpQuery, GetDailyExpQueryVariables>(
    GetDailyExpDocument,
    options,
  );
}
export type GetDailyExpQueryHookResult = ReturnType<typeof useGetDailyExpQuery>;
export type GetDailyExpLazyQueryHookResult = ReturnType<typeof useGetDailyExpLazyQuery>;
export type GetDailyExpQueryResult = Apollo.QueryResult<
  GetDailyExpQuery,
  GetDailyExpQueryVariables
>;
export const GetTodaysEarnedExpDocument = gql`
  query GetTodaysEarnedExp {
    todaysEarnedExp {
      exp
      earnedExp {
        id
        description
        earnedAt
      }
    }
  }
`;

/**
 * __useGetTodaysEarnedExpQuery__
 *
 * To run a query within a React component, call `useGetTodaysEarnedExpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTodaysEarnedExpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTodaysEarnedExpQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTodaysEarnedExpQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTodaysEarnedExpQuery, GetTodaysEarnedExpQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTodaysEarnedExpQuery, GetTodaysEarnedExpQueryVariables>(
    GetTodaysEarnedExpDocument,
    options,
  );
}
export function useGetTodaysEarnedExpLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTodaysEarnedExpQuery,
    GetTodaysEarnedExpQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTodaysEarnedExpQuery, GetTodaysEarnedExpQueryVariables>(
    GetTodaysEarnedExpDocument,
    options,
  );
}
export type GetTodaysEarnedExpQueryHookResult = ReturnType<typeof useGetTodaysEarnedExpQuery>;
export type GetTodaysEarnedExpLazyQueryHookResult = ReturnType<
  typeof useGetTodaysEarnedExpLazyQuery
>;
export type GetTodaysEarnedExpQueryResult = Apollo.QueryResult<
  GetTodaysEarnedExpQuery,
  GetTodaysEarnedExpQueryVariables
>;
export const GetCustomBadgesDocument = gql`
  query GetCustomBadges {
    allCustomBadges {
      badgeId
      companyId
      minExp
      maxExp
      prevMinExp
      nextMaxExp
      badge {
        id
        name
        iconPublicUrl
        type
        minExp
        maxExp
      }
    }
  }
`;

/**
 * __useGetCustomBadgesQuery__
 *
 * To run a query within a React component, call `useGetCustomBadgesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomBadgesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomBadgesQuery({
 *   variables: {
 *   },
 * });
 */

export function useGetCustomBadgesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomBadgesQuery, GetCustomBadgesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomBadgesQuery, GetCustomBadgesQueryVariables>(
    GetCustomBadgesDocument,
    options,
  );
}
export function useGetCustomBadgesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomBadgesQuery, GetCustomBadgesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomBadgesQuery, GetCustomBadgesQueryVariables>(
    GetCustomBadgesDocument,
    options,
  );
}
export type GetCustomBadgesQueryHookResult = ReturnType<typeof useGetCustomBadgesQuery>;
export type GetCustomBadgesLazyQueryHookResult = ReturnType<typeof useGetCustomBadgesLazyQuery>;
export type GetCustomBadgesQueryResult = Apollo.QueryResult<
  GetCustomBadgesQuery,
  GetCustomBadgesQueryVariables
>;
export const GetAllExpEventsDocument = gql`
  query GetAllExpEvents(
    $page: Int
    $perPage: Int
    $sortOrder: String
    $sortField: String
    $filter: ExpEventFilter
  ) {
    expEvents: allExpEvents(
      page: $page
      perPage: $perPage
      sortOrder: $sortOrder
      sortField: $sortField
      filter: $filter
    ) {
      id
      exp
      communityExp
      genericExpEvent {
        id
        name
        description
        expEventType
      }
    }
    total: _allExpEventsMeta(filter: $filter) {
      count
    }
  }
`;

/**
 * __useGetAllExpEventsQuery__
 *
 * To run a query within a React component, call `useGetAllExpEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllExpEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllExpEventsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      perPage: // value for 'perPage'
 *      sortOrder: // value for 'sortOrder'
 *      sortField: // value for 'sortField'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetAllExpEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllExpEventsQuery, GetAllExpEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllExpEventsQuery, GetAllExpEventsQueryVariables>(
    GetAllExpEventsDocument,
    options,
  );
}
export function useGetAllExpEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllExpEventsQuery, GetAllExpEventsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllExpEventsQuery, GetAllExpEventsQueryVariables>(
    GetAllExpEventsDocument,
    options,
  );
}
export type GetAllExpEventsQueryHookResult = ReturnType<typeof useGetAllExpEventsQuery>;
export type GetAllExpEventsLazyQueryHookResult = ReturnType<typeof useGetAllExpEventsLazyQuery>;
export type GetAllExpEventsQueryResult = Apollo.QueryResult<
  GetAllExpEventsQuery,
  GetAllExpEventsQueryVariables
>;
export const GetExpEventDocument = gql`
  query GetExpEvent($id: ID!) {
    expEvent: ExpEvent(id: $id) {
      id
      exp
      communityExp
      genericExpEvent {
        id
        name
        description
        expEventType
      }
    }
  }
`;

/**
 * __useNewCommunityBadgeSubscription__
 *
 * To run a query within a React component, call `useNewCommunityBadgeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewCommunityBadgeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCommunityBadgeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetExpEventQuery(
  baseOptions: Apollo.QueryHookOptions<GetExpEventQuery, GetExpEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetExpEventQuery, GetExpEventQueryVariables>(GetExpEventDocument, options);
}
export function useGetExpEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetExpEventQuery, GetExpEventQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetExpEventQuery, GetExpEventQueryVariables>(
    GetExpEventDocument,
    options,
  );
}
export type GetExpEventQueryHookResult = ReturnType<typeof useGetExpEventQuery>;
export type GetExpEventLazyQueryHookResult = ReturnType<typeof useGetExpEventLazyQuery>;
export type GetExpEventQueryResult = Apollo.QueryResult<
  GetExpEventQuery,
  GetExpEventQueryVariables
>;
export const IncomingSemiInstantCallDocument = gql`
  subscription IncomingSemiInstantCall($id: ID) {
    incomingSemiInstantCall(guestId: $id) {
      id
      name
      amITheHost
      description
      jitsiMeetUrl
      createdAt
      createdBy {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
      guests {
        id
        user {
          id
          fullName
          photoPublicUrl
        }
      }
    }
  }
`;

/**
 * __useIncomingSemiInstantCallSubscription__
 *
 * To run a query within a React component, call `useIncomingSemiInstantCallSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIncomingSemiInstantCallSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomingSemiInstantCallSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIncomingSemiInstantCallSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    IncomingSemiInstantCallSubscription,
    IncomingSemiInstantCallSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    IncomingSemiInstantCallSubscription,
    IncomingSemiInstantCallSubscriptionVariables
  >(IncomingSemiInstantCallDocument, options);
}
export type IncomingSemiInstantCallSubscriptionHookResult = ReturnType<
  typeof useIncomingSemiInstantCallSubscription
>;
export type IncomingSemiInstantCallSubscriptionResult =
  Apollo.SubscriptionResult<IncomingSemiInstantCallSubscription>;
export const NewEarnedCoinDocument = gql`
  subscription NewEarnedCoin {
    newEarnedCoin {
      id
      companyMemberId
      type
      description
      value
      isSeen
      createdAt
    }
  }
`;

/**
 * __useNewEarnedCoinSubscription__
 *
 * To run a query within a React component, call `useNewEarnedCoinSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewEarnedCoinSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewEarnedCoinSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewEarnedCoinSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewEarnedCoinSubscription,
    NewEarnedCoinSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<NewEarnedCoinSubscription, NewEarnedCoinSubscriptionVariables>(
    NewEarnedCoinDocument,
    options,
  );
}
export type NewEarnedCoinSubscriptionHookResult = ReturnType<typeof useNewEarnedCoinSubscription>;
export type NewEarnedCoinSubscriptionResult = Apollo.SubscriptionResult<NewEarnedCoinSubscription>;
export const NewTeamEarnedCoinDocument = gql`
  subscription NewTeamEarnedCoin {
    newTeamEarnedCoin {
      id
      communityId
      type
      description
      value
      isSeen
      createdAt
    }
  }
`;

/**
 * __useNewTeamEarnedCoinSubscription__
 *
 * To run a query within a React component, call `useNewTeamEarnedCoinSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewTeamEarnedCoinSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewTeamEarnedCoinSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewTeamEarnedCoinSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewTeamEarnedCoinSubscription,
    NewTeamEarnedCoinSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewTeamEarnedCoinSubscription,
    NewTeamEarnedCoinSubscriptionVariables
  >(NewTeamEarnedCoinDocument, options);
}
export type NewTeamEarnedCoinSubscriptionHookResult = ReturnType<
  typeof useNewTeamEarnedCoinSubscription
>;
export type NewTeamEarnedCoinSubscriptionResult =
  Apollo.SubscriptionResult<NewTeamEarnedCoinSubscription>;
export const NewCommunityEarnedExpEventDocument = gql`
  subscription NewCommunityEarnedExpEvent {
    newCommunityEarnedExpEvent {
      id
      earnedByTeam {
        id
        exp
        coins
        expLevel {
          id
          name
          iconPublicUrl
        }
      }
    }
  }
`;

/**
 * __useNewCommunityEarnedExpEventSubscription__
 *
 * To run a query within a React component, call `useNewCommunityEarnedExpEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewCommunityEarnedExpEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCommunityEarnedExpEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewCommunityEarnedExpEventSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewCommunityEarnedExpEventSubscription,
    NewCommunityEarnedExpEventSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewCommunityEarnedExpEventSubscription,
    NewCommunityEarnedExpEventSubscriptionVariables
  >(NewCommunityEarnedExpEventDocument, options);
}
export type NewCommunityEarnedExpEventSubscriptionHookResult = ReturnType<
  typeof useNewCommunityEarnedExpEventSubscription
>;
export type NewCommunityEarnedExpEventSubscriptionResult =
  Apollo.SubscriptionResult<NewCommunityEarnedExpEventSubscription>;
export const NewCommunityExpLevelDocument = gql`
  subscription NewCommunityExpLevel {
    newCommunityExpLevel {
      earnedByTeam {
        id
        name
        logoPublicUrl
        exp
        isMeAMember
        coins
      }
      newExpLevel {
        id
        name
        iconPublicUrl
        customBadge {
          badgeId
          companyId
          minExp
        }
        nextBadge {
          id
          name
          customBadge {
            badgeId
            companyId
            minExp
          }
        }
      }
    }
  }
`;

/**
 * __useNewCommunityExpLevelSubscription__
 *
 * To run a query within a React component, call `useNewCommunityExpLevelSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewCommunityExpLevelSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewCommunityExpLevelSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewCommunityExpLevelSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewCommunityExpLevelSubscription,
    NewCommunityExpLevelSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewCommunityExpLevelSubscription,
    NewCommunityExpLevelSubscriptionVariables
  >(NewCommunityExpLevelDocument, options);
}
export type NewCommunityExpLevelSubscriptionHookResult = ReturnType<
  typeof useNewCommunityExpLevelSubscription
>;
export type NewCommunityExpLevelSubscriptionResult =
  Apollo.SubscriptionResult<NewCommunityExpLevelSubscription>;
export const NewEarnedExpEventDocument = gql`
  subscription NewEarnedExpEvent {
    newEarnedExpEvent {
      id
      earnedBy {
        id
        exp
      }
      earnedByTeam {
        id
        exp
        coins
        expLevel {
          id
          name
          iconPublicUrl
        }
      }
      expEvent {
        id
        communityExp
      }
    }
  }
`;

/**
 * __useNewEarnedExpEventSubscription__
 *
 * To run a query within a React component, call `useNewEarnedExpEventSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewEarnedExpEventSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewEarnedExpEventSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewEarnedExpEventSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewEarnedExpEventSubscription,
    NewEarnedExpEventSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewEarnedExpEventSubscription,
    NewEarnedExpEventSubscriptionVariables
  >(NewEarnedExpEventDocument, options);
}
export type NewEarnedExpEventSubscriptionHookResult = ReturnType<
  typeof useNewEarnedExpEventSubscription
>;
export type NewEarnedExpEventSubscriptionResult =
  Apollo.SubscriptionResult<NewEarnedExpEventSubscription>;
export const NewExpLevelDocument = gql`
  subscription NewExpLevel {
    newExpLevel {
      id
      name
      iconPublicUrl
      customBadge {
        badgeId
        companyId
        minExp
      }
      nextBadge {
        id
        name
        customBadge {
          badgeId
          companyId
          minExp
        }
      }
    }
  }
`;

/**
 * __useNewExpLevelSubscription__
 *
 * To run a query within a React component, call `useNewExpLevelSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewExpLevelSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewExpLevelSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewExpLevelSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewExpLevelSubscription,
    NewExpLevelSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<NewExpLevelSubscription, NewExpLevelSubscriptionVariables>(
    NewExpLevelDocument,
    options,
  );
}
export type NewExpLevelSubscriptionHookResult = ReturnType<typeof useNewExpLevelSubscription>;
export type NewExpLevelSubscriptionResult = Apollo.SubscriptionResult<NewExpLevelSubscription>;
export const NotificationCreatedDocument = gql`
  subscription NotificationCreated($id: ID) {
    notificationCreated(companyMemberReceiverId: $id) {
      ...NotificationInfo
      companyMemberSender {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
  ${NotificationInfoFragmentDoc}
`;

/**
 * __useNotificationCreatedSubscription__
 *
 * To run a query within a React component, call `useNotificationCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationCreatedSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNotificationCreatedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NotificationCreatedSubscription,
    NotificationCreatedSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NotificationCreatedSubscription,
    NotificationCreatedSubscriptionVariables
  >(NotificationCreatedDocument, options);
}
export type NotificationCreatedSubscriptionHookResult = ReturnType<
  typeof useNotificationCreatedSubscription
>;
export type NotificationCreatedSubscriptionResult =
  Apollo.SubscriptionResult<NotificationCreatedSubscription>;
export const SentFeedbackDocument = gql`
  subscription SentFeedback($id: ID) {
    sentFeedback(companyMemberId: $id) {
      ...FeedbackInfo
      companyMemberSender {
        id
        user {
          id
          fullName
          photoUrl
          photoPublicUrl
        }
      }
      companyMemberReceiver {
        id
        user {
          id
          fullName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
  ${FeedbackInfoFragmentDoc}
`;

/**
 * __useSentFeedbackSubscription__
 *
 * To run a query within a React component, call `useSentFeedbackSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSentFeedbackSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSentFeedbackSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSentFeedbackSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    SentFeedbackSubscription,
    SentFeedbackSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<SentFeedbackSubscription, SentFeedbackSubscriptionVariables>(
    SentFeedbackDocument,
    options,
  );
}
export type SentFeedbackSubscriptionHookResult = ReturnType<typeof useSentFeedbackSubscription>;
export type SentFeedbackSubscriptionResult = Apollo.SubscriptionResult<SentFeedbackSubscription>;
export const IsUserOnlineDocument = gql`
  subscription IsUserOnline($id: ID!) {
    isUserOnline: isCompanyMemberOnline(userId: $id)
  }
`;

/**
 * __useIsUserOnlineSubscription__
 *
 * To run a query within a React component, call `useIsUserOnlineSubscription` and pass it any options that fit your needs.
 * When your component renders, `useIsUserOnlineSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserOnlineSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIsUserOnlineSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IsUserOnlineSubscription,
    IsUserOnlineSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<IsUserOnlineSubscription, IsUserOnlineSubscriptionVariables>(
    IsUserOnlineDocument,
    options,
  );
}
export type IsUserOnlineSubscriptionHookResult = ReturnType<typeof useIsUserOnlineSubscription>;
export type IsUserOnlineSubscriptionResult = Apollo.SubscriptionResult<IsUserOnlineSubscription>;
export const CreateUserPreferenceDocument = gql`
  mutation CreateUserPreference($userTimezone: UserTimezoneInput!) {
    createUserPreference(input: { userTimezone: $userTimezone }) {
      id
      companyMember {
        id
      }
      userTimezone {
        id
      }
    }
  }
`;
export type CreateUserPreferenceMutationFn = Apollo.MutationFunction<
  CreateUserPreferenceMutation,
  CreateUserPreferenceMutationVariables
>;

/**
 * __useCreateUserPreferenceMutation__
 *
 * To run a mutation, you first call `useCreateUserPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPreferenceMutation, { data, loading, error }] = useCreateUserPreferenceMutation({
 *   variables: {
 *      userTimezone: // value for 'userTimezone'
 *   },
 * });
 */
export function useCreateUserPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserPreferenceMutation,
    CreateUserPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateUserPreferenceMutation, CreateUserPreferenceMutationVariables>(
    CreateUserPreferenceDocument,
    options,
  );
}
export type CreateUserPreferenceMutationHookResult = ReturnType<
  typeof useCreateUserPreferenceMutation
>;
export type CreateUserPreferenceMutationResult =
  Apollo.MutationResult<CreateUserPreferenceMutation>;
export type CreateUserPreferenceMutationOptions = Apollo.BaseMutationOptions<
  CreateUserPreferenceMutation,
  CreateUserPreferenceMutationVariables
>;
export const UpdateUserPreferenceDocument = gql`
  mutation UpdateUserPreference($userTimezone: UserTimezoneInput, $coach: CoachInput) {
    updateUserPreference(input: { userTimezone: $userTimezone, coach: $coach }) {
      id
      coach {
        enable
      }
      companyMember {
        id
      }
      userTimezone {
        id
        label
        name
        tzCode
        utc
      }
      showEntryTunnelTime
      showExitTunnelTime
    }
  }
`;
export type UpdateUserPreferenceMutationFn = Apollo.MutationFunction<
  UpdateUserPreferenceMutation,
  UpdateUserPreferenceMutationVariables
>;

/**
 * __useUpdateUserPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateUserPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPreferenceMutation, { data, loading, error }] = useUpdateUserPreferenceMutation({
 *   variables: {
 *      userTimezone: // value for 'userTimezone'
 *      coach: // value for 'coach'
 *   },
 * });
 */
export function useUpdateUserPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPreferenceMutation,
    UpdateUserPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserPreferenceMutation, UpdateUserPreferenceMutationVariables>(
    UpdateUserPreferenceDocument,
    options,
  );
}
export type UpdateUserPreferenceMutationHookResult = ReturnType<
  typeof useUpdateUserPreferenceMutation
>;
export type UpdateUserPreferenceMutationResult =
  Apollo.MutationResult<UpdateUserPreferenceMutation>;
export type UpdateUserPreferenceMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPreferenceMutation,
  UpdateUserPreferenceMutationVariables
>;
export const SetShowEntryTunnelTodayDocument = gql`
  mutation SetShowEntryTunnelToday($value: Boolean!) {
    setShowEntryTunnelToday(value: $value) {
      id
      isEntryTunnelShownToday
    }
  }
`;
export type SetShowEntryTunnelTodayMutationFn = Apollo.MutationFunction<
  SetShowEntryTunnelTodayMutation,
  SetShowEntryTunnelTodayMutationVariables
>;

/**
 * __useSetShowEntryTunnelTodayMutation__
 *
 * To run a mutation, you first call `useSetShowEntryTunnelTodayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShowEntryTunnelTodayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShowEntryTunnelTodayMutation, { data, loading, error }] = useSetShowEntryTunnelTodayMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetShowEntryTunnelTodayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetShowEntryTunnelTodayMutation,
    SetShowEntryTunnelTodayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetShowEntryTunnelTodayMutation,
    SetShowEntryTunnelTodayMutationVariables
  >(SetShowEntryTunnelTodayDocument, options);
}
export type SetShowEntryTunnelTodayMutationHookResult = ReturnType<
  typeof useSetShowEntryTunnelTodayMutation
>;
export type SetShowEntryTunnelTodayMutationResult =
  Apollo.MutationResult<SetShowEntryTunnelTodayMutation>;
export type SetShowEntryTunnelTodayMutationOptions = Apollo.BaseMutationOptions<
  SetShowEntryTunnelTodayMutation,
  SetShowEntryTunnelTodayMutationVariables
>;
export const SetShowExitTunnelTodayDocument = gql`
  mutation SetShowExitTunnelToday($value: Boolean!) {
    setShowExitTunnelToday(value: $value) {
      id
      isExitTunnelShownToday
    }
  }
`;
export type SetShowExitTunnelTodayMutationFn = Apollo.MutationFunction<
  SetShowExitTunnelTodayMutation,
  SetShowExitTunnelTodayMutationVariables
>;

/**
 * __useSetShowExitTunnelTodayMutation__
 *
 * To run a mutation, you first call `useSetShowExitTunnelTodayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShowExitTunnelTodayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShowExitTunnelTodayMutation, { data, loading, error }] = useSetShowExitTunnelTodayMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetShowExitTunnelTodayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetShowExitTunnelTodayMutation,
    SetShowExitTunnelTodayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetShowExitTunnelTodayMutation,
    SetShowExitTunnelTodayMutationVariables
  >(SetShowExitTunnelTodayDocument, options);
}
export type SetShowExitTunnelTodayMutationHookResult = ReturnType<
  typeof useSetShowExitTunnelTodayMutation
>;
export type SetShowExitTunnelTodayMutationResult =
  Apollo.MutationResult<SetShowExitTunnelTodayMutation>;
export type SetShowExitTunnelTodayMutationOptions = Apollo.BaseMutationOptions<
  SetShowExitTunnelTodayMutation,
  SetShowExitTunnelTodayMutationVariables
>;
export const SetShowEntryTunnelPragmaTodayDocument = gql`
  mutation SetShowEntryTunnelPragmaToday($value: Boolean!, $incrementDay: Boolean) {
    setShowEntryTunnelPragmaToday(value: $value, incrementDay: $incrementDay) {
      id
      isEntryTunnelShownPragmaToday
    }
  }
`;
export type SetShowEntryTunnelPragmaTodayMutationFn = Apollo.MutationFunction<
  SetShowEntryTunnelPragmaTodayMutation,
  SetShowEntryTunnelPragmaTodayMutationVariables
>;

/**
 * __useSetShowEntryTunnelPragmaTodayMutation__
 *
 * To run a mutation, you first call `useSetShowEntryTunnelPragmaTodayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShowEntryTunnelPragmaTodayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShowEntryTunnelPragmaTodayMutation, { data, loading, error }] = useSetShowEntryTunnelPragmaTodayMutation({
 *   variables: {
 *      value: // value for 'value'
 *      incrementDay: // value for 'incrementDay'
 *   },
 * });
 */
export function useSetShowEntryTunnelPragmaTodayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetShowEntryTunnelPragmaTodayMutation,
    SetShowEntryTunnelPragmaTodayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetShowEntryTunnelPragmaTodayMutation,
    SetShowEntryTunnelPragmaTodayMutationVariables
  >(SetShowEntryTunnelPragmaTodayDocument, options);
}
export type SetShowEntryTunnelPragmaTodayMutationHookResult = ReturnType<
  typeof useSetShowEntryTunnelPragmaTodayMutation
>;
export type SetShowEntryTunnelPragmaTodayMutationResult =
  Apollo.MutationResult<SetShowEntryTunnelPragmaTodayMutation>;
export type SetShowEntryTunnelPragmaTodayMutationOptions = Apollo.BaseMutationOptions<
  SetShowEntryTunnelPragmaTodayMutation,
  SetShowEntryTunnelPragmaTodayMutationVariables
>;
export const SetShowExitTunnelPragmaTodayDocument = gql`
  mutation SetShowExitTunnelPragmaToday($value: Boolean!, $incrementDay: Boolean) {
    setShowExitTunnelPragmaToday(value: $value, incrementDay: $incrementDay) {
      id
      isExitTunnelShownPragmaToday
    }
  }
`;
export type SetShowExitTunnelPragmaTodayMutationFn = Apollo.MutationFunction<
  SetShowExitTunnelPragmaTodayMutation,
  SetShowExitTunnelPragmaTodayMutationVariables
>;

/**
 * __useSetShowExitTunnelPragmaTodayMutation__
 *
 * To run a mutation, you first call `useSetShowExitTunnelPragmaTodayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetShowExitTunnelPragmaTodayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setShowExitTunnelPragmaTodayMutation, { data, loading, error }] = useSetShowExitTunnelPragmaTodayMutation({
 *   variables: {
 *      value: // value for 'value'
 *      incrementDay: // value for 'incrementDay'
 *   },
 * });
 */
export function useSetShowExitTunnelPragmaTodayMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetShowExitTunnelPragmaTodayMutation,
    SetShowExitTunnelPragmaTodayMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetShowExitTunnelPragmaTodayMutation,
    SetShowExitTunnelPragmaTodayMutationVariables
  >(SetShowExitTunnelPragmaTodayDocument, options);
}
export type SetShowExitTunnelPragmaTodayMutationHookResult = ReturnType<
  typeof useSetShowExitTunnelPragmaTodayMutation
>;
export type SetShowExitTunnelPragmaTodayMutationResult =
  Apollo.MutationResult<SetShowExitTunnelPragmaTodayMutation>;
export type SetShowExitTunnelPragmaTodayMutationOptions = Apollo.BaseMutationOptions<
  SetShowExitTunnelPragmaTodayMutation,
  SetShowExitTunnelPragmaTodayMutationVariables
>;
export const UpdateCoachPreferenceDocument = gql`
  mutation UpdateCoachPreference($input: CoachInput!) {
    updateCoachPreference(input: $input) {
      enable
    }
  }
`;
export type UpdateCoachPreferenceMutationFn = Apollo.MutationFunction<
  UpdateCoachPreferenceMutation,
  UpdateCoachPreferenceMutationVariables
>;

/**
 * __useUpdateCoachPreferenceMutation__
 *
 * To run a mutation, you first call `useUpdateCoachPreferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCoachPreferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCoachPreferenceMutation, { data, loading, error }] = useUpdateCoachPreferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCoachPreferenceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCoachPreferenceMutation,
    UpdateCoachPreferenceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCoachPreferenceMutation, UpdateCoachPreferenceMutationVariables>(
    UpdateCoachPreferenceDocument,
    options,
  );
}
export type UpdateCoachPreferenceMutationHookResult = ReturnType<
  typeof useUpdateCoachPreferenceMutation
>;
export type UpdateCoachPreferenceMutationResult =
  Apollo.MutationResult<UpdateCoachPreferenceMutation>;
export type UpdateCoachPreferenceMutationOptions = Apollo.BaseMutationOptions<
  UpdateCoachPreferenceMutation,
  UpdateCoachPreferenceMutationVariables
>;
export const SetEnableContextualNotificationValueDocument = gql`
  mutation SetEnableContextualNotificationValue($value: Boolean!) {
    setEnableContextualNotificationValue(value: $value) {
      id
      enableContextualNotification
    }
  }
`;
export type SetEnableContextualNotificationValueMutationFn = Apollo.MutationFunction<
  SetEnableContextualNotificationValueMutation,
  SetEnableContextualNotificationValueMutationVariables
>;

/**
 * __useSetEnableContextualNotificationValueMutation__
 *
 * To run a mutation, you first call `useSetEnableContextualNotificationValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEnableContextualNotificationValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEnableContextualNotificationValueMutation, { data, loading, error }] = useSetEnableContextualNotificationValueMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetEnableContextualNotificationValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEnableContextualNotificationValueMutation,
    SetEnableContextualNotificationValueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetEnableContextualNotificationValueMutation,
    SetEnableContextualNotificationValueMutationVariables
  >(SetEnableContextualNotificationValueDocument, options);
}
export type SetEnableContextualNotificationValueMutationHookResult = ReturnType<
  typeof useSetEnableContextualNotificationValueMutation
>;
export type SetEnableContextualNotificationValueMutationResult =
  Apollo.MutationResult<SetEnableContextualNotificationValueMutation>;
export type SetEnableContextualNotificationValueMutationOptions = Apollo.BaseMutationOptions<
  SetEnableContextualNotificationValueMutation,
  SetEnableContextualNotificationValueMutationVariables
>;
export const SetEnableNavigateurNotificationValueDocument = gql`
  mutation SetEnableNavigateurNotificationValue($value: Boolean!) {
    setEnableNavigateurNotificationValue(value: $value) {
      id
      enableNavigateurNotification
    }
  }
`;
export type SetEnableNavigateurNotificationValueMutationFn = Apollo.MutationFunction<
  SetEnableNavigateurNotificationValueMutation,
  SetEnableNavigateurNotificationValueMutationVariables
>;

/**
 * __useSetEnableNavigateurNotificationValueMutation__
 *
 * To run a mutation, you first call `useSetEnableNavigateurNotificationValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetEnableNavigateurNotificationValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setEnableNavigateurNotificationValueMutation, { data, loading, error }] = useSetEnableNavigateurNotificationValueMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useSetEnableNavigateurNotificationValueMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetEnableNavigateurNotificationValueMutation,
    SetEnableNavigateurNotificationValueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetEnableNavigateurNotificationValueMutation,
    SetEnableNavigateurNotificationValueMutationVariables
  >(SetEnableNavigateurNotificationValueDocument, options);
}
export type SetEnableNavigateurNotificationValueMutationHookResult = ReturnType<
  typeof useSetEnableNavigateurNotificationValueMutation
>;
export type SetEnableNavigateurNotificationValueMutationResult =
  Apollo.MutationResult<SetEnableNavigateurNotificationValueMutation>;
export type SetEnableNavigateurNotificationValueMutationOptions = Apollo.BaseMutationOptions<
  SetEnableNavigateurNotificationValueMutation,
  SetEnableNavigateurNotificationValueMutationVariables
>;
export const GetMyCurrentTimezoneDocument = gql`
  query GetMyCurrentTimezone {
    userTimezone: getMyCurrentTimezone {
      id
      label
      name
      tzCode
      utc
    }
  }
`;

/**
 * __useGetMyCurrentTimezoneQuery__
 *
 * To run a query within a React component, call `useGetMyCurrentTimezoneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCurrentTimezoneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCurrentTimezoneQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCurrentTimezoneQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyCurrentTimezoneQuery,
    GetMyCurrentTimezoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyCurrentTimezoneQuery, GetMyCurrentTimezoneQueryVariables>(
    GetMyCurrentTimezoneDocument,
    options,
  );
}
export function useGetMyCurrentTimezoneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyCurrentTimezoneQuery,
    GetMyCurrentTimezoneQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyCurrentTimezoneQuery, GetMyCurrentTimezoneQueryVariables>(
    GetMyCurrentTimezoneDocument,
    options,
  );
}
export type GetMyCurrentTimezoneQueryHookResult = ReturnType<typeof useGetMyCurrentTimezoneQuery>;
export type GetMyCurrentTimezoneLazyQueryHookResult = ReturnType<
  typeof useGetMyCurrentTimezoneLazyQuery
>;
export type GetMyCurrentTimezoneQueryResult = Apollo.QueryResult<
  GetMyCurrentTimezoneQuery,
  GetMyCurrentTimezoneQueryVariables
>;
export const GetMyPreferencesDocument = gql`
  query GetMyPreferences {
    userPreference: getMyPreferences {
      id
      companyMember {
        id
        company {
          id
          slug
        }
      }
      userTimezone {
        id
        label
        name
        tzCode
        utc
      }
      coach {
        enable
      }
      enableShowEntryTunnel
      enableShowExitTunnel
      showEntryTunnelTime
      showExitTunnelTime
      isEntryTunnelShownToday
      isEntryTunnelShownPragmaToday
      isExitTunnelShownToday
      isExitTunnelShownPragmaToday
      professionalStatusActiveHoursTimeEnd
      professionalStatusActiveHoursTimeStart
      enableProfessionalStatusActiveHours
      enableNavigateurNotification
      enableContextualNotification
    }
  }
`;

/**
 * __useGetMyPreferencesQuery__
 *
 * To run a query within a React component, call `useGetMyPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyPreferencesQuery, GetMyPreferencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyPreferencesQuery, GetMyPreferencesQueryVariables>(
    GetMyPreferencesDocument,
    options,
  );
}
export function useGetMyPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyPreferencesQuery, GetMyPreferencesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyPreferencesQuery, GetMyPreferencesQueryVariables>(
    GetMyPreferencesDocument,
    options,
  );
}
export type GetMyPreferencesQueryHookResult = ReturnType<typeof useGetMyPreferencesQuery>;
export type GetMyPreferencesLazyQueryHookResult = ReturnType<typeof useGetMyPreferencesLazyQuery>;
export type GetMyPreferencesQueryResult = Apollo.QueryResult<
  GetMyPreferencesQuery,
  GetMyPreferencesQueryVariables
>;
export const UpdateMottoDocument = gql`
  mutation UpdateMotto($updateMottoId: ID!, $content: String) {
    updateMotto(id: $updateMottoId, content: $content) {
      id
      content
    }
  }
`;
export type UpdateMottoMutationFn = Apollo.MutationFunction<
  UpdateMottoMutation,
  UpdateMottoMutationVariables
>;

/**
 * __useUpdateMottoMutation__
 *
 * To run a mutation, you first call `useUpdateMottoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMottoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMottoMutation, { data, loading, error }] = useUpdateMottoMutation({
 *   variables: {
 *      updateMottoId: // value for 'updateMottoId'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateMottoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMottoMutation, UpdateMottoMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMottoMutation, UpdateMottoMutationVariables>(
    UpdateMottoDocument,
    options,
  );
}
export type UpdateMottoMutationHookResult = ReturnType<typeof useUpdateMottoMutation>;
export type UpdateMottoMutationResult = Apollo.MutationResult<UpdateMottoMutation>;
export type UpdateMottoMutationOptions = Apollo.BaseMutationOptions<
  UpdateMottoMutation,
  UpdateMottoMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser(
    $id: ID!
    $lastname: String
    $email: String
    $firstname: String
    $bio: String
    $photoUrl: String
    $birthday: DateTime
    $familySituation: String
    $personality: UserPersonality
    $mySharedHorizon: String
    $skills: [ID!]
    $successes: [ID!]
    $passions: [ID!]
    $previousCompanies: [ID!]
    $presentationVideoId: ID
    $role: MainRole
    $suspended: Boolean
    $adress: String
    $phone: String
  ) {
    updateUser(
      input: {
        id: $id
        email: $email
        firstname: $firstname
        lastname: $lastname
        bio: $bio
        photoUrl: $photoUrl
        birthday: $birthday
        familySituation: $familySituation
        personality: $personality
        mySharedHorizon: $mySharedHorizon
        skills: $skills
        successes: $successes
        passions: $passions
        previousCompanies: $previousCompanies
        presentationVideoId: $presentationVideoId
        suspended: $suspended
        role: $role
        adress: $adress
        phone: $phone
      }
    ) {
      id
      bio
      email
      firstName
      lastName
      photoUrl
      photoPublicUrl
      birthday
      familySituation
      personality
      mySharedHorizon
      is2FA
      skills {
        id
      }
      successes {
        id
      }
      passions {
        id
      }
      presentationVideo {
        id
        publicUrl
      }
    }
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      lastname: // value for 'lastname'
 *      email: // value for 'email'
 *      firstname: // value for 'firstname'
 *      bio: // value for 'bio'
 *      photoUrl: // value for 'photoUrl'
 *      birthday: // value for 'birthday'
 *      familySituation: // value for 'familySituation'
 *      personality: // value for 'personality'
 *      mySharedHorizon: // value for 'mySharedHorizon'
 *      skills: // value for 'skills'
 *      successes: // value for 'successes'
 *      passions: // value for 'passions'
 *      previousCompanies: // value for 'previousCompanies'
 *      presentationVideoId: // value for 'presentationVideoId'
 *      role: // value for 'role'
 *      suspended: // value for 'suspended'
 *      adress: // value for 'adress'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const DeleteUserPresentationVideoDocument = gql`
  mutation deleteUserPresentationVideo($id: ID) {
    deleteUserPresentationVideo(id: $id) {
      id
      presentationVideo {
        id
      }
    }
  }
`;
export type DeleteUserPresentationVideoMutationFn = Apollo.MutationFunction<
  DeleteUserPresentationVideoMutation,
  DeleteUserPresentationVideoMutationVariables
>;

/**
 * __useDeleteUserPresentationVideoMutation__
 *
 * To run a mutation, you first call `useDeleteUserPresentationVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserPresentationVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserPresentationVideoMutation, { data, loading, error }] = useDeleteUserPresentationVideoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserPresentationVideoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserPresentationVideoMutation,
    DeleteUserPresentationVideoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserPresentationVideoMutation,
    DeleteUserPresentationVideoMutationVariables
  >(DeleteUserPresentationVideoDocument, options);
}
export type DeleteUserPresentationVideoMutationHookResult = ReturnType<
  typeof useDeleteUserPresentationVideoMutation
>;
export type DeleteUserPresentationVideoMutationResult =
  Apollo.MutationResult<DeleteUserPresentationVideoMutation>;
export type DeleteUserPresentationVideoMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserPresentationVideoMutation,
  DeleteUserPresentationVideoMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
      removed
      firstName
      lastName
    }
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options,
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const GetMyCompaniesDocument = gql`
  query GetMyCompanies {
    me {
      id
      companyMembers {
        id
        suspended
        company {
          id
          slug
          logo
          logoPublicUrl
        }
      }
    }
  }
`;

/**
 * __useGetMyCompaniesQuery__
 *
 * To run a query within a React component, call `useGetMyCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCompaniesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyCompaniesQuery, GetMyCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyCompaniesQuery, GetMyCompaniesQueryVariables>(
    GetMyCompaniesDocument,
    options,
  );
}
export function useGetMyCompaniesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyCompaniesQuery, GetMyCompaniesQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyCompaniesQuery, GetMyCompaniesQueryVariables>(
    GetMyCompaniesDocument,
    options,
  );
}
export type GetMyCompaniesQueryHookResult = ReturnType<typeof useGetMyCompaniesQuery>;
export type GetMyCompaniesLazyQueryHookResult = ReturnType<typeof useGetMyCompaniesLazyQuery>;
export type GetMyCompaniesQueryResult = Apollo.QueryResult<
  GetMyCompaniesQuery,
  GetMyCompaniesQueryVariables
>;
export const GetCoworkersDocument = gql`
  query GetCoworkers {
    coworkers {
      id
      fullName
      firstName
      lastName
      photoUrl
      photoPublicUrl
      birthday
      companyMembers {
        currentMotto {
          id
          content
        }
        currentProfessionnalWeather {
          id
          icon
          title
        }
        id
        company {
          id
          name
        }
      }
      communities {
        id
        community {
          id
          name
          slug
          logo
          logoPublicUrl
        }
      }
    }
  }
`;

/**
 * __useGetCoworkersQuery__
 *
 * To run a query within a React component, call `useGetCoworkersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCoworkersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCoworkersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCoworkersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCoworkersQuery, GetCoworkersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCoworkersQuery, GetCoworkersQueryVariables>(
    GetCoworkersDocument,
    options,
  );
}
export function useGetCoworkersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCoworkersQuery, GetCoworkersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCoworkersQuery, GetCoworkersQueryVariables>(
    GetCoworkersDocument,
    options,
  );
}
export type GetCoworkersQueryHookResult = ReturnType<typeof useGetCoworkersQuery>;
export type GetCoworkersLazyQueryHookResult = ReturnType<typeof useGetCoworkersLazyQuery>;
export type GetCoworkersQueryResult = Apollo.QueryResult<
  GetCoworkersQuery,
  GetCoworkersQueryVariables
>;
export const GetMissionsActionsByCompanyMemberDocument = gql`
  query GetMissionsActionsByCompanyMember($id: ID!) {
    companyMember: getCompanyMemberById(id: $id) {
      id
      user {
        id
        fullName
        photoUrl
        photoPublicUrl
        firstName
        lastName
        myCommunity {
          id
        }
      }
      myOneToOneActions {
        ...ActionInfo
        responsibles {
          id
          user {
            id
            fullName
            photoUrl
            photoPublicUrl
            firstName
            lastName
          }
        }
        roadBlock {
          id
          roadBlockId
        }
      }
      myOneToOneMissions {
        ...MissionInfo
        community {
          id
          logo
          logoPublicUrl
          name
        }
        experts {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        companyMember {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
      }
      myCommunities {
        id
        logo
        logoPublicUrl
        name
      }
    }
  }
  ${ActionInfoFragmentDoc}
  ${MissionInfoFragmentDoc}
`;

/**
 * __useGetMissionsActionsByCompanyMemberQuery__
 *
 * To run a query within a React component, call `useGetMissionsActionsByCompanyMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMissionsActionsByCompanyMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMissionsActionsByCompanyMemberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMissionsActionsByCompanyMemberQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetMissionsActionsByCompanyMemberQuery,
    GetMissionsActionsByCompanyMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetMissionsActionsByCompanyMemberQuery,
    GetMissionsActionsByCompanyMemberQueryVariables
  >(GetMissionsActionsByCompanyMemberDocument, options);
}
export function useGetMissionsActionsByCompanyMemberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMissionsActionsByCompanyMemberQuery,
    GetMissionsActionsByCompanyMemberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetMissionsActionsByCompanyMemberQuery,
    GetMissionsActionsByCompanyMemberQueryVariables
  >(GetMissionsActionsByCompanyMemberDocument, options);
}
export type GetMissionsActionsByCompanyMemberQueryHookResult = ReturnType<
  typeof useGetMissionsActionsByCompanyMemberQuery
>;
export type GetMissionsActionsByCompanyMemberLazyQueryHookResult = ReturnType<
  typeof useGetMissionsActionsByCompanyMemberLazyQuery
>;
export type GetMissionsActionsByCompanyMemberQueryResult = Apollo.QueryResult<
  GetMissionsActionsByCompanyMemberQuery,
  GetMissionsActionsByCompanyMemberQueryVariables
>;
export const GetUnfinishedActionsByCmDocument = gql`
  query GetUnfinishedActionsByCM($id: ID!) {
    unfinishedActionsByCM: getCompanyMemberById(id: $id) {
      id
      myUnfinishedActions {
        ...ActionInfo
      }
    }
  }
  ${ActionInfoFragmentDoc}
`;

/**
 * __useGetUnfinishedActionsByCmQuery__
 *
 * To run a query within a React component, call `useGetUnfinishedActionsByCmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnfinishedActionsByCmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnfinishedActionsByCmQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUnfinishedActionsByCmQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnfinishedActionsByCmQuery,
    GetUnfinishedActionsByCmQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnfinishedActionsByCmQuery, GetUnfinishedActionsByCmQueryVariables>(
    GetUnfinishedActionsByCmDocument,
    options,
  );
}
export function useGetUnfinishedActionsByCmLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnfinishedActionsByCmQuery,
    GetUnfinishedActionsByCmQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnfinishedActionsByCmQuery, GetUnfinishedActionsByCmQueryVariables>(
    GetUnfinishedActionsByCmDocument,
    options,
  );
}
export type GetUnfinishedActionsByCmQueryHookResult = ReturnType<
  typeof useGetUnfinishedActionsByCmQuery
>;
export type GetUnfinishedActionsByCmLazyQueryHookResult = ReturnType<
  typeof useGetUnfinishedActionsByCmLazyQuery
>;
export type GetUnfinishedActionsByCmQueryResult = Apollo.QueryResult<
  GetUnfinishedActionsByCmQuery,
  GetUnfinishedActionsByCmQueryVariables
>;
export const GetMyThanksReceivedTodayDocument = gql`
  query GetMyThanksReceivedToday {
    getMyThanksReceivedToday {
      id
      score
      content
      createdAt
      companyMemberSender {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
`;

/**
 * __useGetMyThanksReceivedTodayQuery__
 *
 * To run a query within a React component, call `useGetMyThanksReceivedTodayQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyThanksReceivedTodayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyThanksReceivedTodayQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyThanksReceivedTodayQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetMyThanksReceivedTodayQuery,
    GetMyThanksReceivedTodayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyThanksReceivedTodayQuery, GetMyThanksReceivedTodayQueryVariables>(
    GetMyThanksReceivedTodayDocument,
    options,
  );
}
export function useGetMyThanksReceivedTodayLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMyThanksReceivedTodayQuery,
    GetMyThanksReceivedTodayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyThanksReceivedTodayQuery, GetMyThanksReceivedTodayQueryVariables>(
    GetMyThanksReceivedTodayDocument,
    options,
  );
}
export type GetMyThanksReceivedTodayQueryHookResult = ReturnType<
  typeof useGetMyThanksReceivedTodayQuery
>;
export type GetMyThanksReceivedTodayLazyQueryHookResult = ReturnType<
  typeof useGetMyThanksReceivedTodayLazyQuery
>;
export type GetMyThanksReceivedTodayQueryResult = Apollo.QueryResult<
  GetMyThanksReceivedTodayQuery,
  GetMyThanksReceivedTodayQueryVariables
>;
export const GetMyCurrentIdsDocument = gql`
  query GetMyCurrentIds {
    me {
      id
      currentCompanyMember {
        id
        myCommunities {
          id
        }
      }
      myCommunity {
        id
      }
    }
  }
`;

/**
 * __useGetMyCurrentIdsQuery__
 *
 * To run a query within a React component, call `useGetMyCurrentIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyCurrentIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyCurrentIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyCurrentIdsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMyCurrentIdsQuery, GetMyCurrentIdsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyCurrentIdsQuery, GetMyCurrentIdsQueryVariables>(
    GetMyCurrentIdsDocument,
    options,
  );
}
export function useGetMyCurrentIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyCurrentIdsQuery, GetMyCurrentIdsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyCurrentIdsQuery, GetMyCurrentIdsQueryVariables>(
    GetMyCurrentIdsDocument,
    options,
  );
}
export type GetMyCurrentIdsQueryHookResult = ReturnType<typeof useGetMyCurrentIdsQuery>;
export type GetMyCurrentIdsLazyQueryHookResult = ReturnType<typeof useGetMyCurrentIdsLazyQuery>;
export type GetMyCurrentIdsQueryResult = Apollo.QueryResult<
  GetMyCurrentIdsQuery,
  GetMyCurrentIdsQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    me {
      id
      firstName
      lastName
      fullName
      photoUrl
      photoPublicUrl
      mySharedHorizon
      bio
      email
      birthday
      familySituation
      isOnline
      personality
      adress
      phone
      skills {
        id
        title
      }
      successes {
        id
        title
      }
      passions {
        id
        title
      }
      previousCompanies {
        id
        name
      }
      pictures {
        id
        title
        imageUrl
        imagePublicUrl
      }
      presentationVideo {
        id
        publicUrl
      }
      communities {
        id
        community {
          id
          companyId
          name
        }
      }
      currentCompanyMember {
        id
        jobTitle
        isFirstEntry
        hiringDate
        userAccess
        role
        businessUnitRole
        exp
        expLevel {
          id
          name
          iconPublicUrl
        }
        businessUnit {
          id
          name
          logo
          logoPublicUrl
        }
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
          email
        }
        currentMotto {
          id
          content
        }
        nPlusOne {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        currentProfessionnalWeather {
          id
          title
          description
          icon
        }
        currentProfessionalStatus {
          title
          order
          id
          icon
          description
        }
      }
    }
  }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export function useGetMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetMeWelcomeBackDocument = gql`
  query GetMeWelcomeBack {
    me {
      id
      firstName
      lastName
      photoUrl
      photoPublicUrl
      currentCompanyMember {
        id
        isFirstEntry
      }
    }
  }
`;

/**
 * __useGetMeWelcomeBackQuery__
 *
 * To run a query within a React component, call `useGetMeWelcomeBackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeWelcomeBackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeWelcomeBackQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeWelcomeBackQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeWelcomeBackQuery, GetMeWelcomeBackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeWelcomeBackQuery, GetMeWelcomeBackQueryVariables>(
    GetMeWelcomeBackDocument,
    options,
  );
}
export function useGetMeWelcomeBackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeWelcomeBackQuery, GetMeWelcomeBackQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeWelcomeBackQuery, GetMeWelcomeBackQueryVariables>(
    GetMeWelcomeBackDocument,
    options,
  );
}
export type GetMeWelcomeBackQueryHookResult = ReturnType<typeof useGetMeWelcomeBackQuery>;
export type GetMeWelcomeBackLazyQueryHookResult = ReturnType<typeof useGetMeWelcomeBackLazyQuery>;
export type GetMeWelcomeBackQueryResult = Apollo.QueryResult<
  GetMeWelcomeBackQuery,
  GetMeWelcomeBackQueryVariables
>;
export const GetMeNotifDocument = gql`
  query GetMeNotif {
    me {
      id
      fullName
      firstName
      lastName
    }
  }
`;

/**
 * __useGetMeNotifQuery__
 *
 * To run a query within a React component, call `useGetMeNotifQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeNotifQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeNotifQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeNotifQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeNotifQuery, GetMeNotifQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeNotifQuery, GetMeNotifQueryVariables>(GetMeNotifDocument, options);
}
export function useGetMeNotifLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeNotifQuery, GetMeNotifQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeNotifQuery, GetMeNotifQueryVariables>(
    GetMeNotifDocument,
    options,
  );
}
export type GetMeNotifQueryHookResult = ReturnType<typeof useGetMeNotifQuery>;
export type GetMeNotifLazyQueryHookResult = ReturnType<typeof useGetMeNotifLazyQuery>;
export type GetMeNotifQueryResult = Apollo.QueryResult<GetMeNotifQuery, GetMeNotifQueryVariables>;
export const GetMeOneToOneDocument = gql`
  query GetMeOneToOne {
    me {
      id
      firstName
      lastName
      fullName
      photoUrl
      photoPublicUrl
      currentCompanyMember {
        id
        isManager
        currentProfessionnalWeather {
          icon
        }
        nPlusOne {
          id
        }
        nMinusOnes {
          id
        }
      }
    }
  }
`;

/**
 * __useGetMeOneToOneQuery__
 *
 * To run a query within a React component, call `useGetMeOneToOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeOneToOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeOneToOneQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeOneToOneQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMeOneToOneQuery, GetMeOneToOneQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeOneToOneQuery, GetMeOneToOneQueryVariables>(
    GetMeOneToOneDocument,
    options,
  );
}
export function useGetMeOneToOneLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMeOneToOneQuery, GetMeOneToOneQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeOneToOneQuery, GetMeOneToOneQueryVariables>(
    GetMeOneToOneDocument,
    options,
  );
}
export type GetMeOneToOneQueryHookResult = ReturnType<typeof useGetMeOneToOneQuery>;
export type GetMeOneToOneLazyQueryHookResult = ReturnType<typeof useGetMeOneToOneLazyQuery>;
export type GetMeOneToOneQueryResult = Apollo.QueryResult<
  GetMeOneToOneQuery,
  GetMeOneToOneQueryVariables
>;
export const GetTwoFactorAuthenticationDocument = gql`
  query GetTwoFactorAuthentication {
    me {
      id
      is2FA
    }
  }
`;

/**
 * __useGetTwoFactorAuthenticationQuery__
 *
 * To run a query within a React component, call `useGetTwoFactorAuthenticationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTwoFactorAuthenticationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTwoFactorAuthenticationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTwoFactorAuthenticationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTwoFactorAuthenticationQuery,
    GetTwoFactorAuthenticationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTwoFactorAuthenticationQuery, GetTwoFactorAuthenticationQueryVariables>(
    GetTwoFactorAuthenticationDocument,
    options,
  );
}
export function useGetTwoFactorAuthenticationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTwoFactorAuthenticationQuery,
    GetTwoFactorAuthenticationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTwoFactorAuthenticationQuery,
    GetTwoFactorAuthenticationQueryVariables
  >(GetTwoFactorAuthenticationDocument, options);
}
export type GetTwoFactorAuthenticationQueryHookResult = ReturnType<
  typeof useGetTwoFactorAuthenticationQuery
>;
export type GetTwoFactorAuthenticationLazyQueryHookResult = ReturnType<
  typeof useGetTwoFactorAuthenticationLazyQuery
>;
export type GetTwoFactorAuthenticationQueryResult = Apollo.QueryResult<
  GetTwoFactorAuthenticationQuery,
  GetTwoFactorAuthenticationQueryVariables
>;
export const GetPasswordDocument = gql`
  query GetPassword {
    hashedPassword: getPassword
  }
`;

/**
 * __useGetPasswordQuery__
 *
 * To run a query within a React component, call `useGetPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPasswordQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPasswordQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPasswordQuery, GetPasswordQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPasswordQuery, GetPasswordQueryVariables>(GetPasswordDocument, options);
}
export function useGetPasswordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPasswordQuery, GetPasswordQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPasswordQuery, GetPasswordQueryVariables>(
    GetPasswordDocument,
    options,
  );
}
export type GetPasswordQueryHookResult = ReturnType<typeof useGetPasswordQuery>;
export type GetPasswordLazyQueryHookResult = ReturnType<typeof useGetPasswordLazyQuery>;
export type GetPasswordQueryResult = Apollo.QueryResult<
  GetPasswordQuery,
  GetPasswordQueryVariables
>;
export const GetUserByIdDocument = gql`
  query GetUserById($id: ID!) {
    user: User(id: $id) {
      id
      firstName
      lastName
      photoUrl
      photoPublicUrl
      mySharedHorizon
      bio
      email
      birthday
      familySituation
      isOnline
      personality
      adress
      phone
      skills {
        id
        title
      }
      successes {
        id
        title
      }
      passions {
        id
        title
      }
      previousCompanies {
        id
        name
      }
      pictures {
        id
        title
        imageUrl
        imagePublicUrl
      }
      presentationVideo {
        id
        publicUrl
      }
      communities {
        id
        community {
          id
          companyId
          name
        }
      }
      currentCompanyMember {
        id
        jobTitle
        isFirstEntry
        hiringDate
        userAccess
        role
        businessUnitRole
        businessUnit {
          id
          name
          logo
          logoPublicUrl
        }
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
          email
        }
        currentMotto {
          id
          content
        }
        nPlusOne {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        currentProfessionnalWeather {
          id
          title
          description
          icon
        }
      }
    }
  }
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options,
  );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
  GetUserByIdQuery,
  GetUserByIdQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    CompanyTeamMembersSuggestion: ['CompanyMember', 'UserCompanyInvitation'],
  },
};
export default result;
