const process: Record<string, string> = {
  'process.team.in.charge': 'Team in charge',
  'process.team.concerned': 'Impacted Team',
  'process.team.in.expert': 'Team in expert',
  'process.delete.description': 'Delete Process',
  'process.delete.success': 'Process deleted succefully',
  'process.label.add': 'Add process',
  'process.label.create': 'Create process',
  'process.search.no.result': 'No process found for your search',
  'process.search.for': 'Search for process',
  'process.no.item': 'No process to show...',
};
export default process;
