import { gql } from '@apollo/client';

export const GET_CONGRATS_DIALOG_STATE = gql`
  query GetCongratsDialogState {
    congratsDialog @client {
      title
      contentTitle
      contentDescription
      isOpen
      redirectUrl
    }
  }
`;
