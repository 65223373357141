const notifications: Record<string, string> = {
  'notifications.issue.postComment.body': '{arg1} a commenté le probléme {arg2}',
  'notifications.issue.postComment.title': "Probléme : Nouveau commentaire d'un expert!",
  'notifications.issue.five.body': 'Vous avez plus de 5 problémes a traiter',
  'notifications.issue.five.title': 'Plus de 5 problémes!',
  'notifications.issue.create.expert.body':
    'Vous avez été séléctionné comme expert pour le probléme : {arg1}',
  'notifications.issue.create.title': 'Un probléme à été créé!',
  'notifications.issue.create.teamCharge.body': 'Votre équipe {arg1} est en charge du post {arg2}',
  'notifications.issue.update_demand.title': 'Probléme : Demande mis à jour!',
  'notifications.issue.update_demand.body': "{arg1} mis à jour d'une demande du probléme {arg2}.",
  'notifications.issue.update.title': 'Un probléme a été édité !',
  'notifications.issue.update.body': '{arg1} a edité le probléme {arg2}',
  'notifications.issue.solved.title': 'Le probléme a été résolu !',
  'notifications.issue.solved.body': 'Le probléme {arg1} a été résolu il y a 1 mois.',
  'notifications.issue.responsible.title': "Vous avez été nommé propriétaire d'un probléme !",
  'notifications.issue.responsible.body': '{arg1} vous a nommé responsable du probléme {arg2}	',

  'notification.advice.create.title': 'Noveau conseil!',
  'notification.advice.create.body':
    '{arg1} a publié un nouveau conseil nommé {arg2} adressé à votre équipe {arg3}',
  'notification.advice.share.title': '{arg1} a partagé un conseil!',
  'notification.advice.share.body': '{arg1} a partagé un conseil nommé: {arg2}',
  'notifications.advice.postComment.body': '{arg1} a commenté le conseil {arg2}',
  'notifications.advice.postComment.title': '{arg1}  a commenté un conseil !',
  'notification.advice.postLike.title': "Conseil : Nouveau j'aime!",
  'notification.advice.postLike.body': '{arg1} aime le conseil nommé : {arg2}',

  'notification.process.create.title': 'Nouveau process créé !',
  'notification.process.create.body': '{arg1} a créé un nouveau process nommé {arg2}',
  'notification.process.validation.title': 'Le process doit être validé !',
  'notification.process.validation.body':
    '{arg1} a créé un nouveau process, il a besoin de votre validation',
  'notification.process.validated.title': 'Processus validé !',
  'notification.process.validated.body': "L'un des validateurs a validé un process nommé {arg1}",
  'notification.process.updated.title': 'Processus mis à jour !',
  'notification.process.updated.body': '{arg1} a mis à jour un process nommé {arg2}',
  'notifications.process.postComment.body': '{arg1} a commenté un process nommé {arg2}',
  'notifications.process.postComment.title': 'Processus commenté !',

  'notifications.idea.postComment.title': '{arg1} a commenté votre idée! ',
  'notifications.idea.postComment.body': '{arg1} a commenté votre idée {arg2} ',
  'notifications.idea.endingRating.body':
    "La période de vote de l'idée {arg1} est terminée - {arg2}.",
  'notifications.idea.endingRating.title': 'La période de vote est terminée !',
  'notification.idea.create.title': 'Nouvelle idée de {arg1}',
  'notification.idea.create.body':
    '{arg1} a partagé une idée nommée {arg2} pour votre équipe: {arg3}',
  'notifications.idea.update.title': '{arg1} a mis a jour votre idée !',
  'notifications.idea.update.body': '{arg1} a mis a jour votre idée {arg2}',
  'notification.idea.rated.title': 'Votre idée a été notée !',
  'notification.idea.rated.body': "Quelqu'un a voté pour votre idée {arg1}",
  'notification.idea.fiveRated.title': '5 notes supplémentaires pour votre idée !',
  'notification.idea.fiveRated.body': 'Votre idée nommée {arg1} a 5 nouvelles notes !',

  'notifications.story.postComment.title': "What's up : Nouveau commentaire !",
  'notifications.story.postComment.body': "{arg1} a commenté votre what's up {arg2} ",
  'notifications.story.postLike.title': "What's up : Nouveau j'aime !",
  'notifications.story.postLike.body': '{arg1} aime votre idée : {arg2}',

  'notifications.thanks.response.title': 'Réponse de votre remerciement !',
  'notifications.thanks.response.body': '{arg1} a repondu a votre remerciement.',
  'notifications.thanks.create.title': 'Merci !',
  'notifications.thanks.create.body': '{arg1} vous a remercié',

  'notifications.feedback.create.title': 'Nouveau retour!',
  'notifications.feedback.create.body': '{arg1} vous a envoyé un retour',
  'notifications.feedback.response.title': 'Réponse pour votre retour !',
  'notifications.feedback.response.body': '{arg1} envoyé une réponse à votre retour.',

  'notification.goal.newAction.title': "Nouvelle action ajoutée sur l'indicateur!",
  'notification.goal.newAction.body': "{arg1} a ajouté une action sur l'indicateur nommé {arg3}",
  'notification.goal.newComment.title': "Nouveau commentaire ajouté sur l'indicateur !",
  'notification.goal.newComment.body':
    "{arg1} a ajouté un commentaire sur l'indicateur nommé {arg3}",

  'notification.indicator.create.title': 'Nouvel indicateur créé !',
  'notification.indicator.create.body': '{arg1} a créé un nouvel indicateur nommé {arg2}.',
  'notification.indicator.update.title': 'Indicateur mis à jour !',
  'notification.indicator.update.body':
    "{arg1} a mis à jour l'indicateur nommé {arg2}. cliquez pour voir les changements.",

  'notification.close.toaster': 'voir',

  'notification.action.create.title': "Affectation du responsable à l'action",
  'notification.action.create.body': "Vous avez assigné un responsable à l'action \n{arg1}",

  'notifications.fun.postComment.title': '{arg1} a commenté "votre have" fun!',
  'notifications.fun.postComment.body': '{arg1} a commenté votre "have fun" nommé {arg2} ',
  'notifications.fun.postLike.title': "Have fun: Nouveau J'aime !",
  'notifications.fun.postLike.body': '{arg1} aime votre "have fun" nommé : {arg2}',

  'notification.employee.updateSuperior.title': "L'employé a changé de supérieur",
  'notification.employee.updateSuperior.body':
    '{arg1} a changé son supérieur hiérarchique de "{arg2}" à "{arg3}"',
  'notification.employe.dayResult.title': '{arg2} employé du jour',
  'notification.employe.dayResultAccepted.body':
    "{arg1} a accepté la proposition d'employé du jour - {arg2}.",
  'notification.employe.dayResultRejected.body':
    "{arg1} a refusé la proposition d'employé du jour - {arg2}.",
  'notification.employe.dayDemande.title': 'Rappel employé du jour !',
  'notification.employe.dayDemande.body':
    "Hé, tu as été choisi pour être «l'employé du jour» de demain - {arg1}, assure toi de mettre à jour ton profil car tout le monde le verra dans le tunnel d'entrée.",
  'notification.employee.welcom.title': 'Bienvenue sur Orizon !',
  'notification.employee.welcom.body':
    'Bienvenue {arg1}, veuillez remplir les informations de ton profil, tu seras présenté aux autres utilisateurs sous peu.',
  'notification.employee.badWeather.title': 'Mauvais temps !!',
  'notification.employee.badWeather.body':
    '{arg1} a une mauvaise météo, voyez ce qui peut être fait. ',

  'notification.calendar.newEvent.title': 'Nouvel évènement!',
  'notification.calendar.newEvent.body':
    "{arg1} vous a ajouté en tant que propriétaire d'un nouvel événement {arg2}{arg3} {arg4}",
  'notification.calendar.newTeamEvent.title': "Nouvel événement d'équipe!",
  'notification.calendar.newTeamEvent.body':
    '{arg1} a créé un nouvel événement {arg2}{arg3} pour votre équipe {arg4} {arg5}',
  'notification.calendar.updateEvent.title': 'Événement modifié !',
  'notification.calendar.updateEvent.body': '{arg1} a édité un événement {arg2}{arg3} {arg4}',
  'notification.calendar.updateTeamEvent.title': "Événement d'équipe modifié !",
  'notification.calendar.updateTeamEvent.body':
    '{arg1} a édité un événement {arg2}{arg3} de votre équipe {arg4} {arg5}',
  'notification.calendar.deleteTeamEvent.title': "Événement d'équipe supprimé!",
  'notification.calendar.deleteTeamEvent.body':
    '{arg1} a supprimé un événement {arg2}{arg3} de votre équipe {arg4} {arg5}',

  'notification.calendar.deleteEvent.title': 'Événement supprimé !',
  'notification.calendar.deleteEvent.body': '{arg1} a supprimé un événement {arg2}{arg3} {arg4}',
  'notification.calendar.startTeamEvent.title': "L'événement d'équipe commence maintenant !",
  'notification.calendar.startTeamEvent.body':
    "L'événement {arg1} {arg2} de votre équipe {arg3} commence maintenant {arg4}",
  'notification.calendar.startEvent.title': "L'événement commence maintenant !",
  'notification.calendar.startEvent.body': "L'événement {arg1} {arg2} commence maintenant {arg3}",
  'notification.calendar.commentMeeting.title': 'Nouveau commentaire sur la réunion !',
  'notification.calendar.commentMeeting.body':
    '{arg1} a ajouté un nouveau commentaire sur la réunion nommé "{arg2}" à {arg3} ({arg4})',
  'notification.calendar.createMeeting.title': 'Demande de réunion !',
  'notification.calendar.createMeeting.body':
    '{arg1} veut un rendez-vous avec toi nommé "{arg2}" à {arg3} ({arg4})',
  'notification.calendar.updateMeeting.title': 'Réunion mise à jour !',
  'notification.calendar.updateMeeting.body':
    '{arg1} a mis à jour la réunion nommée "{arg2}" a {arg3} ({arg4})',
  'notification.calendar.deleteMeeting.title': 'Réunion annulée !',
  'notification.calendar.deleteMeeting.body': 'La réunion {arg1} a {arg2} ({arg3}) a été annulée',
  'notification.calendar.reminderMeeting.title': 'Rappel de réunion en cours !',
  'notification.calendar.reminderMeeting.body': '{arg1} va commencer dans une minute',
  'notification.calendar.startingMeeting.title': 'Une réunion commence maintenant !',
  'notification.calendar.startingMeeting.body': '{arg1} commence maintenant',

  'notification.calendar.commentTeamEvent.title': "Nouveau commentaire sur l'événement d'équipe",
  'notification.calendar.commentTeamEvent.body':
    '{arg1} a ajouté un nouveau commentaire sur l\'événement "{arg2}" de l\'équipe  {arg3} {arg4}',
  'notification.calendar.commentEvent.title': 'Nouveau commentaire sur un événement',
  'notification.calendar.commentEvent.body':
    '{arg1} a ajouté un nouveau commentaire sur l\'événement "{arg2}" {arg3}',
  'notification.calendar.commentLocation.title': "Nouveau commentaire sur l'emplacement",
  'notification.calendar.commentLocation.body':
    '{arg1} a ajouté un nouveau commentaire sur votre emplacement {arg2} du {arg3}',
  'notification.calendar.commentLeave.title': 'Nouveau commentaire sur un congé !',
  'notification.calendar.commentLeave.body':
    '{arg1} a posté un nouveau commentaire sur votre congé, {arg2}',

  'notification.semiInstanceCall.new.title': 'Nouvel appel entrant !',
  'notification.semiInstanceCall.new.body': '{arg1} veut un appel avec toi{arg2}, nommé {arg3}',
  'notification.semiInstanceCall.newGroup.body':
    '{arg1} veut un veut un appel de groupe pour{arg2}, nommé {arg3}',
  'notification.semiInstanceCall.update.title': 'Appel mis à jour',
  'notification.semiInstanceCall.update.body':
    '{arg1} a mis à jour un appel avec vous{arg2}, nommé {arg3}',
  'notification.semiInstanceCall.updateGroup.body':
    '{arg1} a mis à jour un appel du groupe de{arg2}, nommé {arg3}',
  'notification.semiInstanceCall.delete.title': 'Appel fermé',
  'notification.semiInstanceCall.delete.body':
    '{arg1} a clôturé un appel avec vous{arg2}, nommé {arg3}',
  'notification.semiInstanceCall.deleteGroup.body':
    "{arg1} a clôturé l'appel du groupe{arg2}, nommé {arg3}",
  'notification.levelUp.title': 'Nouveau niveau atteint',
  'notification.levelUp.body': 'Vous avez atteint un nouveau niveau: "{arg1}".',

  // Coin notifications for member
  'notification.member.coin.title': 'Nouveau coin gagné',
  'notification.member.coin.body': 'Vous avez gagné {arg1} coin(s) pour {arg2} exp',

  // Coin notifications for team
  'notification.team.coin.title': 'Nouveau coin gagné par votre équipe',
  'notification.team.coin.body': 'Votre équipe {arg1} a gagné {arg2} coin(s) pour {arg3} exp',
};
export default notifications;
