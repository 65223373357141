import React, { useCallback, useEffect, useMemo, useState } from 'react';

import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DefaultPersonAvatar from 'components/common/DefaultPersonAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import PsychologyIcon from '@mui/icons-material/Psychology';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import WorkIcon from '@mui/icons-material/Work';
import ApartmentIcon from '@mui/icons-material/Apartment';

import {
  PeopleFilterField,
  PeopleInputOption,
  useGetPeopleInputOptionsQuery,
} from 'graphql/generated/graphql';

export const useAutocompleteOptions = (field: PeopleFilterField) => {
  // *: use state to assure autocomplete rerender with default value
  const [autocompleteKey, setAutocompleteKey] = useState(0);

  const { data: optionsData, loading: optionsLoading } = useGetPeopleInputOptionsQuery();

  const options = useMemo(() => {
    switch (field) {
      case PeopleFilterField.AllFields:
        return optionsData?.peopleInputOptions ?? [];
      default:
        return optionsData?.peopleInputOptions?.filter((option) => option.type === field) ?? [];
    }
  }, [optionsData, field]);

  const filterToAutocompleteOption = useCallback((filter: string | PeopleInputOption) => {
    if (typeof filter === 'string') return filter;
    const { user, team, supervisor, businessUnit } = filter;
    switch (filter.type) {
      case PeopleFilterField.Name:
        return (
          <>
            <ListItemAvatar>
              <DefaultPersonAvatar
                avatarVariant="circular"
                avatarSize="small"
                src={user?.photoPublicUrl ?? 'fallback'}
                alt={user?.fullName ?? ''}
              />
            </ListItemAvatar>
            <ListItemText primary={user?.fullName} />
          </>
        );
      case PeopleFilterField.Teams:
        return (
          <>
            <ListItemAvatar>
              <DefaultPersonAvatar
                avatarVariant="circular"
                avatarSize="small"
                src={team?.logoPublicUrl ?? 'fallback'}
                alt={team?.name ?? ''}
              />
            </ListItemAvatar>
            <ListItemText primary={team?.name} />
          </>
        );
      case PeopleFilterField.Personality:
        return (
          <>
            <ListItemIcon>
              <PsychologyIcon color="disabled" />
            </ListItemIcon>
            <ListItemText primary={filter.personality} />
          </>
        );
      case PeopleFilterField.Supervisor:
        return (
          <>
            <ListItemAvatar>
              <DefaultPersonAvatar
                avatarVariant="circular"
                avatarSize="small"
                src={supervisor?.photoPublicUrl ?? 'fallback'}
                alt={supervisor?.fullName ?? ''}
              />
            </ListItemAvatar>
            <ListItemText primary={supervisor?.fullName} />
          </>
        );
      case PeopleFilterField.Skills:
        return (
          <>
            <ListItemIcon>
              <LightbulbIcon fontSize="small" color="disabled" />
            </ListItemIcon>
            <ListItemText primary={filter.skill} />
          </>
        );
      case PeopleFilterField.Success:
        return (
          <>
            <ListItemIcon>
              <MilitaryTechIcon color="disabled" />
            </ListItemIcon>
            <ListItemText primary={filter.success} />
          </>
        );
      case PeopleFilterField.Passions:
        return (
          <>
            <ListItemIcon>
              <LocalFireDepartmentIcon color="disabled" />
            </ListItemIcon>
            <ListItemText primary={filter.passion} />
          </>
        );
      case PeopleFilterField.BusinessUnit:
        return (
          <>
            <ListItemAvatar>
              <DefaultPersonAvatar
                avatarVariant="circular"
                avatarSize="small"
                src={businessUnit?.logoPublicUrl ?? 'fallback'}
                alt={businessUnit?.name ?? ''}
              />
            </ListItemAvatar>
            <ListItemText primary={businessUnit?.name} />
          </>
        );
      case PeopleFilterField.PreviousCompanies:
        return (
          <>
            <ListItemIcon>
              <ApartmentIcon fontSize="small" color="disabled" />
            </ListItemIcon>
            <ListItemText primary={filter.previousCompany} />
          </>
        );
      case PeopleFilterField.Email:
        return (
          <>
            <ListItemIcon>
              <AlternateEmailIcon fontSize="small" color="disabled" />
            </ListItemIcon>
            <ListItemText primary={filter.email} />
          </>
        );
      case PeopleFilterField.JobTitle:
        return (
          <>
            <ListItemIcon>
              <WorkIcon fontSize="small" color="disabled" />
            </ListItemIcon>
            <ListItemText primary={filter.jobTitle} />
          </>
        );
      default:
        return '';
    }
  }, []);

  useEffect(() => {
    setAutocompleteKey((prevKey) => prevKey + 1);
  }, [optionsLoading]);

  return { options, optionsLoading, filterToAutocompleteOption, autocompleteKey };
};
