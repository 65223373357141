import { makeStyles } from '@mui/styles';

const LEFT_SECTION_WIDTH = 248;

export default makeStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    background: theme.palette.background.default,
    overflow: 'hidden',
  },

  paper: {
    width: LEFT_SECTION_WIDTH,
    height: '100%',
    borderRadius: '0px',

    display: 'flex',
  },

  miniPaper: {
    width: '4.6%',
    minWidth: '70px',
  },

  main: {
    flex: 1,
    width: 'unset',
    overflow: 'hidden',
  },

  outletWrapper: {
    overflow: 'hidden',
  },
}));
