export enum KeyOfOffsetEnum {
  Limit = 'limit',
  PerPage = 'perPage',
  Offset = 'offset',
}

export interface LoadMoreDataType {
  total?: number;
  fetchMore?: <T extends Record<string, any>>(args: T) => void;
  defaultLimit?: number;
  keyOfOffset?: KeyOfOffsetEnum;
  maxLimit?: number;
}
