import React, { FC, createContext, useState } from 'react';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import createClient from 'utils/apolloClient';
import { ACCESS_TOKEN_KEY, COMPANY_SLUG_KEY } from 'constant';

interface IApolloClientContextProviderProps {
  children: React.ReactNode;
}

interface IApolloClientContext {
  client: ApolloClient<NormalizedCacheObject> | null;
  reconnect?: (newToken: string, newSlug?: string) => void;
}

export const ApolloClientContext = createContext<IApolloClientContext>({
  client: null,
});

export const ApolloClientContextProvider: FC<IApolloClientContextProviderProps> = ({
  children,
}) => {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  const slug = sessionStorage.getItem(COMPANY_SLUG_KEY) ?? undefined;

  const initialClientConfig = createClient(token || '', slug);
  const [client, setClient] = useState(initialClientConfig.client);

  const reconnect = (newToken: string, newSlug?: string) => {
    initialClientConfig.reconnectWebSocket(newToken, newSlug);
    setClient(initialClientConfig.client);
  };

  // Expose the reconnect function and the current client instance
  const value = {
    client,
    reconnect,
  };

  return (
    <ApolloClientContext.Provider value={value}>
      <ApolloProvider client={client}>
        {/* Pass the context value to children if needed */}
        {children}
      </ApolloProvider>
    </ApolloClientContext.Provider>
  );
};
