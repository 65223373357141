import { AxisOptions } from 'react-charts';

export type TDailyData = {
  date: string;
  value: number;
};

export type TChartData = {
  date: Date;
  value: number;
};

export const getPrimaryAxis = (dailyData: TDailyData[]): AxisOptions<TChartData> => {
  const firstDate = dailyData[0]?.date;
  return {
    getValue: (daily) => daily.date,
    min: firstDate ? new Date(new Date(firstDate).getTime() - 5 * 24 * 60 * 60 * 1000) : undefined,
    max: new Date(new Date().getTime() + 5 * 24 * 60 * 60 * 1000),
    showGrid: true,
    tickCount: 20,
  };
};

export const getSecondaryAxes = (dailyData: TDailyData[]): AxisOptions<TChartData>[] => {
  const lastValue = dailyData.at(-1)?.value ?? 0;
  const maxValue = lastValue + lastValue * 0.2;
  return [{ getValue: (daily) => daily.value, min: 0, max: maxValue }];
};

export const formatDailyData = (dailyData: TDailyData[], label: string) => {
  const formattedData = dailyData.map((dataItem) => ({
    date: new Date(dataItem.date),
    value: dataItem.value,
  }));

  return [{ label: label, data: formattedData }];
};
