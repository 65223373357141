import React, { createContext, FC, useState } from 'react';
import moment from 'moment-timezone';
import 'moment';
import 'moment/locale/fr';

import { EN } from '../../constant';

interface IAppContext {
  browserTimezone: string;
  browserLocale: string;
  handleChangeBrowserTimezone?: (timezone: string) => void;
  handleChangeBrowserLocale?: (locale: string) => void;
}

interface IAppContextProviderProps {
  children: React.ReactNode;
}

export const AppContext = createContext<IAppContext>({
  browserLocale: EN,
  browserTimezone: moment.tz.guess(),
});

export const AppContextProvider: FC<IAppContextProviderProps> = ({ children }) => {
  const [browserTimezone, setBrowserTimezone] = useState<string>(moment.tz.guess());
  const [browserLocale, setBrowserLocale] = useState<string>(EN);

  const handleChangeBrowserTimezone = (timezone: string) => {
    setBrowserTimezone(timezone);
  };

  const handleChangeBrowserLocale = (locale: string) => {
    setBrowserLocale(locale);
    moment.locale(locale, { week: { dow: 1 } });
  };

  const contextValue: IAppContext = {
    browserTimezone,
    browserLocale,
    handleChangeBrowserTimezone,
    handleChangeBrowserLocale,
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};
