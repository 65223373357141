const a3: Record<string, string> = {
  'a3.label.navigate.to': 'Naviger vers A3',
  'a3.label.use.a3.process': 'Utiliser le processus A3',
  // success
  'a3.success.create.followup': 'Suivi créé avec succès',
  'a3.success.update.followup': 'Suivi mise à jour avec succès',
  'a3.success.delete.followup': 'Suivi supprimé avec succès',
  'a3.success.create.rootCause': 'Cause créée avec succès',
  'a3.success.update.rootCause': 'Cause mise à jour avec succès',
  'a3.success.delete.rootCause': 'Cause supprimée avec succès',
  'a3.success.create.comment': 'Commentaire créé avec succès',
  'a3.success.create.analysisWhy': 'Pourquoi créé avec succès',
  'a3.success.update.analysisWhy': 'Pourquoi mise à jour avec succès',
  'a3.success.delete.analysisWhy': 'Pourquoi supprimé avec succès',
  'a3.success.create.compare': 'Analyse de comparaison créée avec succès',
  'a3.success.update.compare': 'Analyse de comparaison mise à jour avec succès',
  'a3.success.delete.compare': 'Analyse de comparaison supprimée avec succès',

  // error
  'a3.error.create.followup': 'Erreur durant la création du suivi',
  'a3.error.update.followup': 'Erreur durant la mise à jour du suivi',
  'a3.error.delete.followup': 'Erreur durant la suppréssion du suivi',
  'a3.error.create.rootCause': 'Erreur durant la création de la cause',
  'a3.error.update.rootCause': 'Erreur durant la mise à jour de la cause',
  'a3.error.delete.rootCause': 'Erreur durant la supprésion de la cause',
  'a3.error.create.comment': 'Erreur durant la création du commentaire',
  'a3.error.create.analysisWhy': 'Erreur durant la création du pourquoi',
  'a3.error.update.analysisWhy': 'Erreur durant la mise à jour du pourquoi',
  'a3.error.delete.analysisWhy': 'Erreur durant la suppréssion du pourquoi',
  'a3.error.create.compare': "Erreur lors de la création d'une comparaison",
  'a3.error.update.compare': "Erreur lors de la mise à jour d'une comparaison",
  'a3.error.delete.compare': "Erreur lors de la suppréssion d'une comparaison",

  // dialog
  'a3.dialog.edit.title.followup': 'Modifier le suivi',
  'a3.dialog.delete.title.followup': 'Supprimer le suivi',
  'a3.dialog.edit.title.rootCause': 'Modifier la cause',
  'a3.dialog.delete.title.rootCause': 'Supprimer la cause',
  'a3.dialog.delete.title.goal': "Supprimer l'indicateur",
  'a3.dialog.delete.title.action': "Supprimer l'action",
  'a3.dialog.delete.title.why': 'Supprimer le pourquoi',
  'a3.dialog.delete.title.compare': 'Supprimer la comparaison',

  // objective
  'a3.objective.create.success': 'Objectif créé avec succès',
  'a3.objective.delete.description': 'Supprimer objectif',
  'a3.counter.measure.delete.description': 'Supprimer la contre-mesure?',
  'a3.objective.delete.success': 'Objectif supprimé avec succès',
  'a3.objective.update.success': 'Objectif mise à jour avec succès',
  'a3.objective.edit.your.root.cause.s': 'Modifier la cause',

  // Counter measure
  'a3.counter.measure.create.success': 'Contre-mesure créée avec succès',
  'a3.counter.measure.delete.success': 'Contre-mesure supprimée avec succès',
  'a3.counter.measure.update.success': 'Contre-mesure mise à jour avec succès',
  'a3.post.change.status.success': 'Statut du problème A3 modifié avec succès',

  // Goal
  'a3.goal.delete.success': 'Indicateur supprimé avec succès',

  // input
  'a3.input.create.title.why': 'Vos pourquoi',
  'a3.input.edit.title.why': 'Modifier le pourquoi',
  'a3.input.create.title.compare.subject1': 'Un succès',
  'a3.input.create.title.compare.subject2': 'Un echec',
  'a3.input.edit.title.compare.subject1': 'Modifier la réussite',
  'a3.input.edit.title.compare.subject2': "Modifier l'echec",
  // Plan A3
  'a3.goal.add.new': 'Ajouter un nouvel indicateur',
  'a3.project.add.new': 'Ajouter un nouveau projet',
  'a3.action.add.new': 'Ajouter une nouvel action',
  'a3.sub.action.title': 'Sous-action',

  // precise.description
  'a3.problem.precise.description': 'Informations sur le problème',
  'a3.problem.precise.description.title': 'Description précise du problème ?',
  'a3.problem.precise.description.of': 'Decrivez le plus précisément le problème',
  'a3.precise.description.image.deleted': 'Fichier supprimé avec succès',
  'a3.precise.description.image.upload': 'Fichier téléchargé avec succès',
  'a3.issue.precise.description.drop.upload.files':
    'Déposez une photo ou une vidéo à télécharger ou',
};
export default a3;
