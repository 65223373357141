import moment, { Moment, unitOfTime } from 'moment-timezone';
import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import { IS_VALID_RANGE_KEY } from './constant';
import { DateRange } from 'mui-daterange-picker';

export const DATE_FORMAT_DD_MM_YYYY = 'DD/MM/YYYY';
export const LONG_DATE_FORMAT = 'DD/MMMM/YYYY';
export const FULL_DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const DATE_FORMAT_YYYY_MM_DD = 'YYYY/MM/DD';
export const LOWER_CASE_DATE_WITH_HOUR_FORMAT = 'dd/MM/yyyy HH:mm';
export const UPPER_CASE_DATE_WITH_HOUR_FORMAT = 'DD/MM/YYYY HH:mm';
export const LOWER_CASE_DATE_FORMAT = 'dd/MM/yyyy';
export const MEETING_DATE_FORMAT = 'DD/MM/YYYY HH:mm';
export const DEFAULT_HOUR_FORMAT = 'HH:mm';
export const PARAM_DATE_FORMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FORMAT_MM_DD_YYYY = 'MM-DD-YYYY';
export const CALENDAR_DATE_FORMAT = 'dddd, MMMM DD, YYYY';
export const DATE_FORMAT_WITHOUT_TIMEZONE = 'YYYY-MM-DDTHH:mm:ss';
export const DATE_FORMAT_WITH_TIMEZONE = 'YYYY/MM/DD HH:mm ZZ';
export const DATE_FORMAT_dddd_D_MMMM_YYYY = 'dddd D MMMM, YYYY';
export const DATE_FORMAT_dddd_D = 'dddd D';
export const DATE_FORMAT_MMMM_YYYY = 'MMMM YYYY';
export const DATE_TIME_SPLIT_BY_COMA = 'DD/MM/YYYY, HH:mm';

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, '0');
};

export const formatDateWithoutTime: (date: Date) => string = (date) => {
  return [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
    '-',
  );
};

export const isDateOverdue: (targetDate: Date) => boolean = (targetDate) => {
  const targetDateMoment = moment(formatDateWithoutTime(targetDate));
  const currentDateMoment = moment(formatDateWithoutTime(new Date()));
  return targetDateMoment.isBefore(currentDateMoment);
};

export const toDay = () => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return today;
};

export const formatDateWithoutTimezone = (date: Date) => {
  const datePart = [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-');

  const hourPart = [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), '00'].join(':');

  return `${datePart}T${hourPart}`;
};
// Retourne le temps entre maintenant et la cible
export const getRestTimeFromNow = (time: Date, unitOfTime?: unitOfTime.Diff) => {
  const target = moment(time);
  const today = moment();
  // Retourne target - today
  return target.diff(today, unitOfTime ?? 'milliseconds');
};
// Retourne le nombre de jour entre 2 dates
export const getRestDaysBetweenDate = (firstDate: string, secondDate: string): number => {

  const s = moment.tz(firstDate, moment.tz.guess()).format(DATE_FORMAT_YYYY_MM_DD);
  const e = moment.tz(secondDate, moment.tz.guess()).format(DATE_FORMAT_YYYY_MM_DD);
  // Retourne second - first
  return moment(e, DATE_FORMAT_YYYY_MM_DD).diff(moment(s, DATE_FORMAT_YYYY_MM_DD), 'd') + 1;
};

export const HumanizeMeetingDueDate = (date: Date) => {
  const YYYY = date.getFullYear();
  const MM = padTo2Digits(date.getMonth() + 1);
  const DD = padTo2Digits(date.getDate());
  const HH = padTo2Digits(date.getHours());
  const mm = padTo2Digits(date.getMinutes());
  const ss = padTo2Digits(date.getSeconds());

  return `${YYYY}-${MM}-${DD}T${HH}:${mm}:${ss}`;
};

export const getDateIsoFormatFromLocalTimezoneCode = (date: Date) => {
  const localeTzCode = moment.tz.guess();
  const datePart = moment.tz(date, localeTzCode).format('L');
  return moment.utc(datePart, 'L').toISOString();
};

/******** NB: replace not convert (get same date and time but with another timezone)  **********/

export const replaceDateTimezoneToMyTimezone = (date: Date) => {
  const formattedDateWithoutTimezone = formatDateWithoutTimezone(date);
  return moment(formattedDateWithoutTimezone, DATE_FORMAT_WITHOUT_TIMEZONE).toDate();
};

export const replaceDateTimezoneToLocalTimezone = (date: Date) => {
  const formattedDateWithoutTimezone = formatDateWithoutTimezone(date);
  return new Date(formattedDateWithoutTimezone);
};

export const replaceDateTimezone = (date: Date, tz: string) => {
  const formattedDateWithoutTimezone = formatDateWithoutTimezone(date);
  return moment.tz(formattedDateWithoutTimezone, DATE_FORMAT_WITHOUT_TIMEZONE, tz).toDate();
};

export const isWeekend = (date: Date) => date.getDay() === 0 || date.getDay() === 6;

export const isWorkingHours = (date: Date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return hours < 8 || (hours >= 18 && minutes > 0);
};

/**
 * Returns an array of all times in a day, from 00:00 to 23:59.
 *
 * @return {string[]} An array of time strings in the format "HH:mm".
 */
export const getTimeArray = (): string[] => {
  var times = [];

  for (var hour = 0; hour < 24; hour++) {
    for (var minute = 0; minute < 60; minute++) {
      var hh = hour < 10 ? '0' + hour : hour;
      var mm = minute < 10 ? '0' + minute : minute;
      var time = hh + ':' + mm;
      times.push(time);
    }
  }

  return times;
};

/**
 * Returns an array of all times in a day by 15 minutes, from 00:00 to 23:59.
 *
 * @return {string[]} An array of time strings in the format "HH:mm".
 */
export const getTimeBy15MinutesArray = (): string[] => {
  var times = [];

  for (var hour = 0; hour < 24; hour++) {
    for (var minute = 0; minute < 60; minute += 15) {
      // Increment by 15 minutes
      var hh = hour < 10 ? '0' + hour : hour;
      var mm = minute < 10 ? '0' + minute : minute;
      var time = hh + ':' + mm;
      times.push(time);
    }
  }

  return times;
};

export const combineDateAndTime = (datePart: Date, timePart: string) => {
  // Parse the time string as hours and minutes
  const [hours, minutes] = timePart.split(':').map(Number);

  // Create a new Date object with the date part and the time part
  const combinedDate = new Date(
    datePart.getFullYear(),
    datePart.getMonth(),
    datePart.getDate(),
    hours,
    minutes,
  );

  return combinedDate;
};

export const checkCalendarRestrictionDate = (intl: IntlShape, date: Moment) => {
  const startDay = date.get('day');
  const startHours = date.get('hours');
  const startMinutes = date.get('minutes');

  // Restrict week-end value
  if (startDay === 5 || startDay === 6)
    return new yup.ValidationError(
      intl.formatMessage({ id: 'calendar.create.on.week.end.warning' }),
      null,
      IS_VALID_RANGE_KEY,
    );

  // Restrict event time : between 8AM and 18PM
  if (startHours < 8 || (startHours >= 18 && startMinutes > 0))
    return new yup.ValidationError(
      intl.formatMessage({ id: 'calendar.event.time.warning' }),
      null,
      IS_VALID_RANGE_KEY,
    );
};

export const getThisMonthDateRange: () => DateRange = () => ({
  startDate: new Date(moment().startOf('month').format(PARAM_DATE_FORMAT_YYYY_MM_DD)),
  endDate: new Date(moment().endOf('month').format(PARAM_DATE_FORMAT_YYYY_MM_DD)),
});

//Ajoute des minutes a l'heure actuelle
export const addDurationToDate = (duration: number) => {
  const hoursToAdd = Math.floor(duration / 60);
  const minutesToAdd = duration % 60;

  return moment().startOf('day').add(hoursToAdd, 'hours').add(minutesToAdd, 'minutes').toDate();
};

export const extractDurationFromDate = (date: Date) => {
  const momentDate = moment(date);
  const hours = momentDate.hours();
  const minutes = momentDate.minutes();

  return hours * 60 + minutes;
};

export const formattedWithMyTimezone = (date: Date, format: string) => {
  const formattedDateWithoutTimezone = formatDateWithoutTimezone(date);
  return moment(formattedDateWithoutTimezone, DATE_FORMAT_WITHOUT_TIMEZONE).format(format);
};

export const addDayToDate = (date: Date, dayToAdd: number): Date => {
  var res = new Date(date);
  res.setDate(res.getDate() + dayToAdd);
  return res;
}
