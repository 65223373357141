import { gql } from '@apollo/client';

export const COMMUNITY_BADGE_FRAGMENT = gql`
  fragment CommunityBadgeFragment on CommunityBadge {
    badgeId
    communityId
    status
    done
  }
`;

export const INDIVIDUAL_BADGE_FRAGMENT = gql`
  fragment IndividualBadgeFragment on IndividualBadge {
    badgeId
    companyMemberId
    status
  }
`;

export const BADGE_FRAGMENT = gql`
  fragment BadgeFragment on Badge {
    id
    name
    description
    icon
    iconPublicUrl
    type
    category
    points
  }
`;
