import { gql } from '@apollo/client';

export const USER_ON_POST_FRAGMENT = gql`
  fragment UserOnPostInfo on User {
    id
    firstName
    fullName
    lastName
    email
    photoUrl
    photoPublicUrl
  }
`;

export const N_PLUS_AND_MINUS_ONE_INFO_FRAGMENT = gql`
  fragment NplusAndMinusOneInfo on User {
    id
    firstName
    lastName
    fullName
    isOnline
    photoUrl
    photoPublicUrl
  }
`;

export const USER_INFO_FRAGEMENT = gql`
  fragment UserInfo on User {
    id
    firstName
    lastName
    fullName
    isItMyDay
    email
    bio
    isOnline
    photoPublicUrl
    photoUrl
    birthday
    familySituation
    personality
    mySharedHorizon
    role
    is2FA
    suspended
    removed
    isOnline
  }
`;

export const USER_WITH_PHOTO_IN_LEAVE_FRAGEMENT = gql`
  fragment UserWithPhotoInLeaveFragment on User {
    id
    firstName
    lastName
    fullName
    photoPublicUrl
    photoUrl
  }
`;

export const USER_IN_LEAVE_FRAGEMENT = gql`
  fragment UserInLeaveFragment on User {
    id
    firstName
    lastName
    fullName
    email
  }
`;

export const USER_IN_PROJECT_FRAGMENT = gql`
  fragment UserInProjectFragment on User {
    id
    firstName
    lastName
    fullName
    photoUrl
    photoPublicUrl
  }
`;

export const USER_ME_FRAGMENT = gql`
  fragment UserMeInfo on User {
    id
    fullName
    firstName
    lastName
    photoUrl
    photoPublicUrl
    mySharedHorizon
    bio
    email
    birthday
    familySituation
    isOnline
    personality
    skills {
      id
      title
    }
    successes {
      id
      title
    }
    passions {
      id
      title
    }
    previousCompanies {
      id
      name
    }
    pictures {
      id
      title
      imageUrl
      imagePublicUrl
    }
    presentationVideo {
      id
      publicUrl
    }
    communities {
      id
      community {
        id
        companyId
        name
      }
    }
    currentCompanyMember {
      id
      jobTitle
      isFirstEntry
      hiringDate
      userAccess
      role
      businessUnitRole
      businessUnit {
        id
        name
        logo
        logoPublicUrl
      }
      user {
        id
        fullName
        firstName
        lastName
        photoUrl
        photoPublicUrl
        email
      }
      currentMotto {
        id
        content
      }
      nPlusOne {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
      currentProfessionnalWeather {
        id
        title
        description
        icon
      }
    }
  }
`;
