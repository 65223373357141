const dialog: Record<string, string> = {
  'dialog.input.placeholder.title': 'Title',
  'dialog.input.placeholder.description': 'Description',
  'dialog.input.placeholder.status': 'Status',
  'dialog.input.placeholder.priority': 'Priority',
  'dialog.input.placeholder.who': 'With whom you have the issue ?',
  'dialog.input.placeholder.what': 'What is the object of the issue ?',
  'dialog.input.placeholder.how': 'How the issue happend ?',
  'dialog.input.placeholder.where': 'Where the issue took place ?',
  'dialog.input.placeholder.when': 'When the issue happend ?',
  'dialog.input.placeholder.impact': 'What are the impacts of the issue ?',

  'dialog.input.search.placeholder.community': 'Search an impacted Team',
  'dialog.input.search.placeholder.pending.community': 'Search a pending team',
  'dialog.input.search.placeholder.responsible': 'Search a Responsible concerned',
  'dialog.input.search.placeholder.coworker.to.select':
    'Coworker to select as owner of the issue (Responsible)',
  'dialog.input.search.placeholder.experts': 'Search an Expert concerned',
  'dialog.input.search.placeholder.experts.that.help.solve.the.issue':
    'Coworkers that can be asked to help solve the issue',
  'dialog.input.search.placeholder.validators': 'Search user as validator',
  'dialog.input.search.placeholder.community.source': 'Team to solve/improve the issue (Owner)',
  'dialog.input.search.placeholder.community.sentinel':
    'Teams that need the issue to be solved (Sentinel)',
  'dialog.input.search.team': 'Search a team',
  'dialog.input.search.colaborator.or.team': 'Search a collaborator or a team',
  'dialog.input.search.colaborator': 'Search a collaborator',

  'dialog.input.date.placeholder.target.date': 'Target date',
  'dialog.input.select.placeholder.owner': 'Select an owner',

  'dialog.fieldset.label.community': 'Team',
  'dialog.fieldset.label.reponsible': 'Responsible',
  'dialog.fieldset.label.colaborator': 'Collaborator',

  'dialog.project.create.title': 'Create project',
  'dialog.project.edit.title': 'Edit project',
  'dialog.project.convert.title': 'Convert to project',
  'dialog.project.create.success': 'Project created successfully',
  'dialog.project.create.edit': 'Project edited successfully',

  'dialog.idea.convert.success': 'Idea converted successfully',
  'dialog.post.convert.success': 'Post converted successfully',

  'dialog.error.required': 'Required',
  'dialog.error.invalid.date': 'Invalid date',
  'dialog.error.invalid.time': 'Invalid time',
  'dialog.error.start.date.cannot.be.past.end.date': 'Start date cannot be past end date',
  'dialog.error.end.date.cannot.be.before.start.date': 'End date cannot be before start date',
  'dialog.error.birthday': 'Make sure that Bithday is at least yesterday',

  'dialog.label.well.done': 'Well done',
  'dialog.order.congrats.label':
    'Congratulations for your order. Please report to the technical support department to receive the item',

  'dialog.congrats': 'Congrats',
  'dialog.congrats.idea': 'Your idea has been posted',
  'dialog.congrats.problem': 'Your issue has been posted',
  'dialog.congrats.story': "What's up has been posted successfuly",
  'dialog.congrats.advice': 'Your advice has been posted',
  'dialog.congrats.process': 'Your process has been posted',
  'dialog.congrats.question': 'Your question has been posted',
  'dialog.congrats.have.fun': 'Your have fun has been posted',
  'dialog.order.error.label': "You don't have enough points to order this item",

  'dialog.action.convert.title': 'Convert to action',

  'dialog.button.convert': 'Convert',

  'dialog.input.label.community.source': 'Team source',
  'dialog.input.label.team.in.charge': 'Team in charge',
  'dialog.input.label.your.password': 'Your password',

  'dialog.title.add.a.new.process': 'Add a new process',
  'dialog.title.edit.process': 'Edit process',
  'dialog.title.add.an.issue': 'Add an issue',
  'dialog.title.edit.issue': 'Edit issue',
  'dialog.title.change.profile.s.picture': "Change profile's picture",
  'dialog.title.personal.information': 'Personal information',
  'dialog.title.detailed.information': 'Information details',

  'dialog.title.presentation.file': 'Presentation file',
  'dialog.title.attached.files': 'Attached files',
  'dialog.browse.to.presentation.file': 'to presentation file',
  'dialog.browse.to.attached.files': 'to attached files',
  'dialog.browse.for.your.profile.s.picture': "for your profil's pictures",
  'dialog.browse.for.the.business-unit-logo': 'for the business unit logo',
  'dialog.browse.to.add.pictures': 'to add pictures',
  'dialog.browse.to.add.video': 'to add video',

  'dialog.title.person.who.liked': 'Person who liked',
  'dialog.title.password': 'Password',

  'dialog.idea.input.title': 'Give a title to your idea',
  'dialog.problem.input.title': 'Give a title to your issue',
  'dialog.story.input.title': "Give a title to your what's up",
  'dialog.advice.input.title': 'Give a title to your advice',
  'dialog.process.input.title': 'Give a title to your process',
  'dialog.question.input.title': 'Give a title to your question',

  'dialog.input.give.a.title': 'Give a title',
  'dialog.input.describe.it': 'Describe it',

  'dialog.process.input.description': 'Describe your process',
  'dialog.shop.confirm.description': 'Are you sure you want to order this item',
  'dialog.event.drop.confirm.description': 'Are you sure to move this event to this date',

  'dialog.calendar.create.event': 'Create an event ',
  'dialog.calendar.update.event': 'Update an event ',
  'dialog.project.edit.success': 'Project edited with success',

  'dialog.title.delete.comment': 'Delete comment',
  'dialog.post.delete.idea': 'Do you really want to delete this great idea?',
  'dialog.post.delete': 'Do you really want to delete this great {postType}?',

  'dialog.title.add.a.new.question': 'Add a new question',
  'dialog.title.edit.question': 'Edit question',
  'dialog.title.badges': 'Badges',
  'dialog.title.badge': 'Badge',

  'dialog.title.delete.user': 'Delete User',
  'dialog.title.team.details': 'Team details',

  // Leave
  'dialog.title.leave.creation': 'Leave creation',
  'dialog.title.leave.details': 'Leave details',
  'dialog.input.start.date': 'Start date',
  'dialog.input.ending.on': 'Ending on',
  'dialog.input.reasons.of.the.leave': "Reason's of the leave",
  'dialog.checkbox.special.day.off': 'Special day off',
  'dialog.label.people.to.notify': 'People to notify',
  'dialog.label.other.validators': 'Other validators',
  'dialog.browse.to.add.document.for.the.reason': 'to add document for the reason',
  'dialog.create.leave.success': 'Leave created successfully',
  'dialog.create.leave.error': 'Error on create leave',
  'dialog.label.other.reasons': 'Other reasons',
  'dialog.title.delete.leave': 'Leave deletion',
  'dialog.label.is.a.special.leave': 'Is a special leave',
  'dialog.validate.leave.success': 'Leave status updated successfully',
  'dialog.validate.leave.error': 'Error on update leave status',
  'dialog.title.leave.edition': 'Edit leave',
  'dialog.edit.leave.success': 'Leave edited successfully',
  'dialog.edit.leave.error': 'Error on edit leave',
  'dialog.delete.leave.success': 'Leave deleted successfully',
  'dialog.delete.leave.error': 'Error on delete leave',
  'dialog.title.leave.deletion': 'Delete leave',
  'dialog.title.leave.download': 'Download leaves',
  'dialog.download.leave.success': 'Leaves downloaded successfully',
  'dialog.download.leave.error': 'Error on download leaves',
  'dialog.download.leave.warning': 'There is no validated leaves on this date range',

  'dialog.label.this.item.has.been.deleted': 'This item has been deleted',
  'dialog.label.empty': 'Empty',

  // Business unit
  'dialog.create.business-unit.success': 'Business Unit created successfully',
  'dialog.update.business-unit.success': 'Business Unit updated successfully',
  'dialog.delete.business-unit.success': 'Business Unit deleted successfully',
  'dialog.create.business-unit.error': 'Error on creating Business Unit',
  'dialog.update.business-unit.error': 'Error on updating Business Unit',
  'dialog.delete.business-unit.error': 'Error on deleting Business Unit',
  'dialog.add-member.business-unit.success': 'Member added successfully',
  'dialog.update-member.business-unit.success': 'Member updated successfully',
  'dialog.add-member.business-unit.error': 'Error on adding member to business unit',
  'dialog.update-member.business-unit.error': 'Error on updating member to business unit',
  'dialog.remove-member.business-unit.success': 'Member removed successfully',
  'dialog.remove-member.business-unit.error': 'Error on removing member from business unit',

  'dialog.label.view-all.image': 'View all {item} images',
  'dialog.checkbox.make.public': 'Make public',

  'dialog.update.issue.privacy.success': 'Issue Privacy updated successfully',
  'dialog.update.issue.privacy.error': 'Error on updating Issue Privacy',

  // action
  'dialog.title.delete.action': 'Delete action',

  'dialog.coworker.input.placeholder': 'Search and add a coworker',

  // application
  'dialog.application.details': 'Application details',

  // badge
  'dialog.badge.edit.title': 'Edit badge',
  'dialog.badge.edit.success': 'Badge edited successfully',
  'dialog.badge.edit.error': 'Error on edit badge',

  // Exp event
  'dialog.member.exp.event.title': 'Edit an user event',
  'dialog.team.exp.event.title': 'Edit a team event',
};
export default dialog;
