const chat: Record<string, string> = {
  'chat.label.no.channel': 'You have no team group message yet',
  'chat.label.no.direct.message': 'You have no direct message yet',
  'chat.label.leave.channel': 'Leave channel',
  'chat.label.cannot.discuss': 'You cannot discuss with removed coworker',
  'chat.label.warning.delete_user': 'Warning : All your coworkers are deleted',

  // Labels
  'chat.label.group': 'Team group chat',
  'chat.label.group.create': 'Create team group chat',
  'chat.label.group.update': 'Update team group chat',
  'chat.label.direct.message': 'Direct messages',
  'chat.label.direct.message.create': 'Create direct message',
  'chat.label.create.group': 'Create chat group',
  'chat.label.update.group': 'Update chat group',
  'chat.label.delete.channel': 'Delete channel',
  'chat.label.leave.channel.description': 'Are you sure you want to leave this chat group ?',
  'chat.channel.name.change': 'Channel name changed to {channelName}',
  'chat.channel.user.joined': '{user} joined this channel',
  'chat.channel.user.removed': '{user} was removed from this channel',
  'chat.label.you_and_others': 'You and {count} others',
  'chat.label.from_team': 'From team',
  'chat.label.custom': 'Custom',
  'chat.label.deleted_user': '(Deleted user)',
  
  // Placeholder
  'chat.placeholder.group.chat.name': 'Team group chat name',
  'chat.placeholder.send.a.message': 'Send a message...',
  'chat.placeholder.rename.channel': 'Your channel name...',

  'chat.a.user.is.typing': '{user} is typing...',
  'chat.two.users.are.typing': '{user1} and {user2} are typing...',
  'chat.more.users.are.typing': '{user} and {remainUsersLength} more are typing...',

  // Errors
  'chat.session.exired':
    'Something went wrong about your stream account, Please contact your administrator',
  'chat.create.channel.error': 'Failed to create channel',
  'chat.create.dm.error': 'Failed to create direct message',
  'chat.delete.channel.error': 'Failed to delete channel',
  'chat.leave.channel.error': 'Failed to leave channel',
  'chat.loading.channel.error': 'Error loading conversations, please try again momentarily',
};
export default chat;
