import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ID_KEY } from 'constant';

import { FetchResult, InternalRefetchQueriesInclude } from '@apollo/client';

import { TActionFormValues } from 'components/common/Form/ActionForm/constants';
import { useDisplaySnackBar } from 'components/scenes/hooks/useDisplaySnackBar';

import {
  CreateActionMutationFn,
  UpdateActionMutation,
  useCreateActionMutation,
  useCurrentCompanyMemberQuery,
  useDeleteActionMutation,
  useUpdateActionMutation,
} from 'graphql/generated/graphql';
import { GET_ALL_ACTIONS, GET_COMMUNITY_ACTIONS } from 'graphql/community/query';
import { GET_UNFINISHED_ACTIONS_BY_CM } from 'graphql/user/query';
import { GET_BADGES_BY_COMMUNITY } from 'graphql/badge/query';
import { CURRENT_COMPANY_MEMBER } from 'graphql/company/query';

export type RefetchQueries =
  | InternalRefetchQueriesInclude
  | ((
      result: FetchResult<Partial<UpdateActionMutation>, Record<string, any>, Record<string, any>>,
    ) => InternalRefetchQueriesInclude)
  | undefined;
export interface IActionContextValue {
  handleUpdate?: (
    newAction?: TActionFormValues,
    callback?: () => void,
    refetchQueries?: RefetchQueries,
    communityId?: string,
  ) => void;
  updateLoading?: boolean;
  handleDelete?: (
    id: string,
    callback?: () => void,
    refetchQueries?: RefetchQueries,
    variables?: { postId?: string; projectId?: string },
    communityId?: string,
  ) => void;
  delLoading?: boolean;
  createAction?: CreateActionMutationFn;
  createLoading?: boolean;
}

const ActionContext = React.createContext<IActionContextValue>({});

export const ActionProvider = ({ children }: { children: JSX.Element }) => {
  const displaySnackBar = useDisplaySnackBar();
  const [value, setValue] = React.useState<IActionContextValue>({});
  const [searchParam] = useSearchParams();
  const { data } = useCurrentCompanyMemberQuery();
  const [deleteAction, { loading: delLoading }] = useDeleteActionMutation();
  const [updateAction, { loading: updateLoading }] = useUpdateActionMutation();
  const [createAction, { loading: createLoading }] = useCreateActionMutation();

  const handleUpdate = (
    newAction?: TActionFormValues,
    callback?: () => void,
    refetchQueries?: RefetchQueries,
    communityId?: string,
  ) => {
    updateAction({
      variables: {
        ...newAction,
        id: newAction?.id ?? '',
        responsibleIds: newAction?.responsibles?.map((responsible) => responsible.id) ?? [],
      },
      onCompleted: (data) => {
        if (data.updateAction) {
          callback?.();
          displaySnackBar('team.action.update.success', '', 'success');
        }
      },
      onError: (error) => {
        displaySnackBar('error.action.update', error.message, 'error');
      },
      refetchQueries: refetchQueries ?? [
        { query: GET_BADGES_BY_COMMUNITY, variables: { communityId: communityId ?? '' } },
        { query: CURRENT_COMPANY_MEMBER },
      ],
    });
  };

  const handleDelete = (
    id: string,
    callback?: () => void,
    refetchQueries?: RefetchQueries,
    variables?: { postId?: string; projectId?: string },
    communityId?: string,
  ) => {
    deleteAction({
      variables: { id },
      onCompleted: (data) => {
        if (data.deleteAction?.id) {
          callback?.();
          displaySnackBar('team.action.delete.success', '', 'success');
        }
      },
      onError: (error) => {
        displaySnackBar('error.action.delete', error.message, 'error');
      },
      refetchQueries: refetchQueries ?? [
        {
          query: GET_COMMUNITY_ACTIONS,
          variables: {
            id: searchParam.get(ID_KEY)!,
          },
          notifyOnNetworkStatusChange: true,
        },
        {
          query: GET_COMMUNITY_ACTIONS,
          variables: {
            id: searchParam.get(ID_KEY)!,
            orderBy: 'asc',
          },
        },
        {
          query: GET_UNFINISHED_ACTIONS_BY_CM,
          variables: {
            id: data?.currentCompanyMember?.id ?? '',
          },
          notifyOnNetworkStatusChange: true,
        },
        {
          query: GET_ALL_ACTIONS,
          variables: {
            postId: variables?.postId,
            projectId: variables?.projectId,
          },
        },
        {
          query: GET_ALL_ACTIONS,
          variables: {
            filter: { teamId: communityId ?? '' },
            page: 0,
            sortOrder: 'asc',
          },
        },
        { query: GET_BADGES_BY_COMMUNITY, variables: { communityId: communityId ?? '' } },
        { query: CURRENT_COMPANY_MEMBER },
      ],
    });
  };

  useEffect(() => {
    setValue({
      handleUpdate,
      updateLoading,
      handleDelete,
      delLoading,
      createAction,
      createLoading,
    });
  }, []);

  const memoizedValue = useMemo(() => ({ value }), [value]);

  return <ActionContext.Provider value={memoizedValue.value}>{children}</ActionContext.Provider>;
};

export default ActionContext;
