const userPreference: Record<string, string> = {
  'user.preference.update.active.hours.start.success':
    'Commencement des heures actives mise à jour avec succès',
  'user.preference.update.active.hours.end.success':
    'Fin des heures actives mise à jour avec succès',
  'user.preference.update.active.hours.start.error':
    "Une erreur s'est produite lors de la mise à jour du commencement des heures actives",
  'user.preference.update.active.hours.end.error':
    "Une erreur s'est produite lors de la mise à jour du fin des heures actives",
  'user.preference.toogle.entry.tunnel.success': "Le status du tunnel d'entrée est modifié",
  'user.preference.toogle.active.hours.success':
    'Activation des heures actives mise à jour avec succès',
  'user.preference.toogle.active.hours.error':
    "Une erreur s'est produite lors de la mise à jour de l'activation des heures actives",
  'user.preference.toogle.entry.tunnel.error':
    "Erreur lors de la modification de l'état du tunnel d'entrée",
  'user.preference.toogle.exit.tunnel.success': 'Le status du tunnel de sorti est modifié',
  'user.preference.toogle.exit.tunnel.error':
    "Erreur lors de la modification de l'état du tunnel de sorti",
  'user.preference.toogle.entry.tunnel.save.time.success':
    "Heure du tunnel d'entrée réglée avec succès",
  'user.preference.toogle.entry.tunnel.save.time.error':
    "Erreur sur le réglage de l'heure du tunnel d'entrée",
  'user.preference.toogle.exit.tunnel.save.time.success':
    'Heure du tunnel de sorti réglée avec succès',
  'user.preference.toogle.exit.tunnel.save.time.error':
    "Erreur sur le réglage de l'heure du tunnel de sorti",
  'user.preference.select.language': 'Sélectionnez une langue',
  'user.preference.langage.updated.success': 'La langue a été mise à jour avec succès',
  'user.preference.langage.updated.error':
    "Une erreur s'est produite lors de la mise à jour de la langue",
};
export default userPreference;
