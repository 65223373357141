import React from 'react';
import omit from 'lodash/omit';
import clsx from 'clsx';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { makeStyles } from '@mui/styles';

export interface IEditIconProps extends SvgIconProps {
  id?: string;
}

const useStyles = makeStyles(() => ({
  root: {
    fill: 'none',
    stroke: 'currentColor',
  },
}));

const EditIcon = (props: IEditIconProps) => {
  const { id = 'edit-icon', classes: customClasses, ...rest } = props;
  const classes = useStyles();

  return (
    <SvgIcon
      id={id}
      viewBox="0 0 24 24"
      classes={{ root: clsx(classes.root, customClasses?.root) }}
      {...omit(rest, 'classes')}
    >
      <path
        id="Tracé_19159"
        data-name="Tracé 19159"
        d="M16.93,9.51,13.88,6.46"
        transform="translate(-1 1)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
      <path
        id="Tracé_19160"
        data-name="Tracé 19160"
        d="M5.709,18.069H3V15.36a.831.831,0,0,1,.244-.589L14.356,3.658a.832.832,0,0,1,1.178,0L17.41,5.534a.832.832,0,0,1,0,1.178L6.3,17.825a.831.831,0,0,1-.589.244Z"
        transform="translate(1.5 1)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </SvgIcon>
  );
};

export default EditIcon;
