const project: Record<string, string> = {
  // finalized
  'project.finalized.successfully': 'Project finalized successfully',
  'project.finalized.error': 'An error occurred while finalizing project',

  // status
  'project.update.status.successfully': 'Project status updated successfully',
  'project.update.status.error': 'An error occurred while updating project status',

  // deletion
  'project.delete': 'Delete project',
  'project.delete.success': 'Project deleted successfully',
  'project.delete.error': 'An error occurred while deleting project',
};

export default project;
