const issue: Record<string, string> = {
  'issue.team.in.charge': 'Équipe en charge',
  'issue.team.concerned': 'Équipe impactée',
  'issue.team.in.expert': 'Équipe experte',
  'issue.demand.both': 'Améliorer/Résoudre',
  'issue.demand.improve': 'Améliorer',
  'issue.demand.improve.show.a3': 'Améliorer: afficher A3',
  'issue.demand.solve': 'Résoudre',
  'issue.demand.solve.show.issue': 'Résoudre: afficher problème',
  'issue.delete.description': 'Supprimer le problème',
  'issue.delete.success': 'Problème supprimé avec succès',
  'issue.label.add': 'Ajouter un problème',
  'issue.label.create': 'Créer un problème',
  'issue.search.no.result': 'Aucun resultat',
  'issue.search.for': 'Rechercher des problèmes',
  'issue.handle.dialog.title': 'Gérer résoudre/améliorer',
  'issue.handle.dialog.radio.button.label': 'Choisir entre: ',
  'issue.no.item': 'Aucun problème à afficher...',
  'issue.label.click.on.demand': "*Veuillez cliquer sur l'un des types de demande ci-dessous",
  'issue.label.issue.privacy': 'Confidentialité des problèmes',
  'issue.label.issue.privacy.public': 'Problème externe',
  'issue.label.issue.privacy.private': "Problème de l'équipe",
  'issue.comment.public.description.text':
    "Tout le monde dans l'entreprise peut voir ces commentaires si le problème est public",
  'issue.public.description.text':
    "Le problème est visible par les membres de l'équipe impactée et les experts du problème",
  'issue.private.description.text':
    "Le problème ne sera visible que dans le tableau de bord de l'équipe et uniquement par les membres de l'équipe en charge",
  'issue.comment.private.description.text':
    "Seuls les membres de l'équipe en charge peuvent voir ces commentaires",
};
export default issue;
