import { DynamicMultiSelectItem } from 'components/common/DynamicMultiSelect/DynamicMultiSelect';
import {
  SelectBubbleState,
  DateRangeState,
  DynamicMultiselectState,
  IFilterState,
  SliderState,
} from 'components/scenes/hooks';
import { Filter } from 'graphql/generated/graphql';
import { isEqual } from 'lodash';

export const convertFiltersToVariables = (filtersToApply: IFilterState[]): Filter[] => {
  return filtersToApply.map((filter: IFilterState) => {
    const sliderValue = isEqual((filter as SliderState).sliderValue, filter.config.sliderRange)
      ? []
      : (filter as SliderState).sliderValue;

    const selectedChip = filter.config.isSelectBubbleMultiselect
      ? undefined
      : ((filter as SelectBubbleState).selectedChip as string);

    const selectedChips = filter.config.isSelectBubbleMultiselect
      ? ((filter as SelectBubbleState).selectedChips as string[])
      : undefined;

    const selectedDynamicMultiselectItems =
      (filter as DynamicMultiselectState).selectedDynamicMultiselectItems?.map(
        (selectedDMItem: DynamicMultiSelectItem) => selectedDMItem.value,
      ) ?? undefined;

    return {
      postField: filter.config.postField,
      isCustomFilter: filter.config.isCustomFilter,
      isSelectBubbleMultiselect: filter.config.isSelectBubbleMultiselect,
      isOneToMany: filter.config.isOneToMany,
      sliderValue,
      selectedDynamicMultiselectItems,
      selectedChip,
      selectedChips,
      dateRange: {
        endDate: (filter as DateRangeState).dateRangeValue?.endDate,
        startDate: (filter as DateRangeState).dateRangeValue?.startDate,
      },
    };
  });
};
