export const QUERY_STRING_KEY = 'q';
export const DETAIL_KEY = 'd';
export const ID_KEY = 'id';
export const ALL_KEY = 'all';

export const RUCHE_TABS_URL_PARAM = 't';
export const THANKS_ID_KEY = 'thank-id';
export const THANKS_RECEIVED_KEY = 'thank-received';
export const REPLYED_THANKS_ID_KEY = 'replyed-thanks-id';

export const HIVE_TAB_WHAT_S_UP_KEY = 'what-s-up';

// Feedback keys
export const FEEDBACK_ID_KEY = 'feedback-id';
export const FEEDBACK_RECEIVED_KEY = 'feedback-received';
export const REPLYED_FEEDBACK_ID_KEY = 'replyed-feedback-id';

export const NEED_DIALOG_KEY = 'need-id';

// Simple Team event
export const TEAM_EVENT_ID = 'team-ev-id';

// Meeting
export const MEETING_ID = 'meet-id';
export const EDIT_MEETING_ID = 'ed-meet-id';
export const ACCEPT_MEETING_ID = 'accept-meet-id';
export const REGISTRANT_ID = 'reg-id';
export const DECLINE_MEETING_ID = 'decline-meet-id';

export const EMPLOYEE_OF_DAY_ID_KEY = 'employee_of_day_id';
export const EMPLOYEE_OF_DAY_RECEIVED_KEY = 'employee_of_day_received';

// UseOpenDialog
export const PARAM_ACTION = 'action';
export const GOAL_DETAIL = 'goal-detail';
export const GOAL_HISTORIES_DETAIL = 'goal-histories';
export const GOAL_HISTORY_DETAIL = 'goal-history';
export const TEAM_ISSUE_DETAIL = 'team-issue-detail';
export const PARAM_MUTATION = 'mutation';

// params search key
export const GOAL_ID = 'kpi';
export const GOAL_HISTORY_ID = 'kpi-story';
export const EMPLOYEE_OF_DAY_NOTIF_ID_KEY = 'notif_id';
export const PAGE_KEY = 'p';
export const PER_PAGE_KEY = 'pp';

// Entity id
export const COMPANY_ID = 'company-id';
export const TEAM_ID = 'team-id';
export const BUSINESS_UNIT_ID = 'bu-id';

// problem keys
export const BOTH_KEY = 'both';
export const SOLVE_KEY = 'solve';
export const IMPROVE_KEY = 'improve';

// project keys
export const CREATE_PROJECT_KEY = 'create-project';
export const EDIT_PROJECT_KEY = 'edit-project';
export const PROJECT_DETAIL_KEY = 'project-detail';
export const PROJECT_ID_KEY = 'project-id';

// actions
export const ADD_KEY = 'add';
export const EDIT_KEY = 'edit';
export const CONVERT_KEY = 'convert';
export const ACTION_ID_KEY = 'action-id';

// calendar key
export const TEAM_EVENT_KEY = 'tEvId';
export const PRAM_EVENT_DAY = 'event-day';
export const EVENT_DETAILS = 'event-details';
export const PRAM_COMMUNITY_EVENT_TYPE = 'event-type';
export const COMMUNITY_KEY = 'cty';
export const CREATE_LOCATION = 'create-location';
export const EDIT_LOCATION = 'edit-location';
export const DELETE_LOCATION = 'delete-location';
export const LOCATION_DETAILS = 'location-details';
export const EDIT_EVENT = 'edit-event';

// people keys
export const JOB_KEY = 'j';

export const FROM_KEY = 'from';
export const NOTIFICATION_KEY = 'notif';

export const EMAIL_KEY = 'email';

export const EDIT_BADGE = 'edit-badge';

export const EDIT_EXP_EVENT = 'edit-exp-event';
