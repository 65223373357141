export const NEED_DIALOG = 'need-dialog';
export const EMPLOYEE_OF_THE_DAY_DIALOG = 'employee_of_day_received';
export const BUSINESS_UNIT_DIALOG = 'bu-dialog';
export const BUSINESS_UNIT_MEMBER_DIALOG = 'bu-member-dialog';
export const BUSINESS_UNIT_INDICATOR_DIALOG = 'bu-indicator-dialog';
export const WELCOME_SCREEN_DIALOG = 'welcome-screen-dialog';

// action
export const ACTION_DIALOG = 'action-dialog';

// application
export const APPLICATION_DETAILS = 'application-details';
