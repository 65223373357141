const user: Record<string, string> = {
  'user.update.success': 'Update user with success',
  'error.update.user': 'Error on update user',
  'user.update.motto.success': 'Motto updated successfully',
  'user.update.motto.error': 'Failed to update Motto',
  'error.create.motto': 'Error on create motto',
  'user.picture.delete.successfully': 'Picture deleted successfully',
  'user.delete.presentation.video': 'Presentation video deleted successfully',
  'user.change.supervisor.success': 'Supervisor updated successfully',
  'user.label.see.company.structure': 'See company structure',
  'user.hierarchy.download.label': 'Download as JPG',
  'user.hierarchy.company.structure.label': 'Company structure :',
  'user.double.click.to.see.more': 'Double click to see more about {name}',
  'user.hiring.date': 'Hiring date',
  'user.label.user.access': 'User access ',
  'user.label.user.role': ' User role',
  'user.already.signed.up': 'You already signed up. Login to your account to continue',
  'user.personal.information': 'Personal Information',
  'user.detail.information': 'Information details',
  'user.select.collaborator': 'Select collaborator',

  'user.button.tootlip.title.company.structure':
    'Here you can find the flowchart of your business unit',

  //invitation
  'user.label.email.invitation.sent': 'Invitation sent with success!',
  'user.label.invitation.not.yet.accepted': 'Invitation not yet accepted, resend invitation ?',
  'error.label.invite.user': 'An error occured while sending user invitation',

  'user.label.people.found': '{total} peoples corresponds to the search',
  'user.label.company.structure': 'Company structure',

  'user.event': 'User event',
};

export default user;
