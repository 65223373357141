const admin: Record<string, string> = {
  'admin.label.company.logo': "Logo de l'entreprise",
  'admin.label.company.mission': "Mission de l'entreprise",
  'admin.label.company.romance': "Romance de l'entreprise",
  'admin.label.company.name': "Nom de l'entreprise",
  'admin.label.company.description': "Description de l'entreprise",
  'admin.label.company.email': "Email de l'entreprise",
  'admin.label.company.upload.logo': "AJouter le logo de l'entreprise",
  'admin.label.company.slug': 'Company slug',
  'admin.label.company.update.success': 'Entreprise mise à jour avec succès !',
  'admin.label.company.update.error': "Erreur durant la mise à jour de l'entreprise !",
  'admin.label.company.reporting': 'Rapports',
  'admin.label.company.download.excel': "Télécharger le rapport d'entreprise au format Excel",
  'admin.label.company.image.repository': "Référentiel d'images",
  'admin.label.delete.image': "Supprimer l'image",
  'admin.label.large.file': 'Le fichier fait plus de 10Mb',
  'admin.dialog.image.deleted.success': 'Image supprimé avec succès',
  'admin.dialog.image.deleted.error': "Erreur durant la suppression de l'image",
  'admin.dialog.user.access.required': 'Accès utilisateur requis',
  'admin.dialog.member.role.required': 'Rôle de membre requis',
  'admin.dialog.set.welcom.screen.success': "L'écran de bienvenu à été choisi avec suucès !",
  'admin.dialog.set.welcom.screen.error': "Erreur durant le changement d'écran de bienvenu !",
  'admin.dialog.confirm.delete.user':
    'Voulez-vous vraiment supprimer {companyMemberEmail} de votre entreprise ?',
  'admin.button.label.shuffle': 'Mélanger',
  'admin.button.label.force': 'Forcer',
  'admin.label.employee': 'Employé',
  'admin.label.date': 'Date',
  'admin.label.email': 'Email',
  'admin.label.status': 'Status',
  'admin.update.rank.success': 'Classement mis à jour avec succès',
  'admin.update.rank.error': 'Erreur durant la mise à jour du classement',
  'admin.label.waiting': 'En attente',
  'admin.label.pending': 'En cours',
  'admin.label.accepted': 'Accepté',
  'admin.label.decline': 'Decliné',
  'admin.update.rank.status.error': 'Cet employé ne peut pas être changé',
  'admin.employee.of.the.day.forced.success': 'Employé du jour forcé avec succès',
  'admin.employee.of.the.day.forced.error': "Erreur durant le forcage de l'employé du jour",
  'admin.employee.of.the.day.forced.status.error': 'Cet employé ne peut pas être forcé',
  'admin.label.company.indicators': "Indicateurs d'entreprise",
  'admin.label.company.add.new.indicator': 'Ajouter un nouvel indicateur',
  'admin.label.delete.goal': 'Supprimer un indicateur',
  'admin.label.delete.goal.success': 'Indicateur supprimè avec succès',
  'admin.label.delete.goal.error': "Erreur durant la suppression de l'indicateur",
  'admin.label.our.shared.orizon': 'Notre horizon commun',
  'admin.label.set.as.welcome.screen': "Définir comme écran d'accueil",
  'admin.label.no.word.from.ceo': 'Aucun mot du PDG',
  'admin.word.from.ceo': 'Mot du PDG',
  'admin.word.from.ceo.update.success': 'Mot du PDG mise à jour avec succès !',
  'admin.word.from.ceo.update.error': 'Erreur durant la mise à jour du mot du PDG !',
  'admin.placeholder.ceo.word': 'Tapez ici le mot de PDG',
  'admin.tabs.button.tooltip.title':
    "L'Admin est un espace de gestion pour les utilisateurs disposant d'un accès « Admin » à l'instance Orizon de l'entreprise",

  /** Hive */
  'admin.hive.post.no.item': 'Pas encore de poste',
  'admin.hive.post.no.result': 'Aucun résultat sur la liste des publications',
  'admin.hive.delete.post.title': 'Supprimer le poste',
  'admin.hive.update.post.title.visibility': 'Modifier la visibilitée du post',
  'admin.hive.request.post.title': 'Supprimer le rapport du post',
  'admin.hive.delete.post.description': 'Voulez-vous supprimer le poste nommé "{name}"',
  'admin.hive.delete.post': 'Poste "{name}" supprimé',
  'admin.hive.update.post.description': 'Voulez-vous mettre à jour la visibilitée du post "{name}"',
  'admin.hive.request.post.description': 'Voulez-vous supprimer le rapport remonté sur le post "{name}"',
  'admin.hive.idea.delete.title': "Supprimer l'idée",
  'admin.hive.idea.update.title.visibility': 'Mettre à jour la visibilité des idées',
  'admin.hive.idea.delete.description': 'Voulez-vous supprimer l\'idée nommée "{name}"',
  'admin.hive.idea.update.description':
    'Voulez-vous mettre à jour la visibilité de l\'idée nommée "{name}"',

  'admin.hive.manage.categories': 'Gérer les catégories',
  'admin.hive.category.placeholder': 'Tapez une catégorie',
  'admin.hive.idea.categories.title': 'Gérer les catégories des idées',
  'admin.hive.issue.categories.title': 'Gérer les catégories des problémes',
  'admin.hive.advice.categories.title': 'Gérer les catégories des conseils',
  'admin.hive.question.categories.title': 'Gérer les catégories des questions',
  'admin.hive.delete.category': 'Supprimer "{title}"',
  'admin.hive.categories.list.title': 'Catégories',
  'admin.hive.deleted.post': 'Post supprimè avec succès',
  'admin.hive.deleted.request': 'Rapport supprimè avec succès',
  'admin.hive.deleted.post.error': 'Erreur durant la suppression du post',
  'admin.hive.deleted.request.error': 'Erreur durant la suppression du rapport',
  'admin.hive.update.visibility.post.success': 'Visibilité du post mise à jour avec succès',
  'admin.hive.update.visibility.post.error': 'Erreur durant le changement de visibilité du post',
  'admin.hive.communities.sharing': 'Communautés de partage',
  'admin.hive.members.sharing': 'Membres pour le partage',

  /** Tunnel */
  'admin.tunnel.placeholder.search.director': 'Rechercher un directeur',
  'admin.tunnel.director.setted.success': "Directeur de l'entreprise nommé avec succès",

  /** User */
  'admin.user.no.item': 'Aucun donnée',
  'admin.user.no.result': 'Aucun résultat sur la liste des utilisateurs',

  'admin.member.has.been.restored': 'Le membre a été restauré',

  'admin.badge.edit.event': "Modifier l'événement",
  'admin.badge.delete.event': "Supprimer l'événement",
  'admin.event.no.result': 'Aucun résultat sur la liste des événements',
};
export default admin;
