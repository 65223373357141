const meeting: Record<string, string> = {
  'meeting.label.join.meeting': 'Rejoindre la réunion',
  'meeting.label.end.meeting': 'Fin de la réunion',
  'meeting.label.loading_zoom_meetings': 'Chargement des réunions planifiées...',
  'meeting.label.on.going.meeting': 'Réunion en cours...',
  'meeting.dialog.label.create': 'Créer une réunion',
  'meeting.dialog.label.update': 'Mettre à jour une réunion',
  'meeting.dialog.label.deleted': 'Supprimer une réunion',
  'meeting.dialog.label.accept': 'Réunion accéptée',
  'meeting.dialog.label.expired': 'Réunion expirée',
  'meeting.dialog.label.denied': 'Réunion refusée',
  'meeting.dialog.label.removed': 'Réunion supprimée',
  'meeting.dialog.label.deny': 'Réunion refusée',
  'meeting.label.add.coworker': 'Ajouter des collègues',
  'meeting.label.add.teams': 'Ajouter des équipes',
  'meeting.label.go_to_calendar': 'Aller au calendrier',
  'meeting.label.zoom.link': 'Lien du réunion',
  'meeting.label.ics': 'Fichier .ics de la réunion',
  'meeting.label.link.copied': 'Lien du réunion copié',
  'meeting.create.success': 'Réunion créée avec succès',
  'meeting.update.success': 'Réunion mise à jour avec succès',
  'meeting.cant.drag':
    'Vous ne pouvez pas modifier une réunion par glisser-déposer, veuillez cliquer sur le bouton Modifier à la place',
  'meeting.create.congrats':
    'Votre réunion a été planifiée avec succès, les invitations sont envoyées aux participants',
  'meeting.update.congrats':
    'Votre réunion a été mise à jour avec succès, les invitations sont envoyées aux participants',
  'meeting.team.event.congrats':
    "Votre demande d'inscription à une réunion a été acceptée et votre événement de réunion a été créé avec succès",
  'meeting.expired.details': 'La réunion a été supprimée ou a expiré ',
  'meeting.denied.details': 'La réunion a été refusée ou elle est peut être expirée ',
  'meeting.removed.details': 'La réunion a été supprimée ',
  'meeting.team.event.deny': 'Votre invitation à la réunion a été refusée avec succès',

  'meeeting.accept.success': "Votre demande d'inscription à une réunion a été acceptée",
  'meeting.deny.success': "Votre demande d'inscription à une réunion a été refusée",

  // Auth
  'meeting.label.auth.success': 'Authentification Zoom',
  'meeting.label.auth.success.detail': 'Super, vous êtes maintenant authentifié à Zoom',

  // Placeholder
  'meeting.placeholder.title': 'Titre de la réunion',
  'meeting.placeholder.timezone': 'Sélectionnez un fuseau horaire',
  'meeting.placeholder.pass.code': 'Code secret de la réunion',
  'meeting.placeholder.description': 'Dis quelque chose à propos de la réunion',

  'meeting.failed.to.delete': 'Désolé, la réunion a expiré',
  'meeting.failed.to.delete.more':
    "La réunion est introuvable ou a expiré, mais votre événement d'équipe a été supprimé",

  // Prefilled values
  'meeting.from.hive': 'Réunion planifiée de {creator}',
  'meeting.from.team': "Réunion d'équipe : ",
  'meeting.from.feedback': 'Réunion de restitution : ',
  'meeting.from.a3': 'Réunion de probléme: ',
  'meeting.from.one.to.one': 'Rencontre en tête-à-tête : ',

  'meeting.current.timezone': 'Votre fuseau horaire actuel',
  'meeting.current.timezone.label': 'Date de réunion en fonction de votre fuseau horaire actuel',
  'meeting.registrants.and.creator': 'Inscrits et créateur',
};
export default meeting;
