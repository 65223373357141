const a3: Record<string, string> = {
  'a3.label.navigate.to': 'Navigate to A3',
  'a3.label.use.a3.process': 'Use A3 Process',
  // success
  'a3.success.create.followup': 'Follow up created successfully',
  'a3.success.update.followup': 'Follow up updated successfully',
  'a3.success.delete.followup': 'Follow up deleted successfully',
  'a3.success.create.rootCause': 'Root cause created successfully',
  'a3.success.update.rootCause': 'Root cause updated successfully',
  'a3.success.delete.rootCause': 'Root cause deleted successfully',
  'a3.success.create.comment': 'Comment created successfully',
  'a3.success.create.analysisWhy': 'Analysis why created successfully',
  'a3.success.update.analysisWhy': 'Analysis why updated successfully',
  'a3.success.delete.analysisWhy': 'Analysis why deleted successfully',
  'a3.success.create.compare': 'Analysis compare created successfully',
  'a3.success.update.compare': 'Analysis compare updated successfully',
  'a3.success.delete.compare': 'Analysis compare deleted successfully',

  // error
  'a3.error.create.followup': 'Error on create follow up',
  'a3.error.update.followup': 'Error on update follow up',
  'a3.error.delete.followup': 'Error on delete follow up',
  'a3.error.create.rootCause': 'Error on create root cause',
  'a3.error.update.rootCause': 'Error on update root cause',
  'a3.error.delete.rootCause': 'Error on delete root cause',
  'a3.error.create.comment': 'Error on create comment',
  'a3.error.create.analysisWhy': 'Error on create analysis why',
  'a3.error.update.analysisWhy': 'Error on update analysis why',
  'a3.error.delete.analysisWhy': 'Error on delete analysis why',
  'a3.error.create.compare': 'Error on create analysis compare',
  'a3.error.update.compare': 'Error on update analysis compare',
  'a3.error.delete.compare': 'Error on delete analysis compare',

  // dialog
  'a3.dialog.edit.title.followup': 'Edit follow up',
  'a3.dialog.delete.title.followup': 'Delete follow up',
  'a3.dialog.edit.title.rootCause': 'Edit root cause',
  'a3.dialog.delete.title.rootCause': 'Delete root cause',
  'a3.dialog.delete.title.goal': 'Delete indicator',
  'a3.dialog.delete.title.action': 'Delete action',
  'a3.dialog.delete.title.why': 'Delete why',
  'a3.dialog.delete.title.compare': 'Delete compare',

  // objective
  'a3.objective.create.success': 'Objective successfully created',
  'a3.objective.delete.description': 'Delete Objective',
  'a3.counter.measure.delete.description': 'Delete counter measure ?',
  'a3.objective.delete.success': 'Objective successfully deleted',
  'a3.objective.update.success': 'Objective successfully updated',
  'a3.objective.edit.your.root.cause.s': 'Edit your root cause(s)',

  // Counter measure
  'a3.counter.measure.create.success': 'Proposal counter measure created successfully',
  'a3.counter.measure.delete.success': 'Proposal counter measure deleted successfully',
  'a3.counter.measure.update.success': 'Proposal counter measure updated successfully',
  'a3.post.change.status.success': 'Issue a3 status changed successfully',

  // Goal
  'a3.goal.delete.success': 'Indicator deleted successfully',

  // input
  'a3.input.create.title.why': 'Your why',
  'a3.input.edit.title.why': 'Edit why',
  'a3.input.create.title.compare.subject1': 'Success subject',
  'a3.input.create.title.compare.subject2': 'Failed subject',
  'a3.input.edit.title.compare.subject1': 'Edit success subject',
  'a3.input.edit.title.compare.subject2': 'Edit failed subject',
  // Plan A3
  'a3.goal.add.new': 'Add new indicator',
  'a3.project.add.new': 'Add new project',
  'a3.action.add.new': 'Add new action',
  'a3.sub.action.title': 'Sub-action',

  // precise.description
  'a3.problem.precise.description': 'Issue information',
  'a3.problem.precise.description.title': 'Issue precise description?',
  'a3.problem.precise.description.of': 'Precise description of issue',
  'a3.precise.description.image.deleted': 'Issue image deleted successfully',
  'a3.precise.description.image.upload': 'Issue file uploaded successfully',
  'a3.issue.precise.description.drop.upload.files': 'Drop picture or video to upload or',
};
export default a3;
