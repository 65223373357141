import { DefaultTheme, makeStyles } from '@mui/styles';

export default makeStyles<DefaultTheme, { path?: string }>((theme) => ({
  root: {
    display: 'flex',
    alignContent: 'center',
    zIndex: 1301,

    height: '100vh',
    backgroundColor: theme.palette.background.default,
    backgroundImage: ({ path }) => (path ? `url(${path})` : theme.palette.background.default),

    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center',
    backgroundSize: 'cover',

    '& .MuiDialog-paperFullScreen': {
      backgroundColor: 'transparent',
    },

    cursor: 'pointer',
  },

  welcomeWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',

    width: '100vw',
    height: '100vh',
  },

  label: {
    textAllign: 'center',
    font: 'normal normal bold 8.063rem/11.188rem Poppins',
    letterSpacing: '-3.11px',
    color: theme.palette.common.white,
    textShadow: '0px 3px 6px #00000029',
    opacity: '1',
    textTransform: 'uppercase',
  },

  companyName: {
    textAllign: 'center',
    font: 'normal normal bold 3.25rem/4.5rem Poppins',
    letterSpacing: '-3.11px',
    color: theme.palette.common.white,
    textShadow: '0px 3px 6px #00000029',
    opacity: '1',
    marginTop: -45,
  },

  message: {
    position: 'fixed',
    bottom: 75,
    width: '100%',

    display: 'flex',
    justifyContent: 'center',
  },
}));
