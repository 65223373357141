import { gql } from '@apollo/client';

export const INDICATOR_FRAGMENT = gql`
  fragment IndicatorInfo on Indicator {
    id
    name
    date
    description
    value
    action
    trend
    level
    createdAt
  }
`;

export const MAP_INDICATOR_FRAGMENT = gql`
  fragment MapIndicatorInfo on Indicator {
    id
    name
    action
    trend
    level
    value
  }
`;
export const INDICATOR_HISTORY_FRAGMENT = gql`
  fragment IndicatorHistoryInfo on IndicatorHistory {
    id
    nameFrom
    nameTo
    dateFrom
    dateTo
    descriptionFrom
    descriptionTo
    valueFrom
    valueTo
    actionFrom
    actionTo
    trendFrom
    trendTo
    levelFrom
    levelTo
    createdAt
  }
`;
