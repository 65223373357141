import { gql } from '@apollo/client';
import { ACTION_FRAGMENT } from 'graphql/community/fragment';
import { MISSION_FRAGMENT } from 'graphql/oneToOne/fragment';

export const MY_COMPANIES = gql`
  query GetMyCompanies {
    me {
      id
      companyMembers {
        id
        suspended
        company {
          id
          slug
          logo
          logoPublicUrl
        }
      }
    }
  }
`;

export const COWORKERS = gql`
  query GetCoworkers {
    coworkers {
      id
      fullName
      firstName
      lastName
      photoUrl
      photoPublicUrl
      birthday
      companyMembers {
        currentMotto {
          id
          content
        }
        currentProfessionnalWeather {
          id
          icon
          title
        }
        id
        company {
          id
          name
        }
      }
      communities {
        id
        community {
          id
          name
          slug
          logo
          logoPublicUrl
        }
      }
    }
  }
`;

export const GET_MISSIONS_ACTIONS_BY_COMPANY_MEMBER_ID = gql`
  query GetMissionsActionsByCompanyMember($id: ID!) {
    companyMember: getCompanyMemberById(id: $id) {
      id
      user {
        id
        fullName
        photoUrl
        photoPublicUrl
        firstName
        lastName
        myCommunity {
          id
        }
      }
      myOneToOneActions {
        ...ActionInfo
        responsibles {
          id
          user {
            id
            fullName
            photoUrl
            photoPublicUrl
            firstName
            lastName
          }
        }
        roadBlock {
          id
          roadBlockId
        }
      }
      myOneToOneMissions {
        ...MissionInfo
        community {
          id
          logo
          logoPublicUrl

          name
        }
        experts {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        companyMember {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
      }
      myCommunities {
        id
        logo
        logoPublicUrl

        name
      }
    }
  }
  ${ACTION_FRAGMENT}
  ${MISSION_FRAGMENT}
`;

export const GET_UNFINISHED_ACTIONS_BY_CM = gql`
  query GetUnfinishedActionsByCM($id: ID!) {
    unfinishedActionsByCM: getCompanyMemberById(id: $id) {
      id
      myUnfinishedActions {
        ...ActionInfo
      }
    }
  }
  ${ACTION_FRAGMENT}
`;

export const GET_MY_THANKS_RECEIVED_TODAY = gql`
  query GetMyThanksReceivedToday {
    getMyThanksReceivedToday {
      id
      score
      content
      createdAt
      companyMemberSender {
        id
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
        }
      }
    }
  }
`;

export const GET_MY_CURRENT_IDS = gql`
  query GetMyCurrentIds {
    me {
      id
      currentCompanyMember {
        id
        myCommunities {
          id
        }
      }
      myCommunity {
        id
      }
    }
  }
`;

export const ME = gql`
  query GetMe {
    me {
      id
      firstName
      lastName
      fullName
      photoUrl
      photoPublicUrl
      mySharedHorizon
      bio
      email
      birthday
      familySituation
      isOnline
      personality
      adress
      phone
      skills {
        id
        title
      }
      successes {
        id
        title
      }
      passions {
        id
        title
      }
      previousCompanies {
        id
        name
      }
      pictures {
        id
        title
        imageUrl
        imagePublicUrl
      }
      presentationVideo {
        id
        publicUrl
      }
      communities {
        id
        community {
          id
          companyId
          name
        }
      }
      currentCompanyMember {
        id
        jobTitle
        isFirstEntry
        hiringDate
        userAccess
        role
        businessUnitRole
        exp
        expLevel {
          id
          name
          iconPublicUrl
        }
        businessUnit {
          id
          name
          logo
          logoPublicUrl
        }
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
          email
        }
        currentMotto {
          id
          content
        }
        nPlusOne {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        currentProfessionnalWeather {
          id
          title
          description
          icon
        }
        currentProfessionalStatus {
          title
          order
          id
          icon
          description
        }
      }
    }
  }
`;

export const GET_ME_WELCOME_BACK = gql`
  query GetMeWelcomeBack {
    me {
      id
      firstName
      lastName
      photoUrl
      photoPublicUrl
      currentCompanyMember {
        id
        isFirstEntry
      }
    }
  }
`;

export const GET_ME_NOIF = gql`
  query GetMeNotif {
    me {
      id
      fullName
      firstName
      lastName
    }
  }
`;

export const GET_ME_ONE_TO_ONE = gql`
  query GetMeOneToOne {
    me {
      id
      firstName
      lastName
      fullName
      photoUrl
      photoPublicUrl
      currentCompanyMember {
        id
        isManager
        currentProfessionnalWeather {
          icon
        }
        nPlusOne {
          id
        }
        nMinusOnes {
          id
        }
      }
    }
  }
`;

export const GET_TWO_FACTOR_AUTHENTICATION = gql`
  query GetTwoFactorAuthentication {
    me {
      id
      is2FA
    }
  }
`;

export const GET_PASSWORD = gql`
  query GetPassword {
    hashedPassword: getPassword
  }
`;

export const GET_USER_BY_ID = gql`
  query GetUserById($id: ID!) {
    user: User(id: $id) {
      id
      firstName
      lastName
      photoUrl
      photoPublicUrl
      mySharedHorizon
      bio
      email
      birthday
      familySituation
      isOnline
      personality
      adress
      phone
      skills {
        id
        title
      }
      successes {
        id
        title
      }
      passions {
        id
        title
      }
      previousCompanies {
        id
        name
      }
      pictures {
        id
        title
        imageUrl
        imagePublicUrl
      }
      presentationVideo {
        id
        publicUrl
      }
      communities {
        id
        community {
          id
          companyId
          name
        }
      }
      currentCompanyMember {
        id
        jobTitle
        isFirstEntry
        hiringDate
        userAccess
        role
        businessUnitRole
        businessUnit {
          id
          name
          logo
          logoPublicUrl
        }
        user {
          id
          fullName
          firstName
          lastName
          photoUrl
          photoPublicUrl
          email
        }
        currentMotto {
          id
          content
        }
        nPlusOne {
          id
          user {
            id
            fullName
            firstName
            lastName
            photoUrl
            photoPublicUrl
          }
        }
        currentProfessionnalWeather {
          id
          title
          description
          icon
        }
      }
    }
  }
`;
