import { FieldFunctionOptions, FieldMergeFunction, TypePolicy } from '@apollo/client';
import uniqBy from 'lodash/uniqBy';

import {
  CACHE_KEY,
  COMMUNITY_ID_CACHE_KEY,
  IS_COMPANY_MEMBER_ID_CACHE_KEY,
  FILTER_CACHE_KEY,
  ID_KEY,
  POST_TYPE_CACHE_KEY,
  TEAM_ID_CACHE_KEY,
  PROJECT_ID_CACHE_KEY,
  POST_ID_CACHE_KEY,
} from 'constant';
import { GetCalendarEventsPerDayQuery } from 'graphql/generated/graphql';

export const merge:
  | boolean
  | FieldMergeFunction<any, any, FieldFunctionOptions<Record<string, any>, Record<string, any>>>
  | undefined = (existing = [], incoming, { args }) => {
  const offset = args?.page ?? 0;
  const merged = existing ? existing.slice(0) : [];

  for (let i = 0; i < incoming.length; ++i) {
    merged[offset + i] = incoming[i];
  }

  return uniqBy(merged, '__ref');
};

// TODO: find better way
export const calendarMerge:
  | boolean
  | FieldMergeFunction<
      GetCalendarEventsPerDayQuery['calendarEventsPerDay'] | undefined,
      GetCalendarEventsPerDayQuery['calendarEventsPerDay'] | undefined,
      FieldFunctionOptions<Record<string, any>, Record<string, any>>
    >
  | undefined = (_, incoming) => {
  return incoming;
};

export const Query: TypePolicy = {
  fields: {
    currentCompanyIdeas: {
      // Don't cache separate results based on
      // any of this field's arguments.
      keyArgs: false,
      // Concatenate the incoming list items with
      // the existing list items.
      merge,
    },
    isFiltersApplied: {
      keyArgs: [POST_TYPE_CACHE_KEY],
      merge: false,
    },
    currentCompanyProblems: {
      keyArgs: [COMMUNITY_ID_CACHE_KEY],
      merge,
    },
    currentCompanyAdvice: {
      keyArgs: false,
      merge,
    },
    currentCompanyFuns: {
      keyArgs: false,
      merge,
    },
    currentCompanyStories: {
      keyArgs: false,
      merge,
    },
    currentCompanyProcess: {
      keyArgs: false,
      merge,
    },
    getNotificationByCompanyMemberId: {
      keyArgs: false,
      merge,
    },
    coworkersMember: {
      keyArgs: [CACHE_KEY],
      merge,
    },
    getCommunityIndicators: {
      keyArgs: [COMMUNITY_ID_CACHE_KEY],
      merge,
    },
    allActions: {
      // $filter is an input type argument
      // with subfield teamId or projectId or postId or isMyAction
      keyArgs: [
        FILTER_CACHE_KEY,
        [TEAM_ID_CACHE_KEY, IS_COMPANY_MEMBER_ID_CACHE_KEY],
        PROJECT_ID_CACHE_KEY,
        POST_ID_CACHE_KEY,
      ],
      merge,
    },
    getProjectsByCommunity: {
      keyArgs: [ID_KEY],
      merge,
    },
    allLeaves: {
      keyArgs: false,
      merge,
    },
    calendarEventsPerDay: {
      keyArgs: false,
      merge: calendarMerge,
    },
    calendarCommunityEvents: {
      keyArgs: false,
      merge: merge,
    },
    eventsByCalendarFilterHistory: {
      keyArgs: false,
      merge: merge,
    },
    peoples: {
      keyArgs: false,
      merge: merge,
    },
    allSemiInstantCalls: {
      keyArgs: false,
      merge: merge,
    },
    unSeenNotifications: {
      keyArgs: false,
      merge,
    },
    seenNotifications: {
      keyArgs: false,
      merge,
    },
    myMeetings: {
      keyArgs: false,
      merge,
    },
    getNMinusOnes: {
      keyArgs: false,
      merge,
    },
  },
};
