import { NavTab, AdminTabs, DatabaseUrlTabs } from 'types';

export interface AppRoute {
  [key: string]: {
    path: string;
    description?: string;
    sub?: AppRoute;
  };
}

export const SITE_MAP = Object.freeze({
  Login: {
    path: '/',
  },
  SignUp: {
    path: '/signup',
  },
  ForgotPassword: {
    path: '/forgot-password',
  },
  ResetPassword: {
    path: '/reset-password/:token',
  },
  Client: {
    path: '/client',
    sub: {
      SwitchCompany: {
        path: 'switch',
      },
      NoCompany: {
        path: 'no-company',
      },
      CompanyLayout: {
        /*  path=":company" has no trailing "*".
            This means if you navigate deeper, the parent won't match anymore and 
            therefore the child routes will never render.
            So we change it to path=":company/*"
        */
        path: ':company/*',
        sub: {
          WelcomScreen: {
            path: 'welcome-screen',
          },
          Ruche: {
            path: NavTab.HIVE,
          },
          OneToOne: {
            path: NavTab.ONE_TO_ONE,
            sub: {
              OneToOne: {
                path: '',
              },
              OneToOneDetail: {
                path: ':with',
              },
            },
          },
          Map: {
            path: NavTab.MAP,
          },
          People: {
            path: NavTab.PEOPLE,
            sub: {
              People: {
                path: '',
              },
              CompanyHierarchy: {
                path: 'hierarchy',
              },
            },
          },
          Teams: {
            path: NavTab.TEAMS,
            sub: {
              Team: {
                path: '',
              },
              A3: {
                index: 'a3',
                path: 'a3/:postId',
              },
            },
          },
          Calendar: {
            path: NavTab.CALENDAR,
          },
          Rules: {
            path: NavTab.RULES,
          },
          Issues: {
            path: NavTab.ISSUES,
          },
          Database: {
            path: NavTab.DATABASE,
            sub: {
              Action: {
                path: '',
              },
              Issue: {
                path: DatabaseUrlTabs.Issue,
              },
            },
          },
          Admin: {
            path: NavTab.ADMIN,
            sub: {
              Company: {
                path: AdminTabs.COMPANY,
              },
              TeamAdmin: {
                path: AdminTabs.TEAMS,
              },
              Members: {
                path: AdminTabs.USERS,
              },
              EmployeeOfDay: {
                path: AdminTabs.EMPLOYEE_OF_DAY,
              },
              Shop: {
                path: AdminTabs.SHOP,
              },
              Hive: {
                path: AdminTabs.HIVE,
              },
              Tunnels: {
                path: AdminTabs.TUNNELS,
              },
              Coach: {
                path: AdminTabs.COACH,
              },
              Points: {
                path: AdminTabs.POINTS,
              },
              BusinessUnits: {
                path: AdminTabs.BUSINESS_UNITS,
                sub: {
                  BusinessUnitDetail: {
                    path: ':businessUnitId',
                  },
                },
              },
              Subscription: {
                path: AdminTabs.SUBSCRIPTION,
              },
              Application: {
                path: AdminTabs.APPLICATION,
              },
              Calendar: {
                path: AdminTabs.CALENDAR,
              },
              Badges: {
                path: AdminTabs.BADGES,
              },
            },
          },
          ExitTunnel: {
            path: 'exit-tunnel',
          },
          Shop: {
            path: 'shop',
          },
          Profile: {
            path: 'profile',
          },
          UserPreference: {
            path: 'user-preference',
          },
        },
      },
    },
  },
});
