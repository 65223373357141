const eod: Record<string, string> = {
  // label
  "eod.label.employee.of.the.day.offer": "Offre de l'employé du jour",
  "eod.label.already.accepted": "Déjà accepté",
  "eod.label.force": "Forcé",

  // content
  "eod.content.inform.chosen.tomorrow.eod":
    "Vous avez été choisi pour être « l'employé du jour » de demain - {date}, assurez-vous de mettre à jour votre profil car tout le monde le verra dans le tunnel d'entrée. Si vous refusez, vous ne serez choisi à nouveau qu'après un certain temps.",

  // success
  "eod.snackbar.success.accept": "Employé du jour accepté avec succès",
  "eod.snackbar.success.decline": "L'employé du jour a été décliné avec succès",

  // errors
  "eod.snackbar.error.accept": "Erreur lors de l'acceptation de l'employé du jour",
  "eod.snackbar.error.decline": "Erreur lors du refus de l'employé du jour",
};
export default eod;
