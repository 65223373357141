import { Notification } from 'graphql/generated/graphql';
import { IntlShape } from 'react-intl';

export const getNotificationArguments = (
  notification: Pick<Notification, 'parametres'>,
  isDescription?: boolean,
): Record<string, string> => {
  let args: Record<string, string> = { b: 'chunks => <b>{chunks}</b>' };

  notification?.parametres?.forEach((argument, index) => {
    const nameArgument: string = 'arg' + (index + 1).toString();
    args[nameArgument] = argument ? (isDescription ? `<b>${argument}</b>` : argument) : '';
  });
  return args;
};

export const getNotificationTitle = (
  intl: IntlShape,
  notification: Pick<Notification, 'title' | 'parametres'>,
): string => {
  let args = getNotificationArguments(notification);
  return intl.formatMessage({ id: notification?.title ?? '' }, args) as string;
};

export const getNotificationDescription = (
  intl: IntlShape,
  notification: Pick<Notification, 'description' | 'parametres'>,
): string => {
  let args = getNotificationArguments(notification, true);
  return intl.formatMessage({ id: notification?.description ?? '' }, args) as string;
};

// Handle dynamicaly the args from push notification
export const getPushNotificationArguments = (data: Record<string, string>) => {
  const args: Record<string, string> = {};

  Object.keys(data).forEach((key) => {
    if (key === 'b') {
      // Directly include HTML in the value for 'b'
      args[key] = `<b>${data[key]}</b>`;
    } else {
      args[key] = data[key];
    }
  });

  return args;
};
