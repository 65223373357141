const dialog: Record<string, string> = {
  'coin.history': 'Coin history',
  'coin.type.credited': 'credited',
  'coin.type.debited': 'debited',
  'coin.title': "You've been {type} with {coin} coin(s) from",
  'coin.from': 'coin(s) from',
  'coin.for': 'coin(s) for',
};

export default dialog;
