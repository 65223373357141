const authentification: Record<string, string> = {
  'auth.signup.title': 'Sign Up',
  'auth.signup.label.firstname': 'Firstname',
  'auth.signup.label.lastname': 'Lastname',
  'auth.signup.label.email': 'Email',
  'auth.signup.label.password': 'Password',
  'auth.signup.label.confirmPassword': 'Confirm Password',

  'auth.signin.title': 'Sign In',
  'auth.signin.label.connection': 'Log In',
  'auth.signin.label.wimtim.connection': 'Please login to your WimTim account!',
  'auth.signin.label.email.address': 'Email address',
  'auth.signin.label.remember.me': 'Remember me',

  'auth.reset.label.create.password': 'Creating a new password',
  'auth.reset.label.new.password': 'New password',
  'auth.reset.label.confirm.password': 'Confirm password',
  'auth.alert.label.no.company': 'You are not yet a member in a company',
  'auth.alert.label':
    'Account does not exist or User not found, please check your email address or Incorrect Password',
  'auth.alert.label.access.error':
    'Your access to the platform has been suspended or removed, please talk with the WimTim administrator of your company.',
  'auth.alert.label.access.max.error':
    'Your account has been locked due to too many failed attempts.Please try to sign in again after {countDown} min',
  'auth.alert.label.error.password':
    '{number} login attempts left, and you will have to wait for 30 minutes',
  'auth.change.my.password': 'Change my password',
  'auth.change.my.password.updated': 'Password updated with success.',
  'auth.change.my.password.error': 'Error on update password',

  'auth.two.factor.authentication.success': 'Successful authentication',
  'auth.two.factor.authentication.error': 'Authentication error',
  'auth.two.factor.activation.success': 'Two-factor authentication actived successfully',
  'auth.two.factor.disabling.success': 'Two-factor authentication disabled successfully',

  'auth.send.email.new.password': 'We have sent you an email to reset your password',

  'auth.alert.removed.user': 'You are removed for this platform',
  'auth.alert.suspended.user': 'You are suspended for this platform',

  'auth.alert.deleted.info.user':
    'You no longer have access to this company’s space. Please contact the <a>administrator</a>.',

  'auth.alert.suspended.info.user':
    "Your access to this company's space has been suspended. Please contact the <a>administrator</a>.",
};
export default authentification;
