import { useApolloClient } from '@apollo/client';
import { useIntl } from 'react-intl';
import { displaySnackBar } from '../../../utils/snackBarUtils';

export declare type PrimitiveType = string | number | boolean | null | undefined | Date;

export const useDisplaySnackBar = () => {
  const intl = useIntl();
  const client = useApolloClient();

  const customDisplaySnackBar = (
    id: string,
    defaultMessage?: string,
    type?: 'success' | 'error' | 'warning' | 'info',
    values?: Record<string, PrimitiveType>,
  ) => {
    displaySnackBar(client, {
      message: intl.formatMessage(
        {
          id,
          defaultMessage: defaultMessage ?? '',
        },
        values,
      ),
      type: type ?? 'success',
      isOpen: true,
    });
  };

  return customDisplaySnackBar;
};
