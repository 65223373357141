// INDIVIDUAL BADGEDS
export enum TIndividualBadgeName {
  // Achievements badges
  GENEROUS = 'Generous',
  SOLUTION_PROVIDER = 'Solution Provider',
  LISTENING_GUY = 'Listening guy',
  ADVISOR = 'Advisor',

  // Special badges
  FUNNY = 'Funny',
  LEADER = 'Leader',
  DISCIPLINED = 'Disciplined',

  // Points collection badges
  NOVICE = 'Novice',
  TRAINEE = 'Trainee',
  BROWN_BELT = 'Brown belt',
  PROFICIENT = 'Proficient',
  GRAND_MASTER = 'Grand Master',
  ENLIGHTENED = 'Enlightened',
}

// COMMUNITY BADGEDS
export enum TCommunityBadgeName {
  // Achievements badges
  PROJECT_KILLER = 'Project Killer',
  QUICK = 'Quick',
  SUPER_SOLVER = 'Super Solver',
  CONNECTED_GUYS = 'Connected Guys',
  SUPER_STAR = 'Super Star',

  // Special badges
  HOME_SWEET_HOME = 'Home Sweet Home',
  GROWING_TREE = 'Growing tree',

  // Points collection badges
  LEARNERS = 'Learners',
  INTERMEDIATES = 'Intermediates',
  PERFORMING_TEAM = 'Performing team',
  SOLID_TEAM = 'Solid team',
  DREAM_TEAM = 'Dream team',
  ULTIMATE_SQUAD = 'Ultimate Squad',
}
