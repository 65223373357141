import { gql } from '@apollo/client';

export const ROADBLOCK_FRAGMENT = gql`
  fragment RoadBlock on RoadBlock {
    id
    roadBlockId
    description
    createdAt
  }
`;
export const MISSION_FRAGMENT = gql`
  fragment MissionInfo on Mission {
    id
    title
    deadline
    estimateDate
    priorityLevel
    progressStatus
    createdAt
  }
`;
export const FEEDBACK_FRAGMENT = gql`
  fragment FeedbackInfo on Feedback {
    id
    content
    response
    alreadySeen
    createdAt
    companyMemberSenderId
  }
`;

export const NEED_FRAGMENT = gql`
  fragment NeedInfo on Need {
    id
    title
    description
  }
`;
