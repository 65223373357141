import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { AdminTabs } from 'types';

import useAdminViewStyles from '../styles';
import useMenuTabsStyles from '../AdminMenuTabs/styles';

const AdminSkeleton = () => {
  const adminViewClasses = useAdminViewStyles();
  const menuTabsClasses = useMenuTabsStyles();

  return (
    <Box className={adminViewClasses.root}>
      <Box gridArea="header">
        <Skeleton variant="text" />
      </Box>

      <Box gridArea="tabs" width="clamp(200px, 15.6vw, 300px)">
        {Object.keys(AdminTabs).map((_, index) => (
          <Skeleton key={index} variant="rectangular" className={menuTabsClasses.tab} />
        ))}
      </Box>

      <Box gridArea="view" className={adminViewClasses.rightSection}></Box>
    </Box>
  );
};

export default AdminSkeleton;
