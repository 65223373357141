import * as htmlToImage from 'html-to-image';

// @ts-ignore
const createFileName = (extension = '', ...names) => {
  if (!extension) {
    return '';
  }

  return `${names.join('')}.${extension}`;
};

export const takeScreenShot = async (node: any) => {
  try {
    const dataURI = await htmlToImage.toJpeg(node);

    return {
      loading: dataURI ? false : true,
      error: false,
      errors: {},
      data: dataURI ?? {},
    };
  } catch (error) {
    return {
      loading: false,
      error: true,
      errors: error,
      data: {},
    };
  }
};

// TODO: s3 files are blocked by cors
export const snapShoot = async (fileName: string, node: HTMLElement | null) => {
  try {
    if (!node) return undefined;

    const fontEmbedCSS = await htmlToImage.getFontEmbedCSS(node);
    const blob = await htmlToImage.toBlob(node, {
      fontEmbedCSS,
      cacheBust: true,
      pixelRatio: 1,
    });

    if (!blob) return undefined;

    return new File([blob], fileName, {
      type: 'image/webp',
    });
  } catch (err) {
    return undefined;
  }
};

// @ts-ignore
const download = (image, { name = Date.now().toString(), extension = 'jpg' } = {}) => {
  let loading = true;
  const a = document.createElement('a');
  a.href = image;
  a.download = createFileName(extension, name);

  if (a.download) {
    a.click();
    loading = false;
  }

  return loading;
};

export const downloadScreenshot = (
  innerRef: React.RefObject<HTMLDivElement>,
  fileName?: string,
  extension?: string,
) => {
  let loading = true;

  takeScreenShot(innerRef.current)
    .then(({ data }) => {
      if (data) loading = download(data, { name: fileName, extension });
      return loading;
    })
    .catch(() => {
      loading = false;
    });

  return loading;
};
