const database: Record<string, string> = {
  'action.label.add': 'New Action',
  'action.search.for': 'Search action or keyWord',
  'database.action.no.item': 'No item',
  'database.action.no.result': 'No result',
  'database.delete.title': 'Delete action',
  'database.action.title.detail': 'Action Detail',
  'database.action.snackbar.checked.message': 'Action successfully checked',
  'database.action.snackbar.unchecked.message': 'Action successfully unchecked',
};

export default database;
