const businessunit: Record<string, string> = {
  "business-unit.search.no.result": "Aucune BU trouvée pour votre recherche",
  "business-unit.search.for": "Recherche de BU",
  "business-unit.no.item": "Pas de BU à montrer...",
  "business-unit.label.add.new": "Créer une BU",
  "business-unit.delete.label": "Supprimer une BU",
  "business-unit.remove-member.label": "Supprimer un membre de la BU",
  "business-unit.label.update": "Modifier la BU",
  "business-unit.label.add.member": "Ajouter un membre à la BU",
  "business-unit.label.indicators": "Indicateurs des BU",
  "business-unit.label.members": "Membres de la BU",
  "business-unit.no.specified": "Aucune BU spécifiée",
  "business-unit.label.delete-image": "Supprimer l'image de la BU",
  "business-unit.label.image": "Images de la BU",

  "business-unit.label.excedeed.member": "Cet BU peut avoir au maximum 15 membres",
  "business-unit.no.indicator":
    "Vous pouvez voir le KPI de votre BU ici, mais vous devez en {create} un en premier",
  "business-unit.no.images.start-label":
    "Il n'y a pas d'image dans votre référentiel, vous pouvez la déposer ici ou ",
  "business-unit.no.images.end-label": "de votre ordinateur",
};
export default businessunit;
