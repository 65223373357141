const tabs: Record<string, string> = {
  'tabs.label.hive': 'Hive',
  'tabs.label.ruche': 'Hive',
  'tabs.label.onetoone': '1 to 1',
  'tabs.label.map': 'MAP',
  'tabs.label.people': 'People',
  'tabs.label.teams': 'Teams',
  'tabs.label.community': 'Community',
  'tabs.label.calendar': 'Calendar',
  'tabs.label.process': 'Process',
  'tabs.label.problem': 'Issues',
  'tabs.label.admin': 'Admin',
  'tabs.label.profile': 'Profile',
  'tabs.label.processes': 'Processes',
  'tabs.label.database': 'To Do',
  'tabs.label.rules': 'Rules',
  'tabs.label.badges': 'Badges',

  //Menu tooltip title
  'tabs.tooltip.title.hive':
    'The Hive is the digital living room of the company, where indicators, posts, and coworkers can be found',
  'tabs.tooltip.title.onetoone':
    'The 1-to-1 is an isolated space that allows employees and managers to discuss professional matters',
  'tabs.tooltip.title.map':
    "The MAP is where we can view all the teams' and communities' interactions in the company",
  'tabs.tooltip.title.people':
    'The "People" menu displays all your coworkers in a grid view, there you can also see the hall of fame of your company',
  'tabs.tooltip.title.teams':
    "The Team dashboard is a collaborative space for each of your company's teams",
  'tabs.tooltip.title.calendar':
    "With the Calendar, you can see all the company's events, meetings, and coworkers' leaves with a chronological view.",
  'tabs.tooltip.title.rules': 'A database for all the rules created for the company',
  'tabs.tooltip.title.problem':
    'A database for all the issues created in the company, and the teams',
  'tabs.tooltip.title.database':
    'A database for all the issues created in the company, and the teams',
};
export default tabs;
