import { DefaultTheme, makeStyles } from '@mui/styles';
import { TDefaultAvatarSize } from './DefaultPersonAvatar';

export interface StyleProps {
  avatarSize?: TDefaultAvatarSize;
}

const SMALL = 24;
const MEDIUM = 48;
const LARGE = 150;
const HUGE = 300;

export default makeStyles<DefaultTheme, StyleProps>((theme) => ({
  editIcon: {
    visibility: 'hidden',
    opacity: 0,
    position: 'absolute',
    width: '30%',
    height: '30%',
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
  },
  container: {
    '&:hover $editIcon': {
      visibility: 'visible',
      opacity: 1,
    },
    position: 'relative',
    background: '#1D1E20',
  },
  small: {
    width: SMALL,
    height: SMALL,
  },
  medium: {
    width: MEDIUM,
    height: MEDIUM,
  },
  large: {
    width: LARGE,
    height: LARGE,
  },
  huge: {
    width: HUGE,
    height: HUGE,
  },
  avatarSmall: {
    fontSize: SMALL,
  },
  avatarMedium: {
    fontSize: MEDIUM,
  },
  avatarLarge: {
    fontSize: LARGE,
  },
  avatarHuge: {
    fontSize: HUGE,
  },
  avatar: {
    opacity: 1,
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      opacity: 0.9,
    },
    color: '#404040',
    transform: `translateY(20%)`,
  },
}));
