import { gql } from '@apollo/client';

export const GET_SNACKBAR_STATE = gql`
  query GetSnackbarState {
    snackBar @client {
      type
      message
      isOpen
    }
  }
`;
